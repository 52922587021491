import { useQuery, useQueryClient, UseMutationResult, useMutation } from 'react-query';
import { 
  api, 
  QuizHistoryFilter, 
  ExtendedCategoryStats, 
  CategoryStats as ApiCategoryStats, 
  Category as ApiCategory,
} from '../services/api';
import { useActiveQuiz, ActiveQuizState, getTestletType, isValidActiveQuiz } from './useActiveQuiz';
import { useState } from 'react';

// For the QuestionCategory returned from getQuestionCategories
interface QuestionCategory {
  id: string;
  parent_question_category_name: string;
  sub_question_category_name: string;
}

// Category extends ExtendedCategoryStats and adds category-specific fields
interface Category extends ExtendedCategoryStats {
  id: string;
  parent_question_category_name: string;
  sub_question_category_name: string;
}

type StatKeys = keyof Omit<ExtendedCategoryStats, 'category_id'>;
const statKeys: StatKeys[] = [
  'total_questions',
  'unanswered_questions',
  'correct_percentage',
  'incorrect',
  'correct',
  'confident',
  'maybe',
  'guessing',
  'confident_correct',
  'confident_incorrect',
  'maybe_correct',
  'maybe_incorrect',
  'guessing_correct',
  'guessing_incorrect'
];

const DEFAULT_EXTENDED_STATS: ExtendedCategoryStats = {
  category_id: '',
  total_questions: 0,
  unanswered_questions: 0,
  correct_percentage: 0,
  incorrect: 0,
  correct: 0,
  confident: 0,
  maybe: 0,
  guessing: 0,
  confident_correct: 0,
  confident_incorrect: 0,
  maybe_correct: 0,
  maybe_incorrect: 0,
  guessing_correct: 0,
  guessing_incorrect: 0
};

export const useQuizData = (courseId: string, userId: string | undefined) => {
  const queryClient = useQueryClient();
  
  // Add active quiz state
  const [activeQuizState, setActiveQuizState] = useState<ActiveQuizState>({
    activeQuiz: null,
    activeTestletType: null,
    showActiveQuizModal: false
  });

  // Use the active quiz hook
  const { data: activeQuizData } = useActiveQuiz(userId, courseId, {
    onSuccess: (quiz) => {
      if (quiz && isValidActiveQuiz(quiz)) {
        setActiveQuizState({
          activeQuiz: quiz,
          activeTestletType: getTestletType(quiz),
          showActiveQuizModal: true
        });
      } else {
        setActiveQuizState({
          activeQuiz: null,
          activeTestletType: null,
          showActiveQuizModal: false
        });
      }
    }
  });

  /**
   * 1) Fetches all categories for a course, plus category-level stats (number of answered, correct %, etc.)
   *    Then it transforms the data to include a special 'All' row that sums up child subcategories.
   */
  const fetchCategories = async () => {
    if (!userId) return null;

    // 1. Get the list of question categories
    const data = await api.getQuestionCategories(courseId) as QuestionCategory[];

    // 2. Extract the category IDs and fetch category stats (using the new RPC)
    const allCategoryIds = data.map(category => category.id);
    const stats = await api.getCategoryStats(userId, courseId, allCategoryIds) as ExtendedCategoryStats[];

    // 3. Build a map from category_id => stats
    const statsMap = stats.reduce((acc, stat) => {
      acc[stat.category_id] = stat;
      return acc;
    }, {} as { [key: string]: ExtendedCategoryStats });

    // 4. Group categories by parent_question_category_name
    const categoryGroups: { [key: string]: QuestionCategory[] } = {};
    data.forEach(category => {
      if (!categoryGroups[category.parent_question_category_name]) {
        categoryGroups[category.parent_question_category_name] = [];
      }
      categoryGroups[category.parent_question_category_name].push(category);
    });

    // 5. Sort parent categories by numerical unit or fallback alphabetical
    const sortedParentCategories = Object.keys(categoryGroups).sort((a, b) => {
      const aMatch = a.match(/Unit (\d+):/);
      const bMatch = b.match(/Unit (\d+):/);
      if (aMatch && bMatch) return parseInt(aMatch[1]) - parseInt(bMatch[1]);
      if (aMatch) return -1;
      if (bMatch) return 1;
      return a.localeCompare(b);
    });

    // 6. Build a structure with each parent => subcategories[] (each subcategory extends ExtendedCategoryStats)
    const structuredCategories: { [key: string]: Category[] } = {};
    
    sortedParentCategories.forEach(parentName => {
      // Transform each subcategory into a full Category object, merging stats
      structuredCategories[parentName] = categoryGroups[parentName]
        .map(category => {
          const categoryStats = statsMap[category.id] || {
            ...DEFAULT_EXTENDED_STATS,
            category_id: category.id
          };

          return {
            ...categoryStats,
            ...category,
            id: category.id
          } as Category;
        })
        .sort((a, b) => 
          a.sub_question_category_name.localeCompare(b.sub_question_category_name)
        );

      // 7. Compute aggregate stats for the parent row
      const parentStats = structuredCategories[parentName].reduce((acc, curr) => {
        statKeys.forEach(key => {
          acc[key] += curr[key];
        });
        return acc;
      }, { ...DEFAULT_EXTENDED_STATS, category_id: parentName });

      // 8. Recompute the parent's correct_percentage properly
      const answeredQuestions = parentStats.total_questions - parentStats.unanswered_questions;
      parentStats.correct_percentage = answeredQuestions > 0
        ? (parentStats.correct / answeredQuestions) * 100
        : 0;

      // 9. Finally, insert a special "All" row at the top of each parent's array
      structuredCategories[parentName].unshift({
        ...parentStats,
        id: parentName,
        parent_question_category_name: parentName,
        sub_question_category_name: 'All'
      } as Category);
    });

    return { structuredCategories, statsMap };
  };

  /**
   * React Query: fetch all categories (with stats) for a given course/user
   */
  const { data: categoriesData, isLoading: categoriesLoading, error: categoriesError } = useQuery(
    ['categories', courseId, userId],
    fetchCategories,
    {
      enabled: !!userId,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      retry: 3,
      onError: (error) => {
        console.error('Error fetching categories:', error);
      }
    }
  );

  /**
   * 2) Returns a React Query hook that fetches the number of questions available 
   *    for the user's current filter (selected categories + KeslerQ filter). 
   *    Uses the new getAvailableQuestionCounts RPC.
   */
  const useAvailableQuestionCounts = (selectedCategories: string[], keslerQFilter: string[], enabled: boolean) => {
    return useQuery(
      ['availableQuestionCounts', courseId, userId, selectedCategories, keslerQFilter],
      async () => {
        // 1. Call the new RPC to get aggregated stats
        return api.getAvailableQuestionCounts(courseId, selectedCategories, keslerQFilter, userId!);
      },
      {
        enabled: enabled && !!userId && selectedCategories.length > 0,
        staleTime: 1 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
        retry: 3,
      }
    );
  };

  /**
   * 3) Mutation: reset category stats for the user. 
   *    Once complete, invalidate queries so the UI picks up new data.
   */
  const resetCategories: UseMutationResult<void, Error, { categoryIds: string[] }> = useMutation(
    async ({ categoryIds }) => {
      if (!userId) throw new Error('User not authenticated');
      for (const categoryId of categoryIds) {
        await api.resetCategory(userId, courseId, categoryId);
      }
    },
    {
      onSuccess: () => {
        // If reset was successful, refetch category stats and question counts
        queryClient.invalidateQueries(['categories', courseId]);
        queryClient.invalidateQueries(['availableQuestionCounts', courseId]);
      },
    }
  );

  /**
   * Clears the sessionStorage entry for active quiz and invalidates the query so it'll refetch fresh data next time.
   */
  const resetActiveQuizCheck = () => {
    if (userId && courseId) {
      sessionStorage.removeItem(`activeQuiz_${userId}_${courseId}`);
      queryClient.invalidateQueries(['activeQuiz', userId, courseId]);
      setActiveQuizState({
        activeQuiz: null,
        activeTestletType: null,
        showActiveQuizModal: false
      });
    }
  };

  /**
   * 5) useFilteredQuizHistory: fetch and cache a list of quiz histories by filter (all / correct / incorrect / etc.)
   */
  const useFilteredQuizHistory = (filter: QuizHistoryFilter = 'all') => {
    return useQuery(
      ['filteredQuizHistory', userId, courseId, filter],
      () => api.getFilteredQuizHistory(userId!, courseId, filter),
      {
        enabled: !!userId,
        staleTime: 1 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
        retry: 3,
        onError: (error) => {
          console.error('Error fetching filtered quiz history:', error);
        }
      }
    );
  };

  return {
    // Data and status about categories
    categoriesData,
    categoriesLoading,
    categoriesError,

    // Hook for computing how many questions are available with current filters
    useAvailableQuestionCounts,

    // Mutation to reset category stats
    resetCategories,

    // Active quiz state and management
    activeQuizState,
    setActiveQuizState,
    resetActiveQuizCheck,

    // Hook for fetching user quiz history by filter
    useFilteredQuizHistory,
  };
};