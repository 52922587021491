import React, { useEffect, useState } from 'react';
import { Simulation, TBSq, HTMLContent, FeedbackItem } from '../../services/api';
import { useTheme } from '../../contexts/ThemeContext';
import FormattedNumberInput from './common/FormattedNumberInput';
import TBSDropdown from '../ui/TBSdropdown';
import Exhibits from '../ui/Exhibits';

// Interfaces remain unchanged as they're still valid
interface SimulationsRenderProps {
  simulation: Simulation;
  onAnswerChange: (questionId: string, field: string, value: string, touched?: boolean) => void;
  userAnswers: Record<string, any>;
  isSubmitted: boolean;
  userResponse?: any;
  gradingResults: {
    score: number | null;
    feedback: FeedbackItem[];
  } | null;
  currentSimulationIndex: number;
  totalSimulations: number;
  userId: string;
  courseId: string;
  onTimeUpdate?: (time: number) => void;
  isReviewing?: boolean;
  isMockExam?: boolean;
}

interface Formula {
  type: 'sum' | 'subtract' | 'add';
  refs?: string[];
  first?: Formula;
  second?: Formula;
  values?: Array<number | Formula>;
}

interface UserAnswer {
  value: string;
  touched?: boolean;
}

function isTBSq(question: TBSq | HTMLContent): question is TBSq {
  return (question as TBSq).id !== undefined;
}

const SimulationsRender: React.FC<SimulationsRenderProps> = ({
  simulation,
  onAnswerChange,
  userAnswers,
  isSubmitted,
  userResponse,
  gradingResults,
  currentSimulationIndex,
  totalSimulations,
}) => {
  const { isDarkMode } = useTheme();
  const [questionContent, setQuestionContent] = useState<any>(null);
  const [exhibitsData, setExhibitsData] = useState<any>(null);
  const [touchedFields, setTouchedFields] = useState<Set<string>>(new Set());

  // CHANGE #1: Updated useEffect to clear state when simulation changes
  useEffect(() => {
    // CHANGE #2: Clear both exhibits and question content at the start
    setExhibitsData(null);
    setQuestionContent(null);

    // CHANGE #3: Added null check for exhibits
    if (simulation?.exhibits) {
      try {
        const parsedExhibits = typeof simulation.exhibits === 'string' 
          ? JSON.parse(simulation.exhibits)
          : simulation.exhibits;
        setExhibitsData(parsedExhibits.exhibits || null);
      } catch (error) {
        console.error('Error parsing exhibits:', error);
        setExhibitsData(null);
      }
    }

    // CHANGE #4: Added null check for question_content
    if (simulation?.question_content) {
      try {
        const parsedContent = typeof simulation.question_content === 'string'
          ? JSON.parse(simulation.question_content)
          : simulation.question_content;

        // If submitted, inject user answers into the content
        if (isSubmitted && gradingResults?.feedback) {
          const updatedQuestions = parsedContent.questions.map((question: TBSq | HTMLContent) => {
            if (!isTBSq(question)) return question;

            const feedback = gradingResults.feedback.find(f => f.questionId === question.id);
            if (!feedback) return question;

            // Update the cells with user answers from feedback
            const updatedCells = question.cells.map(cell => {
              if (cell.type === 'text' || !cell.ref) return cell;

              const answerFeedback = feedback.answerFeedback[cell.ref];
              if (!answerFeedback) return cell;

              // Update cell with user's answer while preserving other properties
              return {
                ...cell,
                value: typeof answerFeedback.userAnswer === 'object' 
                  ? answerFeedback.userAnswer.value 
                  : answerFeedback.userAnswer
              };
            });

            return {
              ...question,
              cells: updatedCells
            };
          });

          setQuestionContent({
            ...parsedContent,
            questions: updatedQuestions
          });
        } else {
          setQuestionContent(parsedContent);
        }
      } catch (error) {
        console.error('Error parsing question_content:', error);
        setQuestionContent(null);
      }
    }
  // CHANGE #5: Added simulation.id to dependencies to ensure clean-up on simulation change
  }, [simulation?.id, simulation?.exhibits, simulation?.question_content, isSubmitted, gradingResults]);

  // Functions that remain unchanged as they work correctly
  const calculateCellValue = (
    formula: Formula,
    userAnswers: Record<string, Record<string, UserAnswer>>
  ): string => {
    if (formula.type === 'sum' && formula.refs) {
      const sum: number = formula.refs.reduce((total: number, ref: string) => {
        const questionId = Object.keys(userAnswers).find(qId => userAnswers[qId] && userAnswers[qId][ref] !== undefined);
        if (!questionId) return total;
        const value = userAnswers[questionId][ref]?.value || '0';
        const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
        return total + numericValue;
      }, 0);
      return sum.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }
  
    if (formula.type === 'subtract' && formula.first && formula.second) {
      const firstValue: string = calculateCellValue(formula.first, userAnswers);
      const secondValue: string = calculateCellValue(formula.second, userAnswers);
      const result: number = parseFloat(firstValue.replace(/,/g, '')) - parseFloat(secondValue.replace(/,/g, ''));
      return result.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }
  
    if (formula.type === 'add' && formula.values) {
      const total: number = formula.values.reduce((sum: number, value: number | Formula) => {
        if (typeof value === 'number') {
          return sum + value;
        }
        if (typeof value === 'object') {
          const calculatedValue: string = calculateCellValue(value, userAnswers);
          return sum + parseFloat(calculatedValue.replace(/,/g, ''));
        }
        return sum;
      }, 0);
      return total.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }
  
    return '0';
  };

  // These helper functions remain unchanged as they work well
  const getAnswerStatus = (questionId: string, cellRef: string) => {
    if (!isSubmitted || !gradingResults || !gradingResults.feedback) return 'unanswered';
    const feedback = gradingResults.feedback.find((f: any) => f.questionId === questionId);
    if (!feedback) return 'unanswered';
    return feedback.answerFeedback[cellRef]?.isCorrect ? 'correct' : 'incorrect';
  };

  const getAnswerClass = (status: string) => {
    switch (status) {
      case 'correct':
        return isDarkMode ? 'bg-green-700 text-white' : 'bg-green-100 text-green-800';
      case 'incorrect':
        return isDarkMode ? 'bg-red-700 text-white' : 'bg-red-100 text-red-800';
      default:
        return isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800';
    }
  };

  const handleFieldTouch = (questionId: string, cellRef: string) => {
    setTouchedFields(prev => {
      const newSet = new Set(prev);
      newSet.add(`${questionId}-${cellRef}`);
      return newSet;
    });
  };

  // Updated getUserInputValue to handle both input and submitted states
  const getUserInputValue = (questionId: string, cellRef: string, isInput: boolean = false): string => {
    // If submitted, prioritize feedback values
    if (isSubmitted && gradingResults?.feedback) {
      const feedback = gradingResults.feedback.find(f => f.questionId === questionId);
      if (feedback?.answerFeedback[cellRef]?.userAnswer) {
        const userAnswer = feedback.answerFeedback[cellRef].userAnswer;
        return typeof userAnswer === 'object' ? userAnswer.value : userAnswer;
      }
    }

    // Check userResponse if available
    if (userResponse?.answers?.[questionId]?.[cellRef]) {
      const responseAnswer = userResponse.answers[questionId][cellRef];
      return typeof responseAnswer === 'object' ? responseAnswer.value : responseAnswer;
    }

    // For active input fields, ensure default value
    if (isInput) {
      const currentAnswer = userAnswers[questionId]?.[cellRef];
      if (currentAnswer) {
        return typeof currentAnswer === 'object' ? currentAnswer.value : currentAnswer;
      }
      return '0'; // Default value for input fields
    }

    // Check current userAnswers
    if (userAnswers[questionId]?.[cellRef]) {
      const answer = userAnswers[questionId][cellRef];
      return typeof answer === 'object' ? answer.value : answer;
    }

    // Default return
    return isInput ? '0' : '';
  };

  // Updated renderTextInput to handle display values better
  const renderTextInput = (questionId: string, cellRef: string, cell: any, answerClass: string) => {
    const inputValue = getUserInputValue(questionId, cellRef, true);
    
    return (
      <FormattedNumberInput
        value={inputValue}
        onChange={(newValue) => {
          onAnswerChange(questionId, cellRef, newValue || '0', true);
          handleFieldTouch(questionId, cellRef);
        }}
        disabled={isSubmitted}
        className={`w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${answerClass}`}
      />
    );
  };

  // New function to render cell content with HTML support
  const renderCellContent = (cell: any) => {
    if (cell.type === 'text' && cell.value.includes('<div')) {
      return renderHtmlContent(cell.value, 'question-content-html');
    }
    return <span className={`dark:text-gray-300 ${cell.isBold ? 'font-bold' : ''}`}>{cell.value}</span>;
  };
  
   // Update renderCell to use the new renderCellContent function
   const renderCell = (question: TBSq, cell: any) => {
    const questionId = question.id;
    const cellRef = cell.ref;
    const answerStatus = getAnswerStatus(questionId, cellRef);
    const answerClass = getAnswerClass(answerStatus);

    switch (cell.type) {
      case 'text':
        return renderCellContent(cell);
      case 'dropdown':
        return renderDropdown(questionId, cellRef, cell, answerClass);
      case 'input':
        return isSubmitted && cell.value ? (
          <span className={`block w-full p-2 ${answerClass}`}>{cell.value}</span>
        ) : (
          renderTextInput(questionId, cellRef, cell, answerClass)
        );
      case 'calculated':
        if (cell.formula) {
          const calculatedValue = calculateCellValue(cell.formula, userAnswers);
          return (
            <div className={`
              text-right px-2 
              ${cell.isBold ? 'font-bold' : ''}
              ${isDarkMode ? 'text-white' : 'text-black'}
            `}>
              {calculatedValue}
            </div>
          );
        }
        return null;
      default:
        return <span className="dark:text-gray-300">{cell.value || ''}</span>;
    }
  };

  const renderDropdown = (questionId: string, cellRef: string, cell: any, answerClass: string) => {
    const currentValue = getUserInputValue(questionId, cellRef);
    
    return (
      <TBSDropdown
        value={currentValue}
        onChange={(newValue) => {
          onAnswerChange(questionId, cellRef, newValue, true);
          handleFieldTouch(questionId, cellRef);
        }}
        options={cell.options || []}
        disabled={isSubmitted}
        className="w-full"
        style={{
          maxWidth: 'min(90vw, 600px)',
        }}
        answerClass={answerClass}
      />
    );
  };

  const renderHtmlContent = (html: string, additionalClasses: string = '') => {
    return (
      <div 
        dangerouslySetInnerHTML={{ __html: html }}
        className={`
          ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}
          [&>div]:space-y-6 
          [&>div>div]:bg-gray-800/5 
          dark:[&>div>div]:bg-gray-100/5 
          [&>div>div]:p-4 
          [&>div>div]:rounded-lg 
          [&_p]:mb-4 
          last:[&_p]:mb-0 
          [&_h5]:font-bold 
          [&_h5]:text-lg 
          [&_h5]:mb-2
          [&_ul]:list-disc 
          [&_ul]:pl-6 
          [&_ol]:list-decimal 
          [&_ol]:pl-6
          [&_li]:mb-2
          [&_table]:w-full 
          [&_table]:border-collapse 
          [&_td]:border 
          [&_td]:p-2
          [&_th]:border 
          [&_th]:p-2 
          [&_th]:bg-gray-50 
          dark:[&_th]:bg-gray-800
          ${additionalClasses}
        `}
      />
    );
  };

  const renderTable = () => {
    if (!questionContent || !questionContent.questions) return null;

    return (
      <>
        {questionContent.introduction && (
          <div className="mb-4">
            {renderHtmlContent(questionContent.introduction)}
          </div>
        )}
        <table className="w-full border-collapse border border-gray-300 dark:border-gray-600 mt-4">
          <thead>
            <tr className="bg-gray-100 dark:bg-gray-700">
              {questionContent.tableHeaders?.map((header: string, index: number) => (
                <th key={`header-${index}`} className="border border-gray-300 dark:border-gray-600 p-3 text-left dark:text-white">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {questionContent.questions.map((item: TBSq | HTMLContent, rowIndex: number) => {
              if (!isTBSq(item)) {
                return (
                  <tr key={`html-${rowIndex}`}>
                    <td 
                      colSpan={questionContent.tableHeaders.length} 
                      className="p-3"
                    >
                      {renderHtmlContent(item.content)}
                    </td>
                  </tr>
                );
              }
              
              return (
                <tr key={`row-${item.id}-${rowIndex}`}>
                  {item.cells.map((cell: any, cellIndex: number) => {
                    // Create a unique key combining multiple identifiers
                    const cellKey = cell.ref 
                      ? `${item.id}-${cell.ref}-${cellIndex}`
                      : `${item.id}-${cellIndex}`;
                    
                    return (
                      <td 
                        key={cellKey}
                        className={`border border-gray-300 dark:border-gray-600 p-3 ${cell.className || ''}`}
                        colSpan={cell.colSpan}
                      >
                        {renderCell(item, cell)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div className="simulation-render max-w-4xl mx-auto p-8 bg-white dark:bg-gray-900 rounded-lg">
      <div className="flex justify-between items-center mb-6 pb-4">
        <div className="flex items-center space-x-2">
          <h2 className="text-xl font-bold text-gray-900 dark:text-white">
            Simulation {currentSimulationIndex + 1} of {totalSimulations}
          </h2>
        </div>
      </div>

      {exhibitsData && exhibitsData.length > 0 && (
        <Exhibits exhibits={exhibitsData} />
      )}
      
      <div className="simulation-content">
        {simulation.html && renderHtmlContent(simulation.html, 'mb-4')}
        {renderTable()}
      </div>
    </div>
  );
};

export default React.memo(SimulationsRender);