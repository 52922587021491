import { useQuery, UseQueryResult, QueryClient } from 'react-query';
import { api, SPUserStudyPlan, SPStudyPlanItem, SPCourse, SPCourseMix, SPStudySchedule } from '../services/api';

interface StudyPlanData {
  userStudyPlan: SPUserStudyPlan | null;
  studyPlanItems: SPStudyPlanItem[];
  examDate: string | null;
  startDate: string | null;
  endDate: string | null;
  courseProviders: { [key: string]: string };
  primaryCourse: SPCourse | null;
  secondaryCourse: SPCourse | null;
  courseMix: SPCourseMix | null;
  studySchedule: SPStudySchedule | null;
}

interface StudyPlanError {
  message: string;
}

const fetchStudyPlanData = async (userId: string, courseId: string): Promise<StudyPlanData> => {
  try {
    // First get the user study plan
    const plan = await api.getUserStudyPlan(userId, courseId);
    console.log('Fetched user study plan:', plan);
    
    if (!plan) {
      return {
        userStudyPlan: null,
        studyPlanItems: [],
        examDate: null,
        startDate: null,
        endDate: null,
        courseProviders: {},
        primaryCourse: null,
        secondaryCourse: null,
        courseMix: null,
        studySchedule: null
      };
    }

    // Get course mix first since we need it for the items
    const courseMix = await api.getCourseMix(userId, courseId);
    console.log('Fetched course mix:', courseMix);

    // Then get the rest of the data
    const [items, dateData, cpaCourses, studySchedule] = await Promise.all([
      api.getStudyPlanItems(plan.plan_id),
      api.getExamDate(userId, courseId),
      api.getCPACourses(courseId),
      plan.current_schedule_id ? api.getStudySchedule(plan.plan_id) : null
    ]);

    console.log('Fetched items:', items);
    console.log('Fetched exam date:', dateData);
    console.log('Fetched CPA courses:', cpaCourses);
    console.log('Fetched study schedule:', studySchedule);

    const courseProviders = cpaCourses.reduce((acc: { [key: string]: string }, course: SPCourse) => {
      acc[course.cpa_course_id] = course.name.toLowerCase().includes('kesler') ? 'kesler' :
                                  course.name.toLowerCase().includes('becker') ? 'becker' :
                                  course.name.toLowerCase().includes('gleim') ? 'gleim' : 'blueprint';
      return acc;
    }, {});

    let primaryCourse: SPCourse | null = null;
    let secondaryCourse: SPCourse | null = null;

    if (courseMix) {
      primaryCourse = cpaCourses.find((course: SPCourse) => course.cpa_course_id === courseMix.primary_cpa_course_id) || null;
      secondaryCourse = courseMix.secondary_cpa_course_id 
        ? cpaCourses.find((course: SPCourse) => course.cpa_course_id === courseMix.secondary_cpa_course_id) || null
        : null;
    }

    const result = {
      userStudyPlan: plan,
      studyPlanItems: items || [],
      examDate: dateData?.exam_date || null,
      startDate: dateData?.start_date || null,
      endDate: studySchedule?.end_date || null,
      courseProviders,
      primaryCourse,
      secondaryCourse,
      courseMix,
      studySchedule
    };

    console.log('Returning study plan data:', result);
    return result;
  } catch (error) {
    console.error('Error in fetchStudyPlanData:', error);
    throw error;
  }
};

export const useStudyPlan = (
  userId: string,
  courseId: string,
  queryClient: QueryClient
): UseQueryResult<StudyPlanData, StudyPlanError> => {
  return useQuery<StudyPlanData, StudyPlanError>(
    ['studyPlan', userId, courseId],
    () => fetchStudyPlanData(userId, courseId),
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: (data) => {
        queryClient.setQueryData(['studyPlan', userId, courseId], data);
      },
    }
  );
};