import { 
    Flashcard, 
    CustomFlashcard, 
    UserFlashcardProgress,
    ConfidenceLevelValue,
    api 
  } from '../services/api';
  import { getBrowserInstanceId } from '../utils/browserInstance';
  
  export interface DueCardInfo {
    card: Flashcard | CustomFlashcard;
    dueDate: Date;
    confidenceLevel: ConfidenceLevelValue;
  }
  
  export const REVIEW_INTERVALS = {
    guessing: 1,  // 1 day
    maybe: 3,     // 3 days
    confident: 7  // 7 days
  } as const;
  
  /**
   * Calculates the next review date for a card based on confidence level
   */
  export function calculateNextReviewDate(
    confidenceLevel: ConfidenceLevelValue,
    lastReviewed: Date = new Date()
  ): Date {
    const nextReview = new Date(lastReviewed);
    const interval = confidenceLevel ? REVIEW_INTERVALS[confidenceLevel] : 1;
    nextReview.setDate(nextReview.getDate() + interval);
    return nextReview;
  }
  
  /**
   * Checks if a card is due for review by comparing against the review intervals
   */
  export function isCardDueForReview(
    card: Flashcard | CustomFlashcard,
    progress?: UserFlashcardProgress,
    now: Date = new Date()
  ): boolean {
    let lastReviewed: Date;
    let confidenceLevel: ConfidenceLevelValue;
  
    if ('side_1_content' in card) {
      // Pre-made flashcard - requires progress record
      if (!progress?.last_reviewed || !progress?.confidence_level) {
        return false; // Not reviewed yet, so not due
      }
      lastReviewed = new Date(progress.last_reviewed);
      confidenceLevel = progress.confidence_level;
    } else {
      // Custom flashcard - uses card's own review data
      if (!card.last_reviewed || !card.confidence_level) {
        return false; // Not reviewed yet, so not due
      }
      lastReviewed = new Date(card.last_reviewed);
      confidenceLevel = card.confidence_level;
    }
  
    // Calculate next review date based on confidence level
    const nextReviewDate = calculateNextReviewDate(confidenceLevel, lastReviewed);
    
    // Card is due if current time is past the next review date
    return now >= nextReviewDate;
  }
  
  /**
   * Gets all cards that are due for review based on their last review date and confidence level
   * Now returns confidence level with each card for proper display
   */
  export function getCardsForSpacedRepetition(
    cards: Flashcard[],
    customCards: CustomFlashcard[],
    userProgress: UserFlashcardProgress[],
    now: Date = new Date()
  ): DueCardInfo[] {
    const dueCards: DueCardInfo[] = [];
  
    // Check pre-made flashcards
    cards.forEach(card => {
      const progress = userProgress.find(p => p.flashcard_id === card.id);
      
      // Skip cards that haven't been reviewed yet
      if (!progress?.confidence_level) return;
      
      if (isCardDueForReview(card, progress, now)) {
        const lastReviewed = new Date(progress.last_reviewed);
        dueCards.push({
          card,
          dueDate: calculateNextReviewDate(progress.confidence_level, lastReviewed),
          confidenceLevel: progress.confidence_level
        });
      }
    });
  
    // Check custom flashcards
    customCards.forEach(card => {
      // Skip cards that haven't been reviewed yet
      if (!card.confidence_level || !card.last_reviewed) return;
      
      if (isCardDueForReview(card, undefined, now)) {
        const lastReviewed = new Date(card.last_reviewed);
        dueCards.push({
          card,
          dueDate: calculateNextReviewDate(card.confidence_level, lastReviewed),
          confidenceLevel: card.confidence_level
        });
      }
    });
  
    // Sort by due date (oldest first)
    return dueCards.sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime());
  }
  
  /**
   * Updates the review schedule for a card in the database and returns the next review date
   * Now accepts a forceUpdate parameter to handle confirming existing confidence levels
   */
  export async function updateCardReviewSchedule(
    userId: string,
    courseId: string,
    cardId: string,
    confidenceLevel: ConfidenceLevelValue,
    isCustomCard: boolean,
    lastReviewed: Date = new Date(),
    forceUpdate: boolean = false
  ): Promise<Date> {
    if (!confidenceLevel) {
      throw new Error('Cannot update review schedule without confidence level');
    }
  
    const browserInstanceId = await getBrowserInstanceId();
    const nextReviewDate = calculateNextReviewDate(confidenceLevel, lastReviewed);
  
    try {
      if (isCustomCard) {
        // Update custom flashcard
        await api.updateCustomFlashcard(cardId, {
          confidence_level: confidenceLevel,
          last_reviewed: lastReviewed.toISOString(),
          browser_instance_id: browserInstanceId
        });
      } else {
        // Update user progress for regular flashcard
        const progressData: Omit<UserFlashcardProgress, 'id'> = {
          user_id: userId,
          course_id: courseId,
          flashcard_id: cardId,
          confidence_level: confidenceLevel,
          last_reviewed: lastReviewed.toISOString(),
          browser_instance_id: browserInstanceId,
          version: 1,
          synced: 0
        };
  
        await api.updateUserFlashcardProgress(progressData);
      }
  
      return nextReviewDate;
    } catch (error) {
      console.error('Failed to update review schedule:', error);
      throw error;
    }
  }