import React, { useState, useRef, useEffect, CSSProperties } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface TBSDropdownProps {
  options: string[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  className?: string;
  answerClass?: string;
  style?: CSSProperties;
}

const TBSDropdown: React.FC<TBSDropdownProps> = ({
  options,
  value,
  onChange,
  disabled = false,
  className = '',
  answerClass = '',
  style
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [dropdownWidth, setDropdownWidth] = useState<number>(0);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  // Calculate and set the dropdown width based on button width
  useEffect(() => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.offsetWidth;
      // Make dropdown menu 50% wider than the button
      setDropdownWidth(Math.max(buttonWidth * 1.5, 250)); // minimum width of 250px
    }
  }, [buttonRef.current]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelect = (option: string) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const displayValue = selectedOption || 'Select...';

  return (
    <div 
      ref={dropdownRef} 
      className={`relative w-full ${className}`}
      style={style}
    >
      <button
        ref={buttonRef}
        type="button"
        onClick={toggleDropdown}
        disabled={disabled}
        className={`
          w-full min-h-[40px] p-2 text-left border rounded-md 
          flex justify-between items-start
          ${disabled ? 'cursor-not-allowed opacity-75' : 'cursor-pointer'}
          ${answerClass}
          hover:border-gray-400 dark:hover:border-gray-500
          focus:outline-none focus:ring-2 focus:ring-blue-500
        `}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <span className="whitespace-normal break-words flex-1 mr-2 text-sm">
          {displayValue}
        </span>
        <span className="flex-shrink-0 mt-1">
          {isOpen ? 
            <ChevronUp className="h-4 w-4" /> : 
            <ChevronDown className="h-4 w-4" />
          }
        </span>
      </button>

      {isOpen && (
        <ul
          className={`
            absolute z-50 mt-1 max-h-80 overflow-auto
            bg-white dark:bg-gray-800 
            border border-gray-300 dark:border-gray-600
            rounded-md shadow-lg
          `}
          style={{
            width: `${dropdownWidth}px`,
            left: '50%',
            transform: 'translateX(-50%)'
          }}
          role="listbox"
        >
          {options.map((option, index) => (
            <li
              key={index}
              className={`
                p-3 cursor-pointer text-sm
                ${option === selectedOption ? 
                  'bg-blue-100 dark:bg-blue-900' : 
                  'hover:bg-gray-100 dark:hover:bg-gray-700'
                }
                ${index !== options.length - 1 ? 'border-b border-gray-200 dark:border-gray-700' : ''}
              `}
              onClick={() => handleSelect(option)}
              role="option"
              aria-selected={option === selectedOption}
            >
              <div className="whitespace-normal break-words">
                {option}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TBSDropdown;