import React, { useEffect, useRef, useState } from 'react';
import mermaid from 'mermaid';
import { useTheme } from '../../contexts/ThemeContext';
import { ZoomIn, ZoomOut, Maximize2, MinusCircle, PlusCircle } from 'lucide-react';
import { Button } from '../ui/Button';

interface MermaidProps {
  chart: string;
}

const Mermaid: React.FC<MermaidProps> = ({ chart }) => {
  const { isDarkMode } = useTheme();
  const mermaidRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isRendered, setIsRendered] = useState(false);
  const [scale, setScale] = useState(0.75); // Default to 75%
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [scrollPos, setScrollPos] = useState({ x: 0, y: 0 });
  const [contentHeight, setContentHeight] = useState<number | null>(null);

  useEffect(() => {
    mermaid.initialize({
      startOnLoad: false,
      theme: isDarkMode ? 'dark' : 'default',
      securityLevel: 'loose',
      fontFamily: 'inherit',
      flowchart: {
        htmlLabels: true,
        curve: 'basis',
        useMaxWidth: true,
        padding: 8,
        nodeSpacing: 20,
        rankSpacing: 40,
      },
      themeVariables: {
        fontFamily: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
        fontSize: '14px',
        nodeTextSize: '14px',
        labelTextSize: '14px',
        nodePadding: 8,
        edgeLabelBackground: isDarkMode ? '#374151' : '#f3f4f6',
        mainBkg: 'transparent',
        nodeBkg: isDarkMode ? '#1f2937' : '#ffffff',
        nodeTextColor: isDarkMode ? '#e5e7eb' : '#111827',
        clusterBkg: isDarkMode ? '#111827' : '#f9fafb',
        titleColor: isDarkMode ? '#e5e7eb' : '#111827',
        edgeColor: isDarkMode ? '#6b7280' : '#4b5563',
      }
    });
  }, [isDarkMode]);

  const handleZoomIn = () => {
    setScale(prev => Math.min(prev + 0.2, 3.0));
  };

  const handleZoomOut = () => {
    setScale(prev => Math.max(prev - 0.2, 0.3));
  };

  const handleReset = () => {
    setScale(0.75); // Reset to 75%
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.button === 0) {
      setIsDragging(true);
      setStartPos({
        x: e.pageX - scrollPos.x,
        y: e.pageY - scrollPos.y
      });
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;

    const newX = e.pageX - startPos.x;
    const newY = e.pageY - startPos.y;

    if (containerRef.current) {
      containerRef.current.scrollTo(-newX, -newY);
    }

    setScrollPos({ x: -newX, y: -newY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Update content height when SVG is rendered
  const updateContentHeight = () => {
    if (mermaidRef.current) {
      const svgElement = mermaidRef.current.querySelector('svg');
      if (svgElement) {
        const height = svgElement.getBoundingClientRect().height;
        setContentHeight(height * scale);
      }
    }
  };

  useEffect(() => {
    let mounted = true;

    const renderDiagram = async () => {
      if (!mermaidRef.current || !mounted) return;

      try {
        mermaidRef.current.innerHTML = '';

        const cleanChart = chart
          .replace(/^\s+|\s+$/g, '')
          .replace(/\\n/g, '\n')
          .replace(/\n\s+/g, '\n')
          .trim();

        const container = document.createElement('div');
        container.className = 'mermaid';
        container.style.visibility = 'hidden';
        container.textContent = cleanChart;
        mermaidRef.current.appendChild(container);

        await new Promise(resolve => requestAnimationFrame(resolve));

        const { svg } = await mermaid.render(
          `mermaid-${Date.now()}`, 
          cleanChart
        );

        if (!mounted) return;

        mermaidRef.current.innerHTML = svg;

        const svgElement = mermaidRef.current.querySelector('svg');
        if (svgElement) {
          svgElement.style.width = '100%';
          svgElement.style.height = 'auto';
          svgElement.style.maxWidth = 'none';
          svgElement.style.display = 'block';
          svgElement.style.margin = '0 auto';

          // Remove filter for dark mode - using theme variables instead
          requestAnimationFrame(updateContentHeight);
        }

        setIsRendered(true);

      } catch (err) {
        console.error('Error rendering diagram:', err);
        if (!mounted || !mermaidRef.current) return;

        const errorMessage = err instanceof Error ? err.message : 'Failed to render diagram';
        mermaidRef.current.innerHTML = `
          <div class="p-4 border border-red-300 rounded bg-red-50 dark:bg-red-900/50 text-red-700 dark:text-red-200">
            <p class="font-bold">Failed to render diagram</p>
            <pre class="mt-2 text-sm overflow-auto bg-red-100 dark:bg-red-900/30 p-2 rounded">${chart}</pre>
            <p class="mt-2 text-sm">${errorMessage}</p>
          </div>
        `;
      }
    };

    const timeoutId = setTimeout(renderDiagram, 100);

    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [chart, isDarkMode]);

  useEffect(() => {
    updateContentHeight();
  }, [scale]);

  return (
    <div className="relative">
      <div className="absolute top-2 right-2 flex gap-1 z-10 bg-white/90 dark:bg-gray-800/90 p-1 rounded-lg backdrop-blur-sm shadow-sm">
        <Button
          variant="outline"
          size="sm"
          onClick={handleZoomIn}
          className="h-8 w-8 p-0 flex items-center justify-center"
        >
          <PlusCircle className="h-4 w-4 text-gray-700 dark:text-gray-200" />
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={handleZoomOut}
          className="h-8 w-8 p-0 flex items-center justify-center"
        >
          <MinusCircle className="h-4 w-4 text-gray-700 dark:text-gray-200" />
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={handleReset}
          className="h-8 w-8 p-0 flex items-center justify-center"
        >
          <Maximize2 className="h-4 w-4 text-gray-700 dark:text-gray-200" />
        </Button>
      </div>
      
      <div 
        ref={containerRef}
        className={`
          relative w-full 
          bg-gray-50 dark:bg-gray-900 
          rounded-lg p-2
          overflow-auto
          ${!isRendered ? 'animate-pulse' : ''}
          ${isDragging ? 'cursor-grabbing' : 'cursor-grab'}
        `}
        style={{
          height: contentHeight ? `${Math.min(contentHeight + 32, 900)}px` : '400px', // Add max height of 900px
          maxHeight: '900px'
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div 
          ref={mermaidRef}
          style={{
            transform: `scale(${scale})`,
            transformOrigin: '0 0',
            transition: 'transform 0.2s ease-out'
          }}
        />
      </div>
    </div>
);
};

export default Mermaid;