import React from 'react';

interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'default' | 'destructive' | 'warning' | 'success';
}

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  ({ className = '', variant = 'default', ...props }, ref) => {
    const variants = {
      default: 'bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700',
      destructive: 'bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800',
      warning: 'bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-800',
      success: 'bg-green-50 dark:bg-green-900/20 border border-green-200 dark:border-green-800'
    };

    return (
      <div
        ref={ref}
        role="alert"
        className={`
          relative w-full rounded-lg p-4 shadow-sm
          ${variants[variant]}
          ${className}
        `}
        {...props}
      />
    );
  }
);

Alert.displayName = 'Alert';

export const AlertTitle = React.forwardRef<HTMLHeadingElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className = '', ...props }, ref) => {
    return (
      <h5
        ref={ref}
        className={`mb-1 font-medium leading-none tracking-tight text-gray-900 dark:text-gray-100 ${className}`}
        {...props}
      />
    );
  }
);

AlertTitle.displayName = 'AlertTitle';

export const AlertDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className = '', ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={`text-sm text-gray-700 dark:text-gray-300 [&_p]:leading-relaxed ${className}`}
        {...props}
      />
    );
  }
);

AlertDescription.displayName = 'AlertDescription';