import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { supabase } from '../../../services/supabase';
import { 
  Activity,
  Book,
  ChevronDown,
  Clock,
  HelpCircle,
  Loader2,
  Mail,
  UserCircle,
  ExternalLink
} from 'lucide-react';
import { Card } from '../../ui/Card';
import { Badge } from '../../ui/Badge';
import { Separator } from '../../ui/Separator';
import { Button } from '../../ui/Button';
import { Alert, AlertDescription } from '../../ui/Alert';
import { 
  User, 
  UserCourseAccess,
  Product,
  TIER_IDS,
  COURSE_IDS,
  Question,
  Simulation,
  Flashcard,
  Lesson,
  ContentType
} from '../../../services/api';

interface CourseAccessStatus {
  productTitle: string;
  accessType: 'Full Access' | 'Study Materials Only' | 'Mentor Only' | 'Free Trial';
  tierId: string;
}

interface UserSidebarProps {
  userId: string;
  contentType?: ContentType;
  contentId?: string | null;
  courseId?: string;
  onRefreshData?: () => void;
  onNavigateToContent?: (contentType: ContentType, contentId: string, courseId: string) => void;
}

const UserSidebar: React.FC<UserSidebarProps> = ({
  userId,
  contentType,
  contentId,
  courseId,
  onRefreshData,
  onNavigateToContent
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [courseAccess, setCourseAccess] = useState<CourseAccessStatus[]>([]);
  const [products, setProducts] = useState<Record<string, string>>({});
  const [contentCourseId, setContentCourseId] = useState<string | null>(courseId || null);
   
  // Content states with proper typing
  const [question, setQuestion] = useState<Question | null>(null);
  const [lesson, setLesson] = useState<Lesson | null>(null);
  const [simulation, setSimulation] = useState<Simulation | null>(null);
  const [flashcard, setFlashcard] = useState<Flashcard | null>(null);
  const [contentLoading, setContentLoading] = useState(false);

  useEffect(() => {
    loadUserData();
  }, [userId]);

  useEffect(() => {
    if (contentId && contentType) {
      loadContentAndCourseId();
    }
  }, [contentId, contentType]);

  // Update effect to handle courseId prop changes
  useEffect(() => {
    if (courseId) {
      setContentCourseId(courseId);
    }
  }, [courseId]);

  // New function to get course ID for content
  const loadContentAndCourseId = async () => {
    if (!contentId || !contentType) return;
    
    setContentLoading(true);
    try {
      const { data, error } = await supabase
        .from(contentType)
        .select('*, course_id')
        .eq('id', contentId)
        .single();

      if (error) throw error;
      if (!data) throw new Error(`${contentType} not found`);

      // Set the content course ID
      setContentCourseId(data.course_id);

      // Set the appropriate content state
      switch (contentType) {
        case 'questions':
          setQuestion(data);
          break;
        case 'lessons':
          setLesson(data);
          break;
        case 'simulations':
          setSimulation(data);
          break;
        case 'flashcards':
          setFlashcard(data);
          break;
      }
    } catch (err) {
      console.error(`Error loading ${contentType}:`, err);
      setError(`Failed to load ${contentType}`);
    } finally {
      setContentLoading(false);
    }
  };

  const loadUserData = async () => {
    try {
      setLoading(true);
      await Promise.all([
        loadUserDetails(),
        loadProducts(),
        loadCourseAccess()
      ]);
    } catch (error) {
      console.error('Error loading user data:', error);
      setError('Failed to load user data');
    } finally {
      setLoading(false);
    }
  };

  const loadUserDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error loading user details:', error);
      throw error;
    }
  };

  const loadProducts = async () => {
    try {
      const { data: productsData, error: productsError } = await supabase
        .from('products')
        .select('id, title')
        .in('type', ['course', 'mentorship'])
        .in('id', Object.values(COURSE_IDS));

      if (productsError) throw productsError;

      const productMap: Record<string, string> = {};
      productsData?.forEach((product: Pick<Product, 'id' | 'title'>) => {
        productMap[product.id] = product.title;
      });
      
      setProducts(productMap);
    } catch (error) {
      console.error('Error loading products:', error);
      throw error;
    }
  };

  const loadCourseAccess = async () => {
    try {
      const { data: accessData, error: accessError } = await supabase
        .from('user_course_access')
        .select('*')
        .eq('user_id', userId);

      if (accessError) throw accessError;
      
      const accessStatuses: CourseAccessStatus[] = (accessData || [])
        .filter(access => !access.end_date || new Date(access.end_date) > new Date())
        .map((access: UserCourseAccess) => {
          let accessType: CourseAccessStatus['accessType'];
          
          switch (access.tier_id) {
            case TIER_IDS.FULL_ACCESS:
              accessType = 'Full Access';
              break;
            case TIER_IDS.STUDY_MATERIALS_ONLY:
              accessType = 'Study Materials Only';
              break;
            case TIER_IDS.MENTOR_ONLY:
              accessType = 'Mentor Only';
              break;
            case TIER_IDS.FREE_TRIAL:
              accessType = 'Free Trial';
              break;
            default:
              accessType = 'Free Trial';
          }

          return {
            productTitle: access.course_id,
            accessType,
            tierId: access.tier_id
          };
        });

      setCourseAccess(accessStatuses);
    } catch (error) {
      console.error('Error loading course access:', error);
      throw error;
    }
  };

  // Replace loadQuestionData with generic loadContentData
  const loadContentData = async () => {
    if (!contentId || !contentType) return;
    
    try {
      setContentLoading(true);
      const { data, error } = await supabase
        .from(contentType)
        .select('*')
        .eq('id', contentId)
        .single();

      if (error) throw error;
      setQuestion(data);
    } catch (error) {
      console.error('Error loading content:', error);
      setError(`Failed to load ${contentType} data`);
    } finally {
      setContentLoading(false);
    }
  };

    // Update event handlers with correct types
  const preventContextMenu = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const preventTextSelection = useCallback((e: React.SyntheticEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  // Get content type display name
  const getContentTypeDisplay = (type: ContentType): string => {
    const displayMap: Record<ContentType, string> = {
      'questions': 'Question',
      'lessons': 'Lesson',
      'simulations': 'Simulation',
      'flashcards': 'Flashcard'
    };
    return displayMap[type] || 'Content';
  };

  const renderContent = () => {
    if (!contentId || !contentType || !userId || !contentCourseId) return null;

    return (
      <Card className="mb-4">
        <div className="p-4">
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <HelpCircle className="w-4 h-4" />
              <span className="font-medium">
                {getContentTypeDisplay(contentType)} Reference
              </span>
            </div>
            <Button
              size="sm"
              onClick={() => onNavigateToContent?.(contentType, contentId, contentCourseId)}
              className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white"
            >
              <span>See {getContentTypeDisplay(contentType)}</span>
              <ExternalLink className="w-4 h-4" />
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  if (loading) {
    return (
      <div className="p-4 flex items-center justify-center">
        <Loader2 className="w-6 h-6 animate-spin" />
      </div>
    );
  }

  if (!user) return null;

  return (
    <div className="h-full overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600 scrollbar-track-transparent">
      <div className="p-4 space-y-4">
        <h3 className="font-medium text-gray-900 dark:text-gray-100">Additional Ticket Details</h3>
        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {/* User Info Card */}
        <Card className="p-4">
          <div className="space-y-3">
            <div className="flex items-center gap-2">
              <UserCircle className="w-4 h-4 text-gray-500" />
              <span className="font-medium">{user.full_name || 'Unknown'}</span>
            </div>
            <div className="flex items-center gap-2">
              <Mail className="w-4 h-4 text-gray-500" />
              <span className="text-sm">{user.email}</span>
            </div>
            <Separator />
            <div className="flex items-center gap-2 text-sm">
              <Clock className="w-4 h-4 text-gray-500" />
              Member since {format(new Date(user.created_at || new Date()), 'MMM d, yyyy')}
            </div>
            {user.last_sign_in_at && (
              <div className="flex items-center gap-2 text-sm">
                <Activity className="w-4 h-4 text-gray-500" />
                Last active {format(new Date(user.last_sign_in_at), 'MMM d, yyyy')}
              </div>
            )}
          </div>
        </Card>

        {/* Course Access Card */}
        <Card className="p-4">
          <div className="space-y-3">
            <div className="flex items-center gap-2 mb-2">
              <Book className="w-4 h-4 text-gray-500" />
              <span className="font-medium">Course Access</span>
            </div>
            {courseAccess.length === 0 ? (
              <div className="text-sm text-gray-500">
                No active course access
              </div>
            ) : (
              <div className="space-y-2">
                {courseAccess.map((access, index) => (
                  <div key={index} className="flex items-center justify-between">
                    <span className="text-sm font-medium">
                      {products[access.productTitle] || 'Loading...'}
                    </span>
                    <Badge 
                      variant={access.tierId === TIER_IDS.FULL_ACCESS ? 'default' : 'secondary'}
                      className="text-xs"
                    >
                      {access.accessType}
                    </Badge>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Card>
        {/* Content Section - Always shown if present */}
        {contentId && contentType && (
          <div className="space-y-2 mb-4">
            <h3 className="font-medium text-gray-900 dark:text-gray-100">
              Referenced {contentType.slice(0, -1).charAt(0).toUpperCase() + contentType.slice(1, -1)}
            </h3>
            {contentLoading ? (
              <div className="flex items-center justify-center p-4">
                <Loader2 className="w-4 h-4 animate-spin" />
              </div>
            ) : (
              renderContent()
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSidebar;