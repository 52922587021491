import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { ChevronDown, Check } from 'lucide-react';

interface SelectContextType {
  value: string;
  onValueChange: (value: string) => void;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  disabled?: boolean;
}

const SelectContext = createContext<SelectContextType>({
  value: '',
  onValueChange: () => {},
  isOpen: false,
  setIsOpen: () => {},
  disabled: false
});

interface SelectProps {
  value?: string;
  onValueChange: (value: string) => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

export const Select: React.FC<SelectProps> = ({
  value = '',
  onValueChange,
  children,
  className = '',
  disabled = false
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // Close dropdown if disabled
  useEffect(() => {
    if (disabled && isOpen) {
      setIsOpen(false);
    }
  }, [disabled]);

  return (
    <div className={className}>
      <SelectContext.Provider value={{ value, onValueChange, isOpen, setIsOpen, disabled }}>
        {children}
      </SelectContext.Provider>
    </div>
  );
};

interface SelectTriggerProps {
  children: React.ReactNode;
  className?: string;
}

export const SelectTrigger: React.FC<SelectTriggerProps> = ({ 
  children,
  className = '' 
}) => {
  const { isOpen, setIsOpen, disabled } = useContext(SelectContext);
  
  return (
    <button
      type="button"
      onClick={() => !disabled && setIsOpen(!isOpen)}
      disabled={disabled}
      className={`
        flex h-10 w-full items-center justify-between rounded-md border border-gray-300 
        dark:border-gray-600 bg-white dark:bg-gray-800 px-3 py-2 text-sm
        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500
        disabled:cursor-not-allowed disabled:opacity-50
        ${className}
      `}
    >
      {children}
      <ChevronDown className={`h-4 w-4 opacity-50 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
    </button>
  );
};

interface SelectContentProps {
  children: React.ReactNode;
  className?: string;
  align?: 'start' | 'center' | 'end';
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
}

export const SelectContent: React.FC<SelectContentProps> = ({ 
  children,
  className = '' 
}) => {
  const { isOpen, setIsOpen, disabled } = useContext(SelectContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen && !disabled) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setIsOpen, disabled]);

  if (!isOpen || disabled) return null;

  return (
    <div
      ref={ref}
      className={`
        absolute z-50 w-full min-w-[8rem] overflow-hidden rounded-md border 
        border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 
        shadow-lg mt-1 py-1
        animate-in fade-in-0 zoom-in-95
        ${className}
      `}
    >
      {children}
    </div>
  );
};

interface SelectItemProps {
  value: string;
  children: React.ReactNode;
  className?: string;
}

export const SelectItem: React.FC<SelectItemProps> = ({ 
  value,
  children,
  className = '' 
}) => {
  const { value: selectedValue, onValueChange, setIsOpen, disabled } = useContext(SelectContext);
  const isSelected = value === selectedValue;

  return (
    <button
      type="button"
      onClick={() => {
        if (!disabled) {
          onValueChange(value);
          setIsOpen(false);
        }
      }}
      disabled={disabled}
      className={`
        relative flex w-full items-center px-8 py-2 text-sm
        hover:bg-gray-100 dark:hover:bg-gray-700
        focus:bg-gray-100 dark:focus:bg-gray-700 focus:outline-none
        disabled:cursor-not-allowed disabled:opacity-50
        ${isSelected ? 'bg-gray-100 dark:bg-gray-700' : ''}
        ${className}
      `}
    >
      <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
        {isSelected && <Check className="h-4 w-4" />}
      </span>
      {children}
    </button>
  );
};

interface SelectValueProps {
  placeholder?: string;
  children?: React.ReactNode;
}

export const SelectValue: React.FC<SelectValueProps> = ({ 
  placeholder = 'Select...',
  children
}) => {
  const { value } = useContext(SelectContext);
  return <span className="block truncate">{children || value || placeholder}</span>;
};