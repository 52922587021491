import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../ui/Modal';
import { Button } from '../ui/Button';
import { ModalContext } from '../../App';
import { 
  api, 
  isSimulationQuestion, 
  isMockExamSimulation, 
  isMockExamMCQ,
  QuizQuestionData
} from '../../services/api';

interface QuizHistoryTableEndQuizPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmEnd: () => void;
  quizId: string;
  courseId: string;
}

const QuizHistoryTableEndQuizPopup: React.FC<QuizHistoryTableEndQuizPopupProps> = ({ 
  isOpen, 
  onClose, 
  onConfirmEnd,
  quizId,
  courseId
}) => {
  const navigate = useNavigate();
  const { setIsModalOpen } = useContext(ModalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [quizType, setQuizType] = useState<'mcq' | 'simulation' | null>(null);

  useEffect(() => {
    setIsModalOpen(isOpen);
    return () => setIsModalOpen(false);
  }, [isOpen, setIsModalOpen]);

  useEffect(() => {
    const determineQuizType = async () => {
      if (!quizId) return;
      try {
        const quizHistory = await api.getQuizHistoryById(quizId);
        const firstQuestion = quizHistory.questions_data[0];
        const isSimulation = isSimulationQuestion(firstQuestion) || isMockExamSimulation(firstQuestion);
        setQuizType(isSimulation ? 'simulation' : 'mcq');
      } catch (err) {
        console.error('Error determining quiz type:', err);
      }
    };

    if (isOpen) {
      determineQuizType();
    }
  }, [quizId, isOpen]);

  const handleClose = () => {
    setIsModalOpen(false);
    setError(null);
    onClose();
  };

  const handleConfirmEnd = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const quizHistory = await api.getQuizHistoryById(quizId);
      if (!quizHistory) {
        throw new Error('Quiz history not found');
      }

      const firstQuestion = quizHistory.questions_data[0];
      const isMockExam = isMockExamMCQ(firstQuestion) || isMockExamSimulation(firstQuestion);
      const mockExamId = isMockExam && 'mockExamId' in firstQuestion ? firstQuestion.mockExamId : undefined;

      if (!isMockExam || !mockExamId || !quizHistory.mock_exam_testlet_id) {
        // Handle non-mock exam quizzes
        const totalTime = quizHistory.questions_data
          .filter(q => q.answered)
          .reduce((sum, q) => sum + (q.timeSpent || 0), 0);

        if (quizType === 'simulation') {
          await api.endTBSSession(quizId, totalTime);
        } else {
          await api.endQuizSession(quizId, totalTime, true);
        }
        
        setIsModalOpen(false);
        onConfirmEnd();
        return;
      }

      // Get current testlet info
      const currentTestlet = await api.getMockExamTestletById(quizHistory.mock_exam_testlet_id);
      
      // Get all testlets to find the next one
      const allTestlets = await api.getMockExamTestlets(mockExamId);
      const nextTestlet = allTestlets.find(t => 
        t.testlet_number > currentTestlet.testlet_number && 
        !t.completed
      );

      // Calculate and end current testlet
      const totalTime = quizHistory.questions_data
        .filter(q => q.answered)
        .reduce((sum, q) => sum + (q.timeSpent || 0), 0);

      if (quizType === 'simulation') {
        await api.endTBSSession(quizId, totalTime);
      } else {
        await api.endQuizSession(quizId, totalTime, true);
      }

      // Update testlet completion
      await api.completeTestlet(
        quizHistory.mock_exam_testlet_id,
        0, // Score will be calculated from quiz history
        totalTime,
        quizId
      );
      
      // Special handling for testlet 3 completion (pause timer)
      if (currentTestlet.testlet_number === 3) {
        // Set timer pause state in localStorage
        localStorage.setItem('mockExamTimerPaused', 'true');
        const elapsedSeconds = Math.floor(Date.now() / 1000) - Math.floor(new Date(quizHistory.start_time).getTime() / 1000);
        localStorage.setItem('mockExamElapsedTime', elapsedSeconds.toString());
      }

      // Navigate to mock exam page with next testlet in state
      navigate(`/course/${courseId}/mock-exam/${mockExamId}`, {
        replace: true,
        state: { 
          scrollToTestlet: nextTestlet ? nextTestlet.testlet_number : currentTestlet.testlet_number + 1 
        }
      });

      setIsModalOpen(false);

    } catch (err) {
      console.error('Error ending quiz session:', err);
      setError(err instanceof Error ? err.message : 'Failed to end quiz session');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg w-full max-w-[90%] sm:max-w-md">
        <h2 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 text-gray-800 dark:text-white">
          End {quizType === 'simulation' ? 'Simulation' : 'Quiz'} Session
        </h2>
        <p className="mb-4 text-sm sm:text-base text-gray-600 dark:text-gray-300">
          Are you sure you want to end this {quizType === 'simulation' ? 'simulation' : 'quiz'} session? 
          This action cannot be undone.
        </p>
        {error && (
          <p className="mb-4 text-sm text-red-500 dark:text-red-400">
            {error}
          </p>
        )}
        <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
          <Button
            onClick={handleConfirmEnd}
            disabled={isLoading}
            className={`w-full sm:w-auto bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-200 ${
              isLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isLoading ? 'Ending...' : 'End Session'}
          </Button>
          <Button
            onClick={handleClose}
            disabled={isLoading}
            variant="outline"
            className={`w-full sm:w-auto bg-transparent hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 hover:text-gray-900 dark:hover:text-white ${
              isLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default QuizHistoryTableEndQuizPopup;