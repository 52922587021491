import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { api, LessonCategory, Lesson, UserLessonResponse, TIER_IDS } from '../../services/api';
import DOMPurify from 'dompurify';
import { Menu, ArrowLeft, ChevronDown, ChevronUp, ChevronRight, CheckCircle, Edit, ChevronLeft, ArrowRight, Filter, Lock, Crown, Sparkle, BookOpen, Scale, Shuffle, Target, BrainCircuit, MessageCircle, AlertCircleIcon } from 'lucide-react';
import LessonNotes from './LessonNotes';
import LoadingScreen from './common/LoadingScreen';
import UpgradeModal from './common/UpgradeModal';
import FeedbackPopup from './quiz-tools/FeedbackPopup';
import { useAuth } from '../AuthProvider';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import { Button } from '../ui/Button';
import { ToggleGroup, ToggleGroupItem } from '../ui/ToggleGroup';

interface LessonData extends Lesson {
  is_completed: boolean;
  confidence_level: string | null;
  content: string | null;
}

interface LessonsProps {
  courseId: string;
  userId: string;
  studyTaskId?: string;  // renamed from item_id
  lessonId?: string;
}

interface LessonConfidenceStats {
  total: number;
  confident: number;
  maybe: number;
  guessing: number;
  not_rated: number;
}

const LOCAL_STORAGE_KEY = 'lessonNotes';

const Lessons: React.FC<LessonsProps> = React.memo(({ courseId, userId, studyTaskId, lessonId: propLessonId }) => {
  const location = useLocation();
  const { lessonId: urlLessonId } = useParams<{ lessonId?: string }>();
  const navigate = useNavigate();
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [specialOfferProductId, setSpecialOfferProductId] = useState<string | undefined>(undefined);
  
  // Replace useExtendedUser with useAuth
  const { user, loading: authLoading } = useAuth();
  const { userCourseAccesses, isLoading: accessLoading } = useUserCourseAccess();
  const queryClient = useQueryClient();
  const notesRef = useRef<HTMLDivElement>(null);
  const MemoizedLessonNotes = React.memo(LessonNotes);

  // State management
  const [selectedLessonId, setSelectedLessonId] = useState<string | null>(null);
  const [expandedCategories, setExpandedCategories] = useState<Set<string>>(new Set());
  const [showCategoryMenu, setShowCategoryMenu] = useState(false);
  const [selectedConfidenceLevel, setSelectedConfidenceLevel] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [unsavedNotes, setUnsavedNotes] = useState<Record<string, string>>({});
  const [activeFilters, setActiveFilters] = useState<Set<string>>(new Set(['guessing', 'maybe', 'confident', 'not_rated']));
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  // Update the lessons query key to include userId
  const lessonsQueryKey = ['lessons', courseId, userId, 'completionStatus'];

  // Add new state for feedback popup
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);

  // Fetch lesson categories and lessons using React Query
  const { data: lessonCategories = [], isLoading: categoriesLoading } = useQuery<LessonCategory[]>(
    ['lessonCategories', courseId],
    () => api.getLessonCategories(courseId),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  const { data: lessons = [], isLoading: lessonsLoading } = useQuery<LessonData[]>(
    lessonsQueryKey,
    () => api.getLessons(courseId, userId),
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  );

  // Combined loading state
  const isLoading = authLoading || accessLoading || categoriesLoading || lessonsLoading;

  // Load unsaved notes from local storage
  useEffect(() => {
    const storedNotes = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedNotes) {
      setUnsavedNotes(JSON.parse(storedNotes));
    }
  }, []);

  // Save unsaved notes to local storage
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(unsavedNotes));
  }, [unsavedNotes]);

  // New function to process URLs
  const processUrls = useCallback((content: string) => {
    return content.replace(/https:\/\/app\.keslercpareview\.com\/course\/{{courseId}}/g, `https://app.keslercpareview.com/course/${courseId}`);
  }, [courseId]);
  
  // Memoize selected lesson
  const selectedLesson = useMemo(() => 
    lessons.find(l => l.id === selectedLessonId) || null,
    [lessons, selectedLessonId]
  );

  // Effects for setting initial selected lesson and confidence level
  useEffect(() => {
    const lessonIdToUse = urlLessonId || propLessonId;
    if (lessonIdToUse && lessonIdToUse !== selectedLessonId) {
      setSelectedLessonId(lessonIdToUse);
      const lesson = lessons.find(l => l.id === lessonIdToUse);
      if (lesson) {
        setSelectedConfidenceLevel(lesson.confidence_level);
      }
    }
  }, [urlLessonId, propLessonId, lessons, selectedLessonId]);

  useEffect(() => {
    if (selectedLesson) {
      setSelectedConfidenceLevel(selectedLesson.confidence_level);
    }
  }, [selectedLesson]);

  // Memoize category completion status
  const categoryCompletionStatus = useMemo(() => {
    const status: Record<string, { completed: number, total: number }> = {};
    lessons.forEach(lesson => {
      if (lesson.lesson_category_id) {
        if (!status[lesson.lesson_category_id]) {
          status[lesson.lesson_category_id] = { completed: 0, total: 0 };
        }
        status[lesson.lesson_category_id].total++;
        if (lesson.is_completed) {
          status[lesson.lesson_category_id].completed++;
        }
      }
    });
    return status;
  }, [lessons]);

  // Calculate overall lesson statistics
  const overallLessonStats = useMemo(() => {
    return lessons.reduce((stats, lesson) => {
      stats.total++;
      
      if (lesson.is_completed) {
        stats.completed++;
        
        switch(lesson.confidence_level) {
          case 'confident':
            stats.confident++;
            break;
          case 'maybe':
            stats.maybe++;
            break;
          case 'guessing':
            stats.guessing++;
            break;
        }
      }
      
      return stats;
    }, {
      total: 0,
      completed: 0,
      confident: 0,
      maybe: 0,
      guessing: 0
    });
  }, [lessons]);

    // Memoize the sorted lessons array
  // This array will be sorted first by category_number, then by sequence_number
  const sortedLessons = useMemo(() => {
    return [...lessons].sort((a, b) => {
      // First, sort by category_number
      const categoryA = lessonCategories.find(cat => cat.id === a.lesson_category_id);
      const categoryB = lessonCategories.find(cat => cat.id === b.lesson_category_id);
      if (categoryA && categoryB) {
        if (categoryA.category_number !== categoryB.category_number) {
          return categoryA.category_number - categoryB.category_number;
        }
      }
      // If categories are the same or not found, sort by sequence_number
      return (a.sequence_number || 0) - (b.sequence_number || 0);
    });
  }, [lessons, lessonCategories]);

  const handleNoteChange = useCallback((lessonId: string, content: string) => {
    setUnsavedNotes(prev => ({
      ...prev,
      [lessonId]: content
    }));
  }, []);

  const handleNoteSave = useCallback(async (lessonId: string, content: string) => {
    if (!userId || !courseId) return;
  
    try {
      await api.updateNote(null, { content }, userId, courseId, null, lessonId, null, null);
      setUnsavedNotes(prev => {
        const newUnsavedNotes = { ...prev };
        delete newUnsavedNotes[lessonId];
        return newUnsavedNotes;
      });
      queryClient.invalidateQueries(['lessonNote', userId, courseId, lessonId]);
    } catch (err) {
      console.error('Failed to save note:', err);
      setError('Failed to save note. Please try again.');
    }
  }, [userId, courseId, queryClient]);


  // Handlers
  const handleLessonClick = useCallback((lessonId: string) => {
    setSelectedLessonId(lessonId);
    setShowCategoryMenu(false);
    navigate(`/course/${courseId}/lessons/${lessonId}`, { replace: true });
  }, [courseId, navigate]);

  const handleBackToLessons = useCallback(() => {
    setSelectedLessonId(null);
    setShowCategoryMenu(false);
    setSelectedConfidenceLevel(null);
    navigate(`/course/${courseId}/lessons`, { replace: true });
  }, [courseId, navigate]);

  const handleCategoryClick = useCallback((categoryId: string) => {
    setExpandedCategories(prev => {
      const newSet = new Set(prev);
      if (newSet.has(categoryId)) {
        newSet.delete(categoryId);
      } else {
        newSet.add(categoryId);
      }
      return newSet;
    });
  }, []);

  // Update handleConfidenceLevelChange to immediately update the local cache
  const handleConfidenceLevelChange = useCallback(async (level: string) => {
    if (!selectedLesson || !userId || !courseId) return;

    // Optimistically update the cache
    queryClient.setQueryData<LessonData[]>(lessonsQueryKey, (oldData) => {
      if (!oldData) return [];
      return oldData.map(lesson =>
        lesson.id === selectedLesson.id ? { ...lesson, confidence_level: level } : lesson
      );
    });

    setSelectedConfidenceLevel(level);

    try {
      await api.updateLessonStatus(userId, courseId, selectedLesson.id, selectedLesson.is_completed, level);
      // If the API call is successful, we don't need to do anything else
      // as we've already updated the cache
    } catch (err) {
      console.error('Failed to update confidence level:', err);
      setError('Failed to update confidence level. Please try again.');
      
      // Revert the optimistic update in case of an error
      queryClient.setQueryData<LessonData[]>(lessonsQueryKey, (oldData) => {
        if (!oldData) return [];
        return oldData.map(lesson =>
          lesson.id === selectedLesson.id ? { ...lesson, confidence_level: selectedConfidenceLevel } : lesson
        );
      });
    }
  }, [selectedLesson, userId, courseId, queryClient, lessonsQueryKey, selectedConfidenceLevel]);

  // Update handleLessonCompletion to also update confidence_level in the cache
  const handleLessonCompletion = useCallback(async (lessonId: string, isCompleted: boolean) => {
    if (!userId || !courseId || !selectedConfidenceLevel) return;

    // Optimistically update the cache
    queryClient.setQueryData<LessonData[]>(lessonsQueryKey, (oldData) => {
      if (!oldData) return [];
      return oldData.map(lesson =>
        lesson.id === lessonId ? { ...lesson, is_completed: isCompleted, confidence_level: selectedConfidenceLevel } : lesson
      );
    });

    try {
      await api.updateLessonStatus(userId, courseId, lessonId, isCompleted, selectedConfidenceLevel);
      // If the API call is successful, we don't need to do anything else
      // as we've already updated the cache
    } catch (err) {
      console.error('Failed to update lesson status:', err);
      setError('Failed to update lesson status. Please try again.');
      
      // Revert the optimistic update in case of an error
      queryClient.setQueryData<LessonData[]>(lessonsQueryKey, (oldData) => {
        if (!oldData) return [];
        return oldData.map(lesson =>
          lesson.id === lessonId ? { ...lesson, is_completed: !isCompleted } : lesson
        );
      });
    }
  }, [userId, courseId, selectedConfidenceLevel, queryClient, lessonsQueryKey]);

  const scrollToNotes = useCallback(() => {
    notesRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);



  // Update getNextLesson to use sortedLessons
  const getNextLesson = useCallback((): LessonData | null => {
    if (!selectedLesson) return null;
    const currentIndex = sortedLessons.findIndex(lesson => lesson.id === selectedLesson.id);
    return sortedLessons[currentIndex + 1] || null;
  }, [selectedLesson, sortedLessons]);

  const handleNextLesson = useCallback(() => {
    const nextLesson = getNextLesson();
    if (nextLesson) {
      handleLessonClick(nextLesson.id);
    }
  }, [getNextLesson, handleLessonClick]);

  // Update getPreviousLesson to use sortedLessons
  const getPreviousLesson = useCallback((): LessonData | null => {
    if (!selectedLesson) return null;
    const currentIndex = sortedLessons.findIndex(lesson => lesson.id === selectedLesson.id);
    return sortedLessons[currentIndex - 1] || null;
  }, [selectedLesson, sortedLessons]);

  const handlePreviousLesson = useCallback(() => {
    const previousLesson = getPreviousLesson();
    if (previousLesson) {
      handleLessonClick(previousLesson.id);
    }
  }, [getPreviousLesson, handleLessonClick]);

  const toggleAllCategories = useCallback(() => {
    if (expandedCategories.size === lessonCategories.length) {
      setExpandedCategories(new Set());
    } else {
      setExpandedCategories(new Set(lessonCategories.map(cat => cat.id)));
    }
  }, [expandedCategories, lessonCategories]);

   // Get study task ID from either props or location state
  const effectiveStudyTaskId = useMemo(() => {
    if (studyTaskId) return studyTaskId;
    if (location.state && location.state.studyTaskId) return location.state.studyTaskId;
    return null;
  }, [studyTaskId, location.state]);

  const handleBackToStudyTask = useCallback(() => {
    if (studyTaskId) {
      // Use direct navigation state instead of URL parameters
      navigate(`/course/${courseId}/study-plan/${studyTaskId}`, {
        state: { returnedFrom: 'lesson' },
        replace: true // Use replace to prevent navigation history issues
      });
    }
  }, [studyTaskId, navigate, courseId]);
  
    // Update lessonConfidenceLevels to use the latest data from the cache
    const lessonConfidenceLevels = useMemo(() => {
      const cachedLessons = queryClient.getQueryData<LessonData[]>(lessonsQueryKey) || [];
      const map = new Map<string, string>();
      cachedLessons.forEach(lesson => {
        map.set(lesson.id, lesson.confidence_level || 'not_rated');
      });
      return map;
    }, [queryClient, lessonsQueryKey]);
  
    // Function to toggle filters
    const toggleFilter = useCallback((filter: string) => {
      setActiveFilters(prev => {
        const newFilters = new Set(prev);
        if (newFilters.has(filter)) {
          newFilters.delete(filter);
        } else {
          newFilters.add(filter);
        }
        return newFilters;
      });
    }, []);
  
        // Helper function to check if a category has visible lessons
    const categoryHasVisibleLessons = useCallback((categoryId: string) => {
      return lessons.some(lesson => 
        lesson.lesson_category_id === categoryId && 
        activeFilters.has(lessonConfidenceLevels.get(lesson.id) || 'not_rated')
      );
    }, [lessons, activeFilters, lessonConfidenceLevels]);

    // Placeholder message component
    const NoLessonsPlaceholder = () => (
      <div className="p-4 bg-gray-100 dark:bg-gray-700 rounded-lg text-center">
        <p className="text-gray-600 dark:text-gray-400">
          No lessons available with current filtering settings
        </p>
      </div>
    );

  // Update the getColor function to include hover states
  const getColor = useCallback((lessonId: string) => {
    const lesson = queryClient.getQueryData<LessonData[]>(lessonsQueryKey)?.find(l => l.id === lessonId);
    if (!lesson || !lesson.is_completed) return 'bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700';
    
    switch (lesson.confidence_level) {
      case 'confident':
        return 'bg-green-500 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700';
      case 'maybe':
        return 'bg-yellow-500 hover:bg-yellow-600 dark:bg-yellow-600 dark:hover:bg-yellow-700';
      case 'guessing':
        return 'bg-red-500 hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700';
      default:
        return 'bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700';
    }
  }, [queryClient, lessonsQueryKey]);
  
    const getFilterButtonColor = (level: string, isActive: boolean) => {
      const baseClasses = 'px-4 py-2 rounded-full transition-colors duration-200 ';
      if (isActive) {
        switch (level) {
          case 'confident': return `${baseClasses} bg-green-500 text-white`;
          case 'maybe': return `${baseClasses} bg-orange-500 text-white`;
          case 'guessing': return `${baseClasses} bg-yellow-500 text-white`;
          case 'not_rated': return `${baseClasses} bg-blue-500 text-white`;
          default: return `${baseClasses} bg-gray-500 text-white`;
        }
      } else {
        switch (level) {
          case 'confident': return `${baseClasses} bg-transparent text-green-500 border border-green-500`;
          case 'maybe': return `${baseClasses} bg-transparent text-orange-500 border border-orange-500`;
          case 'guessing': return `${baseClasses} bg-transparent text-yellow-500 border border-yellow-500`;
          case 'not_rated': return `${baseClasses} bg-transparent text-blue-500 border border-blue-500`;
          default: return `${baseClasses} bg-transparent text-gray-500 border border-gray-500`;
        }
      }
    };


    // Getting User Tier Access
    // Add new access control logic
    const hasFullLessonAccess = useMemo(() => {
      if (!userCourseAccesses || userCourseAccesses.length === 0) return false;
      
      const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
      if (courseAccesses.length === 0) return false;

      // Check for full access scenarios
      const hasFullAccess = courseAccesses.some(access => 
        access.tier_id === TIER_IDS.FULL_ACCESS || 
        access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY
      );

      // Check for combination of Mentor Only + Study Materials Only
      const hasMentorAccess = courseAccesses.some(access => access.tier_id === TIER_IDS.MENTOR_ONLY);
      const hasStudyMaterialsAccess = courseAccesses.some(access => access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY);

      return hasFullAccess || (hasMentorAccess && hasStudyMaterialsAccess);
    }, [userCourseAccesses, courseId]);

    // Update upgrade button visibility check
    const showUpgradeButton = useMemo(() => {
      if (!userCourseAccesses || userCourseAccesses.length === 0) {
        return true;
      }
      
      const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
      if (courseAccesses.length === 0) {
        console.warn(`No access found for course ID: ${courseId}`);
        return true;
      }

      const hasFullAccess = courseAccesses.some(access => 
        access.tier_id === TIER_IDS.FULL_ACCESS || 
        access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY
      );

      if (hasFullAccess) {
        return false;
      }

      // Show upgrade button for restricted access tiers
      const onlyHasRestrictedAccess = courseAccesses.every(access =>
        access.tier_id === TIER_IDS.FREE_TRIAL || 
        access.tier_id === TIER_IDS.MENTOR_ONLY
      );

      return onlyHasRestrictedAccess;
    }, [userCourseAccesses, courseId]);

    // Update content lock check
    const isContentLocked = useCallback((lesson: LessonData) => {
      if (!userCourseAccesses || userCourseAccesses.length === 0) return true;
      
      const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
      if (courseAccesses.length === 0) return true;

      // Allow access for full access tiers
      const hasFullAccess = courseAccesses.some(access => 
        access.tier_id === TIER_IDS.FULL_ACCESS || 
        access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY
      );

      if (hasFullAccess) return false;

      // Check for Mentor + Study Materials combination
      const hasMentorAccess = courseAccesses.some(access => access.tier_id === TIER_IDS.MENTOR_ONLY);
      const hasStudyMaterialsAccess = courseAccesses.some(access => access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY);
      
      if (hasMentorAccess && hasStudyMaterialsAccess) return false;

      // For restricted access, only show free trial content
      return !lesson.free_trial_content;
    }, [userCourseAccesses, courseId]);

    //UPGRADE POPUP MODAL
    useEffect(() => {
      const checkSpecialOffer = async () => {
        if (user && user.last_sign_in_at) {
          const lastSignIn = new Date(user.last_sign_in_at);
          const thirtyDaysAgo = new Date();
          thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  
          if (lastSignIn < thirtyDaysAgo) {
            setSpecialOfferProductId("special_offer_product_id");
            setIsUpgradeModalOpen(true);
          }
        }
      };
  
      checkSpecialOffer();
    }, [user]);
  
    const handleUpgradeClick = useCallback(() => {
      console.log('Upgrade button clicked');
      setIsUpgradeModalOpen(prev => {
        console.log('Setting isUpgradeModalOpen to:', !prev);
        return !prev;
      });
    }, []);
  
    const handleCloseUpgradeModal = useCallback(() => {
      console.log('Closing upgrade modal');
      setIsUpgradeModalOpen(false);
    }, []);

  // Add this function before the Lessons component
  const calculateCategoryStats = (
    lessons: LessonData[],
    categoryId: string
  ): LessonConfidenceStats => {
    const categoryLessons = lessons.filter(lesson => lesson.lesson_category_id === categoryId);
    
    return categoryLessons.reduce((acc, lesson) => {
      acc.total++;
      
      switch(lesson.confidence_level) {
        case 'confident':
          acc.confident++;
          break;
        case 'maybe':
          acc.maybe++;
          break;
        case 'guessing':
          acc.guessing++;
          break;
        default:
          acc.not_rated++;
      }
      
      return acc;
    }, {
      total: 0,
      confident: 0,
      maybe: 0,
      guessing: 0,
      not_rated: 0
    });
  };

  // Add this inside the Lessons component
  const categoryStats = useMemo(() => {
    const statsMap = new Map<string, LessonConfidenceStats>();
    
    // Calculate stats for each category
    lessonCategories.forEach(category => {
      statsMap.set(category.id, calculateCategoryStats(lessons, category.id));
    });
    
    // Calculate parent category totals
    lessonCategories.forEach(category => {
      if (category.parent_lesson_category_id) {
        const parentStats = statsMap.get(category.parent_lesson_category_id) || {
          total: 0,
          confident: 0,
          maybe: 0,
          guessing: 0,
          not_rated: 0
        };
        const childStats = statsMap.get(category.id) || {
          total: 0,
          confident: 0,
          maybe: 0,
          guessing: 0,
          not_rated: 0
        };
        
        statsMap.set(category.parent_lesson_category_id, {
          total: parentStats.total + childStats.total,
          confident: parentStats.confident + childStats.confident,
          maybe: parentStats.maybe + childStats.maybe,
          guessing: parentStats.guessing + childStats.guessing,
          not_rated: parentStats.not_rated + childStats.not_rated
        });
      }
    });
    
    return statsMap;
  }, [lessonCategories, lessons]);

  {/* Header section - Keep the blue gradient */}
<div className="bg-gradient-to-r from-primary-blue to-blue-600 rounded-lg shadow-sm p-4">
  <div className="flex flex-col space-y-4">
    <h3 className="text-white text-lg font-semibold">Lesson Categories Overview</h3>
    {/* Stats go here */}
  </div>
</div>

{/* Update renderCategories to use white/gray backgrounds */}
const renderCategories = useMemo(() => {
  const render = (parentCategoryId: string | null = null, depth: number = 0) => {
    const categories = lessonCategories.filter(cat => cat.parent_lesson_category_id === parentCategoryId);

    return categories.map(category => {
      const stats = categoryStats.get(category.id) || {
        total: 0,
        confident: 0,
        maybe: 0,
        guessing: 0,
        not_rated: 0
      };

      return (
        <div key={category.id} className="mb-4">
          <div
            className={`
              ${depth === 0 ? 'bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700' : 'bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700'}
              cursor-pointer transition-colors duration-200 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700
            `}
            onClick={() => handleCategoryClick(category.id)}
          >
            <div className="flex justify-between items-center p-4">
              <span className="font-medium text-gray-800 dark:text-gray-200 flex items-center">
                {expandedCategories.has(category.id) ? 
                  <ChevronDown size={20} /> : 
                  <ChevronRight size={20} />
                }
                <span className="ml-2">{category.name}</span>
              </span>
              
              <div className="flex items-center gap-2">
                {stats.confident > 0 && (
                  <span className="bg-green-100 dark:bg-green-600/50 text-green-800 dark:text-green-100 rounded px-2 py-1 text-xs font-medium flex items-center gap-1">
                    <Sparkle className="w-3 h-3" />
                    {stats.confident}
                  </span>
                )}
                {stats.maybe > 0 && (
                  <span className="bg-yellow-100 dark:bg-yellow-600/50 text-yellow-800 dark:text-yellow-100 rounded px-2 py-1 text-xs font-medium flex items-center gap-1">
                    <Scale className="w-3 h-3" />
                    {stats.maybe}
                  </span>
                )}
                {stats.guessing > 0 && (
                  <span className="bg-red-100/90 text-red-900 dark:bg-red-600/50 dark:text-red-100 rounded px-2 py-1 text-xs font-medium flex items-center gap-1">
                    <Shuffle className="w-3 h-3" />
                    {stats.guessing}
                  </span>
                )}
                <span className="text-sm bg-primary-yellow text-black font-medium px-3 py-1 rounded-full ml-2 whitespace-nowrap">
                  {categoryCompletionStatus[category.id]?.completed || 0}/{stats.total}
                </span>
              </div>
            </div>
          </div>

          {expandedCategories.has(category.id) && (
            <div className="ml-4 mt-2 space-y-2">
              {render(category.id, depth + 1)}
              {categoryHasVisibleLessons(category.id) ? (
                <div className="grid gap-2">
                  {lessons
                    .filter(lesson => lesson.lesson_category_id === category.id)
                    .map((lesson) => {
                      const confidenceLevel = lessonConfidenceLevels.get(lesson.id) || 'not_rated';
                      if (!activeFilters.has(confidenceLevel)) return null;
                      const isLocked = isContentLocked(lesson);

                      return (
                        <div
                          key={lesson.id}
                          className={`
                            p-4 rounded-lg cursor-pointer transition-colors duration-200 border border-gray-200 dark:border-gray-700
                            ${getColor(lesson.id)}
                          `}
                          onClick={() => handleLessonClick(lesson.id)}
                        >
                          <div className="flex justify-between items-center">
                            <span className={lesson.is_completed ? 'text-white' : 'text-gray-800 dark:text-gray-200'}>
                              {lesson.name}
                            </span>
                            <div className="flex items-center gap-2">
                              {lesson.is_completed && (
                                <div className="flex gap-1">
                                  {confidenceLevel === 'confident' && (
                                    <span className="bg-green-400 dark:bg-green-500 text-white rounded px-2 py-1 text-xs font-medium flex items-center gap-1">
                                      <Sparkle className="w-3 h-3" />
                                      Confident
                                    </span>
                                  )}
                                  {confidenceLevel === 'maybe' && (
                                    <span className="bg-yellow-400 dark:bg-yellow-500 text-white rounded px-2 py-1 text-xs font-medium flex items-center gap-1">
                                      <Scale className="w-3 h-3" />
                                      Maybe
                                    </span>
                                  )}
                                  {confidenceLevel === 'guessing' && (
                                    <span className="bg-red-400 dark:bg-red-500 text-white rounded px-2 py-1 text-xs font-medium flex items-center gap-1">
                                      <Shuffle className="w-3 h-3" />
                                      Guessing
                                    </span>
                                  )}
                                </div>
                              )}
                              {isLocked ? (
                                <Lock size={16} className="text-gray-400" />
                              ) : (
                                lesson.is_completed && (
                                  <CheckCircle size={20} className="text-white" />
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg text-center">
                  <p className="text-gray-500 dark:text-gray-400 text-sm">
                    No lessons available with current filtering settings
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      );
    });
  };

  return render;
}, [
  lessonCategories,
  expandedCategories,
  lessons,
  handleLessonClick,
  categoryCompletionStatus,
  categoryStats,
  handleCategoryClick,
  categoryHasVisibleLessons,
  lessonConfidenceLevels,
  activeFilters,
  getColor,
  isContentLocked
]);
    
    
  
  // Update the filter logic to handle single selection
  const handleFilterChange = useCallback((value: string) => {
    if (value) {  // Only update if a value is selected (prevents deselection)
      setSelectedFilter(value);
      // Convert single selection to set of active filters
      if (value === 'all') {
        setActiveFilters(new Set(['confident', 'maybe', 'guessing', 'not_rated']));
      } else {
        setActiveFilters(new Set([value]));
      }
    }
  }, []);

  const renderCategoryMenu = useCallback(() => (
    <div className="mb-6 p-6 bg-grey-50 dark:bg-dark-secondary rounded-lg">

      <h3 className="text-xl font-bold mb-4 text-light-text dark:text-dark-text">Lesson Categories</h3>
      <ul className="w-full space-y-2">
        {renderCategories()}
      </ul>
    </div>
  ), [handleBackToLessons, renderCategories]);

  const renderLessonContent = useCallback((lesson: LessonData) => {
    const isLocked = isContentLocked(lesson);
  
    return (
      <div className="bg-white dark:bg-gray-800 p-2 md:p-4 rounded-lg shadow-md">
        <div className="flex flex-col space-y-2 mb-8">
          {/* Navigation buttons row */}
          <div className="flex items-center justify-between">
            {/* Back to Lessons Button - Left Aligned */}
            <button
              onClick={handleBackToLessons}
              className="flex items-center text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors duration-200"
            >
              <ChevronLeft size={24} />
              <span className="ml-2 text-lg">Back</span>
            </button>
    
            {/* Other Buttons - Right Aligned */}
            <div className="flex items-center space-x-4">
              <button
                onClick={scrollToNotes}
                className="w-12 h-12 flex items-center justify-center rounded-full bg-yellow-100 dark:bg-yellow-900 text-yellow-700 dark:text-yellow-200 hover:bg-yellow-200 dark:hover:bg-yellow-800 transition-colors duration-200"
                title="Go to Notes"
              >
                <Edit size={24} />
              </button>
              <button
                onClick={() => setShowCategoryMenu(!showCategoryMenu)}
                className="w-12 h-12 flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-200"
                title="Toggle Category Menu"
              >
                <Menu size={24} />
              </button>
              <button
                onClick={handlePreviousLesson}
                disabled={!getPreviousLesson()}
                className="w-12 h-12 sm:w-auto sm:h-auto sm:px-6 sm:py-3 flex items-center justify-center rounded-full sm:rounded-md bg-blue-500 dark:bg-blue-700 text-white hover:bg-blue-600 dark:hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                title="Previous Lesson"
              >
                <ArrowLeft size={24} />
              </button>
              <button
                onClick={handleNextLesson}
                disabled={!getNextLesson()}
                className="w-12 h-12 sm:w-auto sm:h-auto sm:px-6 sm:py-3 flex items-center justify-center rounded-full sm:rounded-md bg-blue-500 dark:bg-blue-700 text-white hover:bg-blue-600 dark:hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                title="Next Lesson"
              >
                <ArrowRight size={24} />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFeedbackPopup(true);
                }}
                className="w-12 h-12 flex items-center justify-center rounded-full bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-200 hover:bg-red-200 dark:hover:bg-red-800 transition-colors duration-200"
                title="Report an Issue"
              >
                <AlertCircleIcon size={24} />
              </button>
            </div>
          </div>
  
          {/* Category menu */}
          {showCategoryMenu && (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden max-w-[1200px] mx-auto">
              {renderCategoryMenu()}
            </div>
          )}
  
          {/* Lesson title row */}
          <h3 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800 dark:text-white text-center py-4 sm:py-6 px-4 sm:px-6 bg-gray-50 dark:bg-gray-800 rounded-lg shadow-sm border border-gray-400 dark:border-gray-700">
            {lesson.name}
          </h3>
  
           {/* Lesson content */}
            <div className="p-4 md:p-8 lg:p-12 relative min-h-[500px] shadow-md rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700">
              <div className={`prose dark:prose-invert prose-sm sm:prose-base lg:prose-lg max-w-none ${isLocked ? 'blur-sm' : ''}`}>
                {isLocked ? (
                  <div className="text-center">
                    <h3>This content is hidden</h3>
                    <p>You need to upgrade to see this content</p>
                    <p>Click the upgrade button now to add a full membership to Kesler CPA Review</p>
                  </div>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(processUrls(lesson.content || '')) }} />
                )}
              </div>
              {isLocked && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                  <div className="text-center text-white p-6 rounded-lg">
                    <Lock size={48} className="mx-auto mb-4" />
                    <p className="text-xl font-semibold mb-4">Content is locked</p>
                    <p className="mb-6">Upgrade your account to access this lesson.</p>
                    <div className="flex justify-center">
                      <Button
                        onClick={handleUpgradeClick}
                        className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center"
                      >
                        <Crown size={16} className="mr-2" /> {/* Add Crown icon */}
                        Upgrade Now
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
        </div>

        {/* Add FeedbackPopup component */}
        <FeedbackPopup
          isOpen={showFeedbackPopup}
          onClose={() => setShowFeedbackPopup(false)}
          contentId={lesson.id}
          contentType="lessons"
          contentTitle={`${lesson.lesson_category_name || ''} - ${lesson.name}`}
        />

        {/* Confidence rating and completion buttons */}
        {!isLocked && (
          <div className="mt-6 sm:mt-8 p-4 sm:p-6 bg-gray-50 dark:bg-gray-700 rounded-lg shadow-md border border-gray-400 dark:border-gray-700">
            <h4 className="text-lg sm:text-xl font-semibold mb-4 sm:mb-6 text-gray-800 dark:text-white text-center">
              To complete the lesson, rate your confidence on this topic
            </h4>
            <div className="flex flex-col sm:flex-row justify-center gap-3 sm:gap-4 mb-4 sm:mb-6">
              {/* Guessing Button */}
              <button
                onClick={() => handleConfidenceLevelChange('guessing')}
                className={`flex items-center justify-center px-4 sm:px-6 py-2 sm:py-3 rounded-md text-sm sm:text-base font-medium transition-colors duration-200 ${
                  selectedConfidenceLevel === 'guessing'
                    ? 'bg-red-500 text-white dark:bg-red-500 dark:text-white'
                    : 'bg-red-100 text-red-900 dark:bg-red-900/50 dark:text-red-200 hover:bg-red-200 dark:hover:bg-red-500/30'
                } border-2 ${
                  selectedConfidenceLevel === 'guessing'
                    ? 'border-transparent'
                    : 'border-red-500/30 dark:border-red-500/50'
                }`}
              >
                <Shuffle className="w-4 h-4 mr-2" />
                Guessing
              </button>

              {/* Maybe Button */}
              <button
                onClick={() => handleConfidenceLevelChange('maybe')}
                className={`flex items-center justify-center px-4 sm:px-6 py-2 sm:py-3 rounded-md text-sm sm:text-base font-medium transition-colors duration-200 ${
                  selectedConfidenceLevel === 'maybe'
                    ? 'bg-yellow-500 text-white dark:bg-yellow-500 dark:text-white'
                    : 'bg-yellow-100 text-yellow-900 dark:bg-yellow-900/50 dark:text-yellow-200 hover:bg-yellow-200 dark:hover:bg-yellow-500/30'
                } border-2 ${
                  selectedConfidenceLevel === 'maybe'
                    ? 'border-transparent'
                    : 'border-yellow-500/30 dark:border-yellow-500/50'
                }`}
              >
                <Scale className="w-4 h-4 mr-2" />
                Maybe
              </button>

              {/* Confident Button */}
              <button
                onClick={() => handleConfidenceLevelChange('confident')}
                className={`flex items-center justify-center px-4 sm:px-6 py-2 sm:py-3 rounded-md text-sm sm:text-base font-medium transition-colors duration-200 ${
                  selectedConfidenceLevel === 'confident'
                    ? 'bg-green-500 text-white dark:bg-green-500 dark:text-white'
                    : 'bg-green-100 text-green-900 dark:bg-green-900/50 dark:text-green-200 hover:bg-green-200 dark:hover:bg-green-500/30'
                } border-2 ${
                  selectedConfidenceLevel === 'confident'
                    ? 'border-transparent'
                    : 'border-green-500/30 dark:border-green-500/50'
                }`}
              >
                <Sparkle className="w-4 h-4 mr-2" />
                Confident
              </button>
            </div>
          <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 items-stretch">
            <button
              onClick={() => handleLessonCompletion(lesson.id, !lesson.is_completed)}
              disabled={!selectedConfidenceLevel}
              className={`flex-grow flex items-center justify-center px-4 sm:px-6 py-3 sm:py-4 rounded-md text-sm sm:text-base font-medium transition-colors duration-200 ${
                lesson.is_completed
                  ? 'bg-green-500 text-white hover:bg-green-600'
                  : 'bg-blue-600 text-white hover:bg-blue-700'
              } disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              {lesson.is_completed ? (
                <>
                  <CheckCircle size={20} className="mr-2" />
                  Completed
                </>
              ) : (
                'Mark as Completed'
              )}
            </button>
            {effectiveStudyTaskId && (
              <button
                onClick={handleBackToStudyTask}
                className="flex-grow sm:flex-grow-0 flex items-center justify-center 
                  px-4 sm:px-6 py-3 sm:py-4 rounded-md text-sm sm:text-base font-medium 
                  bg-red-500 dark:bg-red-600 text-white dark:text-gray-100
                  hover:bg-red-600 dark:hover:bg-red-700 transition-colors duration-200"
              >
                <ArrowLeft className="mr-2" size={20} />
                Back To Study Task
              </button>
            )}
          </div>
        </div>
      )}
      {/* Notes section */}
      {!isLocked && (
        <div ref={notesRef} className="mt-6 sm:mt-8 p-4 sm:p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md border border-gray-400 dark:border-gray-700">
          <MemoizedLessonNotes
            courseId={courseId}
            userId={userId}
            lessonId={lesson.id}
            lessonName={lesson.name}
            lessonCategory={lessonCategories.find(cat => cat.id === lesson.lesson_category_id) || { 
              id: '', 
              course_id: '', 
              name: '', 
              parent_lesson_category_id: null,
              category_number: -1
            }}
            unsavedNote={unsavedNotes[lesson.id]}
            onNoteChange={(content) => handleNoteChange(lesson.id, content)}
            onNoteSave={(content) => handleNoteSave(lesson.id, content)}
          />
        </div>
      )}
    </div>
  );
}, [
  handleBackToLessons,
  scrollToNotes,
  setShowCategoryMenu,
  showCategoryMenu,
  handlePreviousLesson,
  getPreviousLesson,
  handleNextLesson,
  getNextLesson,
  renderCategoryMenu,
  isContentLocked,
  processUrls,
  handleUpgradeClick,
  handleConfidenceLevelChange,
  selectedConfidenceLevel,
  handleLessonCompletion,
  studyTaskId,
  handleBackToStudyTask,
  courseId,
  userId,
  lessonCategories,
  unsavedNotes,
  handleNoteChange,
  handleNoteSave,
  showFeedbackPopup, // Add new dependency
]);
  
  if (isLoading || accessLoading) {
    return <LoadingScreen message="Loading your Lessons..." subMessage="This may take a few moments" />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center p-8 bg-red-100 dark:bg-red-900 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-red-700 dark:text-red-300 mb-4">Error</h2>
          <p className="text-red-600 dark:text-red-400">{error}</p>
        </div>
      </div>
    );
  }

    // Update the main return statement with enhanced UI
    return (
      <div className="p-1 md:p-8 bg-white max-w-[1200px] mx-auto rounded-lg shadow-md border border-gray-400 dark:border-gray-700 ${isDarkMode ? 'bg-gray-800 text-gray-100' : 'bg-white text-gray-900'}">
        <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
          <h2 className="text-2xl font-bold mb-4 sm:mb-0">Lessons To Review</h2>
        </div>
        {!selectedLesson ? (
          <div className="space-y-6">
            {/* Overview Card */}
            <div className="bg-gradient-to-r from-primary-blue to-blue-600 rounded-lg shadow-sm p-4">
              <div className="flex flex-col space-y-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-white text-lg font-semibold">Lessons Overview</h3>
                  <div className="flex items-center gap-1.5">
                    <Target className="w-4 h-4 text-white/90" />
                    <span className="text-white/90 text-sm">Study Progress</span>
                  </div>
                </div>
                
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
                  <div className="bg-white/20 rounded-lg p-3">
                    <div className="text-white/90 text-sm">Total Lessons</div>
                    <div className="text-white text-xl font-bold flex items-center gap-2">
                      <BookOpen className="w-5 h-5" />
                      {overallLessonStats.total}
                    </div>
                  </div>

                  <div className="bg-white/20 rounded-lg p-3">
                    <div className="text-white/90 text-sm">Completed</div>
                    <div className="text-white text-xl font-bold flex items-center gap-2">
                      <CheckCircle className="w-5 h-5" />
                      {overallLessonStats.completed}
                    </div>
                  </div>

                  <div className="bg-white/20 rounded-lg p-3">
                    <div className="text-white/90 text-sm">Completion Rate</div>
                    <div className="text-white text-xl font-bold flex items-center gap-2">
                      <Target className="w-5 h-5" />
                      {((overallLessonStats.completed / overallLessonStats.total) * 100).toFixed(1)}%
                    </div>
                  </div>

                  <div className="bg-white/20 rounded-lg p-3">
                    <div className="text-white/90 text-sm">Confidence Levels</div>
                    <div className="flex gap-1 mt-1">
                      <span className="bg-green-100 dark:bg-green-600/50 text-green-800 dark:text-green-100 rounded px-1.5 py-0.5 text-xs font-medium flex items-center gap-1">
                        <Sparkle className="w-3 h-3" />
                        {overallLessonStats.confident}
                      </span>
                      <span className="bg-yellow-100 dark:bg-yellow-600/50 text-yellow-800 dark:text-yellow-100 rounded px-1.5 py-0.5 text-xs font-medium flex items-center gap-1">
                        <Scale className="w-3 h-3" />
                        {overallLessonStats.maybe}
                      </span>
                      <span className="bg-red-100/90 text-red-900 dark:bg-red-600/50 dark:text-red-100 rounded px-1.5 py-0.5 text-xs font-medium flex items-center gap-1">
                        <Shuffle className="w-3 h-3" />
                        {overallLessonStats.guessing}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Progress Bar */}
                <div className="bg-white/20 rounded-lg p-2.5">
                  <div className="flex items-center gap-2 mb-2">
                    <Target className="w-4 h-4 text-white/90" />
                    <span className="text-white/90 text-sm">Study Progress</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="flex-grow">
                      <div className="w-full bg-gray-200/30 rounded-full h-2.5">
                        <div 
                          className="bg-sky-200 h-2.5 rounded-full transition-all duration-500"
                          style={{ 
                            width: `${((overallLessonStats.completed / overallLessonStats.total) * 100)}%` 
                          }}
                        ></div>
                      </div>
                    </div>
                    <span className="text-white text-sm font-medium">
                      {((overallLessonStats.completed / overallLessonStats.total) * 100).toFixed(1)}%
                      </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-2 rounded-lg border border-gray-400 dark:border-gray-600">
              <div className="md:p-4">
                {/* Header Section */}
                <div className="flex flex-col space-y-6 mb-5">
                  {/* Title and Action Buttons Row with Filters */}
                  <div className="flex flex-col lg:flex-row items-center lg:items-center lg:justify-between gap-4">
                    {/* Left side - Title and Expand */}
                    <div className="flex flex-col sm:flex-row items-center sm:items-center gap-4 w-full lg:w-auto">
                      <h3 className="text-xl font-bold text-gray-900 dark:text-white text-center lg:text-left">Lesson Categories</h3>
                      <button
                        onClick={toggleAllCategories}
                        className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md w-full sm:w-auto justify-center
                          bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-200 
                          hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200"
                      >
                        {expandedCategories.size === lessonCategories.length ? (
                          <>
                            <ChevronUp size={14} className="mr-1.5" />
                            <span>Collapse</span>
                          </>
                        ) : (
                          <>
                            <span className="mr-1.5">Expand</span>
                            <ChevronDown size={14} />
                          </>
                        )}
                      </button>
                    </div>

                    {/* Right side - Filters and Upgrade */}
                    <div className="flex flex-col sm:flex-row items-center sm:items-center gap-4 w-full lg:w-auto">
                      <ToggleGroup
                        type="single"
                        value={selectedFilter}
                        onValueChange={handleFilterChange}
                        className="flex justify-center lg:justify-end gap-1 sm:gap-2 w-full sm:w-auto"
                      >
                        <ToggleGroupItem value="all" aria-label="Show all lessons" className="h-10 px-3">
                          <BrainCircuit className="w-5 h-5" />
                          <span className="hidden lg:inline ml-2">All</span>
                        </ToggleGroupItem>
                        <ToggleGroupItem value="confident" aria-label="Show confident lessons" className="h-10 px-3">
                          <Sparkle className="w-5 h-5" />
                          <span className="hidden lg:inline ml-2">Confident</span>
                        </ToggleGroupItem>
                        <ToggleGroupItem value="maybe" aria-label="Show maybe lessons" className="h-10 px-3">
                          <Scale className="w-5 h-5" />
                          <span className="hidden lg:inline ml-2">Maybe</span>
                        </ToggleGroupItem>
                        <ToggleGroupItem value="guessing" aria-label="Show guessing lessons" className="h-10 px-3">
                          <Shuffle className="w-5 h-5" />
                          <span className="hidden lg:inline ml-2">Guessing</span>
                        </ToggleGroupItem>
                        <ToggleGroupItem value="not_rated" aria-label="Show unrated lessons" className="h-10 px-3">
                          <Target className="w-5 h-5" />
                          <span className="hidden lg:inline ml-2">Not Rated</span>
                        </ToggleGroupItem>
                      </ToggleGroup>

                      {showUpgradeButton && (
                        <Button
                          onClick={handleUpgradeClick}
                          className="bg-primary-orange hover:bg-primary-orange-hover text-white 
                            flex items-center justify-center px-6 py-2 text-sm font-medium w-full sm:w-auto"
                        >
                          <Crown size={16} className="mr-2" />
                          Upgrade
                        </Button>
                      )}
                    </div>
                  </div>
                </div>

                {/* Categories section */}
                <div className="space-y-4">
                  {renderCategories()}
                </div>
              </div>
            </div>
        </div>
      ) : (
        <div className="bg-white dark:bg-gray-900 rounded-lg overflow-hidden">
          {renderLessonContent(selectedLesson)}
        </div>
      )}
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={handleCloseUpgradeModal}
      />
    </div>
  );
});


export default React.memo(Lessons);