import React from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { Home, Book, User } from 'react-feather';

interface BottomNavigationProps {
  currentPath: string;
  isInsideCourse: boolean;
}

const BottomNavigation: React.FC<BottomNavigationProps> = ({ currentPath, isInsideCourse }) => {
  const { courseId } = useParams<{ courseId: string }>();
  const location = useLocation();

  const navItems = [
    { path: '/', icon: Home, label: 'Home' },
    { path: '/products', icon: Book, label: 'Products' },
    { path: '/profile', icon: User, label: 'Profile' },
  ];

  // Hide navigation if inside a course or viewing tickets
  if (isInsideCourse || location.pathname.includes('/support')) {
    return null;
  }

  return (
    <nav className="md:hidden fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 shadow-lg z-40">
      <ul className="flex justify-around">
        {navItems.map((item) => {
          const Icon = item.icon;
          const isActive = currentPath === item.path;
          return (
            <li key={item.path}>
              <Link
                to={item.path}
                className={`flex flex-col items-center py-2 px-3 ${
                  isActive
                    ? 'text-primary-blue dark:text-primary-blue'
                    : 'text-gray-600 dark:text-gray-400'
                }`}
              >
                <Icon size={24} />
                <span className="text-xs mt-1">{item.label}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default BottomNavigation;