import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../components/AuthProvider';
import { supabase } from '../../../services/supabase';
import { Ticket, api } from '../../../services/api';
import { 
  Mail,
  Plus,
  BookOpen,
  HelpCircle
} from 'lucide-react';
import { Button } from '../../ui/Button';
import { Card } from '../../ui/Card';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../ui/Accordion';
import UserTicketList from './UserTicketList';
import UserTicketDetail from './UserTicketDetail';
import UserSidebar from './UserSidebar';
import QuestionPreview from '../../course/QuestionPreview';
import LessonPreview from '../../course/LessonPreview';
import SimulationPreview from '../../course/SimulationPreview';
import KeslerCardsPreview from '../../course/KeslerCardsPreview';
import TicketComposer from './TicketComposer';
import { 
  Question,
  Lesson,
  Simulation,
  Flashcard,
  ContentType
} from '../../../services/api';

interface ContentWithCourse {
  id: string;
  course_id: string;
}

const UserTicketDashboard: React.FC = () => {
  const { user, refreshUser } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<{ email: string; full_name: string | null } | null>(null);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [showSidebar, setShowSidebar] = useState(true);
  const [isCreatingTicket, setIsCreatingTicket] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [expandedContent, setExpandedContent] = useState<boolean>(false);
  const [accordionValue, setAccordionValue] = useState<string>('content');

  // Update content states to include course_id
  const [question, setQuestion] = useState<Question & ContentWithCourse | null>(null);
  const [lesson, setLesson] = useState<Lesson & ContentWithCourse | null>(null);
  const [simulation, setSimulation] = useState<Simulation & ContentWithCourse | null>(null);
  const [flashcard, setFlashcard] = useState<Flashcard & ContentWithCourse | null>(null);
  const [contentLoading, setContentLoading] = useState(false);

  // Add refs to store subscription channels
  const ticketSubscriptionRef = useRef<ReturnType<typeof supabase.channel> | null>(null);
  const messageSubscriptionRef = useRef<ReturnType<typeof supabase.channel> | null>(null);

  const handleNavigateToContent = useCallback((contentType: ContentType, contentId: string, courseId: string) => {
    const routes = {
      questions: `/course/${courseId}/questions/${contentId}/preview`,
      lessons: `/course/${courseId}/lessons/${contentId}/preview`,
      simulations: `/course/${courseId}/simulations/${contentId}/preview`,
      flashcards: `/course/${courseId}/kesler-cards/${contentId}/preview`
    };
    navigate(routes[contentType]);
  }, [navigate]);

  const handleBackToStudying = () => {
    navigate('/products');
  };

  const loadUserTickets = useCallback(async () => {
    if (!user?.id) return;
    
    try {
      // Get support user first
      const supportUser = await api.getOrCreateSupportUser(user.email, user.id);
      
      // Get latest tickets for this user
      const { data: ticketsData, error } = await supabase
        .from('support_tickets')
        .select(`
          *,
          support_users!support_tickets_support_user_id_fkey(*)
        `)
        .eq('support_user_id', supportUser.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      // Update any relevant state based on the tickets
      // Note: We don't need to set the tickets here since UserTicketList 
      // manages its own ticket state and will refresh via the subscription
    } catch (error) {
      console.error('Error loading tickets:', error);
    }
  }, [user?.id, user?.email]);

  const refreshData = useCallback(async () => {
    setShouldRefresh(true);
    try {
      // Refresh user data if needed
      await refreshUser();
      // Force reload tickets
      await loadUserTickets();
    } catch (error) {
      console.error('Error refreshing data:', error);
    } finally {
      setShouldRefresh(false);
    }
  }, [refreshUser]);

  // Memoize loadUserData to prevent unnecessary re-renders
  const loadUserData = useCallback(async () => {
      if (!user?.id) return;
      
      try {
          const { data, error } = await supabase
              .from('users')
              .select('email, full_name')
              .eq('id', user.id)
              .single();

          if (error) throw error;
          setUserData(data);
      } catch (error) {
          console.error('Error loading user data:', error);
      }
  }, [user?.id]);

  // Setup subscriptions only once when component mounts
  useEffect(() => {
      if (user?.id) {
          // Load initial user data
          loadUserData();

          // Setup realtime subscriptions
          const ticketChannel = supabase.channel(`tickets-${user.id}`);
          const messageChannel = supabase.channel(`messages-${user.id}`);

          ticketSubscriptionRef.current = ticketChannel
              .on('postgres_changes', {
                  event: '*',
                  schema: 'public',
                  table: 'support_tickets',
                  filter: `user_id=eq.${user.id}`
              }, () => {
                  refreshData();
              })
              .subscribe();

          messageSubscriptionRef.current = messageChannel
              .on('postgres_changes', {
                  event: 'INSERT',
                  schema: 'public',
                  table: 'support_messages'
              }, () => {
                  refreshData();
              })
              .subscribe();

          // Cleanup subscriptions
          return () => {
              if (ticketSubscriptionRef.current) {
                  ticketSubscriptionRef.current.unsubscribe();
              }
              if (messageSubscriptionRef.current) {
                  messageSubscriptionRef.current.unsubscribe();
              }
          };
      }
  }, [user?.id, refreshData, loadUserData]);

  useEffect(() => {
    if (selectedTicket?.content_id && selectedTicket?.content_type) {
      // Type assertion to ensure content_type is ContentType
      loadContentData(selectedTicket.content_type as ContentType, selectedTicket.content_id);
    } else {
      setQuestion(null);
      setLesson(null);
      setSimulation(null);
      setFlashcard(null);
    }
  }, [selectedTicket?.content_id, selectedTicket?.content_type]);

  const loadContentData = async (contentType: ContentType, contentId: string) => {
    if (!contentId || !contentType) return;
    
    try {
      setContentLoading(true);

      const tableName = {
        'questions': 'questions',
        'lessons': 'lessons',
        'simulations': 'simulations',
        'flashcards': 'flashcards'
      }[contentType] as string;

      if (!tableName) return;

      const { data, error } = await supabase
        .from(tableName)
        .select('*, course_id')
        .eq('id', contentId)
        .single();

      if (error) {
        console.error(`Error loading ${contentType}:`, error);
        return;
      }
      
      if (!data) {
        console.error(`${contentType} not found`);
        return;
      }

      // Update the appropriate state based on content type
      switch (contentType) {
        case 'questions':
          setQuestion(data as Question & ContentWithCourse);
          break;
        case 'lessons':
          setLesson(data as Lesson & ContentWithCourse);
          break;
        case 'simulations':
          setSimulation(data as Simulation & ContentWithCourse);
          break;
        case 'flashcards':
          setFlashcard(data as Flashcard & ContentWithCourse);
          break;
      }

    } catch (error) {
      console.error(`Error loading ${contentType}:`, error);
    } finally {
      setContentLoading(false);
    }
  };

  const getContentCourseId = useCallback(() => {
    if (!selectedTicket?.content_type || !selectedTicket?.content_id) return null;
    
    switch (selectedTicket.content_type) {
      case 'questions':
        return question?.course_id || null;
      case 'lessons':
        return lesson?.course_id || null;
      case 'simulations':
        return simulation?.course_id || null;
      case 'flashcards':
        return flashcard?.course_id || null;
      default:
        return null;
    }
  }, [selectedTicket, question, lesson, simulation, flashcard]);

  const renderContent = () => {
    // Early return if we don't have the required data
    if (!selectedTicket?.content_type || !selectedTicket?.content_id || !user?.id) return null;

    // Save contentId to ensure it's stable and non-null
    const contentId = selectedTicket.content_id;
    
    const getPreviewComponent = () => {
      switch (selectedTicket.content_type) {
        case 'questions':
          if (!question?.course_id) return null;
          return (
            <QuestionPreview
              questionId={contentId}
              courseId={question.course_id}
              userId={user.id}
            />
          );
        case 'lessons':
          if (!lesson?.course_id) return null;
          return (
            <LessonPreview
              lessonId={contentId}
              courseId={lesson.course_id}
              userId={user.id}
            />
          );
        case 'simulations':
          if (!simulation?.course_id) return null;
          return (
            <div className="relative">
              <SimulationPreview
                simulationId={contentId}
                courseId={simulation.course_id}
                userId={user.id}
              />
              <div className="absolute bottom-4 left-4 right-4 text-sm text-gray-500 dark:text-gray-400">
                Note: Any previous responses or work may not be shown in this preview.
              </div>
            </div>
          );
        case 'flashcards':
          if (!flashcard?.course_id) return null;
          return (
            <KeslerCardsPreview
              flashcardId={contentId}
              courseId={flashcard.course_id}
              userId={user.id}
            />
          );
        default:
          return null;
      }
    };

    return (
      <Card className="mb-4">
        <Accordion
          type="single"
          value={accordionValue}
          onValueChange={setAccordionValue}
          collapsible
        >
          <AccordionItem value="content">
            <AccordionTrigger className="px-4 py-2 hover:no-underline">
              <div className="flex items-center gap-2">
                <HelpCircle className="w-4 h-4" />
                <span>
                  {selectedTicket.content_type.slice(0, -1).charAt(0).toUpperCase() + 
                   selectedTicket.content_type.slice(1, -1)} Content
                </span>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <div className="px-4 pb-4">
                <div className="border rounded-lg p-4">
                  {contentLoading ? (
                    <div className="flex items-center justify-center py-4">
                      <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900 dark:border-white"></div>
                    </div>
                  ) : (
                    getPreviewComponent()
                  )}
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Card>
    );
  };

  const handleBackToList = useCallback(() => {
    setIsCreatingTicket(false);
    setSelectedTicket(null);
    setShowSidebar(true);
  }, []);

  const handleTicketSelect = useCallback((ticket: Ticket) => {
    setSelectedTicket(ticket);
    setIsCreatingTicket(false);
    setShowSidebar(true);
    setExpandedContent(false);
  }, []);

  const handleCreateNewTicket = useCallback(() => {
      setIsCreatingTicket(true);
      setSelectedTicket(null);
      setShowSidebar(false);
  }, []);

  const toggleContent = useCallback(() => {
    setExpandedContent(prev => !prev);
  }, []);

  return (
    <div className="h-full flex flex-col bg-gray-50 dark:bg-gray-900">
      {shouldRefresh && (
        <div className="absolute inset-0 bg-black/10 dark:bg-black/20 flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
        </div>
      )}

      {/* Header section */}
      {!selectedTicket && !isCreatingTicket && (
        <div className="flex-none bg-white dark:bg-gray-800">
          <div className="flex justify-between items-center h-14 md:h-16 px-3 md:px-6 border-b dark:border-gray-700">
            <h1 className="text-lg md:text-2xl font-bold">Support Tickets</h1>
            <div className="flex items-center gap-4">
              <Button 
                onClick={handleBackToStudying}
                className="hidden md:flex items-center bg-orange-500 hover:bg-orange-600 text-white dark:bg-orange-600 dark:hover:bg-orange-700 dark:text-white border-none"
              >
                <BookOpen className="w-4 h-4 mr-2" />
                <span>Back To Studying</span>
              </Button>
              <Button onClick={handleCreateNewTicket} className="h-8 md:h-10 text-sm md:text-base flex items-center">
                <Plus className="w-4 h-4 md:mr-2" />
                <span className="hidden md:inline">New Ticket</span>
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Main Content Area */}
      <div className="flex-1 min-h-0 p-3 md:p-6">
        <div className="h-full flex gap-3 md:gap-6">
          {/* Ticket List Section */}
          <div className={`transition-all duration-300 ease-in-out h-full ${
            selectedTicket || isCreatingTicket
              ? 'hidden md:block md:w-1/4 md:min-w-[300px]'
              : 'w-full'
          }`}>
            <div className="h-full bg-white dark:bg-gray-800 rounded-lg shadow-sm">
              <UserTicketList
                onTicketSelect={handleTicketSelect}
                selectedTicketId={selectedTicket?.id}
                isCompressed={Boolean(selectedTicket || isCreatingTicket)}
              />
            </div>
          </div>

          {/* Detail and Sidebar Section */}
          {(selectedTicket || isCreatingTicket) && (
            <div className="flex-1 min-h-0">
              <div className="flex flex-col md:flex-row gap-3 md:gap-6 h-full">
                {/* Ticket Detail/Composer Container */}
                <div className="flex-1 min-h-0 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
                  {isCreatingTicket ? (
                    <TicketComposer 
                      onBack={handleBackToList}
                      onRefresh={refreshData}
                    />
                  ) : (
                    <div className="h-full flex flex-col">
                      <UserTicketDetail
                        ticketId={selectedTicket!.id}
                        onBack={handleBackToList}
                        onToggleContent={selectedTicket?.content_id ? toggleContent : undefined}
                      />
                      {expandedContent && selectedTicket?.content_id && selectedTicket?.content_type && (
                        <div className="flex-none border-t border-gray-200 dark:border-gray-700">
                          {renderContent()}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/* Sidebar */}
                {showSidebar && selectedTicket && (
                  <div className="w-full md:w-1/3 md:flex-none md:min-w-[300px] bg-white dark:bg-gray-800 rounded-lg shadow-sm">
                    <UserSidebar
                      userId={user?.id || ''}
                      contentType={selectedTicket?.content_type as ContentType | undefined}
                      contentId={selectedTicket?.content_id}
                      courseId={getContentCourseId() || undefined}
                      onNavigateToContent={handleNavigateToContent}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Mobile Action Buttons */}
      {!selectedTicket && !isCreatingTicket && (
        <>
          <div className="md:hidden fixed bottom-4 right-4 z-10">
            <Button
              onClick={handleCreateNewTicket}
              size="lg"
              className="rounded-full w-12 h-12 shadow-lg"
            >
              <Mail className="w-6 h-6" />
            </Button>
          </div>
          <div className="md:hidden fixed bottom-4 left-4 z-10">
            <Button
              onClick={handleBackToStudying}
              size="lg"
              className="rounded-full w-12 h-12 shadow-lg bg-orange-500 hover:bg-orange-600"
            >
              <BookOpen className="w-6 h-6" />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default UserTicketDashboard;