import React, { useMemo, useState } from 'react';
import { useConfidenceTracker } from '../../hooks/useConfidenceTracker';
import { ConfidenceTrackerData } from '../../services/api';
import { useAuth } from '../AuthProvider';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import { Crown } from 'lucide-react';
import { TIER_IDS } from '../../services/api';
import UpgradeModal from './common/UpgradeModal';

interface StudyPlanExamDayTrackerProps {
  userId: string;
  courseId: string;
}

interface UpgradeButtonProps {
  onClick: () => void;
}

const UpgradeButton: React.FC<UpgradeButtonProps> = ({ onClick }) => (
  <button
    onClick={onClick}
    className="mt-4 w-full bg-primary-orange hover:bg-primary-orange-hover text-white py-2 px-4 rounded-lg text-sm font-medium transition-colors duration-200 flex items-center justify-center"
  >
    <Crown size={16} className="mr-2" />
    Unlock Full Access
  </button>
);

const ConfidenceGauge: React.FC<{ confident: number; total: number; label: string }> = ({ confident, total, label }) => {
  const percentage = total > 0 ? (confident / total) * 100 : 0;
  const isReady = percentage >= 75;

  const getColor = (value: number) => {
    if (value <= 25) return 'bg-red-500';
    if (value <= 50) return 'bg-yellow-500';
    if (value <= 75) return 'bg-orange-500';
    return 'bg-green-500';
  };

  return (
    <div className="mb-2 md:mb-3">
      <div className="flex justify-between mb-1 text-xs md:text-sm">
        <span className="font-medium text-blue-700 dark:text-white truncate mr-2">
          {label}{isReady ? ' (Ready!)' : ''}
        </span>
        <span className="font-medium text-blue-700 dark:text-white whitespace-nowrap">{confident}/{total}</span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2 md:h-2.5 dark:bg-gray-700 relative overflow-hidden">
        {[25, 50, 75].map((mark) => (
          <div
            key={mark}
            className="absolute top-0 bottom-0 w-px bg-gray-400"
            style={{ left: `${mark}%` }}
          ></div>
        ))}
        {[25, 50, 75, 100].map((chunk, index) => {
          const chunkPercentage = Math.min(Math.max(percentage - (index * 25), 0), 25);
          const isFirstChunk = index === 0;
          const isLastChunk = index === 3;
          const isFull = chunkPercentage === 25;
          const isPreviousFull = percentage > index * 25;

          let roundedClass = '';
          if (isFirstChunk) roundedClass = 'rounded-l-full';
          if (isLastChunk && !isFull) roundedClass = 'rounded-r-full';
          if (!isFirstChunk && !isPreviousFull) roundedClass = 'rounded-l-full';

          return (
            <div
              key={chunk}
              className={`absolute top-0 left-0 h-2 md:h-2.5 ${getColor(chunk)} ${roundedClass}`}
              style={{
                width: `${chunkPercentage}%`,
                left: `${index * 25}%`
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

const StudyPlanExamDayTracker: React.FC<StudyPlanExamDayTrackerProps> = ({ userId, courseId }) => {
  const { data, loading, error } = useConfidenceTracker(userId, courseId);
  const { user } = useAuth();
  const { userCourseAccesses } = useUserCourseAccess();
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  
  const showUpgradeButton = useMemo(() => {
    if (!user || !userCourseAccesses) return false;
    
    const courseAccess = userCourseAccesses.find(access => access.course_id === courseId);
    if (!courseAccess) return true;
    
    return courseAccess.tier_id === TIER_IDS.FREE_TRIAL || 
           courseAccess.tier_id === TIER_IDS.MENTOR_ONLY;
  }, [user, userCourseAccesses, courseId]);

  if (loading) return <div className="text-xs md:text-sm">Loading exam day confidence tracker...</div>;
  if (error) return <div className="text-xs md:text-sm text-red-500">Error loading confidence tracker: {error.message}</div>;
  if (!data) return null;
  
  const orderedData: [string, keyof ConfidenceTrackerData][] = [
    ['Mentorship', 'mentorship'],
    ['Lessons', 'lessons'],
    ['Flashcards', 'flashcards'],
    ['Questions', 'questions'],
    ['Simulations', 'simulations'],
  ];

  return (
    <>
      <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4 sm:p-6 text-center h-full border border-gray-400 dark:border-gray-700">
        <h3 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4 text-gray-700 dark:text-gray-300">
          Exam Day Confidence Tracker
        </h3>
        <ConfidenceGauge 
          confident={Object.values(data).reduce((sum, category) => 
            sum + (category.confident || category.completed || 0), 0)}
          total={Object.values(data).reduce((sum, category) => 
            sum + category.total, 0)}
          label="Overall"
        />
        {orderedData.map(([label, key]) => (
          <ConfidenceGauge 
            key={key} 
            confident={key === 'mentorship' ? data[key].completed : data[key].confident} 
            total={data[key].total} 
            label={label} 
          />
        ))}
        {showUpgradeButton && (
          <UpgradeButton onClick={() => setIsUpgradeModalOpen(true)} />
        )}
      </div>
      <UpgradeModal 
        isOpen={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
      />
    </>
  );
};

export default StudyPlanExamDayTracker;