import React, { useEffect, useRef, useCallback } from 'react';
import { useSimulationTimer, useQuizTimer } from '../../../contexts/TimerContext';

interface TimeTracker {
  startTime: number;
  elapsedBeforePause: number;
  isTracking: boolean;
  lastUpdate: number;
}

interface QuestionTimeManagerProps {
  questionId: string;
  questionType: 'simulation' | 'mcq';
  isSubmitted: boolean;
  onTimeUpdate: (elapsed: number) => void;
}

const QuestionTimeManager: React.FC<QuestionTimeManagerProps> = ({
  questionId,
  questionType,
  isSubmitted,
  onTimeUpdate,
}) => {
  const simulationTimer = useSimulationTimer();
  const quizTimer = useQuizTimer();
  const timer = questionType === 'simulation' ? simulationTimer : quizTimer;
  
  const timeTrackerRef = useRef<Record<string, TimeTracker>>({});
  const updateIntervalRef = useRef<NodeJS.Timeout>();

  const initializeTimer = useCallback(() => {
    if (timeTrackerRef.current[questionId]?.isTracking) return;
    
    const now = Date.now();
    timeTrackerRef.current[questionId] = {
      startTime: now,
      elapsedBeforePause: 0,
      isTracking: true,
      lastUpdate: now
    };
    
    timer.setStartTime(now);
    timer.setElapsedTimeBeforePause(0);
    timer.setIsRunning(true);
  }, [questionId, timer]);

  const updateElapsedTime = useCallback(() => {
    const tracker = timeTrackerRef.current[questionId];
    if (!tracker || !tracker.isTracking) return;

    const now = Date.now();
    const currentElapsed = Math.floor((now - tracker.startTime) / 1000);
    const totalElapsed = tracker.elapsedBeforePause + currentElapsed;

    if (now - tracker.lastUpdate >= 1000) {
      onTimeUpdate(totalElapsed);
      tracker.lastUpdate = now;
    }
  }, [questionId, onTimeUpdate]);

  const pauseTimer = useCallback(() => {
    const tracker = timeTrackerRef.current[questionId];
    if (!tracker || !tracker.isTracking) return;

    const now = Date.now();
    const currentElapsed = Math.floor((now - tracker.startTime) / 1000);
    tracker.elapsedBeforePause += currentElapsed;
    tracker.isTracking = false;
    
    timer.setIsRunning(false);
    timer.setElapsedTimeBeforePause(tracker.elapsedBeforePause);
    onTimeUpdate(tracker.elapsedBeforePause);

    if (updateIntervalRef.current) {
      clearInterval(updateIntervalRef.current);
      updateIntervalRef.current = undefined;
    }
  }, [questionId, timer, onTimeUpdate]);

  // Initialize timer when question changes
  useEffect(() => {
    if (!timeTrackerRef.current[questionId]) {
      initializeTimer();
    }

    // Start interval for time updates
    if (!updateIntervalRef.current) {
      updateIntervalRef.current = setInterval(updateElapsedTime, 1000);
    }

    return () => {
      if (updateIntervalRef.current) {
        clearInterval(updateIntervalRef.current);
        updateIntervalRef.current = undefined;
      }
      if (timeTrackerRef.current[questionId]?.isTracking) {
        pauseTimer();
      }
    };
  }, [questionId, initializeTimer, pauseTimer, updateElapsedTime]);

  // Handle submission state
  useEffect(() => {
    if (isSubmitted) {
      pauseTimer();
    }
  }, [isSubmitted, pauseTimer]);

  return null;
};

export default QuestionTimeManager;