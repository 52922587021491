import React, { FC, ReactNode } from 'react';
import { Crown, Lock } from 'lucide-react';
import { Button } from '../../../components/ui/Button';
import { useUserCourseAccess } from '../../UserCourseAccessProvider';
import UpgradeModal from '../common/UpgradeModal';
import { TIER_IDS } from '../../../services/api';

interface PremiumTaskWrapperProps {
  children: ReactNode;
  courseId: string;
  taskType: string;
  isPremium?: boolean;
}

export const PremiumTaskWrapper: FC<PremiumTaskWrapperProps> = ({
  children,
  courseId,
  taskType,
  isPremium = true
}) => {
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const { userCourseAccesses } = useUserCourseAccess();

  const isRestrictedAccess = React.useMemo(() => {
    if (!userCourseAccesses || userCourseAccesses.length === 0) return true;
    
    const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
    if (courseAccesses.length === 0) return true;

    const hasFullAccess = courseAccesses.some(
      access => access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY || access.tier_id === TIER_IDS.FULL_ACCESS
    );

    if (hasFullAccess) return false;

    const onlyHasRestrictedAccess = courseAccesses.every(
      access => access.tier_id === TIER_IDS.FREE_TRIAL || access.tier_id === TIER_IDS.MENTOR_ONLY
    );

    return onlyHasRestrictedAccess;
  }, [userCourseAccesses, courseId]);

  const handleUpgradeClick = () => setIsUpgradeModalOpen(true);
  const handleCloseUpgradeModal = () => setIsUpgradeModalOpen(false);

  if (!isPremium || !isRestrictedAccess) {
    return <>{children}</>;
  }

  const getTaskTitle = (type: string) => {
    switch (type) {
      case 'loop':
        return 'Master Your Knowledge';
      case 'funnel':
        return 'Focus Where It Matters';
      case 'mock':
        return 'Exam-Day Confidence';
      case 'funnel-retake':
        return 'Strategic Score Improvement';
      case 'mock-retake':
        return 'Exam-Day Excellence';
      default:
        return 'Premium Study Feature';
    }
  };

  const getTaskDescription = (type: string) => {
    switch (type) {
      case 'loop':
        return (
          <div className="text-left space-y-4">
            <div className="font-medium text-lg mb-2">
              Defeat the curve of forgetting with our proven study loop system:
            </div>
            <ul className="space-y-3 ml-4">
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Combine pre-made flashcards, targeted quizzes, and personalized study tools</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Transform temporary knowledge into lasting mastery</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Create your own KeslerCards to capture breakthrough moments</span>
              </li>
            </ul>
          </div>
        );
      case 'funnel':
        return (
          <div className="text-left space-y-4">
            <div className="font-medium text-lg mb-2">
              Stop wasting time on what you already know:
            </div>
            <ul className="space-y-3 ml-4">
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Identify and target your weakest areas with our intelligent Study Funnel System</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Create a laser-focused study strategy that maximizes your score potential</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Turn your weaknesses into strengths before exam day</span>
              </li>
            </ul>
          </div>
        );
      case 'mock':
        return (
          <div className="text-left space-y-4">
            <div className="font-medium text-lg mb-2">
              Train like a champion for exam day:
            </div>
            <ul className="space-y-3 ml-4">
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Experience real exam conditions in our simulation system</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Master time management and overcome test anxiety</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Build the confidence and stamina you need to perform at your peak</span>
              </li>
            </ul>
          </div>
        );
      case 'funnel-retake':
        return (
          <div className="text-left space-y-4">
            <div className="font-medium text-lg mb-2">
              Transform your retake into a triumph:
            </div>
            <ul className="space-y-3 ml-4">
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Analyze your previous performance to create a targeted improvement strategy</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Focus your efforts where they'll have the biggest impact</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Turn past challenges into future successes</span>
              </li>
            </ul>
          </div>
        );
      case 'mock-retake':
        return (
          <div className="text-left space-y-4">
            <div className="font-medium text-lg mb-2">
              Level up your exam performance:
            </div>
            <ul className="space-y-3 ml-4">
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Build on your first attempt with enhanced mock exams</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Develop advanced time management strategies</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Boost your confidence for a stronger, more successful retake</span>
              </li>
            </ul>
          </div>
        );
      default:
        return (
          <div className="text-left space-y-4">
            <div className="font-medium text-lg mb-2">
              Maximize your CPA exam success:
            </div>
            <ul className="space-y-3 ml-4">
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Access premium study features designed for success</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Utilize proven strategies and tools</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 h-2 w-2 mt-2 bg-primary-orange rounded-full mr-3"></div>
                <span>Take control of your CPA exam journey</span>
              </li>
            </ul>
          </div>
        );
    }
  };

  const getButtonText = (type: string) => {
    switch (type) {
      case 'loop':
        return 'Unlock: Study Looping Quizzes';
      case 'funnel':
        return 'Unlock: Study Funnel Quizzes';
      case 'mock':
        return 'Unlock: Mock Exam';
      case 'funnel-retake':
        return 'Unlock: Study Funnel Retake Quizzes';
      case 'mock-retake':
        return 'Unlock: Retake Mock Exam';
      default:
        return 'Upgrade Now';
    }
  };

  return (
    <div className="relative">
      <div className="blur-sm pointer-events-none">
        {children}
      </div>
      <div className="absolute pt-10 inset-0 flex items-start justify-center bg-gray-900 bg-opacity-75 z-10 rounded-lg">
        <div className="text-center text-white px-6 py-8 max-w-md mx-auto">
          <Lock size={36} className="mx-auto mb-3" />
          <h3 className="text-xl font-semibold mb-2">{getTaskTitle(taskType)}</h3>
          <div className="mb-6 text-sm leading-relaxed">{getTaskDescription(taskType)}</div>
          <Button
            onClick={handleUpgradeClick}
            className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center mx-auto text-sm px-6 py-3"
          >
            <Crown size={14} className="mr-2" />
            {getButtonText(taskType)}
          </Button>
        </div>
      </div>
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={handleCloseUpgradeModal}
      />
    </div>
  );
};

export default PremiumTaskWrapper;