import React, { useState, useEffect } from 'react';
import { api, TIER_IDS } from '../../services/api';
import type { MockExam as MockExamType } from '../../services/api';
import { useAuth } from '../AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/Button';
import { Card } from '../ui/Card';
import { Alert, AlertTitle, AlertDescription } from '../ui/Alert';
import { 
  Award, 
  Calendar, 
  Clock, 
  ChevronRight, 
  AlertCircle, 
  Play, 
  Lock, 
  Crown,
  CheckCircle,
  Activity,
  Target
} from 'lucide-react';
import MockExamActions from './MockExamActions';
import { useMockExamTimer } from '../../contexts/TimerContext';
import UpgradeModal from './common/UpgradeModal';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import PopupModal from './common/PopupModal';
import { useActiveQuiz, ActiveQuizState, getTestletType } from '../../hooks/useActiveQuiz';

interface MockExamProps {
  courseId: string;
  userId: string;
}

const MockExam: React.FC<MockExamProps> = ({ courseId, userId }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const timer = useMockExamTimer();
  const [mockExams, setMockExams] = useState<MockExamType[]>([]);
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasActive, setHasActive] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const { userCourseAccesses } = useUserCourseAccess();

 // Add active quiz state management
  const [activeQuizState, setActiveQuizState] = useState<ActiveQuizState>({
    activeQuiz: null,
    activeTestletType: null,
    showActiveQuizModal: false
  });

  // Initialize useActiveQuiz hook
  const { data: activeQuiz } = useActiveQuiz(userId, courseId, {
    enabled: false,
    onSuccess: (quiz) => {
      if (quiz) {
        setActiveQuizState(prev => ({
          ...prev,
          activeQuiz: quiz,
          showActiveQuizModal: true
        }));
      }
    }
  });
    useEffect(() => {
      fetchMockExams();
    }, [user, courseId]);

    // Check if user has mock exam access
    const hasMockExamAccess = React.useMemo(() => {
      if (!userCourseAccesses || !Array.isArray(userCourseAccesses)) return false;
      
      const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
      if (courseAccesses.length === 0) return false;
  
      // Check if user has either full access, study materials only, or both study materials + mentor
      const hasFullAccess = courseAccesses.some(access => access.tier_id === TIER_IDS.FULL_ACCESS);
      const hasStudyMaterials = courseAccesses.some(access => access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY);
      const hasMentorAccess = courseAccesses.some(access => access.tier_id === TIER_IDS.MENTOR_ONLY);
  
      return hasFullAccess || hasStudyMaterials || (hasStudyMaterials && hasMentorAccess);
    }, [userCourseAccesses, courseId]);
  
    // Check if user should see upgrade button
    const showUpgradeButton = React.useMemo(() => {
      if (!userCourseAccesses || !Array.isArray(userCourseAccesses)) return true;
      
      const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
      if (courseAccesses.length === 0) return true;
  
      const hasFullAccess = courseAccesses.some(access => 
        access.tier_id === TIER_IDS.FULL_ACCESS || 
        access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY
      );
  
      const onlyHasRestrictedAccess = courseAccesses.every(access =>
        access.tier_id === TIER_IDS.FREE_TRIAL || 
        access.tier_id === TIER_IDS.MENTOR_ONLY
      );
  
      return !hasFullAccess && onlyHasRestrictedAccess;
    }, [userCourseAccesses, courseId]);
  
    const fetchMockExams = async () => {
      if (!user || !courseId) return;
      try {
        // Only fetch mock exams if user has access
        if (!hasMockExamAccess) {
          setMockExams([]);
          return;
        }
  
        const data = await api.getMockExamHistory(user.id, courseId);
        
        // Filter out incomplete exams if user no longer has access
        const filteredExams = data.filter(exam => 
          exam.completed || hasMockExamAccess
        );
        
        setMockExams(filteredExams);
        const hasActiveExam = hasMockExamAccess && await api.hasActiveMockExam(user.id, courseId);
        setHasActive(hasActiveExam);
      } catch (err: any) {
        console.error(err);
        setError('Failed to load mock exams');
      }
    };
  
    useEffect(() => {
      fetchMockExams();
    }, [user, courseId, hasMockExamAccess]);
  
    // Update how we use the hook
    const { checkForActiveQuiz } = useActiveQuiz(userId, courseId, {
      enabled: false,
      onSuccess: (quiz) => {
        if (quiz) {
          setActiveQuizState(prev => ({
            ...prev,
            activeQuiz: quiz,
            showActiveQuizModal: true
          }));
        }
      }
    });

    const handleCreateMockExam = async () => {
      if (!user || !courseId || !hasMockExamAccess) {
        setIsUpgradeModalOpen(true);
        return;
      }
      
      setIsCreating(true);
      setError(null);
      
      try {
        // Manually check for active quiz
        const { data: activeQuiz } = await checkForActiveQuiz();
        
        if (activeQuiz) {
          setActiveQuizState({
            activeQuiz: activeQuiz,
            activeTestletType: 'mock_exam',
            showActiveQuizModal: true
          });
          setIsCreating(false);
          return;
        }
  
        await timer.resetTimer();
        
        localStorage.removeItem('mockExamStartTime');
        localStorage.removeItem('mockExamTimerPaused');
        localStorage.removeItem('mockExamElapsedTime');
        localStorage.removeItem('mockExamIsRunning');
        
        const mockExamId = await api.createMockExam(user.id, courseId);
        await fetchMockExams();
        navigate(`/course/${courseId}/mock-exam/${mockExamId}`);
      } catch (err: any) {
        setError(err.message || 'Failed to create mock exam');
        console.error(err);
      } finally {
        setIsCreating(false);
      }
    };
  
    const handleEndQuizAndCreateMockExam = async () => {
      if (!activeQuizState.activeQuiz) return;
      
      try {
        // End the active quiz session
        const totalTime = activeQuizState.activeQuiz.questions_data.reduce(
          (sum, q) => sum + (q.timeSpent || 0), 
          0
        );
        
        await api.endQuizSession(
          activeQuizState.activeQuiz.id, 
          totalTime,
          true
        );
        
        // Reset active quiz state
        setActiveQuizState({
          activeQuiz: null,
          activeTestletType: null,
          showActiveQuizModal: false
        });
        
        // Create new mock exam
        await handleCreateMockExam();
      } catch (error) {
        console.error('Error ending quiz:', error);
        setError('Failed to end quiz session. Please try again.');
      }
    };
  
    const handleEndMockExam = async (examId: string) => {
      if (!hasMockExamAccess) {
        setIsUpgradeModalOpen(true);
        return;
      }
  
      try {
        await api.endMockExam(examId);
        await fetchMockExams();
      } catch (err: any) {
        setError(err.message || 'Failed to end mock exam');
        console.error(err);
      }
    };
  
    const handleDeleteMockExam = async (examId: string) => {
      if (!hasMockExamAccess) {
        setIsUpgradeModalOpen(true);
        return;
      }
  
      try {
        await api.deleteMockExam(examId);
        await fetchMockExams();
      } catch (err: any) {
        setError(err.message || 'Failed to delete mock exam');
        console.error(err);
      }
    };

  const formatTime = (exam: MockExamType): string => {
    if (!exam.completed && exam.total_time === null) return 'In Progress';
    if (!exam.completed) return 'Incomplete';
    if (exam.total_time === null) return 'N/A';
    const hours = Math.floor(exam.total_time / 3600);
    const minutes = Math.floor((exam.total_time % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const formatScore = (exam: MockExamType): string => {
    if (!exam.completed) return 'N/A';
    if (exam.score === null) return 'N/A';
    return `${exam.score.toFixed(2)}%`;
  };

  const getScoreColor = (score: number | null, completed: boolean): string => {
    if (!completed || score === null) return 'text-gray-500 dark:text-gray-400';
    if (score >= 75) return 'text-green-600 dark:text-green-400';
    if (score >= 65) return 'text-yellow-600 dark:text-yellow-400';
    return 'text-red-600 dark:text-red-400';
  };

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 p-4 md:p-8 max-w-[1000px] mx-auto rounded-lg shadow-md dark:shadow-gray-800">
      <Card className="max-w-7xl mx-auto">
        <div className="p-6 space-y-6">
          {/* Header section */}
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
            <div className="space-y-1">
              <h1 className="text-2xl md:text-3xl font-bold flex items-center gap-2">
                <Award className="h-8 w-8 text-primary-blue flex-shrink-0" />
                Mock Exams
              </h1>
              <p className="text-gray-600 dark:text-gray-400">
                Test your exam day readiness with full-length mock exams
              </p>
            </div>
            
            <div className="flex gap-4">
              {showUpgradeButton && (
                <Button
                  onClick={() => setIsUpgradeModalOpen(true)}
                  className="bg-primary-orange hover:bg-primary-orange-hover text-white px-6 py-3 rounded-lg transition-all duration-200"
                >
                  <span className="inline-flex items-center justify-center gap-2">
                    <Crown className="h-5 w-5 flex-shrink-0" />
                    <span>Upgrade</span>
                  </span>
                </Button>
              )}
              <Button
                onClick={handleCreateMockExam}
                disabled={isCreating || hasActive || !hasMockExamAccess}
                className={`px-6 py-3 rounded-lg transition-all duration-200 ${
                  isCreating || hasActive || !hasMockExamAccess
                    ? 'bg-gray-400 hover:bg-gray-400 cursor-not-allowed opacity-50' 
                    : 'bg-primary-blue hover:bg-blue-600'
                } text-white`}
              >
                {isCreating ? (
                  <span className="inline-flex items-center gap-2">
                    <span className="animate-spin">⌛</span>
                    <span>Creating...</span>
                  </span>
                ) : (
                  <span className="inline-flex items-center gap-2">
                    {hasActive || !hasMockExamAccess ? (
                      <Lock className="h-5 w-5 flex-shrink-0" />
                    ) : (
                      <Award className="h-5 w-5 flex-shrink-0" />
                    )}
                    <span>Create Mock Exam</span>
                  </span>
                )}
              </Button>
            </div>
          </div>

          {/* Marketing Alert */}
          {!hasMockExamAccess && (
            <Alert variant="default" className="bg-white dark:bg-gray-800/50 border border-blue-200 dark:border-blue-700">
              <div className="flex flex-col items-center text-center max-w-3xl mx-auto">
                <div className="flex items-center gap-2 mb-4">
                  <Crown className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                  <AlertTitle className="text-blue-600 dark:text-blue-400 text-xl">Unlock Full Mock Exam Access!</AlertTitle>
                </div>
                <AlertDescription className="mt-2 space-y-6">
                  <p className="text-gray-700 dark:text-gray-300 text-lg">
                    Supercharge your CPA exam preparation with our comprehensive mock exams
                  </p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-left">
                    <div className="bg-blue-50/50 dark:bg-gray-700/50 p-4 rounded-lg">
                      <h4 className="font-semibold text-gray-800 dark:text-gray-200 mb-2">Exam Simulation</h4>
                      <p className="text-gray-600 dark:text-gray-400">Full-length simulated exam experience with real exam-style questions</p>
                    </div>
                    <div className="bg-blue-50/50 dark:bg-gray-700/50 p-4 rounded-lg">
                      <h4 className="font-semibold text-gray-800 dark:text-gray-200 mb-2">Performance Analytics</h4>
                      <p className="text-gray-600 dark:text-gray-400">Detailed analytics and score predictions to track your progress</p>
                    </div>
                    <div className="bg-blue-50/50 dark:bg-gray-700/50 p-4 rounded-lg">
                      <h4 className="font-semibold text-gray-800 dark:text-gray-200 mb-2">Task-Based Simulations</h4>
                      <p className="text-gray-600 dark:text-gray-400">Practice with authentic task-based simulations just like the real exam</p>
                    </div>
                    <div className="bg-blue-50/50 dark:bg-gray-700/50 p-4 rounded-lg">
                      <h4 className="font-semibold text-gray-800 dark:text-gray-200 mb-2">Unlimited Practice</h4>
                      <p className="text-gray-600 dark:text-gray-400">Take as many mock exams as you need to perfect your strategy</p>
                    </div>
                  </div>
                  <div className="pt-4">
                    <Button
                      onClick={() => setIsUpgradeModalOpen(true)}
                      className="bg-primary-orange hover:bg-primary-orange-hover text-white px-8 py-4 text-lg rounded-lg transition-all duration-200"
                    >
                      <span className="inline-flex items-center justify-center gap-2">
                        <Crown className="h-5 w-5 flex-shrink-0" />
                        <span>Upgrade Now to Access Mock Exams</span>
                      </span>
                    </Button>
                  </div>
                </AlertDescription>
              </div>
            </Alert>
          )}

          {/* Existing Alerts */}
          {error && (
            <Alert variant="destructive">
              <div className="flex items-center gap-2">
                <AlertCircle className="h-5 w-5 flex-shrink-0" />
                <AlertTitle>Error</AlertTitle>
              </div>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {hasActive && (
            <Alert variant="warning">
              <div className="flex items-center gap-2">
                <AlertCircle className="h-5 w-5 flex-shrink-0" />
                <AlertTitle>Active Mock Exam</AlertTitle>
              </div>
              <AlertDescription>
                You have an active mock exam in progress. Please complete or end it before creating a new one.
              </AlertDescription>
            </Alert>
          )}

          {hasMockExamAccess && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {mockExams.map((exam) => {
                const timeOver = exam.completed && exam.total_time != null && exam.total_time > 14400;
                const timeRating = !exam.completed ? 'N/A' : (timeOver ? 'Over Time' : 'On Time');
                const timeRatingColor = !exam.completed ? 'text-gray-500' : 
                  (timeOver ? 'text-red-600 dark:text-red-400' : 'text-green-600 dark:text-green-400');

                return (
                  <div
                    key={exam.id}
                    className={`
                      relative rounded-xl overflow-hidden
                      transition-all duration-300
                      ${!exam.completed ? `
                        bg-gradient-to-b from-white to-blue-50 
                        dark:from-gray-800 dark:to-blue-900/10
                        ring-2 ring-blue-400/40 dark:ring-blue-300/40 
                        shadow-lg shadow-blue-500/20 dark:shadow-blue-400/20
                        hover:ring-blue-400/50 dark:hover:ring-blue-300/50
                        hover:shadow-xl hover:shadow-blue-500/30 dark:hover:shadow-blue-400/30
                      ` : `
                        bg-white dark:bg-gray-800
                        ring-1 ring-gray-200 dark:ring-gray-700
                        hover:shadow-lg dark:hover:shadow-gray-700/30
                      `}
                    `}
                  >
                    {/* Card Header */}
                    <div className="p-4 border-b border-gray-200 dark:border-gray-700">
                      <div className="flex justify-between items-start mb-2">
                        <span className="px-3 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-200 flex items-center gap-1.5">
                          <Award className="w-3.5 h-3.5" />
                          <span>Mock Exam #{exam.exam_number}</span>
                        </span>
                        <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                          exam.completed ? 'bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-200' : 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-200'
                        }`}>
                          {exam.completed ? 'Completed' : 'In Progress'}
                        </span>
                      </div>
                      <span className="text-xs text-gray-500 dark:text-gray-400 flex items-center">
                        <Calendar className="w-3.5 h-3.5 mr-1" />
                        {new Date(exam.created_at).toLocaleString()}
                      </span>
                    </div>

                    {/* Card Body */}
                    <div className="p-4 space-y-4">
                      {/* Score and Time Rating */}
                      <div className="grid grid-cols-2 gap-4">
                        <div className="flex items-center">
                          <CheckCircle className="w-4 h-4 mr-2 text-gray-400" />
                          <div>
                            <div className={`text-sm font-medium ${getScoreColor(exam.score, exam.completed)}`}>
                              {formatScore(exam)}
                            </div>
                            <div className="text-xs text-gray-500 dark:text-gray-400">
                              Score
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <Activity className="w-4 h-4 mr-2 text-gray-400" />
                          <div>
                            <div className={`text-sm font-medium ${timeRatingColor}`}>
                              {timeRating}
                            </div>
                            <div className="text-xs text-gray-500 dark:text-gray-400">
                              Time Rating
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Duration and Exam Day Score */}
                      <div className="grid grid-cols-2 gap-4">
                        <div className="flex items-center">
                          <Clock className="w-4 h-4 mr-2 text-gray-400" />
                          <div>
                            <div className="text-sm font-medium text-gray-900 dark:text-white">
                              {formatTime(exam)}
                            </div>
                            <div className="text-xs text-gray-500 dark:text-gray-400">
                              Duration
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <Target className="w-4 h-4 mr-2 text-gray-400" />
                          <div>
                            <div className={`text-sm font-medium ${getScoreColor(exam.exam_day_score, exam.completed)}`}>
                              {!exam.completed ? 'N/A' : 
                                exam.exam_day_score !== null ? `${exam.exam_day_score.toFixed(2)}%` : 'N/A'}
                            </div>
                            <div className="text-xs text-gray-500 dark:text-gray-400">
                              Exam Day Score
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Card Actions */}
                      <div className="mt-4 grid grid-cols-2 gap-2">
                        {!exam.completed ? (
                          <>
                            <Button
                              onClick={() => navigate(`/course/${courseId}/mock-exam/${exam.id}`)}
                              className="w-full bg-primary-blue hover:bg-blue-600 text-white"
                            >
                              <span className="inline-flex items-center gap-2">
                                <Play className="h-4 w-4" />
                                <span>Resume</span>
                              </span>
                            </Button>
                            <MockExamActions 
                              exam={exam}
                              onEnd={handleEndMockExam}
                              onDelete={handleDeleteMockExam}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              onClick={() => navigate(`/course/${courseId}/mock-exam/${exam.id}/review`)}
                              className="w-full bg-primary-blue hover:bg-blue-600 text-white"
                            >
                              Review Results
                            </Button>
                            <MockExamActions 
                              exam={exam}
                              onEnd={handleEndMockExam}
                              onDelete={handleDeleteMockExam}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}

              {mockExams.length === 0 && (
                <div className="col-span-full p-8 text-center bg-white dark:bg-gray-800 rounded-xl">
                  <div className="flex flex-col items-center gap-4">
                    <Award className="h-12 w-12 text-gray-400 dark:text-gray-600" />
                    <div className="space-y-2">
                      <p className="text-gray-600 dark:text-gray-400">
                        No mock exams found
                      </p>
                      <p className="text-sm text-gray-500 dark:text-gray-500">
                        Create your first mock exam to begin practicing!
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <Alert variant="default" className="mt-6">
            <div className="flex items-center gap-2">
              <AlertCircle className="h-5 w-5 flex-shrink-0" />
              <AlertTitle>About Mock Exams</AlertTitle>
            </div>
            <AlertDescription>
              <ul className="list-disc ml-4 space-y-1">
                <li>Each mock exam simulates the real CPA exam environment</li>
                <li>Complete all 5 testlets within the 4-hour time limit</li>
                <li>Review detailed explanations for all questions after completion</li>
                <li>Track your performance and identify areas for improvement</li>
              </ul>
            </AlertDescription>
          </Alert>
        </div>
      </Card>

      {/* Add PopupModal */}
      <PopupModal
        isOpen={activeQuizState.showActiveQuizModal}
        onClose={() => setActiveQuizState(prev => ({ ...prev, showActiveQuizModal: false }))}
        onResume={handleCreateMockExam}
        onEnd={handleEndQuizAndCreateMockExam}
        activeQuiz={activeQuizState.activeQuiz}
        courseId={courseId}
      />

      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
      />
    </div>
  );
};

export default React.memo(MockExam);