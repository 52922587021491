import React, { useState } from 'react';
import { Modal } from '../ui/Modal';
import useKeyPress from '../../hooks/useKeyPress';
import DOMPurify from 'dompurify';

const MAX_CONFIRM_LENGTH = 10;

interface SimulationResetCategoryPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  categoryCount: number;
}

const SimulationResetCategoryPopup: React.FC<SimulationResetCategoryPopupProps> = ({
  isOpen,
  onClose,
  onConfirm,
  categoryCount
}) => {
  const [confirmText, setConfirmText] = useState('');

  const sanitizeInput = (input: string) => {
    return DOMPurify.sanitize(input);
  };

  const handleConfirm = () => {
    if (sanitizeInput(confirmText).toLowerCase() === 'reset') {
      onConfirm();
      setConfirmText('');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedInput = sanitizeInput(e.target.value).slice(0, MAX_CONFIRM_LENGTH);
    setConfirmText(sanitizedInput);
  };

  useKeyPress('Enter', handleConfirm, () => isOpen && confirmText.toLowerCase() === 'reset');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full mx-auto">
        <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-gray-200">
          Reset Simulation Categories
        </h2>
        <p className="mb-4 text-gray-600 dark:text-gray-400">
          Are you sure you want to reset {categoryCount} selected simulation{' '}
          {categoryCount === 1 ? 'category' : 'categories'}? This action will clear
          all responses and confidence levels for the selected simulations.
        </p>
        <p className="mb-2 text-gray-600 dark:text-gray-400">
          Enter RESET in the textbox below to confirm:
        </p>
        <input
          type="text"
          value={confirmText}
          onChange={handleInputChange}
          className="w-full p-2 mb-4 border rounded text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-700"
          placeholder="Type RESET to confirm"
          maxLength={MAX_CONFIRM_LENGTH}
        />
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            disabled={sanitizeInput(confirmText).toLowerCase() !== 'reset'}
            className={`px-4 py-2 rounded ${
              sanitizeInput(confirmText).toLowerCase() === 'reset'
                ? 'bg-red-500 text-white hover:bg-red-600'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            Reset
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SimulationResetCategoryPopup;