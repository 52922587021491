import { useState, useEffect } from 'react';
import { api, StudyTaskDetails } from '../services/api';

export const useStudyTaskDetails = (userId: string, courseId: string, itemId: string) => {
  const [taskDetails, setTaskDetails] = useState<StudyTaskDetails | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let isMounted = true;
    const fetchTaskDetails = async () => {
      if (!userId || !courseId || !itemId) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        console.log('Fetching task details for:', { userId, courseId, itemId });
        
        const details = await api.getStudyTaskDetails(userId, courseId, itemId);
        console.log('Raw API Response:', details);
        
        if (!isMounted) return;

        // Create a complete details object with all required fields
        const enhancedDetails: StudyTaskDetails = {
          ...details,
          topic_type: details.topic_type || 'learn',
          estimated_minutes: details.estimated_minutes || null,
        };

        console.log('Enhanced task details:', enhancedDetails);
        
        // Fetch plan_id if not included
        if (!enhancedDetails.plan_id) {
          console.log('Fetching missing plan_id...');
          const userStudyPlan = await api.getUserStudyPlan(userId, courseId);
          if (userStudyPlan && isMounted) {
            enhancedDetails.plan_id = userStudyPlan.plan_id;
            console.log('Updated with plan_id:', enhancedDetails.plan_id);
          }
        }

        if (isMounted) {
          console.log('Setting final task details:', enhancedDetails);
          setTaskDetails(enhancedDetails);
        }
      } catch (err) {
        console.error('Error in useStudyTaskDetails:', err);
        if (isMounted) {
          setError(err instanceof Error ? err : new Error('Failed to fetch task details'));
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchTaskDetails();
    return () => {
      isMounted = false;
    };
  }, [userId, courseId, itemId]);

  // Add debug effect for state updates
  useEffect(() => {
    console.log('Task details state updated:', taskDetails);
  }, [taskDetails]);

  return { taskDetails, isLoading, error };
};