import React, { useMemo } from 'react';
import { 
  ListCheck, 
  FileQuestion, 
  BrainCircuit,
  TrendingUp,
  Sparkle,
  Scale,
  Shuffle,
  Target,
  CheckCircle,
  XCircle
} from 'lucide-react';

// Using ExtendedCategoryStats from api
interface CategoryStats {
  total_questions: number;
  unanswered_questions: number;
  correct: number;
  incorrect: number;
  confident: number;
  maybe: number;
  guessing: number;
  correct_percentage: number;
  confident_correct: number;
  confident_incorrect: number;
  maybe_correct: number;
  maybe_incorrect: number;
  guessing_correct: number;
  guessing_incorrect: number;
}

interface Category extends CategoryStats {
  id: string;
  parent_question_category_name: string;
  sub_question_category_name: string;
}

interface CategoriesData {
  structuredCategories: {
    [key: string]: Category[];
  };
  statsMap: {
    [key: string]: CategoryStats;
  };
}

interface ExtendedSimulationStats {
  total?: number;
  unanswered?: number;
  correct?: number;
  incorrect?: number;
  confident?: number;
  maybe?: number;
  guessing?: number;
  confident_correct?: number;
  confident_incorrect?: number;
  maybe_correct?: number;
  maybe_incorrect?: number;
  guessing_correct?: number;
  guessing_incorrect?: number;
}

interface SimulationData {
  groupedCategories: {
    id: string;
    course_id: string;
    parent_question_category_name: string;
    unitNumber?: number;
    categoryIds: string[];
    stats: ExtendedSimulationStats;
  }[];
  overallStats: ExtendedSimulationStats;
}

interface AnalyticsOverviewCardProps {
  userId: string;
  courseId: string;
  categoriesData: CategoriesData | null | undefined;
  simulationData: SimulationData | null | undefined;
  isLoading: boolean;
}

interface MCQStats {
  total: number;
  unanswered: number;
  correct: number;
  incorrect: number;
  confident: number;
  maybe: number;
  guessing: number;
}

const CompactConfidenceIndicator: React.FC<{ count: number, type: 'confident' | 'maybe' | 'guessing', percentage: string }> = ({ count, type, percentage }) => {
  if (count === 0) return null;

  const getIcon = () => {
    switch(type) {
      case 'confident': return <Sparkle className="w-3 h-3" />;
      case 'maybe': return <Scale className="w-3 h-3" />;
      case 'guessing': return <Shuffle className="w-3 h-3" />;
    }
  };
  
  const getColors = () => {
    switch(type) {
      case 'confident': return 'bg-green-100 dark:bg-green-600/50 text-green-800 dark:text-green-100';
      case 'maybe': return 'bg-yellow-100 dark:bg-yellow-600/50 text-yellow-800 dark:text-yellow-100';
      case 'guessing': return 'bg-red-100/90 text-red-900 dark:bg-red-600/50 dark:text-red-100';
    }
  };

  return (
    <div className={`${getColors()} rounded px-1.5 py-0.5 text-xs font-medium flex items-center gap-1`}>
      {getIcon()}
      <span>{count}</span>
      <span className=" text-grey-800 text-xs">({percentage}%)</span>
    </div>
  );
};

const QuestionTypeCard: React.FC<{ 
  title: string, 
  total: number,
  correct: number,
  incorrect: number,
  confident: number,
  maybe: number,
  guessing: number
}> = ({ 
  title, 
  total,
  correct,
  incorrect,
  confident,
  maybe,
  guessing
}) => {
  const confidenceStats = [
    { count: confident, type: 'confident' as const, percentage: ((confident / total) * 100).toFixed(1) },
    { count: maybe, type: 'maybe' as const, percentage: ((maybe / total) * 100).toFixed(1) },
    { count: guessing, type: 'guessing' as const, percentage: ((guessing / total) * 100).toFixed(1) }
  ];

  return (
    <div className="bg-white/20 rounded-lg p-2.5">
      <div className="flex items-center justify-between mb-2">
        <span className="text-white/90 text-sm">{title} ({total})</span>
        <div className="flex items-center gap-2 text-xs">
          <div className="flex items-center text-green-200">
            <CheckCircle className="w-3.5 h-3.5 mr-1" />
            <span>{correct}</span>
          </div>
          <div className="flex items-center text-red-200">
            <XCircle className="w-3.5 h-3.5 mr-1" />
            <span>{incorrect}</span>
          </div>
        </div>
      </div>
      <div className="flex gap-1">
        {confidenceStats.map(stat => (
          stat.count > 0 && (
            <CompactConfidenceIndicator 
              key={stat.type}
              count={stat.count} 
              type={stat.type}
              percentage={stat.percentage}
            />
          )
        ))}
      </div>
    </div>
  );
};

const AnalyticsOverviewCard: React.FC<AnalyticsOverviewCardProps> = ({ 
  userId, 
  courseId, 
  categoriesData, 
  simulationData,
  isLoading 
}) => {
  const stats = useMemo(() => {
    if (!categoriesData || !simulationData) return null;

    // Get MCQ stats
    const mcqStats = Object.values(categoriesData.structuredCategories).reduce((acc: MCQStats, categories) => {
      const allStats = categories[0];
      return {
        total: acc.total + allStats.total_questions,
        unanswered: acc.unanswered + allStats.unanswered_questions,
        correct: acc.correct + allStats.correct,
        incorrect: acc.incorrect + allStats.incorrect,
        confident: acc.confident + allStats.confident,
        maybe: acc.maybe + (allStats.maybe || 0),
        guessing: acc.guessing + (allStats.guessing || 0)
      };
    }, { total: 0, unanswered: 0, correct: 0, incorrect: 0, confident: 0, maybe: 0, guessing: 0 });

    // Get simulation stats
    const simStats = simulationData.overallStats;

    // Calculate totals
    const totalQuestions = mcqStats.total + (simStats.total || 0);
    const totalAnswered = totalQuestions - (mcqStats.unanswered + (simStats.unanswered || 0));
    const totalCorrect = mcqStats.correct + (simStats.correct || 0);
    const totalConfident = mcqStats.confident + (simStats.confident || 0);

    return {
      total: totalQuestions,
      unanswered: mcqStats.unanswered + (simStats.unanswered || 0),
      accuracy: totalAnswered > 0 ? Math.round((totalCorrect / totalAnswered) * 100) : 0,
      confident: {
        total: totalConfident,
        percentage: Math.round((totalConfident / totalQuestions) * 100)
      },
      mcq: {
        total: mcqStats.total,
        correct: mcqStats.correct,
        incorrect: mcqStats.incorrect,
        confident: mcqStats.confident,
        maybe: mcqStats.maybe,
        guessing: mcqStats.guessing
      },
      simulations: {
        total: simStats.total || 0,
        correct: simStats.correct || 0,
        incorrect: simStats.incorrect || 0,
        confident: simStats.confident || 0,
        maybe: simStats.maybe || 0,
        guessing: simStats.guessing || 0
      }
    };
  }, [categoriesData, simulationData]);

  if (isLoading || !stats) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-3 animate-pulse">
        <div className="h-6 bg-gray-200 dark:bg-gray-700 rounded w-1/3 mb-3"></div>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="h-20 bg-gray-200 dark:bg-gray-700 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-r from-primary-blue to-blue-600 rounded-lg shadow-sm p-3">
      <div className="flex flex-col space-y-3">
        {/* Header */}
        <div className="flex items-center justify-between">
          <h3 className="text-white text-lg font-semibold">Performance Analytics Overview</h3>
          <div className="flex items-center gap-1.5">
            <BrainCircuit className="w-4 h-4 text-white/90" />
            <span className="text-white/90 text-sm">Updated Daily</span>
          </div>
        </div>
        
        {/* Main Stats Grid */}
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
          <div className="bg-white/20 rounded-lg p-2.5">
            <div className="text-white/90 text-sm mb-1">Total Questions</div>
            <div className="text-white text-xl font-bold flex items-center gap-1.5">
              <ListCheck className="w-5 h-5" />
              {stats.total}
            </div>
          </div>

          <div className="bg-white/20 rounded-lg p-2.5">
            <div className="text-white/90 text-sm mb-1">Questions Left</div>
            <div className="text-white text-xl font-bold flex items-center gap-1.5">
              <FileQuestion className="w-5 h-5" />
              {stats.unanswered}
            </div>
          </div>

          <div className="bg-white/20 rounded-lg p-2.5">
            <div className="text-white/90 text-sm mb-1">Confident Progress</div>
            <div className="text-white text-xl font-bold flex items-center gap-1.5">
              <Sparkle className="w-5 h-5" />
              {stats.confident.percentage}%
            </div>
          </div>

          <div className="bg-white/20 rounded-lg p-2.5">
            <div className="text-white/90 text-sm mb-1">Accuracy Rate</div>
            <div className="text-white text-xl font-bold flex items-center gap-1.5">
              <TrendingUp className="w-5 h-5" />
              {stats.accuracy}%
            </div>
          </div>
        </div>

        {/* Question Type Cards - Grid for desktop, Stack for mobile */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          {/* MCQ Card */}
          <QuestionTypeCard 
            title="Multiple Choice"
            total={stats.mcq.total}
            correct={stats.mcq.correct}
            incorrect={stats.mcq.incorrect}
            confident={stats.mcq.confident}
            maybe={stats.mcq.maybe}
            guessing={stats.mcq.guessing}
          />

          {/* Simulations Card */}
          <QuestionTypeCard 
            title="Simulations"
            total={stats.simulations.total}
            correct={stats.simulations.correct}
            incorrect={stats.simulations.incorrect}
            confident={stats.simulations.confident}
            maybe={stats.simulations.maybe}
            guessing={stats.simulations.guessing}
          />
        </div>

        {/* Progress Bar Card */}
        <div className="bg-white/20 rounded-lg p-2.5">
          <div className="flex items-center gap-2 mb-2">
            <Target className="w-4 h-4 text-white/90" />
            <span className="text-white/90 text-sm">Questions Completed</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex-grow">
              <div className="w-full bg-gray-200/30 rounded-full h-2.5">
                <div 
                  className="bg-sky-200 h-2.5 rounded-full transition-all duration-500"
                  style={{ 
                    width: `${Math.round(((stats.total - stats.unanswered) / stats.total) * 100)}%` 
                  }}
                ></div>
              </div>
            </div>
            <span className="text-white text-sm font-medium">
              {Math.round(((stats.total - stats.unanswered) / stats.total) * 100)}%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsOverviewCard;