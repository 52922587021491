import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../services/auth';
import { api } from '../../services/api';
import { queryClient } from '../../services/queryClient';
import { cacheManager } from '../../services/api';
import { useAuth } from '../../components/AuthProvider';
import { supabase } from '../../services/supabase';

function Logout() {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const performLogout = async () => {
      try {
        // Set logout message first
        sessionStorage.setItem('authMessage', 'Successfully logged out');

        // Prevent further data fetches
        queryClient.clear();
        queryClient.removeQueries();
        
        // Clear all caches
        cacheManager.clear();
        await api.clearAllCache();
        
        // Clear user-specific caches if user exists
        if (user?.id) {
          await api.clearUserCache(user.id);
        }

        // Clear all storage
        Object.keys(localStorage).forEach(key => {
          localStorage.removeItem(key);
        });
        sessionStorage.removeItem('supabase.auth.token');
        
        // Sign out with Supabase
        await supabase.auth.signOut();

        // Immediate redirect to login using href instead of replace
        window.location.href = '/login';
      } catch (error) {
        console.error('Error during logout:', error);
        
        // Force cleanup on error
        sessionStorage.setItem('authMessage', 'Logged out due to an error');
        localStorage.clear();
        sessionStorage.clear();
        queryClient.clear();
        cacheManager.clear();
        window.location.href = '/login';
      }
    };

    performLogout();
  }, [user]); // Removed navigate from deps as it's not used

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="text-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white mx-auto mb-4"></div>
        <div className="text-gray-900 dark:text-white">Logging out...</div>
      </div>
    </div>
  );
}

export default Logout;