import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { api, isMockExamMCQ, TIER_IDS } from '../../services/api';
import type { MockExam, MockExamTestlet, QuizQuestionData, Product } from '../../services/api';
import { Button } from '../ui/Button';
import MockTimer from "../ui/MockTimer";
import { useMockExamTimer }  from '../../contexts/TimerContext';
import { Card } from '../ui/Card';
import { AlertCircle, BookOpen, Clock, CheckCircle, ChevronRight, PlayCircle, PauseCircle, Crown, ChevronDown, ChevronUp, Target, Book, Award } from 'lucide-react';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import UpgradeModal from './common/UpgradeModal';

// This component now controls the entire flow of the Mock Exam:
// 1) If 0 testlets completed: Show instructions + Start Exam (Testlet #1).
// 2) After Testlet #1: Show break screen + Start Testlet #2.
// 3) After Testlet #2: Show break screen + Start Testlet #3.
// 4) After Testlet #3: Show special break screen (timer paused) + Start Testlet #4 (resume timer).
// 5) After Testlet #4: Show break screen + Start Testlet #5.
// 6) After Testlet #5: Show final results table.
//
// Timer Logic:
// - Start the 4-hour timer when user starts Testlet #1.
// - After Testlet #3 completes, pause the timer (simulating a 15-min break).
// - On starting Testlet #4, resume the timer.
// - After Testlet #5 completes, stop the timer.
//
// We leverage local component state for timer management.
// If the user refreshes mid-exam, the timer will reset (out of scope for now).
//
// We use tailwind classes for styling and ensure dark mode compatibility.

interface MockExamDetailProps {
  courseId: string;
  userId: string;
  mockExamId?: string;
  isReview?: boolean;
  products: Product[];  // Add products to props
}

interface TestletStatistics {
  totalQuestions: number;
  correctAnswers: number;
  incorrectAnswers: number;
  unanswered: number;
  averageTimePerQuestion: number;
  confidenceLevels?: {
    confident: number;
    maybe: number;
    guessing: number;
    confident_correct: number;
    confident_incorrect: number;
    maybe_correct: number;
    maybe_incorrect: number;
    guessing_correct: number;
    guessing_incorrect: number;
  };
}

interface ExamStructure {
  testletNumber: number;
  description: string;
  questionCount: number;
  type: 'MCQ' | 'TBS';
}
// Function to get exam structure based on course ID
const getExamStructure = (courseId: string): ExamStructure[] => {
  switch (courseId) {
    // AUD - Auditing and Attestation
    case 'e780a7b3-dcd1-4740-ba54-014e594326a4': // AUD_2024
      return [
        { testletNumber: 1, description: "Multiple Choice Questions", questionCount: 39, type: 'MCQ' },
        { testletNumber: 2, description: "Multiple Choice Questions", questionCount: 39, type: 'MCQ' },
        { testletNumber: 3, description: "Task-Based Simulations", questionCount: 2, type: 'TBS' },
        { testletNumber: 4, description: "Task-Based Simulations", questionCount: 3, type: 'TBS' },
        { testletNumber: 5, description: "Task-Based Simulations", questionCount: 2, type: 'TBS' }
      ];
    
    // FAR - Financial Accounting and Reporting
    case '2e7dd01a-dffa-481d-9359-17594c903eb0': // FAR_2024
      return [
        { testletNumber: 1, description: "Multiple Choice Questions", questionCount: 25, type: 'MCQ' },
        { testletNumber: 2, description: "Multiple Choice Questions", questionCount: 25, type: 'MCQ' },
        { testletNumber: 3, description: "Task-Based Simulations", questionCount: 2, type: 'TBS' },
        { testletNumber: 4, description: "Task-Based Simulations", questionCount: 3, type: 'TBS' },
        { testletNumber: 5, description: "Task-Based Simulations", questionCount: 2, type: 'TBS' }
      ];
    
    // REG - Regulation
    case '2d55838e-52b1-46d3-8bdf-8d691e28e596': // REG_2024
      return [
        { testletNumber: 1, description: "Multiple Choice Questions", questionCount: 36, type: 'MCQ' },
        { testletNumber: 2, description: "Multiple Choice Questions", questionCount: 36, type: 'MCQ' },
        { testletNumber: 3, description: "Task-Based Simulations", questionCount: 2, type: 'TBS' },
        { testletNumber: 4, description: "Task-Based Simulations", questionCount: 3, type: 'TBS' },
        { testletNumber: 5, description: "Task-Based Simulations", questionCount: 3, type: 'TBS' }
      ];
    
    // BAR - Business Analysis and Reporting
    case '7b008bd2-4b5d-4876-82d5-c9f1e05895e0': // BAR_2024
      return [
        { testletNumber: 1, description: "Multiple Choice Questions", questionCount: 25, type: 'MCQ' },
        { testletNumber: 2, description: "Multiple Choice Questions", questionCount: 25, type: 'MCQ' },
        { testletNumber: 3, description: "Task-Based Simulations", questionCount: 2, type: 'TBS' },
        { testletNumber: 4, description: "Task-Based Simulations", questionCount: 3, type: 'TBS' },
        { testletNumber: 5, description: "Task-Based Simulations", questionCount: 2, type: 'TBS' }
      ];
    
    // ISC - Information Systems and Controls
    case '2ced0142-2bd9-423e-a541-9ac6bfed88ef': // ISC_2024
      return [
        { testletNumber: 1, description: "Multiple Choice Questions", questionCount: 41, type: 'MCQ' },
        { testletNumber: 2, description: "Multiple Choice Questions", questionCount: 41, type: 'MCQ' },
        { testletNumber: 3, description: "Task-Based Simulations", questionCount: 1, type: 'TBS' },
        { testletNumber: 4, description: "Task-Based Simulations", questionCount: 3, type: 'TBS' },
        { testletNumber: 5, description: "Task-Based Simulations", questionCount: 2, type: 'TBS' }
      ];
    
    // TCP - Tax Compliance and Planning
    case 'ffbf1eef-d731-4100-9bc7-8dda62a586d3': // TCP_2024
      return [
        { testletNumber: 1, description: "Multiple Choice Questions", questionCount: 34, type: 'MCQ' },
        { testletNumber: 2, description: "Multiple Choice Questions", questionCount: 34, type: 'MCQ' },
        { testletNumber: 3, description: "Task-Based Simulations", questionCount: 2, type: 'TBS' },
        { testletNumber: 4, description: "Task-Based Simulations", questionCount: 3, type: 'TBS' },
        { testletNumber: 5, description: "Task-Based Simulations", questionCount: 2, type: 'TBS' }
      ];
    
    default:
      console.warn(`No specific exam structure found for course ID: ${courseId}. Using default structure.`);
      return [
        { testletNumber: 1, description: "Multiple Choice Questions", questionCount: 38, type: 'MCQ' },
        { testletNumber: 2, description: "Multiple Choice Questions", questionCount: 38, type: 'MCQ' },
        { testletNumber: 3, description: "Task-Based Simulations", questionCount: 2, type: 'TBS' },
        { testletNumber: 4, description: "Task-Based Simulations", questionCount: 3, type: 'TBS' },
        { testletNumber: 5, description: "Task-Based Simulations", questionCount: 3, type: 'TBS' }
      ];
  }
};

export default function MockExamDetail({
  courseId,
  userId,
  mockExamId,
  isReview = false,
  products,
}: MockExamDetailProps) {
  const [testlets, setTestlets] = useState<MockExamTestlet[]>([]);
  const [mockExam, setMockExam] = useState<MockExam | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [processingTestlet, setProcessingTestlet] = useState<string | null>(null);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const { userCourseAccesses } = useUserCourseAccess();
  const examStructure = getExamStructure(courseId);
  const navigate = useNavigate();
  const params = useParams();

  const examId = mockExamId || params.mockExamId || "";
  const cId = courseId || params.courseId || "";
  const uId = userId;
  const currentProduct = products.find(product => product.id === cId);
  
  // Timer State and Context setup stays
  const timer = useMockExamTimer();
  const [finalTime, setFinalTime] = useState<number | null>(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const testlet3HandledRef = useRef(false);

   // Access control check
   const hasMockExamAccess = useMemo(() => {
    if (!userCourseAccesses || !Array.isArray(userCourseAccesses)) return false;
    
    const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
    if (courseAccesses.length === 0) return false;

    const hasFullAccess = courseAccesses.some(access => access.tier_id === TIER_IDS.FULL_ACCESS);
    const hasStudyMaterials = courseAccesses.some(access => access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY);
    const hasMentorAccess = courseAccesses.some(access => access.tier_id === TIER_IDS.MENTOR_ONLY);

    return hasFullAccess || hasStudyMaterials || (hasStudyMaterials && hasMentorAccess);
  }, [userCourseAccesses, courseId]);

  useEffect(() => {
    // Redirect if no access and exam is not completed
    if (mockExam && !mockExam.completed && !hasMockExamAccess) {
      navigate(`/course/${courseId}/mock-exam`);
    }
  }, [mockExam, hasMockExamAccess, courseId, navigate]);
  
  // Add this effect to maintain timer state
  useEffect(() => {
    if (!mockExam?.completed && timer.isRunning) {
      const savedStartTime = localStorage.getItem('mockExamStartTime');
      if (savedStartTime) {
        const startTime = parseInt(savedStartTime);
        if (!isNaN(startTime) && startTime < Date.now()) {
          timer.setStartTime(startTime);
        }
      }
    }
  }, [mockExam]);
  
  // Compute completed testlets
  const completedCount = testlets.filter((t) => t.completed).length;

  // Stop timer on completion
  useEffect(() => {
    if (mockExam?.completed && timer.isRunning) {
      timer.setIsRunning(false);
      const totalElapsed = Math.floor((Date.now() - timer.startTime) / 1000) + timer.elapsedTimeBeforePause;
      setFinalTime(totalElapsed);
    }
  }, [mockExam, timer]);

  // Update handleTimeUpdate with logging
  const handleTimeUpdate = (elapsed: number) => {
    if (mockExam?.completed && !finalTime) {
      setFinalTime(elapsed);
    }
  };

  // Add this new consolidated effect
  useEffect(() => {
    const handleTestlet3Completion = () => {
      if (!testlets.length) return;
  
      const testlet3 = testlets.find(t => t.testlet_number === 3);
      
      // Only handle testlet 3 completion when we've just completed 3 testlets
      // This prevents triggering for testlets 4 and 5
      if (!testlet3?.completed || testlet3HandledRef.current || completedCount !== 3) return;
  
      // Set flag first to prevent multiple executions
      testlet3HandledRef.current = true;
  
      // Calculate elapsed time
      const currentTime = Date.now();
      const elapsedSeconds = Math.floor((currentTime - timer.startTime) / 1000) + timer.elapsedTimeBeforePause;
  
      console.log('Pausing timer for testlet 3', {
        currentTime,
        startTime: timer.startTime,
        elapsedTimeBeforePause: timer.elapsedTimeBeforePause,
        elapsedSeconds,
        completedCount
      });
  
      // Update timer state synchronously
      timer.setElapsedTimeBeforePause(elapsedSeconds);
      timer.setIsRunning(false);
      timer.setSpecialBreakPaused(true);
  
      // Save to localStorage
      localStorage.setItem('mockExamElapsedTime', elapsedSeconds.toString());
      localStorage.setItem('mockExamTimerPaused', 'true');
  
      // Force a recheck of timer state after a short delay
      setTimeout(() => {
        if (timer.isRunning) {
          console.log('Forcing timer stop');
          timer.setIsRunning(false);
        }
      }, 100);
    };
  
    handleTestlet3Completion();
  }, [testlets, timer, completedCount]); // Add completedCount to dependencies

  // Add this new reset effect
  useEffect(() => {
    testlet3HandledRef.current = false;
    localStorage.removeItem('mockExamTimerPaused');
  }, [mockExamId]);

  // Add this effect after the existing timer effects
  useEffect(() => {
    if (!mockExam?.completed && timer.isRunning) {
      const saveInterval = setInterval(() => {
        const currentTime = Date.now();
        const elapsedSeconds = Math.floor((currentTime - timer.startTime) / 1000) + timer.elapsedTimeBeforePause;
        
        if (examId) {
          api.saveMockExamTimerState(examId, elapsedSeconds);
        }
      }, 60000); // Save every minute

      return () => clearInterval(saveInterval);
    }
  }, [mockExam, timer.isRunning, timer.startTime, timer.elapsedTimeBeforePause, examId]);
  
  // Update the fetchMockExam function with access check
  const fetchMockExam = async () => {
    if (!examId || !cId || !uId) return;
    try {
      const mockExams = await api.getMockExamHistory(uId, cId);
      const currentExam = mockExams.find((exam) => exam.id === examId);
      
      if (currentExam) {
        // If exam is not completed and user doesn't have access, redirect
        if (!currentExam.completed && !hasMockExamAccess) {
          navigate(`/course/${cId}/mock-exam`);
          return;
        }
        setMockExam(currentExam);
      } else {
        setError("Mock exam not found");
      }
    } catch (err: any) {
      setError("Failed to load mock exam");
      console.error(err);
    }
  };

   // Modified fetch testlets
   const fetchTestlets = async () => {
    if (!cId || !examId) return;
    try {
      const data = await api.getMockExamTestlets(examId);
      setTestlets(data);
    } catch (err: any) {
      setError("Failed to load testlets");
      console.error(err);
    }
  };
  
  useEffect(() => {
    fetchMockExam();
    fetchTestlets();
  }, [cId, examId, uId]);
  
  const handleStartTestlet = async (t: MockExamTestlet) => {
    if (!cId || !uId || !examId || !mockExam || processingTestlet === t.id || !hasMockExamAccess) {
      if (!hasMockExamAccess) {
        setIsUpgradeModalOpen(true);
        return;
      }
      return;
    }

    setProcessingTestlet(t.id);
    setError(null);
  
    try {
      // Check for existing quiz history before proceeding
      const existingQuizHistory = await api.getQuizHistoryByTestletId(t.id);
      
      if (existingQuizHistory) {
        // Navigate to existing quiz/simulation based on testlet type
        if (t.testlet_number <= 2) {
          navigate(`/course/${cId}/quiz?mock_exam_testlet_id=${t.id}&quiz_history_id=${existingQuizHistory.id}`);
        } else {
          navigate(`/course/${cId}/simulations?mock_exam_testlet_id=${t.id}&quiz_history_id=${existingQuizHistory.id}`);
        }
        return;
      }

      // Update the testlet 4 handling section in handleStartTestlet
      if (t.testlet_number === 4) {
        const now = Date.now();
        const savedElapsedTime = parseInt(localStorage.getItem('mockExamElapsedTime') || '0');
        
        // Calculate the resume start time
        const resumeStartTime = now - (savedElapsedTime * 1000);
        
        console.log('Starting timer resume sequence for testlet 4', {
          now,
          savedElapsedTime,
          resumeStartTime,
          currentState: {
            isRunning: timer.isRunning,
            specialBreakPaused: timer.specialBreakPaused,
            elapsedBeforePause: timer.elapsedTimeBeforePause
          }
        });

        try {
          // First, reset all timer states to ensure clean slate
          localStorage.removeItem('mockExamTimerPaused');
          
          // Update states one by one with proper sequence
          await new Promise<void>(resolve => {
            timer.setElapsedTimeBeforePause(savedElapsedTime);
            resolve();
          });
          
          await new Promise<void>(resolve => {
            timer.setStartTime(resumeStartTime);
            localStorage.setItem('mockExamStartTime', resumeStartTime.toString());
            resolve();
          });
          
          await new Promise<void>(resolve => {
            timer.setSpecialBreakPaused(false);
            resolve();
          });
          
          // Small delay before setting running state
          await new Promise(resolve => setTimeout(resolve, 100));
          
          // Set running state last
          await new Promise<void>(resolve => {
            timer.setIsRunning(true);
            localStorage.setItem('mockExamIsRunning', 'true');
            resolve();
          });

          // Verify final state
          await new Promise(resolve => setTimeout(resolve, 100));
          
          const finalState = {
            isRunning: timer.isRunning,
            specialBreakPaused: timer.specialBreakPaused,
            elapsedBeforePause: timer.elapsedTimeBeforePause,
            startTime: timer.startTime
          };

          console.log('Final timer state after resume:', finalState);

          // Double-check if state needs to be enforced
          if (!finalState.isRunning) {
            console.log('Enforcing running state one final time');
            timer.setIsRunning(true);
            localStorage.setItem('mockExamIsRunning', 'true');
          }

        } catch (error) {
          console.error('Error resuming timer for testlet 4:', error);
        }
      }
  
      // Handle MCQ testlets (1-2)
      if (t.testlet_number <= 2) {
        console.log('Creating MCQ history for testlet', {
          testletNumber: t.testlet_number,
          isRunning: timer.isRunning
        });
  
        const quizHistoryId = await api.createMockExamMCQHistory(
          uId,
          cId,
          examId,
          t.id,
          mockExam.exam_number,
          t.testlet_number,
          t.questions_data
        );
  
        // Verify timer running for testlet 1
        if (t.testlet_number === 1 && !timer.isRunning) {
          timer.setIsRunning(true);
        }
  
        navigate(`/course/${cId}/quiz?mock_exam_testlet_id=${t.id}&quiz_history_id=${quizHistoryId}`);
      } else {
        // Handle simulation testlets (3-5)
        console.log('Creating simulation history for testlet', {
          testletNumber: t.testlet_number,
          isRunning: timer.isRunning
        });
  
        const simulationQuestionsData = t.questions_data.map((simId: string) => ({
          type: "mock_exam_sim" as const,
          score: 0,
          answered: false,
          feedback: [],
          isCorrect: false,
          timeSpent: 0,
          questionId: simId,
          userAnswer: "",
          simulationId: simId,
          confidenceLevel: null,
          question_results: [],
          free_trial_content: false,
          mockExamId: examId,
          mockExamTestletId: t.id,
          testletNumber: t.testlet_number,
          mockExamNumber: mockExam.exam_number,
        }));
  
        const quizHistoryId = await api.createQuizHistory(
          uId,
          cId,
          simulationQuestionsData,
          `Mock Exam #${mockExam.exam_number} - Testlet ${t.testlet_number}`,
          t.id
        );
  
        navigate(`/course/${cId}/simulations?mock_exam_testlet_id=${t.id}&quiz_history_id=${quizHistoryId}`);
      }
    } catch (err: any) {
      console.error("Error starting testlet:", err);
      setError(err.message || "Failed to start testlet");
    } finally {
      setProcessingTestlet(null);
    }
  };

  const handleReviewTestletResults = async (t: MockExamTestlet) => {
    // Allow viewing completed testlets even without access
    if (!mockExam?.completed && !hasMockExamAccess) {
      setIsUpgradeModalOpen(true);
      return;
    }

    try {
      const quizHistory = await api.getQuizHistoryByTestletId(t.id);
      if (!quizHistory) {
        throw new Error("Quiz history not found");
      }
  
      const firstQuestion = quizHistory.questions_data[0] as QuizQuestionData;
  
      if (isMockExamMCQ(firstQuestion)) {
        // Navigate to quiz review with necessary state
        navigate(`/course/${cId}/quiz`, { 
          state: { 
            viewQuizId: quizHistory.id,
            quizData: quizHistory,
            from: 'analytics',
            showSummary: true,
            quizCompleted: true,
            mockExamContext: {
              mockExamId: examId,
              testletId: t.id,
              testletNumber: t.testlet_number
            }
          }
        });
      } else {
        // For simulations, keep existing navigation
        navigate(`/course/${cId}/simulation-review`, {
          state: {
            quizHistoryId: quizHistory.id,
            courseId: cId,
            completed: true,
            mockExamId: examId,
          },
        });
      }
    } catch (error) {
      console.error("Error handling testlet review:", error);
      setError("Failed to load testlet review");
    }
  };

  const getRecommendation = (score: number, timeSpent: number) => {
    const allowedTimePerTestlet = (240 * 60) / 5; // ~48 min/testlet
    const overTime = timeSpent > allowedTimePerTestlet;
    if (overTime && score < 75) {
      return "Focus on both time management and accuracy. Review question pacing.";
    } else if (overTime) {
      return "Focus on time management.";
    } else if (score < 75) {
      return "Good time management, improve accuracy.";
    }
    return "Excellent balance of time and accuracy!";
  };

  const formatTime = (seconds: number | null): string => {
    if (seconds === null) return "--";
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}m ${secs}s`;
  };


  const renderInstructions = () => {
    return (
      <Card className="max-w-4xl mx-auto p-6">
        <div className="mb-6">
          <div className="flex items-center space-x-2 mb-4">
            <BookOpen className="h-6 w-6 text-primary-blue" />
            <h2 className="text-2xl font-bold">Mock Exam #{mockExam?.exam_number}</h2>
          </div>
        </div>

        {error && (
          <div className="flex items-center p-4 mb-6 bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-200 rounded-lg">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}

        <div className="space-y-6">
          <div className="prose dark:prose-invert max-w-none">
            <p className="text-lg font-medium">Welcome to your <strong>{currentProduct?.description || 'CPA'}</strong> Mock Exam!</p>
            
            <div className="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg mb-6">
              <h3 className="text-lg font-semibold text-blue-800 dark:text-blue-200 mb-2">
                Things To Consider:
              </h3>
              <ul className="list-disc ml-6 space-y-2 text-blue-700 dark:text-blue-300">
                <li>The REAL CPA Exam is a 4 hour timed exam. For time management purposes, we give you 48 minutes per testlet to help you pace yourself.</li>
                <li>Use this mock exam as a tool to prepare for the Prometric Center experience.</li>
                <li>Accuracy during this exam is important, but understand that scoring below 75% doesn't necessarily indicate exam failure due to weighted scoring.</li>
                <li>Focus on testlet time management, break timing, mental focus, and reducing test anxiety.</li>
                <li><strong>IMPORTANT:</strong> Minimize distractions and recreate the Prometric Center environment.</li>
              </ul>
            </div>

            <div className="bg-gray-50 dark:bg-gray-800/50 p-4 rounded-lg mb-6">
              <h3 className="text-lg font-semibold mb-2">Exam Structure:</h3>
              <div className="space-y-3">
                {examStructure.map((structure) => (
                  <div key={structure.testletNumber} className="flex items-center">
                    <div className="w-8 h-8 rounded-full bg-primary-blue text-white flex items-center justify-center mr-3">
                      {structure.testletNumber}
                    </div>
                    <span className="text-gray-700 dark:text-gray-300">
                      {structure.questionCount} {structure.type === 'MCQ' ? 'Multiple Choice Questions' : 'Task-Based Simulations'}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-yellow-50 dark:bg-yellow-900/30 p-4 rounded-lg mb-6">
              <h3 className="text-lg font-semibold text-yellow-800 dark:text-yellow-200 mb-2">
                Important Notes:
              </h3>
              <ul className="list-disc ml-6 space-y-2 text-yellow-700 dark:text-yellow-300">
                <li>On the real exam, each multiple-choice testlet contains pretest questions that don't count toward your score.</li>
                <li>One of the task-based simulation testlets contains a pretest simulation.</li>
                <li>Pretest materials are not identified during the exam.</li>
              </ul>
            </div>
          </div>

          {testlets[0] && !testlets[0].completed && (
            <div className="flex justify-center">
              <Button 
                onClick={() => handleStartTestlet(testlets[0])}
                className="bg-primary-blue hover:bg-blue-600 text-white px-6 py-3 rounded-lg inline-flex items-center justify-center space-x-2"
              >
                <PlayCircle className="h-5 w-5" />
                <span>Start Exam (Testlet #1)</span>
              </Button>
            </div>
          )}
        </div>
      </Card>
    );
  };


  const renderBreakScreen = (testletNumberToStart: number) => {
    let message = testletNumberToStart === 4
      ? "You may take optional break here (this timer will stop the 4 hour clock for up to 15 minutes on the real exam). Click the button below to begin Testlet #4"
      : `You may take an optional break here (but timer continues to run) or click the button below to begin Testlet #${testletNumberToStart}`;
  
    const nextTestlet = testlets.find((t) => t.testlet_number === testletNumberToStart);
  
    return (
      <Card className="max-w-lg mx-auto p-6 text-center">
        <div className="mb-6">
          <h2 className="text-2xl font-bold mb-2">Mock Exam #{mockExam?.exam_number}</h2>
          
          {error && (
            <div className="flex items-center p-4 mb-4 bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-200 rounded-lg">
              <AlertCircle className="h-5 w-5 mr-2" />
              {error}
            </div>
          )}
          
          <div className="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg mb-6">
            <p className="text-blue-700 dark:text-blue-300">{message}</p>
          </div>
  
          {/* Warning card */}
          <div className="p-4 bg-yellow-50 dark:bg-yellow-900/30 rounded-lg text-left">
            <div className="flex items-start space-x-3">
              <AlertCircle className="h-5 w-5 text-yellow-600 dark:text-yellow-400 mt-0.5 flex-shrink-0" />
              <div className="space-y-1">
                <p className="text-sm text-yellow-700 dark:text-yellow-300">
                  Remember to:
                </p>
                <ul className="list-disc ml-4 text-sm text-yellow-600 dark:text-yellow-400 space-y-1">
                  <li>Stay focused and manage your time carefully</li>
                  <li>Read each question thoroughly before answering</li>
                  <li>Use breaks wisely to maintain mental clarity</li>
                  <li>Double-check your answers before submitting each testlet</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
  
        {nextTestlet && (
          <Button
            onClick={() => handleStartTestlet(nextTestlet)}
            disabled={processingTestlet === nextTestlet.id}
            className="bg-primary-blue hover:bg-blue-600 text-white px-6 py-3 rounded-lg inline-flex items-center justify-center space-x-2 mx-auto"
          >
            {processingTestlet === nextTestlet?.id ? (
              <>
                <span className="animate-spin">⌛</span>
                <span>Starting...</span>
              </>
            ) : (
              <>
                <PlayCircle className="h-5 w-5" />
                <span>Start Testlet #{testletNumberToStart}</span>
              </>
            )}
          </Button>
        )}
      </Card>
    );
  };

  const calculateTestletStatistics = async (testlet: MockExamTestlet): Promise<TestletStatistics | null> => {
    try {
      const quizHistory = await api.getQuizHistoryByTestletId(testlet.id);
      if (!quizHistory || !quizHistory.questions_data) return null;

      const stats: TestletStatistics = {
        totalQuestions: quizHistory.questions_data.length,
        correctAnswers: 0,
        incorrectAnswers: 0,
        unanswered: 0,
        averageTimePerQuestion: 0,
        confidenceLevels: {
          confident: 0,
          maybe: 0,
          guessing: 0,
          confident_correct: 0,
          confident_incorrect: 0,
          maybe_correct: 0,
          maybe_incorrect: 0,
          guessing_correct: 0,
          guessing_incorrect: 0
        }
      };

      let totalTime = 0;
      
      quizHistory.questions_data.forEach((question: QuizQuestionData) => {
        if (question.isCorrect) stats.correctAnswers++;
        else if (question.answered) stats.incorrectAnswers++;
        else stats.unanswered++;

        totalTime += question.timeSpent || 0;

        // Track confidence levels and their correlation with correctness
        if (question.confidenceLevel) {
          const confidence = question.confidenceLevel.toLowerCase();
          const isCorrect = question.isCorrect;

          // Increment overall confidence counts
          switch (confidence) {
            case 'confident':
              stats.confidenceLevels!.confident++;
              if (isCorrect) stats.confidenceLevels!.confident_correct++;
              else stats.confidenceLevels!.confident_incorrect++;
              break;
            case 'maybe':
              stats.confidenceLevels!.maybe++;
              if (isCorrect) stats.confidenceLevels!.maybe_correct++;
              else stats.confidenceLevels!.maybe_incorrect++;
              break;
            case 'guessing':
              stats.confidenceLevels!.guessing++;
              if (isCorrect) stats.confidenceLevels!.guessing_correct++;
              else stats.confidenceLevels!.guessing_incorrect++;
              break;
          }
        }
      });

      stats.averageTimePerQuestion = totalTime / stats.totalQuestions;

      return stats;
    } catch (error) {
      console.error('Error calculating testlet statistics:', error);
      return null;
    }
  };

  const renderTestletStatistics = (stats: TestletStatistics) => {
    return (
      <div className="space-y-4">
        {/* Question Statistics */}
        <div className="grid grid-cols-3 gap-2">
          <div className="bg-green-50 dark:bg-green-900/30 p-2 rounded-lg text-center">
            <div className="text-lg font-bold text-green-600 dark:text-green-400">
              {stats.correctAnswers}
            </div>
            <div className="text-xs text-green-700 dark:text-green-300">Correct</div>
          </div>
          <div className="bg-red-50 dark:bg-red-900/30 p-2 rounded-lg text-center">
            <div className="text-lg font-bold text-red-600 dark:text-red-400">
              {stats.incorrectAnswers}
            </div>
            <div className="text-xs text-red-700 dark:text-red-300">Incorrect</div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-800/50 p-2 rounded-lg text-center">
            <div className="text-lg font-bold text-gray-600 dark:text-gray-400">
              {stats.unanswered}
            </div>
            <div className="text-xs text-gray-500">Unanswered</div>
          </div>
        </div>

        {/* Time Per Question */}
        <div className="bg-blue-50 dark:bg-blue-900/30 p-3 rounded-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Clock className="h-4 w-4 text-blue-600 dark:text-blue-400" />
              <span className="text-sm text-blue-700 dark:text-blue-300">
                Avg. Time per Question
              </span>
            </div>
            <span className="text-sm font-bold text-blue-600 dark:text-blue-400">
              {Math.round(stats.averageTimePerQuestion)}s
            </span>
          </div>
        </div>

        {/* Confidence Levels */}
        {stats.confidenceLevels && (
          <div className="bg-purple-50 dark:bg-purple-900/30 p-3 rounded-lg space-y-3">
            <div className="flex items-center space-x-2">
              <Target className="h-4 w-4 text-purple-600 dark:text-purple-400" />
              <span className="text-sm font-medium text-purple-700 dark:text-purple-300">
                Confidence Levels
              </span>
            </div>

            {/* Overall Confidence Distribution */}
            <div className="grid grid-cols-3 gap-2">
              <div className="bg-green-50 dark:bg-green-900/20 p-2 rounded-lg text-center">
                <div className="text-sm font-bold text-green-600 dark:text-green-400">
                  {stats.confidenceLevels.confident}
                </div>
                <div className="text-xs text-green-700 dark:text-green-300">Confident</div>
                <div className="text-xs text-green-600 dark:text-green-400 mt-1">
                  ({stats.confidenceLevels.confident_correct} correct)
                </div>
              </div>
              <div className="bg-yellow-50 dark:bg-yellow-900/20 p-2 rounded-lg text-center">
                <div className="text-sm font-bold text-yellow-600 dark:text-yellow-400">
                  {stats.confidenceLevels.maybe}
                </div>
                <div className="text-xs text-yellow-700 dark:text-yellow-300">Maybe</div>
                <div className="text-xs text-yellow-600 dark:text-yellow-400 mt-1">
                  ({stats.confidenceLevels.maybe_correct} correct)
                </div>
              </div>
              <div className="bg-red-50 dark:bg-red-900/20 p-2 rounded-lg text-center">
                <div className="text-sm font-bold text-red-600 dark:text-red-400">
                  {stats.confidenceLevels.guessing}
                </div>
                <div className="text-xs text-red-700 dark:text-red-300">Guessing</div>
                <div className="text-xs text-red-600 dark:text-red-400 mt-1">
                  ({stats.confidenceLevels.guessing_correct} correct)
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const [testletStats, setTestletStats] = useState<Record<string, TestletStatistics>>({});

  useEffect(() => {
    const loadTestletStats = async () => {
      if (!mockExam?.completed) return;
      
      const stats: Record<string, TestletStatistics> = {};
      
      for (const testlet of testlets) {
        if (testlet.completed) {
          const testletStat = await calculateTestletStatistics(testlet);
          if (testletStat) {
            stats[testlet.id] = testletStat;
          }
        }
      }
      
      setTestletStats(stats);
    };

    loadTestletStats();
  }, [mockExam, testlets]);

  const renderFinalResults = () => {
    return (
      <div className="max-w-7xl mx-auto space-y-6">
        {/* Header Card */}
        <Card className="p-6">
          <div className="space-y-4">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <h2 className="text-2xl font-bold">Mock Exam #{mockExam?.exam_number} Results</h2>
              {finalTime && (
                <div className="flex items-center gap-2 text-primary-blue bg-blue-50 dark:bg-blue-900/30 px-4 py-2 rounded-lg">
                  <Clock className="h-5 w-5" />
                  <span className="font-bold whitespace-nowrap">
                    {Math.floor(finalTime / 3600)}h {Math.floor((finalTime % 3600) / 60)}m
                  </span>
                </div>
              )}
            </div>
  
            {error && (
              <div className="flex items-center p-4 bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-200 rounded-lg">
                <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
                {error}
              </div>
            )}
  
            <div className="bg-green-50 dark:bg-green-900/30 p-4 rounded-lg">
              <div className="flex items-start space-x-2">
                <CheckCircle className="h-6 w-6 text-green-600 dark:text-green-400 flex-shrink-0" />
                <div className="space-y-2">
                  <p className="text-green-700 dark:text-green-300 font-medium">
                    Congratulations! You've completed your Kesler CPA Review Mock Exam!
                  </p>
                  <p className="text-sm text-green-600 dark:text-green-400">
                    Review your performance for each testlet below and identify areas for improvement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
  
        {/* Results Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {testlets.map((t) => {
            const totalTimeFormatted = formatTime(t.time_spent);
            const timeOver = t.time_spent != null && t.time_spent > 2880;
            const timeRating = t.completed ? (timeOver ? 'Over Time' : 'On Time') : '--';
            const timeRatingColor = timeOver 
              ? 'text-red-600 dark:text-red-400 bg-red-50 dark:bg-red-900/30'
              : 'text-green-600 dark:text-green-400 bg-green-50 dark:bg-green-900/30';
            const recommendation = t.completed && t.score != null && t.time_spent != null
              ? getRecommendation(t.score, t.time_spent)
              : '--';
            const scoreColor = t.completed && t.score != null
              ? t.score >= 75 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'
              : 'text-gray-600 dark:text-gray-400';
            
            const stats = testletStats[t.id];

            return (
              <Card key={t.id} className="overflow-hidden">
                {/* Card Header */}
                <div className="p-4 border-b border-gray-200 dark:border-gray-700">
                  <div className="flex justify-between items-start mb-2">
                    <div className="flex items-center gap-2">
                      <span className="px-3 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-200">
                        Testlet #{t.testlet_number}
                      </span>
                      <span className="px-3 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-gray-200">
                        {t.testlet_number <= 2 ? 'MCQ' : 'SIM'}
                      </span>
                    </div>
                    <span className={`px-3 py-1 rounded-full text-xs font-medium ${timeRatingColor}`}>
                      {timeRating}
                    </span>
                  </div>
                </div>

                {/* Card Body */}
                <div className="p-4 space-y-4">
                  {/* Score and Time Section */}
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
                      <div className="space-y-1">
                        <div className={`text-2xl font-bold ${scoreColor} flex items-center justify-center`}>
                          {t.completed && t.score != null ? `${t.score.toFixed(1)}%` : '--'}
                        </div>
                        <div className="text-sm text-gray-500 dark:text-gray-400 text-center">Score</div>
                      </div>
                    </div>
                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
                      <div className="space-y-1">
                        <div className="text-2xl font-bold text-gray-900 dark:text-gray-100 flex items-center justify-center">
                          {t.completed ? totalTimeFormatted : '--'}
                        </div>
                        <div className="text-sm text-gray-500 dark:text-gray-400 text-center">Time Spent</div>
                      </div>
                    </div>
                  </div>

                  {/* Statistics Section */}
                  {stats && renderTestletStatistics(stats)}

                  {/* Performance Analysis */}
                  {stats && (
                    <div className="bg-gray-50 dark:bg-gray-800/50 p-3 rounded-lg space-y-2">
                      <div className="flex items-center space-x-2">
                        <Book className="h-4 w-4 text-gray-600 dark:text-gray-400" />
                        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Performance Analysis
                        </span>
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-300">
                        <p>{recommendation}</p>
                      </div>
                    </div>
                  )}

                  {/* Review Button */}
                  {t.completed && (
                    <Button
                      onClick={() => handleReviewTestletResults(t)}
                      className="w-full bg-primary-blue hover:bg-blue-600 text-white"
                    >
                      <span className="inline-flex items-center justify-center space-x-2">
                        <span>Review Results</span>
                        <ChevronRight className="h-4 w-4" />
                      </span>
                    </Button>
                  )}
                </div>
              </Card>
            );
          })}
        </div>
      </div>
    );
  };

  // Modified timer render function to be a card
  const renderTimerCard = () => {
    if (completedCount === 0 && !timer.isRunning) return null;
    if (mockExam?.completed && !finalTime) return null;
    
    return (
      <Card className="max-w-lg mx-auto mb-6">
        <div className="p-4">
          <div className="flex items-center justify-center gap-3">
            {timer.specialBreakPaused ? (
              <>
                <PauseCircle className="h-6 w-6 text-yellow-500" />
                <h3 className="text-xl font-semibold text-yellow-500">Break Timer (Paused)</h3>
              </>
            ) : (
              <>
                <Clock className="h-6 w-6 text-primary-blue" />
                <h3 className="text-xl font-semibold text-primary-blue">Exam Timer</h3>
              </>
            )}
          </div>
          
          <div className="mt-3 text-center">
            {mockExam?.completed ? (
              <div className="text-3xl font-bold text-primary-blue dark:text-blue-400">
                Final Time: {Math.floor(finalTime! / 3600)}h {Math.floor((finalTime! % 3600) / 60)}m
              </div>
            ) : (
              <MockTimer
                onTimeUpdate={handleTimeUpdate}
                className="text-3xl font-bold"
                fixedTime={mockExam?.completed ? finalTime || undefined : undefined}
                variant="large"
                showIcon={false}
              />
            )}
          </div>
          
          {timer.specialBreakPaused && (
            <div className="mt-2 text-center text-sm text-yellow-600 bg-yellow-50 dark:bg-yellow-900/30 p-2 rounded">
              Timer paused for optional 15-minute break
            </div>
          )}
        </div>
      </Card>
    );
  };

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 p-4 md:p-8 max-w-[1000px] mx-auto rounded-lg shadow-md dark:shadow-gray-800">
      <div className="w-full max-w-7xl mx-auto">
        {!hasMockExamAccess && !mockExam?.completed && (
          <Card className="max-w-lg mx-auto mb-6">
            <div className="p-6 text-center">
              <Crown className="h-12 w-12 text-primary-orange mx-auto mb-4" />
              <h3 className="text-xl font-bold mb-2">Upgrade Required</h3>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                You need to upgrade your subscription to access mock exams.
              </p>
              <Button
                onClick={() => setIsUpgradeModalOpen(true)}
                className="bg-primary-orange hover:bg-primary-orange-hover text-white"
              >
                Upgrade Now
              </Button>
            </div>
          </Card>
        )}

        {/* Timer card */}
        {renderTimerCard()}

        {/* Main content */}
        {!mockExam || testlets.length === 0 ? (
          <Card className="max-w-lg mx-auto p-6">
            <div className="flex items-center justify-center space-x-2 text-gray-600 dark:text-gray-400">
              <span className="animate-spin">⌛</span>
              <span>Loading...</span>
            </div>
          </Card>
        ) : mockExam.completed ? (
          renderFinalResults()
        ) : (
          <>
            {completedCount === 0 && renderInstructions()}
            {completedCount === 1 && renderBreakScreen(2)}
            {completedCount === 2 && renderBreakScreen(3)}
            {completedCount === 3 && renderBreakScreen(4)}
            {completedCount === 4 && renderBreakScreen(5)}
          </>
        )}

        {/* Return to exam list button */}
        <div className="mt-6 text-center">
          <Button
            onClick={() => navigate(`/course/${courseId}/mock-exam`)}
            className="bg-orange-500 hover:bg-orange-600 dark:bg-gray-800 dark:hover:bg-gray-700 text-white dark:text-gray-300 inline-flex items-center justify-center space-x-2"
          >
            Return to Mock Exam List
          </Button>
        </div>
        <UpgradeModal
          isOpen={isUpgradeModalOpen}
          onClose={() => setIsUpgradeModalOpen(false)}
        />
      </div>
    </div>
  );
}