export const parseQuestionContent = (content: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  
  const table = doc.querySelector('table');
  const optionsList = Array.from(doc.querySelectorAll('ol')).find(ol => !ol.hasAttribute('class'));
  const romanList = doc.querySelector('ol.upper-roman');
  
  // Extract the first paragraph as the question text
  const questionParagraph = doc.querySelector('p');
  let questionText = questionParagraph ? questionParagraph.outerHTML : content;
  
  let tableHTML = '';
  let options: string[] = [];
  let romanListHTML = '';
  let isTableQuestion = false;

  // Handle roman list if present
  if (romanList) {
    romanListHTML = romanList.outerHTML;
    questionText = content.replace(romanListHTML, '');
  }

  // Check if the table contains 'data-choice'
  if (table) {
    const optionCells = table.querySelectorAll('.multi-choice[data-choice]');
    if (optionCells.length > 0) {
      // This is an MCQ table
      isTableQuestion = true;
      questionText = content.replace(table.outerHTML, '').trim();
      
      // Add classes for center alignment
      table.classList.add('w-full', 'border-collapse');
      
      // Modify the table header for MCQ format
      const headerRow = table.querySelector('thead tr');
      if (headerRow) {
        const firstTh = headerRow.querySelector('th');
        if (firstTh) firstTh.textContent = 'Option';
        headerRow.querySelectorAll('th').forEach(th => {
          th.classList.add('border', 'border-light-text', 'dark:border-dark-text', 'p-2', 'text-center', 'font-bold');
        });
      }
      
      // Process MCQ options from table
      optionCells.forEach((cell) => {
        const row = cell.closest('tr');
        if (row) {
          const letter = cell.getAttribute('data-choice');
          const optionText = Array.from(row.querySelectorAll('td'))
            .slice(1)
            .map(td => td.textContent)
            .join(' | ');
          options.push(`${letter}. ${optionText}`);
          
          // Update the first cell to show the option letter
          const firstTd = row.querySelector('td');
          if (firstTd) {
            firstTd.innerHTML = `<div class="multi-choice" data-choice="${letter}">${letter}</div>`;
            firstTd.classList.add('border', 'border-light-text', 'dark:border-dark-text', 'p-2', 'text-center', 'align-middle', 'cursor-pointer');
          }
          
          // Style all cells in the MCQ row
          row.querySelectorAll('td').forEach(td => {
            td.classList.add('border', 'border-light-text', 'dark:border-dark-text', 'p-2', 'text-center', 'align-middle');
          });
        }
      });
      
      tableHTML = table.outerHTML;
    } else {
      // This is a regular table, keep it in the question text
      // Do not modify or extract it
      if (optionsList) {
        // Process regular multiple choice options if present
        options = Array.from(optionsList.querySelectorAll('li')).map((li, index) => {
          const letter = String.fromCharCode(65 + index);
          return `${letter}. ${li.textContent || ''}`;
        });
        questionText = content.replace(optionsList.outerHTML, '').trim();
      }
    }
  } else if (optionsList) {
    // No table, just regular multiple choice options
    options = Array.from(optionsList.querySelectorAll('li')).map((li, index) => {
      const letter = String.fromCharCode(65 + index);
      return `${letter}. ${li.textContent || ''}`;
    });
    questionText = content.replace(optionsList.outerHTML, '').trim();
  }

  return { questionText, tableHTML, options, romanList: romanListHTML, isTableQuestion };
};