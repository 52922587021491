import { Link } from 'react-router-dom';
import { useAuth } from '../components/AuthProvider';

function Home() {
  const { user, loading } = useAuth();

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold mb-6 text-gray-900 dark:text-white">
          Welcome to Kesler CPA Review
        </h1>
        
        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
            Your Path to CPA Success
          </h2>
          <p className="mb-4 text-gray-600 dark:text-gray-300">
            Our comprehensive Kesler CPA Review is designed to help you prepare effectively for your CPA exam. 
            With expert-led courses, study plans, and a variety of practice materials, 
            you'll be well-equipped to tackle the exam with confidence.
          </p>
          
          {loading ? (
            <div className="flex justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
          ) : (
            user ? (
              <Link 
                to="/products" 
                className="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors duration-200"
              >
                Explore Courses
              </Link>
            ) : (
              <div className="space-x-4">
                <Link 
                  to="/login" 
                  className="inline-block bg-green-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-green-700 transition-colors duration-200"
                >
                  Log In
                </Link>
                <Link 
                  to="/register" 
                  className="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors duration-200"
                >
                  Register
                </Link>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;