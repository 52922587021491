import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown } from 'lucide-react';
import { useProducts } from '../ProductProvider';
import { useNavigate } from 'react-router-dom';

interface CourseDropdownProps {
  currentCourseId: string | undefined;
}

const CourseDropdown: React.FC<CourseDropdownProps> = ({ currentCourseId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentCourseTitle, setCurrentCourseTitle] = useState('Select a Course');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { getProduct } = useProducts();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCurrentCourse = async () => {
      if (currentCourseId) {
        const course = await getProduct(currentCourseId);
        if (course) {
          setCurrentCourseTitle(course.title);
        } else {
          setCurrentCourseTitle('Select a Course');
        }
      } else {
        setCurrentCourseTitle('Select a Course');
      }
    };

    fetchCurrentCourse();
  }, [currentCourseId, getProduct]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSwitchCourse = () => {
    navigate('/products');
    setIsOpen(false);
  };

  return (
    <div className="relative hidden md:block" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center text-xl md:text-2xl font-bold text-gray-800 dark:text-white focus:outline-none"
      >
        {currentCourseTitle}
        <ChevronDown size={20} className="ml-2 text-gray-600 dark:text-gray-300" />
      </button>
      {isOpen && (
        <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1">
            <button
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600"
              onClick={handleSwitchCourse}
            >
              Switch Course
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseDropdown;