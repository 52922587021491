import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '../ui/Dialog';
import { Button } from '../ui/Button';
import { XCircle, Trash2 } from 'lucide-react';
import type { MockExam } from '../../services/api';
import { useMockExamTimer } from '../../contexts/TimerContext';

interface MockExamActionProps {
  exam: MockExam;
  onEnd: (examId: string) => Promise<void>;
  onDelete: (examId: string) => Promise<void>;
}

type ProcessingAction = 'none' | 'end' | 'delete';

const MockExamActions: React.FC<MockExamActionProps> = ({ exam, onEnd, onDelete }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [processingAction, setProcessingAction] = useState<ProcessingAction>('none');
  const timer = useMockExamTimer();

  const handleEnd = async () => {
    setProcessingAction('end');
    try {
      await onEnd(exam.id);
      timer.resetTimer(); // Reset timer when ending exam
      setIsDialogOpen(false);
    } catch (error) {
      console.error('Failed to end exam:', error);
    } finally {
      setProcessingAction('none');
    }
  };

  const handleDelete = async () => {
    setProcessingAction('delete');
    try {
      await onDelete(exam.id);
      timer.resetTimer(); // Reset timer when deleting exam
      setIsDialogOpen(false);
    } catch (error) {
      console.error('Failed to delete exam:', error);
    } finally {
      setProcessingAction('none');
    }
  };

  if (!exam.completed) {
    return (
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogTrigger asChild>
          <Button
            variant="destructive"
            className="w-full bg-red-500 hover:bg-red-600 text-white"
            disabled={processingAction !== 'none'}
          >
            <span className="inline-flex items-center gap-2">
              <XCircle className="h-4 w-4 flex-shrink-0" />
              <span>End Exam</span>
            </span>
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogTitle>End Mock Exam #{exam.exam_number}?</DialogTitle>
          <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            <p className="mb-4">How would you like to handle this exam?</p>
            <div className="space-y-4">
              <div className="p-4 bg-yellow-50 dark:bg-yellow-900/20 rounded-lg">
                <h3 className="font-medium text-yellow-800 dark:text-yellow-200 mb-2">End & Save Results</h3>
                <p className="text-yellow-700 dark:text-yellow-300 text-sm mb-2">
                  Choose this if you've encountered a technical issue but want to preserve your progress.
                  Your exam will be marked as complete with your current scores.
                </p>
                <Button
                  onClick={handleEnd}
                  disabled={processingAction !== 'none'}
                  className="w-full bg-yellow-500 hover:bg-yellow-600 text-white"
                >
                  <span className="inline-flex items-center gap-2">
                    <XCircle className="h-4 w-4 flex-shrink-0" />
                    <span>
                      {processingAction === 'end' ? 'Saving...' : 'End & Save Results'}
                    </span>
                  </span>
                </Button>
              </div>

              <div className="p-4 bg-red-50 dark:bg-red-900/20 rounded-lg">
                <h3 className="font-medium text-red-800 dark:text-red-200 mb-2">Delete & Start Over</h3>
                <p className="text-red-700 dark:text-red-300 text-sm mb-2">
                  Choose this to completely remove this exam and start fresh.
                  All progress will be permanently deleted.
                </p>
                <Button
                  onClick={handleDelete}
                  disabled={processingAction !== 'none'}
                  className="w-full bg-red-500 hover:bg-red-600 text-white"
                >
                  <span className="inline-flex items-center gap-2">
                    <Trash2 className="h-4 w-4 flex-shrink-0" />
                    <span>
                      {processingAction === 'delete' ? 'Deleting...' : 'Delete & Start Over'}
                    </span>
                  </span>
                </Button>
              </div>

              <Button
                onClick={() => setIsDialogOpen(false)}
                disabled={processingAction !== 'none'}
                variant="secondary"
                className="w-full bg-gray-100 hover:bg-gray-200 text-gray-900 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-200"
              >
                Cancel
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  // For completed exams, only show delete option
  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        <Button
          variant="destructive"
          className="w-full bg-red-500 hover:bg-red-600 text-white"
          disabled={processingAction !== 'none'}
        >
          <span className="inline-flex items-center gap-2">
            <Trash2 className="h-4 w-4 flex-shrink-0" />
            <span>Delete</span>
          </span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Delete Mock Exam #{exam.exam_number}?</DialogTitle>
        <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
          <p className="mb-4">Are you sure you want to delete this completed mock exam?</p>
          <div className="bg-red-50 dark:bg-red-900/20 p-4 rounded-lg mb-4">
            <p className="text-red-700 dark:text-red-300">
              This will permanently remove all exam data including your scores and progress.
              This action cannot be undone.
            </p>
          </div>
          <div className="flex justify-end space-x-2">
            <Button
              onClick={() => setIsDialogOpen(false)}
              disabled={processingAction !== 'none'}
              variant="secondary"
              className="bg-gray-100 hover:bg-gray-200 text-gray-900 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-200"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              disabled={processingAction !== 'none'}
              variant="destructive"
              className="bg-red-500 hover:bg-red-600 text-white"
            >
              {processingAction === 'delete' ? 'Deleting...' : 'Delete Exam'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MockExamActions;