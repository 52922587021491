import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { api } from '../../services/api';
import {
  Question,
  CategoryStats,
  QuizHistory,
  ExtendedCategoryStats
} from '../../services/api';
import { 
  Sparkle, 
  Scale, 
  Shuffle, 
  FileQuestion, 
  CheckCircle, 
  X, 
  ChevronDown, 
  Filter,
  ArrowRightLeft
} from 'lucide-react';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import Tooltip from '../ui/Tooltip';
import { ActiveQuizState } from '../../hooks/useActiveQuiz';

interface QuizCustomizerProps {
  courseId: string;
  userId: string;
  show: boolean;
  availableQuestionCounts: ExtendedCategoryStats;
  customQuestionCount: number;
  setCustomQuestionCount: (count: number) => void;
  keslerQFilter: Array<StatusFilter | ConfidenceLevel>;
  setKeslerQFilter: React.Dispatch<React.SetStateAction<Array<StatusFilter | ConfidenceLevel>>>;
  onStartQuiz: (questions: Question[]) => void;
  onResumeQuiz: (quizToResume: QuizHistory) => void;
  beginQuizInitialization: () => void;
  selectedCategories: string[];
  // Replace the old active quiz props with activeQuizState
  activeQuizState: ActiveQuizState;
  setActiveQuizState: (state: ActiveQuizState) => void;
  resetActiveQuizCheck: () => void;
}

type ConfidenceLevel = 'guessing' | 'maybe' | 'confident';
type StatusFilter = 'unanswered' | 'correct' | 'incorrect';
type TestletType = 'mock_exam' | 'study_task' | 'simulation' | 'MCQ' | 'loop' | 'learn' | 'funnel' | 'retake_funnel' | null;

const MAX_ALLOWED_QUESTIONS = 72;
const availableButtons = [10, 25, 50];

function getFilteredCount(
  stats: ExtendedCategoryStats | null | undefined,
  conf: ConfidenceLevel,
  stat: 'correct' | 'incorrect'
): number {
  if (!stats) return 0;
  switch (`${conf}_${stat}`) {
    case 'confident_correct':
      return stats.confident_correct;
    case 'confident_incorrect':
      return stats.confident_incorrect;
    case 'maybe_correct':
      return stats.maybe_correct;
    case 'maybe_incorrect':
      return stats.maybe_incorrect;
    case 'guessing_correct':
      return stats.guessing_correct;
    case 'guessing_incorrect':
      return stats.guessing_incorrect;
    default:
      return 0;
  }
}

const getFilterTooltip = (filter: string): string => {
  switch (filter) {
    case 'unanswered':
      return 'Unanswered Questions';
    case 'correct':
      return 'Previously Correct';
    case 'incorrect':
      return 'Previously Incorrect';
    case 'confident':
      return 'Marked Confident';
    case 'maybe':
      return 'Marked Maybe';
    case 'guessing':
      return 'Marked Guessing';
    default:
      return '';
  }
};

const getSessionButtonText = (testletType: TestletType | undefined): string => {
  if (!testletType) return 'Resume/End Quiz';
  
  switch (testletType) {
    case 'mock_exam':
      return 'Resume/End Mock Exam';
    case 'study_task':
      return 'Resume/End Study Task';
    default:
      return 'Resume/End Quiz';
  }
};

const getTotalAvailable = (stats: ExtendedCategoryStats | null, filters: string[]): number => {
  if (!stats) return 0;

  const statusFilter = filters.find(f => 
    ['unanswered', 'correct', 'incorrect'].includes(f)
  ) as StatusFilter | undefined;

  const confidenceFilter = filters.find(f => 
    ['confident', 'maybe', 'guessing'].includes(f)
  ) as ConfidenceLevel | undefined;

  if (!statusFilter) {
    return stats.total_questions;
  }

  // Handle unanswered questions
  if (statusFilter === 'unanswered') {
    return stats.unanswered_questions;
  }

  // Handle answered questions with confidence
  if (confidenceFilter) {
    return getFilteredCount(stats, confidenceFilter, statusFilter);
  }

  // Handle just correct/incorrect without confidence
  return statusFilter === 'correct' ? stats.correct : stats.incorrect;
};

const QuizCustomizer: React.FC<QuizCustomizerProps> = ({
  courseId,
  userId,
  show,
  availableQuestionCounts,
  customQuestionCount,
  setCustomQuestionCount,
  keslerQFilter,
  setKeslerQFilter,
  onStartQuiz,
  onResumeQuiz,
  beginQuizInitialization,
  selectedCategories,
  activeQuizState,
  setActiveQuizState,
  resetActiveQuizCheck
}) => {
  // State management
  const [error, setError] = useState<string | null>(null);
  const [quizHistory, setQuizHistory] = useState<QuizHistory[]>([]);
  const [categoryStats, setCategoryStats] = useState<CategoryStats[]>([]);
  const [maxQuestions, setMaxQuestions] = useState<number>(0);
  const [maxUnansweredQuestions, setMaxUnansweredQuestions] = useState<number>(0);
  const [selectedCountButton, setSelectedCountButton] = useState<number | null>(null);
  const [isValidInput, setIsValidInput] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const [lastAvailableCount, setLastAvailableCount] = useState<number>(0);
  
  const { userCourseAccesses } = useUserCourseAccess();

  const rawCounts = useMemo(() => {
    // Helper to get counts based on status filter
    const getFilteredCounts = () => {
      const statusFilter = keslerQFilter.find(f => 
        ['correct', 'incorrect'].includes(f)
      ) as 'correct' | 'incorrect' | undefined;
  
      // If no status filter, show total counts
      if (!statusFilter) {
        return {
          confident: availableQuestionCounts?.confident || 0,
          maybe: availableQuestionCounts?.maybe || 0,
          guessing: availableQuestionCounts?.guessing || 0
        };
      }
  
      // If correct/incorrect is selected, only show confidence counts for that status
      return {
        confident: availableQuestionCounts?.[`confident_${statusFilter}`] || 0,
        maybe: availableQuestionCounts?.[`maybe_${statusFilter}`] || 0,
        guessing: availableQuestionCounts?.[`guessing_${statusFilter}`] || 0
      };
    };
  
    // Get confidence counts based on status filter
    const confidenceCounts = getFilteredCounts();
  
    // For unanswered, get the sum from selected categories
    const selectedStats = selectedCategories.reduce((sum, categoryId) => {
      const stat = categoryStats.find(s => s.category_id === categoryId);
      return sum + (stat?.unanswered_questions || 0);
    }, 0);
  
    return {
      unanswered: selectedStats,
      correct: availableQuestionCounts?.correct || 0,
      incorrect: availableQuestionCounts?.incorrect || 0,
      confident: confidenceCounts.confident,
      maybe: confidenceCounts.maybe,
      guessing: confidenceCounts.guessing
    };
  }, [availableQuestionCounts, categoryStats, selectedCategories, keslerQFilter]);
  
  // Memoize the available count calculation
  const availableCount = useMemo(() => {
    if (!availableQuestionCounts) return 0;

    const count = getTotalAvailable(availableQuestionCounts, keslerQFilter);
    setLastAvailableCount(count);
    return count;
  }, [availableQuestionCounts, keslerQFilter]);
      
  // Modified to preserve button selection
  const updateQuestionCount = useCallback((newCount: number) => {
    const maxAllowed = Math.min(availableCount, MAX_ALLOWED_QUESTIONS);
    const clampedValue = Math.min(Math.max(0, newCount), maxAllowed);
    
    setInputValue(clampedValue.toString());
    setIsValidInput(true);
    setCustomQuestionCount(clampedValue);
  }, [availableCount, setCustomQuestionCount]);
  
  const handleKeslerQFilterChange = useCallback(
    (clickedLabel: string) => {
      const clicked = clickedLabel.toLowerCase() as StatusFilter | ConfidenceLevel;
      
      setKeslerQFilter((prevFilters) => {
        let newFilters: Array<StatusFilter | ConfidenceLevel>;
        
        if (clicked === 'unanswered') {
          newFilters = ['unanswered'];
        } else {
          newFilters = prevFilters.filter(f => f !== 'unanswered');
          
          if (['correct', 'incorrect'].includes(clicked)) {
            newFilters = newFilters.filter(f => !['correct', 'incorrect'].includes(f));
            if (!prevFilters.includes(clicked)) {
              newFilters.push(clicked as StatusFilter);
            }
          }
          
          if (['confident', 'maybe', 'guessing'].includes(clicked)) {
            newFilters = newFilters.filter(f => !['confident', 'maybe', 'guessing'].includes(f));
            if (!prevFilters.includes(clicked)) {
              newFilters.push(clicked as ConfidenceLevel);
            }
          }
          
          if (newFilters.length === 0) {
            newFilters = ['unanswered'];
          }
        }

        // Pre-calculate the new count before updating filters
        const newAvailableCount = getTotalAvailable(availableQuestionCounts, newFilters);
        const newCount = Math.min(newAvailableCount, MAX_ALLOWED_QUESTIONS);
        
        // Update counts immediately
        updateQuestionCount(newCount);
        setInputValue(newCount.toString());
        
        return newFilters;
      });
      
      // Reset the selected count button whenever a filter changes
      setSelectedCountButton(null);
    },
    [availableQuestionCounts, updateQuestionCount]
  );

  // Modified handleButtonClick to properly update all related state
  const handleButtonClick = useCallback((count: number) => {
    if (count > availableCount) return;
    setHasUserInteracted(true);
    setSelectedCountButton(count);
    updateQuestionCount(count);
    setInputValue(count.toString());
  }, [availableCount, updateQuestionCount]);

  // Modified handleInputChange to clear button selection when typing
  const handleInputChange = useCallback((value: string) => {
    setHasUserInteracted(true);
    
    // Remove non-numeric characters and limit to 2 digits
    const numericValue = value.replace(/[^0-9]/g, '').slice(0, 2);
    
    // Always update the input value first to prevent long numbers
    setInputValue(numericValue);
    setSelectedCountButton(null);
    
    if (numericValue === '') {
      setIsValidInput(false);
      setCustomQuestionCount(0);
      return;
    }

    const parsed = parseInt(numericValue, 10);
    if (isNaN(parsed)) {
      setIsValidInput(false);
      setCustomQuestionCount(0);
      return;
    }

    // Ensure the parsed value doesn't exceed the maximum allowed questions or available questions
    const maxAllowed = Math.min(availableCount, MAX_ALLOWED_QUESTIONS);
    const limitedValue = Math.min(parsed, maxAllowed);
    setIsValidInput(true);
    updateQuestionCount(limitedValue);
  }, [availableCount, setCustomQuestionCount, updateQuestionCount]);

  // Effect to sync with parent's customQuestionCount
  useEffect(() => {
    if (!isInputFocused) {
      setInputValue(customQuestionCount.toString());
      setSelectedCountButton(availableButtons.includes(customQuestionCount) ? customQuestionCount : null);
    }
  }, [customQuestionCount, isInputFocused]);

  // Remove both previous useEffects and replace with this one:
  useEffect(() => {
    if (!show || !availableQuestionCounts || hasUserInteracted) return;
    
    const initialCount = Math.min(
      availableQuestionCounts.unanswered_questions,
      MAX_ALLOWED_QUESTIONS
    );
    
    updateQuestionCount(initialCount);
    setSelectedCountButton(
      availableButtons.includes(initialCount) ? initialCount : null
    );
  }, [show, availableQuestionCounts, hasUserInteracted, updateQuestionCount]);

  // UPDATED: fetch quiz history, etc. so we define setQuizHistory above
  const fetchQuizHistory = useCallback(async () => {
    try {
      const history = await api.getFilteredQuizHistory(userId, courseId, 'all');
      setQuizHistory(history || []);
    } catch (error) {
      console.error('Failed to fetch quiz history:', error);
      setQuizHistory([]);
    }
  }, [userId, courseId]);

  // UPDATED: fetch category stats so we define setCategoryStats, etc.
  const fetchCategoryStats = useCallback(async () => {
    try {
      const stats = await api.getCategoryStats(userId, courseId, selectedCategories);
      setCategoryStats(stats);
      const totalQuestions = stats.reduce((sum, s) => sum + s.total_questions, 0);
      const totalUnansweredQs = stats.reduce((sum, s) => sum + s.unanswered_questions, 0);
      setMaxQuestions(totalQuestions);
      setMaxUnansweredQuestions(totalUnansweredQs);
    } catch (err) {
      console.error('Failed to fetch category stats:', err);
    }
  }, [userId, courseId, selectedCategories]);

  useEffect(() => {
    if (show) {
      fetchQuizHistory();
      fetchCategoryStats();
    }
  }, [show, fetchQuizHistory, fetchCategoryStats]);

  // Handler for active quiz button click
  const handleActiveQuizClick = useCallback(() => {
    setActiveQuizState({
      ...activeQuizState,
      showActiveQuizModal: true
    });
  }, [activeQuizState, setActiveQuizState]);
  
  // Update renderActiveSessionButtons
  const renderActiveSessionButtons = useCallback(() => {
    return (
      <button
        onClick={() => setActiveQuizState({
          ...activeQuizState,
          showActiveQuizModal: true
        })}
        className="flex items-center gap-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md text-sm font-medium transition-colors"
      >
        <ArrowRightLeft className="w-4 h-4" />
        <span>{getSessionButtonText(activeQuizState.activeTestletType)}</span>
      </button>
    );
  }, [activeQuizState, setActiveQuizState]);

  // Start Quiz Functions
  const startNewQuiz = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Validate required inputs
      if (!selectedCategories || selectedCategories.length === 0) {
        throw new Error('Please select at least one category before starting the quiz.');
      }

      if (!customQuestionCount || customQuestionCount <= 0) {
        throw new Error('Please select a valid number of questions before starting the quiz.');
      }

      if (!keslerQFilter || keslerQFilter.length === 0) {
        throw new Error('Please select at least one KeslerQ filter before starting the quiz.');
      }

      const questionCount = Math.min(
        customQuestionCount,
        getTotalAvailable(availableQuestionCounts, keslerQFilter),
        MAX_ALLOWED_QUESTIONS
      );

      if (questionCount <= 0) {
        throw new Error('No questions available with the current filters. Please adjust your selection.');
      }

      // Instead of fetching questions here, we'll just trigger the parent's callbacks
      // with the validated configuration
      beginQuizInitialization(); // Show global loading state immediately
      onStartQuiz([]); // Pass empty array since Quiz.tsx will handle fetching

    } catch (err) {
      console.error('Failed to start quiz:', err);
      setError(err instanceof Error ? err.message : 'Failed to start quiz. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedCategories,
    customQuestionCount,
    keslerQFilter,
    availableQuestionCounts,
    getTotalAvailable,
    beginQuizInitialization,
    onStartQuiz
  ]);

  // Update handlers to use activeQuizState
  const handleResumeQuiz = useCallback(() => {
    if (!activeQuizState.activeQuiz) return;
    onResumeQuiz(activeQuizState.activeQuiz);
    setActiveQuizState({
      ...activeQuizState,
      showActiveQuizModal: false
    });
  }, [activeQuizState, onResumeQuiz, setActiveQuizState]);

  const handleEndQuiz = useCallback(async () => {
    if (!activeQuizState.activeQuiz) return;
    
    try {
      setError(null);
      await api.endQuizSession(activeQuizState.activeQuiz.id, 0, true);
      resetActiveQuizCheck();
      // Refresh the quiz history after ending
      fetchQuizHistory();
    } catch (err) {
      console.error('Failed to end quiz:', err);
      setError(err instanceof Error ? err.message : 'Failed to end quiz. Please try again.');
    }
  }, [activeQuizState.activeQuiz, resetActiveQuizCheck, fetchQuizHistory]);

  // Modified resetQuizState to be more focused
  const resetCustomizerState = useCallback(() => {
    setError(null);
    setCustomQuestionCount(0);
    setKeslerQFilter(['unanswered']);
    setSelectedCountButton(null);
    setIsValidInput(true);
    setInputValue('0');
    setHasUserInteracted(false);
  }, []);

  // Keep the error clearing effect
  useEffect(() => {
    setError(null);
  }, [selectedCategories, keslerQFilter, customQuestionCount]);

  // Add a cleanup effect when the customizer is hidden
  useEffect(() => {
    if (!show) {
      resetCustomizerState();
    }
  }, [show, resetCustomizerState]);

  const handleStartQuizClick = async () => {
    try {
      setError(null);
      beginQuizInitialization(); // show global spinner immediately
      setIsLoading(true);

      await startNewQuiz();

      setIsLoading(false);
    } catch (err) {
      console.error('Error in handleStartQuizClick:', err);
      setIsLoading(false);
      setError('Failed to start quiz. Please try again.');
    }
  };

  if (!show) return null;
  
  const isButtonDisabled = (count: number) => count > availableCount;

  const renderFilterButton = (filter: string, count: number) => {
    const isSelected = keslerQFilter.includes(filter as StatusFilter | ConfidenceLevel);
    return (
      <div className="relative" key={filter}>
        <Tooltip 
          content={getFilterTooltip(filter)}
          side="top"
          delayMS={200}
          className="z-[9999]" // Ensure tooltip appears above everything
        >
          <button
            onClick={() => handleKeslerQFilterChange(filter)}
            className={`flex items-center shrink-0 gap-1 px-2.5 py-1.5 rounded-md text-sm transition-all whitespace-nowrap border ${
              getFilterColor(filter, isSelected)
            }`}
          >
            <div className="w-4 h-4 shrink-0 flex items-center justify-center">
              {getFilterIcon(filter)}
            </div>
            <span className="text-xs">{count}</span>
          </button>
        </Tooltip>
      </div>
    );
  };

  const getFilterIcon = (filter: string) => {
    switch (filter) {
      case 'unanswered':
        return <FileQuestion className="w-4 h-4" />;
      case 'correct':
        return <CheckCircle className="w-4 h-4" />;
      case 'incorrect':
        return <X className="w-4 h-4" />;
      case 'confident':
        return <Sparkle className="w-4 h-4" />;
      case 'maybe':
        return <Scale className="w-4 h-4" />;
      case 'guessing':
        return <Shuffle className="w-4 h-4" />;
      default:
        return null;
    }
  };

  const getFilterColor = (filter: string, isSelected: boolean) => {
    const baseColors = {
      unanswered: {
        normal: 'bg-blue-50 text-blue-700 border-blue-200 hover:bg-blue-100 dark:bg-blue-900/20 dark:text-blue-300 dark:border-blue-800',
        selected: 'bg-blue-500 text-white border-blue-600 hover:bg-blue-600 dark:bg-blue-600 dark:text-white dark:border-blue-500'
      },
      correct: {
        normal: 'bg-green-50 text-green-700 border-green-200 hover:bg-green-100 dark:bg-green-900/20 dark:text-green-300 dark:border-green-800',
        selected: 'bg-green-500 text-white border-green-600 hover:bg-green-600 dark:bg-green-600 dark:text-white dark:border-green-500'
      },
      incorrect: {
        normal: 'bg-red-50 text-red-700 border-red-200 hover:bg-red-100 dark:bg-red-900/20 dark:text-red-300 dark:border-red-800',
        selected: 'bg-red-500 text-white border-red-600 hover:bg-red-600 dark:bg-red-600 dark:text-white dark:border-red-500'
      },
      confident: {
        normal: 'bg-emerald-50 text-emerald-700 border-emerald-200 hover:bg-emerald-100 dark:bg-emerald-900/20 dark:text-emerald-300 dark:border-emerald-800',
        selected: 'bg-emerald-500 text-white border-emerald-600 hover:bg-emerald-600 dark:bg-emerald-600 dark:text-white dark:border-emerald-500'
      },
      maybe: {
        normal: 'bg-yellow-50 text-yellow-700 border-yellow-200 hover:bg-yellow-100 dark:bg-yellow-900/20 dark:text-yellow-300 dark:border-yellow-800',
        selected: 'bg-yellow-500 text-white border-yellow-600 hover:bg-yellow-600 dark:bg-yellow-600 dark:text-white dark:border-yellow-500'
      },
      guessing: {
        normal: 'bg-orange-50 text-orange-700 border-orange-200 hover:bg-orange-100 dark:bg-orange-900/20 dark:text-orange-300 dark:border-orange-800',
        selected: 'bg-orange-500 text-white border-orange-600 hover:bg-orange-600 dark:bg-orange-600 dark:text-white dark:border-orange-500'
      }
    };

    return isSelected ? baseColors[filter as keyof typeof baseColors].selected : baseColors[filter as keyof typeof baseColors].normal;
  };

  // Add handler for input selection
  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsInputFocused(true);
    event.target.select();
  };
  // Modified renderActionButtons
  // FIX: updated the label to display the inputValue in parentheses
  const renderActionButtons = () => {
    // If there's an active session, show a single button to resume/end
    if (activeQuizState.activeQuiz || activeQuizState.activeTestletType) {
      return (
        <div className="flex items-center justify-end gap-4">
          {renderActiveSessionButtons()}
        </div>
      );
    }

    // Otherwise, show the question count buttons, input, and start button
    return (
      <div className="flex items-center justify-end gap-4">
        <div className="flex items-center gap-2">
          {availableButtons.map((count) => (
            <button
              key={count}
              onClick={() => handleButtonClick(count)}
              disabled={isButtonDisabled(count)}
              className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
                selectedCountButton === count
                  ? 'bg-blue-500 text-white'
                  : isButtonDisabled(count)
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-gray-700 dark:text-gray-500'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600'
              }`}
            >
              {count}
            </button>
          ))}
          <div className="relative">
          <input
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              value={inputValue}
              onChange={(e) => handleInputChange(e.target.value)}
              onFocus={handleInputFocus}
              onBlur={() => setIsInputFocused(false)}
              className={`w-20 px-3 py-1.5 rounded-md text-sm font-medium border ${
                isValidInput
                  ? 'border-gray-200 dark:border-gray-600'
                  : 'border-red-500'
              } focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200`}
            />
          </div>
        </div>
        <button
          onClick={handleStartQuizClick}
          disabled={!isValidInput || customQuestionCount === 0 || isLoading}
          className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
            !isValidInput || customQuestionCount === 0 || isLoading
              ? 'bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-gray-700 dark:text-gray-500'
              : 'bg-blue-500 hover:bg-blue-600 text-white'
          }`}
        >
          {/* FIX: show the inputValue so the user knows how many questions they’re about to start */}
          {isLoading ? 'Starting...' : `Start Quiz (${inputValue})`}
        </button>
      </div>
    );
  };

  return (
    <div className="quiz-customizer-wrapper fixed bottom-0 left-0 right-0 z-40">
      {/* Tab Header - Adjusted positioning */}
      <div className="absolute top-0 left-[max(1rem,calc(50%-35rem))] -translate-y-full">
        <div className="flex items-center gap-1.5 px-4 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-t-lg cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-750">
          <span className="text-sm font-medium text-gray-700 dark:text-gray-200">Q Customizer</span>
          <ChevronDown className="w-4 h-4 text-gray-500 dark:text-gray-400 shrink-0" />
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow-lg transition-all duration-300 ease-in-out border-t border-gray-200 dark:border-gray-700">
        <div className="max-w-7xl mx-auto px-4 lg:px-8 py-3 pb-5 md:py-4 md:pb-6">
          <div className="flex flex-col md:flex-row md:items-center md:justify-center space-y-4 md:space-y-0 md:gap-6">
            {/* Filter Section */}
            <div className="w-full md:w-auto">
              <div className="flex items-center gap-3 md:border md:border-gray-200 dark:md:border-gray-700 md:rounded-lg md:p-2">
                <div className="hidden md:flex items-center gap-2 px-2 text-gray-600 dark:text-gray-300">
                  <Filter className="w-4 h-4" />
                  <span className="text-sm font-medium">Filters</span>
                </div>
                <div className="flex gap-2 overflow-x-auto [scrollbar-width:none] [-ms-overflow-style:none] [&::-webkit-scrollbar]:hidden pb-2 -mb-2 md:pb-0 md:mb-0">
                  {([
                    { filter: 'unanswered', count: rawCounts.unanswered },
                    { filter: 'incorrect', count: rawCounts.incorrect },
                    { filter: 'correct', count: rawCounts.correct },
                    { filter: 'confident', count: rawCounts.confident },
                    { filter: 'maybe', count: rawCounts.maybe },
                    { filter: 'guessing', count: rawCounts.guessing },
                  ] as const).map(({ filter, count }) => renderFilterButton(filter, count))}
                </div>
              </div>
            </div>
              
            {/* Action buttons section */}
            {renderActionButtons()}
          </div>
        </div>
      </div>
    </div>
  );
};


export default React.memo(QuizCustomizer);