import { useState, useEffect } from 'react';
import { useParams, Routes, Route, useLocation } from 'react-router-dom';
import { useAuth } from '../components/AuthProvider';
import { useProducts } from '../components/ProductProvider';
import CourseHomepage from '../components/course/CourseHomepage';
import StudyPlan from '../components/course/StudyPlan';
import Lessons from '../components/course/Lessons';
import Quiz from '../components/course/Quiz';
import Simulations from '../components/course/Simulations';
import Analytics from '../components/course/Analytics';
import FocusNotes from '../components/course/FocusNotes';
import KeslerCards from '../components/course/KeslerCards';
import MockExam from '../components/course/MockExam';
import StudyTaskDetail from '../components/course/StudyTaskDetail';
import Mentor from '../components/course/Mentor';
import Search from '../components/course/Search';
import SimulationReview from '../components/course/SimulationReview';
import SimulationPreview from '../components/course/SimulationPreview';
import KeslerCardsPreview from '../components/course/KeslerCardsPreview';
import LessonPreview from '../components/course/LessonPreview';
import QuestionPreview from '../components/course/QuestionPreview';
import MockExamDetail from '../components/course/MockExamDetail';
import LoadingScreen from '../components/course/common/LoadingScreen';

interface CourseData {
  id: string;
  title: string;
  description: string;
}

interface MockExamDetailWrapperProps {
  isReview?: boolean;
}

function Course() {
  const { courseId } = useParams<{ courseId: string }>();
  const { user } = useAuth();
  const { getProduct, products, loading: productsLoading, error } = useProducts();
  const [course, setCourse] = useState<CourseData | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (courseId) {
      setIsLoading(true);
      getProduct(courseId).then(fetchedCourse => {
        if (fetchedCourse) {
          setCourse(fetchedCourse as CourseData);
        }
        setIsLoading(false);
      });
    }
  }, [courseId, getProduct]);

  const LessonsWrapper = () => {
    const { lessonId } = useParams<{ lessonId: string }>();
    const location = useLocation();
    const item_id = location.state?.item_id;
    return <Lessons courseId={courseId!} userId={user!.id} studyTaskId={item_id} lessonId={lessonId} />;
  };

  const SimulationsWrapper = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const mockExamTestletId = searchParams.get('mock_exam_testlet_id');
    const quizHistoryId = searchParams.get('quiz_history_id');

    return (
      <Simulations
        courseId={courseId!} 
        userId={user!.id} 
        mockExamTestletId={mockExamTestletId || undefined}
        quizHistoryId={quizHistoryId || undefined}
      />
    );
  };

  const SimulationReviewWrapper = () => {
    const location = useLocation();
    const state = location.state as {
      quizHistoryId?: string;
      courseId?: string;
      completed?: boolean;
      mockExamId?: string;  // Added for mock exam context
    };
    return (
      <SimulationReview 
        courseId={courseId!} 
        userId={user!.id} 
      />
    );
  };
  
  const SimulationPreviewWrapper = () => {
    const { simulationId } = useParams<{ simulationId: string }>();
    return <SimulationPreview simulationId={simulationId!} courseId={courseId!} userId={user!.id} />;
  };
  
  const MentorWrapper = () => {
    const { moduleId } = useParams<{ moduleId: string }>();
    return <Mentor courseId={courseId!} userId={user!.id} initialModuleId={moduleId} />;
  };

  const QuizWrapper = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const mockExamTestletId = searchParams.get('mock_exam_testlet_id');
    const quizHistoryId = searchParams.get('quiz_history_id');
    const studyTaskTestletId = searchParams.get('study_task_testlet_id');
    const studyTaskId = searchParams.get('study_task_id');  // Add this
    const testletType = searchParams.get('type');  // Add this
  
    // Add these to the Quiz component props
    return (
      <Quiz
        courseId={courseId!} 
        userId={user!.id}
        mockExamTestletId={mockExamTestletId || undefined}
        quizHistoryId={quizHistoryId || undefined}
        studyTaskTestletId={studyTaskTestletId || undefined}
        studyTaskId={studyTaskId || undefined}  // Pass this
        testletType={testletType || undefined}  // Pass this
      />
    );
  };
  
  const MockExamWrapper = () => {
    return <MockExam courseId={courseId!} userId={user!.id} />;
  };

  const KeslerCardPreviewWrapper = () => {
    const { flashcardId } = useParams<{ flashcardId: string }>();
    return flashcardId ? (
      <KeslerCardsPreview
        flashcardId={flashcardId}
        courseId={courseId!}
        userId={user!.id}
      />
    ) : null;
  };

  const QuestionPreviewWrapper = () => {
    const { questionId } = useParams<{ questionId: string }>();
    return questionId ? (
      <QuestionPreview
        questionId={questionId}
        courseId={courseId!}
        userId={user!.id}
      />
    ) : null;
  };

  const LessonPreviewWrapper = () => {
    const { lessonId } = useParams<{ lessonId: string }>();
    return lessonId ? (
      <LessonPreview
        lessonId={lessonId}
        courseId={courseId!}
        userId={user!.id}
      />
    ) : null;
  };
  
  const MockExamDetailWrapper = ({ isReview }: MockExamDetailWrapperProps) => {
    const { mockExamId } = useParams<{ mockExamId: string }>();
    const productsArray = Object.values(products);
    
    return (
      <MockExamDetail 
        courseId={courseId!} 
        userId={user!.id} 
        mockExamId={mockExamId}
        isReview={isReview}
        products={productsArray}
      />
    );
  };

  if (isLoading || productsLoading) {
    return <LoadingScreen message="Loading Kesler CPA..." subMessage="This may take a few moments" />;
  }

  if (error) return <div className="text-red-500">{(error as Error).message}</div>;
  if (!course || !courseId) return <div>Course not found.</div>;
  if (!user) return <div>Please log in to access this course.</div>;

  return (
    <div className="flex">
      <div className="flex-1 overflow-y-auto">
        <div className="w-full max-w-[95%] mx-auto py-0">
          <Routes>
            <Route index element={<CourseHomepage course={course} />} />
            <Route path="study-plan" element={<StudyPlan courseId={courseId} userId={user.id} />} />
            <Route path="mentor" element={<MentorWrapper />} />
            <Route path="mentor/:moduleId" element={<MentorWrapper />} />
            <Route path="lessons" element={<LessonsWrapper />} />
            <Route path="lessons/:lessonId" element={<LessonsWrapper />} />
            <Route path="quiz" element={<QuizWrapper />} />
            <Route path="quiz/:quizId" element={<QuizWrapper />} />
            <Route path="simulations" element={<SimulationsWrapper />} />
            <Route 
              path="simulation-review" 
              element={<SimulationReview courseId={courseId} userId={user.id} />}
            />
            <Route path="mock-exam" element={<MockExamWrapper />} />
            <Route path="mock-exam/:mockExamId" element={<MockExamDetailWrapper isReview={false} />} />
            <Route 
              path="mock-exam/:mockExamId/review" 
              element={<MockExamDetailWrapper isReview={true} />} 
            />
            {/* Add new route for mock exam testlet review */}
            <Route 
              path="mock-exam/:mockExamId/testlet/:testletId/review" 
              element={<SimulationReviewWrapper />} 
            />
            {/* Preview Routes */}
            <Route path="questions/:questionId/preview" element={<QuestionPreviewWrapper />} />
            <Route path="lessons/:lessonId/preview" element={<LessonPreviewWrapper />} />
            <Route path="simulations/:simulationId/preview" element={<SimulationPreviewWrapper />} />
            <Route path="kesler-cards/:flashcardId/preview" element={<KeslerCardPreviewWrapper />} />

            <Route path="analytics" element={<Analytics courseId={courseId} userId={user.id} />} />
            <Route path="focus-notes" element={<FocusNotes courseId={courseId} userId={user.id} />} />
            <Route path="kesler-cards" element={<KeslerCards courseId={courseId} userId={user.id} />} />
            <Route path="study-task/:itemId" element={<StudyTaskDetail userId={user.id} courseId={courseId} />} />
            <Route path="study-task/:itemId/quiz" element={<QuizWrapper />} />
            <Route path="study-plan/:itemId" element={<StudyTaskDetail userId={user.id} courseId={courseId} />} />            
            <Route path="search" element={<Search courseId={courseId} />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Course;