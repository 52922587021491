import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { api, Note, CustomFlashcard } from '../../../services/api';
import { useAuth } from '../../AuthProvider';
import { ModalContext } from '../../../App';
import { Save, X } from 'lucide-react';
import DOMPurify from 'dompurify';
import { getBrowserInstanceId } from '../../../utils/browserInstance';
import { useTextSelection } from '../../../contexts/TextSelectionContext';

interface TextSelectionPopupProps {
  courseId: string;
  lessonId?: string;
  questionId?: string;
  simulationId?: string;
  moduleId?: string;
  existingFlashcard?: CustomFlashcard;
  onFlashcardCreated?: (newCard: CustomFlashcard) => void;
}

interface ConfirmationPopupProps {
  message: string;
  onClose: () => void;
  duration?: number;
}

const FRONT_MAX_LENGTH = 500;
const BACK_MAX_LENGTH = 2000;
const MAX_TAG_LENGTH = 30;
const MAX_TAGS = 3;

const TextSelectionPopup: React.FC<TextSelectionPopupProps> = ({
  courseId,
  lessonId,
  questionId,
  simulationId,
  moduleId,
  existingFlashcard,
  onFlashcardCreated
}) => {
  const [selectedText, setSelectedText] = useState('');
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [showInitialPopup, setShowInitialPopup] = useState(false);
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [showFlashcardPopup, setShowFlashcardPopup] = useState(false);
  const [noteContent, setNoteContent] = useState('');
  const [frontContent, setFrontContent] = useState('');
  const [backContent, setBackContent] = useState('');
  const { user } = useAuth();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const noteTextareaRef = useRef<HTMLTextAreaElement>(null);
  const frontTextareaRef = useRef<HTMLTextAreaElement>(null);
  const backTextareaRef = useRef<HTMLTextAreaElement>(null);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [activeTextArea, setActiveTextArea] = useState<'note' | 'front' | 'back' | null>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const { isModalOpen } = useContext(ModalContext);
  const { setSelectionPopupVisible } = useTextSelection();

  // Add new state variables for tags and color
  const [flashcardTags, setFlashcardTags] = useState<string[]>([]);
  const [tagInput, setTagInput] = useState('');
  const tagInputRef = useRef<HTMLTextAreaElement | null>(null);
  const [isInputActive, setIsInputActive] = useState(false);
  const inputRefs = useRef<(HTMLTextAreaElement | HTMLInputElement | null)[]>([]);
  const [showCharLimitWarning, setShowCharLimitWarning] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [allTags, setAllTags] = useState<string[]>([]);
  const [filteredTags, setFilteredTags] = useState<string[]>([]);

  const [isVisible, setIsVisible] = useState(true);

  const handleInputFocus = () => setIsInputActive(true);
  const handleInputBlur = () => setIsInputActive(false);

  // Update whenever any popup visibility changes
  useEffect(() => {
    const isAnyPopupVisible = showInitialPopup || showNotePopup || showFlashcardPopup;
    setSelectionPopupVisible(isAnyPopupVisible);
    
    return () => {
      setSelectionPopupVisible(false);
    };
  }, [showInitialPopup, showNotePopup, showFlashcardPopup, setSelectionPopupVisible]);
  
  const addInputRef = (el: HTMLTextAreaElement | HTMLInputElement | null) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };

  const sanitizeInput = (input: string) => {
    return DOMPurify.sanitize(input);
  };

  const handleCardContentChange = (setter: React.Dispatch<React.SetStateAction<string>>, maxLength: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = sanitizeInput(e.target.value);
    setter(newValue.slice(0, maxLength));
    setShowCharLimitWarning(newValue.length >= maxLength);
    if (newValue.length >= maxLength) {
      setTimeout(() => setShowCharLimitWarning(false), 3000);
    }
  };

  const setTagInputRef = useCallback((el: HTMLTextAreaElement | null) => {
    tagInputRef.current = el;
    if (el) {
      addInputRef(el);
    }
  }, [addInputRef]);

     // Define color options
     const colorOptions = [
      { name: 'Light Gray', value: '#E2E8F0', darkMode: '#2D3748' },
      { name: 'Light Blue', value: '#BFDBFE', darkMode: '#2C5282' },
      { name: 'Light Green', value: '#BBF7D0', darkMode: '#276749' },
      { name: 'Light Yellow', value: '#FEF08A', darkMode: '#723B13' },
      { name: 'Light Pink', value: '#FBCFE8', darkMode: '#702459' },
      { name: 'Light Purple', value: '#DDD6FE', darkMode: '#553C9A' },
    ];
  
     // Updated initial state for flashcard color
     const [flashcardColor, setFlashcardColor] = useState<string>(colorOptions[0].value);

     const handleTagInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = sanitizeInput(e.target.value.slice(0, MAX_TAG_LENGTH));
      setTagInput(value);
  
      // Filter tags based on input
      const filtered = allTags.filter(tag => tag.toLowerCase().includes(value.toLowerCase()));
      setFilteredTags(filtered);
  
      if (value.endsWith(',') || value.endsWith(' ')) {
        const newTag = value.slice(0, -1).trim();
        if (newTag && flashcardTags.length < MAX_TAGS && !flashcardTags.includes(newTag)) {
          setFlashcardTags([...flashcardTags, newTag]);
          setTagInput('');
          setFilteredTags([]);
        } else {
          setTagInput(value.slice(0, -1));
        }
      }
    };

    const fetchTags = useCallback(async () => {
      if (user && showFlashcardPopup) {
        try {
          const tags = await api.getAllUserTags(user.id, courseId);
          setAllTags(tags);
        } catch (error) {
          console.log('Could not load tags:', error);
          setAllTags([]);
        }
      }
    }, [user, courseId, showFlashcardPopup]);
  
    // Update useEffect to only run when flashcard popup is opened
    useEffect(() => {
      if (showFlashcardPopup) {
        fetchTags();
      }
    }, [fetchTags, showFlashcardPopup]);

    const addTag = (tag: string) => {
      if (flashcardTags.length < MAX_TAGS && !flashcardTags.includes(tag)) {
        setFlashcardTags([...flashcardTags, tag]);
        setTagInput('');
        setFilteredTags([]);
      }
    };
  
    const removeTag = (tagToRemove: string) => {
      setFlashcardTags(flashcardTags.filter(tag => tag !== tagToRemove));
    };
  
    const handleTagKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Backspace' && tagInput === '' && flashcardTags.length > 0) {
        e.preventDefault();
        const lastTag = flashcardTags[flashcardTags.length - 1];
        removeTag(lastTag);
        setTagInput(lastTag);
      } else if (e.key === ',' || e.key === ' ') {
        e.preventDefault();
        const newTag = tagInput.trim();
        if (newTag && flashcardTags.length < MAX_TAGS && !flashcardTags.includes(newTag)) {
          setFlashcardTags([...flashcardTags, newTag.slice(0, MAX_TAG_LENGTH)]);
          setTagInput('');
        }
      }
    };

  useEffect(() => {
    if (existingFlashcard) {
      setIsEditing(true);
      setFrontContent(existingFlashcard.front_content);
      setBackContent(existingFlashcard.back_content);
      setFlashcardTags(existingFlashcard.tags);
      setFlashcardColor(existingFlashcard.color);
    }
  }, [existingFlashcard]);

const handleTextSelection = useCallback((e: MouseEvent | TouchEvent) => {
    if (showNotePopup || showFlashcardPopup || isModalOpen) {
      setShowInitialPopup(false);
      return;
    }
  
    const selection = window.getSelection();
    if (!selection) return;
    
    const selectedText = selection.toString().trim();
    if (selectedText.length === 0) {
      setShowInitialPopup(false);
      return;
    }
  
    // Get the target element where the selection occurred
    const startContainer = selection.getRangeAt(0).startContainer;
    const target = e.target as Element;
  
    // Function to check if an element or its parents match certain selectors
    const hasAncestor = (element: Node | null, selectors: string): boolean => {
      if (!element || element.nodeType !== Node.ELEMENT_NODE) {
        return element?.parentElement ? hasAncestor(element.parentElement, selectors) : false;
      }
      return !!(element as Element).closest(selectors);
    };
  
    // Check for various UI elements where we don't want to trigger the popup
    const isDisallowedElement = hasAncestor(startContainer, [
      // Interactive elements
      'button',                    // Any button element
      '[role="button"]',          // Elements with button role
      'input',                    // Input fields
      'textarea',                 // Text areas
      '[contenteditable="true"]', // Editable elements
      'a',                        // Links
      
      // Navigation and structural elements
      'nav',                      // Navigation elements
      'header',                   // Header sections
      'footer',                   // Footer elements
      
      // UI Framework specific
      '.MuiButton-root',          // Material UI buttons
      '.btn',                     // Common button class
      
      // Headers and titles
      'h1, h2, h3, h4, h5, h6',   // Headers
      '.title',                   // Elements with title class
      '[role="heading"]',         // Elements with heading role
      
      // Special content
      'code',                     // Code blocks
      'label',                    // Form labels
      '.copyright',               // Copyright text
      '.logo',                    // Logo elements
      '.navigation',              // Navigation elements
      '.menu',                    // Menu elements
      
      // Progress indicators and status elements
      '[role="progressbar"]',     // Progress bars
      '.progress',                // Progress elements
      '.status-indicator',        // Status indicators
      
      // Icons and decorative elements
      '.icon',                    // Icon elements
      '[role="img"]',            // Image role elements
      'svg',                      // SVG elements
      
      // Study plan specific selectors
      '[data-state="closed"]',    // Radix UI collapsed state
      '[data-state="open"]',      // Radix UI expanded state
      '.accordion-trigger',       // Accordion trigger elements
      '.accordion-content',       // Accordion content container
      '.accordion-header',        // Accordion header
      '[role="region"]',         // Accordion panels
      '[role="tab"]',            // Tab elements
      '[role="tabpanel"]',       // Tab panels
      '.study-plan-item',        // Study plan specific items
      '.module-header',          // Module headers
      '.module-title',           // Module titles
      '.module-description',     // Module descriptions in collapsed state
      '[data-radix-accordion-content]', // Radix accordion content
      '[data-radix-accordion-trigger]', // Radix accordion triggers
      '.expandable-content',     // Generic expandable content
      '.collapsible-header',     // Collapsible headers
      '.collapsible-content',    // Collapsible content
      
      // Interactive containers
      '.accordion',               // Accordion elements
      '.collapsible',            // Collapsible elements
      '.dropdown',               // Dropdown elements
      
      // Module-specific elements (from StudyPlanMentor)
      '.module-status',          // Module status indicators
      '.module-progress',        // Module progress bars
      '.module-metrics',         // Module metric displays
      '.completion-indicator',   // Completion indicators
      
      // Utility classes
      '.no-select',             // Explicit no-selection class
      '.controls',              // Control elements
      '.actions',               // Action containers
      
      // Meta information
      '.meta',                  // Meta information
      '.stats',                // Statistics displays
      '.count',                // Count displays
      '.percentage'            // Percentage displays
    ].join(', '));
  
    if (isDisallowedElement) {
      setShowInitialPopup(false);
      return;
    }
  
    setSelectedText(selectedText);
    setShowInitialPopup(true);
  
  }, [showNotePopup, showFlashcardPopup, isModalOpen]);

  const handleContextMenu = useCallback((e: React.MouseEvent<HTMLTextAreaElement | HTMLInputElement>, type: 'note' | 'front' | 'back') => {
    e.preventDefault();
    const x = e.clientX;
    const y = e.clientY;
    setContextMenuPosition({ x, y });
    setShowContextMenu(true);
    setActiveTextArea(type);
  }, []);

  const handleDeleteSelectedText = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    let textArea: HTMLTextAreaElement | HTMLInputElement | null = null;
    let setText: React.Dispatch<React.SetStateAction<string>> | null = null;
  
    switch (activeTextArea) {
      case 'note':
        textArea = noteTextareaRef.current;
        setText = setNoteContent;
        break;
      case 'front':
        textArea = frontTextareaRef.current;
        setText = setFrontContent;
        break;
      case 'back':
        textArea = backTextareaRef.current;
        setText = setBackContent;
        break;
    }
  
    if (textArea && setText) {
      const start = textArea.selectionStart ?? 0;
      const end = textArea.selectionEnd ?? 0;
      const currentText = textArea.value;
      const newText = currentText.slice(0, start) + currentText.slice(end);
      setText(newText);
  
      requestAnimationFrame(() => {
        textArea?.focus();
        textArea?.setSelectionRange(start, start);
      });
    }
  
    setShowContextMenu(false);
  }, [activeTextArea]);


  const handleCreateNote = () => {
    setNoteContent(selectedText);
    setShowInitialPopup(false);
    setShowNotePopup(true);
  };

  const handleCreateFlashcard = () => {
    setBackContent(selectedText);
    setFrontContent('');
    setShowInitialPopup(false);
    setShowFlashcardPopup(true);
  };

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
      setShowInitialPopup(false);
      if (showNotePopup) {
        setShowNotePopup(false);
      }
      if (showFlashcardPopup) {
        setShowFlashcardPopup(false);
      }
    }
  }, [showNotePopup, showFlashcardPopup]);

  useEffect(() => {
    const handleContextMenu = (e: MouseEvent) => {
      if (!(e.target instanceof HTMLInputElement) && !(e.target instanceof HTMLTextAreaElement)) {
        e.preventDefault();
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('mouseup', handleTextSelection);
    document.addEventListener('touchend', handleTextSelection);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('mouseup', handleTextSelection);
      document.removeEventListener('touchend', handleTextSelection);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleTextSelection, handleClickOutside]);

  useEffect(() => {
    if (isModalOpen) {
      setIsVisible(false);
      setShowInitialPopup(false);
      setShowNotePopup(false);
      setShowFlashcardPopup(false);
    } else {
      setIsVisible(true);
    }
  }, [isModalOpen]);

  const saveNote = useCallback(async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!user) {
      return;
    }
    try {       
      const noteData: Omit<Note, 'id' | 'created_at' | 'updated_at'> = {
        user_id: user.id,
        course_id: courseId,
        lesson_id: lessonId || null,
        question_id: questionId || null,
        simulation_id: simulationId || null,
        module_id: moduleId || null,
        content: sanitizeInput(noteContent)
      };
             
      const savedNote = await api.createNote(noteData);
      setShowNotePopup(false);
      setConfirmationMessage('Note Saved Successfully');
      setShowConfirmation(true);
    } catch (error) {
      console.error('Error creating note:', error);
      setConfirmationMessage('Failed to save note. Please try again.');
      setShowConfirmation(true);
    }
  }, [user, courseId, lessonId, questionId, simulationId, moduleId, noteContent]);

  const saveFlashcard = useCallback(async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!user) {
      return;
    }
    try {
      const tags = flashcardTags.length > 0 ? flashcardTags : ['general'];
      const browserInstanceId = await getBrowserInstanceId();
  
      const flashcardData = {
        user_id: user.id,
        course_id: courseId,
        front_content: sanitizeInput(frontContent),
        back_content: sanitizeInput(backContent),
        confidence_level: null,
        tags,
        color: flashcardColor,
        browser_instance_id: browserInstanceId
      };
  
      const createdCard = await api.createCustomFlashcard(flashcardData);
      setShowFlashcardPopup(false);
      setConfirmationMessage('Flashcard Saved Successfully');
      setShowConfirmation(true);
  
      // Refresh the tags list
      fetchTags();
  
      // Clear form
      setFrontContent('');
      setBackContent('');
      setFlashcardTags([]);
      setTagInput('');
      setFlashcardColor(colorOptions[0].value);
  
      // Call the callback function to update the parent component's state
      if (onFlashcardCreated) {
        onFlashcardCreated(createdCard);
      }
    } catch (error) {
      console.error('Error creating flashcard:', error);
      setConfirmationMessage('Failed to save flashcard. Please try again.');
      setShowConfirmation(true);
    }
  }, [
    user,
    courseId,
    frontContent,
    backContent,
    flashcardTags,
    flashcardColor,
    onFlashcardCreated,
    fetchTags,
    colorOptions
  ]);

  useEffect(() => {
    if (showNotePopup && noteTextareaRef.current) {
      noteTextareaRef.current.focus();
    }
    if (showFlashcardPopup && frontTextareaRef.current) {
      frontTextareaRef.current.focus();
    }
  }, [showNotePopup, showFlashcardPopup]);

  const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({ message, onClose, duration = 3000 }) => {
    useEffect(() => {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
  
      return () => clearTimeout(timer);
    }, [onClose, duration]);
  
    return (
      <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg z-50">
        {message}
      </div>
    );
  };
  // Add this check right after the state declarations
  if (!courseId) {
    return null;
  }
  if (!isVisible) {
    return null;
  }

  return (
    <>
      {showInitialPopup && (
  <div className="fixed inset-0 pointer-events-none flex items-center justify-center z-50">
    <div className="pointer-events-auto bg-white/60 dark:bg-gray-800/60 backdrop-blur-md p-2 rounded-lg shadow-lg relative border border-gray-200 dark:border-gray-700">
      {/* Tab Header - with matching border style */}
      <div className="absolute left-0 top-0 -translate-y-[calc(100%-1px)]">
        <div className="flex items-center gap-1 px-2 py-1 bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm border border-gray-200 dark:border-gray-700 border-b-0 rounded-t-lg">
          <span className="flex items-center gap-1 text-[10px] font-medium text-gray-700 dark:text-gray-200">
            <Save className="w-3 h-3" /> 
            Looping Tools
          </span>
        </div>
      </div>

      {/* Popup Content */}
      <div className="flex space-x-2">
        <button
          onClick={handleCreateNote}
          className="bg-[#008ffe]/90 hover:bg-[#008ffe] text-white px-2 py-1 rounded text-sm transition-colors duration-200"
        >
          Create Note
        </button>
        <button
          onClick={handleCreateFlashcard}
          className="bg-[#fe5d00]/90 hover:bg-[#fe5d00] text-white px-2 py-1 rounded text-sm transition-colors duration-200"
        >
          Create KeslerCard
        </button>
      </div>
    </div>
  </div>
)}
  
      {showNotePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div ref={popupRef} className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg max-w-md w-full">
            <textarea
              ref={noteTextareaRef}
              value={noteContent}
              onChange={handleCardContentChange(setNoteContent, BACK_MAX_LENGTH)}
              onContextMenu={(e) => handleContextMenu(e, 'note')}
              className="w-full p-2 mb-4 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text"
              rows={4}
              maxLength={BACK_MAX_LENGTH}
            />
            <p className="text-xs text-gray-500 mt-1 mb-2">{noteContent.length}/{BACK_MAX_LENGTH}</p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={saveNote}
                className="bg-[#008ffe] text-white px-4 py-2 rounded hover:bg-[#0070cc] transition-colors duration-200"
              >
                Save Note
              </button>
              <button
                onClick={() => setShowNotePopup(false)}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400 transition-colors duration-200"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showFlashcardPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div ref={popupRef} className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg max-w-md w-full">
            <textarea
              ref={frontTextareaRef}
              value={frontContent}
              onChange={handleCardContentChange(setFrontContent, FRONT_MAX_LENGTH)}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onContextMenu={(e) => handleContextMenu(e, 'front')}
              placeholder="Front of card"
              className="w-full p-2 mb-2 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text"
              rows={3}
              maxLength={FRONT_MAX_LENGTH}
            />
            <p className="text-xs text-gray-500 mt-1 mb-2">{frontContent.length}/{FRONT_MAX_LENGTH}</p>
            <textarea
              ref={backTextareaRef}
              value={backContent}
              onChange={handleCardContentChange(setBackContent, BACK_MAX_LENGTH)}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onContextMenu={(e) => handleContextMenu(e, 'back')}
              placeholder="Back of card"
              className="w-full p-2 mb-2 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text"
              rows={3}
              maxLength={BACK_MAX_LENGTH}
            />
            <p className="text-xs text-gray-500 mt-1 mb-4">{backContent.length}/{BACK_MAX_LENGTH}</p>
            <div className="mb-4">
              <label htmlFor="tags" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Tags (max {MAX_TAGS}, press comma or space to add)
              </label>
              <div className="relative">
                <div className="flex flex-wrap items-center gap-2 p-1 border border-gray-300 rounded-md bg-gray-50 dark:bg-gray-700 min-h-[50px]">
                  {flashcardTags.map((tag, index) => (
                    <span
                      key={index}
                      className="ml-1 inline-flex items-center bg-blue-100 dark:bg-primary-blue text-blue-800 dark:text-blue-200 px-2 py-1 rounded-full text-sm max-h-[40px]"
                    >
                      {tag}
                      <button
                        onClick={() => removeTag(tag)}
                        className="ml-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 flex items-center justify-center"
                      >
                        <X size={18} />
                      </button>
                    </span>
                  ))}
                  <textarea
                    ref={setTagInputRef}
                    id="tags"
                    value={tagInput}
                    onChange={handleTagInput}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      handleTagKeyDown(e);
                    }}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    className="flex-grow p-0.5 bg-transparent border-none outline-none text-gray-900 dark:text-gray-100 text-sm md:text-base resize-none h-6 min-h-[24px] overflow-hidden"
                    placeholder={flashcardTags.length < MAX_TAGS ? "Add a comma or space to add tag" : ""}
                    rows={1}
                    disabled={flashcardTags.length >= MAX_TAGS}
                    maxLength={MAX_TAG_LENGTH}
                  />
                </div>
                <p className="text-xs text-gray-500 mt-1 text-right">
                  {tagInput.length}/{MAX_TAG_LENGTH}
                </p>
                {filteredTags.length > 0 && (
                  <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                    {filteredTags.map((tag, index) => (
                      <div
                        key={index}
                        className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                        onClick={() => addTag(tag)}
                      >
                        {tag}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Card Color
              </label>
              <div className="flex space-x-2">
                {colorOptions.map((color) => (
                  <button
                    key={color.value}
                    onClick={() => setFlashcardColor(color.value)}
                    className={`w-8 h-8 rounded-full border-2 ${
                      flashcardColor === color.value ? 'border-blue-500' : 'border-transparent'
                    }`}
                    style={{ backgroundColor: color.value }}
                    title={color.name}
                  />
                ))}
              </div>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={saveFlashcard}
                className="bg-[#fe5d00] text-white px-4 py-2 rounded hover:bg-[#cc4a00] transition-colors duration-200"
              >
                {isEditing ? 'Update Flashcard' : 'Create Flashcard'}
              </button>
              <button
                onClick={() => setShowFlashcardPopup(false)}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400 transition-colors duration-200"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirmation && (
        <ConfirmationPopup
          message={confirmationMessage}
          onClose={() => setShowConfirmation(false)}
        />
      )}

      {showContextMenu && (
        <div
          className="fixed bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded shadow-lg z-50"
          style={{ left: `${contextMenuPosition.x}px`, top: `${contextMenuPosition.y}px` }}
        >
          <button
            className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
            onMouseDown={handleDeleteSelectedText}
          >
            Delete
          </button>
        </div>
      )}

      {showCharLimitWarning && (
        <div className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded-md shadow-lg">
          Character limit reached!
        </div>
      )}
    </>
  );
};

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({ message, onClose, duration = 3000 }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [onClose, duration]);

  return (
    <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg z-50">
      {message}
    </div>
  );
};

export default TextSelectionPopup;