// src/components/course/studyPlan/MockExamTask.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { api, StudyTaskDetails, TIER_IDS } from '../../../services/api';
import { Check, CheckCircle2, AlertTriangle, Clock, ArrowRight } from 'lucide-react';
import DOMPurify from 'dompurify';
import { useUserCourseAccess } from '../../UserCourseAccessProvider';
import { PremiumTaskWrapper } from './PremiumTaskWrapper';

interface MockExamTaskProps {
  taskDetails: StudyTaskDetails;
  userId: string;
  courseId: string;
  itemId: string;
  onTaskComplete: () => void;
}

interface KeslerTask {
  id: string;
  title: string;
  description: string;
  completed: boolean;
  actionType: 'big-box-mock' | 'kesler-mock';
  buttonText: string;
}

export const MockExamTask: React.FC<MockExamTaskProps> = ({
  taskDetails,
  userId,
  courseId,
  itemId,
  onTaskComplete,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isMainTaskCompleted, setIsMainTaskCompleted] = useState<boolean>(false);
  const [keslerTasks, setKeslerTasks] = useState<KeslerTask[]>([]);
  const { userCourseAccesses } = useUserCourseAccess();

  // Check user's access tier
  const isRestrictedAccess = React.useMemo(() => {
    if (!userCourseAccesses || userCourseAccesses.length === 0) return true;
    const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
    if (courseAccesses.length === 0) return true;

    // Check if user has study materials or full access
    const hasFullAccess = courseAccesses.some(
      access => access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY || access.tier_id === TIER_IDS.FULL_ACCESS
    );

    // If they have full access, they can access premium content
    if (hasFullAccess) return false;

    // Otherwise, they only have free trial or mentor-only
    const onlyHasRestrictedAccess = courseAccesses.every(
      access => access.tier_id === TIER_IDS.FREE_TRIAL || access.tier_id === TIER_IDS.MENTOR_ONLY
    );

    return onlyHasRestrictedAccess;
  }, [userCourseAccesses, courseId]);

  // Format time for display
  const formatTimeEstimate = (minutes: number | null): string => {
    if (!minutes) return 'Not available';

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours === 0) {
      return `${remainingMinutes} minutes`;
    } else if (hours === 1) {
      return remainingMinutes > 0
        ? `${hours} hour ${remainingMinutes} minutes`
        : `${hours} hour`;
    } else {
      return remainingMinutes > 0
        ? `${hours} hours ${remainingMinutes} minutes`
        : `${hours} hours`;
    }
  };

  const getEstimatedTime = (taskDetails: StudyTaskDetails): number | null => {
    return taskDetails.estimated_minutes;
  };

  // Render a card that shows estimated time
  const renderTimeEstimate = () => {
    const isRetake = taskDetails.topic_type?.includes('retake');
    const estimatedTime = getEstimatedTime(taskDetails);

    return (
      <div className="mb-6">
        <div className="p-6 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-700 rounded-lg border border-gray-200 dark:border-gray-600 shadow-sm">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 flex items-center">
            <Clock className="w-5 h-5 mr-2 text-primary-blue" />
            Estimated Time to Complete
          </h3>
          <div className="bg-white dark:bg-gray-800 rounded-lg p-4 border border-gray-200 dark:border-gray-700">
            <div className="flex items-start">
              <ArrowRight className="w-5 h-5 text-primary-blue mt-0.5 mr-2 shrink-0" />
              <div>
                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  {isRetake ? 'Review Time' : 'Mock Exam Time'}
                </p>
                <p className="text-2xl font-bold text-gray-900 dark:text-gray-100 mt-1">
                  {formatTimeEstimate(estimatedTime)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Create or restore tasks from localStorage
  useEffect(() => {
    if (taskDetails) {
      setIsMainTaskCompleted(taskDetails.is_completed);

      const storedTasks = localStorage.getItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`);
      if (storedTasks) {
        const parsedTasks: KeslerTask[] = JSON.parse(storedTasks);
        const updatedTasks = parsedTasks.map(task => ({
          ...task,
          completed: taskDetails.is_completed ? true : task.completed,
        }));
        setKeslerTasks(updatedTasks);
        localStorage.setItem(
          `keslerTasks_${itemId}_${taskDetails.topic_type}`,
          JSON.stringify(updatedTasks)
        );
      } else {
        const newTasks: KeslerTask[] = [
          {
            id: '1',
            title: 'Take Big Box CPA Review Course Mock Exam',
            description: 'Complete a full mock exam from your Big Box CPA Review Course.',
            completed: taskDetails.is_completed,
            actionType: 'big-box-mock',
            buttonText: 'Start Big Box Mock',
          },
          {
            id: '2',
            title: 'Take Kesler CPA Review Mock Exam',
            description: 'Complete a full mock exam from Kesler CPA Review.',
            completed: taskDetails.is_completed,
            actionType: 'kesler-mock',
            buttonText: 'Start Kesler Mock',
          },
        ];
        setKeslerTasks(newTasks);
        localStorage.setItem(
          `keslerTasks_${itemId}_${taskDetails.topic_type}`,
          JSON.stringify(newTasks)
        );
      }
    }
  }, [taskDetails, itemId]);

  // Determine the action to take for each task
  const getTaskAction = useCallback(
    (actionType: KeslerTask['actionType'], isCompleted: boolean) => {
      // No action if task is completed
      if (isCompleted) return () => {};

      switch (actionType) {
        case 'kesler-mock':
          return () => {
            // Navigate to the Kesler CPA mock exam page
            navigate(`/course/${courseId}/mock-exam`);
          };
        case 'big-box-mock':
        default:
          // For demonstration, we do nothing or show a user message
          return () => {
            console.log('Big Box Mock Exam is an external resource or not yet implemented.');
          };
      }
    },
    [courseId, navigate]
  );

  const handleActionClick = (task: KeslerTask) => {
    const action = getTaskAction(task.actionType, task.completed);
    action();
  };

  const renderTaskButton = (task: KeslerTask) => {
    const baseClasses =
      'text-white py-2 px-4 rounded-md transition duration-300 text-sm inline-flex items-center justify-center space-x-1';
    let buttonClasses = baseClasses;

    if (task.completed) {
      buttonClasses += ' bg-gray-300 cursor-not-allowed';
    } else {
      buttonClasses += ' bg-primary-orange hover:bg-primary-orange-hover';
    }

    buttonClasses += ' w-full sm:w-auto';

    return (
      <button
        onClick={() => !task.completed && handleActionClick(task)}
        disabled={task.completed}
        className={buttonClasses}
      >
        {task.completed ? (
          <>
            <CheckCircle2 className="w-4 h-4 mr-1" />
            <span>Completed</span>
          </>
        ) : (
          task.buttonText
        )}
      </button>
    );
  };

  // Toggle task completion
  const toggleTaskCompletion = async (taskId: string) => {
    const updatedTasks = keslerTasks.map(task =>
      task.id === taskId ? { ...task, completed: !task.completed } : task
    );
    setKeslerTasks(updatedTasks);
    localStorage.setItem(
      `keslerTasks_${itemId}_${taskDetails.topic_type}`,
      JSON.stringify(updatedTasks)
    );

    const allCompleted = updatedTasks.every(task => task.completed);
    if (allCompleted && !isMainTaskCompleted) {
      await handleMarkComplete();
    }
  };

  const handleMarkComplete = async () => {
    try {
      await api.markStudyTaskComplete(itemId);
      setIsMainTaskCompleted(true);

      // Mark all tasks as completed in local state and storage
      const updatedTasks = keslerTasks.map(task => ({ ...task, completed: true }));
      setKeslerTasks(updatedTasks);
      localStorage.setItem(
        `keslerTasks_${itemId}_${taskDetails.topic_type}`,
        JSON.stringify(updatedTasks)
      );

      await queryClient.invalidateQueries(['studyPlan', userId, courseId]);
      onTaskComplete();
    } catch (error) {
      console.error('Error marking task as complete:', error);
    }
  };

  const handleUncompleteTask = async () => {
    try {
      await api.unmarkStudyTaskComplete(itemId);
      setIsMainTaskCompleted(false);

      await queryClient.invalidateQueries(['studyPlan', userId, courseId]);
      onTaskComplete();
    } catch (error) {
      console.error('Error unmarking task as complete:', error);
    }
  };

  const formatContentWithLineBreaks = (content: string) => {
    return content.replace(/;(?=<\/span><span class="font-bold">)/g, ';</span><br/><span class="font-bold">');
  };

  const renderCompletionCircle = (task: KeslerTask) => {
    // No tooltips for mock exams, just a simple toggle
    return (
      <button
        onClick={() => toggleTaskCompletion(task.id)}
        className={`w-8 h-8 rounded-full flex items-center justify-center transition-all duration-300 border-2 ${
          task.completed
            ? 'bg-gray-400 border-gray-400 cursor-not-allowed'
            : 'bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-500 hover:border-primary-blue dark:hover:border-primary-blue'
        }`}
        title={task.completed ? 'Task completed' : 'Mark as complete'}
      >
        {task.completed && <Check size={16} className="mx-auto text-white" />}
      </button>
    );
  };

  const renderTaskContent = (taskDetails: StudyTaskDetails) => {
    return (
      <>
        <div className="text-center px-6 pb-6 bg-transparent">
          <h2
            className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-gray-100"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(taskDetails.task_name) }}
          />
        </div>

        {/* "Pro Tip" Card */}
        <div className="mb-6 p-4 bg-yellow-100 dark:bg-yellow-800 rounded-lg">
          <h4 className="flex items-center text-lg font-semibold mb-2 text-yellow-800 dark:text-yellow-200">
            <AlertTriangle className="mr-2" size={24} />
            PRO TIP
          </h4>
          <p className="text-yellow-800 dark:text-yellow-200">
            Don't worry about your score so much as your time management. The exam is graded
            differently than your mock exam, so instead focus on making sure you complete all the
            questions, leave time to review, and complete the exam in four hours. This will maximize
            your score on the real exam.
          </p>
        </div>

        {/* Estimated Time to Complete */}
        {renderTimeEstimate()}

        {/* Mock Exam Tasks Card */}
        <div className="space-y-4">
          {keslerTasks.map(task => (
            <div
              key={task.id}
              className={`bg-white dark:bg-gray-800 rounded-lg border-2 transition-all duration-300 ${
                task.completed
                  ? 'border-gray-200 dark:border-gray-700'
                  : 'border-gray-200 dark:border-gray-700'
              }`}
            >
              <div className="p-4 sm:p-6">
                {/* Desktop Layout */}
                <div className="hidden sm:grid grid-cols-[auto_1fr_auto] gap-6 items-center">
                  <div className="flex items-center justify-center">{renderCompletionCircle(task)}</div>
                  <div>
                    <h4
                      className={`text-lg font-medium ${
                        task.completed
                          ? 'text-gray-500 dark:text-gray-400 line-through'
                          : 'text-gray-900 dark:text-gray-100'
                      }`}
                    >
                      {task.title}
                    </h4>
                    <p
                      className={`mt-1 text-sm ${
                        task.completed
                          ? 'text-gray-400 dark:text-gray-500 line-through'
                          : 'text-gray-600 dark:text-gray-300'
                      }`}
                    >
                      {task.description}
                    </p>
                  </div>
                  <div className="flex items-center">{renderTaskButton(task)}</div>
                </div>

                {/* Mobile Layout */}
                <div className="sm:hidden flex flex-col space-y-4">
                  <div className="flex items-start justify-between">
                    <div className="flex-grow">
                      <h4
                        className={`text-lg font-medium ${
                          task.completed
                            ? 'text-gray-500 dark:text-gray-400 line-through'
                            : 'text-gray-900 dark:text-gray-100'
                        }`}
                      >
                        {task.title}
                      </h4>
                      <p
                        className={`mt-1 text-sm ${
                          task.completed
                            ? 'text-gray-400 dark:text-gray-500 line-through'
                            : 'text-gray-600 dark:text-gray-300'
                        }`}
                      >
                        {task.description}
                      </p>
                    </div>
                    <div className="ml-4">{renderCompletionCircle(task)}</div>
                  </div>
                  <div>{renderTaskButton(task)}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const content = (
    <div className="bg-transparent">
      {renderTaskContent(taskDetails)}

      {/* Mark Task as Complete / Incomplete Button */}
      <div className="mt-8">
        <button
          onClick={isMainTaskCompleted ? handleUncompleteTask : handleMarkComplete}
          className={`w-full ${
            isMainTaskCompleted
              ? 'bg-gray-400 hover:bg-gray-500'
              : 'bg-primary-blue hover:bg-primary-blue-hover'
          } text-white py-3 px-6 rounded-md transition duration-300`}
        >
          {isMainTaskCompleted ? 'Mark Task as Incomplete' : 'Mark Task as Complete'}
        </button>
      </div>
    </div>
  );

  return (
    <PremiumTaskWrapper courseId={courseId} taskType="mock" isPremium={isRestrictedAccess}>
      {content}
    </PremiumTaskWrapper>
  );
};

export default MockExamTask;
