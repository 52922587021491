import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { truncateText } from '../../utils/helpers';
import { Product, UserAccessState, TIER_IDS, COURSE_IDS } from '../../services/api';
import { Button } from '../ui/Button';
import { Crown, Gift, Rocket, Zap, HelpCircle, Users } from 'lucide-react';
import { shouldShowUpgradeButton, shouldShowActivateButton } from '../../utils/accessUtils';
import SwitchRequestModal from './../course/common/switch-course/SwitchRequestModal';
import Sidebar from '../../pages/Sidebar';

interface ProductListProps {
  products: Product[];
  accessStates: Record<string, UserAccessState>;
  switchesAvailable: number;
  onUpgrade: () => void;
  onUpgradeSMOnly: () => void;
  onActivate: (productId: string) => void;
  onRequestSwitch: () => void;
}

const StatusTag = ({ isActive }: { isActive: boolean }) => (
  <div className={`
    absolute top-3 right-3 px-2 py-1 rounded-full text-xs font-medium
    ${isActive 
      ? 'bg-emerald-100 dark:bg-emerald-900/30 text-emerald-700 dark:text-emerald-300 ring-1 ring-emerald-400/30 dark:ring-emerald-400/20' 
      : 'bg-amber-100 dark:bg-amber-900/30 text-amber-700 dark:text-amber-300 ring-1 ring-amber-400/30 dark:ring-amber-400/20'
    }
  `}>
    {isActive ? 'Active' : 'Trial'}
  </div>
);

function ProductList({ 
  products, 
  accessStates,
  switchesAvailable,
  onUpgrade,
  onUpgradeSMOnly,
  onActivate,
  onRequestSwitch
}: ProductListProps) {
  const [switchModalOpen, setSwitchModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<string>('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 1350 || window.innerHeight < 825;
      setIsMobile(mobile);
      
      // Close menu when transitioning to desktop
      if (!mobile && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMenuOpen]);

  useEffect(() => {
    // Prevent body scroll when menu is open on mobile
    if (isMenuOpen && isMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen, isMobile]);

  const hasActivePaidPurchase = Object.values(accessStates).some(state => 
    state.isActivatable
  );

  const sortedProducts = [...products]
    .filter(product => product.type !== 'mentorship')
    .sort((a, b) => {
      const aIsActive = accessStates[a.id]?.currentTier === TIER_IDS.STUDY_MATERIALS_ONLY || 
                       accessStates[a.id]?.currentTier === TIER_IDS.FULL_ACCESS;
      const bIsActive = accessStates[b.id]?.currentTier === TIER_IDS.STUDY_MATERIALS_ONLY ||
                       accessStates[b.id]?.currentTier === TIER_IDS.FULL_ACCESS;
      if (aIsActive && !bIsActive) return -1;
      if (!aIsActive && bIsActive) return 1;
      return a.title.localeCompare(b.title);
    });

  const handleActivateClick = (productId: string) => {
    if (switchesAvailable > 0) {
      onActivate(productId);
    } else {
      onRequestSwitch();
    }
  };

  const handleSwitchRequestClick = (productId: string) => {
    setSelectedProductId(productId);
    setSwitchModalOpen(true);
  };

  const shouldShowStudyGroup = (accessState: UserAccessState) => {
    return accessState.currentTier === TIER_IDS.MENTOR_ONLY ||
           accessState.currentTier === TIER_IDS.STUDY_MATERIALS_ONLY ||
           accessState.currentTier === TIER_IDS.FULL_ACCESS;
  };

  const shouldShowSwitchRequest = (accessState: UserAccessState) => {
    return hasActivePaidPurchase &&
           accessState.currentTier === TIER_IDS.FREE_TRIAL &&
           switchesAvailable === 0;
  };

  const renderButtons = (product: Product) => {
    const accessState = accessStates[product.id];
    if (!accessState) return null;

    const hasStudyAccess = accessState.currentTier === TIER_IDS.STUDY_MATERIALS_ONLY || 
                          accessState.currentTier === TIER_IDS.FULL_ACCESS;

    const topRowButtonClass = "inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 shadow-sm hover:shadow-md";
    const bottomRowButtonClass = "flex w-full items-center justify-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 shadow-sm hover:shadow-md";

    return (
      <div className="mt-4 space-y-2">
        {/* First Row - Two Column Grid */}
        <div className="grid grid-cols-2 gap-2">
          {/* Study / Trial Button */}
          {hasStudyAccess ? (
            <Link 
              to={`/course/${product.id}/study-plan`}
              className={`${topRowButtonClass} bg-blue-500 dark:bg-blue-600 text-white hover:bg-blue-600 dark:hover:bg-blue-700`}
            >
              <Zap className="w-4 h-4 mr-1.5" />
              Study
            </Link>
          ) : (
            <Link 
              to={`/course/${product.id}/study-plan`}
              className={`${topRowButtonClass} bg-gradient-to-r from-purple-500 to-blue-500 dark:from-purple-600 dark:to-blue-600 text-white hover:from-purple-600 hover:to-blue-600`}
            >
              <Gift className="w-4 h-4 mr-1.5" />
              Start Trial
            </Link>
          )}

          {/* Mentor Button */}
          <Link
            to={`/course/${product.id}/mentor`}
            className={`${topRowButtonClass} bg-purple-500 dark:bg-purple-600 text-white hover:bg-purple-600 dark:hover:bg-purple-700`}
          >
            <Crown className="w-4 h-4 mr-1.5" />
            Mentorship
          </Link>
        </div>

        {/* Second Row - Full Width Buttons */}
        <div className="flex flex-col gap-2">
          {/* Study Group Button */}
          {shouldShowStudyGroup(accessState) && (
            <a
              href="https://www.facebook.com/groups/thecpaexamcommunity"
              target="_blank"
              rel="noopener noreferrer"
              className={`${bottomRowButtonClass} bg-indigo-500 dark:bg-indigo-600 text-white hover:bg-indigo-600 dark:hover:bg-indigo-700`}
            >
              <Users className="w-4 h-4 mr-1.5" />
              Study Group
            </a>
          )}

          {/* Upgrade Button */}
          {shouldShowUpgradeButton(accessState) && (
            <Button
              onClick={accessState.hasMentorAccess ? onUpgradeSMOnly : onUpgrade}
              className={`${bottomRowButtonClass} bg-gradient-to-r from-green-500 to-emerald-500 dark:from-green-600 dark:to-emerald-600 text-white hover:from-green-600 hover:to-emerald-600`}
            >
              <Crown className="w-4 h-4 mr-1.5" />
              {accessState.hasMentorAccess ? 'Upgrade Materials' : 'Upgrade'}
            </Button>
          )}

          {/* Activate Button */}
          {shouldShowActivateButton(accessState, switchesAvailable) && (
            <Button
              onClick={() => handleActivateClick(product.id)}
              className={`${bottomRowButtonClass} bg-gradient-to-r from-orange-500 to-amber-500 dark:from-orange-600 dark:to-amber-600 text-white hover:from-orange-600 hover:to-amber-600`}
            >
              <Rocket className="w-4 h-4 mr-1.5" />
              Activate Access
            </Button>
          )}

          {/* Switch Request Button */}
          {shouldShowSwitchRequest(accessState) && (
            <Button
              onClick={() => handleSwitchRequestClick(product.id)}
              className={`${bottomRowButtonClass} bg-gray-500 dark:bg-gray-600 text-white hover:bg-gray-600 dark:hover:bg-gray-700`}
            >
              <HelpCircle className="w-4 h-4 mr-1.5" />
              Switch?
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {isMobile && (
        <Sidebar
          user={null}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
      )}
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 md:px-0">
        {sortedProducts.map((product) => {
          const isActive = accessStates[product.id]?.currentTier === TIER_IDS.STUDY_MATERIALS_ONLY ||
                         accessStates[product.id]?.currentTier === TIER_IDS.FULL_ACCESS;
          
          return (
            <div 
              key={product.id} 
              className={`
                relative rounded-xl overflow-hidden
                transition-all duration-300
                ${isActive ? `
                  bg-gradient-to-b from-white to-blue-50 
                  dark:from-gray-800 dark:to-blue-900/10
                  ring-2 ring-blue-400/40 dark:ring-blue-300/40 
                  shadow-lg shadow-blue-500/20 dark:shadow-blue-400/20
                  hover:ring-blue-400/50 dark:hover:ring-blue-300/50
                  hover:shadow-xl hover:shadow-blue-500/30 dark:hover:shadow-blue-400/30
                ` : `
                  bg-white dark:bg-gray-800
                  hover:shadow-lg
                `}
              `}
            >
              <StatusTag isActive={isActive} />
              <div className="p-5">
                <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">
                  {product.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300 text-sm mb-3 leading-relaxed">
                  {truncateText(product.description, 100)}
                </p>
                {renderButtons(product)}
              </div>
            </div>
          );
        })}
      </div>

      <SwitchRequestModal
        isOpen={switchModalOpen}
        onClose={() => setSwitchModalOpen(false)}
        productId={selectedProductId}
      />
    </>
  );
}

export default ProductList;