import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FeedbackItem, QuizHistory, SimulationQuestionData, Simulation, TIER_IDS } from '../../services/api';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../components/ui/Tabs';
import { ChevronDown, ChevronUp, Crown, X } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import SimulationsRender from './SimulationsRender';
import LoadingScreen from './common/LoadingScreen';
import SimulationNotes from './SimulationNotes';
import { api, ConfidenceLevelValue } from '../../services/api';
import { Button } from '../../components/ui/Button';
import UpgradeModal from './common/UpgradeModal';

interface SimulationReviewProps {
  courseId: string;
  userId: string;
}

interface LocationState {
  quizHistoryId?: string;
  courseId?: string;
  completed?: boolean;
  mockExamId?: string;
  // Add support for the old navigation pattern
  simulationIds?: string[];
  quizId?: string;
  from?: string;
}

interface SimulationReviewData {
  simulationId: string;
  score: number;
  confidenceLevel: ConfidenceLevelValue;
  timeSpent: number;
  feedback: FeedbackItem[];
  userAnswers: Record<string, any>;
  answer_content: string;
  html: string | null;
  exhibits: any;
  question_content: any;
  free_trial_content: boolean;
}

const SimulationReview: React.FC<SimulationReviewProps> = ({ courseId, userId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { userCourseAccesses } = useUserCourseAccess();
  const [selectedId, setSelectedId] = useState<string>('');
  const [simulationsData, setSimulationsData] = useState<SimulationReviewData[]>([]);
  const [loading, setLoading] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showComparison, setShowComparison] = useState(false);
  const state = location.state as LocationState;
  const quizHistoryId = state?.quizHistoryId;
  const [mockExamId, setMockExamId] = useState<string | undefined>(state?.mockExamId);
  const [showInitialUpgradeModal, setShowInitialUpgradeModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  
  // Add new function to check access level
  const hasFullSimulationAccess = React.useMemo(() => {
    if (!userCourseAccesses || userCourseAccesses.length === 0) return false;
    
    const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
    if (courseAccesses.length === 0) return false;

    const hasFullAccess = courseAccesses.some(
      access => access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY || access.tier_id === TIER_IDS.FULL_ACCESS
    );

    return hasFullAccess;
  }, [userCourseAccesses, courseId]);

  // Add upgrade button visibility check
  const showUpgradeButton = React.useMemo(() => {
    if (!userCourseAccesses || userCourseAccesses.length === 0) {
      return true;
    }
    
    const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
    if (courseAccesses.length === 0) {
      return true;
    }

    const hasFullAccess = courseAccesses.some(access => 
      access.tier_id === TIER_IDS.FULL_ACCESS || 
      access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY
    );

    if (hasFullAccess) {
      return false;
    }

    const onlyHasRestrictedAccess = courseAccesses.every(access =>
      access.tier_id === TIER_IDS.FREE_TRIAL || 
      access.tier_id === TIER_IDS.MENTOR_ONLY
    );

    return onlyHasRestrictedAccess;
  }, [userCourseAccesses, courseId]);

  useEffect(() => {
    const loadResults = async () => {
      const state = location.state as LocationState;
      
      // Handle missing state
      if (!state) {
        setError('No state found in location');
        setLoading(false);
        return;
      }
    
      // Determine quizHistoryId from either direct prop or converted from old pattern
      const effectiveQuizHistoryId = state.quizHistoryId || state.quizId;
      
      if (!effectiveQuizHistoryId || !courseId) {
        console.error('Missing required parameters:', { state, courseId });
        setError('Missing required parameters');
        setLoading(false);
        return;
      }
    
      try {
        setLoading(true);
        const quizHistory: QuizHistory = await api.getSimulationQuizHistoryById(effectiveQuizHistoryId);
        
        // Rest of the loading logic remains the same...
        const answeredQuestionsData = (quizHistory.questions_data as SimulationQuestionData[])
          .filter(q => 
            q.answered && 
            q.score !== undefined && 
            q.score !== null && 
            q.feedback && 
            Array.isArray(q.feedback) && 
            q.feedback.length > 0
          );
    
        const simulationIds = answeredQuestionsData.map(q => q.simulationId);
        const simulations = await api.getSimulationsById(simulationIds, userId, courseId);
    
        const resultsData: SimulationReviewData[] = answeredQuestionsData.map((q): SimulationReviewData => {
          const sim = simulations.find(s => s.id === q.simulationId);
          const timeSpent = typeof q.timeSpent === 'number' ? q.timeSpent : 0;
          
          if (!sim) {
            return {
              simulationId: q.simulationId,
              score: q.score || 0,
              confidenceLevel: q.confidenceLevel || 'guessing',
              timeSpent,
              feedback: q.feedback || [],
              userAnswers: q.userAnswer ? JSON.parse(q.userAnswer) : {},
              answer_content: '',
              html: null,
              exhibits: null,
              question_content: {},
              free_trial_content: false
            };
          }
    
          return {
            simulationId: q.simulationId,
            score: q.score || 0,
            confidenceLevel: q.confidenceLevel || 'guessing',
            timeSpent,
            feedback: q.feedback || [],
            userAnswers: q.userAnswer ? JSON.parse(q.userAnswer) : {},
            answer_content: sim.answer_content,
            html: sim.html,
            exhibits: sim.exhibits,
            question_content: sim.question_content,
            free_trial_content: sim.free_trial_content
          };
        });
    
        if (resultsData.length > 0) {
          setSelectedId(resultsData[0].simulationId);
        }
        setSimulationsData(resultsData);
        
        // Show initial upgrade modal for restricted access users after completing simulations
        if (showUpgradeButton && !hasFullSimulationAccess) {
          setShowInitialUpgradeModal(true);
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error loading simulation review:', err);
        setError('Failed to load simulation results. Please try again.');
        setLoading(false);
      }
    };

    loadResults();
  }, [quizHistoryId, courseId, userId, showUpgradeButton, hasFullSimulationAccess]);

  const calculateAverageScore = () => {
    if (simulationsData.length === 0) return 0;
    const total = simulationsData.reduce((sum, r) => sum + r.score, 0);
    return (total / simulationsData.length).toFixed(1);
  };

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleBackToSimulations = () => {
    setIsTransitioning(true);
    if (mockExamId) {
      // If we came from a mock exam, go back to mock exam view
      navigate(`/course/${courseId}/mock-exam/${mockExamId}`);
    } else {
      // Otherwise go back to simulations
      navigate(`/course/${courseId}/simulations`);
    }
  };

  if (loading) {
    return <LoadingScreen message="Loading Simulation Review..." />;
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] space-y-4">
        <p className="text-lg text-red-600 dark:text-red-400">{error}</p>
        <button
          onClick={() => navigate(-1)}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Go Back
        </button>
      </div>
    );
  }

  if (simulationsData.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] space-y-4">
        <p className="text-lg text-gray-600 dark:text-gray-400">
          No simulation results found.
        </p>
        <button
          onClick={() => navigate(`/course/${courseId}/simulations`)}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Back to Simulations
        </button>
      </div>
    );
  }

  // Add initial upgrade modal component
  const renderInitialUpgradeModal = () => {
    if (!showInitialUpgradeModal) return null;

    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-lg w-full mx-auto">
          <div className="flex justify-end p-2">
            <button
              onClick={() => setShowInitialUpgradeModal(false)}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-full p-1 
                       hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
            >
              <X size={24} />
            </button>
          </div>
          
          <div className="px-6 pb-6">
            <div className="text-center mb-6">
              <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
                Ready to Unlock Full Access?
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Great job completing the simulations! Upgrade now to access our complete
                simulation bank and boost your CPA exam preparation.
              </p>
              
              <div className="space-y-3">
                <Button
                  onClick={() => {
                    setShowInitialUpgradeModal(false);
                    setShowUpgradeModal(true);
                  }}
                  className="w-full bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center 
                           justify-center py-3 rounded-lg transition-colors"
                >
                  <Crown size={20} className="mr-2" />
                  Upgrade Now
                </Button>
                
                <Button
                  onClick={() => setShowInitialUpgradeModal(false)}
                  className="w-full bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 
                           text-gray-700 dark:text-gray-200 py-3 rounded-lg transition-colors"
                >
                  Maybe Later
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  return (
  <>
    {isTransitioning && (
      <div className="fixed inset-0 bg-white dark:bg-gray-900 z-50">
        <LoadingScreen 
          message="Returning to Simulations..." 
          subMessage="Almost there" 
        />
      </div>
    )}
    <div className="p-4 sm:p-6 lg:p-8">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Overall Results</h2>
            <button
              onClick={handleBackToSimulations}
              className="px-4 py-2 bg-orange-500 hover:bg-orange-600 text-white rounded-lg font-medium transition-colors duration-200"
            >
              Back to Simulations
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-600 dark:text-gray-400">Average Score</p>
              <p className="text-2xl font-bold">{calculateAverageScore()}%</p>
            </div>
            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-600 dark:text-gray-400">Total Time</p>
              <p className="text-2xl font-bold">
                {formatTime(simulationsData.reduce((sum, r) => sum + (r.timeSpent || 0), 0))}
              </p>
            </div>
            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-600 dark:text-gray-400">Simulations Completed</p>
              <p className="text-2xl font-bold">{simulationsData.length}</p>
            </div>
          </div>
        </div>

        <Tabs value={selectedId} onValueChange={setSelectedId}>
          <TabsList className="mb-4">
            {simulationsData.map((result, index) => (
              <TabsTrigger 
                key={result.simulationId} 
                value={result.simulationId}
                className="min-w-[150px]"
              >
                Simulation {index + 1} ({result.score.toFixed(1)}%)
              </TabsTrigger>
            ))}
          </TabsList>

          {simulationsData.map((result, index) => {
            // Construct a full Simulation object with default values
            const simulation: Simulation = {
              id: result.simulationId,
              course_id: courseId!,
              question_type: 'mixed_table',
              question_content: result.question_content,
              html: result.html,
              exhibits: result.exhibits,
              answer_content: result.answer_content,
              correct_answer: {}, // no correct answers provided, so empty
              question_content_file: null,
              answer_content_file: null,
              exam_id: null,
              exam_name: null,
              exam_section_name: null,
              exam_section_time_allowed: null,
              question_category_id: null,
              question_category_name: null,
              sub_question_category_name: null,
              choices_count: 0,
              related_lessons: [],
              question_number: 1,
              answers_count: 0,
              elo_rank: null,
              correct_answers_count: 0,
              incorrect_answers_count: 0,
              related_section_ids: [],
              related_section_names: [],
              external_id: null,
              tags: [],
              answer_container_type: null,
              last_updated: '',
              deleted_at: null,
              free_trial_content: result.free_trial_content
            };

            return (
              <TabsContent key={result.simulationId} value={result.simulationId}>
                <div className="space-y-6">
                  <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
                    <h2 className="text-xl font-bold mb-4">Results Summary</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                        <p className="text-sm text-gray-600 dark:text-gray-400">Score</p>
                        <p className="text-2xl font-bold">{result.score.toFixed(1)}%</p>
                      </div>
                      <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                        <p className="text-sm text-gray-600 dark:text-gray-400">Confidence Level</p>
                        <p className="text-2xl font-bold capitalize">{result.confidenceLevel}</p>
                      </div>
                      <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                        <p className="text-sm text-gray-600 dark:text-gray-400">Time Spent</p>
                        <p className="text-2xl font-bold">{formatTime(result.timeSpent || 0)}</p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg">
                    <SimulationNotes 
                      simulationId={result.simulationId}
                      courseId={courseId!}
                      userId={userId}
                    />
                  </div>

                  <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
                    <SimulationsRender
                      simulation={simulation}
                      onAnswerChange={() => {}}
                      userAnswers={result.userAnswers}
                      isSubmitted={true}
                      gradingResults={{ score: result.score, feedback: result.feedback }}
                      currentSimulationIndex={index}
                      totalSimulations={simulationsData.length}
                      userId={userId}
                      courseId={courseId!}
                      isReviewing={true}
                    />
                  </div>

                  <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg">
                    <div className="p-6">
                      <h3 className="text-xl font-bold mb-6">Solution</h3>
                      <button
                        onClick={() => setShowComparison(!showComparison)}
                        className="w-full flex items-center justify-between p-4 bg-gray-100 dark:bg-gray-700 rounded-lg mb-6 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200"
                      >
                        <span className="text-lg font-semibold">Response Comparison</span>
                        {showComparison ? <ChevronUp className="w-6 h-6" /> : <ChevronDown className="w-6 h-6" />}
                      </button>

                      {showComparison && (
                        <div className="mb-6 space-y-6">
                          {result.feedback
                            .filter(f => f.questionId !== 'totals' && f.questionId !== 'header')
                            .map((item) => {
                              const questionNum = item.questionId.replace(/[^0-9]/g, '');
                              return (
                                <div key={`${result.simulationId}-${item.questionId}`} className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                                  <h4 className="text-lg font-semibold mb-2">Question {questionNum}</h4>
                                  {Object.entries(item.answerFeedback).map(([key, val]) => {
                                    const isCorrect = val.isCorrect;
                                    const userAnswerValue = typeof val.userAnswer === 'object' && val.userAnswer !== null
                                      ? val.userAnswer.value
                                      : val.userAnswer;
                                    const correctAnswerValue = typeof val.correctAnswer === 'object' && val.correctAnswer !== null
                                      ? val.correctAnswer.value
                                      : val.correctAnswer;

                                    return (
                                      <div 
                                        key={`${result.simulationId}-${item.questionId}-${key}`} 
                                        className={`p-2 rounded ${isCorrect ? 'bg-green-100 dark:bg-green-800' : 'bg-red-100 dark:bg-red-800'}`}
                                      >
                                        <p className={`${isDarkMode ? 'text-gray-200' : 'text-gray-800'} ${isCorrect ? 'font-bold' : ''}`}>
                                          Your answer: <span className={isCorrect ? (isDarkMode ? 'text-green-400' : 'text-green-600') : (isDarkMode ? 'text-red-400' : 'text-red-600')}>
                                            {userAnswerValue}
                                          </span>
                                        </p>
                                        <p className={`${isDarkMode ? 'text-gray-200' : 'text-gray-800'} font-bold`}>
                                          Correct answer: {correctAnswerValue}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                        </div>
                      )}

                      <div 
                        dangerouslySetInnerHTML={{ 
                          __html: `<div class="${isDarkMode ? 'text-gray-300' : 'text-gray-700'} [&>div]:space-y-6 [&>div>div]:bg-gray-800/5 dark:[&>div>div]:bg-gray-100/5 [&>div>div]:p-4 [&>div>div]:rounded-lg [&_p]:mb-4 last:[&_p]:mb-0 [&_h5]:font-bold [&_h5]:text-lg [&_h5]:mb-2">${result.answer_content}</div>` 
                        }} 
                      />
                    </div>
                  </div>
                </div>
              </TabsContent>
            );
          })}
        </Tabs>
      </div>
    {/* Add both upgrade modals */}
      {renderInitialUpgradeModal()}
      <UpgradeModal 
        isOpen={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
      />
    </div>
  </>
);
};

export default SimulationReview;