import React, { createContext, useContext, useReducer, useCallback, useEffect, useRef } from 'react';

interface BaseTimerState {
  startTime: number;
  isRunning: boolean;
  elapsedTimeBeforePause: number;
  elapsedTime: number;
}

interface MockExamTimerState extends BaseTimerState {
  specialBreakPaused: boolean;
}

interface BaseTimerContextType extends BaseTimerState {
    setStartTime: (time: number) => void;
    setIsRunning: (isRunning: boolean) => void;
    setElapsedTimeBeforePause: (time: number) => void;
    setElapsedTime: (time: number) => void;
    resetTimer: () => void;
    // Change this line to be conditional on the timer type
    updateTimerState: <T extends BaseTimerState>(updates: Partial<T>) => Promise<void>;
  }
  
  interface MockExamTimerContextType extends Omit<BaseTimerContextType, 'updateTimerState'> {
    specialBreakPaused: boolean;
    setSpecialBreakPaused: (paused: boolean) => void;
    resumeFromBreak: (startTime: number, elapsedTime: number) => Promise<void>;
    // Add specific type for mock exam timer
    updateTimerState: (updates: Partial<MockExamTimerState>) => Promise<void>;
  }

const MockExamTimerContext = createContext<MockExamTimerContextType | undefined>(undefined);
const SimulationTimerContext = createContext<BaseTimerContextType | undefined>(undefined);
const QuizTimerContext = createContext<BaseTimerContextType | undefined>(undefined);
const DEBOUNCE_INTERVAL = 500;

type TimerAction = 
  | { type: 'RESET' }
  | { type: 'SET_START_TIME'; time: number }
  | { type: 'SET_RUNNING'; isRunning: boolean }
  | { type: 'SET_ELAPSED_TIME_BEFORE_PAUSE'; time: number }
  | { type: 'SET_ELAPSED_TIME'; time: number }
  | { type: 'SET_SPECIAL_BREAK_PAUSED'; paused: boolean }
  | { type: 'UPDATE_MULTIPLE'; updates: Partial<MockExamTimerState | BaseTimerState> };

const timerReducer = (state: MockExamTimerState | BaseTimerState, action: TimerAction): MockExamTimerState | BaseTimerState => {
  switch (action.type) {
    case 'RESET':
      return {
        startTime: Date.now(),
        isRunning: false,
        elapsedTimeBeforePause: 0,
        elapsedTime: 0,
        ...('specialBreakPaused' in state ? { specialBreakPaused: false } : {})
      };
    case 'SET_START_TIME':
      return { ...state, startTime: action.time };
    case 'SET_RUNNING':
      return { ...state, isRunning: action.isRunning };
    case 'SET_ELAPSED_TIME_BEFORE_PAUSE':
      return { ...state, elapsedTimeBeforePause: action.time };
    case 'SET_ELAPSED_TIME':
      return { ...state, elapsedTime: action.time };
    case 'SET_SPECIAL_BREAK_PAUSED':
      return 'specialBreakPaused' in state 
        ? { ...state, specialBreakPaused: action.paused } as MockExamTimerState
        : state;
    case 'UPDATE_MULTIPLE':
      return { ...state, ...action.updates };
    default:
      return state;
  }
};

const createTimerProvider = (
  Context: React.Context<any>,
  storagePrefix: string,
  includeBreakPause = false
) => {
  return ({ children }: { children: React.ReactNode }) => {
    const getInitialState = () => {
      const baseState: BaseTimerState = {
        startTime: parseInt(localStorage.getItem(`${storagePrefix}StartTime`) || Date.now().toString()),
        isRunning: localStorage.getItem(`${storagePrefix}Running`) === 'true',
        elapsedTimeBeforePause: parseInt(localStorage.getItem(`${storagePrefix}ElapsedTimeBeforePause`) || '0'),
        elapsedTime: 0,
      };

      if (includeBreakPause) {
        return {
          ...baseState,
          specialBreakPaused: localStorage.getItem(`${storagePrefix}SpecialBreakPaused`) === 'true'
        } as MockExamTimerState;
      }

      return baseState;
    };

    const [state, dispatch] = useReducer(timerReducer, null, getInitialState);
    const lastUpdateTimeRef = useRef<{ [key: string]: number }>({});
    const updateTimeoutRef = useRef<{ [key: string]: NodeJS.Timeout }>({});

    const debouncedUpdate = useCallback((
      key: string,
      action: TimerAction,
      storageKey: string,
      value: any
    ) => {
      const now = Date.now();
      const lastUpdate = lastUpdateTimeRef.current[key] || 0;

      if (updateTimeoutRef.current[key]) {
        clearTimeout(updateTimeoutRef.current[key]);
      }

      if (now - lastUpdate < DEBOUNCE_INTERVAL) {
        updateTimeoutRef.current[key] = setTimeout(() => {
          localStorage.setItem(storageKey, value.toString());
          dispatch(action);
          lastUpdateTimeRef.current[key] = Date.now();
        }, DEBOUNCE_INTERVAL);
      } else {
        localStorage.setItem(storageKey, value.toString());
        dispatch(action);
        lastUpdateTimeRef.current[key] = now;
      }
    }, []);

    const resetTimer = useCallback(() => {
      localStorage.removeItem(`${storagePrefix}StartTime`);
      localStorage.removeItem(`${storagePrefix}Running`);
      localStorage.removeItem(`${storagePrefix}ElapsedTimeBeforePause`);
      if (includeBreakPause) {
        localStorage.removeItem(`${storagePrefix}SpecialBreakPaused`);
      }
      dispatch({ type: 'RESET' });
    }, [storagePrefix, includeBreakPause]);

    const setStartTime = useCallback((time: number) => {
      debouncedUpdate(
        'startTime',
        { type: 'SET_START_TIME', time },
        `${storagePrefix}StartTime`,
        time
      );
    }, [debouncedUpdate, storagePrefix]);

    const setIsRunning = useCallback((isRunning: boolean) => {
      debouncedUpdate(
        'isRunning',
        { type: 'SET_RUNNING', isRunning },
        `${storagePrefix}Running`,
        isRunning
      );
    }, [debouncedUpdate, storagePrefix]);

    const setElapsedTimeBeforePause = useCallback((time: number) => {
      debouncedUpdate(
        'elapsedTimeBeforePause',
        { type: 'SET_ELAPSED_TIME_BEFORE_PAUSE', time },
        `${storagePrefix}ElapsedTimeBeforePause`,
        time
      );
    }, [debouncedUpdate, storagePrefix]);

    const setElapsedTime = useCallback((time: number) => {
      debouncedUpdate(
        'elapsedTime',
        { type: 'SET_ELAPSED_TIME', time },
        `${storagePrefix}ElapsedTime`,
        time
      );
    }, [debouncedUpdate, storagePrefix]);

    const setSpecialBreakPaused = useCallback((paused: boolean) => {
      if (includeBreakPause) {
        debouncedUpdate(
          'specialBreakPaused',
          { type: 'SET_SPECIAL_BREAK_PAUSED', paused },
          `${storagePrefix}SpecialBreakPaused`,
          paused
        );
      }
    }, [debouncedUpdate, storagePrefix, includeBreakPause]);

    // Update the updateTimerState implementation
    const updateTimerState = useCallback(async <T extends BaseTimerState>(updates: Partial<T>) => {
        const updatePromises = Object.entries(updates).map(([key, value]) => {
        return new Promise<void>((resolve) => {
            const storageKey = `${storagePrefix}${key.charAt(0).toUpperCase()}${key.slice(1)}`;
            if (value !== undefined) {
            localStorage.setItem(storageKey, value.toString());
            }
            resolve();
        });
        });
    
        await Promise.all(updatePromises);
        dispatch({ type: 'UPDATE_MULTIPLE', updates });
        await new Promise(resolve => setTimeout(resolve, 50));
    }, [storagePrefix]);
    
    // Update resumeFromBreak to use the correct type
    const resumeFromBreak = useCallback(async (startTime: number, elapsedTime: number) => {
        await updateTimerState<MockExamTimerState>({
        startTime,
        elapsedTimeBeforePause: elapsedTime,
        specialBreakPaused: false,
        isRunning: true
        });
    }, [updateTimerState]);

    useEffect(() => {
      return () => {
        Object.values(updateTimeoutRef.current).forEach(timeout => {
          clearTimeout(timeout);
        });
      };
    }, []);

    const value = {
      ...state,
      setStartTime,
      setIsRunning,
      setElapsedTimeBeforePause,
      setElapsedTime,
      resetTimer,
      updateTimerState,
      ...(includeBreakPause && { 
        setSpecialBreakPaused,
        resumeFromBreak 
      })
    } as MockExamTimerContextType | BaseTimerContextType;

    return <Context.Provider value={value}>{children}</Context.Provider>;
  };
};

export const MockExamTimerProvider = createTimerProvider(MockExamTimerContext, 'mockExam', true);
export const SimulationTimerProvider = createTimerProvider(SimulationTimerContext, 'simulation');
export const QuizTimerProvider = createTimerProvider(QuizTimerContext, 'quiz');

export const useMockExamTimer = (): MockExamTimerContextType => {
  const context = useContext(MockExamTimerContext);
  if (context === undefined) {
    throw new Error('useMockExamTimer must be used within a MockExamTimerProvider');
  }
  return context;
};

export const useSimulationTimer = (): BaseTimerContextType => {
  const context = useContext(SimulationTimerContext);
  if (context === undefined) {
    throw new Error('useSimulationTimer must be used within a SimulationTimerProvider');
  }
  return context;
};

export const useQuizTimer = (): BaseTimerContextType => {
  const context = useContext(QuizTimerContext);
  if (context === undefined) {
    throw new Error('useQuizTimer must be used within a QuizTimerProvider');
  }
  return context;
};