import React, { useState, useEffect, useRef } from 'react';
import { Clock, PauseCircle } from 'lucide-react';
import { useMockExamTimer } from '../../contexts/TimerContext';

interface MockTimerProps {
  onTimeUpdate?: (elapsedTime: number) => void;
  className?: string;
  fixedTime?: number;
  showIcon?: boolean;
  variant?: 'default' | 'large' | 'small';
}

const MockTimer: React.FC<MockTimerProps> = ({
  onTimeUpdate,
  className = '',
  fixedTime,
  showIcon = true,
  variant = 'default'
}) => {
  const timer = useMockExamTimer();
  const [displayTime, setDisplayTime] = useState<number | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout>();
  
  const sizeClasses = {
    small: 'text-base',
    default: 'text-lg',
    large: 'text-2xl'
  };

  // Handle initial timer setup and updates
  useEffect(() => {
    const updateDisplayTime = () => {
      // If we have a fixed time, use that
      if (fixedTime !== undefined) {
        setDisplayTime(fixedTime);
        setIsInitialized(true);
        return;
      }

      // If timer is paused or not running, maintain current display time
      if (!timer.isRunning || timer.specialBreakPaused) {
        const elapsed = timer.elapsedTimeBeforePause || 0;
        setDisplayTime(elapsed);
        setIsInitialized(true);
        return;
      }

      // Calculate current elapsed time
      if (timer.startTime && timer.startTime <= Date.now()) {
        const now = Date.now();
        const elapsedSeconds = Math.floor((now - timer.startTime) / 1000);
        const totalElapsed = elapsedSeconds + (timer.elapsedTimeBeforePause || 0);
        setDisplayTime(totalElapsed);
        setIsInitialized(true);
      }
    };

    // Initial update
    updateDisplayTime();

    // Clear any existing interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }

    // Only set up interval if timer should be running
    if (!fixedTime && !timer.specialBreakPaused && timer.isRunning) {
      intervalRef.current = setInterval(updateDisplayTime, 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [
    timer.startTime,
    timer.isRunning,
    timer.specialBreakPaused,
    timer.elapsedTimeBeforePause,
    fixedTime,
    isInitialized
  ]);

  // Update onTimeUpdate callback
  useEffect(() => {
    if (displayTime !== null && onTimeUpdate) {
      onTimeUpdate(displayTime);
    }
  }, [displayTime, onTimeUpdate]);

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const getTimerColor = () => {
    if (timer.specialBreakPaused) return 'text-yellow-500 dark:text-yellow-400';
    if (displayTime && displayTime >= 14400) return 'text-red-500 dark:text-red-400';
    if (displayTime && displayTime >= 12600) return 'text-yellow-500 dark:text-yellow-400';
    return 'text-blue-500 dark:text-blue-400';
  };

  if (!isInitialized || displayTime === null) {
    return (
      <div className={`flex items-center gap-2 ${sizeClasses[variant]} text-gray-400 ${className}`}>
        <Clock className={`${variant === 'small' ? 'w-4 h-4' : 'w-5 h-5'} animate-pulse`} />
        <span className="font-mono">...</span>
      </div>
    );
  }

  return (
    <div className={`flex items-center gap-2 ${sizeClasses[variant]} ${getTimerColor()} ${className}`}>
      {showIcon && (
        timer.specialBreakPaused ? (
          <PauseCircle className={`${variant === 'small' ? 'w-4 h-4' : 'w-5 h-5'} flex-shrink-0`} />
        ) : (
          <Clock className={`${variant === 'small' ? 'w-4 h-4' : 'w-5 h-5'} flex-shrink-0`} />
        )
      )}
      <span className="font-mono">{formatTime(Math.max(0, displayTime))}</span>
    </div>
  );
};

export default React.memo(MockTimer);