import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../services/auth';
import { Button } from '../ui/Button';
import { ArrowLeft, Mail, AlertCircle, CheckCircle } from 'lucide-react';
import { Alert, AlertDescription } from '../ui/Alert';

interface ForgotPasswordProps {
  onBackToLogin?: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onBackToLogin }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setMessage('');

    try {
      await auth.resetPassword(email);
      setMessage('Password reset email sent. Please check your inbox.');
    } catch (err) {
      setError('Failed to send reset email. Please verify your email and try again.');
    } finally {
      setIsLoading(false);
    }
  }, [email]);

  return (
    <div className="min-h-screen flex items-top justify-center py-6 px-4 sm:px-6 lg:px-8 bg-gray-50 dark:bg-gray-900">
      <div className="w-full max-w-md space-y-8">
        {/* Header Section */}
        <div className="text-center">
          <Link 
            to="/login"
            className="inline-flex items-center text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200 mb-8"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Login
          </Link>
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
            Reset Password
          </h2>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            Enter your email address and we'll send you instructions to reset your password.
          </p>
        </div>

        {/* Alert Messages */}
        {(message || error) && (
          <Alert className={`${message ? 'bg-green-50 dark:bg-green-900/30 border-green-200 dark:border-green-800' : 
                                       'bg-red-50 dark:bg-red-900/30 border-red-200 dark:border-red-800'}`}>
            <div className="flex items-center gap-3">
              {message ? (
                <CheckCircle className="h-4 w-4 text-green-600 dark:text-green-400 flex-shrink-0" />
              ) : (
                <AlertCircle className="h-4 w-4 text-red-600 dark:text-red-400 flex-shrink-0" />
              )}
              <AlertDescription className={`${message ? 'text-green-700 dark:text-green-300' : 
                                                       'text-red-700 dark:text-red-300'}`}>
                {message || error}
              </AlertDescription>
            </div>
          </Alert>
        )}

        {/* Form Section */}
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="space-y-2">
            <label 
              htmlFor="email" 
              className="block text-sm font-medium text-gray-700 dark:text-gray-300"
            >
              Email address
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail className="h-5 w-5 text-gray-400" />
              </div>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 
                          rounded-lg bg-white dark:bg-gray-800 
                          text-gray-900 dark:text-white
                          placeholder-gray-500 dark:placeholder-gray-400
                          focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 
                          focus:border-transparent
                          transition duration-150 ease-in-out"
                placeholder="Enter your email"
              />
            </div>
          </div>

          <Button
            type="submit"
            disabled={isLoading}
            className="w-full flex justify-center py-3 px-4 border border-transparent 
                     rounded-lg shadow-sm text-sm font-medium text-white
                     bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600
                     focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                     disabled:opacity-50 disabled:cursor-not-allowed
                     transition duration-150 ease-in-out"
          >
            {isLoading ? 'Sending...' : 'Send Reset Link'}
          </Button>

          <div className="text-center">
            <Link 
              to="/login"
              className="text-sm text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300"
            >
              Remember your password? Sign in
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;