import React, { useState, useEffect } from 'react';
import { supabase, supabaseAdmin } from '../../../services/supabase';
import { format } from 'date-fns';
import { auth } from '../../../services/auth';
import { 
  Activity,
  Clock,
  Loader2,
  Mail,
  UserCircle,
  BookOpen,
  HelpCircle,
  AlertCircle,
  Send,
  ExternalLink
} from 'lucide-react';
import { Card } from '../../ui/Card';
import { Badge } from '../../ui/Badge';
import { Separator } from '../../ui/Separator';
import { Alert, AlertDescription } from '../../ui/Alert';
import { Button } from '../../ui/Button';
import CourseAccessControls from '../CourseAccessControls';
import PaymentControls from '../PaymentControls';
import { 
  TIER_IDS, 
  COURSE_IDS, 
  User,
  UserCourseAccess,
  UserCoursePurchase,
  Product,
  SupportUser,
  Question,
  Lesson,
  Simulation,
  Flashcard,
  ContentType
} from '../../../services/api';

interface CourseAccessStatus {
  productTitle: string;
  accessType: 'Full Access' | 'Study Materials Only' | 'Mentor Only' | 'Free Trial';
  tierId: string;
}

interface CustomerSidebarProps {
  userId: string;
  contentType?: ContentType;
  contentId?: string | null;
  onRefreshData: (status?: string) => void;
  onNavigateToContent?: (contentType: ContentType, contentId: string) => void;
}

const CustomerSidebar: React.FC<CustomerSidebarProps> = ({ 
  userId, 
  contentType,
  contentId,
  onRefreshData,
  onNavigateToContent 
}) => {
  // Support user state
  const [supportUser, setSupportUser] = useState<SupportUser | null>(null);
  
  // Auth user related states
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [purchases, setPurchases] = useState<UserCoursePurchase[]>([]);
  const [courseAccess, setCourseAccess] = useState<CourseAccessStatus[]>([]);
  const [products, setProducts] = useState<Record<string, string>>({});
  const [userCourseAccess, setUserCourseAccess] = useState<UserCourseAccess[]>([]);
  
  // Content states with proper typing
  const [question, setQuestion] = useState<Question | null>(null);
  const [lesson, setLesson] = useState<Lesson | null>(null);
  const [simulation, setSimulation] = useState<Simulation | null>(null);
  const [flashcard, setFlashcard] = useState<Flashcard | null>(null);
  const [contentLoading, setContentLoading] = useState(false);
  
  // Common states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Auth invite states
  const [sendingInvite, setSendingInvite] = useState(false);
  const [inviteError, setInviteError] = useState<string | null>(null);
  const [invitationSent, setInvitationSent] = useState<boolean>(false);
  const [lastInviteTime, setLastInviteTime] = useState<Date | null>(null);

  useEffect(() => {
    loadInitialData();
  }, [userId]);

  useEffect(() => {
    if (contentId && contentType) {
      loadContentData();
    } else {
      setQuestion(null);
      setLesson(null);
      setSimulation(null);
      setFlashcard(null);
      setError(null);
    }
  }, [contentId, contentType]);

  const loadContentData = async () => {
    if (!contentId || !contentType) return;
    
    try {
      setContentLoading(true);
      setError(null);

      const tableName = {
        questions: 'questions',
        lessons: 'lessons',
        simulations: 'simulations',
        flashcards: 'flashcards'
      }[contentType];

      const { data, error } = await supabase
        .from(tableName)
        .select('*')
        .eq('id', contentId)
        .maybeSingle();

      if (error) throw error;
      if (!data) {
        setError(`${contentType} not found`);
        return;
      }

      // Update the appropriate state based on content type
      switch (contentType) {
        case 'questions':
          setQuestion(data as Question);
          break;
        case 'lessons':
          setLesson(data as Lesson);
          break;
        case 'simulations':
          setSimulation(data as Simulation);
          break;
        case 'flashcards':
          setFlashcard(data as Flashcard);
          break;
      }

    } catch (error: any) {
      console.error(`Error loading ${contentType}:`, error);
      setError(error.message || `Failed to load ${contentType}`);
    } finally {
      setContentLoading(false);
    }
  };

  const loadAuthUserData = async (authUserId: string) => {
    try {
      if (!authUserId) return;
  
      // Use supabaseAdmin to bypass RLS
      const { data: userData, error: userError } = await supabaseAdmin
        .from('users')
        .select('*')
        .eq('id', authUserId)
        .maybeSingle();
  
      if (userError) throw userError;
      if (!userData) return;
  
      // Use supabaseAdmin for purchases as well
      const { data: purchasesData, error: purchasesError } = await supabaseAdmin
        .from('user_course_purchases')
        .select('*')
        .eq('user_id', authUserId)
        .order('purchase_date', { ascending: false });
  
      if (purchasesError) throw purchasesError;
  
      // Use supabaseAdmin for course access
      const { data: accessData, error: accessError } = await supabaseAdmin
        .from('user_course_access')
        .select('*')
        .eq('user_id', authUserId);
  
      if (accessError) throw accessError;
  
      // Update state only if we have valid user data
      setAuthUser(userData);
      setPurchases(purchasesData || []);
      setUserCourseAccess(accessData || []);
      
      const accessStatuses: CourseAccessStatus[] = (accessData || []).map((access) => ({
        productTitle: access.course_id,
        accessType: getAccessType(access.tier_id),
        tierId: access.tier_id
      }));
  
      setCourseAccess(accessStatuses);
  
    } catch (error) {
      console.error('Error loading auth user data:', error);
      throw error;
    }
  };
  
  const getAccessType = (tierId: string): CourseAccessStatus['accessType'] => {
    switch (tierId) {
      case TIER_IDS.FULL_ACCESS:
        return 'Full Access';
      case TIER_IDS.STUDY_MATERIALS_ONLY:
        return 'Study Materials Only';
      case TIER_IDS.MENTOR_ONLY:
        return 'Mentor Only';
      default:
        return 'Free Trial';
    }
  };
  
  const loadInitialData = async () => {
    try {
      setLoading(true);
      setError(null);
  
      console.log('Loading initial data for support user ID:', userId);
  
      // Use supabaseAdmin for support user query
      const { data: supportUserData, error: supportUserError } = await supabaseAdmin
        .from('support_users')
        .select('*')
        .eq('id', userId)
        .maybeSingle();
  
      if (supportUserError) {
        console.error('Error fetching support user:', supportUserError);
        throw supportUserError;
      }
  
      if (!supportUserData) {
        console.error('Support user not found');
        throw new Error('Support user not found');
      }
  
      setSupportUser(supportUserData);
      console.log('Support user loaded:', supportUserData);
  
      if (supportUserData.auth_user_id) {
        console.log('Loading auth user data for ID:', supportUserData.auth_user_id);
        await Promise.all([
          loadAuthUserData(supportUserData.auth_user_id),
          loadProducts()
        ]);
      } else {
        console.log('No auth_user_id found for support user');
      }
  
    } catch (error) {
      console.error('Error loading initial data:', error);
      setError(error instanceof Error ? error.message : 'Failed to load user data');
    } finally {
      setLoading(false);
    }
  };

  const loadProducts = async () => {
    try {
      const { data: productsData, error: productsError } = await supabase
        .from('products')
        .select('id, title')
        .in('type', ['course', 'mentorship'])
        .in('id', Object.values(COURSE_IDS));

      if (productsError) throw productsError;

      const productMap: Record<string, string> = {};
      productsData?.forEach((product: Pick<Product, 'id' | 'title'>) => {
        productMap[product.id] = product.title;
      });
      
      setProducts(productMap);
    } catch (error) {
      console.error('Error loading products:', error);
    }
  };

  const sendAuthInvite = async () => {
    if (!supportUser?.email) {
      console.error('No support user email available');
      return;
    }
    
    try {
      console.log('Starting authentication email process for:', supportUser.email);
      setSendingInvite(true);
      setInviteError(null);
      
      const { data, error } = await auth.sendAuthenticationEmail(supportUser.email);

      if (error) {
        console.error('Error sending authentication email:', error);
        throw error;
      }

      // Update UI state
      setInvitationSent(true);
      setLastInviteTime(new Date());
      onRefreshData('Authentication email sent successfully');
    } catch (error) {
      console.error('Error in sendAuthInvite:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to send authentication email';
      setInviteError(errorMessage);
    } finally {
      setSendingInvite(false);
    }
  };

  const resendAuthInvite = async () => {
    if (!supportUser?.email) return;

    try {
      setSendingInvite(true);
      setInviteError(null);

      const { error } = await auth.resendAuthenticationEmail(supportUser.email);

      if (error) throw error;

      setLastInviteTime(new Date());
      onRefreshData('Authentication email resent successfully');
    } catch (error) {
      console.error('Error resending authentication email:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to resend authentication email';
      setInviteError(errorMessage);
    } finally {
      setSendingInvite(false);
    }
  };

  // Update the button section in the return statement
  const renderAuthButton = () => {
    if (!supportUser?.auth_user_id) {
      return (
        <>
          <Separator />
          <div className="flex items-center gap-2">
            <Button
              variant="destructive"
              size="sm"
              onClick={invitationSent ? resendAuthInvite : sendAuthInvite}
              disabled={sendingInvite}
              className="w-full bg-orange-500 hover:bg-orange-600 flex items-center justify-center gap-2"
            >
              {sendingInvite ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <Send className="h-4 w-4" />
              )}
              <span>
                {invitationSent ? 'Resend Authentication Email' : 'Send Authentication Email'}
              </span>
            </Button>
          </div>
          {lastInviteTime && (
            <div className="text-sm text-gray-500 mt-2">
              Last sent: {format(lastInviteTime, 'MMM d, yyyy h:mm a')}
            </div>
          )}
          {inviteError && (
            <Alert variant="destructive" className="mt-2">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{inviteError}</AlertDescription>
            </Alert>
          )}
        </>
      );
    }
    return null;
  };

  const getUserStatus = () => {
    if (supportUser?.auth_user_id) {
      if (courseAccess.length > 0) {
        const hasFullAccess = courseAccess.some(access => access.tierId === TIER_IDS.FULL_ACCESS);
        return {
          label: hasFullAccess ? 'Customer' : 'Free Trial',
          variant: hasFullAccess ? 'default' : 'secondary'
        };
      }
      return {
        label: 'Registered',
        variant: 'outline'
      };
    }
    return {
      label: 'Unregistered',
      variant: 'destructive'
    };
  };

  if (loading) {
    return (
      <div className="p-4 flex items-center justify-center">
        <Loader2 className="w-6 h-6 animate-spin" />
      </div>
    );
  }

  if (!supportUser) return null;

  const userStatus = getUserStatus();

  const getContentTypeDisplay = (type: ContentType): string => {
    const displayMap: Record<ContentType, string> = {
      'questions': 'Question',
      'lessons': 'Lesson',
      'simulations': 'Simulation',
      'flashcards': 'Flashcard'
    };
    return displayMap[type] || 'Content';
  };

  const renderContent = () => {
    if (!contentId || !contentType || !userId) return null;

    return (
      <Card className="mb-4">
        <div className="p-4">
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <HelpCircle className="w-4 h-4" />
              <span className="font-medium">
                {getContentTypeDisplay(contentType)} Reference
              </span>
            </div>
            <Button
              size="sm"
              onClick={() => onNavigateToContent?.(contentType, contentId)}
              className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white"
            >
              <span>See {getContentTypeDisplay(contentType)}</span>
              <ExternalLink className="w-4 h-4" />
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  if (loading) {
    return (
      <div className="p-4 flex items-center justify-center">
        <Loader2 className="w-6 h-6 animate-spin" />
      </div>
    );
  }

  if (!supportUser) return null;

  return (
    <div className="h-full overflow-y-auto">
      <div className="p-3 md:p-4 space-y-3 md:space-y-4">
        {error && (
          <Alert variant="destructive" className="text-sm">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        
        {/* User Info Card */}
        <Card className="p-3 md:p-4">
          <div className="space-y-2 md:space-y-3">
            {/* Name and Status */}
            <div className="flex items-start md:items-center justify-between gap-2">
              <div className="flex items-center gap-1.5 min-w-0">
                <UserCircle className="w-4 h-4 text-gray-500 flex-shrink-0" />
                <span className="font-medium text-sm truncate">
                  {supportUser?.full_name || supportUser?.email.split('@')[0]}
                </span>
              </div>
              <Badge variant={getUserStatus().variant as any} className="text-xs whitespace-nowrap flex-shrink-0">
                {getUserStatus().label}
              </Badge>
            </div>
  
            {/* Email */}
            <div className="flex items-start gap-1.5">
              <Mail className="w-4 h-4 text-gray-500 mt-0.5 flex-shrink-0" />
              <div className="text-sm min-w-0">
                <div className="truncate">{supportUser?.email}</div>
                {supportUser?.email_domain && (
                  <div className="text-gray-500 text-xs">({supportUser.email_domain})</div>
                )}
              </div>
            </div>
  
            {/* Last Sign In */}
            {authUser?.last_sign_in_at && (
              <div className="flex items-start gap-1.5">
                <Clock className="w-4 h-4 text-gray-500 mt-0.5 flex-shrink-0" />
                <div className="text-sm">
                  Last sign in: {format(new Date(authUser.last_sign_in_at), 'MMM d, yyyy h:mm a')}
                </div>
              </div>
            )}
  
            {/* Member Since */}
            <div className="flex items-start gap-1.5">
              <Activity className="w-4 h-4 text-gray-500 mt-0.5 flex-shrink-0" />
              <div className="text-sm">
                Member since {supportUser?.created_at ? 
                  format(new Date(supportUser.created_at), 'MMM d, yyyy') : 
                  'Unknown'
                }
              </div>
            </div>
  
            {/* Authentication Invite Button */}
            {renderAuthButton()}
          </div>
        </Card>
  
        {/* Content Section */}
        {contentId && contentType && (
          <div className="space-y-2">
            {contentLoading ? (
              <div className="flex items-center justify-center p-4">
                <Loader2 className="w-4 h-4 animate-spin" />
              </div>
            ) : (
              renderContent()
            )}
          </div>
        )}
  
        {/* Authenticated User Content */}
        {supportUser.auth_user_id && authUser && (
          <>
            {/* Course Access Status Card */}
            <Card className="p-3 md:p-4">
              <div className="space-y-2 md:space-y-3">
                <div className="flex items-center gap-1.5">
                  <BookOpen className="w-4 h-4 text-gray-500" />
                  <span className="font-medium text-sm">Course Access</span>
                </div>
                {courseAccess.length === 0 ? (
                  <div className="text-sm text-gray-500 pl-6">
                    No active course access
                  </div>
                ) : (
                  <div className="space-y-2 pl-6">
                    {courseAccess.map((access, index) => (
                      <div key={index} className="flex items-center justify-between gap-2 min-w-0">
                        <span className="text-sm font-medium truncate">
                          {products[access.productTitle] || 'Loading...'}
                        </span>
                        <Badge 
                          variant={access.tierId === TIER_IDS.FULL_ACCESS ? 'default' : 'secondary'}
                          className="text-xs whitespace-nowrap flex-shrink-0"
                        >
                          {access.accessType}
                        </Badge>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Card>
  
            {/* Course Access Controls Card */}
            <Card className="overflow-hidden">
              <div className="p-3 md:p-4">
                <CourseAccessControls
                  userId={authUser.id}
                  userEmail={authUser.email}
                  currentAccess={userCourseAccess}
                  onAccessChanged={async () => {
                    await loadAuthUserData(supportUser.auth_user_id!);
                    onRefreshData();
                  }}
                  defaultOpen={false}
                />
              </div>
            </Card>
  
            {/* Payment Controls Card */}
            <Card className="overflow-hidden">
              <div className="p-3 md:p-4">
                <PaymentControls
                  purchases={purchases}
                  onPurchaseUpdate={async () => {
                    await loadAuthUserData(supportUser.auth_user_id!);
                    onRefreshData();
                  }}
                  defaultOpen={false}
                />
              </div>
            </Card>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerSidebar;