import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { 
  api, 
  Category, 
  CategoryStats, 
  Question, 
  Note, 
  QuizHistory, 
  QuizQuestionData, 
  QuizSummary, 
  FilterCounts, 
  UserAnswer, 
  MCQQuestionData,
  MockExamMCQQuestionData, 
  isMockExamMCQ,
  ConfidenceLevelValue, 
  QuizHistoryFilter,
  StudyTaskTestletQuestionData,
  isStudyTaskTestlet,
  StudyTaskTestletType,
  StudyTaskTestlet,
  TIER_IDS,
  ExtendedCategoryStats
} from '../../services/api';
import { useStudyTaskDetails } from '../../hooks/useStudyTaskDetails';
import { Calculator as CalculatorIcon, Target, Table, HelpCircle, ChevronRight, CheckCircle, Wrench, Crown, X, ArrowLeft, LightbulbIcon, BookOpen, Lock, Shuffle, Sparkle, Scale, Pencil, Notebook, CircleDot, FileQuestion, ListCheck } from 'lucide-react';
import PopupModal from './common/PopupModal';
import { ModalContext } from '../../App'; // Import the ModalContext
import QuizResetCategoryPopup from './QuizResetCategoryPopup'; // Adjust the import path as necessary
import QuizCustomizer from './QuizCustomizer';
import QuizSummaryFilter from './QuizSummaryFilter';
import { parseQuestionContent } from '../../utils/questionUtils';
import { useAuth } from '../AuthProvider';
import { useQuizData } from '../../hooks/useQuizData';
import { useQuizHistory } from '../../hooks/useQuizHistory';
import { useCategoryStats } from '../../hooks/useCategoryStats';
import LoadingScreen from './common/LoadingScreen';
import EndTestlet from './quiz-tools/EndTestlet';
import Calculator from './quiz-tools/Calculator';
import Spreadsheet from './quiz-tools/Spreadsheet';
import FeedbackPopup from './quiz-tools/FeedbackPopup';
import TestModeToggle from './quiz-tools/TestModeToggle';
import { Button } from '../../components/ui/Button';
import useKeyPress from '../../hooks/useKeyPress';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import UpgradeModal from './common/UpgradeModal';
import { useTextSelection } from '../../contexts/TextSelectionContext';
import { useActiveQuiz, ActiveQuizState, getTestletType } from '../../hooks/useActiveQuiz';

// Helper function to type guard non-null QuizSummary items
function isValidQuizSummary(item: QuizSummary | null): item is QuizSummary {
  return item !== null;
}

interface QuizProps {
  courseId: string;
  userId: string;
  mockExamTestletId?: string;  // Mock exam props
  quizHistoryId?: string;      // Quiz history prop
  studyTaskTestletId?: string; // Study task props
  studyTaskId?: string;        // Added studyTaskId
  testletType?: string;        // Added testletType
}

interface QuizConfidenceLevel {
  question_id: string;
  current_confidence_level: string | null;
  confidence_history: Array<{ level: string | null; timestamp: string; }>;
}

interface QuizInitializationState {
  questions: (Question & { note: Note | null })[];
  confidenceLevels: QuizConfidenceLevel[];  // Use our local interface
  quizHistory: QuizHistory;
}

interface QuizInitParams {
  quizId?: string;
  selectedQuestions?: Question[];
  mockExamTestletId?: string;
  studyTaskTestletId?: string;
  studyTaskId?: string | null;  // Allow null since that's what we get from state
}

type ConfidenceLevel = 'guessing' | 'maybe' | 'confident';
type CurrentView = 'categories' | 'quiz' | 'summary';
type ExpandAllHandler = (expand: boolean) => void;
let currentView: CurrentView = 'categories';  // Default to a valid CurrentView value

type StatusFilter = 'unanswered' | 'correct' | 'incorrect';

// Function to get count for a specific status/confidence combination
function getStatusCount(stats: ExtendedCategoryStats, status: StatusFilter | ConfidenceLevel): number {
  switch (status) {
    case 'correct': return stats.correct;
    case 'incorrect': return stats.incorrect;
    case 'unanswered': return stats.unanswered_questions;
    case 'confident': return stats.confident;
    case 'maybe': return stats.maybe;
    case 'guessing': return stats.guessing;
    default: return 0;
  }
}

// Function to get combined status/confidence count
function getFilteredCount(
  stats: ExtendedCategoryStats, 
  confidence: ConfidenceLevel, 
  status: 'correct' | 'incorrect'
): number {
  switch(`${confidence}_${status}`) {
    case 'confident_correct': return stats.confident_correct;
    case 'confident_incorrect': return stats.confident_incorrect;
    case 'maybe_correct': return stats.maybe_correct;
    case 'maybe_incorrect': return stats.maybe_incorrect;
    case 'guessing_correct': return stats.guessing_correct;
    case 'guessing_incorrect': return stats.guessing_incorrect;
    default: return 0;
  }
}

const MAX_NOTE_LENGTH = 2000;

const Quiz = ({ 
  courseId, 
  userId, 
  mockExamTestletId: propMockExamTestletId, 
  quizHistoryId: propQuizHistoryId, 
  studyTaskTestletId: propStudyTaskTestletId,
  studyTaskId: propStudyTaskId,
  testletType: propTestletType 
}: QuizProps) => {
  const { user } = useAuth();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [isInitializingQuiz, setIsInitializingQuiz] = useState(false);
  const [keslerQFilter, setKeslerQFilter] = useState<Array<StatusFilter | ConfidenceLevel>>(['unanswered']);
  const [summaryFilter, setSummaryFilter] = useState('all');
  const [showCustomizer, setShowCustomizer] = useState(false);
  const [customQuestionCount, setCustomQuestionCount] = useState(10);
  const [showResetPopup, setShowResetPopup] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentView, setCurrentView] = useState<'categories' | 'quiz' | 'summary'>('categories');
  const [isNoteTextareaFocused, setIsNoteTextareaFocused] = useState(false);
  const [isSpreadsheetFocused, setIsSpreadsheetFocused] = useState(false);
  const { isSelectionPopupVisible } = useTextSelection();

  // CACHE
  const questionCache = new Map<string, {
    data: {
      questions: (Question & { note: Note | null })[];
      confidenceHistory: {[key: string]: { level: string | null; timestamp: string; }[]};
    };
    timestamp: number;
  }>();
  const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

  // Add this fetch helper
  const fetchQuizData = useCallback(async (questionIds: string[]) => {
    const cacheKey = `${currentUser?.id}_${courseId}_${questionIds.sort().join('_')}`;
    
    const cached = questionCache.get(cacheKey);
    if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
      return cached.data;
    }

    const [questions, confidenceLevels] = await Promise.all([
      api.getQuestionsById(questionIds, currentUser?.id || '', courseId),
      api.getConfidenceLevels(currentUser?.id || '', courseId, questionIds)
    ]);

    const confidenceHistory = confidenceLevels.reduce((acc, cl) => {
      if (cl.question_id && cl.confidence_history) {
        acc[cl.question_id] = cl.confidence_history.filter(h => h.level !== null);
      }
      return acc;
    }, {} as {[key: string]: { level: string | null; timestamp: string; }[]});

    const result = { questions, confidenceHistory };
    questionCache.set(cacheKey, { data: result, timestamp: Date.now() });
    return result;
  }, [currentUser?.id, courseId]);

  const [hasInitialized, setHasInitialized] = useState(false);
  const {resumeQuizId, viewQuizId, quizData, from, showSummary: initialShowSummary, quizCompleted: initialQuizCompleted } = location.state || {};
  const [showSummary, setShowSummary] = useState(initialShowSummary || false);
  const [quizCompleted, setQuizCompleted] = useState(initialQuizCompleted || false);
  const [viewingCompletedQuiz, setViewingCompletedQuiz] = useState(false);
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);
  const activeQuizRef = useRef<any>(null);
  const [closingSummary, setClosingSummary] = useState(false);
  const [isResumingQuiz, setIsResumingQuiz] = useState(false);
  const [isEndingQuiz, setIsEndingQuiz] = useState(false);
  const [isLoadingNextQuestion, setIsLoadingNextQuestion] = useState(false);
  const [isLoadingQuizSummary, setIsLoadingQuizSummary] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditingConfidence, setIsEditingConfidence] = useState(false);
  const [confidenceHistory, setConfidenceHistory] = useState<{
    [questionId: string]: { level: string | null; timestamp: string; }[];
  }>({});
  const [focusedConfidenceLevel, setFocusedConfidenceLevel] = useState<number>(-1);

  // FREE TRIAL
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showEndQuizUpgrade, setShowEndQuizUpgrade] = useState(false);
  const { userCourseAccesses } = useUserCourseAccess();

  //QUIZ TOOLS
  const [showCalculator, setShowCalculator] = useState(false);
  const [showSpreadsheet, setShowSpreadsheet] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isTestMode, setIsTestMode] = useState(false);
  const [showEndTestletModal, setShowEndTestletModal] = useState(false);
  const [isLastQuestion, setIsLastQuestion] = useState(false);

 // Hooks
 const {
  categoriesData,
  categoriesLoading,
  categoriesError,
  useAvailableQuestionCounts,
  resetCategories,
  activeQuizState,
  setActiveQuizState,
  resetActiveQuizCheck
} = useQuizData(courseId, currentUser?.id);

  // Keep the existing categoryStats state
  const [categoryStats, setCategoryStats] = useState<{ [key: string]: CategoryStats }>({});


  // Use the new custom hook
  const {
    categoryStats: cachedCategoryStats,
    invalidateCategoryStats
  } = useCategoryStats(courseId, user?.id || '', selectedCategories);

  const { data: availableQuestionCounts } = useAvailableQuestionCounts(
    selectedCategories,
    keslerQFilter,
    !!currentUser
  );

  const { data: quizHistory, refetchQuizHistory } = useQuizHistory(
    currentUser?.id, 
    courseId, 
    summaryFilter as QuizHistoryFilter, 
    true, 
    false
  );
  
  const [filterCounts, setFilterCounts] = useState({
    all: 0,
    correct: 0,
    incorrect: 0,
    confident: 0,
    maybe: 0,
    guessing: 0
  });

  const [isLoading, setIsLoading] = useState(false);

  //Screen Scrolling
  const scrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  //LOCAL CATEGORY STATS
  const [localCategoryStats, setLocalCategoryStats] = useState<{[key: string]: CategoryStats}>({});
  const queryClient = useQueryClient();
  const [isFinishingQuiz, setIsFinishingQuiz] = useState(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  
  // Update other parts of the file that use these confidence levels
  const confidenceLevels: ConfidenceLevel[] = ['guessing', 'maybe', 'confident'];
  const [categoriesStructure, setCategoriesStructure] = useState<{[key: string]: Category[]}>({});
  const [questions, setQuestions] = useState<(Question & { note: Note | null })[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState<UserAnswer>({});
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [isResettingCategories, setIsResettingCategories] = useState(false);
  
  const [showExplanation, setShowExplanation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const MemoizedQuizSummaryFilter = React.memo(QuizSummaryFilter);
  const [note, setNote] = useState('');
  const [showNoteEditor, setShowNoteEditor] = useState(false);
  const [currentNote, setCurrentNote] = useState<Note | null>(null);
  const [quizStarted, setQuizStarted] = useState(false);
  const [noteContent, setNoteContent] = useState<string>('');
  const [isEditingNote, setIsEditingNote] = useState<boolean>(false);
  const [quizSummary, setQuizSummary] = useState<QuizSummary[]>([]);
  const [lessonId, setLessonId] = useState<string | null>(null);
  const [simulationId, setSimulationId] = useState<string | null>(null);
  const [confidenceLevel, setConfidenceLevel] = useState<ConfidenceLevel | null>(null);
  const [editingNoteIndex, setEditingNoteIndex] = useState<number | null>(null);
  // Add these new state variables at the top of your component
  const [editingNotes, setEditingNotes] = useState<{ [key: number]: boolean }>({});
  const [noteContents, setNoteContents] = useState<{ [key: number]: string }>({});
  const [notes, setNotes] = useState<{ [questionId: string]: Note }>({});
  const [currentQuizHistoryId, setCurrentQuizHistoryId] = useState<string | null>(null);
  const [currentQuizSummary, setCurrentQuizSummary] = useState<QuizSummary[]>([]);
  const [localQuizHistory, setLocalQuizHistory] = useState<QuizHistory[]>([]);
  const [isFilteringLocally, setIsFilteringLocally] = useState(false);
  const [totalTime, setTotalTime] = useState(0);
  const [averageTime, setAverageTime] = useState(0);
  const [incompleteQuiz, setIncompleteQuiz] = useState<any | null>(null);
  
  const [showMobileTools, setShowMobileTools] = useState(false);
  // Pagination helper functions
  const [currentPage, setCurrentPage] = useState(1);
  //quiz history - 20 per page
  const quizzesPerPage = 20;
  const MAX_ALLOWED_QUESTIONS = 72;
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  //quiz summary pages - 10 per page
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = quizSummary.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(quizSummary.length / itemsPerPage);
  const [selectAll, setSelectAll] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [isFinishing, setIsFinishing] = useState(false);
  // QUIZ TIMER
  const [quizStartTime, setQuizStartTime] = useState<number | null>(null);
  const [questionStartTime, setQuestionStartTime] = useState<number | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [pausedTime, setPausedTime] = useState<number>(0);
  const [explanationStartTime, setExplanationStartTime] = useState<number | null>(null);
  const quizStartTimeRef = useRef<number | null>(null);
  const pausedTimeRef = useRef<number>(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // Extract mock exam testlet / study task testlet from URL
  const [isInitializingMockExam, setIsInitializingMockExam] = useState(false);
  const providedQuizHistoryId = searchParams.get('quiz_history_id') || null;  
  const [mockExamId, setMockExamId] = useState<string | undefined>(undefined);
  const [isMockExam] = useState(() => Boolean(propMockExamTestletId));
  const [mockExamTestletId] = useState(propMockExamTestletId);
  // Extract mock exam params from URL if not provided as props
  const effectiveTestletId = propMockExamTestletId || searchParams.get('mock_exam_testlet_id') || undefined;
  const effectiveQuizHistoryId = propQuizHistoryId || searchParams.get('quiz_history_id') || undefined;
  
  // Update the state definition to handle optional mock exam fields
  const [mockExamState, setMockExamState] = useState<{
    mockExamId: string | undefined;
    mockExamTestletId: string | undefined;
    isMockExam: boolean;
  }>(() => ({
    mockExamId: undefined,
    mockExamTestletId: propMockExamTestletId,
    isMockExam: Boolean(propMockExamTestletId)
  }));
  const [studyTaskTestletId] = useState(propStudyTaskTestletId);
  const [isStudyTask, setIsStudyTask] = useState(false);
  const [studyTaskId, setStudyTaskId] = useState<string | null>(null);
  const [testletData, setTestletData] = useState<QuizQuestionData | null>(null);

  // Keep these for initialization and data fetching
  const effectiveStudyTaskId = propStudyTaskId || searchParams.get('study_task_id');
  const effectiveTestletType = propTestletType || searchParams.get('type') as StudyTaskTestletType;
  const { taskDetails, isLoading: isLoadingTaskDetails } = useStudyTaskDetails(
    userId,
    courseId,
    effectiveStudyTaskId || ''
  );

  // Keep helper functions
  function validateTestletType(type: string | null): StudyTaskTestletType | null {
    if (!type) return null;
    
    const validTypes: StudyTaskTestletType[] = ['learn', 'loop', 'funnel', 'retake_funnel'];
    return validTypes.includes(type as StudyTaskTestletType) 
      ? (type as StudyTaskTestletType) 
      : 'learn';
  }

  const convertToStudyTaskQuestionData = (testlet: StudyTaskTestlet): StudyTaskTestletQuestionData => {
    const validatedType = validateTestletType(testlet.type);
    if (!validatedType) {
      throw new Error('Invalid testlet type');
    }

    return {
      type: 'sp_studytasktestlet',
      testletId: testlet.id,
      studyTaskId: testlet.study_task_id,
      questionId: testlet.id,
      answered: false,
      isCorrect: false,
      timeSpent: 0,
      userAnswer: '',
      confidenceLevel: null,
      score: 0,
      feedback: [],
      question_results: [],
      free_trial_content: false
    };
  };

  // Keep studyTaskState as the source of derived state
  const [studyTaskState, setStudyTaskState] = useState<{
    studyTaskId: string | null;
    studyTaskTestletId: string | undefined;
    isStudyTask: boolean;
    testletType: StudyTaskTestletType | null;
  }>(() => {
    const searchParams = new URLSearchParams(location.search);
    
    const studyTaskId = location.state?.studyTaskId || 
                       searchParams.get('study_task_id') || 
                       taskDetails?.study_task_id || 
                       null;
    
    const testletType = location.state?.testletType || 
                       searchParams.get('type') as StudyTaskTestletType || 
                       'learn';
    
    return {
      studyTaskId,
      studyTaskTestletId: propStudyTaskTestletId,
      isStudyTask: Boolean(propStudyTaskTestletId),
      testletType: validateTestletType(testletType)
    };
  });

  // Add synchronization effect between studyTaskState and individual states
  useEffect(() => {
    setIsStudyTask(studyTaskState.isStudyTask);
    setStudyTaskId(studyTaskState.studyTaskId);
    // Don't need setTestletData here as it's handled separately
  }, [studyTaskState]);

  // Calculations
  const totals = useMemo(() => {
    return Object.values(categoriesData?.structuredCategories || {}).reduce((acc, subCategories) => {
      subCategories.forEach(category => {
        if (category.sub_question_category_name !== 'All') {
          acc.totalQuestions += category.total_questions || 0;
          acc.unansweredQuestions += category.unanswered_questions || 0;
          acc.correctAnswers += (category.total_questions - category.unanswered_questions) * (category.correct_percentage / 100);
        }
      });
      return acc;
    }, { totalQuestions: 0, unansweredQuestions: 0, correctAnswers: 0 });
  }, [categoriesData]);

  // Add this function to handle confidence level updates
  const handleConfidenceLevelUpdate = useCallback(async (newLevel: ConfidenceLevel) => {
    if (!currentQuizHistoryId || !questions[currentQuestion] || !currentUser) return;
  
    const currentQuestionId = questions[currentQuestion].id;
  
    try {
      // Update quiz history only - this is the source of truth for the quiz session
      await api.updateQuizHistory(
        currentQuizHistoryId,
        currentQuestionId,
        userAnswers[currentQuestionId],
        userAnswers[currentQuestionId] === questions[currentQuestion].correct_answer,
        newLevel,
        quizSummary[quizSummary.length - 1].timeSpent || 0
      );
  
      // Update local quiz summary state
      setQuizSummary(prev => prev.map(item => {
        if (item.questionId === currentQuestionId) {
          return {
            ...item,
            confidenceLevel: newLevel
          };
        }
        return item;
      }));
  
      // Update local confidence history for the session
      setConfidenceHistory(prev => ({
        ...prev,
        [currentQuestionId]: [
          { level: newLevel, timestamp: new Date().toISOString() },
          ...(prev[currentQuestionId] || [])
        ]
      }));
  
      setIsEditingConfidence(false);
  
    } catch (error) {
      console.error('Failed to update confidence level:', error);
      setError('Failed to update confidence level. Please try again.');
    }
  }, [
    currentQuizHistoryId, 
    questions, 
    currentQuestion, 
    currentUser, 
    userAnswers, 
    quizSummary,
    api, 
    setError
  ]);

  const calculateConfidenceCounts = useCallback((questionsData: QuizHistory['questions_data']) => {
    return questionsData.reduce((counts, q) => {
      if (q.answered && q.confidenceLevel) {
        counts[q.confidenceLevel as keyof typeof counts] = (counts[q.confidenceLevel as keyof typeof counts] || 0) + 1;
      }
      return counts;
    }, { confident: 0, maybe: 0, guessing: 0 });
  }, []);

  // Updated handleScroll function
  const handleScroll = useCallback(() => {
    if (tableRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tableRef.current;
      const isAtEnd = scrollLeft >= scrollWidth - clientWidth - 10; // Changed from 1 to 10
      setShowScrollIndicator(!isAtEnd);
    }
  }, []);

  const getFilterCountsFromQuizHistory = useCallback((quizHistory: QuizHistory): FilterCounts => {
    const confidenceCounts = calculateConfidenceCounts(quizHistory.questions_data);
    return {
      all: quizHistory.total_questions,
      correct: quizHistory.correct_answers,
      incorrect: quizHistory.incorrect_answers,
      confident: confidenceCounts.confident,
      maybe: confidenceCounts.maybe,
      guessing: confidenceCounts.guessing
    };
  }, [calculateConfidenceCounts]);

  const getFilterCountsFromQuizSummary = useCallback((summary: QuizSummary[]): FilterCounts => {
    console.log('Getting filter counts from quiz summary:', summary);
    const counts: FilterCounts = {
      all: 0,
      correct: 0,
      incorrect: 0,
      confident: 0,
      maybe: 0,
      guessing: 0
    };
  
    summary.forEach(q => {
      counts.all++;
      counts[q.isCorrect ? 'correct' : 'incorrect']++;
      if (q.confidenceLevel && q.confidenceLevel !== 'Not recorded') {
        const confidenceKey = q.confidenceLevel.toLowerCase() as keyof FilterCounts;
        if (confidenceKey in counts) {
          counts[confidenceKey]++;
        } else {
          console.warn(`Unknown confidence level: ${q.confidenceLevel} for question ${q.questionId}`);
        }
      } else {
        console.warn(`Question ${q.questionId} has no confidence level recorded. Full question data:`, q);
      }
    });
    
    console.log('Calculated filter counts:', counts);
    return counts;
  }, []);


  const filterCountsRef = useRef<FilterCounts>({
    all: 0,
    correct: 0,
    incorrect: 0,
    confident: 0,
    maybe: 0,
    guessing: 0
  });

  
    // This function updates the filter counts based on quiz history
    const updateFilterCounts = useCallback((data: QuizHistory | QuizHistory[] | QuizSummary[]) => {
      if (!data) {
        console.warn('Attempted to update filter counts with empty or undefined data');
        return;
      }

      let newFilterCounts: FilterCounts;

      if (Array.isArray(data)) {
        if (data.length === 0) {
          console.warn('Attempted to update filter counts with empty array');
          return;
        }
        if ('parentCategory' in data[0]) {
          console.log('Updating filter counts from QuizSummary[]');
          newFilterCounts = getFilterCountsFromQuizSummary(data as QuizSummary[]);
        } else {
          console.log('Updating filter counts from QuizHistory[]');
          newFilterCounts = getFilterCountsFromQuizHistory(data[0]);
        }
      } else {
        console.log('Updating filter counts from single QuizHistory');
        newFilterCounts = getFilterCountsFromQuizHistory(data);
      }

      if (JSON.stringify(newFilterCounts) !== JSON.stringify(filterCountsRef.current)) {
        filterCountsRef.current = newFilterCounts;
        setFilterCounts(newFilterCounts);
        console.log('Filter counts updated:', newFilterCounts);
      } else {
        console.log('Filter counts unchanged, skipping update');
      }
    }, [getFilterCountsFromQuizHistory, getFilterCountsFromQuizSummary]);

    const fetchQuizSummary = useCallback(async (quizId: string) => {
      setIsLoadingQuizSummary(true);
      setError(null);
      try {
        const quizHistoryData = await api.getQuizHistoryById(quizId);
        if (!quizHistoryData) throw new Error('Quiz history not found');
    
        const userId = currentUser?.id || '';
        const quizQuestions = await api.getQuestionsById(
          quizHistoryData.questions_data.map((q: QuizQuestionData) => q.questionId),
          userId,
          courseId
        );
    
        const summary = await Promise.all(quizHistoryData.questions_data.map(async (questionData: QuizQuestionData) => {
          const question = quizQuestions.find(q => q.id === questionData.questionId);
          
          if (!question) {
            console.warn(`Question with id ${questionData.questionId} not found`);
            return null;
          }
        
          const categoryData = question.question_category_id 
            ? await api.getQuestionCategory(question.question_category_id)
            : null;
          
          const { questionText, options, romanList, tableHTML, isTableQuestion } = parseQuestionContent(question.question_content || '');
        
          const summaryItem: QuizSummary = {
            parentCategory: categoryData?.parent_question_category_name || '',
            subCategory: categoryData?.sub_question_category_name || '',
            questionId: questionData.questionId,
            question: questionText,
            options: options,
            userAnswer: questionData.userAnswer,
            correctAnswer: question.correct_answer || '',
            answerContent: question.answer_content || '',
            isCorrect: questionData.isCorrect,
            note: question.note,
            confidenceLevel: questionData.confidenceLevel || 'Not recorded',
            timeSpent: questionData.timeSpent || 0,
            romanList: romanList,
            tableHTML: tableHTML,
            isTableQuestion: isTableQuestion,
            free_trial_content: question.free_trial_content || false
          };
        
          return summaryItem;
        }));
        
        // Filter out null values and assert the type
        const validSummary = summary.filter(isValidQuizSummary);
    
        setQuizSummary(validSummary);
        console.log('Updating filter counts from fetchQuizSummary');
        updateFilterCounts(validSummary);
        setViewingCompletedQuiz(true);
      } catch (err) {
        console.error('Failed to load quiz results:', err);
        setError('Failed to load quiz results. Please try again.');
      } finally {
        setIsLoadingQuizSummary(false);
      }
    }, [courseId, currentUser, updateFilterCounts, api]);


    const updateElapsedTime = useCallback(() => {
      if (quizStartTimeRef.current && !isPaused && !showExplanation) {
        const now = Date.now();
        const newElapsedTime = Math.floor((now - quizStartTimeRef.current - pausedTimeRef.current) / 1000);
        setElapsedTime(newElapsedTime);
      }
    }, [isPaused, showExplanation]);
  
    useEffect(() => {
      if (quizStartTime && !isPaused && !showExplanation) {
        quizStartTimeRef.current = quizStartTime;
        pausedTimeRef.current = pausedTime;
        timerRef.current = setInterval(updateElapsedTime, 1000);
      } else if (timerRef.current) {
        clearInterval(timerRef.current);
      }
  
      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }, [quizStartTime, isPaused, showExplanation, pausedTime, updateElapsedTime]);

    //END TIMER

    //FREE TRIAL BUTTON
    const showUpgradeButton = useMemo(() => {
      if (!userCourseAccesses || userCourseAccesses.length === 0) {
        return true; // Show upgrade button if no access at all
      }
      
      const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
      if (courseAccesses.length === 0) {
        console.warn(`No access found for course ID: ${courseId}`);
        return true; // Show upgrade button if no access for this course
      }

      // Check if user has either full access or study materials only access
      const hasFullAccess = courseAccesses.some(access => 
        access.tier_id === TIER_IDS.FULL_ACCESS || 
        access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY
      );

      if (hasFullAccess) {
        return false; // Don't show upgrade button if user has full access
      }

      // Show upgrade button if user only has restricted access (free trial or mentor only)
      const onlyHasRestrictedAccess = courseAccesses.every(access =>
        access.tier_id === TIER_IDS.FREE_TRIAL || 
        access.tier_id === TIER_IDS.MENTOR_ONLY
      );

      return onlyHasRestrictedAccess;
    }, [userCourseAccesses, courseId]);

    // Update the handleUpgradeClick to include proper typing
    const handleUpgradeClick = useCallback((): void => {
      setShowUpgradeModal(true);
    }, []);

    // Add a new helper function to check if content should be locked
    const isContentLocked = useCallback((questionData: Question): boolean => {
      if (!userCourseAccesses || userCourseAccesses.length === 0) {
        return true;
      }

      const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
      if (courseAccesses.length === 0) {
        return true;
      }

      // If user has full access or study materials access, content is never locked
      const hasFullAccess = courseAccesses.some(access => 
        access.tier_id === TIER_IDS.FULL_ACCESS || 
        access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY
      );

      if (hasFullAccess) {
        return false;
      }

      // For users with restricted access, check if the content is marked as free trial
      return !questionData.free_trial_content;
    }, [userCourseAccesses, courseId]);

    //END FREE TRIAL BUTTONS


    // MOCK EXAM TESTLET FILTER

    // Then update the quiz state management effects for test mode
    useEffect(() => {
      // Only force test mode ON for mock exam testlets
      if (mockExamTestletId) {
        setIsTestMode(true);
      }
      // Study task testlets don't force test mode
      if (studyTaskTestletId) {
        setIsTestMode(false);
      }
    }, [mockExamTestletId, studyTaskTestletId]);

    // Update the quiz reset effect
    useEffect(() => {
      if (quizStarted) {
        // Reset necessary states
        setShowExplanation(false);
        setConfidenceLevel(null);
        setSelectedAnswer(null);
        setShowCalculator(false);
        setShowSpreadsheet(false);
        setShowFeedback(false);
        setShowNoteEditor(false);
        setNote('');
        setCurrentNote(null);
        setNoteContent('');
        setIsEditingNote(false);
        
        // Only force test mode for mock exams
        if (mockExamTestletId) {
          setIsTestMode(true);
        }
        // Ensure study task testlets aren't in test mode
        if (studyTaskTestletId) {
          setIsTestMode(false);
        }
      }
    }, [quizStarted, mockExamTestletId, studyTaskTestletId]);

    // Update resumeState handling to check for mock exam testlet or study task testlet
    useEffect(() => {
      // Update the handleResumeState function to properly handle test mode
      const handleResumeState = async () => {
        if (!resumeQuizId || !quizData || !currentUser) {
          console.log('Missing required data:', { resumeQuizId, quizData: !!quizData, currentUser: !!currentUser });
          return;
        }

        try {
          setIsLoading(true);
          console.log('Resume quiz data:', quizData);
          
          const firstQuestion = quizData.questions_data[0];
          if (!firstQuestion) {
            console.error('No questions data found');
            return;
          }

          // Determine quiz type and find unanswered question
          let unansweredQuestion: QuizQuestionData | undefined;
          
          if (isStudyTaskTestlet(firstQuestion)) {
            console.log('Handling study task testlet quiz');
            unansweredQuestion = quizData.questions_data.find(
              (q: QuizQuestionData) => isStudyTaskTestlet(q) && !q.answered
            );
            // Ensure test mode is OFF for study tasks
            setIsTestMode(false);
            setCurrentView('quiz');
          } else if (isMockExamMCQ(firstQuestion)) {
            console.log('Handling mock exam MCQ quiz');
            unansweredQuestion = quizData.questions_data.find(
              (q: QuizQuestionData) => isMockExamMCQ(q) && !q.answered
            );
            // Force test mode ON for mock exams
            setIsTestMode(true);
            setCurrentView('quiz');
          } else {
            console.log('Handling regular MCQ quiz');
            unansweredQuestion = quizData.questions_data.find(
              (q: QuizQuestionData): q is MCQQuestionData => q.type === 'mcq' && !q.answered
            );
          }

          if (!unansweredQuestion) {
            console.error('No unanswered questions found, quiz type:', firstQuestion.type);
            return;
          }

          // Get full question data
          const questionIds = quizData.questions_data.map((q: QuizQuestionData) => q.questionId);
          console.log('Fetching questions:', questionIds);
          const questions = await api.getQuestionsById(questionIds, currentUser.id, courseId);
          console.log('Fetched questions:', questions.length);
          setQuestions(questions);

          // Set current question index
          const currentIndex = quizData.questions_data.findIndex(
            (q: QuizQuestionData) => q.questionId === unansweredQuestion?.questionId
          );
          console.log('Setting current question index:', currentIndex);
          
          setCurrentQuestion(currentIndex);
          setQuizStarted(true);
          setCurrentQuizHistoryId(resumeQuizId);
          
          // Set timer state
          const now = Date.now();
          const startTime = new Date(quizData.start_time).getTime();
          const pausedDuration = now - startTime - (quizData.total_time * 1000);
          
          setQuizStartTime(startTime);
          quizStartTimeRef.current = startTime;
          setQuestionStartTime(now);
          setElapsedTime(quizData.total_time);
          setPausedTime(pausedDuration);
          pausedTimeRef.current = pausedDuration;
          setIsPaused(false);
          
        } catch (error) {
          console.error('Error handling resume state:', error);
          setError('Failed to resume quiz. Please try again.');
        } finally {
          setIsLoading(false);
        }
      };

      handleResumeState();
    }, [resumeQuizId, quizData, currentUser, courseId]);
    
    
    useEffect(() => {
      if (studyTaskTestletId && !studyTaskState.studyTaskId) {
        const initializeStudyTaskState = async () => {
          try {
            setIsLoading(true);
            
            // Get quiz history if it exists
            const queryParams = new URLSearchParams(location.search);
            const providedQuizHistoryId = queryParams.get('quiz_history_id');
    
            if (providedQuizHistoryId) {
              const quizHistoryData = await api.getQuizHistoryById(providedQuizHistoryId);
              if (!quizHistoryData) {
                throw new Error('Quiz history not found');
              }
    
              // Extract study task data from first question
              const firstQuestion = quizHistoryData.questions_data[0];
              if (isStudyTaskTestlet(firstQuestion)) {
                const studyTaskData = {
                  studyTaskId: firstQuestion.studyTaskId,
                  testletId: firstQuestion.testletId,
                  type: firstQuestion.type || 'learn'
                };
    
                setStudyTaskState(prev => ({
                  ...prev,
                  studyTaskId: studyTaskData.studyTaskId,
                  studyTaskTestletId: studyTaskData.testletId,
                  isStudyTask: true,
                  testletType: studyTaskData.type as StudyTaskTestletType
                }));
    
                setIsStudyTask(true);
                setStudyTaskId(studyTaskData.studyTaskId);
    
                // Initialize and load questions
                const questionIds = quizHistoryData.questions_data.map(q => q.questionId);
                const quizQuestions = await api.getQuestionsById(questionIds, userId, courseId);
                
                setQuestions(quizQuestions);
                const answeredQuestionsCount = quizHistoryData.questions_data.filter((q) => q.answered).length;
                setCurrentQuestion(answeredQuestionsCount);
                
                // Set quiz state
                setCurrentQuizHistoryId(providedQuizHistoryId);
                setQuizCompleted(quizHistoryData.completed);
                setQuizStarted(true);
                setCurrentView('quiz');
    
                console.log('Study task state initialized from quiz history:', studyTaskData);
    
              } else {
                throw new Error('Invalid question type in quiz history');
              }
    
            } else {
              // No quiz history - check for active quiz
              const studyTaskId = effectiveStudyTaskId;
              const testletType = effectiveTestletType as StudyTaskTestletType || 'learn';
    
              if (!studyTaskId) {
                throw new Error('No study task ID provided');
              }
    
              const result = await api.checkActiveStudyTaskQuiz(
                studyTaskId,
                testletType
              );
    
              const testlet = result.testlet;
                  
              if (!result.hasActive || !testlet) {
                // Handle no active quiz case
                setStudyTaskState(prev => ({
                  ...prev,
                  studyTaskId: studyTaskId,
                  studyTaskTestletId: studyTaskTestletId,
                  isStudyTask: true,
                  testletType
                }));
    
                setIsStudyTask(true);
                setStudyTaskId(studyTaskId);
    
              } else {
                // Handle existing quiz case
                const testletQuestionData = convertToStudyTaskQuestionData(testlet);
                const validTestletType = validateTestletType(testlet.type);
                    
                setStudyTaskState(prev => ({
                  ...prev,
                  studyTaskId: studyTaskId,
                  studyTaskTestletId: testlet.id,
                  isStudyTask: true,
                  testletType: validTestletType
                }));
    
                setIsStudyTask(true);
                setStudyTaskId(studyTaskId);
                setTestletData(testletQuestionData);
              }
    
              console.log('Study task state initialized from active check:', {
                studyTaskId,
                testletId: studyTaskTestletId,
                hasActiveQuiz: result.hasActive,
                testlet: result.testlet ? result.testlet.id : undefined,
                testletType
              });
            }
    
          } catch (error) {
            console.error('Failed to initialize study task state:', error);
            // Ensure fallback state uses valid StudyTaskTestletType
            const fallbackType = validateTestletType(effectiveTestletType || 'learn');
            setStudyTaskState(prev => ({
              ...prev,
              studyTaskId: effectiveStudyTaskId,
              studyTaskTestletId: studyTaskTestletId,
              isStudyTask: true,
              testletType: fallbackType
            }));
            setIsStudyTask(true);
          } finally {
            setIsLoading(false);
          }
        };
    
        initializeStudyTaskState();
      }
    }, [studyTaskTestletId, studyTaskState.studyTaskId, effectiveStudyTaskId, effectiveTestletType, location.search, userId, courseId]);

    // Update useEffect for handling study task testlets
    useEffect(() => {
      const initializeStudyTaskTestlet = async () => {
        if (!studyTaskState.studyTaskTestletId || !userId || !courseId || !user) {
          return;
        }

        try {
          setIsLoading(true);

          // Get quiz history if it exists
          const queryParams = new URLSearchParams(location.search);
          const providedQuizHistoryId = queryParams.get('quiz_history_id');

          if (providedQuizHistoryId) {
            const quizHistoryData = await api.getQuizHistoryById(providedQuizHistoryId);
            if (!quizHistoryData) {
              throw new Error('Quiz history not found');
            }

            // Verify this is a study task testlet using the new state
            const isStudyTaskType = quizHistoryData.study_task_testlet_id === studyTaskState.studyTaskTestletId;
            
            if (!isStudyTaskType) {
              throw new Error('Quiz history does not match study task testlet');
            }

            setIsStudyTask(isStudyTaskType);
            console.log('Quiz type verification:', { 
              isStudyTask: isStudyTaskType, 
              testletId: quizHistoryData.study_task_testlet_id 
            });
    
            // Load full questions
            const questionIds = quizHistoryData.questions_data.map(q => q.questionId);
            const quizQuestions = await api.getQuestionsById(questionIds, user.id, courseId);
            
            setQuestions(quizQuestions);
            const answeredQuestionsCount = quizHistoryData.questions_data.filter((q) => q.answered).length;
            setCurrentQuestion(answeredQuestionsCount);
            
            // Set last question state properly
            const isLastQuestionNow = answeredQuestionsCount === quizQuestions.length - 1;
            setIsLastQuestion(isLastQuestionNow);
            console.log('Question state:', { 
              total: quizQuestions.length,
              current: answeredQuestionsCount,
              isLast: isLastQuestionNow
            });
    
            // Set user answers
            const userAnswersObj = quizHistoryData.questions_data.reduce((acc: any, q: QuizQuestionData) => {
              if (q.answered) {
                acc[q.questionId] = q.userAnswer;
              }
              return acc;
            }, {});
            setUserAnswers(userAnswersObj);
    
            // Initialize quiz state
            setCurrentQuizHistoryId(providedQuizHistoryId);
            setQuizCompleted(quizHistoryData.completed);
            setQuizStarted(true);
            setCurrentView('quiz');
            setShowSummary(false);
    
            // Setup timer
            const originalStartTime = new Date(quizHistoryData.start_time).getTime();
            const now = Date.now();
            const answeredTime = quizHistoryData.total_time || 0;
            const pausedDuration = now - originalStartTime - (answeredTime * 1000);
            
            setQuizStartTime(originalStartTime);
            quizStartTimeRef.current = originalStartTime;
            setQuestionStartTime(now);
            setElapsedTime(answeredTime);
            setPausedTime(pausedDuration);
            pausedTimeRef.current = pausedDuration;
            setIsPaused(false);
    
            console.log('Study task testlet quiz initialized:', {
              questions: quizQuestions.length,
              currentQuestion: answeredQuestionsCount,
              isLastQuestion: isLastQuestionNow,
              completed: quizHistoryData.completed,
              isStudyTask: isStudyTaskType
            });
          }
        } catch (err) {
          console.error('Failed to initialize study task testlet quiz:', err);
          setError('Failed to load study task testlet. Please try again.');
        } finally {
          setIsLoading(false);
        }
      };
    
      if (studyTaskTestletId) {
        initializeStudyTaskTestlet();
      }
    }, [studyTaskTestletId, userId, courseId, user, location.search]);

    // Add mock exam initialization effect
    useEffect(() => {
      const initializeMockExamTestlet = async () => {
        if (!effectiveTestletId || !userId || !courseId || !user || !effectiveQuizHistoryId) {
          setError('Missing required data for mock exam testlet.');
          setIsLoading(false);
          return;
        }

        try {
          setIsLoading(true);

          // Get mock exam testlet to find mock exam ID
          const testlet = await api.getMockExamTestletById(effectiveTestletId);
          setMockExamId(testlet.mock_exam_id);

          // Load quiz history to resume
          const quizHistory = await api.getQuizHistoryById(effectiveQuizHistoryId);
          
          if (!quizHistory) {
            throw new Error('Quiz history not found');
          }

          // Setup quiz state from history
          const answeredQuestionsCount = quizHistory.questions_data.filter((q) => q.answered).length;
          setCurrentQuestion(answeredQuestionsCount);
          
          // Load full questions
          const questionIds = quizHistory.questions_data.map(q => q.questionId);
          const questions = await api.getQuestionsById(questionIds, userId, courseId);
          setQuestions(questions);

          // Setup user answers from history
          const userAnswersObj = quizHistory.questions_data.reduce((acc: any, q) => {
            if (q.answered) {
              acc[q.questionId] = q.userAnswer;
            }
            return acc;
          }, {});
          setUserAnswers(userAnswersObj);

          // Initialize other quiz state
          setCurrentQuizHistoryId(effectiveQuizHistoryId);
          setQuizCompleted(quizHistory.completed);
          setQuizStarted(true);
          setShowSummary(false);

        } catch (err) {
          console.error('Failed to initialize mock exam testlet:', err);
          setError('Failed to load mock exam MCQ testlet. Please try again.');
        } finally {
          setIsLoading(false);
        }
      };

      if (effectiveTestletId && effectiveQuizHistoryId) {
        initializeMockExamTestlet();
      }
    }, [effectiveTestletId, userId, courseId, user, effectiveQuizHistoryId]);

    // If user provided mock_exam_testlet_id and quiz_history_id in the URL,
    // it means quiz_history is already created by MockExamDetail. Just load that quiz.
    // This avoids creating quiz_history twice.
    useEffect(() => {
      if (providedQuizHistoryId && mockExamTestletId && user) {
        (async () => {
          try {
            setIsLoading(true);
            const quizHistoryData = await api.getQuizHistoryById(providedQuizHistoryId);
            if (!quizHistoryData) {
              throw new Error('Quiz history not found');
            }

            // Load full questions
            const questionIds = quizHistoryData.questions_data.map(q => q.questionId);
            const quizQuestions = await api.getQuestionsById(questionIds, user.id, courseId);
            
            setQuestions(quizQuestions);
            const answeredQuestionsCount = quizHistoryData.questions_data.filter((q) => q.answered).length;
            setCurrentQuestion(answeredQuestionsCount);

            const userAnswersObj = quizHistoryData.questions_data.reduce((acc: any, q: QuizQuestionData) => {
              if (q.answered) {
                acc[q.questionId] = q.userAnswer;
              }
              return acc;
            }, {});
            setUserAnswers(userAnswersObj);

            setCurrentQuizHistoryId(providedQuizHistoryId);
            setQuizCompleted(quizHistoryData.completed);
            setQuizStarted(true);
            setCurrentView('quiz');
            setShowSummary(false);

            // Setup timer based on quizHistory
            const originalStartTime = new Date(quizHistoryData.start_time).getTime();
            const now = Date.now();
            const answeredTime = quizHistoryData.total_time || 0;
            const pausedDuration = now - originalStartTime - (answeredTime * 1000);
            setQuizStartTime(originalStartTime);
            quizStartTimeRef.current = originalStartTime;
            setQuestionStartTime(now);
            setElapsedTime(answeredTime);
            setPausedTime(pausedDuration);
            pausedTimeRef.current = pausedDuration;
            setIsPaused(false);

          } catch (err: any) {
            console.error('Failed to initialize mock exam testlet quiz:', err);
            setError('Failed to load mock exam testlet. Please try again.');
          } finally {
            setIsLoading(false);
          }
        })();
      }
    }, [providedQuizHistoryId, mockExamTestletId, user, courseId]);

// Main useEffect for data fetching and initialization
useEffect(() => {
  if (quizHistory && quizHistory.length > 0) {
    const latestQuiz = quizHistory[0];
    const confidenceCounts = calculateConfidenceCounts(latestQuiz.questions_data);
    setFilterCounts({
      all: latestQuiz.total_questions,
      correct: latestQuiz.correct_answers,
      incorrect: latestQuiz.incorrect_answers,
      confident: confidenceCounts.confident,
      maybe: confidenceCounts.maybe,
      guessing: confidenceCounts.guessing
    });
  }
}, [quizHistory, calculateConfidenceCounts]);

// In Quiz.tsx

const transformAvailableCounts = (counts: any): ExtendedCategoryStats => {
  console.log('Transforming counts:', counts);
  return {
    category_id: 'aggregated',
    total_questions: counts.total_questions || 0,
    unanswered_questions: counts.unanswered_questions || 0,
    correct_percentage: counts.correct_percentage || 0,
    incorrect: counts.incorrect || 0,
    correct: counts.correct || 0,
    confident: counts.confident || 0,
    maybe: counts.maybe || 0,
    guessing: counts.guessing || 0,
    confident_correct: counts.confident_correct || 0,
    confident_incorrect: counts.confident_incorrect || 0,
    maybe_correct: counts.maybe_correct || 0,
    maybe_incorrect: counts.maybe_incorrect || 0,
    guessing_correct: counts.guessing_correct || 0,
    guessing_incorrect: counts.guessing_incorrect || 0
  };
};

// Memoize the transformed counts
const finalAvailableCounts = useMemo(() => {
  if (!availableQuestionCounts) return undefined;
  return transformAvailableCounts(availableQuestionCounts);
}, [availableQuestionCounts]);

// Update the available questions calculation
const totalAvailableQuestions = useMemo(() => {
  if (!finalAvailableCounts) {
    console.log('No final available counts');
    return 0;
  }

  console.log('Current keslerQFilter:', keslerQFilter);

  if (keslerQFilter.includes('unanswered')) {
    console.log('Unanswered questions count:', finalAvailableCounts.unanswered_questions);
    return finalAvailableCounts.unanswered_questions;
  }

  const statusFilter = keslerQFilter.find(f => 
    ['correct', 'incorrect'].includes(f)
  ) as 'correct' | 'incorrect' | undefined;

  const confidenceLevels = keslerQFilter.filter(f => 
    ['confident', 'maybe', 'guessing'].includes(f)
  ) as ConfidenceLevel[];

  if (confidenceLevels.length > 0) {
    return confidenceLevels.reduce((sum, confidence) => {
      if (statusFilter) {
        return sum + getFilteredCount(finalAvailableCounts, confidence, statusFilter);
      }
      return sum + getStatusCount(finalAvailableCounts, confidence);
    }, 0);
  }

  if (statusFilter) {
    return getStatusCount(finalAvailableCounts, statusFilter);
  }

  return finalAvailableCounts.unanswered_questions;
}, [finalAvailableCounts, keslerQFilter]);

// Update the initialization effect to only run once
useEffect(() => {
  if (!finalAvailableCounts || hasInitialized) return;

  const initialCount = Math.min(totalAvailableQuestions, 72);
  setCustomQuestionCount(initialCount);
  setHasInitialized(true);
}, [finalAvailableCounts, totalAvailableQuestions, hasInitialized]);

// Update the filter change effect to better preserve user selections
useEffect(() => {
  if (!hasInitialized || !finalAvailableCounts) return;

  const availableQuestions = totalAvailableQuestions;
  
  // If current count exceeds available, adjust it down
  if (customQuestionCount > availableQuestions) {
    const newCount = Math.min(availableQuestions, 72);
    console.log('Reducing count to match available:', {
      from: customQuestionCount,
      to: newCount,
      available: availableQuestions
    });
    setCustomQuestionCount(newCount);
  }
  // If no count is set but questions are available, set a default
  else if (customQuestionCount === 0 && availableQuestions > 0) {
    const newCount = Math.min(10, availableQuestions);
    console.log('Setting default count:', {
      to: newCount,
      available: availableQuestions
    });
    setCustomQuestionCount(newCount);
  }
}, [totalAvailableQuestions, customQuestionCount, hasInitialized, finalAvailableCounts]);

  // Add this effect to handle pausing when showing explanation
  useEffect(() => {
    if (showExplanation) {
      setExplanationStartTime(Date.now());
    } else if (explanationStartTime) {
      setPausedTime(prev => prev + (Date.now() - explanationStartTime));
      setExplanationStartTime(null);
    }
  }, [showExplanation]);

  // Add this effect to handle pausing when user navigates away
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (quizStarted && !quizCompleted) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden && quizStarted && !quizCompleted) {
        handlePauseQuiz();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [quizStarted, quizCompleted]);

//LOG TESTS REMOVE LATER

useEffect(() => {
  if (availableQuestionCounts) {
    console.log('availableQuestionCounts:', availableQuestionCounts);
  }
}, [availableQuestionCounts]);

// Handle quiz question choices
useEffect(() => {
  const choiceElements = document.querySelectorAll('.quiz-content [data-choice]');
  choiceElements.forEach(element => {
    element.addEventListener('click', (e) => {
      const choice = element.getAttribute('data-choice');
      if (choice) {
        handleAnswer(choice);
      }
    });
  });

  return () => {
    choiceElements.forEach(element => {
      element.removeEventListener('click', () => {});
    });
  };
}, [currentQuestion]);

useEffect(() => {
  if (categoriesData) {
    setCategoriesStructure(categoriesData.structuredCategories);
    console.log('Categories data in component:', categoriesData);
  }
}, [categoriesData]);

useEffect(() => {
  const historyToUse = isFilteringLocally ? localQuizHistory : quizHistory;
  if (historyToUse && historyToUse.length > 0) {
    console.log('Updating filter counts from useEffect');
    updateFilterCounts(historyToUse);
  }
}, [isFilteringLocally, updateFilterCounts]);

// -------------------------
  //  FIX #1 - localQuizHistory effect to avoid infinite loop
  // -------------------------
  useEffect(() => {
    // Only update if we have actual quiz history data and we're not filtering locally.
    // We'll compare entire arrays (via JSON.stringify) and update setLocalQuizHistory only if content changed.
    if (!quizHistory || quizHistory.length === 0) return;
    if (isFilteringLocally) return;

    const currentLocalHistory = JSON.stringify(localQuizHistory);
    const newHistory = JSON.stringify(quizHistory);

    if (currentLocalHistory !== newHistory) {
      setLocalQuizHistory(quizHistory);
    }
  }, [quizHistory, localQuizHistory, isFilteringLocally]);

  // -------------------------
  //  FIX #2 - resumeQuizId effect to avoid repeated setState
  // -------------------------
  useEffect(() => {
    if (!resumeQuizId) return;
    // If there's no active quiz or the IDs don't match, do nothing
    if (!activeQuizState.activeQuiz) return;
    if (activeQuizState.activeQuiz.id !== resumeQuizId) return;

    // Only show the modal if it's not already shown
    if (!activeQuizState.showActiveQuizModal) {
      setActiveQuizState(prev => ({
        ...prev,
        showActiveQuizModal: true
      }));
    }
  }, [resumeQuizId, activeQuizState.activeQuiz, activeQuizState.showActiveQuizModal, setActiveQuizState]);


useEffect(() => {
  if (categoriesData?.statsMap) {
    setLocalCategoryStats(categoriesData.statsMap);
  }
}, [categoriesData]);

  // Add this effect to reset scroll position
  useEffect(() => {
    scrollToTop();
  }, [quizStarted, quizCompleted, viewingCompletedQuiz, currentQuestion, scrollToTop]);

  useEffect(() => {
    if (closingSummary) {
      const timer = setTimeout(() => {
        setClosingSummary(false);
      }, 100); // Small delay to ensure the view has changed
      return () => clearTimeout(timer);
    }
  }, [closingSummary]);

  // Update categoryStats when cachedCategoryStats changes
  useEffect(() => {
    if (cachedCategoryStats) {
      const formattedStats = cachedCategoryStats.reduce((acc: { [key: string]: CategoryStats }, stat: CategoryStats) => {
        acc[stat.category_id] = stat;
        return acc;
      }, {});
      setCategoryStats(formattedStats);
    }
  }, [cachedCategoryStats]);

  useEffect(() => {
    const categoryFromUrl = searchParams.get('category');
    const countFromUrl = searchParams.get('count');

    if (categoryFromUrl) {
      setSelectedCategories([categoryFromUrl]);
      setShowCustomizer(true);
    }

    if (countFromUrl) {
      const parsedCount = parseInt(countFromUrl, 10);
      if (!isNaN(parsedCount)) {
        setCustomQuestionCount(parsedCount);
      }
    }

    // Always show the customizer when there are URL parameters
    if (categoryFromUrl || countFromUrl) {
      setShowCustomizer(true);
    }
  }, [searchParams]);

  // Updated useEffect for scroll handling
  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener('scroll', handleScroll);
      handleScroll(); // Check initial scroll position
    }
    return () => {
      if (tableElement) {
        tableElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

// END USE EFFECT


  // Update how we use the hook
  const { checkForActiveQuiz } = useActiveQuiz(userId, courseId, {
    enabled: false
  });

  // Updated handleCategorySelection function
  const handleCategorySelection = useCallback(async (categoryId: string, isParent: boolean) => {
    try {
      // First check for active quiz
      const { data: activeQuiz } = await checkForActiveQuiz();
      
      if (activeQuiz) {
        setActiveQuizState({
          activeQuiz,
          activeTestletType: getTestletType(activeQuiz),
          showActiveQuizModal: true
        });
        return;
      }

      // If no active quiz, proceed with category selection
      setSelectedCategories(prev => {
        let newSelection: string[];
        if (isParent) {
          const parentCategory = categoriesData?.structuredCategories[categoryId];
          if (parentCategory) {
            const subCategoryIds = parentCategory
              .filter(cat => cat.sub_question_category_name !== 'All')
              .map(cat => cat.id);
            const isAllSelected = subCategoryIds.every(id => prev.includes(id));
            if (isAllSelected) {
              newSelection = prev.filter(id => !subCategoryIds.includes(id));
            } else {
              newSelection = [...new Set([...prev, ...subCategoryIds])];
            }
          } else {
            newSelection = prev;
          }
        } else {
          newSelection = prev.includes(categoryId)
            ? prev.filter(id => id !== categoryId)
            : [...prev, categoryId];
        }
        
        // Update selectAll state
        const allCategoryIds = Object.values(categoriesData?.structuredCategories || {})
          .flatMap(subCategories => subCategories
            .filter(category => category.sub_question_category_name !== 'All')
            .map(category => category.id)
          );
        setSelectAll(newSelection.length === allCategoryIds.length);
        
        // Show customizer if categories are selected
        setShowCustomizer(newSelection.length > 0);
        
        return newSelection;
      });
    } catch (error) {
      console.error('Error checking for active quiz:', error);
      setError?.('Failed to check for active quizzes');
    }
  }, [categoriesData, checkForActiveQuiz, setActiveQuizState, setError]);

  // This function formats time in seconds to a string format (minutes:seconds)
  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };



  // Consolidate quiz initialization logic
  const initializeQuiz = async (params: QuizInitParams): Promise<QuizInitializationState> => {
    const { quizId, selectedQuestions, mockExamTestletId, studyTaskTestletId, studyTaskId } = params;
    
    try {
      let quizHistory: QuizHistory;
      let questions: (Question & { note: Note | null })[];
      let confidenceLevels: QuizConfidenceLevel[];
  
      if (quizId) {
        // Resuming existing quiz
        [quizHistory] = await Promise.all([
          api.getQuizHistoryById(quizId)
        ]);
  
        const questionIds = quizHistory.questions_data.map(q => q.questionId);
        
        // Get the API responses
        const [questionsResponse, confidenceLevelsResponse] = await Promise.all([
          api.getQuestionsById(questionIds, currentUser.id, courseId),
          api.getConfidenceLevels(currentUser.id, courseId, questionIds)
        ]);
  
        // Transform the confidence levels to match our interface
        questions = questionsResponse;
        confidenceLevels = confidenceLevelsResponse
          .filter(cl => cl.confidence_history.some(history => history.level !== null))
          .map(cl => ({
            question_id: cl.question_id || '',
            current_confidence_level: cl.current_confidence_level,
            confidence_history: cl.confidence_history
              .filter(history => history.level !== null)
              .map(history => ({
                level: history.level || '',  // Convert null to empty string if needed
                timestamp: history.timestamp
              }))
          }));
  
      } else {
        // Starting new quiz
        questions = selectedQuestions || [];
        const questionIds = questions.map(q => q.id);
  
        // Create initial question data based on quiz type
        const initialQuestionsData = questionIds.map(questionId => {
          const baseData = {
            questionId,
            answered: false,
            isCorrect: false,
            timeSpent: 0,
            userAnswer: '',
            confidenceLevel: null as ConfidenceLevelValue,
            free_trial_content: questions.find(q => q.id === questionId)?.free_trial_content || false
          };
  
          if (mockExamTestletId) {
            return {
              ...baseData,
              type: 'mock_exam_mcq' as const,
              mockExamId: mockExamState.mockExamId!,
              mockExamTestletId,
              testletNumber: 1,
              mockExamNumber: 1
            };
          } else if (studyTaskTestletId && studyTaskId) {
            return {
              ...baseData,
              type: 'sp_studytasktestlet' as const,
              testletId: studyTaskTestletId,
              studyTaskId,
              score: 0,
              feedback: [],
              question_results: []
            };
          } else {
            return {
              ...baseData,
              type: 'mcq' as const
            };
          }
        });
  
        // Get confidence data
        const [confidenceLevelsResponse, quizHistoryId] = await Promise.all([
          api.getConfidenceLevels(currentUser.id, courseId, questionIds),
          api.createQuizHistory(
            currentUser.id, 
            courseId, 
            initialQuestionsData,
            undefined,
            mockExamTestletId
          )
        ]);
  
        // Transform confidence levels with proper type handling
        confidenceLevels = confidenceLevelsResponse
          .filter(cl => cl.confidence_history.some(history => history.level !== null))
          .map(cl => ({
            question_id: cl.question_id || '',
            current_confidence_level: cl.current_confidence_level,
            confidence_history: cl.confidence_history
              .filter(history => history.level !== null)
              .map(history => ({
                level: history.level || '',  // Convert null to empty string if needed
                timestamp: history.timestamp
              }))
          }));
  
        quizHistory = await api.getQuizHistoryById(quizHistoryId);
      }
  
      return { questions, confidenceLevels, quizHistory };
    } catch (error) {
      console.error('Failed to initialize quiz:', error);
      throw error;
    }
  };

  // -----------
  // 1) Provide a callback that QuizCustomizer will call
  //    the moment the user clicks "Start Quiz."
  //    We'll set isInitializingQuiz = true to show an immediate global spinner.
  // -----------
  const beginQuizInitialization = useCallback(() => {
    // This sets our top-level spinner
    setIsInitializingQuiz(true);
  }, []);

  // If normal scenario (no mock exam), handle start quiz
  const handleStartQuiz = useCallback(async () => {
    console.log('=== Starting handleStartQuiz ===');
    
    try {
      // Show loading immediately
      setIsInitializingQuiz(true);
  
      // Early returns with proper cleanup
      if (mockExamTestletId && providedQuizHistoryId) {
        console.log('Exiting early - mock exam scenario detected');
        return;
      }
    
      if (!currentUser) {
        console.log('Exiting early - no current user');
        return;
      }
  
      // Check for any active mock exam or study task testlet
      const isMockExamActive = sessionStorage.getItem('mock_exam_testlet_active') === 'true';
      const isStudyTaskActive = sessionStorage.getItem('study_task_testlet_active') === 'true';
  
      if (isMockExamActive || isStudyTaskActive) {
        // If there's an active testlet, show the popup with the active quiz
        const activeQuizData = await api.getActiveQuiz(userId, courseId);
        if (activeQuizData) {
          activeQuizRef.current = activeQuizData;
          setActiveQuizState({
            activeQuiz: activeQuizData,
            activeTestletType: getTestletType(activeQuizData),
            showActiveQuizModal: true
          });
        }
        return;
      }
    
      if (activeQuizRef.current) {
        console.log('Active quiz detected, showing popup');
        setActiveQuizState({
          ...activeQuizState,
          showActiveQuizModal: true
        });
        return;
      }
  
  
      // Get questions first
      const questionsToUse = await api.getQuestionsForCustomQuiz(
        courseId,
        selectedCategories,
        keslerQFilter,
        Math.min(customQuestionCount, 72),
        currentUser.id
      );
  
      if (!questionsToUse || questionsToUse.length === 0) {
        setError('No questions were returned for the selected criteria.');
        return;
      }
  
      // Initialize quiz with selected questions
      const { questions, confidenceLevels, quizHistory } = await initializeQuiz({
        selectedQuestions: questionsToUse,
        mockExamTestletId,
        studyTaskTestletId: studyTaskState.studyTaskTestletId,
        studyTaskId: studyTaskState.studyTaskId
      });
  
      if (!keslerQFilter.includes('unanswered')) {
        await api.resetSelectedQuestions(currentUser.id, courseId, questions.map(q => q.id));
      }
  
      // Set all states at once
      const startTime = Date.now();
      const initialConfidenceHistory = confidenceLevels.reduce((acc, cl) => {
        if (cl.question_id && cl.confidence_history) {
          acc[cl.question_id] = cl.confidence_history
            .map(hist => ({
              level: hist.level || null,
              timestamp: hist.timestamp
            }))
            .sort((a, b) => 
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
            );
        }
        return acc;
      }, {} as {[key: string]: { level: string | null; timestamp: string; }[]});
  
      // Batch update all states
      setCurrentView('quiz');
      setQuestions(questions);
      setCurrentQuestion(0);
      setUserAnswers({});
      setQuizCompleted(false);
      setQuizStarted(true);
      setNotes({});
      setIsLastQuestion(false);
      setQuizSummary([]);
      setShowExplanation(false);
      setConfidenceLevel(null);
      setSelectedAnswer(null);
      
      setQuizStartTime(startTime);
      setQuestionStartTime(startTime);
      setElapsedTime(0);
      setPausedTime(0);
      setIsPaused(false);
      
      setConfidenceHistory(initialConfidenceHistory);
      setCurrentQuizHistoryId(quizHistory.id);
      setFilterCounts({
        all: 0,
        correct: 0,
        incorrect: 0,
        confident: 0,
        maybe: 0,
        guessing: 0
      });
  
      // Update refs and navigate
      activeQuizRef.current = quizHistory;
      setActiveQuizState({
        activeQuiz: quizHistory,
        activeTestletType: getTestletType(quizHistory),
        showActiveQuizModal: false // Don't show modal when starting fresh
      });

      navigate(`/course/${courseId}/quiz`, {
        replace: true,
        state: {
          resumeQuizId: quizHistory.id,
          quizData: quizHistory,
          from: 'quiz-start'
        }
      });

    } catch (err) {
      console.error('Failed to start custom quiz:', err);
      setError(`Failed to start custom quiz. Please try again. Error: ${err instanceof Error ? err.message : JSON.stringify(err)}`);
    } finally {
      setIsInitializingQuiz(false);
    }
  }, [
    courseId, 
    selectedCategories, 
    keslerQFilter, 
    customQuestionCount, 
    currentUser,
    mockExamTestletId,
    studyTaskState,
    navigate,
    activeQuizState,
    setActiveQuizState
  ]);

  const handlePauseQuiz = useCallback(() => {
    if (!isPaused) {
      setIsPaused(true);
      const newPausedTime = pausedTime + (Date.now() - (quizStartTime || 0) - pausedTime);
      setPausedTime(newPausedTime);
      pausedTimeRef.current = newPausedTime;
    }
  }, [isPaused, quizStartTime, pausedTime]);


  useEffect(() => {
    const initializeQuiz = async () => {
      setIsLoading(true);
      if (user) {
        setCurrentUser(user);
        setIsLoading(false);
      }
    };

    initializeQuiz();
  }, [user]);


  // Add this memoized value at the component level, before the renderQuestion function
  const lastConfidenceLevelForCurrentQuestion = useMemo(() => {
    const history = confidenceHistory[questions[currentQuestion]?.id];
    if (!history || !Array.isArray(history)) {
      return null;
    }
    const sortedHistory = [...history].sort((a, b) => 
      new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    );
    return sortedHistory.find(entry => entry.level !== null)?.level || null;
  }, [confidenceHistory, questions, currentQuestion]);

  // Update loadConfidenceLevels to properly transform the confidence history
  const loadConfidenceLevels = async (questionIds: string[]) => {
    if (!currentUser) return;
    try {
      const confidenceLevels = await api.getConfidenceLevels(currentUser.id, courseId, questionIds);
      
      // Transform the confidence levels into our history format
      const newConfidenceHistory = confidenceLevels.reduce((acc, cl) => {
        if (cl.question_id && cl.confidence_history) {
          acc[cl.question_id] = cl.confidence_history;
        }
        return acc;
      }, {} as {[key: string]: { level: string | null; timestamp: string; }[]});

      console.log('Setting confidence history:', newConfidenceHistory);
      setConfidenceHistory(newConfidenceHistory);
    } catch (err) {
      console.error('Failed to load confidence levels:', err);
    }
  };

  // This function handles resuming a previously started quiz
  const handleResumeQuiz = useCallback(async (quizToResume: QuizHistory) => {
    setIsResumingQuiz(true);
    try {
      console.log('Attempting to resume quiz:', quizToResume);
      
      if (!quizToResume) {
        throw new Error('Quiz data is null or undefined');
      }
      
      if (!quizToResume.questions_data || quizToResume.questions_data.length === 0) {
        throw new Error('Quiz has no questions data');
      }
      
      if (!currentUser) {
        throw new Error('Current user is not set');
      }
  
      const firstQuestion = quizToResume.questions_data[0];
      
      // Handle study task testlet resume
      if (isStudyTaskTestlet(firstQuestion)) {
        setStudyTaskState(prev => ({
          ...prev,
          studyTaskId: location.state?.studyTaskId || null,
          studyTaskTestletId: firstQuestion.testletId,
          isStudyTask: true,
          testletType: firstQuestion.type as StudyTaskTestletType
        }));
        
        setIsStudyTask(true);
        setStudyTaskId(location.state?.studyTaskId || null);
      }
  
      // Handle both string IDs and QuizQuestionData objects
      const questionIds = Array.isArray(quizToResume.questions_data) ? 
        quizToResume.questions_data.map((q: QuizQuestionData | string) => {
          if (typeof q === 'string') return q;
          return q.questionId;
        }).filter(Boolean) : [];
  
      console.log('Question IDs to fetch:', questionIds);
      
      if (questionIds.length === 0) {
        throw new Error('No valid question IDs found');
      }
  
      // Use the cached fetch function
      const { questions: quizQuestions, confidenceHistory: newConfidenceHistory } = 
        await fetchQuizData(questionIds);
  
      console.log('Fetched quiz questions:', quizQuestions);
  
      if (quizQuestions.length === 0) {
        throw new Error('Failed to fetch any quiz questions');
      }
  
      // Set questions and confidence history
      setConfidenceHistory(newConfidenceHistory);
      setQuestions(quizQuestions);
  
      // Handle answered questions based on data format
      const answeredQuestionsCount = Array.isArray(quizToResume.questions_data) && 
        typeof quizToResume.questions_data[0] === 'object' ?
        quizToResume.questions_data.filter((q: QuizQuestionData) => q.answered).length : 0;
  
      console.log('Number of answered questions:', answeredQuestionsCount);
      setCurrentQuestion(answeredQuestionsCount);
  
      // Set up user answers if available
      const userAnswersObj = Array.isArray(quizToResume.questions_data) && 
        typeof quizToResume.questions_data[0] === 'object' ?
        quizToResume.questions_data.reduce((acc, q: QuizQuestionData) => {
          if (q.answered) {
            acc[q.questionId] = q.userAnswer;
          }
          return acc;
        }, {} as Record<string, string>) : {};
  
      // Initialize all states
      setUserAnswers(userAnswersObj);
      setCurrentQuizHistoryId(quizToResume.id);
      setQuizCompleted(false);
      
      // Set up timer state
      const now = Date.now();
      const originalStartTime = new Date(quizToResume.start_time).getTime();
      const pausedDuration = now - originalStartTime - (quizToResume.total_time * 1000);
  
      setQuizStartTime(originalStartTime);
      quizStartTimeRef.current = originalStartTime;
      setQuestionStartTime(now);
      setElapsedTime(quizToResume.total_time);
      setPausedTime(pausedDuration);
      pausedTimeRef.current = pausedDuration;
      setIsPaused(false);
      
      setQuizStarted(true);
      setCurrentView('quiz');
      console.log('Quiz resumed successfully');
    } catch (err) {
      console.error('Failed to prepare quiz for resumption:', err);
      setError('Failed to resume quiz. Please try again.');
    } finally {
      setIsResumingQuiz(false);
    }
  }, [
    currentUser,
    courseId,
    location.state,
    fetchQuizData,
    setQuestions,
    setCurrentQuestion,
    setUserAnswers,
    setCurrentQuizHistoryId,
    setQuizCompleted,
    setQuizStarted,
    setError,
    setConfidenceHistory
  ]);

useEffect(() => {
  if (isLoading) return; // Don't proceed if still loading
  if (viewQuizId && from === 'analytics') {
    fetchQuizSummary(viewQuizId);
    setViewingCompletedQuiz(true);
    setShowSummary(true);
    setQuizCompleted(true);
  } else if (resumeQuizId && quizData && !viewingCompletedQuiz && currentUser) {
    // Only skip if it's a mock exam testlet
    const isMockExam = mockExamTestletId && providedQuizHistoryId;
    
    if (!isMockExam) {
      // Resume all other quiz types, including study tasks
      handleResumeQuiz(quizData);
    }
  }
}, [viewQuizId, from, resumeQuizId, quizData, viewingCompletedQuiz, handleResumeQuiz, currentUser]);


//QUIZ SUMMARY DETAILS AND FILTERING

  // This function handles viewing the results of a completed quiz
  const handleViewQuizResults = async (quizId: string) => {
    try {
      if (!currentUser) throw new Error('User not authenticated');
  
      // Fetch the specific quiz history
      const quizHistoryData = await api.getQuizHistoryById(quizId);
      if (!quizHistoryData) throw new Error('Quiz history not found');
  
      // Filter to only include answered questions
      const answeredQuestions = quizHistoryData.questions_data.filter(
        (q: QuizQuestionData) => q.answered
      );
  
      // Fetch all questions for answered questions only
      const quizQuestions = await api.getQuestionsById(
        answeredQuestions.map((q: QuizQuestionData) => q.questionId),
        currentUser.id,
        courseId
      );
  
      // Create quiz summary only for answered questions
      const summary = await Promise.all(answeredQuestions.map(async (questionData: QuizQuestionData) => {
        const question = quizQuestions.find(q => q.id === questionData.questionId);
        const categoryData = question?.question_category_id 
          ? await api.getQuestionCategory(question.question_category_id)
          : null;
        const { questionText, options, romanList, tableHTML, isTableQuestion } = parseQuestionContent(question?.question_content || '');
        return {
          parentCategory: categoryData?.parent_question_category_name || '',
          subCategory: categoryData?.sub_question_category_name || '',
          questionId: questionData.questionId,
          question: questionText,
          options: options,
          romanList: romanList,
          tableHTML: tableHTML,
          isTableQuestion: isTableQuestion,
          userAnswer: questionData.userAnswer,
          correctAnswer: question?.correct_answer || '',
          answerContent: question?.answer_content || '',
          isCorrect: questionData.isCorrect,
          note: question?.note || null,
          confidenceLevel: questionData.confidenceLevel || 'Not recorded',
          timeSpent: questionData.timeSpent || 0,
          free_trial_content: question?.free_trial_content || false
        };
      }));
  
      // If this is a study task testlet, set the appropriate state
      const firstQuestion = quizHistoryData.questions_data[0];
      if (isStudyTaskTestlet(firstQuestion)) {
        setStudyTaskState(prev => ({
          ...prev,
          studyTaskId: firstQuestion.studyTaskId || null,
          studyTaskTestletId: quizHistoryData.study_task_testlet_id,
          isStudyTask: true,
          testletType: firstQuestion.type as StudyTaskTestletType || 'learn'
        }));
      }
  
      setCurrentQuizSummary(summary);
      setQuizSummary(summary);
      setCurrentView('summary');
      setQuizCompleted(true);
      setQuizStarted(false);
      setViewingCompletedQuiz(true);
  
      // Update the filter counts with answered questions only
      console.log('Updating filter counts from handleViewQuizResults');
      const quizHistoryWithAnswered = {
        ...quizHistoryData,
        questions_data: answeredQuestions
      };
      updateFilterCounts([quizHistoryWithAnswered]);
  
      // Calculate and update total time and average time based on answered questions
      const totalTimeSpent = summary.reduce((sum, item) => sum + (item.timeSpent || 0), 0);
      const avgTimePerQuestion = summary.length > 0 ? totalTimeSpent / summary.length : 0;
      setTotalTime(totalTimeSpent);
      setAverageTime(avgTimePerQuestion);
  
    } catch (err) {
      console.error('Failed to load quiz results:', err);
      setError('Failed to load quiz results. Please try again.');
    }
  };

  
  // Update handleEndQuizSession
  const handleEndQuizSession = useCallback(async (quizId: string) => {
    setIsEndingQuiz(true);
    try {
      // Get quiz history before ending session
      const quizHistoryBeforeEnd = await api.getQuizHistoryById(quizId);
      if (!quizHistoryBeforeEnd) throw new Error('Quiz history not found');

      // End the quiz session
      await api.endQuizSession(quizId, totalTime, true);
      
      // Clear active quiz state
      activeQuizRef.current = null;
      resetActiveQuizCheck(); // Use the hook's reset function
      
      // Invalidate category stats
      await invalidateCategoryStats();
      
      // Handle mock exam navigation
      if (mockExamTestletId && mockExamId) {
        navigate(`/course/${courseId}/mock-exam/${mockExamId}`, { replace: true });
      } else {
        // For regular quizzes, ensure quiz history is updated before showing summary
        await new Promise(resolve => setTimeout(resolve, 1000)); // Small delay to ensure DB is updated
        await handleViewQuizResults(quizId);
        await refetchQuizHistory();
      }
      
      // Reset quiz state
      setQuestions([]);
      setCurrentQuestion(0);
      setUserAnswers({});
      setQuizCompleted(true);
      setQuizStarted(false);
      
    } catch (err) {
      console.error('Failed to end quiz session:', err);
      setError('Failed to end quiz session. Please try again.');
    } finally {
      setIsEndingQuiz(false);
    }
  }, [
    totalTime,
    invalidateCategoryStats,
    mockExamTestletId,
    mockExamId,
    navigate,
    courseId,
    handleViewQuizResults,
    refetchQuizHistory,
    resetActiveQuizCheck // Add this dependency
  ]);

  const filteredQuizHistory = useMemo(() => {
    if (!quizHistory) return [];
    return quizHistory.filter(quiz => {
      if (summaryFilter === 'all') return true;
      if (summaryFilter === 'correct') return quiz.correct_answers > 0;
      if (summaryFilter === 'incorrect') return quiz.incorrect_answers > 0;
      const confidenceCounts = calculateConfidenceCounts(quiz.questions_data);
      return confidenceCounts[summaryFilter as ConfidenceLevel] > 0;
    });
  }, [quizHistory, summaryFilter, calculateConfidenceCounts]);

  const handleSummaryFilterChange = useCallback((newFilter: string) => {
    console.log('Changing filter locally to:', newFilter);
    setSummaryFilter(newFilter);
  }, []);

  //Quiz History

  // Modify the existing handleAnswer function
  const handleAnswer = useCallback((answerId: string) => {
    setSelectedAnswer(answerId);
    
    const choiceElements = document.querySelectorAll('.quiz-content [data-choice]');
    choiceElements.forEach(element => {
      const choice = element.getAttribute('data-choice');
      if (choice === answerId) {
        element.classList.add('selected');
        element.closest('li')?.classList.add('selected');
      } else {
        element.classList.remove('selected');
        element.closest('li')?.classList.remove('selected');
      }
    });
  }, []);

  // Add this before the renderConfidenceLevel function
  const getConfidenceIcon = (type: 'confident' | 'maybe' | 'guessing', isMobile: boolean = false) => {
    const classes = isMobile ? "w-3 h-3" : "w-4 h-4";
    
    switch(type) {
      case 'confident': return <Sparkle className={classes} />;
      case 'maybe': return <Scale className={classes} />;
      case 'guessing': return <Shuffle className={classes} />;
    }
  };

  const getConfidenceColors = (level: 'confident' | 'maybe' | 'guessing', isSelected: boolean) => {
    if (!isSelected) return '';
    
    switch(level) {
      case 'confident': 
        return 'bg-green-500 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700';
      case 'maybe': 
        return 'bg-yellow-500 hover:bg-yellow-600 dark:bg-yellow-600 dark:hover:bg-yellow-700';
      case 'guessing': 
        return 'bg-red-500 hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700';
      default: 
        return '';
    }
  };

  // Modify the existing handleConfidenceSelection function
  const handleConfidenceSelection = useCallback((level: ConfidenceLevel) => {
    setConfidenceLevel(level);
    setFocusedConfidenceLevel(confidenceLevels.indexOf(level));
  }, [confidenceLevels]);

  const handleSubmitAnswer = async () => {
    setIsSubmitting(true);
    if (selectedAnswer && currentUser && confidenceLevel && currentQuizHistoryId && questionStartTime) {
      const question = questions[currentQuestion];
      const answerLetter = selectedAnswer.charAt(0);
      const isCorrect = answerLetter === question.correct_answer;
      const currentTime = Date.now();
      const timeSpent = Math.max(0, Math.floor((currentTime - questionStartTime) / 1000)); // Ensure non-negative
    
      try {
        // Save the question response
        await api.saveQuestionResponse({
          user_id: currentUser.id,
          question_id: question.id,
          course_id: courseId,
          selected_answer: answerLetter,
          is_correct: isCorrect,
          confidence_level: confidenceLevel,
          response_time: timeSpent
        });
  
        // Update question confidence level
        await api.updateQuestionConfidenceLevel(currentUser.id, courseId, question.id, confidenceLevel);

        // Update quiz history
        await api.updateQuizHistory(currentQuizHistoryId, question.id, selectedAnswer, isCorrect, confidenceLevel, timeSpent);

        // Updated: Update confidenceHistory with timestamp
        setConfidenceHistory(prev => ({
          ...prev,
          [question.id]: [
            { level: confidenceLevel, timestamp: new Date().toISOString() },
            ...(prev[question.id] || [])
          ]
        }));
  
        setUserAnswers({ ...userAnswers, [question.id]: selectedAnswer });
        
        const { questionText, tableHTML, options, romanList, isTableQuestion } = parseQuestionContent(question.question_content);
  
        const newQuizSummaryItem: QuizSummary = {
          parentCategory: question.question_category_name,
          subCategory: question.sub_question_category_name,
          questionId: question.id,
          question: questionText,
          romanList,
          options,
          tableHTML,
          isTableQuestion,
          userAnswer: selectedAnswer,
          correctAnswer: question.correct_answer,
          answerContent: question.answer_content,
          isCorrect,
          note: question.note,
          confidenceLevel,
          timeSpent,
          free_trial_content: question.free_trial_content || false
        };
  
        console.log('New quiz summary item:', newQuizSummaryItem);
  
        setQuizSummary(prevSummary => [...prevSummary, newQuizSummaryItem]);
  
        // Update local category stats
        updateLocalCategoryStats(question, isCorrect, confidenceLevel);
  
        // Update filter counts
        updateFilterCounts([newQuizSummaryItem]);
  
        setConfidenceLevel(null);
  
        // Highlight the selected and correct answers
        highlightAnswers(selectedAnswer, question.correct_answer);
  
        // Close calculator and spreadsheet
        setShowCalculator(false);
        setShowSpreadsheet(false);

        
        // If mock exam testlet scenario and test mode enforced
        // Actually, isTestMode also used for normal quiz but for mock exam we must always be test mode.
        // If isTestMode or mockExam scenario:
        // We now handle logic differently if this is a mock exam testlet:
        if (mockExamTestletId && providedQuizHistoryId) {
          // For mock exam testlets, we want to auto-advance without showing explanation?
          // The instructions say skip summary at the end, but we can still show explanation if not test mode.
          // Actually, instructions say to skip quiz summary view after completion, not necessarily skip explanation per question.
          // We'll keep explanation logic for MCQs. Just do normal explanation unless isTestMode is true.
          if (isTestMode) {
            await handleNextQuestion();
          } else {
            setShowExplanation(true);
          }
        } else {
          // Normal quiz scenario
          if (isTestMode) {
            await handleNextQuestion();
          } else {
            setShowExplanation(true);
          }
        }

      } catch (err) {
        console.error('Error saving answer:', err);
        setError('Failed to save answer. Please try again.');
      }
    }
    setIsSubmitting(false);
  };

  const updateLocalCategoryStats = (question: Question, isCorrect: boolean, confidenceLevel: string) => {
    setLocalCategoryStats(prevStats => {
      const updatedStats = { ...prevStats };
      const categoryId = question.question_category_id;
      if (updatedStats[categoryId]) {
        updatedStats[categoryId].total_questions--;
        updatedStats[categoryId].unanswered_questions--;
        
        // Calculate new correct answers
        const answeredQuestions = updatedStats[categoryId].total_questions - updatedStats[categoryId].unanswered_questions;
        const correctAnswers = Math.round((updatedStats[categoryId].correct_percentage / 100) * answeredQuestions);
        const newCorrectAnswers = isCorrect ? correctAnswers + 1 : correctAnswers;
        
        // Update correct percentage
        updatedStats[categoryId].correct_percentage = (newCorrectAnswers / (answeredQuestions + 1)) * 100;
      }
      return updatedStats;
    });
  };

  const highlightAnswers = (selectedAnswer: string, correctAnswer: string) => {
    const dataCells = document.querySelectorAll('.quiz-content td [data-choice]');
    if (dataCells.length > 0) {
      dataCells.forEach(cell => {
        const cellParent = cell.closest('td');
        const cellChoice = cell.getAttribute('data-choice');
        if (cellChoice === selectedAnswer) {
          cellParent?.classList.add('selected');
          cellParent?.classList.add(selectedAnswer === correctAnswer ? 'correct' : 'incorrect');
        }
        if (cellChoice === correctAnswer) {
          cellParent?.classList.add('correct');
        }
      });
    }
  };

  // Updated handleNextQuestion function
  const handleNextQuestion = async () => {
    setIsLoadingNextQuestion(true);
    try {
      setShowExplanation(false);
      setConfidenceLevel(null);
      setSelectedAnswer(null);
      setPausedTime(prev => prev + (explanationStartTime ? (Date.now() - explanationStartTime) : 0));
      setExplanationStartTime(null);
      setQuestionStartTime(Date.now());

      if (!questions || questions.length === 0) {
        console.error('No questions available');
        return;
      }

      const nextQuestionIndex = currentQuestion + 1;
      const isNextQuestionLast = nextQuestionIndex === questions.length - 1;

       // Update handleNextQuestion's study task branch to use the centralized navigation
       if (studyTaskState.isStudyTask && nextQuestionIndex >= questions.length) {
        if (studyTaskState.studyTaskTestletId && currentQuizHistoryId) {
          // Complete the study task testlet
          await api.updateStudyTaskTestletFromQuizHistory(studyTaskState.studyTaskTestletId, currentQuizHistoryId);
          
          // Get study task ID from multiple sources in order of preference
          const studyTaskIdFromUrl = searchParams.get('study_task_id');
          const effectiveId = studyTaskIdFromUrl || 
                            taskDetails?.study_task_id || 
                            studyTaskState.studyTaskId;
          
          if (effectiveId) {
            // Navigate to study task view with appropriate state
            navigate(`/course/${courseId}/study-task/${effectiveId}`, {
              replace: true,
              state: {
                from: 'quiz',
                testletCompleted: true,
                skipSummary: true,
                testletType: studyTaskState.testletType,
                refreshStudyTask: true
              }
            });
          } else {
            console.error('No study task ID found for navigation');
            navigate(`/course/${courseId}/study-plan`, { replace: true });
          }
          return;
        }
      }
      // Handle mock exam testlet completion
      if (mockExamTestletId && currentQuizHistoryId) {
        const quizHistory = await api.getQuizHistoryById(currentQuizHistoryId);
        
        if (!quizHistory) {
          throw new Error('Quiz history not found');
        }

        if (nextQuestionIndex < questions.length) {
          setCurrentQuestion(nextQuestionIndex);
          setIsLastQuestion(isNextQuestionLast);
        } else {
          // Calculate final score for the testlet
          const correctAnswers = quizHistory.questions_data.filter(
            (q: QuizQuestionData) => q.isCorrect
          ).length;
          const totalQuestions = quizHistory.questions_data.length;
          const score = (correctAnswers / totalQuestions) * 100;
          
          // End quiz session and complete testlet
          await api.endQuizSession(currentQuizHistoryId, totalTime, false);
          await api.completeTestlet(mockExamTestletId, score, totalTime, currentQuizHistoryId);
          
          // Navigate back to mock exam view
          if (mockExamId) {
            navigate(`/course/${courseId}/mock-exam/${mockExamId}`, {
              replace: true
            });
          }
        }
        return;
      }

      // Regular quiz scenario
      if (nextQuestionIndex < questions.length) {
        setCurrentQuestion(nextQuestionIndex);
        setIsLastQuestion(isNextQuestionLast);
      } else {
        await handleFinishQuiz();
      }
    } catch (error) {
      console.error('Error loading next question:', error);
      setError('Failed to load next question. Please try again.');
    } finally {
      setIsLoadingNextQuestion(false);
    }
  };

  const handleFinishQuizMockExam = async () => {
    // Mock exam scenario: at the end, skip summary and go back to MockExamDetail
    // End quiz session normally
    setIsFinishingQuiz(true);
    try {
      await handleQuizComplete(true); 
      
      // Get the mock exam ID if we don't have it
      if (!mockExamId && currentQuizHistoryId) {
        const quizHistoryData = await api.getQuizHistoryById(currentQuizHistoryId);
        if (quizHistoryData && quizHistoryData.questions_data.length > 0) {
          const firstQuestion = quizHistoryData.questions_data[0];
          if (isMockExamMCQ(firstQuestion)) {
            setMockExamId(firstQuestion.mockExamId);
          }
        }
      }

      // Always navigate back to mock exam detail with the correct ID
      if (mockExamId) {
        navigate(`/course/${courseId}/mock-exam/${mockExamId}`, { replace: true });
      } else {
        // Fallback to mock exam list if we somehow don't have the ID
        navigate(`/course/${courseId}/mock-exam`, { replace: true });
      }
    } catch (error) {
      console.error('Error finishing mock exam testlet quiz:', error);
      setError('Failed to finish mock exam testlet. Please try again.');
    } finally {
      setIsFinishingQuiz(false);
    }
  };
  
  const handleFinishQuizStudyTask = async () => {
    console.log("Starting handleFinishQuizStudyTask");
    setIsFinishingQuiz(true);
    try {
      await handleQuizComplete(false);
  
      if (!studyTaskState.studyTaskTestletId || !currentQuizHistoryId) {
        console.error("Missing study task data:", {
          studyTaskTestletId: studyTaskState.studyTaskTestletId,
          currentQuizHistoryId,
        });
        throw new Error("Missing required study task data");
      }
  
      const quizHistory = await api.getQuizHistoryById(currentQuizHistoryId);
  
      if (!quizHistory) {
        throw new Error("Quiz history not found");
      }
  
      // Update the study task testlet with results
      await api.updateStudyTaskTestletFromQuizHistory(
        studyTaskState.studyTaskTestletId,
        currentQuizHistoryId
      );
  
      // Get effective study task ID - check all possible sources
      const effectiveId =
        studyTaskState.studyTaskId ||
        searchParams.get("study_task_id") ||
        location.state?.studyTaskId;
  
      // Calculate quiz completion status
      const answeredQuestions = quizHistory.questions_data.filter(q => q.answered);
      const totalQuestions = quizHistory.questions_data.length;
      const isQuizFullyCompleted = answeredQuestions.length === totalQuestions;
  
      console.log("Navigation decision:", {
        studyTaskState,
        effectiveId,
        from: location.state?.from,
        viewingCompletedQuiz,
        quizCompletion: {
          answered: answeredQuestions.length,
          total: totalQuestions,
          isFullyCompleted: isQuizFullyCompleted
        }
      });
  
      if (effectiveId) {
        // Force a small delay to ensure study task component is ready
        await new Promise((resolve) => setTimeout(resolve, 100));
        if (location.state?.from === "analytics" && !location.state?.testletCompleted) {
          console.log("Showing quiz summary (from analytics)");
          setQuizCompleted(true);
          setShowSummary(true);
          setViewingCompletedQuiz(true);
        } else {
          console.log("Forcing navigation to study task");
          setViewingCompletedQuiz(false);
          setShowSummary(false);
  
          navigate(`/course/${courseId}/study-task/${effectiveId}`, {
            replace: true,
            state: {
              from: "quiz",
              testletCompleted: isQuizFullyCompleted, // Only mark as completed if all questions were answered
              skipSummary: true,
              testletType: studyTaskState.testletType,
              refreshStudyTask: true,
              returnedFrom: "quiz",
              questionsAnswered: answeredQuestions.length,
              totalQuestions: totalQuestions
            },
          });
        }
      } else {
        console.error("No study task ID found for navigation");
        navigate(`/course/${courseId}/study-plan`, { replace: true });
      }
    } catch (error) {
      console.error("Error finishing study task testlet quiz:", error);
      setError("Failed to finish study task testlet. Please try again.");
    } finally {
      setIsFinishingQuiz(false);
    }
  };

  // Update the handleFinishQuiz function
  const handleFinishQuiz = async () => {
    setIsFinishingQuiz(true);
    try {
      await handleQuizComplete(isMockExam);
      
      // Different handling based on quiz type
      if (isMockExam && mockExamId) {
        // For mock exam testlets, mark testlet complete and return to mock exam view
        if (mockExamTestletId) {
          const quizHistory = await api.getQuizHistoryById(currentQuizHistoryId!);
          const correctAnswers = quizHistory.questions_data.filter(q => q.isCorrect).length;
          const totalQuestions = quizHistory.questions_data.length;
          const score = (correctAnswers / totalQuestions) * 100;
          
          await api.completeTestlet(mockExamTestletId, score, totalTime, currentQuizHistoryId || undefined);
        }
        
        // Navigate back to mock exam view
        navigate(`/course/${courseId}/mock-exam/${mockExamId}`, {
          replace: true
        });
      } else if (isStudyTask && studyTaskId) {
        // For study task testlets, check completion before navigating
        if (studyTaskTestletId && currentQuizHistoryId) {
          const quizHistory = await api.getQuizHistoryById(currentQuizHistoryId);
          const answeredQuestions = quizHistory.questions_data.filter(q => q.answered);
          const totalQuestions = quizHistory.questions_data.length;
          const isQuizFullyCompleted = answeredQuestions.length === totalQuestions;
          
          await api.updateStudyTaskTestletFromQuizHistory(studyTaskTestletId, currentQuizHistoryId);
  
          // Navigate back to study task view with completion status
          navigate(`/course/${courseId}/study-task/${studyTaskId}`, {
            replace: true,
            state: {
              from: 'quiz',
              testletCompleted: isQuizFullyCompleted,
              skipSummary: true,
              testletType: studyTaskState.testletType,
              refreshStudyTask: true,
              returnedFrom: 'quiz',
              questionsAnswered: answeredQuestions.length,
              totalQuestions
            }
          });
        }
      } else {
        // Standard quiz completion
        if (showUpgradeButton) {
          setShowEndQuizUpgrade(true);
        } else {
          setQuizCompleted(true);
        }
      }

      // Invalidate and refetch categories and question counts
      queryClient.invalidateQueries(['categories', courseId]);
      queryClient.invalidateQueries(['availableQuestionCounts', courseId]);

    } catch (error) {
      console.error('Error finishing quiz:', error);
      setError('Failed to finish quiz. Please try again.');
    } finally {
      setIsFinishingQuiz(false);
    }
  };

  // Helper function to calculate filter counts from summary
  const getFilterCountsFromSummary = (summary: QuizSummary[]): FilterCounts => {
    const counts: FilterCounts = {
      all: 0,
      correct: 0,
      incorrect: 0,
      confident: 0,
      maybe: 0,
      guessing: 0
    };

    summary.forEach(q => {
      counts.all++;
      q.isCorrect ? counts.correct++ : counts.incorrect++;
      const cl = q.confidenceLevel.toLowerCase();
      if (['confident', 'maybe', 'guessing'].includes(cl)) {
        counts[cl as keyof FilterCounts]++;
      }
    });

    return counts;
  };

  const handleQuizComplete = useCallback(async (isMockExamScenario: boolean) => {
    if (!currentQuizHistoryId || !currentUser) return;
    setIsFinishing(true);
    try {
      const quizHistoryData = await api.getQuizHistoryById(currentQuizHistoryId);
      if (!quizHistoryData) throw new Error('Quiz history not found');
  
      // Calculate total time including explanation time
      const totalTimeComputed = elapsedTime + (explanationStartTime ? Math.floor((Date.now() - explanationStartTime) / 1000) : 0);
  
      // If mock exam scenario, handle separately and return early
      if (isMockExamScenario) {
        if (mockExamTestletId) {
          const answeredQuestions = quizHistoryData.questions_data.filter(
            (q: QuizQuestionData) => q.answered
          );
          const correctAnswers = answeredQuestions.filter(
            (q: QuizQuestionData) => q.isCorrect
          ).length;
          const score = (correctAnswers / answeredQuestions.length) * 100;
  
          await api.completeTestlet(mockExamTestletId, score, totalTimeComputed, currentQuizHistoryId);
        }
  
        await api.endQuizSession(currentQuizHistoryId, totalTimeComputed, false);
        return;
      }
  
      // For regular quizzes, end the session first to ensure all data is saved
      await api.endQuizSession(currentQuizHistoryId, totalTimeComputed, true);
  
      // Fetch fresh quiz history after session is ended
      const updatedQuizHistory = await api.getQuizHistoryById(currentQuizHistoryId);
      const answeredQuestions = updatedQuizHistory.questions_data.filter(
        (q: QuizQuestionData) => q.answered
      );
  
      // Get all required data for summary
      const questionIds = answeredQuestions.map(q => q.questionId);
      const [quizQuestions, confidenceLevels] = await Promise.all([
        api.getQuestionsById(questionIds, currentUser.id, courseId),
        api.getConfidenceLevels(currentUser.id, courseId, questionIds)
      ]);
  
      // Create confidence level map from both quiz history and confidence levels table
      const confidenceLevelMap = answeredQuestions.reduce((map, q) => {
        // First try to get from quiz history
        const confidenceFromHistory = q.confidenceLevel;
        // Fallback to confidence levels table
        const confidenceFromTable = confidenceLevels.find(cl => cl.question_id === q.questionId)?.current_confidence_level;
        
        map[q.questionId] = confidenceFromHistory || confidenceFromTable || 'Not recorded';
        return map;
      }, {} as Record<string, string>);
  
      // Create summary with verified confidence levels
      const updatedSummary: QuizSummary[] = await Promise.all(
        answeredQuestions.map(async (questionData: QuizQuestionData) => {
          const question = quizQuestions.find(q => q.id === questionData.questionId);
          if (!question) {
            return {
              parentCategory: '',
              subCategory: '',
              questionId: questionData.questionId,
              question: 'Question not found',
              options: [],
              userAnswer: questionData.userAnswer,
              correctAnswer: '',
              answerContent: '',
              isCorrect: questionData.isCorrect,
              note: null,
              confidenceLevel: confidenceLevelMap[questionData.questionId],
              timeSpent: questionData.timeSpent,
              romanList: '',
              tableHTML: '',
              isTableQuestion: false,
              free_trial_content: false
            };
          }
  
          const categoryData = question.question_category_id 
            ? await api.getQuestionCategory(question.question_category_id)
            : null;
  
          const { questionText, options, romanList, tableHTML, isTableQuestion } = parseQuestionContent(question.question_content || '');
  
          return {
            parentCategory: categoryData?.parent_question_category_name || '',
            subCategory: categoryData?.sub_question_category_name || '',
            questionId: questionData.questionId,
            question: questionText,
            options: options,
            userAnswer: questionData.userAnswer,
            correctAnswer: question.correct_answer || '',
            answerContent: question.answer_content || '',
            isCorrect: questionData.isCorrect,
            note: question.note,
            confidenceLevel: confidenceLevelMap[questionData.questionId],
            timeSpent: questionData.timeSpent,
            romanList: romanList,
            tableHTML: tableHTML,
            isTableQuestion: isTableQuestion,
            free_trial_content: question.free_trial_content || false
          };
        })
      );
  
      // Update UI states
      setQuizSummary(updatedSummary);
      setShowSummary(true);
      setCurrentView('summary');
      setFilterCounts(getFilterCountsFromSummary(updatedSummary));
  
      // Reset quiz state
      setQuizCompleted(true);
      setViewingCompletedQuiz(true);
      setQuizStarted(false);
      setQuizStartTime(null);
      quizStartTimeRef.current = null;
      setQuestionStartTime(null);
      setIsPaused(false);
      setPausedTime(0);
      pausedTimeRef.current = 0;
      setExplanationStartTime(null);
  
      // Invalidate caches and update data
      await invalidateCategoryStats();
      queryClient.invalidateQueries(['availableQuestionCounts', courseId, currentUser.id]);
      await refetchQuizHistory();
  
    } catch (err) {
      console.error('Failed to complete quiz:', err);
      setError('Failed to complete quiz. Please try again.');
    } finally {
      setIsFinishing(false);
    }
  }, [
    currentQuizHistoryId,
    currentUser,
    courseId,
    mockExamTestletId,
    elapsedTime,
    explanationStartTime,
    api,
    queryClient,
    refetchQuizHistory,
    invalidateCategoryStats,
    getFilterCountsFromSummary
  ]);

  //NOTE TAKING SECTION

  const sanitizeInput = useCallback((input: string) => {
    return DOMPurify.sanitize(input);
  }, []);

  // Function to handle note content changes
  const handleNoteChange = useCallback((indexOrValue: number | string, value?: string) => {
    if (typeof indexOrValue === 'number' && value !== undefined) {
      // This is for summary notes
      const sanitizedContent = sanitizeInput(value);
      setNoteContents(prev => ({
        ...prev,
        [indexOrValue]: sanitizedContent.slice(0, MAX_NOTE_LENGTH)
      }));
    } else if (typeof indexOrValue === 'string') {
      // This is for the main quiz note
      const sanitizedContent = sanitizeInput(indexOrValue);
      setNoteContent(sanitizedContent.slice(0, MAX_NOTE_LENGTH));
    }
  }, [sanitizeInput]);

  const handleNoteTaking = async () => {
    if (!currentUser) return;
    try {
      const note = await api.getNoteForQuestion(currentUser.id, courseId, questions[currentQuestion].id);
      if (note) {
        const sanitizedContent = sanitizeInput(note.content);
        setNote(sanitizedContent);
        setCurrentNote({
          ...note,
          content: sanitizedContent
        });
      } else {
        setNote('');
        setCurrentNote(null);
      }
      setShowNoteEditor(true);
    } catch (err) {
      console.error('Failed to fetch note:', err);
    }
  };
  
  const handleUpdateNote = async () => {
    console.log('handleUpdateNote called');
    if (!currentUser) {
      console.error('No current user');
      setError('You must be logged in to update notes.');
      return;
    }
    if (!questions.length || currentQuestion >= questions.length) {
      console.error('Invalid question index');
      setError('Unable to update note: Invalid question selected.');
      return;
    }
    
    const currentQuestionData = questions[currentQuestion];
    console.log('Current question data:', currentQuestionData);
  
    if (!currentQuestionData) {
      console.error('Current question data is null');
      setError('Failed to update/create note: Question data is missing.');
      return;
    }
  
    try {
      console.log('Attempting to update/create note');
      const sanitizedContent = sanitizeInput(noteContent);
      const updatedNote = await api.updateNote(
        currentQuestionData.note?.id || null,
        { content: sanitizedContent },
        currentUser.id,
        courseId,
        currentQuestionData.id,
        null, // lessonId is null for question notes
        null, // moduleId is null for question notes
        null
      );
      
      console.log('Note updated/created successfully:', updatedNote);
  
      // Update the questions state with the updated or new note
      setQuestions(prevQuestions => prevQuestions.map(q => 
        q.id === currentQuestionData.id ? { ...q, note: updatedNote } : q
      ));
  
      setIsEditingNote(false);
      setNoteContent('');
  
      await api.logUserActivity({
        user_id: currentUser.id,
        course_id: courseId,
        activity_type: currentQuestionData.note ? 'update_note' : 'create_note',
        activity_id: updatedNote.id,
        result: 'success'
      });
  
      console.log('User activity logged');
    } catch (err: unknown) {
      console.error('Failed to update/create note:', err);
      if (err instanceof Error) {
        setError(`Failed to update/create note: ${err.message}`);
      } else {
        setError('Failed to update/create note: Unknown error');
      }
    }
  };
  

  const handleSaveNote = async () => {
    if (!currentUser) return;
    try {
      const question = questions[currentQuestion];
      const sanitizedContent = sanitizeInput(noteContent);
      const updatedNote = await api.updateNote(
        question.note?.id || null,
        { content: sanitizedContent },
        currentUser.id,
        courseId,
        question.id,
        null, // lessonId is null for question notes
        null, // moduleId is null for question notes
        null //simulation id
      );
      
      setQuestions(prevQuestions => 
        prevQuestions.map((q, index) => 
          index === currentQuestion ? { ...q, note: updatedNote } : q
        )
      );
      setNoteContent('');
      setIsEditingNote(false);
  
      await api.logUserActivity({
        user_id: currentUser.id,
        course_id: courseId,
        activity_type: question.note ? 'update_note' : 'create_note',
        activity_id: updatedNote.id,
        result: 'success'
      });
    } catch (err) {
      console.error('Failed to save note:', err);
      setError('Failed to save note. Please try again.');
    }
  };
  

  const handleClearNote = async () => {
    if (!currentUser || !questions.length || currentQuestion >= questions.length) {
        console.log('Early return conditions not met:', {
            currentUser: !!currentUser,
            questionsLength: questions.length,
            currentQuestion
        });
        return;
    }

    const currentQuestionData = questions[currentQuestion];
    
    // Add explicit note check
    if (!currentQuestionData?.note?.id) {
        console.log('No valid note to clear:', currentQuestionData?.note);
        return;
    }
  
    try {
        const noteId = currentQuestionData.note.id;
        await api.deleteNote(noteId);
      
        // Update the questions state to remove the note
        setQuestions(prevQuestions => prevQuestions.map(q => 
            q.id === currentQuestionData.id ? { ...q, note: null } : q
        ));
  
        setNoteContent('');
        setCurrentNote(null);  // Add this line
        setIsEditingNote(false);  // Add this line
  
        await api.logUserActivity({
            user_id: currentUser.id,
            course_id: courseId,
            activity_type: 'delete_note',
            activity_id: noteId,
            result: 'success'
        });
    } catch (err) {
        console.error('Failed to clear note:', err);
        setError('Failed to clear note. Please try again.');
    }
};

//END QUIZ NOTE FUNCTIONS

//QUIZ MOCK EXAM TESTLET

// In Quiz.tsx
// Mock exam initialization effect - handles both new testlets and resuming
useEffect(() => {
  const initMockExamTestlet = async () => {
    if (!mockExamTestletId || !currentUser) return;

    try {
      setIsInitializingMockExam(true);
      
      // Get testlet data to find mock exam ID
      const testletData = await api.getMockExamTestletById(mockExamTestletId);
      
      // Update mock exam state
      setMockExamState(prev => ({
        ...prev,
        mockExamId: testletData.mock_exam_id,
        mockExamTestletId,
        isMockExam: true
      }));

      // If we have a quiz history ID, we're resuming
      if (effectiveQuizHistoryId) {
        const quizHistory = await api.getQuizHistoryById(effectiveQuizHistoryId);
        
        if (!quizHistory) {
          throw new Error('Quiz history not found');
        }

        // Setup quiz state from history
        const answeredQuestionsCount = quizHistory.questions_data.filter((q) => q.answered).length;
        setCurrentQuestion(answeredQuestionsCount);
        
        // Load full questions
        const questionIds = quizHistory.questions_data.map(q => q.questionId);
        const questions = await api.getQuestionsById(questionIds, currentUser.id, courseId);
        setQuestions(questions);

        // Setup user answers from history
        const userAnswersObj = quizHistory.questions_data.reduce((acc: any, q) => {
          if (q.answered) {
            acc[q.questionId] = q.userAnswer;
          }
          return acc;
        }, {});
        setUserAnswers(userAnswersObj);

        // Initialize quiz state
        setCurrentQuizHistoryId(effectiveQuizHistoryId);
        setQuizCompleted(quizHistory.completed);
        setQuizStarted(true);
        setShowSummary(false);
        setCurrentView('quiz');

        // Setup timer
        const originalStartTime = new Date(quizHistory.start_time).getTime();
        const now = Date.now();
        const answeredTime = quizHistory.total_time || 0;
        const pausedDuration = now - originalStartTime - (answeredTime * 1000);
        
        setQuizStartTime(originalStartTime);
        quizStartTimeRef.current = originalStartTime;
        setQuestionStartTime(now);
        setElapsedTime(answeredTime);
        setPausedTime(pausedDuration);
        pausedTimeRef.current = pausedDuration;
        setIsPaused(false);

      } else {
        // Starting new testlet
        const quizQuestions = await api.getQuestionsById(
          testletData.questions_data, 
          currentUser.id, 
          courseId
        );

        // Set initial states
        setCurrentView('quiz');
        setQuestions(quizQuestions);
        setCurrentQuestion(0);
        setUserAnswers({});
        setQuizCompleted(false);
        setQuizStarted(true);
        setConfidenceHistory({});
        setIsTestMode(true);

        // Setup timer
        const startTime = Date.now();
        setQuizStartTime(startTime);
        setQuestionStartTime(startTime);
        setElapsedTime(0);
        setPausedTime(0);
        setIsPaused(false);

        // Create initial quiz history
        const initialQuestionsData: MockExamMCQQuestionData[] = quizQuestions.map((question) => ({
          type: 'mock_exam_mcq',
          questionId: question.id,
          answered: false,
          isCorrect: false,
          timeSpent: 0,
          userAnswer: '',
          confidenceLevel: null,
          free_trial_content: question.free_trial_content,
          mockExamId: testletData.mock_exam_id,
          mockExamTestletId: mockExamTestletId,
          testletNumber: testletData.testlet_number,
          mockExamNumber: 1
        }));

        // Create history entry and update URL
        const quizHistoryId = await api.createQuizHistory(
          currentUser.id, 
          courseId, 
          initialQuestionsData,
          undefined,
          mockExamTestletId
        );

        setCurrentQuizHistoryId(quizHistoryId);

        // Update URL without triggering remount
        navigate(
          `/course/${courseId}/quiz?mock_exam_testlet_id=${mockExamTestletId}&quiz_history_id=${quizHistoryId}`,
          { replace: true }
        );
      }

    } catch (err) {
      console.error('Failed to initialize mock exam testlet:', err);
      setError('Failed to load mock exam testlet. Please try again.');
      // Navigate back to mock exam on error
      navigate(`/course/${courseId}/mock-exam`, { replace: true });
    } finally {
      setIsInitializingMockExam(false);
    }
  };

  if (mockExamTestletId && currentUser) {
    initMockExamTestlet();
  }
}, [mockExamTestletId, currentUser, effectiveQuizHistoryId, courseId, navigate]);

// In Quiz.tsx
// First effect - Just fetch mock exam ID when needed
useEffect(() => {
  if (effectiveTestletId && !mockExamState.mockExamId) {
    const fetchMockExamId = async () => {
      try {
        const testlet = await api.getMockExamTestletById(effectiveTestletId);
        setMockExamState(prev => ({
          ...prev,
          mockExamId: testlet.mock_exam_id,
          mockExamTestletId: effectiveTestletId
        }));
      } catch (error) {
        console.error('Failed to fetch mock exam ID:', error);
        setError('Failed to initialize mock exam testlet.');
      }
    };
    fetchMockExamId();
  }
}, [effectiveTestletId]);


//END QUIZ MOCK EXAM TESTLET


//QUIZ TOOLS
const updateQuizHistory = useCallback(async (answeredOnly: boolean) => {
  if (!currentQuizHistoryId || !activeQuizRef.current) return;

  try {
    const currentQuizData = activeQuizRef.current;
    let questionsData = currentQuizData.questions_data;

    if (answeredOnly) {
      // Filter to only include answered questions
      questionsData = questionsData.filter((q: QuizQuestionData) => q.answered);

      // Calculate new statistics
      const totalAnswered = questionsData.length;
      const correctAnswers = questionsData.filter((q: QuizQuestionData) => q.isCorrect).length;
      const incorrectAnswers = totalAnswered - correctAnswers;
      const percentageCorrect = totalAnswered > 0 ? (correctAnswers / totalAnswered) * 100 : 0;
      const totalTime = questionsData.reduce((sum: number, q: QuizQuestionData) => sum + (q.timeSpent || 0), 0);
      const avgTimePerQuestion = totalAnswered > 0 ? totalTime / totalAnswered : 0;

      // Update the active quiz ref
      activeQuizRef.current = {
        ...currentQuizData,
        questions_data: questionsData,
        total_questions: totalAnswered,
        correct_answers: correctAnswers,
        incorrect_answers: incorrectAnswers,
        percentage_correct: percentageCorrect,
        total_time: totalTime,
        avg_time_per_question: avgTimePerQuestion,
        completed: true,
        end_time: new Date().toISOString()
      };

      if (mockExamTestletId) {
        // For mock exam testlets, complete the testlet before ending session
        const score = percentageCorrect;
        await api.completeTestlet(mockExamTestletId, score, totalTime, currentQuizHistoryId);
      }

      // End the quiz session with true for manuallyEnded
      await api.endQuizSession(currentQuizHistoryId, totalTime, true);
    }
  } catch (error) {
    console.error('Error updating quiz history:', error);
    setError('Failed to update quiz history. Please try again.');
  }
}, [currentQuizHistoryId, mockExamTestletId, api, setError]);

const handleEndTestlet = useCallback(async () => {
  if (currentQuizHistoryId && currentUser) {
    try {
      if (currentQuestion > 0) {
        // If questions were answered, use updateQuizHistory
        await updateQuizHistory(true);
      } else {
        // If no questions answered, just end the session
        await api.endQuizSession(currentQuizHistoryId, elapsedTime, true);
      }
      
      // Reset all quiz-related states
      setQuizCompleted(false);
      setShowEndTestletModal(false);
      setQuizStarted(false);
      setCurrentView('categories');
      setSelectedCategories([]);
      setQuestions([]);
      setCurrentQuestion(0);
      setUserAnswers({});
      setQuizSummary([]);
      setSelectedAnswer(null);
      setShowExplanation(false);
      setConfidenceLevel(null);
      
      // Clear timer states
      setQuizStartTime(null);
      quizStartTimeRef.current = null;
      setQuestionStartTime(null);
      setIsPaused(false);
      setPausedTime(0);
      pausedTimeRef.current = 0;
      setExplanationStartTime(null);
      setElapsedTime(0);
      
      // Clear any active quiz reference
      activeQuizRef.current = null;
      
      // Clear any errors
      setError(null);
      
      // Handle navigation based on quiz type
      if (isMockExam && mockExamId) {
        navigate(`/course/${courseId}/mock-exam/${mockExamId}`, { replace: true });
      } else {
        navigate(`/course/${courseId}/quiz`, { replace: true });
      }
      
      // Invalidate queries to refresh data
      queryClient.invalidateQueries(['categories', courseId]);
      queryClient.invalidateQueries(['availableQuestionCounts', courseId]);
      await refetchQuizHistory();

    } catch (err) {
      console.error('Failed to end testlet session:', err);
      // Even if ending the session fails, we still want to reset the UI
      setQuizStarted(false);
      setCurrentView('categories');
      navigate(`/course/${courseId}/quiz`, { replace: true });
    }
  }
}, [
  currentQuizHistoryId, 
  currentUser, 
  currentQuestion,
  elapsedTime, 
  courseId,
  isMockExam,
  mockExamId,
  navigate, 
  queryClient, 
  refetchQuizHistory,
  updateQuizHistory
]);

// Add this new effect to handle the force refresh
useEffect(() => {
  const handleForceRefresh = async () => {
    if (location.state?.forceRefresh && location.state?.viewQuizId) {
      const quizId = location.state.viewQuizId;
      setIsLoadingQuizSummary(true);
      try {
        // Fetch fresh quiz data
        const quizHistoryData = await api.getQuizHistoryById(quizId);
        if (!quizHistoryData) throw new Error('Quiz history not found');

        // Only process answered questions
        const answeredQuestions = quizHistoryData.questions_data.filter(
          (q: QuizQuestionData) => q.answered
        );

        // Fetch questions and create summary
        const userId = currentUser?.id || '';
        const quizQuestions = await api.getQuestionsById(
          answeredQuestions.map((q: QuizQuestionData) => q.questionId),
          userId,
          courseId
        );

        const summary = await Promise.all(answeredQuestions.map(async (questionData: QuizQuestionData) => {
          const question = quizQuestions.find(q => q.id === questionData.questionId);
          if (!question) return null;
        
          const categoryData = question.question_category_id 
            ? await api.getQuestionCategory(question.question_category_id)
            : null;
          
          const { questionText, options, romanList, tableHTML, isTableQuestion } = parseQuestionContent(question.question_content || '');
        
          const summaryItem: QuizSummary = {
            parentCategory: categoryData?.parent_question_category_name || '',
            subCategory: categoryData?.sub_question_category_name || '',
            questionId: questionData.questionId,
            question: questionText,
            options: options,
            userAnswer: questionData.userAnswer,
            correctAnswer: question.correct_answer || '',
            answerContent: question.answer_content || '',
            isCorrect: questionData.isCorrect,
            note: question.note,
            confidenceLevel: questionData.confidenceLevel || 'Not recorded',
            timeSpent: questionData.timeSpent || 0,
            romanList: romanList || '',
            tableHTML: tableHTML || '',
            isTableQuestion: isTableQuestion || false,
            free_trial_content: question.free_trial_content || false
          };
        
          return summaryItem;
        }));
        
        // Filter out null values and assert the type
        const validSummary = summary.filter(isValidQuizSummary);
        
        // Update states with fresh data
        setQuizSummary(validSummary);
        updateFilterCounts(validSummary);
        setViewingCompletedQuiz(true);

        // Clear the forceRefresh flag from location state
        navigate(`/course/${courseId}/quiz`, { 
          state: { 
            viewQuizId: quizId,
            from: 'quiz'
          },
          replace: true
        });

      } catch (err) {
        console.error('Failed to load quiz results:', err);
        setError('Failed to load quiz results. Please try again.');
      } finally {
        setIsLoadingQuizSummary(false);
      }
    }
  };

  handleForceRefresh();
}, [location.state?.forceRefresh]);

const toggleCalculator = useCallback(() => {
  setShowCalculator(prev => !prev);
}, []);

const toggleSpreadsheet = useCallback(() => {
  setShowSpreadsheet(prev => !prev);
}, []);

const toggleFeedback = useCallback(() => {
  setShowFeedback(prev => !prev);
}, []);

const toggleTestMode = useCallback(() => {
  setIsTestMode(prev => !prev);
}, []);

//END QUIZ TOOLS


  //COMPLETED QUIZ

  // Updated handleSelectAll function
  const handleSelectAll = useCallback(async () => {
    try {
      // Check for active quiz first
      const { data: activeQuiz } = await checkForActiveQuiz();
      
      if (activeQuiz) {
        setActiveQuizState({
          activeQuiz,
          activeTestletType: getTestletType(activeQuiz),
          showActiveQuizModal: true
        });
        return;
      }

      // If no active quiz, proceed with selection
      setSelectAll(prev => {
        const next = !prev;
        if (next) {
          const allCategoryIds = Object.values(categoriesStructure)
            .flatMap(subCategories => subCategories
              .filter(category => category.sub_question_category_name !== 'All')
              .map(category => category.id)
            );
          setSelectedCategories(allCategoryIds);
          setShowCustomizer(true);
        } else {
          setSelectedCategories([]);
          setShowCustomizer(false);
        }
        return next;
      });
    } catch (error) {
      console.error('Error checking for active quiz:', error);
      setError?.('Failed to check for active quizzes');
    }
  }, [categoriesStructure, checkForActiveQuiz, setActiveQuizState, setError]);

  // Updated handler for expanding/collapsing all
  const handleExpandAll = (expand: boolean) => {
    if (expand) {
      // When expanding, get all parent category names and set them all as expanded
      const allParentCategories = Object.keys(categoriesStructure);
      setExpandedCategories(allParentCategories);
    } else {
      // When collapsing, clear all expanded categories
      setExpandedCategories([]);
    }
    setIsAllExpanded(expand);
  };

  // Update the individual category click handler
  const handleCategoryClick = (categoryId: string) => {
    setExpandedCategories(prev => {
      if (prev.includes(categoryId)) {
        return prev.filter(id => id !== categoryId);
      } else {
        return [...prev, categoryId];
      }
    });
    setIsAllExpanded(false);
  };

  // Helper function to check if a category is expanded
  const isCategoryExpanded = (categoryId: string) => expandedCategories.includes(categoryId);

//Sub-components

const PaginationControls: React.FC<{
  currentPage: number;
  totalPages: number;
  paginate: (pageNumber: number) => void;
}> = ({ currentPage, totalPages, paginate }) => {
  return (
    <div className="flex justify-center mt-4">
      {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
        <button
          key={number}
          onClick={() => paginate(number)}
          className={`mx-1 px-3 py-1 rounded ${
            currentPage === number
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          {number}
        </button>
      ))}
    </div>
  );
};

  // Helper function to get the letter for a given index
  const getLetterForIndex = (index: number) => String.fromCharCode(65 + index);


  //useKEYPRESS.TS CODE
// Handle answer selection via keystroke
  const handleKeyboardAnswer = useCallback((index: number) => {
    const answerId = getLetterForIndex(index);
    handleAnswer(answerId);
  }, [handleAnswer]);

  // Handle cycling through confidence levels
  const handleCycleConfidence = useCallback(() => {
    setFocusedConfidenceLevel((prev) => {
      const next = (prev + 1) % confidenceLevels.length;
      handleConfidenceSelection(confidenceLevels[next]);
      return next;
    });
  }, [confidenceLevels, handleConfidenceSelection]);

  // Handle next question or finish quiz
  const handleNextOrFinish = useCallback(() => {
    if (isLastQuestion) {
      // Handle different quiz type scenarios
      if (mockExamTestletId && providedQuizHistoryId) {
        handleFinishQuizMockExam();
      } else if (studyTaskTestletId) {
        handleFinishQuizStudyTask();
      } else {
        handleFinishQuiz();
      }
    } else {
      handleNextQuestion();
    }
  }, [
    isLastQuestion,
    handleFinishQuiz,
    handleFinishQuizMockExam,
    handleFinishQuizStudyTask,
    handleNextQuestion,
    mockExamTestletId,
    providedQuizHistoryId,
    studyTaskTestletId
  ]);

  // Consolidated spacebar press handler
  const handleSpacebarPress = useCallback(() => {
    if (showExplanation) {
      handleNextOrFinish();
    } else if (selectedAnswer && confidenceLevel && !isSubmitting) {
      handleSubmitAnswer();
    }
  }, [showExplanation, selectedAnswer, confidenceLevel, isSubmitting, handleNextOrFinish, handleSubmitAnswer]);

  // Update the condition for enabling keyboard shortcuts
  const isKeyboardShortcutsEnabled = useCallback(() => 
    !isModalOpen &&
    !isNoteTextareaFocused && 
    !isSpreadsheetFocused && 
    !showFeedback &&
    currentView === 'quiz' && 
    quizStarted &&
    !isSelectionPopupVisible, // Add this condition
  [isModalOpen, isNoteTextareaFocused, isSpreadsheetFocused, showFeedback, currentView, quizStarted, isSelectionPopupVisible]);

  // The useKeyPress hooks remain the same, they'll now respect the popup visibility
  useKeyPress('a', () => handleKeyboardAnswer(0), () => isKeyboardShortcutsEnabled() && !showExplanation);
  useKeyPress('b', () => handleKeyboardAnswer(1), () => isKeyboardShortcutsEnabled() && !showExplanation);
  useKeyPress('c', () => handleKeyboardAnswer(2), () => isKeyboardShortcutsEnabled() && !showExplanation);
  useKeyPress('d', () => handleKeyboardAnswer(3), () => isKeyboardShortcutsEnabled() && !showExplanation);

  useKeyPress('Tab', (event?: KeyboardEvent) => {
    if (event) {
      event.preventDefault();
    }
    handleCycleConfidence();
  }, () => isKeyboardShortcutsEnabled() && !showExplanation && selectedAnswer !== null);

  useKeyPress(' ', (event?: KeyboardEvent) => {
    if (event) {
      event.preventDefault();
    }
    handleSpacebarPress();
  }, () => isKeyboardShortcutsEnabled());


  // CATEGORY TABLE
  const renderCategories = () => {
    if (categoriesLoading) {
      return (
        <div className="flex justify-center items-center h-48">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-blue"></div>
        </div>
      );
    }
  
    function hasMessage(error: unknown): error is { message: string } {
      return typeof error === 'object' && error !== null && 'message' in error;
    }
    
    if (categoriesError) {
      return (
        <div className="flex justify-center items-center h-48 text-red-500">
          <div className="text-center">
            <div className="mb-2">⚠️</div>
            <div>{hasMessage(categoriesError) ? categoriesError.message : 'Unknown error'}</div>
          </div>
        </div>
      );
    }
  
    if (!categoriesData || !categoriesData.structuredCategories) {
      return (
        <div className="flex justify-center items-center h-48 text-gray-500">
          No categories available
        </div>
      );
    }
  
    const defaultStats: ExtendedCategoryStats = {
      category_id: '',
      total_questions: 0,
      unanswered_questions: 0,
      correct_percentage: 0,
      incorrect: 0,
      correct: 0,
      confident: 0,
      maybe: 0,
      guessing: 0,
      confident_correct: 0,
      confident_incorrect: 0,
      maybe_correct: 0,
      maybe_incorrect: 0,
      guessing_correct: 0,
      guessing_incorrect: 0
    };
  
    const { statsMap } = categoriesData;
  
    // Calculate global totals from all categories
    const globalTotals = Object.values(statsMap).reduce((acc, stats) => {
      acc.totalQuestions += stats.total_questions || 0;
      acc.unansweredQuestions += stats.unanswered_questions || 0;
      acc.correctAnswers += stats.correct || 0;
      acc.confident += stats.confident || 0;
      acc.maybe += stats.maybe || 0;
      acc.guessing += stats.guessing || 0;
      return acc;
    }, {
      totalQuestions: 0,
      unansweredQuestions: 0,
      correctAnswers: 0,
      confident: 0,
      maybe: 0,
      guessing: 0
    });
  
    // Filter categories logic
    const filteredCategoriesStructure = Object.entries(categoriesStructure).reduce<Record<string, Category[]>>(
      (acc, [parentCategory, subCategories]) => {
        const validSubCategories = subCategories.filter(category => 
          category.sub_question_category_name === 'All' || 
          (category.total_questions && category.total_questions > 0)
        );
  
        if (validSubCategories.length > 1) {
          acc[parentCategory] = validSubCategories;
        }
  
        return acc;
      },
      {}
    );
  
    // Helper function to render stats with icons
    const renderStatWithIcon = (icon: React.ReactNode, value: number | string, label?: string) => (
      <div className="flex items-center gap-1.5 text-sm text-gray-600 dark:text-gray-300">
        {icon}
        <span>{value}</span>
        {label && <span className="text-gray-400 dark:text-gray-500">{label}</span>}
      </div>
    );
  
    const renderConfidenceLevel = (count: number, type: 'confident' | 'maybe' | 'guessing', isMobile: boolean = false) => {
      const getIcon = () => {
        const mobileClasses = "w-3 h-3";
        const desktopClasses = "w-4 h-4";
        const classes = isMobile ? mobileClasses : desktopClasses;
        
        switch(type) {
          case 'confident': return <Sparkle className={classes} />;
          case 'maybe': return <Scale className={classes} />;
          case 'guessing': return <Shuffle className={classes} />;
        }
      };
      
      const getColors = () => {
        switch(type) {
          case 'confident': return 'bg-green-100 dark:bg-green-600/50 text-green-800 dark:text-green-100';
          case 'maybe': return 'bg-yellow-100 dark:bg-yellow-600/50 text-yellow-800 dark:text-yellow-100';
          case 'guessing': return 'bg-red-100/90 text-red-900 dark:bg-red-600/50 dark:text-red-100'; // Adjusted red color
        }
      };
    
      const baseClasses = `${getColors()} rounded font-medium flex items-center`;
      const responsiveClasses = isMobile 
        ? 'px-1.5 py-0.5 text-xs gap-1'
        : 'px-2.5 py-1.5 text-sm gap-1.5';
    
      return (
        <span className={`${baseClasses} ${responsiveClasses}`}>
          {getIcon()}
          <span>{count}</span>
        </span>
      );
    };
  
    if (Object.keys(filteredCategoriesStructure).length === 0) {
      return (
        <div className="text-center p-8 text-gray-500 bg-white dark:bg-gray-800 rounded-lg shadow">
          No categories with questions available
        </div>
      );
    }
  
    return (
      <div className="space-y-3">
        {/* Summary Card - Reduced shadow */}
        <div className="bg-gradient-to-r from-primary-blue to-blue-600 rounded-lg shadow-sm p-4">
          <div className="flex flex-col space-y-4">
            <h3 className="text-white text-lg font-semibold">Quiz Categories Overview</h3>
            
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
              {/* Stats cards with consistent styling */}
              <div className="bg-white/20 rounded-lg p-3">
                <div className="text-white/90 text-sm">Total Questions</div>
                <div className="text-white text-xl sm:text-2xl font-bold flex items-center gap-2">
                  <ListCheck className="w-5 h-5 sm:w-6 sm:h-6" />
                  {globalTotals.totalQuestions}
                </div>
              </div>
              <div className="bg-white/20 rounded-lg p-3">
                <div className="text-white/90 text-sm">Unanswered</div>
                <div className="text-white text-xl sm:text-2xl font-bold flex items-center gap-2">
                  <FileQuestion className="w-5 h-5 sm:w-6 sm:h-6" />
                  {globalTotals.unansweredQuestions}
                </div>
              </div>
              <div className="bg-white/20 rounded-lg p-3">
                <div className="text-white/90 text-sm">Correct %</div>
                <div className="text-white text-xl sm:text-2xl font-bold flex items-center gap-2">
                  <CheckCircle className="w-5 h-5 sm:w-6 sm:h-6" />
                  {((globalTotals.correctAnswers / (globalTotals.totalQuestions - globalTotals.unansweredQuestions)) * 100 || 0).toFixed(1)}%
                </div>
              </div>
              <div className="bg-white/20 rounded-lg p-3">
                <div className="text-white/90 text-sm">Confidence Count</div>
                {/* Mobile view */}
                <div className="flex gap-1 mt-1 sm:hidden">
                  {renderConfidenceLevel(globalTotals.confident, 'confident', true)}
                  {renderConfidenceLevel(globalTotals.maybe, 'maybe', true)}
                  {renderConfidenceLevel(globalTotals.guessing, 'guessing', true)}
                </div>
                {/* Desktop view with slightly reduced size */}
                <div className="hidden sm:flex items-center gap-2 mt-1">
                  {renderConfidenceLevel(globalTotals.confident, 'confident', false)}
                  {renderConfidenceLevel(globalTotals.maybe, 'maybe', false)}
                  {renderConfidenceLevel(globalTotals.guessing, 'guessing', false)}
                </div>
              </div>
            </div>

            {/* Progress Bar Section */}
            <div className="bg-white/20 rounded-lg p-2.5">
              <div className="flex items-center gap-2 mb-2">
                <Target className="w-4 h-4 text-white/90" />
                <span className="text-white/90 text-sm">Quiz Progress</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="flex-grow">
                  <div className="w-full bg-gray-200/30 rounded-full h-2.5">
                    <div 
                      className="bg-sky-200 h-2.5 rounded-full transition-all duration-500"
                      style={{ 
                        width: `${((globalTotals.totalQuestions - globalTotals.unansweredQuestions) / globalTotals.totalQuestions * 100)}%` 
                      }}
                    ></div>
                  </div>
                </div>
                <span className="text-white text-sm font-medium">
                  {((globalTotals.totalQuestions - globalTotals.unansweredQuestions) / globalTotals.totalQuestions * 100).toFixed(1)}%
                </span>
              </div>
            </div>

          </div>
        </div>
  
        {/* Controls Card - Reduced shadow */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4">
          <div className="flex flex-row items-center justify-between gap-4">
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="selectAll"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="w-5 h-5 rounded border-2 border-gray-300 checked:bg-primary-blue checked:border-transparent focus:ring-primary-blue"
                />
                <label htmlFor="selectAll" className="text-gray-700 dark:text-gray-200 font-medium cursor-pointer whitespace-nowrap">
                  {selectAll ? 'Deselect All' : 'Select All'}
                </label>
              </div>
            </div>
            <button
              onClick={() => handleExpandAll(!isAllExpanded)}
              className="px-3 py-1.5 bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 rounded-lg transition-colors whitespace-nowrap"
            >
              {isAllExpanded ? 'Collapse All' : 'Expand All'}
            </button>
          </div>
        </div>
  
        {/* Categories List */}
        <div className="space-y-2">
          {Object.entries(filteredCategoriesStructure).map(([parentCategory, subCategories]) => {
            const parentStats = subCategories.reduce((acc, category) => {
              if (category.sub_question_category_name !== 'All') {
                const stats = statsMap[category.id] || { ...defaultStats, category_id: category.id };
                acc.total += stats.total_questions;
                acc.unanswered += stats.unanswered_questions;
                acc.confident += stats.confident;
                acc.maybe += stats.maybe;
                acc.guessing += stats.guessing;
                acc.correct += stats.correct;
                acc.incorrect += stats.incorrect;
              }
              return acc;
            }, { 
              total: 0, 
              unanswered: 0, 
              confident: 0, 
              maybe: 0, 
              guessing: 0, 
              correct: 0, 
              incorrect: 0 
            });

            const parentCorrectPercentage = parentStats.total - parentStats.unanswered > 0
              ? (parentStats.correct / (parentStats.total - parentStats.unanswered)) * 100
              : 0;

            const isParentSelected = subCategories
              .filter(cat => cat.sub_question_category_name !== 'All')
              .every(cat => selectedCategories.includes(cat.id));

            return (
              <div 
                key={parentCategory} 
                className={`
                  overflow-hidden bg-white dark:bg-gray-800 rounded-lg shadow-sm border
                  ${isParentSelected 
                    ? 'border-primary-blue dark:border-primary-blue/50' 
                    : 'border-gray-200 dark:border-gray-700'
                  }
                `}
              >
                {/* Parent Category Header */}
                <div 
                  className={`
                    p-4 cursor-pointer transition-all duration-200
                    ${isParentSelected 
                      ? 'bg-blue-50 hover:bg-blue-100 dark:bg-blue-950/50 dark:hover:bg-blue-900/50' 
                      : 'bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700/50'
                    }
                  `}
                  onClick={() => handleCategoryClick(parentCategory)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4 flex-grow">
                      <div className="shrink-0 w-5">
                        <input
                          type="checkbox"
                          checked={isParentSelected}
                          onChange={() => handleCategorySelection(parentCategory, true)}
                          className="w-5 h-5 rounded border-gray-300 text-primary-blue focus:ring-primary-blue"
                          onClick={e => e.stopPropagation()}
                        />
                      </div>
                      <div className="flex-grow min-w-0">
                        <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white">
                          {parentCategory}
                        </h3>
                        <div className="flex flex-wrap items-center gap-3 mt-2">
                          {renderStatWithIcon(<ListCheck className="w-4 h-4" />, parentStats.total)}
                          {renderStatWithIcon(<FileQuestion className="w-4 h-4" />, parentStats.unanswered)}
                          {renderStatWithIcon(<CheckCircle className="w-4 h-4" />, `${parentCorrectPercentage.toFixed(1)}%`)}
                          <div className="flex gap-1 sm:hidden">
                            {renderConfidenceLevel(parentStats.confident, 'confident', true)}
                            {renderConfidenceLevel(parentStats.maybe, 'maybe', true)}
                            {renderConfidenceLevel(parentStats.guessing, 'guessing', true)}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="flex items-center ml-auto">
                      <div className="hidden sm:flex items-center gap-1.5 mr-4">
                        {renderConfidenceLevel(parentStats.confident, 'confident')}
                        {renderConfidenceLevel(parentStats.maybe, 'maybe')}
                        {renderConfidenceLevel(parentStats.guessing, 'guessing')}
                      </div>
                      <ChevronRight 
                        className={`w-5 h-5 transition-transform duration-200 ${
                          isCategoryExpanded(parentCategory) ? 'rotate-90' : ''
                        }`} 
                      />
                    </div>
                  </div>
                </div>

                {/* Subcategories */}
                {isCategoryExpanded(parentCategory) && (
                  <div className="border-t border-gray-200 dark:border-gray-700">
                    {subCategories
                      .filter(category => 
                        category.sub_question_category_name !== 'All' && 
                        category.total_questions > 0
                      )
                      .map((category, index, filteredArray) => {
                        const stats = statsMap[category.id] || { ...defaultStats, category_id: category.id };
                        const isSelected = selectedCategories.includes(category.id);
                        const isLast = index === filteredArray.length - 1;

                        return (
                          <div 
                            key={category.id}
                            className={`
                              p-4 pl-12 transition-all duration-200
                              ${isSelected 
                                ? 'bg-blue-50 hover:bg-blue-100 dark:bg-blue-950/50 dark:hover:bg-blue-900/50' 
                                : 'bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700/50'
                              }
                              ${!isLast ? 'border-b border-gray-200 dark:border-gray-700' : ''}
                            `}
                          >
                            <div className="flex flex-col sm:flex-row sm:items-center gap-4">
                              <div className="flex items-center gap-4 flex-grow">
                                <div className="shrink-0 w-5">
                                  <input
                                    type="checkbox"
                                    checked={isSelected}
                                    onChange={() => handleCategorySelection(category.id, false)}
                                    className="w-5 h-5 rounded border-gray-300 text-primary-blue focus:ring-primary-blue"
                                  />
                                </div>
                                <div className="min-w-0">
                                  <h4 className="font-medium text-gray-700 dark:text-gray-100 truncate">
                                    {category.sub_question_category_name}
                                  </h4>
                                  <div className="flex flex-wrap items-center gap-3 mt-1.5">
                                    {renderStatWithIcon(<ListCheck className="w-4 h-4" />, stats.total_questions)}
                                    {renderStatWithIcon(<FileQuestion className="w-4 h-4" />, stats.unanswered_questions)}
                                    {renderStatWithIcon(<CheckCircle className="w-4 h-4" />, `${stats.correct_percentage.toFixed(1)}%`)}
                                    <div className="flex gap-1 sm:hidden">
                                      {renderConfidenceLevel(stats.confident, 'confident', true)}
                                      {renderConfidenceLevel(stats.maybe, 'maybe', true)}
                                      {renderConfidenceLevel(stats.guessing, 'guessing', true)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                              <div className="hidden sm:flex items-center ml-auto gap-1.5">
                                {renderConfidenceLevel(stats.confident, 'confident')}
                                {renderConfidenceLevel(stats.maybe, 'maybe')}
                                {renderConfidenceLevel(stats.guessing, 'guessing')}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        </div>
      );
    };
    
const renderHTML = (html: string) => {
  return { __html: DOMPurify.sanitize(html) };
};

const renderQuestion = () => {
  if (!questions || questions.length === 0 || currentQuestion >= questions.length) {
    return <div>No questions available.</div>;
  }

  const question = questions[currentQuestion];
  if (!question) {
    return <div>Question not found.</div>;
  }

  // Check if this question should be locked
  if (isContentLocked(question)) {
    return (
      <div className="bg-white dark:bg-dark-secondaryLight p-4 rounded shadow">
        <div className="text-center py-8">
          <Lock className="w-12 h-12 mx-auto mb-4 text-gray-400" />
          <h3 className="text-xl font-semibold mb-2">Premium Content</h3>
          <p className="text-gray-600 dark:text-gray-400 mb-4">
            Upgrade your account to access this question and our complete question bank.
          </p>
          <Button
            onClick={handleUpgradeClick}
            className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center justify-center mx-auto"
          >
            <Crown className="w-5 h-5 mr-2" />
            Upgrade Now
          </Button>
        </div>
      </div>
    );
  }

  // Rest of the existing renderQuestion logic...
  const { questionText, tableHTML, options, romanList, isTableQuestion } = parseQuestionContent(question.question_content);
  const isLastQuestion = currentQuestion === questions.length - 1;

  const triggerEndTestlet = () => {
    setShowEndTestletModal(true);
  };

  // Helper function to get color class based on confidence level
  const getColorClass = (level: string) => {
    switch(level) {
      case 'guessing': return 'bg-red-500 hover:bg-red-600 dark:bg-red-700 dark:hover:bg-red-600';
      case 'maybe': return 'bg-yellow-500 hover:bg-yellow-600 dark:bg-yellow-700 dark:hover:bg-yellow-600';
      case 'confident': return 'bg-green-500 hover:bg-green-600 dark:bg-green-700 dark:hover:bg-green-600';
      default: return 'bg-gray-400 hover:bg-gray-500 dark:bg-gray-600 dark:hover:bg-gray-500';
    }
  };

  return (
    <>
      <div className="p-4">
        {/* Top toolbar section - mobile */}
        <div className="flex justify-between items-center mb-4 sm:hidden">
          <TestModeToggle 
            isTestMode={isTestMode} 
            onToggle={setIsTestMode} 
            disabled={showExplanation || (mockExamTestletId != null)}
          />
          <Button
            onClick={() => setShowMobileTools(!showMobileTools)}
            className="btn btn-primary flex items-center justify-center"
          >
            <Wrench className="w-5 h-5 mr-2" />
            <span>Tools</span>
          </Button>
          <Button
            onClick={triggerEndTestlet}
            className="btn bg-red-500 hover:bg-red-600 text-white font-bold flex items-center justify-center w-10 h-10"
          >
            <X className="w-5 h-5" />
          </Button>
        </div>

        {/* Top toolbar section - desktop */}
        <div className="hidden sm:flex sm:flex-row justify-between items-center mb-4 pb-4 border-b border-gray-300 dark:border-gray-700">
          <div>
            <TestModeToggle 
              isTestMode={isTestMode} 
              onToggle={(checked) => !mockExamTestletId && setIsTestMode(checked)}
              disabled={showExplanation || (mockExamTestletId != null)}
            />
          </div>
          <div className="flex space-x-2">
            <Button onClick={toggleCalculator} className="btn btn-primary flex items-center justify-center">
              <CalculatorIcon className="w-5 h-5 mr-2" />
              <span>Calculator</span>
            </Button>
            <Button onClick={toggleSpreadsheet} className="btn btn-primary flex items-center justify-center">
              <Table className="w-5 h-5 mr-2" />
              <span>Sheet</span>
            </Button>
            <Button onClick={toggleFeedback} className="btn btn-primary flex items-center justify-center">
              <HelpCircle className="w-5 h-5 mr-2" />
              <span>Feedback</span>
            </Button>
            <Button onClick={triggerEndTestlet} className="btn btn-orange hover:bg-orange-600">
              End Testlet
            </Button>
          </div>
        </div>

        {/* Mobile tools dropdown */}
        {showMobileTools && (
          <div className="sm:hidden flex flex-col space-y-2 mb-4">
            <Button onClick={toggleCalculator} className="btn btn-primary flex items-center justify-center">
              <CalculatorIcon className="w-5 h-5 mr-2" />
              <span>Calculator</span>
            </Button>
            <Button onClick={toggleSpreadsheet} className="btn btn-primary flex items-center justify-center">
              <Table className="w-5 h-5 mr-2" />
              <span>Sheet</span>
            </Button>
            <Button onClick={toggleFeedback} className="btn btn-primary flex items-center justify-center">
              <HelpCircle className="w-5 h-5 mr-2" />
              <span>Feedback</span>
            </Button>
          </div>
        )}

        {/* Question content */}
        <div className="quiz-content mb-4 text-gray-900 dark:text-dark-text" 
             dangerouslySetInnerHTML={renderHTML(questionText)} />
        
        {/* Question options */}
        {isTableQuestion ? (
          <div 
            className="quiz-content text-gray-900 dark:text-dark-text" 
            dangerouslySetInnerHTML={renderHTML(tableHTML)} 
            onClick={handleTableClick}
          />
        ) : (
          <div className="space-y-2">
          {options.map((option, index) => {
            const optionLetter = String.fromCharCode(65 + index);
            const isSelected = selectedAnswer === optionLetter;
            const hasConfidence = !!confidenceLevel;

            let buttonClasses = [
              "answer-option",
              "w-full",
              "text-left",
              "px-4",
              "py-2",
              "rounded",
              "transition-colors",
              "duration-300",
              "text-gray-900",
              "dark:text-white",
              "bg-gray-50",
              // Only add hover states when not showing explanation
              !showExplanation && "hover:bg-gray-100 dark:hover:bg-gray-600",
              "dark:bg-gray-700",
            ];

            if (isSelected && !showExplanation) {
              buttonClasses.push(
                "selected", 
                "bg-blue-400", 
                "text-white", 
                "dark:bg-orange-600",
                "border-transparent",
                hasConfidence ? "confidence-selected" : "no-confidence-selected"
              );
            }

            if (showExplanation) {
              if (optionLetter === question.correct_answer) {
                buttonClasses.push(
                  "correct",
                  "bg-green-500",
                  "text-white",
                  "dark:bg-green-700",
                  "border-transparent"
                );
              } else if (isSelected) {
                buttonClasses.push(
                  "incorrect",
                  "bg-red-500",
                  "text-white",
                  "dark:bg-red-700",
                  "border-transparent"
                );
              }
            }

            return (
              <button
                key={index}
                onClick={() => handleAnswer(optionLetter)}
                disabled={showExplanation}
                className={buttonClasses.filter(Boolean).join(" ")}
              >
                {option}
              </button>
            );
          })}
          </div>
        )}
        {!showExplanation && (
          <div className="mt-4 sm:mt-8 bg-gray-50 dark:bg-gray-800 rounded-lg shadow-md p-4 sm:p-6">
            <div className="space-y-4 sm:space-y-6">
              <p className="text-base sm:text-lg font-semibold text-gray-900 dark:text-gray-200">
                {selectedAnswer 
                  ? "How confident are you in your answer?" 
                  : "Select an answer, then rate your confidence"}
              </p>
              <div className="flex flex-col sm:grid sm:grid-cols-3 gap-2 sm:gap-4">
                {confidenceLevels.map((level) => {
                  const isLastConfidenceLevel = lastConfidenceLevelForCurrentQuestion === level;
                  const isSelected = confidenceLevel === level;
                  const confidenceColors = getConfidenceColors(level as 'confident' | 'maybe' | 'guessing', isSelected);

                  return (
                    <div key={level} className="relative">
                      <button
                        onClick={() => handleConfidenceSelection(level as ConfidenceLevel)}
                        disabled={!selectedAnswer}
                        className={`
                          w-full py-3 px-4 rounded-lg font-medium transition-all duration-200 ease-in-out
                          flex items-center justify-center gap-2
                          ${isSelected 
                            ? `${confidenceColors} text-white border border-transparent`
                            : 'bg-white text-gray-800 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600 border border-gray-300 dark:border-gray-600'}
                          ${!selectedAnswer && 'opacity-50 cursor-not-allowed'}
                          ${isSelected && 'ring-2 ring-offset-2 ring-offset-gray-100 dark:ring-offset-gray-800 ring-blue-500'}
                        `}
                      >
                        {getConfidenceIcon(level as 'confident' | 'maybe' | 'guessing', false)}
                        <span className={isLastConfidenceLevel && !isSelected ? 'opacity-75' : ''}>
                          {level.charAt(0).toUpperCase() + level.slice(1)}
                        </span>
                      </button>
                      {isLastConfidenceLevel && !isSelected && (
                        <div className="absolute -top-2 -right-2">
                          <span className="bg-blue-500 text-white text-xs px-1.5 py-0.5 rounded-full">
                            Previous
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <button
                onClick={handleSubmitAnswer}
                disabled={!selectedAnswer || !confidenceLevel || isSubmitting}
                className={`
                  w-full py-3 px-6 rounded-lg text-white font-semibold transition-all duration-200 ease-in-out relative
                  ${selectedAnswer && confidenceLevel && !isSubmitting
                    ? 'bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700'
                    : 'bg-gray-300 dark:bg-gray-600 text-gray-500 dark:text-gray-400 cursor-not-allowed'}
                `}
              >
                {isSubmitting ? (
                  <span className="absolute inset-0 flex items-center justify-center">
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </span>
                ) : (
                  <span>Submit Answer</span>
                )}
              </button>
            </div>
          </div>
        )}
        {/* Explanation section */}
        {!isTestMode && showExplanation && (
          <div className="explanation mt-8 mb-8">
            <div className="grid grid-cols-1 gap-6 mb-8">
              {/* Status Card - removed overflow-hidden */}
              <div className={`relative rounded-xl shadow-sm border ${
                userAnswers[question.id] === question.correct_answer 
                  ? 'bg-green-50/90 dark:bg-gray-800 border-green-100 dark:border-gray-700' 
                  : 'bg-red-50/90 dark:bg-gray-800 border-red-100 dark:border-gray-700'
              }`}>
                <div className="p-4">
                  <div className="flex items-center justify-between">
                    {/* Left side: Status Icon and Text */}
                    <div className="flex items-center gap-4">
                      <div className={`flex items-center justify-center w-12 h-12 rounded-xl ${
                        userAnswers[question.id] === question.correct_answer 
                          ? 'bg-green-100 dark:bg-green-900/20' 
                          : 'bg-red-100 dark:bg-red-900/20'
                      }`}>
                        {userAnswers[question.id] === question.correct_answer ? (
                          <CheckCircle className="w-7 h-7 text-green-600 dark:text-green-400" />
                        ) : (
                          <X className="w-7 h-7 text-red-600 dark:text-red-400" />
                        )}
                      </div>

                      <h3 className={`text-lg font-semibold ${
                        userAnswers[question.id] === question.correct_answer 
                          ? 'text-green-700 dark:text-green-400' 
                          : 'text-red-700 dark:text-red-400'
                      }`}>
                        {userAnswers[question.id] === question.correct_answer 
                          ? `${question.correct_answer} is correct!` 
                          : `${userAnswers[question.id]} is incorrect, should be ${question.correct_answer}`
                        }
                      </h3>
                    </div>

                    {/* Right side: Confidence Level and Next Button */}
                    <div className="flex items-center gap-3">
                      {quizSummary.length > 0 && quizSummary[quizSummary.length - 1].confidenceLevel && (
                        <div className="relative shrink-0">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsEditingConfidence(!isEditingConfidence);
                            }}
                            className={`px-3 py-1.5 rounded-lg text-white text-sm flex items-center gap-2 transition-colors ${
                              quizSummary[quizSummary.length - 1].confidenceLevel === 'confident' 
                                ? 'bg-green-500 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700' 
                                : quizSummary[quizSummary.length - 1].confidenceLevel === 'maybe'
                                ? 'bg-yellow-500 hover:bg-yellow-600 dark:bg-yellow-600 dark:hover:bg-yellow-700'
                                : 'bg-red-500 hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700'
                            }`}
                          >
                            {/* Confidence Icon */}
                            {quizSummary[quizSummary.length - 1].confidenceLevel === 'confident' && <Sparkle className="w-4 h-4" />}
                            {quizSummary[quizSummary.length - 1].confidenceLevel === 'maybe' && <Scale className="w-4 h-4" />}
                            {quizSummary[quizSummary.length - 1].confidenceLevel === 'guessing' && <Shuffle className="w-4 h-4" />}
                            
                            {/* Hide text on mobile, show only on larger screens */}
                            <span className="capitalize hidden sm:inline">
                              {quizSummary[quizSummary.length - 1].confidenceLevel}
                            </span>
                            <Pencil className="w-3 h-3 opacity-75" />
                          </button>

                          {/* Confidence Level Dropdown with click outside handling */}
                          {isEditingConfidence && (
                            <>
                              <div 
                                className="fixed inset-0 z-40"
                                onClick={() => setIsEditingConfidence(false)}
                              />
                              <div className="absolute top-full right-0 mt-1 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 py-0.5 min-w-[140px] z-50">
                                {(['confident', 'maybe', 'guessing'] as const).map((level) => (
                                  <button
                                    key={level}
                                    onClick={() => {
                                      handleConfidenceLevelUpdate(level);
                                      setIsEditingConfidence(false);
                                    }}
                                    className="w-full px-3 py-1.5 text-left text-sm flex items-center gap-2 hover:bg-gray-50 dark:hover:bg-gray-700/50 text-gray-700 dark:text-gray-200"
                                  >
                                    {level === 'confident' && <Sparkle className="w-4 h-4" />}
                                    {level === 'maybe' && <Scale className="w-4 h-4" />}
                                    {level === 'guessing' && <Shuffle className="w-4 h-4" />}
                                    <span className="capitalize">{level}</span>
                                  </button>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      )}

                      {/* Next/Finish Question Button */}
                      <button
                        onClick={isLastQuestion ? handleFinishQuiz : handleNextQuestion}
                        className={`
                          h-9 flex items-center justify-center rounded-lg text-white 
                          transition-all duration-200 ease-in-out relative gap-2
                          w-9 sm:w-auto sm:px-4 sm:py-2 font-semibold
                          ${isFinishing || isLoadingNextQuestion
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700'}
                        `}
                        disabled={isFinishing || isLoadingNextQuestion}
                      >
                        {(isFinishing || isLoadingNextQuestion) ? (
                          <span className="absolute inset-0 flex items-center justify-center">
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                          </span>
                        ) : (
                          <>
                            <span className="hidden sm:inline">
                              {isLastQuestion ? 'Finish Quiz' : 'Next Question'}
                            </span>
                            {isLastQuestion ? (
                              <CheckCircle className="h-5 w-5" />
                            ) : (
                              <ChevronRight className="h-5 w-5" />
                            )}
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution Card - existing code remains the same */}
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-md border border-gray-100 dark:border-gray-700 overflow-hidden">
              <div className="bg-gradient-to-r from-[var(--color-primary-blue)] to-[var(--color-primary-blue-hover)] dark:from-[var(--color-primary-blue)] dark:to-[var(--color-primary-blue-hover)] px-5 py-4">
                <h3 className="text-lg font-semibold text-white flex items-center">
                  <BookOpen className="w-5 h-5 mr-2" />
                  Detailed Explanation
                </h3>
              </div>

              <div className="px-5 py-4">
                {/* Main Explanation Content */}
                <div className="prose prose-blue dark:prose-invert max-w-none">
                  <div 
                    className="quiz-content text-gray-700 dark:text-gray-200 [&>p]:mb-4 [&>ul]:mb-4 [&>ul]:pl-4 [&>ul>li]:mb-2" 
                    dangerouslySetInnerHTML={renderHTML(question.answer_content)} 
                  />
                </div>

                {/* Key Points Section (if present in content) */}
                {question.answer_content.includes('Key Point') && (
                  <div className="mt-6 bg-blue-50 dark:bg-blue-900/20 rounded-lg p-4">
                    <h4 className="text-blue-700 dark:text-blue-400 font-semibold flex items-center mb-3">
                      <LightbulbIcon className="w-5 h-5 mr-2" />
                      Key Points to Remember
                    </h4>
                    <div className="text-blue-600 dark:text-blue-300 space-y-2">
                      {/* Key points would be extracted and rendered here */}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Navigation Button */}
            <div className="mt-6">
              {renderQuizCompletion()}
            </div>
          </div>
        )}
      </div>

      {/* Notes section */}
      <div className="mt-4">
        {renderNoteSection()}
      </div>
      
      {/* Modals and popups */}
      <EndTestlet
        isOpen={showEndTestletModal}
        onClose={() => setShowEndTestletModal(false)}
        onEndTestlet={handleEndTestlet}
        handleFinishQuiz={
          mockExamTestletId && providedQuizHistoryId 
            ? handleFinishQuizMockExam 
            : studyTaskTestletId 
              ? handleFinishQuizStudyTask
              : handleFinishQuiz
        }
        setIsLastQuestion={setIsLastQuestion}
        updateQuizHistory={async () => {}}
        isLastQuestion={isLastQuestion}
        questionsAnswered={currentQuestion + 1}
        totalQuestions={questions.length}
        isViewingExplanation={showExplanation}
        courseId={courseId}
        quizId={currentQuizHistoryId || ''}
      />
      <Calculator 
        isOpen={showCalculator} 
        onClose={() => setShowCalculator(false)}
      />
      <Spreadsheet 
        isOpen={showSpreadsheet} 
        onClose={() => setShowSpreadsheet(false)}
        onFocusChange={setIsSpreadsheetFocused}
      />
      <FeedbackPopup 
        isOpen={showFeedback} 
        onClose={toggleFeedback}
        contentId={question.id}
        contentType="questions"
        contentTitle={question.question_content?.slice(0, 50)} // Optional: adds context to the ticket
      />
    </>
  );
};

  const handleTableClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    const cell = target.closest('td');
    if (cell && cell.querySelector('.multi-choice')) {
      const multiChoice = cell.querySelector('.multi-choice');
      if (multiChoice) {
        const choice = multiChoice.getAttribute('data-choice');
        if (choice) {
          handleAnswer(choice);
          // Remove 'selected' class from all cells
          cell.closest('table')?.querySelectorAll('td').forEach(td => td.classList.remove('selected'));
          // Add 'selected' class to the clicked cell
          cell.classList.add('selected');
        }
      }
    }
  };

  const handleResetCategories = () => {
    if (selectedCategories.length > 0 && !isResettingCategories) {
      setShowResetPopup(true);
    }
  };

  const handleConfirmReset = async () => {
    setIsResettingCategories(true);
    try {
      await resetCategories.mutateAsync({ categoryIds: selectedCategories });
      
      // Clear local quiz state
      setQuestions([]);
      setCurrentQuestion(0);
      setUserAnswers({});
      setQuizCompleted(false);
      setQuizSummary([]);
      setSelectedCategories([]);
    } catch (err) {
      console.error('Failed to reset categories:', err);
      setError(`Failed to reset categories. Please try again. Error: ${err instanceof Error ? err.message : JSON.stringify(err)}`);
    } finally {
      setIsResettingCategories(false);
      setShowResetPopup(false);
    }
  };

const handleNavigateToAnalytics = () => {
  navigate(`/course/${courseId}/analytics`);
};

// Update handleCloseSummary
const handleCloseSummary = useCallback(() => {
  // Reset all necessary states
  setCurrentView('categories');
  setQuizStarted(false);
  setQuizCompleted(false);
  setViewingCompletedQuiz(false);
  setShowSummary(false);
  setSelectedCategories([]);
  setQuestions([]);
  setCurrentQuestion(0);
  setUserAnswers({});
  setQuizSummary([]);
  
  // Reset states related to the current question
  setSelectedAnswer(null);
  setShowExplanation(false);
  setConfidenceLevel(null);
  setQuestionStartTime(null);
  setElapsedTime(0);

  // Reset timer-related states and refs
  setQuizStartTime(null);
  quizStartTimeRef.current = null;
  setPausedTime(0);
  pausedTimeRef.current = 0;
  setIsPaused(false);
  if (timerRef.current) {
    clearInterval(timerRef.current);
    timerRef.current = null;
  }
  
  // Reset note-related states
  setNote('');
  setCurrentNote(null);
  setShowNoteEditor(false);
  setNoteContent('');
  setIsEditingNote(false);
  
  // Clear the active quiz reference and state
  activeQuizRef.current = null;
  resetActiveQuizCheck(); // Use the hook's reset function
  
  // Reset any other state variables that might affect the quiz state
  setIsSubmitting(false);
  setIsFinishing(false);
  setIsLoadingNextQuestion(false);
  
  // Force a re-render by updating the URL
  navigate(`/course/${courseId}/quiz`, { replace: true });
  
  // Optionally, you can add a small delay before navigating to ensure all state updates have been processed
  setTimeout(() => {
    navigate(`/course/${courseId}/quiz`, { replace: true });
  }, 0);
}, [courseId, navigate, resetActiveQuizCheck]); // Add resetActiveQuizCheck to dependencies

const capitalizeFirstLetter = (string: string): string => {
return string.charAt(0).toUpperCase() + string.slice(1);
};



  const renderNoteSection = () => {
    // Don't render notes section if this is a mock exam
    if (mockExamTestletId) {
      return null;
    }

    if (!questions.length || currentQuestion >= questions.length) {
      return null;
    }

    const currentQuestionData = questions[currentQuestion];
    // Add explicit null check for the note
    const hasNote = currentQuestionData?.note?.id != null;

      return (
        <div className="space-y-6 mt-4">
            <div className="bg-light-background dark:bg-dark-secondaryLight p-4 rounded shadow">
                <h3 className="text-xl font-bold mb-4 text-light-text dark:text-dark-text">
                    {hasNote ? (isEditingNote ? 'Edit Note' : 'Your Note') : 'Create New Note'}
                </h3>
                {(!hasNote || isEditingNote) ? (
                    <>
                        <textarea
                            placeholder="Note Content"
                            value={noteContent}
                            onChange={(e) => {
                                const sanitizedContent = sanitizeInput(e.target.value);
                                setNoteContent(sanitizedContent.slice(0, MAX_NOTE_LENGTH));
                            }}
                            onFocus={() => setIsNoteTextareaFocused(true)}
                            onBlur={() => setIsNoteTextareaFocused(false)}
                            className="w-full p-2 mb-2 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text"
                            rows={3}
                            maxLength={MAX_NOTE_LENGTH}
                        />
                        <p className="text-sm text-gray-500 mb-2">
                            {noteContent.length}/{MAX_NOTE_LENGTH} characters
                        </p>
                        <div className="space-x-2">
                            <button
                                onClick={handleUpdateNote}
                                className="bg-light-primary dark:bg-dark-primary text-white px-4 py-2 rounded hover:bg-light-accent dark:hover:bg-dark-accent"
                            >
                                {hasNote ? 'Save Edit' : 'Create Note'}
                            </button>
                            {isEditingNote && hasNote && (
                                <button
                                    onClick={() => {
                                        setIsEditingNote(false);
                                        setNoteContent(currentQuestionData.note?.content || '');
                                    }}
                                    className="bg-gray-500 dark:bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-600 dark:hover:bg-gray-700"
                                >
                                    Cancel
                                </button>
                            )}
                        </div>
                    </>
                ) : hasNote ? (
                    <>
                        <p 
                            className="mt-2 text-light-text dark:text-dark-text" 
                            dangerouslySetInnerHTML={{ 
                                __html: DOMPurify.sanitize(currentQuestionData.note?.content || '') 
                            }}
                        />
                        <div className="mt-4 space-x-2">
                            <button
                                onClick={() => {
                                    setIsEditingNote(true);
                                    setNoteContent(currentQuestionData.note?.content || '');
                                }}
                                className="bg-yellow-500 dark:bg-yellow-600 text-white px-4 py-2 rounded hover:bg-yellow-600 dark:hover:bg-yellow-700"
                            >
                                Edit
                            </button>
                            <button
                                onClick={handleClearNote}
                                className="bg-red-500 dark:bg-red-600 text-white px-4 py-2 rounded hover:bg-red-600 dark:hover:bg-red-700"
                            >
                                Clear
                            </button>
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
  };

  // Add end of quiz upgrade modal component
  const renderEndQuizUpgrade = () => {
    if (!showEndQuizUpgrade) return null;

    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-lg w-full mx-auto">
          <div className="flex justify-end p-2">
            <button
              onClick={() => {
                setShowEndQuizUpgrade(false);
                setQuizCompleted(true);
                setShowUpgradeModal(false);
              }}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-full p-1 
                         hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
            >
              <X size={24} />
            </button>
          </div>
          
          <div className="px-6 pb-6">
            <div className="text-center mb-6">
              <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
                Ready to Unlock Full Access?
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Great job completing the quiz! Upgrade now to access our complete question bank
                and boost your CPA exam preparation.
              </p>
              
              <div className="space-y-3">
                <Button
                  onClick={() => {
                    setShowEndQuizUpgrade(false);
                    setShowUpgradeModal(true);
                  }}
                  className="w-full bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center 
                           justify-center py-3 rounded-lg transition-colors"
                >
                  <Crown size={20} className="mr-2" />
                  Upgrade Now
                </Button>
                
                <Button
                  onClick={() => {
                    setShowEndQuizUpgrade(false);
                    setQuizCompleted(true);
                  }}
                  className="w-full bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 
                           text-gray-700 dark:text-gray-200 py-3 rounded-lg transition-colors"
                >
                  Maybe Later
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Update renderQuizCompletion to be more explicit about conditions
const renderQuizCompletion = () => {
  console.log('Rendering quiz completion:', {
    isStudyTask,
    isLastQuestion,
    currentQuestion,
    totalQuestions: questions.length
  });

  if (isStudyTask && (isLastQuestion || currentQuestion === questions.length - 1)) {
    return (
      <button
        onClick={handleFinishQuiz}
        className={`
          w-full py-3 px-6 rounded-lg text-white font-semibold text-base sm:text-lg
          transition-all duration-200 ease-in-out relative
          flex items-center justify-center
          ${isFinishing ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700'}
        `}
        disabled={isFinishing}
      >
        {isFinishing ? (
          <span className="absolute inset-0 flex items-center justify-center">
            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </span>
        ) : (
          <>
            <span>Back to Study Task</span>
            <ArrowLeft className="ml-2 h-5 w-5" />
          </>
        )}
      </button>
    );
  }

    // Default button for other cases
    return (
      <button
        onClick={handleNextQuestion}
        className={`
          w-full py-3 px-6 rounded-lg text-white font-semibold text-base sm:text-lg
          transition-all duration-200 ease-in-out relative
          flex items-center justify-center
          ${isFinishing || isLoadingNextQuestion
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700'}
        `}
        disabled={isFinishing || isLoadingNextQuestion}
      >
        {(isFinishing || isLoadingNextQuestion) ? (
          <span className="absolute inset-0 flex items-center justify-center">
            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </span>
        ) : (
          <>
            <span>{isLastQuestion ? 'Finish Quiz' : 'Next Question'}</span>
            {isLastQuestion ? (
              <CheckCircle className="ml-2 h-5 w-5" />
            ) : (
              <ChevronRight className="ml-2 h-5 w-5" />
            )}
          </>
        )}
      </button>
    );
  };

const renderQuizSummary = ({ onClose }: { onClose: () => void }) => {
  const renderHTML = (html: string) => ({ __html: DOMPurify.sanitize(html) });
    
  // Remove this block and replace with a comment:
  // Loading screen is now handled in the main render method
  
  if (error) return <div className="text-red-500 dark:text-red-400">{error}</div>;

  if (isFinishing) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-center">
          <div className="spinner border-light-primary dark:border-dark-primary"></div>
          <p className="mt-4 text-light-text dark:text-dark-text">Finishing quiz...</p>
        </div>
      </div>
    );
  }

  const answeredQuestions = quizSummary.filter(item => item.userAnswer !== null);
  const totalTime = answeredQuestions.reduce((sum, item) => sum + (item.timeSpent || 0), 0);
  const averageTime = answeredQuestions.length > 0 ? totalTime / answeredQuestions.length : 0;

  const filteredItems = answeredQuestions.filter(item => {
    if (summaryFilter === 'all') return true;
    if (summaryFilter === 'correct') return item.isCorrect;
    if (summaryFilter === 'incorrect') return !item.isCorrect;
    return item.confidenceLevel.toLowerCase() === summaryFilter;
  });

  // Function to toggle note editing state
  const toggleNoteEditing = (index: number) => {
    setEditingNotes(prev => ({ ...prev, [index]: !prev[index] }));
    if (!editingNotes[index]) {
      // If we're starting to edit, initialize the note content
      setNoteContents(prev => ({ ...prev, [index]: filteredItems[index].note?.content || '' }));
    }
  };

  // Updated function to handle saving notes
  const handleSaveSummaryNote = async (index: number) => {
    if (!currentUser) return;
    const item = filteredItems[index];
    try {
      const updatedNote = await api.updateNote(
        item.note?.id || null,
        { content: noteContents[index] || '' },
        currentUser.id,
        courseId,
        item.questionId,
        null,
        null,
        null
      );
      
      // Update the quizSummary state with the new note
      setQuizSummary(prevSummary => 
        prevSummary.map(summaryItem => 
          summaryItem.questionId === item.questionId 
            ? { ...summaryItem, note: updatedNote } 
            : summaryItem
        )
      );

      // Reset the editing state for this note
      toggleNoteEditing(index);

      await api.logUserActivity({
        user_id: currentUser.id,
        course_id: courseId,
        activity_type: item.note ? 'update_note' : 'create_note',
        activity_id: updatedNote.id,
        result: 'success'
      });
    } catch (err) {
      console.error('Failed to save note:', err);
      setError('Failed to save note. Please try again.');
    }
  };

  // Updated function to handle clearing notes
  const handleClearSummaryNote = async (index: number) => {
    const item = filteredItems[index];
    if (item.note) {
      try {
        await api.deleteNote(item.note.id);
        
        // Update the quizSummary state to remove the note
        setQuizSummary(prevSummary => 
          prevSummary.map(summaryItem => 
            summaryItem.questionId === item.questionId 
              ? { ...summaryItem, note: null } 
              : summaryItem
          )
        );

        // Reset the note content for this index
        setNoteContents(prev => {
          const newContents = { ...prev };
          delete newContents[index];
          return newContents;
        });

      } catch (err) {
        console.error('Failed to clear note:', err);
        setError('Failed to clear note. Please try again.');
      }
    }
  };

  return (
    <div className="space-y-4 md:space-y-8 max-w-[1000px] mx-auto">
      <h2 className="text-xl md:text-2xl font-bold text-light-text dark:text-dark-text">Quiz Summary</h2>
      
       {/* Quiz statistics and action buttons */}
       <div className="bg-light-background dark:bg-dark-secondaryLight p-4 rounded-lg shadow">
          <div className="flex flex-col space-y-4 md:flex-row md:justify-between md:items-center">
            <div className="space-y-2 md:space-y-1">
              <p className="text-sm md:text-base text-light-text dark:text-dark-text">
                <span className="font-semibold">Total Time:</span> {formatTime(totalTime)}
              </p>
              <p className="text-sm md:text-base text-light-text dark:text-dark-text">
                <span className="font-semibold">Avg Time/Question:</span> {formatTime(averageTime)}
              </p>
            </div>
            <div className="flex flex-col sm:flex-row gap-2">
              {showUpgradeButton && (
                <Button
                  onClick={handleUpgradeClick}
                  className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center 
                           justify-center px-4 py-2 text-sm font-medium"
                >
                  <Crown size={16} className="mr-2" />
                  Upgrade
                </Button>
              )}
              <button 
                onClick={handleNavigateToAnalytics} 
                className="btn btn-secondary px-4 py-2 text-sm font-medium"
              >
                Analytics
              </button>
              <button 
                onClick={handleCloseSummary}
                className="btn btn-primary px-4 py-2 text-sm font-medium"
              >
                Close Summary
              </button>
            </div>
          </div>
        </div>
  
      {/* Filter results */}
      <div className="bg-light-background dark:bg-dark-secondaryLight pt-5 pb-1 px-4 rounded-lg shadow">
        <QuizSummaryFilter 
          filter={summaryFilter} 
          setFilter={handleSummaryFilterChange} 
          counts={filterCounts}
          currentSummary={filteredQuizHistory}
        />
      </div>

      {filteredItems.slice(indexOfFirstItem, indexOfLastItem).map((item: QuizSummary, index: number) => (
        <div key={index} className="bg-light-background dark:bg-dark-secondaryLight p-4 md:p-6 rounded-lg shadow space-y-4 border border-gray-400 dark:border-gray-700">
          <div>
            <p className="text-lg font-bold text-light-text dark:text-dark-text">{item.parentCategory}</p>
            {item.subCategory && (
              <p className="text-sm text-gray-500 dark:text-gray-400">{item.subCategory}</p>
            )}
          </div>
          
          {/* Stats and metadata section */}
          <div className="flex flex-col space-y-2 md:flex-row md:justify-between md:items-center text-sm text-gray-500 dark:text-gray-400">
            <p><strong>Confidence:</strong> {capitalizeFirstLetter(item.confidenceLevel)}</p>
            <p><strong>Time:</strong> {formatTime(item.timeSpent || 0)}</p>
          </div>
          
          <hr className="border-t border-light-text dark:border-dark-text" />
          
          {/* Question content section */}
          <div className="space-y-4">
            <div className="quiz-content text-light-text dark:text-dark-text" dangerouslySetInnerHTML={renderHTML(item.question)} />
            
            {/* Table or Options */}
            {item.isTableQuestion ? (
              <div className="quiz-content overflow-x-auto">
                <div className="inline-block min-w-full align-middle">
                  <div className="overflow-hidden border-b border-gray-200 dark:border-gray-700 shadow sm:rounded-lg">
                    <div 
                      className="w-full table-auto [&_table]:w-full [&_table]:border-collapse
                               [&_th]:border [&_th]:border-gray-200 [&_th]:dark:border-gray-700 [&_th]:p-3 [&_th]:text-center [&_th]:bg-gray-50 [&_th]:dark:bg-gray-700 [&_th]:font-semibold
                               [&_td]:border [&_td]:border-gray-200 [&_td]:dark:border-gray-700 [&_td]:p-3 [&_td]:text-center"
                      dangerouslySetInnerHTML={renderHTML(item.tableHTML)} 
                    />
                  </div>
                </div>
              </div>
            ) : (
              item.options.length > 0 && (
                <div className="space-y-2">
                  {item.options.map((option, optionIndex) => {
                    const optionLetter = String.fromCharCode(65 + optionIndex);
                    let optionClasses = [
                      "answer-option",
                      "w-full",
                      "text-left",
                      "px-4",
                      "py-2",
                      "rounded",
                      "transition-colors",
                      "duration-300",
                      "text-light-text",
                      "dark:text-dark-text",
                      "border",
                      "border-gray-400",
                      "dark:border-gray-500",
                    ];

                    if (optionLetter === item.userAnswer) {
                      optionClasses.push("selected");
                      if (optionLetter === item.correctAnswer) {
                        optionClasses.push("correct", "bg-green-500", "hover:bg-green-600", "dark:bg-green-700", "dark:hover:bg-green-800", "text-white");
                      } else {
                        optionClasses.push("incorrect", "bg-red-500", "hover:bg-red-600", "dark:bg-red-700", "dark:hover:bg-red-800", "text-white");
                      }
                    } else if (optionLetter === item.correctAnswer) {
                      optionClasses.push("correct", "bg-green-500", "hover:bg-green-600", "dark:bg-green-700", "dark:hover:bg-green-800", "text-white");
                    }

                    return (
                      <div
                        key={optionIndex}
                        className={optionClasses.join(" ")}
                      >
                        {option}
                      </div>
                    );
                  })}
                </div>
              )
            )}
          </div>
          {/* Updated Explanation Card */}
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-md border border-gray-100 dark:border-gray-700 overflow-hidden">
            <div className="bg-gradient-to-r from-[var(--color-primary-blue)] to-[var(--color-primary-blue-hover)] dark:from-[var(--color-primary-blue)] dark:to-[var(--color-primary-blue-hover)] px-5 py-4">
              <h3 className="text-lg font-semibold text-white flex items-center">
                <BookOpen className="w-5 h-5 mr-2" />
                Detailed Explanation
              </h3>
            </div>

            <div className="px-5 py-4">
              <div className="prose prose-blue dark:prose-invert max-w-none">
                <div 
                  className="quiz-content text-gray-700 dark:text-gray-200 [&>p]:mb-4 [&>ul]:mb-4 [&>ul]:pl-4 [&>ul>li]:mb-2" 
                  dangerouslySetInnerHTML={renderHTML(item.answerContent)} 
                />
              </div>
            </div>
          </div>
          
          {/* Note Section */}
          <div className="mt-4 pb-4">
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-md border border-gray-100 dark:border-gray-700 overflow-hidden">
              <div className="bg-gradient-to-r from-[#fe5d00] to-[#ff7a33] dark:from-[#fe5d00] dark:to-[#ff7a33] px-5 py-4">
                <h3 className="text-lg font-semibold text-white flex items-center">
                  <Notebook className="w-5 h-5 mr-2" />
                  Your Notes
                </h3>
              </div>

              <div className="px-5 py-4">
                {editingNotes[index] ? (
                  <div className="space-y-3">
                    <textarea
                      placeholder="Note Content"
                      value={noteContents[index] || ''}
                      onChange={(e) => handleNoteChange(index, e.target.value)}
                      className="w-full p-3 border rounded-lg bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text focus:ring-2 focus:ring-[#fe5d00] focus:border-transparent"
                      rows={3}
                      maxLength={MAX_NOTE_LENGTH}
                    ></textarea>
                    <p className="text-sm text-gray-500">
                      {(noteContents[index] || '').length}/{MAX_NOTE_LENGTH} characters
                    </p>
                    <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-2">
                      <button
                        onClick={() => handleSaveSummaryNote(index)}
                        className="bg-[#fe5d00] hover:bg-[#ff7a33] text-white px-4 py-2 rounded transition-colors duration-200 w-full md:w-auto"
                      >
                        {item.note ? 'Update Note' : 'Create Note'}
                      </button>
                      <button
                        onClick={() => toggleNoteEditing(index)}
                        className="bg-gray-500 dark:bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-600 dark:hover:bg-gray-700 w-full md:w-auto"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : item.note ? (
                  <div className="space-y-3">
                    <div className="prose max-w-none">
                      <div 
                        className="text-gray-700 dark:text-gray-200" 
                        dangerouslySetInnerHTML={{ __html: sanitizeInput(item.note.content) }}
                      />
                    </div>
                    <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-2">
                      <button
                        onClick={() => toggleNoteEditing(index)}
                        className="bg-[#fe5d00] hover:bg-[#ff7a33] text-white px-4 py-2 rounded transition-colors duration-200 w-full md:w-auto"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleClearSummaryNote(index)}
                        className="bg-gray-500 dark:bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-600 dark:hover:bg-gray-700 w-full md:w-auto"
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="text-center py-2">
                    <button
                      onClick={() => toggleNoteEditing(index)}
                      className="bg-[#fe5d00] hover:bg-[#ff7a33] text-white px-4 py-2 rounded transition-colors duration-200 w-full md:w-auto"
                    >
                      Add Note
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={paginate}
      />
    </div>
  );
};
 
  // If we're viewing a completed quiz from analytics or if viewingCompletedQuiz is true, render the quiz summary
  // Main render logic
  // First, check if we should show the quiz summary
  return (
    <>
      {/* Loading screens and notifications */}
      {(isResumingQuiz || isInitializingMockExam) && (
        <LoadingScreen 
          message={isInitializingMockExam ? "Preparing Mock Exam..." : "Starting Quiz..."}
          subMessage="Please wait while we load your content."
        />
      )}
      {isEndingQuiz && (
        <LoadingScreen 
          message="Ending Quiz..." 
          subMessage="Please wait while we process your results."
        />
      )}
      {isLoadingQuizSummary && (
        <LoadingScreen 
          message="Loading Quiz Summary..." 
          subMessage="Please wait while we process your results."
        />
      )}
      
      {isResettingCategories && (
        <LoadingScreen 
          message="Resetting Categories..." 
          subMessage="Please wait while we reset your selected categories. This may take up to 2-5 minutes, do not leave the"
        />
      )}
      
      {quizCompleted || showSummary ? (
        // Only show summary if from analytics OR not a study task
        (location.state?.from === 'analytics' && !location.state?.testletCompleted) || 
        (!studyTaskTestletId && !mockExamTestletId && !location.state?.skipSummary) ? (
          renderQuizSummary({ onClose: handleCloseSummary })
        ) : null
      ) : currentView === 'categories' && !quizStarted ? (
        <div className="space-y-4 relative pb-20 bg-white p-5 max-w-[1200px] mx-auto rounded-lg shadow dark:bg-dark-background border border-gray-400 dark:border-gray-700">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
            <h2 className="text-2xl font-bold mb-4 sm:mb-0">Custom Quiz</h2>
            <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
              {showUpgradeButton && (
                <Button
                  onClick={handleUpgradeClick}
                  className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center justify-center px-4 py-2 text-sm font-medium w-full sm:w-auto"
                >
                  <Crown size={16} className="mr-2" />
                  Upgrade
                </Button>
              )}
              <button
                onClick={handleResetCategories}
                disabled={selectedCategories.length === 0 || isResettingCategories}
                className={`w-full sm:w-auto text-red-500 dark:text-red-400 dark:hover:text-red-400 disabled:text-gray-300 dark:disabled:text-gray-600 ${
                  selectedCategories.length === 0 || isResettingCategories
                    ? 'cursor-not-allowed'
                    : 'hover:text-red-600 dark:hover:bg-gray-800'
                } p-2 rounded`}
              >
                Reset Categories
              </button>
            </div>
          </div>

          <div className="bg-white dark:bg-dark-background">
            
            {/* Updated table structure to match StudyPlan */}
            <div className="rounded-lg relative">
              <div
                ref={tableRef}
              >
                {renderCategories()}
              </div>
              {showScrollIndicator && (
                <>
                  <div className="absolute -right-[3px] top-0 bottom-0 w-[10%] bg-gradient-to-l from-white dark:from-gray-800 to-transparent pointer-events-none" />
                  <div className="fixed right-0 top-1/2 -translate-y-1/2 w-[10%] h-full pointer-events-none">
                    {/* You can add a chevron icon here if needed */}
                  </div>
                </>
              )}
            </div>
          </div>

          <QuizCustomizer
            courseId={courseId}
            userId={user?.id || ''}
            show={showCustomizer}
            availableQuestionCounts={finalAvailableCounts || {
              category_id: 'aggregated',
              total_questions: 0,
              unanswered_questions: 0,
              correct_percentage: 0,
              incorrect: 0,
              correct: 0,
              confident: 0,
              maybe: 0,
              guessing: 0,
              confident_correct: 0,
              confident_incorrect: 0,
              maybe_correct: 0,
              maybe_incorrect: 0,
              guessing_correct: 0,
              guessing_incorrect: 0
            }}
            customQuestionCount={customQuestionCount}
            setCustomQuestionCount={setCustomQuestionCount}
            keslerQFilter={keslerQFilter}
            setKeslerQFilter={setKeslerQFilter}
            onStartQuiz={handleStartQuiz}
            onResumeQuiz={handleResumeQuiz}
            beginQuizInitialization={beginQuizInitialization}
            selectedCategories={selectedCategories}
            activeQuizState={activeQuizState}
            setActiveQuizState={setActiveQuizState}
            resetActiveQuizCheck={resetActiveQuizCheck}
          />
        
          {/* Only show PopupModal if there's an active quiz and showPopup is true */}
          {activeQuizState.showActiveQuizModal && (
            <PopupModal
              isOpen={activeQuizState.showActiveQuizModal}
              onClose={() => {
                setActiveQuizState({
                  ...activeQuizState,
                  showActiveQuizModal: false
                });
                setIsModalOpen(false);
              }}
              onResume={() => {
                setCurrentView('quiz');
                setQuizStarted(true);
                setActiveQuizState({
                  ...activeQuizState,
                  showActiveQuizModal: false
                });
                setIsModalOpen(false);
              }}
              onEnd={() => {
                if (activeQuizState.activeQuiz) {
                  handleEndQuizSession(activeQuizState.activeQuiz.id);
                }
                resetActiveQuizCheck();
                setIsModalOpen(false);
              }}
              activeQuiz={activeQuizState.activeQuiz}
              courseId={courseId}
            />
          )}

          {/* Add QuizResetCategoryPopup here */}
          <QuizResetCategoryPopup 
            isOpen={showResetPopup}
            onClose={() => setShowResetPopup(false)}
            onConfirm={handleConfirmReset}
            categoryCount={selectedCategories.length}
          />
        </div>
      ) : currentView === 'quiz' && quizStarted ? (
        <div className="flex flex-col md:flex-row max-w-[1000px] mx-auto bg-white shadow-md dark:bg-dark-background dark:shadow-md rounded-lg md:p-4 md:mt-4">
          <div className="flex-grow px-2 sm:px-4">
            <div className="space-y-4 text-light-text dark:text-dark-text">
              <div className="flex justify-between items-center">
                <h2 className="text-xl sm:text-2xl font-bold">Question {currentQuestion + 1} of {questions.length}</h2>
                <div className="text-light-text dark:text-dark-text"><strong>Time: {formatTime(elapsedTime)}</strong></div>
              </div>
              {renderQuestion()}
            
              {showNoteEditor && (
                <div className="mt-4 bg-white p-4 rounded shadow">
                  <h3 className="text-xl font-bold mb-2">Edit Note</h3>
                  <textarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    className="w-full h-32 p-2 border rounded"
                    placeholder="Enter your note here..."
                  />
                  <div className="mt-2 space-x-2">
                    <button
                      onClick={handleSaveNote}
                      className="btn btn-primary"
                    >
                      Save Changes
                    </button>
                    <button
                      onClick={() => setShowNoteEditor(false)}
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              {currentNote && !showNoteEditor && (
                <div className="mt-4 bg-white p-4 rounded shadow">
                  <h3 className="text-xl font-bold mb-2">Your Note</h3>
                  <p>{currentNote.content}</p>
                  <div className="mt-2 space-x-2">
                    <button onClick={handleNoteTaking} className="btn btn-secondary">
                      Edit Note
                    </button>
                    <button onClick={handleClearNote} className="btn btn-secondary">
                      Clear Note
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}

    {/* Add upgrade modals */}
    <UpgradeModal
        isOpen={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
      />
      
      {/* Render end quiz upgrade modal */}
      {renderEndQuizUpgrade()}
    </>
  );
}

export default React.memo(Quiz);