import React, { createContext, useContext, useState } from 'react';

interface TabsContextType<T extends string> {
  value: T;
  onValueChange: (value: T) => void;
}

const TabsContext = createContext<TabsContextType<any>>({
  value: '',
  onValueChange: () => {}
});

interface TabsProps<T extends string> {
  value: T;
  onValueChange: (value: T) => void;
  className?: string;
  children: React.ReactNode;
}

export function Tabs<T extends string>({
  value,
  onValueChange,
  className = '',
  children
}: TabsProps<T>) {
  return (
    <TabsContext.Provider value={{ value, onValueChange }}>
      <div className={className}>
        {children}
      </div>
    </TabsContext.Provider>
  );
}

interface TabsListProps {
  className?: string;
  children: React.ReactNode;
}

export const TabsList: React.FC<TabsListProps> = ({
  className = '',
  children
}) => {
  return (
    <div className={`relative flex flex-wrap gap-1 p-1 bg-gray-100/80 dark:bg-gray-800/80 
      rounded-lg backdrop-blur-sm ${className}`}>
      {children}
    </div>
  );
};

interface TabsTriggerProps<T extends string> {
  value: T;
  className?: string;
  children: React.ReactNode;
}

export function TabsTrigger<T extends string>({
  value,
  className = '',
  children
}: TabsTriggerProps<T>) {
  const { value: selectedValue, onValueChange } = useContext(TabsContext);
  const isSelected = value === selectedValue;

  return (
    <button
      type="button"
      role="tab"
      aria-selected={isSelected}
      data-state={isSelected ? 'active' : 'inactive'}
      className={`
        relative px-4 py-2 text-sm font-medium transition-all duration-200
        rounded-md outline-none text-gray-700 dark:text-gray-300
        data-[state=active]:bg-white data-[state=active]:text-primary-600 
        data-[state=active]:shadow-sm dark:data-[state=active]:bg-gray-700 
        dark:data-[state=active]:text-primary-400
        hover:bg-gray-200/70 dark:hover:bg-gray-700/70
        hover:text-gray-900 dark:hover:text-gray-100
        focus:outline-none focus:ring-2 focus:ring-primary-500/30 
        dark:focus:ring-primary-400/30
        disabled:pointer-events-none disabled:opacity-50
        ${className}
      `}
      onClick={() => onValueChange(value)}
    >
      {children}
    </button>
  );
}

interface TabsContentProps<T extends string> {
  value: T;
  className?: string;
  children: React.ReactNode;
}

export function TabsContent<T extends string>({
  value,
  className = '',
  children
}: TabsContentProps<T>) {
  const { value: selectedValue } = useContext(TabsContext);

  if (value !== selectedValue) return null;

  return (
    <div 
      role="tabpanel"
      className={`mt-6 animate-in fade-in-50 slide-in-from-top-1 duration-200
        focus-visible:outline-none focus-visible:ring-2 
        focus-visible:ring-primary-500/30 focus-visible:ring-offset-2 
        ${className}`}
    >
      {children}
    </div>
  );
}