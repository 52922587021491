import React, { createContext, useContext, useState } from 'react';

interface ToggleGroupContextType {
  value: string;
  onChange: (value: string) => void;
  type: 'single' | 'multiple';
}

const ToggleGroupContext = createContext<ToggleGroupContextType>({
  value: '',
  onChange: () => {},
  type: 'single'
});

interface ToggleGroupProps {
  type?: 'single' | 'multiple';
  value: string;
  onValueChange: (value: string) => void;
  className?: string;
  children: React.ReactNode;
}

export const ToggleGroup: React.FC<ToggleGroupProps> = ({
  type = 'single',
  value,
  onValueChange,
  className = '',
  children
}) => {
  return (
    <ToggleGroupContext.Provider value={{ value, onChange: onValueChange, type }}>
      <div 
        role="group"
        className={`inline-flex items-center gap-1 p-1 bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 ${className}`}
      >
        {children}
      </div>
    </ToggleGroupContext.Provider>
  );
};

interface ToggleGroupItemProps {
  value: string;
  className?: string;
  children: React.ReactNode;
  'aria-label'?: string;
}

export const ToggleGroupItem: React.FC<ToggleGroupItemProps> = ({
  value,
  className = '',
  children,
  'aria-label': ariaLabel
}) => {
  const { value: selectedValue, onChange } = useContext(ToggleGroupContext);
  const isSelected = value === selectedValue;

  return (
    <button
      type="button"
      role="radio"
      aria-checked={isSelected}
      aria-label={ariaLabel}
      onClick={() => onChange(value)}
      className={`
        flex items-center gap-2 px-3 py-2 text-sm font-medium rounded-md
        transition-all duration-200
        ${isSelected ? 
          'bg-primary-blue text-white shadow-sm' : 
          'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
        }
        focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:ring-offset-1
        disabled:pointer-events-none disabled:opacity-50
        ${className}
      `}
    >
      {children}
    </button>
  );
};