import React, { useState } from 'react';
import { Modal } from '../ui/Modal';
import useKeyPress from '../../hooks/useKeyPress';
import DOMPurify from 'dompurify';
import { Alert, AlertTitle, AlertDescription } from '../ui/Alert';

const MAX_CONFIRM_LENGTH = 10;

interface QuizResetCategoryPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  categoryCount: number;
}

const QuizResetCategoryPopup: React.FC<QuizResetCategoryPopupProps> = ({
  isOpen,
  onClose,
  onConfirm,
  categoryCount
}) => {
  const [confirmText, setConfirmText] = useState('');
  const [isResetting, setIsResetting] = useState(false);

  const sanitizeInput = (input: string) => {
    return DOMPurify.sanitize(input);
  };

  const handleConfirm = async () => {
    if (sanitizeInput(confirmText).toLowerCase() === 'reset') {
      setIsResetting(true);
      try {
        await onConfirm();
      } finally {
        setIsResetting(false);
        setConfirmText('');
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedInput = sanitizeInput(e.target.value).slice(0, MAX_CONFIRM_LENGTH);
    setConfirmText(sanitizedInput);
  };

  useKeyPress('Enter', handleConfirm, () => isOpen && confirmText.toLowerCase() === 'reset');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
        {isResetting ? (
          <div className="space-y-4">
            <Alert variant="warning" className="mb-4">
              <AlertTitle>System Resetting Categories</AlertTitle>
              <AlertDescription>
                Please do NOT close this window while the reset is in progress.
              </AlertDescription>
            </Alert>
            <div className="flex justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
            </div>
          </div>
        ) : (
          <>
            <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-gray-200">
              Reset Quiz Categories
            </h2>
            <p className="mb-4 text-gray-600 dark:text-gray-400">
              Are you sure you want to reset {categoryCount} selected quiz{' '}
              {categoryCount === 1 ? 'category' : 'categories'}? This action will mark
              all questions in these categories as unanswered BUT will retain your
              confidence levels.
            </p>
            <p className="mb-2 text-gray-600 dark:text-gray-400">
              Enter RESET in the textbox below to confirm:
            </p>
            <input
              type="text"
              value={confirmText}
              onChange={handleInputChange}
              className="w-full p-2 mb-4 border rounded text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-700"
              placeholder="Type RESET to confirm"
              maxLength={MAX_CONFIRM_LENGTH}
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirm}
                disabled={sanitizeInput(confirmText).toLowerCase() !== 'reset'}
                className={`px-4 py-2 rounded ${
                  sanitizeInput(confirmText).toLowerCase() === 'reset'
                    ? 'bg-red-500 text-white hover:bg-red-600'
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
              >
                Reset
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default QuizResetCategoryPopup;