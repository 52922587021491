import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../components/AuthProvider';
import { supabase } from '../../../services/supabase';
import { api, Message, Ticket } from '../../../services/api';
import { format } from 'date-fns';
import { 
  AlertCircle, 
  Clock, 
  Inbox, 
  Loader2, 
  MessageCircle, 
  Search,
  Tag,
  CircleDot,
  Mail,
  MailOpen,
} from 'lucide-react';
import { Input } from '../../ui/Input';
import { Card } from '../../ui/Card';
import { Badge } from '../../ui/Badge';
import { Tabs, TabsList, TabsTrigger } from '../../ui/Tabs';
import { ScrollArea } from '../../ui/ScrollArea';
import { 
  Tooltip,
  TooltipContent,
  TooltipTrigger } from '../../ui/Tooltip';
import Pagination from '../../course/common/Pagination';

const ITEMS_PER_PAGE = 25;
const MAX_PREVIEW_LENGTH = 150;
const MAX_TOOLTIP_LENGTH = 500;

// Helper function to strip HTML tags
const stripHtmlTags = (html: string) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

// Get first line of text
const getFirstLine = (text: string) => {
  const stripped = stripHtmlTags(text);
  const firstLine = stripped.split('\n')[0];
  return firstLine;
};

interface RawTicketMessage {
  id: string;
  ticket_id: string;
  support_user_id: string;  // Changed from user_id
  content: string;
  is_internal: boolean;
  created_at: string;
  is_read: boolean;
  attachments: Array<{ name: string; url: string; type: string }>;
  email_sent: boolean;
}

interface TicketWithMessages extends Ticket {
  messages: Message[];
}

interface UserTicketListProps {
  onTicketSelect: (ticket: Ticket) => void;
  selectedTicketId?: string;
  isCompressed: boolean;
}

const UserTicketList: React.FC<UserTicketListProps> = ({ 
  onTicketSelect,
  selectedTicketId 
}) => {
  const { user } = useAuth();
  const [userData, setUserData] = useState<{ email: string; full_name: string | null } | null>(null);
  const [tickets, setTickets] = useState<TicketWithMessages[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [currentTab, setCurrentTab] = useState('all');
  const [unreadCount, setUnreadCount] = useState(0);
  const isCompressedView = Boolean(selectedTicketId);

  // Load initial data
  useEffect(() => {
    if (user?.id) {
      Promise.all([
        loadUserData(),
        loadUserTickets()
      ]).catch(console.error);
    }
  }, [user?.id]);

  // Add effect for pagination changes
  useEffect(() => {
    if (user?.id) {
      loadUserTickets();
    }
  }, [currentPage, user?.id]);

  // Real-time subscriptions
  useEffect(() => {
    if (user?.id) {
      // Set up realtime subscription for tickets
      const ticketSubscription = supabase
        .channel('user-tickets')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'support_tickets',
            filter: `user_id=eq.${user.id}`
          },
          (payload) => {
            if (payload.eventType === 'UPDATE') {
              // Update the specific ticket in the list
              setTickets(prevTickets => 
                prevTickets.map(ticket => 
                  ticket.id === payload.new.id 
                    ? { ...ticket, ...payload.new }
                    : ticket
                )
              );
            } else {
              // For other changes, reload the full list
              loadUserTickets();
            }
          }
        )
        .subscribe();

      // Subscribe to messages table for unread updates
      const messageSubscription = supabase
        .channel('ticket-messages')
        .on(
          'postgres_changes',
          {
            event: 'INSERT',
            schema: 'public',
            table: 'support_messages'
          },
          (payload) => {
            const ticketId = payload.new.ticket_id;
            if (tickets.some(t => t.id === ticketId)) {
              loadUserTickets();
            }
          }
        )
        .subscribe();

      return () => {
        ticketSubscription.unsubscribe();
        messageSubscription.unsubscribe();
      };
    }
  }, [user?.id, tickets]);

  const loadUserData = async () => {
    if (!user?.id) return;
    
    try {
      // Use existing getOrCreateSupportUser instead of direct query
      const supportUser = await api.getOrCreateSupportUser(user.email, user.id);
      setUserData({
        email: supportUser.email,
        full_name: supportUser.full_name
      });
    } catch (error) {
      console.error('Error loading user data:', error);
    }
  };

  const loadUserTickets = async () => {
    if (!user?.id) return;
  
    try {
      setLoading(true);
  
      // Get support user first using our helper
      const supportUser = await api.getOrCreateSupportUser(user.email, user.id);
  
      // Get total count
      const { count } = await supabase
        .from('support_tickets')
        .select('id', { count: 'exact' })
        .eq('support_user_id', supportUser.id);
  
      setTotalItems(count || 0);
  
      // Get paginated tickets with support user data
      const { data: ticketsData, error: ticketsError } = await supabase
        .from('support_tickets')
        .select(`
          *,
          support_users!support_tickets_support_user_id_fkey (
            id,
            email,
            full_name,
            auth_user_id
          ),
          messages:support_messages (
            id,
            ticket_id,
            support_user_id,
            content,
            is_internal,
            created_at,
            is_read,
            attachments,
            email_sent
          ),
          messages_count:support_messages(count)
        `)
        .eq('support_user_id', supportUser.id)
        .order('last_activity_at', { ascending: false })
        .range((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE - 1);
  
      if (ticketsError) {
        console.error('Error fetching tickets:', ticketsError);
        throw ticketsError;
      }
  
      if (ticketsData) {
        // Collect all auth_user_ids
        const authUserIds = new Set<string>();
        const supportUserIds = new Set<string>();
  
        ticketsData.forEach(ticket => {
          // Add support user and their auth_user_id if exists
          if (ticket.support_user_id) {
            supportUserIds.add(ticket.support_user_id);
          }
          if (ticket.support_users?.auth_user_id) {
            authUserIds.add(ticket.support_users.auth_user_id);
          }
          
          // Add admin_id if exists
          if (ticket.admin_id) {
            authUserIds.add(ticket.admin_id);
          }
  
          // Add message support users and their auth_user_ids
          ticket.messages?.forEach((message: RawTicketMessage) => {
            if (message.support_user_id) {
              supportUserIds.add(message.support_user_id);
            }
          });
        });
  
        // Fetch all needed users in parallel
        const [supportUsersResponse, authUsersResponse] = await Promise.all([
          supabase
            .from('support_users')
            .select('id, email, full_name, auth_user_id')
            .in('id', Array.from(supportUserIds)),
          supabase
            .from('users')
            .select('id, email, full_name')
            .in('id', Array.from(authUserIds))
        ]);
  
        if (supportUsersResponse.error) throw supportUsersResponse.error;
        if (authUsersResponse.error) throw authUsersResponse.error;
  
        // Create maps for both support users and auth users
        const supportUserMap = new Map(supportUsersResponse.data?.map(u => [u.id, u]) || []);
        const authUserMap = new Map(authUsersResponse.data?.map(u => [u.id, u]) || []);
  
        // Process tickets and attach user data
        const processedTickets: TicketWithMessages[] = ticketsData.map(ticket => {
          const messages: Message[] = (ticket.messages || []).map((message: RawTicketMessage) => {
            const supportUser = supportUserMap.get(message.support_user_id);
            const authUser = supportUser?.auth_user_id ? authUserMap.get(supportUser.auth_user_id) : null;
  
            return {
              id: message.id,
              ticket_id: message.ticket_id,
              support_user_id: message.support_user_id,
              content: message.content,
              is_internal: message.is_internal || false,
              created_at: message.created_at,
              is_read: message.is_read || false,
              attachments: message.attachments || [],
              email_sent: message.email_sent || false,
              user: {
                id: supportUser?.id || 'unknown',
                email: supportUser?.email || 'unknown',
                full_name: authUser?.full_name || supportUser?.full_name || 'Unknown User'
              }
            };
          });
  
          // Get the ticket's support user and corresponding auth user
          const ticketSupportUser = supportUserMap.get(ticket.support_user_id);
          const ticketAuthUser = ticketSupportUser?.auth_user_id ? 
            authUserMap.get(ticketSupportUser.auth_user_id) : null;
  
          const processedTicket: TicketWithMessages = {
            ...ticket,
            messages,
            _count: {
              messages: ticket.messages_count?.[0]?.count || 0
            },
            has_unread: messages.some(
              message => !message.is_read && 
                message.support_user_id !== supportUser.id && 
                !message.is_internal
            ) || false,
            user: {
              id: ticketSupportUser?.id || 'unknown',
              email: ticketSupportUser?.email || 'unknown',
              full_name: ticketAuthUser?.full_name || ticketSupportUser?.full_name || 'Unknown User'
            },
            admin: ticket.admin_id ? authUserMap.get(ticket.admin_id) : undefined
          };
  
          return processedTicket;
        });
  
        setTickets(processedTickets);
          
        // Calculate total unread count
        const unread = processedTickets.filter(t => t.has_unread).length;
        setUnreadCount(unread);
      }
    } catch (error) {
      console.error('Error loading tickets:', error);
    } finally {
      setLoading(false);
    }
  };

  const markTicketAsRead = async (ticketId: string) => {
    if (!user?.id) return;

    try {
      // Mark all messages in the ticket as read
      await supabase
        .from('support_messages')
        .update({ is_read: true })
        .eq('ticket_id', ticketId)
        .neq('user_id', user.id);

      // Update local state
      setTickets(prev => 
        prev.map(ticket => 
          ticket.id === ticketId 
            ? { ...ticket, has_unread: false }
            : ticket
        )
      );
    } catch (error) {
      console.error('Error marking ticket as read:', error);
    }
  };

  // Filter tickets based on search and current tab
  const filteredTickets = tickets.filter(ticket => {
    const matchesSearch = !searchQuery || 
      ticket.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ticket.body.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ticket.tags?.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()));

    const matchesTab = currentTab === 'all' || 
      (currentTab === 'unread' && ticket.has_unread) ||
      (currentTab === 'open' && ticket.status === 'open') ||
      (currentTab === 'closed' && ticket.status === 'closed');

    return matchesSearch && matchesTab;
  });

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'new': return 'bg-yellow-400 text-black border border-yellow-500 dark:border-yellow-600';
      case 'open': return 'bg-red-600 text-white border border-red-700 dark:border-red-800';
      case 'pending': return 'bg-blue-600 text-yellow-200 border border-blue-700 dark:border-blue-800';
      case 'closed': return 'bg-gray-600 text-white border border-gray-700 dark:border-gray-800';
      default: return 'bg-gray-100 text-gray-800 dark:bg-gray-900/20 dark:text-gray-300 border border-gray-200 dark:border-gray-800';
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    if (!text) return '';
    const stripped = stripHtmlTags(text);
    if (stripped.length <= maxLength) return stripped;
    return `${stripped.substring(0, maxLength).trim()}...`;
  };

  // Get first line and truncate it
  const getFirstLine = (text: string) => {
    const stripped = stripHtmlTags(text);
    const firstLine = stripped.split('\n')[0];
    return truncateText(firstLine, MAX_PREVIEW_LENGTH);
  };

  const formatEmailPreview = (content: string) => {
    const cleanText = stripHtmlTags(content)
      .replace(/\s+/g, ' ')  // Normalize whitespace
      .trim();
  
    // Split into lines of reasonable length
    const words = cleanText.split(' ');
    const lines = [];
    let currentLine = '';
  
    words.forEach(word => {
      if (currentLine.length + word.length > 60) {
        lines.push(currentLine.trim());
        currentLine = word;
      } else {
        currentLine += ' ' + word;
      }
    });
  
    if (currentLine) {
      lines.push(currentLine.trim());
    }
  
    return lines.join('\n');
  };

  const renderTicketSubject = (ticket: TicketWithMessages) => (
    <Tooltip content={
      <div className="whitespace-pre-wrap">{ticket.subject}</div>
    }>
      <TooltipTrigger asChild>
        <div className={`flex items-center text-sm truncate mb-2 ${ticket.has_unread ? 'font-semibold' : 'font-normal'}`}>
          {ticket.has_unread ? (
            <Mail className="w-4 h-4 text-blue-500 flex-shrink-0" />
          ) : (
            <MailOpen className="w-4 h-4 text-gray-400 flex-shrink-0" />
          )}
          <span className="truncate ml-2">{truncateText(ticket.subject, MAX_PREVIEW_LENGTH)}</span>
        </div>
      </TooltipTrigger>
    </Tooltip>
  );

  const renderTicketPreview = (ticket: TicketWithMessages) => (
    <Tooltip content={
      <div className="whitespace-pre-wrap leading-relaxed">
        {formatEmailPreview(ticket.body)}
      </div>
    }>
      <TooltipTrigger asChild>
        <div className="text-xs text-gray-600 dark:text-gray-400 line-clamp-1">
          {getFirstLine(ticket.body)}
        </div>
      </TooltipTrigger>
    </Tooltip>
  );

  return (
    <div className="flex flex-col h-full">
      {/* Header Section - Fixed height */}
      <div className="flex-none p-3 md:p-4 border-b dark:border-gray-800 bg-white dark:bg-gray-900">
        <div className="flex flex-col gap-3">
          {/* Search - Optimized for mobile */}
          <div className="relative">
            <Input
              placeholder="Search tickets..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-8 h-8 md:h-9 text-sm md:text-base bg-gray-50 dark:bg-gray-800"
            />
            <Search className="w-3.5 h-3.5 md:w-4 md:h-4 text-gray-500 absolute left-2.5 top-2.5" />
          </div>

          {/* Tabs - Optimized for mobile */}
          <Tabs value={currentTab} onValueChange={setCurrentTab} className="w-full">
            <TabsList className="w-full grid grid-cols-4 p-1 bg-gradient-to-r from-gray-100/90 to-gray-200/80 dark:from-gray-800/90 dark:to-gray-700/80 rounded-lg md:rounded-full shadow-md">
              <TabsTrigger 
                value="all"
                className="px-2 md:px-4 py-1.5 md:py-2 text-xs md:text-sm font-medium md:font-semibold transition-all rounded-md md:rounded-full data-[state=active]:bg-white data-[state=active]:text-gray-900 data-[state=active]:shadow-lg"
              >
                All
              </TabsTrigger>
              <TabsTrigger 
                value="unread"
                className="px-2 md:px-4 py-1.5 md:py-2 text-xs md:text-sm font-medium md:font-semibold transition-all rounded-md md:rounded-full data-[state=active]:bg-white data-[state=active]:text-gray-900 data-[state=active]:shadow-lg"
              >
                <div className="flex items-center justify-center gap-1 md:gap-2">
                  Unread
                  {unreadCount > 0 && (
                    <span className="inline-flex items-center justify-center w-4 h-4 md:px-2 md:py-0.5 text-[10px] md:text-xs font-semibold rounded-full bg-blue-500 text-white">
                      {unreadCount}
                    </span>
                  )}
                </div>
              </TabsTrigger>
              <TabsTrigger 
                value="open"
                className="px-2 md:px-4 py-1.5 md:py-2 text-xs md:text-sm font-medium md:font-semibold transition-all rounded-md md:rounded-full data-[state=active]:bg-white data-[state=active]:text-gray-900 data-[state=active]:shadow-lg"
              >
                Open
              </TabsTrigger>
              <TabsTrigger 
                value="closed"
                className="px-2 md:px-4 py-1.5 md:py-2 text-xs md:text-sm font-medium md:font-semibold transition-all rounded-md md:rounded-full data-[state=active]:bg-white data-[state=active]:text-gray-900 data-[state=active]:shadow-lg"
              >
                Closed
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 min-h-0">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <Loader2 className="w-6 md:w-8 h-6 md:h-8 animate-spin text-gray-500" />
          </div>
        ) : filteredTickets.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full text-gray-500 p-4 md:p-8">
            <Inbox className="w-8 h-8 md:w-12 md:h-12 mb-2" />
            <p className="text-sm md:text-base">No tickets found</p>
          </div>
        ) : (
          <div className="divide-y divide-gray-100 dark:divide-gray-800">
            {filteredTickets.map((ticket) => (
              <div
                key={ticket.id}
                onClick={() => {
                  if (ticket.has_unread) {
                    markTicketAsRead(ticket.id);
                  }
                  onTicketSelect(ticket);
                }}
                className={`
                  group p-3 md:p-4 cursor-pointer border-l-4 transition-all
                  hover:bg-gray-50 dark:hover:bg-gray-800/50 
                  ${selectedTicketId === ticket.id ? 
                    'bg-blue-50/80 dark:bg-blue-900/20 border-l-blue-500' : 
                    'border-l-transparent bg-white dark:bg-gray-900'}
                  ${ticket.has_unread ? 
                    'bg-blue-50/50 dark:bg-blue-900/10' : ''}
                `}
              >
                <div className="space-y-2">
                  <div className="flex items-start gap-2">
                    <div className="flex-shrink-0 pt-1">
                      {ticket.has_unread ? (
                        <CircleDot className="w-2 h-2 text-blue-500" />
                      ) : (
                        <div className="w-2" />
                      )}
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="flex items-start justify-between gap-2">
                        <div className="flex-1 min-w-0">
                          <div className="flex items-center text-sm truncate mb-1">
                            {ticket.has_unread ? (
                              <Mail className="w-3.5 h-3.5 md:w-4 md:h-4 text-blue-500 flex-shrink-0" />
                            ) : (
                              <MailOpen className="w-3.5 h-3.5 md:w-4 md:h-4 text-gray-400 flex-shrink-0" />
                            )}
                            <span className={`truncate ml-2 ${ticket.has_unread ? 'font-semibold' : 'font-normal'}`}>
                              {truncateText(ticket.subject, MAX_PREVIEW_LENGTH)}
                            </span>
                          </div>
                          <div className="text-xs text-gray-600 dark:text-gray-400 line-clamp-1">
                            {getFirstLine(ticket.body)}
                          </div>
                        </div>
                        <div className="flex-shrink-0">
                          <Badge className={`text-[10px] md:text-xs whitespace-nowrap ${getStatusColor(ticket.status)}`}>
                            {ticket.status}
                          </Badge>
                        </div>
                      </div>
                      <div className="flex items-center justify-between text-[10px] md:text-xs text-gray-500 mt-2">
                        <div className="flex items-center gap-3">
                          <span className="flex items-center gap-1">
                            <Clock className="w-3 h-3" />
                            {format(new Date(ticket.last_activity_at), 'MMM d, h:mm a')}
                          </span>
                          <span className="flex items-center gap-1">
                            <MessageCircle className="w-3 h-3" />
                            {ticket._count?.messages || 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Pagination */}
      <div className="flex-none border-t dark:border-gray-800">
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default UserTicketList;