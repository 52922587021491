import React from 'react';
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react';
import { Button } from '../../../components/ui/Button';

interface PaginationProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  if (totalPages <= 1) return null;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      if (currentPage > 3) pageNumbers.push('...');
      
      let start = Math.max(2, currentPage - 1);
      let end = Math.min(totalPages - 1, currentPage + 1);
      
      if (currentPage <= 3) end = Math.min(totalPages - 1, maxPagesToShow - 1);
      if (currentPage >= totalPages - 2) start = Math.max(2, totalPages - 3);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }
      
      if (currentPage < totalPages - 2) pageNumbers.push('...');
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <nav className="flex justify-center items-center mt-4 px-4 sm:px-2" aria-label="Pagination">
      <ul className="flex items-center space-x-1 sm:space-x-2">
        {/* First page button */}
        <li>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
            className="p-2 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label="First page"
          >
            <ChevronsLeft size={20} />
          </Button>
        </li>
        
        {/* Previous page button */}
        <li>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="p-2 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label="Previous page"
          >
            <ChevronLeft size={20} />
          </Button>
        </li>

        {/* Page numbers */}
        {renderPageNumbers().map((number, index) => (
          <li key={index}>
            {number === '...' ? (
              <span className="px-2 py-2 text-gray-500">...</span>
            ) : (
              <Button
                variant={currentPage === number ? 'primary' : 'ghost'}
                size="sm"
                onClick={() => handlePageChange(number as number)}
                className={`w-8 h-8 ${
                  currentPage === number
                    ? ''
                    : 'hover:bg-gray-100 dark:hover:bg-gray-800'
                }`}
                aria-current={currentPage === number ? 'page' : undefined}
              >
                {number}
              </Button>
            )}
          </li>
        ))}

        {/* Next page button */}
        <li>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="p-2 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label="Next page"
          >
            <ChevronRight size={20} />
          </Button>
        </li>

        {/* Last page button */}
        <li>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
            className="p-2 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label="Last page"
          >
            <ChevronsRight size={20} />
          </Button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;