import React, { useState, useEffect } from 'react';
import { Modal } from '../../../components/ui/Modal';
import { Button } from '../../../components/ui/Button';
import { useNavigate } from 'react-router-dom';
import { QuizQuestionData, api } from '../../../services/api';

interface EndTestletProps {
  isOpen: boolean;
  onClose: () => void;
  onEndTestlet: () => Promise<void>;
  handleFinishQuiz: () => Promise<void>;
  setIsLastQuestion: (isLast: boolean) => void;
  updateQuizHistory: (answeredOnly: boolean) => Promise<void>;
  isLastQuestion: boolean;
  questionsAnswered: number;
  totalQuestions: number;
  isViewingExplanation: boolean;
  courseId: string;
  quizId: string | null;
  studyTaskTestletId?: string;
  mockExamTestletId?: string;
  mockExamId?: string;
}

const EndTestlet: React.FC<EndTestletProps> = ({ 
  isOpen, 
  onClose, 
  onEndTestlet,
  handleFinishQuiz,
  setIsLastQuestion,
  updateQuizHistory,
  isLastQuestion, 
  totalQuestions,
  isViewingExplanation,
  courseId,
  quizId,
  studyTaskTestletId,
  mockExamTestletId,
  mockExamId
}) => {
  const [isEnding, setIsEnding] = useState(false);
  const [actualAnsweredCount, setActualAnsweredCount] = useState(0);
  const navigate = useNavigate();

  // Fetch the actual number of answered questions from quiz history
  useEffect(() => {
    const fetchAnsweredCount = async () => {
      if (!quizId) return;

      try {
        const quizHistory = await api.getQuizHistoryById(quizId);
        if (quizHistory) {
          const answeredCount = quizHistory.questions_data.filter(
            (q: QuizQuestionData) => q.answered
          ).length;
          setActualAnsweredCount(answeredCount);
        }
      } catch (error) {
        console.error('Error fetching quiz history:', error);
      }
    };

    if (isOpen) {
      fetchAnsweredCount();
    }
  }, [isOpen, quizId]);

  const handleEndTestlet = async () => {
    if (isEnding) return;
    
    setIsEnding(true);
    try {
      if (actualAnsweredCount === 0) {
        await onEndTestlet();
        if (mockExamTestletId && mockExamId) {
          navigate(`/course/${courseId}/mock-exam/${mockExamId}`, { replace: true });
        } else if (studyTaskTestletId) {
          navigate(`/course/${courseId}/study-plan`, { 
            replace: true,
            state: {
              returnedFrom: 'quiz',
              testletCompleted: false // No questions answered
            }
          });
        } else {
          navigate(`/course/${courseId}/quiz`, { replace: true });
        }
        return;
      }

      await updateQuizHistory(true);
      setIsLastQuestion(true);
      await handleFinishQuiz();
      
      // If this is a study task testlet, navigate back with completion state
      if (studyTaskTestletId) {
        navigate(`/course/${courseId}/study-plan`, {
          replace: true,
          state: {
            returnedFrom: 'quiz',
            testletCompleted: true // Questions were answered
          }
        });
      }
      
    } catch (error) {
      console.error('Error ending testlet:', error);
    } finally {
      setIsEnding(false);
      onClose();
    }
  };

  // Get the appropriate button text based on quiz type and state
  const getButtonText = () => {
    if (actualAnsweredCount === 0) {
      if (mockExamTestletId) {
        return 'Return to Mock Exam';
      } else if (studyTaskTestletId) {
        return 'Return to Study Plan';
      }
      return 'End Quiz';
    }
    
    return isLastQuestion ? 'Finish Quiz' : 'End Testlet';
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full mx-auto">
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
              {isLastQuestion ? 'End of Testlet' : 'Warning'}
            </h2>
            <p className="mb-4 text-gray-700 dark:text-gray-300">
              You have completed {actualAnsweredCount} out of {totalQuestions} questions.
            </p>
            <p className="mb-6 text-gray-700 dark:text-gray-300">
              {actualAnsweredCount === 0 
                ? 'You haven\'t answered any questions yet. Ending now will return you to the previous page.'
                : isLastQuestion
                ? 'Would you like to finish the quiz or continue reviewing your answers?'
                : 'You have not completed all questions in this testlet. The quiz will only grade your answered questions.'}
            </p>
            <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
              <Button 
                onClick={onClose} 
                variant="outline" 
                className="w-full sm:w-auto dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
              >
                {isLastQuestion ? 'Continue Review' : 'Cancel'}
              </Button>
              <Button 
                onClick={handleEndTestlet} 
                variant={isLastQuestion ? "primary" : "destructive"} 
                className="w-full sm:w-auto relative"
                disabled={isEnding}
              >
                {isEnding && (
                  <span className="absolute inset-0 flex items-center justify-center">
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </span>
                )}
                <span className={isEnding ? 'invisible' : ''}>
                  {getButtonText()}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EndTestlet;