import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { api, Note, TIER_IDS } from '../../services/api';
import { auth } from '../../services/auth';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import QuestionPreview from './QuestionPreview';
import Pagination from './common/Pagination';
import LoadingScreen from './common/LoadingScreen';
import UpgradeModal from './common/UpgradeModal';
import { Edit, Trash2, ChevronDown, ChevronUp, Book, HelpCircle, Box, User, File, Crown, Lock, ArrowLeft } from 'lucide-react';
import { Button } from '../ui/Button';
import DOMPurify from 'dompurify';

interface FocusNotesProps {
  courseId: string;
  userId: string;
}

interface ExtendedNote extends Note {
  question_category?: string;
  sub_question_category?: string;
  lesson_name?: string;
  lesson_category?: string;
  lesson_subcategory?: string;
}

const noteTypes = ['All', 'Quiz', 'Lesson', 'Simulation', 'Mentor', 'General'] as const;
type NoteType = typeof noteTypes[number];

const noteTypeColors: Record<NoteType, string> = {
  All: 'bg-gray-100 dark:bg-gray-900 border-gray-300 dark:border-gray-800',
  Quiz: 'bg-blue-100 dark:bg-blue-900 border-blue-300 dark:border-blue-800',
  Lesson: 'bg-green-100 dark:bg-green-900 border-green-300 dark:border-green-800',
  Simulation: 'bg-purple-100 dark:bg-purple-900 border-purple-300 dark:border-purple-800',
  Mentor: 'bg-yellow-100 dark:bg-yellow-900 border-yellow-300 dark:border-yellow-800',
  General: 'bg-red-100 dark:bg-gray-700 border-red-300 dark:border-gray-800',
};

const getNoteIcon = (noteType: NoteType) => {
  switch (noteType) {
    case 'Quiz': return <HelpCircle size={18} className="text-blue-600 dark:text-blue-400" />;
    case 'Lesson': return <Book size={18} className="text-green-600 dark:text-green-400" />;
    case 'Simulation': return <Box size={18} className="text-purple-600 dark:text-purple-400" />;
    case 'Mentor': return <User size={18} className="text-yellow-600 dark:text-yellow-400" />;
    default: return <File size={18} className="text-gray-600 dark:text-gray-400" />;
  }
};

const MAX_NOTE_LENGTH = 2000;
const TRUNCATE_LENGTH = 500;

function FocusNotes({ courseId }: FocusNotesProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const noteRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [studyTaskId, setStudyTaskId] = useState<string | null>(null);
  const [notes, setNotes] = useState<Note[]>([]);
  const [newNote, setNewNote] = useState('');
  const [editingNote, setEditingNote] = useState<Note | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [expandedNoteId, setExpandedNoteId] = useState<string | null>(null);
  const [selectedNoteType, setSelectedNoteType] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNotes, setTotalNotes] = useState(0);
  const [expandedQuestionId, setExpandedQuestionId] = useState<string | null>(null);
  const notesPerPage = 25;
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const { userCourseAccesses, isLoading: accessLoading } = useUserCourseAccess();

  // Check if user has restricted access
  const hasRestrictedAccess = useMemo(() => {
    if (!userCourseAccesses || userCourseAccesses.length === 0) return true;
    
    const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
    if (courseAccesses.length === 0) return true;
  
    // If user has study materials or full access in any of their tiers, they get full access
    const hasFullAccess = courseAccesses.some(
      access => access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY || 
                access.tier_id === TIER_IDS.FULL_ACCESS
    );
  
    if (hasFullAccess) return false;
  
    // Otherwise, check if they only have restricted tiers
    return courseAccesses.every(
      access => access.tier_id === TIER_IDS.FREE_TRIAL || 
                access.tier_id === TIER_IDS.MENTOR_ONLY
    );
  }, [userCourseAccesses, courseId]);

  // Function to check if note is from premium content
  const isNotePremiumContent = useCallback((note: ExtendedNote) => {
    if (!hasRestrictedAccess) return false;
  
    // Check if quiz/question is from premium content
    if (note.question_id && !note.is_free_trial_content) {
      return true;
    }
  
    // Check if lesson is premium
    if (note.lesson_id && !note.is_free_trial_content) {
      return true;
    }
  
    // Simulations and mentor modules are premium if not free trial
    if ((note.simulation_id || note.module_id) && !note.is_free_trial_content) {
      return true;
    }
  
    return false;
  }, [hasRestrictedAccess]);

  const handleUpgradeClick = useCallback(() => {
    setIsUpgradeModalOpen(true);
  }, []);

  const handleCloseUpgradeModal = useCallback(() => {
    setIsUpgradeModalOpen(false);
  }, []);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = await auth.getCurrentUser();
      setCurrentUser(user);
    };
    fetchCurrentUser();
  }, []);

  // STUDY TASK NAVIGATION

  // Add effect to handle study task ID from location state
  useEffect(() => {
    if (location.state?.studyTaskId) {
      setStudyTaskId(location.state.studyTaskId);
    }
  }, [location.state]);

  // Add return to study task handler
  const handleBackToStudyTask = useCallback(() => {
    if (studyTaskId) {
      navigate(`/course/${courseId}/study-plan/${studyTaskId}`, {
        state: { 
          returnedFrom: 'focus-notes',
          returnUrl: `/course/${courseId}/focus-notes`
        },
        replace: true
      });
    }
  }, [studyTaskId, navigate, courseId]);

  // NOTES FETCHING AND HANDLING

  const fetchNotes = useCallback(async () => {
    try {
      if (!currentUser) throw new Error('User not authenticated');
      
      const { notes, total } = await api.getNotes(
        currentUser.id,
        courseId,
        selectedNoteType === 'All' ? null : selectedNoteType,
        currentPage,
        notesPerPage
      );
      setNotes(notes);
      setTotalNotes(total);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch notes. Please try again later.');
      setLoading(false);
    }
  }, [courseId, currentUser, selectedNoteType, currentPage]);

  useEffect(() => {
    if (currentUser) {
      fetchNotes();
    }
  }, [fetchNotes, currentUser]);

  // ADD: Function to handle navigation to lesson
  const handleGoToLesson = (lessonId: string) => {
    navigate(`/course/${courseId}/lessons/${lessonId}`);
  };

  // First, add the mentor module navigation handler
  const handleGoToMentorModule = (moduleId: string) => {
    navigate(`/course/${courseId}/mentor/${moduleId}`);
  };

  const handleGoToSimulation = (simulationId: string) => {
    navigate(`/course/${courseId}/simulations`, {
      state: { simulationId }
    });
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const sanitizedContent = DOMPurify.sanitize(e.target.value);
    setNewNote(sanitizedContent.slice(0, MAX_NOTE_LENGTH));
  };

  const handleEditingNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (editingNote) {
      const sanitizedContent = DOMPurify.sanitize(e.target.value);
      setEditingNote({ 
        ...editingNote, 
        content: sanitizedContent.slice(0, MAX_NOTE_LENGTH) 
      });
    }
  };

  async function handleCreateNote() {
    try {
      const user = await auth.getCurrentUser();
      if (!user) throw new Error('User not authenticated');
      
      await api.createNote({
        user_id: user.id,
        course_id: courseId,
        content: DOMPurify.sanitize(newNote),
        lesson_id: null,
        question_id: null,
        simulation_id: null,
        module_id: null
      });
      setNewNote('');
      fetchNotes();
  
      await api.logUserActivity({
        user_id: user.id,
        course_id: courseId,
        activity_type: 'create_note',
        activity_id: user.id,
        result: 'success'
      });
    } catch (err) {
      setError('Failed to create note. Please try again.');
    }
  }

  async function handleUpdateNote() {
    try {
      const user = await auth.getCurrentUser();
      if (!user || !editingNote) throw new Error('User not authenticated or no note selected');
      
      await api.updateNote(
        editingNote.id,
        { content: DOMPurify.sanitize(editingNote.content) },
        user.id,
        courseId,
        editingNote.question_id,
        editingNote.lesson_id,
        editingNote.module_id,
        editingNote.simulation_id
      );
      setEditingNote(null);
      fetchNotes();
  
      await api.logUserActivity({
        user_id: user.id,
        course_id: courseId,
        activity_type: 'update_note',
        activity_id: editingNote.id,
        result: 'success'
      });
    } catch (err) {
      setError('Failed to update note. Please try again.');
    }
  }

  async function handleClearNote(noteId: string) {
    try {
      const user = await auth.getCurrentUser();
      if (!user) throw new Error('User not authenticated');
      
      await api.deleteNote(noteId);
      fetchNotes();
  
      await api.logUserActivity({
        user_id: user.id,
        course_id: courseId,
        activity_type: 'delete_note',
        activity_id: noteId,
        result: 'success'
      });
    } catch (err) {
      setError('Failed to clear note. Please try again.');
    }
  }

  const getNoteType = (note: ExtendedNote): NoteType => {
    if (note.question_id) return 'Quiz';
    if (note.lesson_id) return 'Lesson';
    if (note.simulation_id) return 'Simulation';
    if (note.module_id) return 'Mentor';
    return 'General';
  };

  const handleToggleQuestionPreview = useCallback((noteId: string) => {
    setExpandedQuestionId(prevId => {
      const isExpanding = prevId !== noteId;
      
      if (isExpanding) {
        // Small delay to ensure DOM updates before scrolling
        setTimeout(() => {
          const element = noteRefs.current[noteId];
          if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - 100; // 100px offset
            
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }
        }, 100);
      }
      
      return isExpanding ? noteId : null;
    });
  }, []);

  const handleNoteTypeChange = (noteType: string) => {
    setSelectedNoteType(noteType);
    setCurrentPage(1);
  };

  // Filter out premium content notes when displaying total count
  const filteredTotalNotes = useMemo(() => {
    if (!hasRestrictedAccess) return totalNotes;
    return notes.filter(note => !isNotePremiumContent(note)).length;
  }, [totalNotes, notes, hasRestrictedAccess, isNotePremiumContent]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  if (isLoading) {
    return <LoadingScreen message="Loading your Focus Notes..." subMessage="This may take a few moments" />;
  }
  if (error) return <div className="text-red-500 dark:text-red-400">{error}</div>;

  const renderNoteCard = (note: ExtendedNote) => {
    const noteType = getNoteType(note);
    const isExpanded = expandedNoteId === note.id;
    const isQuestionExpanded = expandedQuestionId === note.id;
    const isPremium = isNotePremiumContent(note);
  
    const truncatedContent = note.content.length > TRUNCATE_LENGTH 
      ? `${note.content.slice(0, TRUNCATE_LENGTH)}...` 
      : note.content;
  
    const buttonBaseClasses = "w-8 h-8 flex items-center justify-center rounded-full transition duration-300";
  
    const handleNavigate = (noteType: NoteType, id: string | null) => {
      if (!id) return;
      
      switch (noteType) {
        case 'Quiz':
          handleToggleQuestionPreview(id);
          break;
        case 'Lesson':
          navigate(`/course/${courseId}/lessons/${id}`);
          break;
        case 'Simulation':
          navigate(`/course/${courseId}/simulations/${id}/preview`);
          break;
        case 'Mentor':
          navigate(`/course/${courseId}/mentor/${id}`);
          break;
      }
    };
  
    return (
      <div 
        key={note.id}
        ref={el => noteRefs.current[note.id] = el}
        className="relative rounded-lg shadow-sm border overflow-hidden h-full flex flex-col group"
      >
        <div className={`${noteTypeColors[noteType]} ${isPremium ? 'blur-sm' : ''} flex-grow flex flex-col h-full`}>
          <div className="p-4 flex flex-col h-full">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center space-x-2">
                {getNoteIcon(noteType)}
                <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-100">{noteType} Note</h4>
              </div>
              <button
                onClick={() => setExpandedNoteId(isExpanded ? null : note.id)}
                className={`${buttonBaseClasses} text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200`}
              >
                {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
              </button>
            </div>
            
            <div className="text-sm text-gray-600 dark:text-gray-400 mb-2">
              {note.lesson_name && (
                <p className="font-medium text-green-600 dark:text-green-400">
                  Lesson: {note.lesson_name}
                </p>
              )}
              {note.question_category && (
                <p className="font-medium text-blue-600 dark:text-blue-400">
                  Quiz: {note.question_category}
                </p>
              )}
              {note.module_title && (
                <p className="font-medium text-yellow-600 dark:text-yellow-400">
                  Mentor Module: {note.module_title}
                </p>
              )}
              {note.simulation_id && (
                <p className="font-medium text-purple-600 dark:text-purple-400">
                  Simulation Exercise
                </p>
              )}
            </div>
  
            <p className="text-gray-700 dark:text-gray-300 flex-grow">
              {isExpanded ? note.content : truncatedContent}
            </p>
  
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => setEditingNote(note)}
                className={`${buttonBaseClasses} text-yellow-600 hover:bg-yellow-100 dark:hover:bg-yellow-800`}
              >
                <Edit size={18} />
              </button>
              <button
                onClick={() => handleClearNote(note.id)}
                className={`${buttonBaseClasses} text-red-600 hover:bg-red-100 dark:hover:bg-red-800`}
              >
                <Trash2 size={18} />
              </button>
              {note.question_id && (
                <button
                  onClick={() => handleToggleQuestionPreview(note.id)}
                  className={`${buttonBaseClasses} ${
                    isQuestionExpanded
                      ? 'bg-blue-100 dark:bg-blue-800 text-blue-600 dark:text-blue-400'
                      : 'text-blue-600 hover:bg-blue-100 dark:hover:bg-blue-800'
                  }`}
                  title={isQuestionExpanded ? 'Hide Question Preview' : 'Show Question Preview'}
                >
                  <HelpCircle size={18} />
                </button>
              )}
              {note.lesson_id && (
                <button
                  onClick={() => note.lesson_id && handleNavigate('Lesson', note.lesson_id)}
                  className={`${buttonBaseClasses} text-green-600 hover:bg-green-100 dark:hover:bg-green-800`}
                  title="Go to Lesson"
                >
                  <Book size={18} />
                </button>
              )}
              {note.module_id && (
                <button
                  onClick={() => note.module_id && handleNavigate('Mentor', note.module_id)}
                  className={`${buttonBaseClasses} text-yellow-600 hover:bg-yellow-100 dark:hover:bg-yellow-800`}
                  title="Go to Mentor Module"
                >
                  <User size={18} />
                </button>
              )}
              {note.simulation_id && (
                <button
                  onClick={() => note.simulation_id && handleNavigate('Simulation', note.simulation_id)}
                  className={`${buttonBaseClasses} text-purple-600 hover:bg-purple-100 dark:hover:bg-purple-800`}
                  title="Go to Simulation"
                >
                  <Box size={18} />
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Premium content overlay */}
        {isPremium && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-10 rounded-lg">
            <div className="text-center text-white px-6 py-4 max-w-sm mx-auto">
              <Lock size={36} className="mx-auto mb-3" />
              <p className="text-lg font-semibold mb-2">Premium Content Note</p>
              <p className="mb-4 text-sm">Upgrade your account to access notes from premium content.</p>
              <Button
                onClick={handleUpgradeClick}
                className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center mx-auto text-sm px-4 py-2"
              >
                <Crown size={14} className="mr-2" />
                Upgrade Now
              </Button>
            </div>
          </div>
        )}

        {/* Question preview section */}
        {isQuestionExpanded && note.question_id && currentUser && !isPremium && (
          <div className="border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
            <QuestionPreview
              questionId={note.question_id}
              courseId={courseId}
              userId={currentUser.id}
            />
          </div>
        )}
      </div>
    );
  };

  // Update the main return statement to include the return button when appropriate
  return (
    <div className="bg-white space-y-6 p-0 md:p-6 px-4 md:px-8 rounded-lg shadow-sm dark:bg-gray-800 max-w-[1280px] mx-auto">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">Focus Notes</h2>
        {studyTaskId && (
          <button
            onClick={handleBackToStudyTask}
            className="flex items-center px-4 py-2 text-sm font-medium rounded-md bg-red-500 dark:bg-red-600 text-white hover:bg-red-600 dark:hover:bg-red-700 transition-colors duration-200"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Study Task
          </button>
        )}
      </div>
      
      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
        <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Create New Note</h3>
        <textarea
          placeholder="Note Content"
          value={newNote}
          onChange={handleNoteChange}
          className="w-full p-2 mb-2 border rounded-md bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-100 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          rows={3}
          maxLength={MAX_NOTE_LENGTH}
        ></textarea>
        <p className="text-sm text-gray-500 mb-2">
          {newNote.length}/{MAX_NOTE_LENGTH} characters
        </p>
        <button
          onClick={handleCreateNote}
          disabled={newNote.trim().length === 0}
          className={`px-4 py-2 rounded-md transition duration-300 ${
            newNote.trim().length === 0
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'bg-blue-500 hover:bg-blue-600 text-white'
          }`}
        >
          Create Note
        </button>
      </div>

      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
        <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Filter Notes</h3>
        <div className="flex flex-wrap gap-2">
          {noteTypes.map((type) => {
            const buttonColor = type !== 'All' ? noteTypeColors[type] : 'bg-gray-200 dark:bg-gray-700';
            return (
              <button
                key={type}
                onClick={() => handleNoteTypeChange(type)}
                className={`px-4 py-2 rounded-full text-sm md:text-base transition duration-300 ${
                  selectedNoteType === type
                    ? `${buttonColor} text-gray-900 dark:text-gray-100 font-semibold`
                    : `${buttonColor} text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100`
                }`}
              >
                {type}
              </button>
            );
          })}
        </div>
      </div>
  
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {notes.map(renderNoteCard)}
      </div>

      <Pagination
        currentPage={currentPage}
        totalItems={filteredTotalNotes}
        itemsPerPage={notesPerPage}
        onPageChange={handlePageChange}
      />

      {editingNote && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full">
            <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Edit Note</h3>
            <div className="space-y-4">
              <div>
                <textarea
                  value={editingNote.content}
                  onChange={handleEditingNoteChange}
                  className="w-full p-2 border rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-sm md:text-base"
                  rows={5}
                  placeholder="Note content"
                  maxLength={MAX_NOTE_LENGTH}
                />
                <p className="text-sm text-gray-500 mt-1">
                  {editingNote.content.length}/{MAX_NOTE_LENGTH} characters
                </p>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => setEditingNote(null)}
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 dark:bg-gray-600 dark:text-gray-200 dark:hover:bg-gray-500 transition duration-300"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdateNote}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800 transition duration-300"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={handleCloseUpgradeModal}
      />
    </div>
  );
}

export default FocusNotes;