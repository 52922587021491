import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { StudyPlanGenerator } from './StudyPlanGenerator';
import StudyPlanCountdown from './StudyPlanCountdown';
import StudyPlanView from './StudyPlanView';
import StudyPlanMentor from './StudyPlanMentor';
import StudyPlanExamDayTracker from './StudyPlanExamDayTracker';
import StudyPlanResetPopup from './StudyPlanResetPopup';
import { useStudyPlan } from '../../hooks/useStudyPlan';
import { api, SPStudyPlanItem, SPCourse, ConfidenceTrackerData } from '../../services/api';
import { formatDate } from '../../utils/helpers';
import LoadingScreen from './common/LoadingScreen';
import { Switch } from './../../components/ui/Switch';
import { QuoteAlert } from './../../components/ui/QuotesAlert';
import { Button } from './../../components/ui/Button';
import { 
  Minimize2,
  Maximize2,
  ListCheck, 
  CheckCircle,
  AlertTriangle,
  Calendar,
  ClipboardList
} from 'lucide-react';
import DOMPurify from 'dompurify';

interface StudyPlanProps {
  courseId: string;
  userId: string;
}
  
interface WeekRange {
  weekNumber: number;
  start: Date;
  end: Date;
  availableMinutes: number;
  isFinalWeek?: boolean;
}

interface TaskDueDate {
  start: Date;
  end: Date;
  weekNumber: number;
  sequenceNumber: number;  // Add sequence number to track order
}

interface TaskFilters {
  hideLate: boolean;
  hideCompleted: boolean;
}

interface FilterToggleProps {
  label: string;
  isVisible: boolean;
  onToggle: () => void;
}

interface CourseSequence {
  id: string;
  title: string | null;
  sequence: number;
}

interface TopicSequences {
  [key: string]: {
    primary: CourseSequence;
    secondary?: CourseSequence;
    blueprint?: CourseSequence;
  };
}

const FilterToggle: React.FC<FilterToggleProps> = ({ label, isVisible, onToggle }) => {
  return (
    <div className="flex items-center space-x-2 mr-4 mb-2">
      <Switch
        checked={isVisible}
        onChange={onToggle}
      />
      <label className="text-sm font-medium leading-none text-light-text dark:text-dark-text">
        {label}
      </label>
    </div>
  );
};

type SortColumn = 'blueprint' | 'primary' | 'secondary' | 'dueDate';
type FilterOption = SortColumn | 'late' | 'completed';

function StudyPlan({ courseId, userId }: StudyPlanProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { data, isLoading, error, refetch } = useStudyPlan(userId, courseId, queryClient);
  const [showQuoteAlert, setShowQuoteAlert] = useState(true);
  const [studyType, setStudyType] = useState<'brand_new' | 'retake'>('brand_new');
  const [sortColumn, setSortColumn] = useState<SortColumn>('primary');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
  const [isResetPopupOpen, setIsResetPopupOpen] = useState(false);
  const [confidenceData, setConfidenceData] = useState<ConfidenceTrackerData | null>(null);
  const [taskDueDates, setTaskDueDates] = useState<Map<string, TaskDueDate>>(new Map());
  const isPrimaryBlueprint = useMemo(() => data?.primaryCourse?.is_blueprint_based || false, [data]);
  const isSecondaryBlueprint = useMemo(() => data?.secondaryCourse?.is_blueprint_based || false, [data]);
  const isBlueprintCourse = useMemo(() => isPrimaryBlueprint || isSecondaryBlueprint, [isPrimaryBlueprint, isSecondaryBlueprint]);
  const hasBlueprintCourse = useMemo(() => isPrimaryBlueprint || isSecondaryBlueprint, [isPrimaryBlueprint, isSecondaryBlueprint]);
  const tableRef = useRef<HTMLDivElement>(null);
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);
  const [hiddenFilters, setHiddenFilters] = useState<FilterOption[]>([]);
  const [activeProvider, setActiveProvider] = useState<'primary' | 'secondary' | 'blueprint'>('primary');
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [isMentorExpanded, setIsMentorExpanded] = useState(true);
  const [taskFilters, setTaskFilters] = useState<TaskFilters>({
    hideLate: false,
    hideCompleted: false
  });
  
  const handleScroll = useCallback(() => {
    if (tableRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tableRef.current;
      const isAtEnd = scrollLeft >= scrollWidth - clientWidth - 10;
      setShowScrollIndicator(!isAtEnd);
    }
  }, []);

  // Instead of nullable Date, use current date as fallback
  const startDate = useMemo(() => {
    if (data?.startDate) {
      return new Date(data.startDate);
    }
    // Fallback to current date if no start date is available
    return new Date();
  }, [data?.startDate]);

  // Add effect to fetch study type
  useEffect(() => {
    const fetchStudyType = async () => {
      if (data?.userStudyPlan?.study_type_id) {
        try {
          const studyTypeData = await api.getStudyType(userId, courseId);
          setStudyType(studyTypeData.study_type);
        } catch (error) {
          console.error('Error fetching study type:', error);
        }
      }
    };

    fetchStudyType();
  }, [data?.userStudyPlan?.study_type_id, userId, courseId]);

  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener('scroll', handleScroll);
      handleScroll(); // Check initial scroll position
    }
    return () => {
      if (tableElement) {
        tableElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);
  
  const hasSecondaryProvider = useMemo(() => {
    return data?.studyPlanItems?.some(item => item.secondary_provider) || false;
  }, [data?.studyPlanItems]);

  useEffect(() => {
    if (location.state && location.state.refreshStudyPlan) {
      console.log('Refetching study plan data...');
      refetch();
    }
  }, [location.state, refetch]);

  // Add this effect to listen for task completion events
  useEffect(() => {
    const handleTaskCompletion = () => {
      console.log('Task completion detected, invalidating query cache');
      queryClient.invalidateQueries(['studyPlan', userId, courseId]);
    };

    window.addEventListener('taskCompleted', handleTaskCompletion);

    return () => {
      window.removeEventListener('taskCompleted', handleTaskCompletion);
    };
  }, [queryClient, userId, courseId]);

  useEffect(() => {
    const fetchConfidenceData = async () => {
      try {
        const fetchedData = await api.getConfidenceTrackerData(userId, courseId);
        setConfidenceData(fetchedData);
      } catch (error) {
        console.error('Error fetching confidence data:', error);
      }
    };

    fetchConfidenceData();
  }, [userId, courseId]);

  // Update the formatDateRange function to handle wrapping
  const formatDateRange = (start: Date, end: Date, isFinalWeek: boolean = false): JSX.Element => {
    const formatDate = (date: Date) => {
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const year = date.getFullYear().toString().slice(-2);
      return `${month}/${day}/${year}`;
    };

    if (isFinalWeek) {
      const finalWeekText = studyType === 'brand_new' ? 'Breathing Room + Review' : 'Breathing Room + Final Push';
      return (
        <div className="flex flex-col">
          <span className="whitespace-normal">{finalWeekText}</span>
          <span className="whitespace-normal">({formatDate(start)}-{formatDate(end)})</span>
        </div>
      );
    }

    return <span className="whitespace-normal">({formatDate(start)}-{formatDate(end)})</span>;
  };

  // Update the calculateWeeks function
  const calculateWeeks = (
    startDate: Date, 
    examDate: Date, 
    recommendedWeeklyHours: number,
    studyPlanItems: SPStudyPlanItem[]
  ): WeekRange[] => {
    // Get the maximum week number from the database
    const maxWeekNumber = Math.max(...studyPlanItems
      .filter(item => item.week_number !== null)
      .map(item => item.week_number || 0));
    
    const weeks: WeekRange[] = [];
    let currentDate = new Date(startDate);
    
    // Calculate weeks based on the actual week numbers from the database
    for (let weekNum = 1; weekNum <= maxWeekNumber; weekNum++) {
      const weekStart = new Date(currentDate);
      const weekEnd = new Date(currentDate);
      
      // If this is the final week, use the exam date as the end date
      if (weekNum === maxWeekNumber) {
        weeks.push({
          weekNumber: weekNum,
          start: weekStart,
          end: examDate,
          availableMinutes: recommendedWeeklyHours * 60,
          isFinalWeek: true
        });
      } else {
        weekEnd.setDate(weekEnd.getDate() + 6);
        weeks.push({
          weekNumber: weekNum,
          start: weekStart,
          end: weekEnd,
          availableMinutes: recommendedWeeklyHours * 60,
          isFinalWeek: false
        });
      }

      currentDate.setDate(currentDate.getDate() + 7);
    }

    return weeks;
  };

  const calculateTaskDueDates = (
    weeks: WeekRange[],
    tasks: SPStudyPlanItem[],
    isPrimaryBlueprint: boolean
  ): Map<string, TaskDueDate> => {
    const taskDueDates = new Map<string, TaskDueDate>();
  
    // Sort tasks by sequence number first
    const sortedTasks = [...tasks].sort((a, b) => {
      const aSeq = isPrimaryBlueprint ? 
        (a.blueprint_topic_sequence || 0) : 
        (a.primary_sequence || 0);
      const bSeq = isPrimaryBlueprint ? 
        (b.blueprint_topic_sequence || 0) : 
        (b.primary_sequence || 0);
      return aSeq - bSeq;
    });
  
    sortedTasks.forEach(task => {
      if (task.week_number !== null) {
        const week = weeks.find(w => w.weekNumber === task.week_number);
        if (week) {
          taskDueDates.set(task.item_id, {
            start: week.start,
            end: week.end,
            weekNumber: task.week_number,
            sequenceNumber: isPrimaryBlueprint ? 
              (task.blueprint_topic_sequence || 0) : 
              (task.primary_sequence || 0)
          });
        }
      }
    });
  
    return taskDueDates;
  };
  
  // Update the useMemo hook for weeks and due dates calculation
  const { weeks, dueDates } = useMemo(() => {
    if (data?.startDate && data?.examDate && data?.studyPlanItems && data?.userStudyPlan?.recommended_weekly_hours) {
      const startDate = new Date(data.startDate);
      const examDate = new Date(data.examDate);
      const recommendedWeeklyHours = parseInt(data.userStudyPlan.recommended_weekly_hours);
      
      const calculatedWeeks = calculateWeeks(
        startDate, 
        examDate, 
        recommendedWeeklyHours,
        data.studyPlanItems
      );
      
      const calculatedDueDates = calculateTaskDueDates(
        calculatedWeeks,
        data.studyPlanItems,
        isPrimaryBlueprint
      );
      
      return { weeks: calculatedWeeks, dueDates: calculatedDueDates };
    }
    return { weeks: [], dueDates: new Map() };
  }, [data, isPrimaryBlueprint]);

    // Update taskDueDates when dueDates changes
    useEffect(() => {
      setTaskDueDates(dueDates);
      console.log('Task due dates updated:', dueDates);
    }, [dueDates]);

  // Update the getPlannedDate function to handle wrapping
  const getPlannedDate = useCallback((item: SPStudyPlanItem): JSX.Element => {
    const dueDate = taskDueDates.get(item.item_id);
    if (!dueDate) return <span className="text-left">Not scheduled</span>;
    
    const week = weeks.find(w => w.weekNumber === dueDate.weekNumber);
    const dateElement = week 
      ? formatDateRange(week.start, week.end, week.isFinalWeek)
      : formatDateRange(dueDate.start, dueDate.end);
    
    if (item.is_completed) {
      return (
        <div className="text-left">
          <span className="font-bold text-white-600 whitespace-normal">
            Week {dueDate.weekNumber} {dateElement}
          </span>
        </div>
      );
    }
    
    return (
      <div className="text-left">
        <span className="whitespace-normal">
          Week {dueDate.weekNumber} {dateElement}
        </span>
      </div>
    );
  }, [taskDueDates, weeks, studyType]);  

  const rowColorClasses = {
    inProgress: 'bg-yellow-100 dark:bg-yellow-200 text-yellow-800 dark:text-black hover:bg-yellow-200 dark:hover:bg-yellow-100',
    overdue: 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-100 hover:bg-red-200 dark:hover:bg-red-800',
    completed: 'bg-green-100 dark:bg-[var(--color-primary-blue)] text-green-800 dark:text-white hover:bg-green-200 dark:hover:bg-[var(--color-primary-blue-hover)]',
    future: 'bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700',
  };
  
  const getRowClass = useCallback((item: SPStudyPlanItem): string => {
    if (item.is_completed) {
      return rowColorClasses.completed;
    }
  
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    const dueDate = taskDueDates.get(item.item_id);
    if (!dueDate) return rowColorClasses.future;
  
    if (dueDate.end < today) return rowColorClasses.overdue;
    if (dueDate.start <= today && today <= dueDate.end) return rowColorClasses.inProgress;
    return rowColorClasses.future;
  }, [taskDueDates, rowColorClasses]);

  // Update the handleTaskClick function
  const handleTaskClick = (itemId: string, topicType: string | null, sequence?: number) => {
    navigate(`/course/${courseId}/study-task/${itemId}`, { 
      state: { 
        topicType: topicType || 'default',
        sequence: sequence
      } 
    });
  };

  const handleResetClick = () => {
    setIsResetPopupOpen(true);
  };

  const handleResetConfirm = async () => {
    // This function will be passed to the StudyPlanResetPopup component
    try {
      await api.resetStudyPlan(userId, courseId);
      setIsResetPopupOpen(false);
      refetch(); // Refetch the study plan data after reset
    } catch (error) {
      console.error('Error resetting study plan:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleSort = (column: SortColumn) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const getProviderDisplayName = (course: SPCourse | null, alias: string | null | undefined) => {
    if (!course) return '';
    if (course.is_blueprint_based && alias) {
      return alias.charAt(0).toUpperCase() + alias.slice(1);
    }
    return course.display_name;
  };

  const toggleExpanded = (itemId: string) => {
    setExpandedItems(prev => {
      const newSet = new Set(prev);
      if (newSet.has(itemId)) {
        newSet.delete(itemId);
      } else {
        newSet.add(itemId);
      }
      return newSet;
    });
  };

  // Update the CheckboxIcon component
  const CheckboxIcon: React.FC<{ checked: boolean; onClick: () => void; topicType: string }> = ({ checked, onClick, topicType }) => (
    <div
      className={`w-full h-full flex items-center justify-center cursor-pointer`}
      onClick={onClick}
    >
      <svg
        className={`w-6 h-6 ${
          checked ? 'text-green-500' : 'text-red-500'
        }`}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
        {checked && <path d="M9 12l2 2 4-4"></path>}
      </svg>
    </div>
  );

  // TASK FILTERS
  const isTopicLate = useCallback((item: SPStudyPlanItem): boolean => {
    if (item.is_completed) return false;
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const dueDate = taskDueDates.get(item.item_id);
    if (!dueDate) return false;
    
    return dueDate.end < today;
  }, [taskDueDates]);

  const toggleFilter = (filter: FilterOption) => {
    if (filter === 'late' || filter === 'completed') {
      setTaskFilters(prev => ({
        ...prev,
        [filter === 'late' ? 'hideLate' : 'hideCompleted']: !prev[filter === 'late' ? 'hideLate' : 'hideCompleted']
      }));
      return;
    }

    setHiddenFilters(prev => {
      const newHiddenFilters = prev.includes(filter)
        ? prev.filter(f => f !== filter)
        : [...prev, filter];
      
      // Ensure at least one course column is always visible
      const visibleCourseColumns = ['primary', 'secondary'].filter(c => !newHiddenFilters.includes(c as FilterOption));
      if (!hasBlueprintCourse && !newHiddenFilters.includes('blueprint')) {
        visibleCourseColumns.push('blueprint');
      }
      
      if (visibleCourseColumns.length === 0) {
        return prev;
      }
      
      return newHiddenFilters;
    });
  };


  const renderTruncatedContent = (content: string, itemId: string, taskId: string, isCompleted: boolean, topicType: string | null, isTaskName: boolean = false) => {
    const isExpanded = expandedItems.has(itemId);
    const parser = new DOMParser();
    
    // Step 1: Add line breaks after semicolons, but only if they're at the end of a normal span
    const contentWithLineBreaks = content.replace(/;(?=<\/span><span class="font-bold">)/g, ';</span><br/><span class="font-bold">');
    
    const doc = parser.parseFromString(contentWithLineBreaks, 'text/html');
    const contentLength = doc.body.textContent?.length || 0;
  
    const truncateLine = (text: string, maxLength: number) => {
      if (text.length <= maxLength) return text;
      return text.substr(0, maxLength - 3) + '...';
    };
  
    let truncatedContent: string;
  
    if (isTaskName) {
      // Special handling for task names
      const text = doc.body.textContent || '';
      truncatedContent = isExpanded || contentLength < 100 ? contentWithLineBreaks : truncateLine(text, 100);
    } else {
      // Existing logic for other content with modifications
      const paragraphs = Array.from(doc.body.getElementsByTagName('p'));
  
      const truncateParagraph = (p: HTMLParagraphElement) => {
        // Step 2: Handle multiple span pairs in a paragraph
        const spans = Array.from(p.getElementsByTagName('span'));
        if (spans.length > 0) {
          const totalLength = spans.reduce((sum, span) => sum + (span.textContent?.length || 0), 0);
          const maxLength = 100;
  
          if (totalLength <= maxLength) {
            // Step 3: Preserve structure when joining spans
            return `<p class="${p.className}">${p.innerHTML}</p>`;
          } else {
            // Step 4: Truncate content while preserving structure
            let truncatedHtml = '';
            let remainingLength = maxLength;
            
            for (let i = 0; i < spans.length; i += 2) {
              const boldSpan = spans[i];
              const normalSpan = spans[i + 1];
              
              if (!boldSpan || !normalSpan) break;
              
              const boldText = boldSpan.textContent || '';
              const normalText = normalSpan.textContent || '';
              
              if (remainingLength > 0) {
                const availableLength = remainingLength - boldText.length;
                const truncatedNormalText = truncateLine(normalText, availableLength);
                
                truncatedHtml += `<span class="${boldSpan.className}">${boldText}</span>`;
                truncatedHtml += `<span class="${normalSpan.className}">${truncatedNormalText}</span>`;
                
                remainingLength -= (boldText.length + truncatedNormalText.length);
                
                if (remainingLength > 0 && i < spans.length - 2 && normalText.endsWith(';')) {
                  truncatedHtml += '<br/>';
                  remainingLength--;
                }
              } else {
                break;
              }
            }
  
            return `<p class="${p.className}">${truncatedHtml}</p>`;
          }
        } else {
          return `<p class="${p.className}">${truncateLine(p.textContent || '', 100)}</p>`;
        }
      };
  
      const truncatedParagraphs = paragraphs.map(truncateParagraph);
      truncatedContent = isExpanded || contentLength < 100 ? contentWithLineBreaks : truncatedParagraphs.join('');
    }
  
    const wasContentTruncated = truncatedContent !== contentWithLineBreaks && contentLength >= 100;
  
    const handleContentClick = () => {
      if (isExpanded || contentLength < 100) {
        handleTaskClick(taskId, topicType);
      } else {
        toggleExpanded(itemId);
      }
    };
  
    const linkColorClass = isCompleted
      ? 'text-blue-500 dark:text-white hover:text-blue-700 dark:hover:text-blue-300'
      : 'text-blue-500 hover:text-blue-700';
  
    return (
      <div>
        <div
          className={`${(isExpanded || contentLength < 100) ? '' : 'cursor-pointer'} overflow-hidden`}
          onClick={handleContentClick}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(truncatedContent) }}
        />
        {wasContentTruncated && (
          <button
            className={`${linkColorClass} text-xs mt-1`}
            onClick={(e) => {
              e.stopPropagation();
              toggleExpanded(itemId);
            }}
          >
            {isExpanded ? 'Show less' : 'Show more'}
          </button>
        )}
      </div>
    );
  };
  

  
// Update handleExpandAll to include mentor section
const handleExpandAll = useCallback((expand: boolean) => {
  setIsAllExpanded(expand);
  setIsMentorExpanded(expand);
}, []);

// Update the studyPlanStats calculation
const studyPlanStats = useMemo(() => {
  if (!data?.studyPlanItems) return null;

  const now = new Date();
  const weekStart = new Date(now);
  weekStart.setDate(now.getDate() - now.getDay()); // Start of current week
  
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 6); // End of current week

  const totalTasks = data.studyPlanItems.length;
  const completedTasks = data.studyPlanItems.filter(item => item.is_completed).length;
  
  // Calculate late tasks (excluding completed ones)
  const lateTasks = data.studyPlanItems.filter(item => {
    const dueDate = taskDueDates.get(item.item_id);
    return !item.is_completed && 
           dueDate?.end && 
           dueDate.end < now;
  }).length;

  // Calculate tasks in progress this week
  const dueThisWeek = data.studyPlanItems.filter(item => {
    const dueDate = taskDueDates.get(item.item_id);
    return !item.is_completed && 
           dueDate?.start && 
           dueDate?.end && 
           dueDate.start <= now && 
           dueDate.end >= now;
  }).length;

  // Calculate remaining tasks
  const remainingTasks = totalTasks - completedTasks;

  return {
    total: totalTasks,
    dueThisWeek,
    late: lateTasks,
    completed: completedTasks,
    remaining: remainingTasks
  };
}, [data?.studyPlanItems, taskDueDates]);

// Update CourseControls to pass proper handler
const CourseControls = () => {
  const buttonBaseClasses = "text-gray-900 dark:text-gray-100";
  
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4 mb-6 border border-gray-400 dark:border-gray-700">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
          Your Study Plan
        </h2>
        <Button
          onClick={() => handleExpandAll(!isAllExpanded)}
          variant="outline"
          size="sm"
          className={`flex items-center gap-2 ${buttonBaseClasses}`}
        >
          {isAllExpanded ? (
            <>
              <Minimize2 className="w-4 h-4" />
              Collapse All
            </>
          ) : (
            <>
              <Maximize2 className="w-4 h-4" />
              Expand All
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

// Update the sortedItems logic to prioritize sequence-based sorting
const sortedItems = useMemo(() => {
  if (!data?.studyPlanItems) return [];
  
  return [...data.studyPlanItems].sort((a, b) => {
    // Get the appropriate sequence numbers based on active provider
    let aSeq: number;
    let bSeq: number;
    
    switch (activeProvider) {
      case 'primary':
        aSeq = a.primary_sequence || 0;
        bSeq = b.primary_sequence || 0;
        break;
      case 'secondary':
        aSeq = a.secondary_topic_sequence || 0;
        bSeq = b.secondary_topic_sequence || 0;
        break;
      case 'blueprint':
        aSeq = a.blueprint_topic_sequence || 0;
        bSeq = b.blueprint_topic_sequence || 0;
        break;
      default:
        aSeq = 0;
        bSeq = 0;
    }
    
    // Sort primarily by sequence number
    return sortDirection === 'asc' ? aSeq - bSeq : bSeq - aSeq;
  });
}, [data?.studyPlanItems, activeProvider, sortDirection]);

  const filteredAndSortedItems = useMemo(() => {
    if (!sortedItems) return [];
    
    return sortedItems.filter(item => {
      // Filter out late items if hideLate is true
      if (taskFilters.hideLate && isTopicLate(item)) {
        return false;
      }

      // Filter out completed items if hideCompleted is true
      if (taskFilters.hideCompleted && item.is_completed) {
        return false;
      }

      return true;
    });
  }, [sortedItems, taskFilters, isTopicLate]);

  const renderStudyPlan = () => {
    if (!sortedItems.length) return null;
  
    return (
      <>

      {/* Study Plan Overview Card */}
      <div className="bg-gradient-to-r from-primary-blue to-blue-600 rounded-lg shadow-sm p-4 mb-6">
        <div className="flex flex-col space-y-4">
          <h3 className="text-white text-lg font-semibold">Study Plan Overview</h3>
          
          <div className="grid grid-cols-2 lg:grid-cols-5 gap-3">
            {/* Total Tasks */}
            <div className="bg-white/20 rounded-lg p-3">
              <div className="text-white/90 text-sm">Total Tasks</div>
              <div className="text-white text-xl font-bold flex items-center gap-2">
                <ListCheck className="w-5 h-5" />
                {studyPlanStats?.total || 0}
              </div>
            </div>
            
            {/* Due This Week */}
            <div className="bg-white/20 rounded-lg p-3">
              <div className="text-white/90 text-sm">Due This Week</div>
              <div className="text-white text-xl font-bold flex items-center gap-2">
                <Calendar className="w-5 h-5" />
                {studyPlanStats?.dueThisWeek || 0}
              </div>
            </div>

            {/* Late Tasks */}
            <div className="bg-white/20 rounded-lg p-3">
              <div className="text-white/90 text-sm">Late</div>
              <div className="text-white text-xl font-bold flex items-center gap-2">
                <AlertTriangle className="w-5 h-5" />
                {studyPlanStats?.late || 0}
              </div>
            </div>

            {/* Completed Tasks */}
            <div className="bg-white/20 rounded-lg p-3">
              <div className="text-white/90 text-sm">Completed</div>
              <div className="text-white text-xl font-bold flex items-center gap-2">
                <CheckCircle className="w-5 h-5" />
                {studyPlanStats?.completed || 0}
              </div>
            </div>

            {/* Remaining Tasks */}
            <div className="bg-white/20 rounded-lg p-3">
              <div className="text-white/90 text-sm">Remaining</div>
              <div className="text-white text-xl font-bold flex items-center gap-2">
                <ClipboardList className="w-5 h-5" />
                {studyPlanStats?.remaining || 0}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Use the CourseControls component */}
      <CourseControls />

      {/* Study Plan Mentor Section */}
      <StudyPlanMentor 
        courseId={courseId} 
        userId={userId}
        isExpanded={isMentorExpanded}
        onExpandChange={setIsMentorExpanded}
      />

      {/* Study Plan View */}
      <StudyPlanView
        weeks={weeks}
        items={sortedItems}
        primaryDisplayName={primaryDisplayName}
        secondaryDisplayName={secondaryDisplayName}
        taskDueDates={taskDueDates}
        isPrimaryBlueprint={isPrimaryBlueprint}
        isSecondaryBlueprint={isSecondaryBlueprint}
        handleTaskClick={handleTaskClick}
        isAllExpanded={isAllExpanded}
        activeProvider={activeProvider}
        startDate={startDate}
      />
    </>
  );
};

if (isLoading) {
  return <LoadingScreen message="Loading your study plan..." subMessage="This may take a few moments" />;
}

if (error) return <div className="text-red-500 text-sm md:text-base">Error loading study plan: {error.message}</div>;

if (!data || !data.userStudyPlan) {
  return <StudyPlanGenerator userId={userId} courseId={courseId} onComplete={() => window.location.reload()} />;
}

const { userStudyPlan, examDate, primaryCourse, secondaryCourse, courseMix } = data;

const primaryDisplayName = getProviderDisplayName(primaryCourse, courseMix?.alias);
const secondaryDisplayName = getProviderDisplayName(secondaryCourse, courseMix?.alias);

return (
  <div className="space-y-4 md:space-y-6 max-w-[1200px] mx-auto bg-gray-50 dark:bg-gray-900 rounded-lg shadow-md p-2 md:p-8">
    <div className="flex flex-row justify-between items-center">
      <h2 className="text-xl md:text-2xl font-bold">Your Study Plan</h2>
      <button
        onClick={handleResetClick}
        className="text-red-500 hover:text-red-700 text-sm md:text-base"
      >
        Reset Study Plan
      </button>
    </div>
    {showQuoteAlert && (
      <div className="flex-1">
        <QuoteAlert onClose={() => setShowQuoteAlert(false)} />
      </div>
    )}
    <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
      {examDate && (
        <div className="w-full md:w-1/2">
          <StudyPlanCountdown
            examDate={examDate ? formatDate(examDate) : null}
            startDate={data.startDate ? formatDate(data.startDate) : null}
            planId={userStudyPlan.plan_id}
            userId={userId}
            courseId={courseId}
            studyPlanItems={data.studyPlanItems}
            recommendedWeeklyHours={parseInt(data.userStudyPlan.recommended_weekly_hours)}
            isPrimaryBlueprint={isPrimaryBlueprint}
          />
        </div>
      )}
      <div className="w-full md:w-1/2">
        <StudyPlanExamDayTracker userId={userId} courseId={courseId} />
      </div>
    </div>

    {sortedItems.length > 0 ? (
      <div className="w-full">
        
        {/* Render new card-based study plan */}
        {renderStudyPlan()}

        {/* Add a message when no items are visible due to filters */}
        {sortedItems.length > 0 && filteredAndSortedItems.length === 0 && (
          <div className="text-center py-8 text-gray-500 dark:text-gray-400">
            No topics match your current filter settings
          </div>
        )}
      </div>
    ) : (
      <p className="text-sm md:text-base">No study tasks available. Your study plan is being generated.</p>
    )}
    <StudyPlanResetPopup
      isOpen={isResetPopupOpen}
      onClose={() => setIsResetPopupOpen(false)}
      onConfirm={handleResetConfirm}
    />
  </div>
);
}

export default StudyPlan;