import React from 'react';
import { Modal } from '../../../components/ui/Modal';
import { Button } from '../../../components/ui/Button';
import { useNavigate } from 'react-router-dom';
import { 
  QuizHistory, 
  isSimulationQuestion, 
  isMockExamSimulation, 
  isMockExamMCQ,
  isStudyTaskTestlet,
  StudyTaskTestletType,
  QuizQuestionData,
  api 
} from '../../../services/api';
import { useUserCourseAccess } from '../../UserCourseAccessProvider';

interface PopupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onResume: () => void;
  onEnd: () => void;
  activeQuiz: QuizHistory | null;
  courseId: string;
}

const PopupModal: React.FC<PopupModalProps> = ({ 
  isOpen, 
  onClose, 
  onResume, 
  onEnd,
  activeQuiz,
  courseId 
}) => {
  const navigate = useNavigate();
  const { userCourseAccesses } = useUserCourseAccess();

  const getQuizType = (quiz: QuizHistory) => {
    if (!quiz.questions_data?.[0]) return 'mcq';
    
    const firstQuestion = quiz.questions_data[0];
    
    if (isMockExamMCQ(firstQuestion)) return 'mock_exam';
    if (isStudyTaskTestlet(firstQuestion)) return 'study_task';
    if (isSimulationQuestion(firstQuestion) || isMockExamSimulation(firstQuestion)) return 'simulation';
    return 'mcq';
  };

  const getModalContent = () => {
    if (!activeQuiz) return {
      title: 'Active Quiz Session',
      message: 'You have an unfinished quiz. Would you like to resume the existing quiz or end the session and start a new one?'
    };

    const quizType = getQuizType(activeQuiz);
    
    switch (quizType) {
      case 'mock_exam':
        return {
          title: 'Active Mock Exam in Progress',
          message: 'You have an unfinished Mock Exam testlet. Would you like to resume it or end the session?'
        };
      case 'study_task':
        return {
          title: 'Active Study Task in Progress',
          message: 'You have an unfinished Study Task testlet. Would you like to resume it or end the session?'
        };
      case 'simulation':
        return {
          title: 'Active Simulation in Progress',
          message: 'You have an unfinished Simulation. Would you like to resume it or end the session?'
        };
      default:
        return {
          title: 'Active Quiz Session',
          message: 'You have an unfinished quiz. Would you like to resume the existing quiz or end the session and start a new one?'
        };
    }
  };

  const handleResume = async () => {
    if (!activeQuiz) {
      onResume();
      return;
    }

    try {
      const firstQuestion = activeQuiz.questions_data[0];

      // Handle mock exam MCQ
      if (isMockExamMCQ(firstQuestion)) {
        const searchParams = new URLSearchParams({
          mock_exam_testlet_id: activeQuiz.mock_exam_testlet_id || '',
          quiz_history_id: activeQuiz.id
        }).toString();

        navigate(`/course/${courseId}/quiz?${searchParams}`, { 
          state: { 
            resumeQuizId: activeQuiz.id,
            quizData: activeQuiz,
            mockExamId: firstQuestion.mockExamId,
            mockExamTestletId: activeQuiz.mock_exam_testlet_id,
            from: 'quiz-history',
            preserveParams: true
          },
          replace: false
        });
        return;
      }

      // Handle simulations
      if (isSimulationQuestion(firstQuestion) || isMockExamSimulation(firstQuestion)) {
        const unansweredSimulation = activeQuiz.questions_data.find(
          q => (isSimulationQuestion(q) || isMockExamSimulation(q)) && !q.answered
        );

        if (!unansweredSimulation) {
          console.error('No unanswered simulations found');
          return;
        }

        navigate(`/course/${courseId}/simulations`, { 
          state: { 
            resumeQuizId: activeQuiz.id,
            quizData: activeQuiz,
            currentSimulationIndex: activeQuiz.questions_data.findIndex(
              q => !q.answered
            )
          }
        });
        return;
      }

      // Handle study task testlets
      if (isStudyTaskTestlet(firstQuestion)) {
        try {
          let studyTaskId = firstQuestion.studyTaskId;
          let testletType: StudyTaskTestletType = 'learn';
          let testletId = activeQuiz.study_task_testlet_id || firstQuestion.testletId;

          // If we have a study_task_testlet_id, fetch the testlet data
          if (testletId) {
            const testletData = await api.getStudyTaskTestletById(testletId);
            if (testletData) {
              studyTaskId = testletData.study_task_id;
              testletType = testletData.type;
              testletId = testletData.id;
            }
          }

          if (!studyTaskId) {
            console.error('Unable to find study task ID');
            return;
          }

          const searchParams = new URLSearchParams({
            study_task_testlet_id: testletId,
            quiz_history_id: activeQuiz.id,
            study_task_id: studyTaskId,
            type: testletType
          }).toString();

          navigate(`/course/${courseId}/quiz?${searchParams}`, { 
            state: { 
              resumeQuizId: activeQuiz.id,
              quizData: activeQuiz,
              studyTaskTestletId: testletId,
              studyTaskId: studyTaskId,
              from: 'quiz-history',
              showSummary: false,
              quizCompleted: false,
              testletType: testletType,
              preserveParams: true
            },
            replace: false
          });
          return;
        } catch (error) {
          console.error('Error resuming study task testlet:', error);
          throw error;
        }
      }

      // Handle regular MCQ quiz
      navigate(`/course/${courseId}/quiz`, { 
        state: { 
          resumeQuizId: activeQuiz.id,
          quizData: activeQuiz,
          from: 'quiz-history'
        }
      });
    } catch (error) {
      console.error('Error in handleResume:', error);
      throw error;
    }
  };

  const handleEnd = async () => {
    if (!activeQuiz) {
      onEnd();
      return;
    }

    try {
      const quizType = getQuizType(activeQuiz);
      const firstQuestion = activeQuiz.questions_data[0];

      if (quizType === 'simulation') {
        // Calculate total time from all questions
        const totalTime = activeQuiz.questions_data.reduce(
          (sum, q) => sum + (q.timeSpent || 0), 
          0
        );

        // End the TBS session first
        await api.endTBSSession(activeQuiz.id, totalTime);

        // Get simulation IDs from questions that are simulations
        const simulationIds = activeQuiz.questions_data
          .filter((q): q is QuizQuestionData & { simulationId: string } => 
            (isSimulationQuestion(q) || isMockExamSimulation(q)) && 'simulationId' in q)
          .map(q => q.simulationId);

        // Navigate to simulation review with the correct IDs
        navigate(`/course/${courseId}/simulation-review`, {
          state: { 
            simulationIds,
            courseId,
            quizId: activeQuiz.id,
            quizHistoryId: activeQuiz.id,
            from: 'quiz',
            userCourseAccess: Array.isArray(userCourseAccesses) 
              ? userCourseAccesses.find(access => access.course_id === courseId)
              : userCourseAccesses
          }
        });
      } else {
        // Calculate final stats for non-simulation quizzes
        const answeredQuestions = activeQuiz.questions_data.filter(q => q.answered);
        const totalTime = answeredQuestions.reduce((sum, q) => sum + (q.timeSpent || 0), 0);
        
        // If this is a study task testlet, update it
        if (activeQuiz.study_task_testlet_id) {
          await api.updateStudyTaskTestletFromQuizHistory(
            activeQuiz.study_task_testlet_id,
            activeQuiz.id
          );
        }

        // End the quiz session
        await api.endQuizSession(activeQuiz.id, totalTime, true);

        // For mock exams and study tasks, include additional state params
        const additionalState = isMockExamMCQ(firstQuestion) ? {
          mockExamId: firstQuestion.mockExamId,
          mockExamTestletId: activeQuiz.mock_exam_testlet_id
        } : isStudyTaskTestlet(firstQuestion) ? {
          studyTaskId: firstQuestion.studyTaskId,
          studyTaskTestletId: activeQuiz.study_task_testlet_id
        } : {};

        // Navigate to quiz with summary state
        navigate(`/course/${courseId}/quiz`, { 
          state: { 
            viewQuizId: activeQuiz.id,
            from: 'analytics',
            showSummary: true,
            quizCompleted: true,
            ...additionalState,
            userCourseAccess: Array.isArray(userCourseAccesses) 
              ? userCourseAccesses.find(access => access.course_id === courseId)
              : userCourseAccesses
          }
        });
      }
      
      onEnd();
    } catch (error) {
      console.error('Error ending quiz session:', error);
      throw error;
    }
  };

  const content = getModalContent();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg w-full max-w-[90%] sm:max-w-md">
        <h2 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 text-gray-800 dark:text-white">
          {content.title}
        </h2>
        <p className="mb-4 text-sm sm:text-base text-gray-600 dark:text-gray-300">
          {content.message}
        </p>
        <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
          <Button
            onClick={handleResume}
            className="w-full sm:w-auto bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200"
          >
            Resume Session
          </Button>
          <Button
            onClick={handleEnd}
            className="w-full sm:w-auto bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-200"
          >
            End Session
          </Button>
          <Button
            onClick={onClose}
            variant="outline"
            className="w-full sm:w-auto bg-transparent hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 hover:text-gray-900 dark:hover:text-white"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PopupModal;