import { useQuery, useQueryClient } from 'react-query';
import { useState } from 'react';
import { 
  api, 
  ExtendedSimulationStats,
  DEFAULT_STATS,
  CACHE_KEYS 
} from '../services/api';
import { useActiveQuiz, ActiveQuizState, getTestletType, isValidActiveQuiz } from './useActiveQuiz';

interface GroupedCategory {
  id: string;
  course_id: string;
  parent_question_category_name: string;
  unitNumber?: number;
  categoryIds: string[];
  stats: ExtendedSimulationStats;
}

interface SimulationDataResult {
  groupedCategories: GroupedCategory[];
  overallStats: ExtendedSimulationStats;
}

const DEFAULT_FILTERS = [
  'maybe', 'correct', 'guessing', 'confident', 'incorrect', 'unanswered',
  'maybe_correct', 'maybe_incorrect', 'guessing_correct', 'confident_correct',
  'guessing_incorrect', 'confident_incorrect'
];

export const useSimulationData = (courseId: string, userId: string) => {
  const queryClient = useQueryClient();
  const [activeQuizState, setActiveQuizState] = useState<ActiveQuizState>({
    activeQuiz: null,
    activeTestletType: null,
    showActiveQuizModal: false
  });

  const { data: activeQuizData } = useActiveQuiz(userId, courseId, {
    onSuccess: (quiz) => {
      if (quiz && isValidActiveQuiz(quiz)) {
        setActiveQuizState({
          activeQuiz: quiz,
          activeTestletType: getTestletType(quiz),
          showActiveQuizModal: true
        });
      } else {
        setActiveQuizState({
          activeQuiz: null,
          activeTestletType: null,
          showActiveQuizModal: false
        });
      }
    }
  });

  // Update query key to use constant
  const queryKey = CACHE_KEYS.SIMULATION_DATA(courseId, userId);

  const { data: simulationData, isLoading, error, refetch } = useQuery<SimulationDataResult, Error>(
    queryKey,
    async () => {
      try {
        const categories = await api.getSimulationCategories(courseId);
        const allCategoryIds = categories.map(cat => cat.id);
        
        const categoryStats = await api.getSimulationCategoryStats(
          courseId,
          allCategoryIds,
          DEFAULT_FILTERS,
          userId
        );

        const statsMap = new Map(
          categoryStats.map(stat => [stat.parent_category, stat.category_stats])
        );

        const groupedByParent = categories.reduce<Record<string, string[]>>((acc, cat) => {
          const match = cat.parent_question_category_name.match(/Unit (\d+):/);
          if (!match) return acc;
          
          const parentStats = statsMap.get(cat.parent_question_category_name);
          if (parentStats && (parentStats.total ?? 0) > 0) {
            const parentKey = cat.parent_question_category_name;
            if (!acc[parentKey]) {
              acc[parentKey] = [];
            }
            acc[parentKey].push(cat.id);
          }
          return acc;
        }, {});

        const overallStats = categoryStats.reduce<ExtendedSimulationStats>((acc, curr) => {
          if (!curr.category_stats.total) return acc;
          
          return {
            total: (acc.total || 0) + (curr.category_stats.total || 0),
            unanswered: (acc.unanswered || 0) + (curr.category_stats.unanswered || 0),
            correct: (acc.correct || 0) + (curr.category_stats.correct || 0),
            incorrect: (acc.incorrect || 0) + (curr.category_stats.incorrect || 0),
            confident: (acc.confident || 0) + (curr.category_stats.confident || 0),
            maybe: (acc.maybe || 0) + (curr.category_stats.maybe || 0),
            guessing: (acc.guessing || 0) + (curr.category_stats.guessing || 0),
            confident_correct: (acc.confident_correct || 0) + (curr.category_stats.confident_correct || 0),
            confident_incorrect: (acc.confident_incorrect || 0) + (curr.category_stats.confident_incorrect || 0),
            maybe_correct: (acc.maybe_correct || 0) + (curr.category_stats.maybe_correct || 0),
            maybe_incorrect: (acc.maybe_incorrect || 0) + (curr.category_stats.maybe_incorrect || 0),
            guessing_correct: (acc.guessing_correct || 0) + (curr.category_stats.guessing_correct || 0),
            guessing_incorrect: (acc.guessing_incorrect || 0) + (curr.category_stats.guessing_incorrect || 0)
          };
        }, DEFAULT_STATS);

        const groupedCategories = Object.entries(groupedByParent)
          .map(([parentName, categoryIds]) => {
            const match = parentName.match(/Unit (\d+):/);
            const unitNumber = match ? parseInt(match[1], 10) : undefined;
            const stats = statsMap.get(parentName) || DEFAULT_STATS;

            return {
              id: categoryIds[0],
              course_id: courseId,
              parent_question_category_name: parentName,
              unitNumber,
              categoryIds,
              stats
            };
          })
          .sort((a, b) => (a.unitNumber || 0) - (b.unitNumber || 0));

        return {
          groupedCategories,
          overallStats
        };
      } catch (error) {
        console.error('Error fetching simulation data:', error);
        throw error;
      }
    },
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000
    }
  );

  const invalidateSimulationData = async () => {
    try {
      await Promise.all([
        queryClient.invalidateQueries(queryKey),
        queryClient.invalidateQueries(['selectedCategoriesStats']),
        api.invalidateSimulationCaches()
      ]);
      await refetch();
    } catch (error) {
      console.error('Error invalidating simulation data:', error);
    }
  };

  const useSelectedCategoriesStats = (
    selectedIds: string[],
    filters: string[] = DEFAULT_FILTERS,
    enabled: boolean = true
  ) => {
    return useQuery<ExtendedSimulationStats, Error>(
      ['selectedCategoriesStats', courseId, selectedIds.join(','), filters.join(',')],
      async () => {
        const categoryStats = await api.getSimulationCategoryStats(
          courseId,
          selectedIds,
          filters,
          userId
        );

        return categoryStats.reduce<ExtendedSimulationStats>((acc, curr) => ({
          total: (acc.total || 0) + (curr.category_stats.total || 0),
          unanswered: (acc.unanswered || 0) + (curr.category_stats.unanswered || 0),
          correct: (acc.correct || 0) + (curr.category_stats.correct || 0),
          incorrect: (acc.incorrect || 0) + (curr.category_stats.incorrect || 0),
          confident: (acc.confident || 0) + (curr.category_stats.confident || 0),
          maybe: (acc.maybe || 0) + (curr.category_stats.maybe || 0),
          guessing: (acc.guessing || 0) + (curr.category_stats.guessing || 0),
          confident_correct: (acc.confident_correct || 0) + (curr.category_stats.confident_correct || 0),
          confident_incorrect: (acc.confident_incorrect || 0) + (curr.category_stats.confident_incorrect || 0),
          maybe_correct: (acc.maybe_correct || 0) + (curr.category_stats.maybe_correct || 0),
          maybe_incorrect: (acc.maybe_incorrect || 0) + (curr.category_stats.maybe_incorrect || 0),
          guessing_correct: (acc.guessing_correct || 0) + (curr.category_stats.guessing_correct || 0),
          guessing_incorrect: (acc.guessing_incorrect || 0) + (curr.category_stats.guessing_incorrect || 0)
        }), DEFAULT_STATS);
      },
      {
        enabled: enabled && selectedIds.length > 0,
        staleTime: 30 * 1000,
        cacheTime: 5 * 60 * 1000,
        keepPreviousData: true
      }
    );
  };

  const resetSimulations = async (categoryIds: string[]) => {
    try {
      await api.resetSimulations(userId, courseId, categoryIds);
      await invalidateSimulationData();
    } catch (error) {
      console.error('Error resetting simulations:', error);
      throw error;
    }
  };

  const resetActiveQuizCheck = () => {
    setActiveQuizState({
      activeQuiz: null,
      activeTestletType: null,
      showActiveQuizModal: false
    });
  };

  return {
    simulationData: simulationData || { groupedCategories: [], overallStats: DEFAULT_STATS },
    isLoading,
    error,
    useSelectedCategoriesStats,
    resetSimulations,
    invalidateSimulationData,
    activeQuizState,
    setActiveQuizState,
    resetActiveQuizCheck
  };
};