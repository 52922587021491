import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ProfileDropdown from './ProfileDropdown';
import BottomNavigation from './mobile/BottomNavigation';
import CourseDropdown from './CourseDropdown';
import { useAuth } from '../AuthProvider';
import { useTheme } from '../../contexts/ThemeContext';
import TextSelectionPopup from '../course/PiracyProtection/TextSelectionPopup';
import Sidebar from '../../pages/Sidebar';
import { Menu } from 'lucide-react';
import Footer from './Footer';

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  const location = useLocation();
  const { courseId } = useParams<{ courseId?: string }>();
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCoursePage, setIsCoursePage] = useState(false);
  const [currentCourseId, setCurrentCourseId] = useState<string | undefined>(undefined);
  const [isMobileView, setIsMobileView] = useState(false);

  const isSupportDashboard = location.pathname === '/admin/support';
  const isAdminRoute = location.pathname.startsWith('/admin');
  const isProductsPage = location.pathname === '/products';

  // Handle mobile/desktop view
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 1350 || window.innerHeight < 825;
      setIsMobileView(isMobile);
      
      // Only auto-close menu when transitioning to desktop if not on products page
      if (!isMobile && isMenuOpen && !isProductsPage) {
        setIsMenuOpen(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMenuOpen, isProductsPage]);

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const isCourse = pathParts[1] === 'course' && pathParts[2];
    setIsCoursePage(!!isCourse);
    setCurrentCourseId(isCourse ? pathParts[2] : undefined);
  }, [location]);

  useEffect(() => {
    if (isAdminRoute) {
      const style = document.createElement('style');
      style.innerHTML = `
        .admin-content {
          user-select: none;
          -webkit-user-select: none;
        }
        [data-question-content="true"] {
          user-select: text;
          -webkit-user-select: text;
        }
      `;
      document.head.appendChild(style);
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [isAdminRoute]);

  // Determine if sidebar should be shown
  const shouldShowSidebar = !isMobileView && isCoursePage;

  // Determine if hamburger menu should be shown
  const shouldShowHamburger = isMobileView || isProductsPage;

  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark bg-gray-900' : 'bg-gray-100'}`}>
      {/* Fixed Sidebar - Only shown in course pages on desktop */}
      {shouldShowSidebar && (
        <div className="fixed top-0 left-0 h-screen z-30">
          <Sidebar
            courseId={currentCourseId}
            user={user}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        </div>
      )}

      {/* Main Content Area */}
      <div 
        className={`
          transition-all duration-300 
          ${shouldShowSidebar ? 'ml-[var(--sidebar-width)]' : ''} 
          md:pr-6 md:pl-4 pt-2 pb-2
        `}
      >
        <div className="min-h-screen flex flex-col">
          {/* Header */}
          <header className="bg-white shadow-sm dark:bg-gray-800 h-16 mb-3 sticky top-0 z-20">
            <div className="h-full px-2 sm:px-4 lg:px-6">
              <div className="flex justify-between items-center h-full">
                <div className="flex items-center h-full space-x-2">
                  {shouldShowHamburger && (
                    <button
                      onClick={() => setIsMenuOpen(true)}
                      className="text-gray-600 hover:text-gray-800 
                        focus:outline-none dark:text-gray-300 dark:hover:text-gray-100 
                        w-10 h-10 flex items-center justify-center"
                      aria-label="Open menu"
                    >
                      <Menu size={24} />
                    </button>
                  )}
                  {isCoursePage && (
                    <CourseDropdown currentCourseId={currentCourseId} />
                  )}
                </div>
                <Link 
                  to="/products" 
                  className="absolute left-1/2 transform -translate-x-1/2 h-full flex items-center"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/logo/kesler-course-logo-blue-orange.png`}
                    alt="Kesler CPA Review"
                    className="h-8 w-auto dark:hidden"
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/logo/kesler-course-logo-white.png`}
                    alt="Kesler CPA Review"
                    className="h-8 w-auto hidden dark:block"
                  />
                </Link>
                <div className="flex items-center h-full">
                  <ProfileDropdown />
                </div>
              </div>
            </div>
          </header>

          {/* Main Content */}
          <main className="flex-1">
            {isSupportDashboard ? (
              <div className="h-full overflow-hidden">
                {children}
              </div>
            ) : (
              <div className="h-full">
                {children}
              </div>
            )}
          </main>

          {/* Footer */}
          {!isSupportDashboard && <Footer />}
        </div>
      </div>

      {/* Menu Overlay */}
      {isMenuOpen && (
        <Sidebar
          courseId={currentCourseId}
          user={user}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
      )}
      
      <BottomNavigation currentPath={location.pathname} isInsideCourse={isCoursePage} />
      
      {!isAdminRoute && user && currentCourseId && (
        <TextSelectionPopup courseId={currentCourseId} />
      )}
    </div>
  );
}

export default Layout;