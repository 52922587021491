import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { auth } from '../../services/auth';
import { api } from '../../services/api';
import { useAuth } from '../AuthProvider';
import { getBrowserInstanceId } from '../../utils/browserInstance';
import { Button } from '../ui/Button';
import { Alert, AlertDescription } from '../ui/Alert';
import { Mail, AlertCircle, CheckCircle, ArrowLeft, Loader } from 'lucide-react';

const VerifyEmail: React.FC = () => {
  const [verificationStatus, setVerificationStatus] = useState<'pending' | 'success' | 'error'>('pending');
  const [email, setEmail] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isResending, setIsResending] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [browserInstanceId, setBrowserInstanceId] = useState<string | null>(null);

  useEffect(() => {
    const initializeComponent = async () => {
      const emailFromState = (location.state as { email?: string })?.email;
      const browserInstanceIdFromState = (location.state as { browserInstanceId?: string })?.browserInstanceId;
      setEmail(emailFromState || user?.email || null);
      
      const instanceId = browserInstanceIdFromState || await getBrowserInstanceId();
      setBrowserInstanceId(instanceId);

      const verifyEmail = async () => {
        const token = new URLSearchParams(location.search).get('token');
        if (token) {
          try {
            const { data, error } = await auth.verifyEmail(token);
            if (error) throw error;
            setVerificationStatus('success');
            if (instanceId) {
              await api.createUserInstance(instanceId);
            }
            setTimeout(() => navigate('/login'), 3000);
          } catch (error) {
            console.error('Verification error:', error);
            setVerificationStatus('error');
            setError((error as Error).message || 'An error occurred during verification');
          }
        }
      };

      verifyEmail();
    };

    initializeComponent();
  }, [location, navigate, user]);

  const handleResendVerification = async () => {
    if (!email) {
      setError('Unable to resend verification email. Email address is missing.');
      return;
    }

    setIsResending(true);
    setError(null);

    try {
      await auth.sendVerificationEmail(email);
      setVerificationStatus('pending');
    } catch (error) {
      setError('Failed to send verification email. Please try again.');
    } finally {
      setIsResending(false);
    }
  };

  const getStatusAlert = () => {
    switch (verificationStatus) {
      case 'success':
        return (
          <Alert className="bg-green-50 dark:bg-green-900/30 border-green-200 dark:border-green-800">
            <div className="flex items-center gap-3">
              <CheckCircle className="h-4 w-4 text-green-600 dark:text-green-400 flex-shrink-0" />
              <AlertDescription className="text-green-700 dark:text-green-300">
                Your email has been successfully verified! Redirecting to login...
              </AlertDescription>
            </div>
          </Alert>
        );
      case 'error':
        return (
          <Alert className="bg-red-50 dark:bg-red-900/30 border-red-200 dark:border-red-800">
            <div className="flex items-center gap-3">
              <AlertCircle className="h-4 w-4 text-red-600 dark:text-red-400 flex-shrink-0" />
              <AlertDescription className="text-red-700 dark:text-red-300">
                {error || 'Failed to verify email. The link may have expired.'}
              </AlertDescription>
            </div>
          </Alert>
        );
      default:
        return (
          <Alert className="bg-blue-50 dark:bg-blue-900/30 border-blue-200 dark:border-blue-800">
            <div className="flex items-center gap-3">
              <Mail className="h-4 w-4 text-blue-600 dark:text-blue-400 flex-shrink-0" />
              <AlertDescription className="text-blue-700 dark:text-blue-300">
                Please check your email for a verification link.
              </AlertDescription>
            </div>
          </Alert>
        );
    }
  };

  return (
    <div className="min-h-screen flex items-top justify-center py-6 px-4 sm:px-6 lg:px-8 bg-gray-50 dark:bg-gray-900">
      <div className="w-full max-w-md space-y-8">
        {/* Header Section */}
        <div className="text-center">
          <Link 
            to="/login"
            className="inline-flex items-center text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200 mb-8"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Login
          </Link>
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
            Email Verification
          </h2>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            {email ? `We sent a verification email to ${email}` : 'Please verify your email address'}
          </p>
        </div>

        {/* Status Alert */}
        <div className="space-y-6">
          {getStatusAlert()}

          {/* Resend Button */}
          {email && verificationStatus !== 'success' && (
            <Button
              onClick={handleResendVerification}
              disabled={isResending}
              className="w-full flex justify-center py-3 px-4 border border-transparent 
                       rounded-lg shadow-sm text-sm font-medium text-white
                       bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600
                       focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                       disabled:opacity-50 disabled:cursor-not-allowed
                       transition duration-150 ease-in-out"
            >
              {isResending ? (
                <div className="flex items-center">
                  <Loader className="w-5 h-5 mr-2 animate-spin" />
                  Sending...
                </div>
              ) : (
                <>
                  <Mail className="w-5 h-5 mr-2" />
                  Resend Verification Email
                </>
              )}
            </Button>
          )}

          {/* No Email Warning */}
          {!email && verificationStatus !== 'success' && (
            <Alert className="bg-amber-50 dark:bg-amber-900/30 border-amber-200 dark:border-amber-800">
              <div className="flex items-center gap-3">
                <AlertCircle className="h-4 w-4 text-amber-600 dark:text-amber-400 flex-shrink-0" />
                <AlertDescription className="text-amber-700 dark:text-amber-300">
                  Unable to resend verification email. Please try logging in again to set your email address.
                </AlertDescription>
              </div>
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;