import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { 
  api, 
  Simulation, 
  SimulationCategory,
  SimulationQuestionData, 
  MockExamSimulationQuestionData,
  TIER_IDS,
  FeedbackItem,
  QuizHistory,
  ConfidenceLevelValue,
  BatchUserTBSResponse,
  ExtendedSimulationStats,
  SimulationCounts,
  DEFAULT_STATS
} from '../../services/api';
import { 
  Sparkle, 
  Target,
  Scale, 
  Shuffle, 
  Crown, 
  ListCheck, 
  FileQuestion, 
  CheckCircle,
} from 'lucide-react';
import SimulationQuiz from './SimulationsQuiz';
import SimulationsCustomizer from './SimulationsCustomizer';
import { useTheme } from '../../contexts/ThemeContext';
import { useAuth } from '../AuthProvider';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import LoadingScreen from './common/LoadingScreen';
import SimulationResetCategoryPopup from './SimulationResetCategoryPopup';
import UpgradeModal from './common/UpgradeModal';
import { Button } from '../../components/ui/Button';
import { useQueryClient } from 'react-query';
import { useSimulationData } from '../../hooks/useSimulationData';
import { useActiveQuiz, ActiveQuizState, getTestletType } from '../../hooks/useActiveQuiz';
import PopupModal from './common/PopupModal';


interface SimulationsProps {
  courseId: string;
  userId: string;
  mockExamTestletId?: string;
  quizHistoryId?: string;
}

interface SimulationCategoryWithStatus extends SimulationCategory {
  total: number;
  completed: number;
  unitNumber?: number;
  categoryIds: string[];
}

interface SimulationResult {
  simulation: Simulation;
  userAnswers: Record<string, any>;
  score: number;
  feedback: FeedbackItem[];
  confidenceLevel: ConfidenceLevelValue;
  timeSpent: number;
}

// Type for the category objects
interface GroupedCategory {
  id: string;
  course_id: string;
  parent_question_category_name: string;
  unitNumber?: number;
  categoryIds: string[];
  stats: ExtendedSimulationStats;
}

// Type definitions
type ConfidenceLevel = 'guessing' | 'maybe' | 'confident';
type CurrentView = 'categories' | 'simulation' | 'summary';
type StatusFilter = 'unanswered' | 'correct' | 'incorrect';

// Transform function to convert between types
const transformToSimulationCounts = (stats: ExtendedSimulationStats): SimulationCounts => ({
  total: stats.total,
  unanswered: stats.unanswered,
  correct: stats.correct,
  incorrect: stats.incorrect,
  confident: stats.confident,
  maybe: stats.maybe,
  guessing: stats.guessing,
  confident_correct: stats.confident_correct,
  confident_incorrect: stats.confident_incorrect,
  maybe_correct: stats.maybe_correct,
  maybe_incorrect: stats.maybe_incorrect,
  guessing_correct: stats.guessing_correct,
  guessing_incorrect: stats.guessing_incorrect
});

const Simulations: React.FC<SimulationsProps> = ({ 
  courseId, 
  userId, 
  mockExamTestletId: propMockExamTestletId, 
  quizHistoryId: propQuizHistoryId 
}) => {
  const { user } = useAuth();
  // FREE TRIAL
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { userCourseAccesses } = useUserCourseAccess();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [simulationCategories, setSimulationCategories] = useState<SimulationCategoryWithStatus[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedSimulation, setSelectedSimulation] = useState<Simulation | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [showCustomizer, setShowCustomizer] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [currentSimulationIndex, setCurrentSimulationIndex] = useState<number>(0);
  const [totalSimulations, setTotalSimulations] = useState<number>(0);
  const [simulationResults, setSimulationResults] = useState<SimulationResult[]>([]);
  const [currentQuizHistoryId, setCurrentQuizHistoryId] = useState<string | undefined>(undefined);
  const [userResponses, setUserResponses] = useState<BatchUserTBSResponse>({});

  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);

  // Customizer state
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [customSimulationCount, setCustomSimulationCount] = useState(2);
  const [keslerQFilter, setKeslerQFilter] = useState<string[]>(['unanswered']);
  const [quizStarted, setQuizStarted] = useState(false);
  
  const {
    simulationData,
    isLoading: isLoadingSimData,
    error: simDataError,
    useSelectedCategoriesStats,
    resetSimulations: resetSimulationsFromHook,
    activeQuizState,
    setActiveQuizState,
    resetActiveQuizCheck,
    invalidateSimulationData // Add this destructure
  } = useSimulationData(courseId, userId);
  
  // Use selected categories stats
  const {
    data: selectedStats,
    isLoading: isLoadingSelectedStats
  } = useSelectedCategoriesStats(
    selectedCategories,
    keslerQFilter,
    selectedCategories.length > 0
  );
  
  // Update the display stats memo
  const displayStats = useMemo(() => {
    if (selectedCategories.length === 0) {
      return simulationData.overallStats;
    }
    return selectedStats || DEFAULT_STATS;
  }, [selectedCategories.length, simulationData.overallStats, selectedStats]);

  // Use props or search params for IDs
  const effectiveTestletId = propMockExamTestletId || searchParams.get('mock_exam_testlet_id') || undefined;
  const effectiveQuizHistoryId = propQuizHistoryId || searchParams.get('quiz_history_id') || undefined;
  
  // Timer States
  const currentElapsedTimeRef = useRef<number>(0);

  //reset popup
  const [showResetPopup, setShowResetPopup] = useState(false);
  const [isResettingCategories, setIsResettingCategories] = useState(false);
  const queryClient = useQueryClient();
  
  // Helper function to determine testlet type
  const getTestletType = (quiz: QuizHistory): 'mock_exam' | 'study_task' | null => {
    const firstQuestion = quiz.questions_data[0];
    if (firstQuestion.type === 'mock_exam_mcq' || firstQuestion.type === 'mock_exam_sim') {
      return 'mock_exam';
    }
    if (firstQuestion.type === 'sp_studytasktestlet') {
      return 'study_task';
    }
    return null;
  };

  // Effect to sync session storage with activeQuizState
  useEffect(() => {
    if (activeQuizState.activeQuiz && activeQuizState.showActiveQuizModal) {
      sessionStorage.setItem('simulation_quiz_active', 'true');
      sessionStorage.setItem('current_quiz_id', activeQuizState.activeQuiz.id);
    } else {
      sessionStorage.removeItem('simulation_quiz_active');
      sessionStorage.removeItem('current_quiz_id');
    }
  }, [activeQuizState]);


  // Make mockExamId consistent with props typing
  const [mockExamId, setMockExamId] = useState<string | undefined>(undefined);
  
  // Define isMockExam based on effective ID
  const isMockExam = Boolean(effectiveTestletId);

  // If mockExamTestletId is provided, we should NOT recreate quiz history if quizHistoryId is given.
  // If quizHistoryId is not given, that means user navigated here incorrectly, or we need to handle that scenario.

  // For Mock Exam scenario:
  // If mockExamTestletId && currentQuizHistoryId are provided, we just resume that quiz
  // If only mockExamTestletId is provided and NOT quizHistoryId, it means no quiz history was created.
  // But we fixed it in MockExamDetail.tsx: now it always creates quiz_history before navigating.
  // So we assume quiz_history_id is always provided for mock exam testlets now.

  // Updated fetchSimulationCategories with new tier access logic

  // Add new function to check access level
  const hasFullSimulationAccess = React.useMemo(() => {
    if (!userCourseAccesses || userCourseAccesses.length === 0) return false;
    
    const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
    if (courseAccesses.length === 0) return false;

    const hasFullAccess = courseAccesses.some(
      access => access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY || access.tier_id === TIER_IDS.FULL_ACCESS
    );

    return hasFullAccess;
  }, [userCourseAccesses, courseId]);

  // Add upgrade button visibility check
  const showUpgradeButton = useMemo(() => {
    if (!userCourseAccesses || userCourseAccesses.length === 0) {
      return true;
    }
    
    const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
    if (courseAccesses.length === 0) {
      console.warn(`No access found for course ID: ${courseId}`);
      return true;
    }

    const hasFullAccess = courseAccesses.some(access => 
      access.tier_id === TIER_IDS.FULL_ACCESS || 
      access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY
    );

    if (hasFullAccess) {
      return false;
    }

    const onlyHasRestrictedAccess = courseAccesses.every(access =>
      access.tier_id === TIER_IDS.FREE_TRIAL || 
      access.tier_id === TIER_IDS.MENTOR_ONLY
    );

    return onlyHasRestrictedAccess;
  }, [userCourseAccesses, courseId]);

  // Add upgrade click handler
  const handleUpgradeClick = useCallback((): void => {
    setShowUpgradeModal(true);
  }, []);

  const fetchSimulationCategories = useCallback(async () => {
    if (!courseId || !userId) return;

    try {
      setLoading(true);
      const [
        categoriesData,
        simulationsData,
        batchResponses
      ] = await Promise.all([
        api.getSimulationCategories(courseId),
        api.getSimulations(courseId),
        api.getAllUserTBSResponses(userId, courseId)
      ]);

      setUserResponses(batchResponses);

      // Filter simulations based on access level
      const accessibleSimulations = hasFullSimulationAccess 
        ? simulationsData 
        : simulationsData.filter(sim => sim.free_trial_content);

      const groupedCategories = categoriesData.reduce<Record<string, SimulationCategoryWithStatus>>(
        (acc, category) => {
          const unitMatch = category.parent_question_category_name.match(/Unit (\d+):/);
          if (!unitMatch) return acc;

          const unitNumber = parseInt(unitMatch[1], 10);
          const unitKey = `Unit ${unitNumber}`;

          const categorySimulations = accessibleSimulations.filter(sim => 
            sim.question_category_id === category.id
          );

          const completedSimulations = categorySimulations.filter(sim => 
            batchResponses[sim.id]?.completed
          ).length;

          if (!acc[unitKey]) {
            acc[unitKey] = {
              id: category.id,
              course_id: category.course_id,
              parent_question_category_name: category.parent_question_category_name,
              sub_question_category_name: '',
              total: 0,
              completed: 0,
              unitNumber,
              categoryIds: []
            };
          }

          acc[unitKey].total += categorySimulations.length;
          acc[unitKey].completed += completedSimulations;
          if (categorySimulations.length > 0) {
            acc[unitKey].categoryIds.push(category.id);
          }

          return acc;
        },
        {}
      );

      const processedCategories = Object.values(groupedCategories)
        .filter(category => category.total > 0)
        .sort((a, b) => (a.unitNumber || 0) - (b.unitNumber || 0));

      setSimulationCategories(processedCategories);
      setLoading(false);
    } catch (err) {
      console.error('Failed to fetch simulation categories:', err);
      setError('Failed to load simulation categories. Please try again.');
      setLoading(false);
    }
  }, [courseId, userId, hasFullSimulationAccess]);

  useEffect(() => {
    fetchSimulationCategories();
  }, [fetchSimulationCategories]);

  // MOCK EXAM CODE
  // If this is a mock exam testlet scenario, we just load the first simulation and quiz history
  // Update the mock exam initialization effect
  useEffect(() => {
    const initializeMockExamTestlet = async () => {
      if (!effectiveTestletId || !userId || !courseId || !user || !effectiveQuizHistoryId) {
        setError('Missing required data for mock exam testlet.');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);

        // Get mock exam testlet to find mock exam ID
        const testlet = await api.getMockExamTestletById(effectiveTestletId);
        setMockExamId(testlet.mock_exam_id);

        // Load quiz history to resume
        const quizHistory = await api.getSimulationQuizHistoryById(effectiveQuizHistoryId);
        const firstSimId = (quizHistory.questions_data[0] as SimulationQuestionData).simulationId;
        const firstSimulation = await api.getSimulationsById([firstSimId], userId, courseId);

        setSelectedSimulation(firstSimulation[0]);
        setTotalSimulations(quizHistory.questions_data.length);
        setCurrentSimulationIndex(0);
        setSimulationResults([]);
        sessionStorage.removeItem('simulationResults');
        setCurrentQuizHistoryId(effectiveQuizHistoryId);

      } catch (err) {
        console.error('Failed to initialize mock exam testlet:', err);
        setError('Failed to load mock exam simulation testlet. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    if (effectiveTestletId && effectiveQuizHistoryId) {
      initializeMockExamTestlet();
    }
  }, [effectiveTestletId, userId, courseId, user, effectiveQuizHistoryId]);


  // Resuming logic if normal scenario (not mock exam)
  const resumeState = location.state as {
    resumeQuizId?: string;
    quizData?: QuizHistory;
    currentSimulationIndex?: number;
    simulation?: Simulation;
  } | null;

  useEffect(() => {
    const handleResumeState = async () => {
      if (resumeState?.resumeQuizId && resumeState.quizData && !isMockExam) {
        try {
          setLoading(true);
          
          // Updated type guard to check for both simulation types
          const unansweredSimulation = resumeState.quizData.questions_data.find(
            (q): q is SimulationQuestionData | MockExamSimulationQuestionData => 
              (q.type === 'simulation' || q.type === 'mock_exam_sim') && !q.answered
          );
    
          if (!unansweredSimulation) {
            console.error('No unanswered simulations found');
            return;
          }
    
          const [simulation] = await api.getSimulationsById(
            [unansweredSimulation.simulationId],
            userId,
            courseId
          );
    
          if (!simulation) {
            console.error('Failed to fetch simulation data');
            return;
          }
    
          setSelectedSimulation(simulation);
          setCurrentSimulationIndex(
            resumeState.quizData.questions_data.findIndex(
              q => (q.type === 'simulation' || q.type === 'mock_exam_sim') && 
                   q.simulationId === unansweredSimulation.simulationId
            )
          );
          setTotalSimulations(resumeState.quizData.questions_data.length);
          setCurrentQuizHistoryId(resumeState.resumeQuizId);
          
          setSimulationResults([]);
          sessionStorage.removeItem('simulationResults');
          setQuizStarted(true);
    
          const batchResponses = await api.getAllUserTBSResponses(userId, courseId);
          setUserResponses(batchResponses);
        } catch (error) {
          console.error('Error handling resume state:', error);
          setError('Failed to resume simulation. Please try again.');
        } finally {
          setLoading(false);
        }
      }
    };

    handleResumeState();
  }, [resumeState, userId, courseId, isMockExam]);

  // Update how we use the hook
  const { checkForActiveQuiz } = useActiveQuiz(userId, courseId, {
    enabled: false
  });

  // Update handleCategorySelection
// Update handleCategorySelection
const handleCategorySelection = useCallback(async (categoryId: string) => {
  console.log('handleCategorySelection called with categoryId:', categoryId);
  
  // First handle the category selection
  setSelectedCategories(prev => {
    const selectedCategory = simulationData.groupedCategories.find(cat => cat.id === categoryId);
    console.log('Found category:', selectedCategory);
    if (!selectedCategory) return prev;

    const hasSelected = selectedCategory.categoryIds.some(id => prev.includes(id));
    console.log('Category was previously selected:', hasSelected);
    const newSelection = hasSelected
      ? prev.filter(id => !selectedCategory.categoryIds.includes(id))
      : [...prev, ...selectedCategory.categoryIds];
    
    console.log('New selection:', newSelection);
    setShowCustomizer(newSelection.length > 0);
    return newSelection;
  });

  // Then check for active quiz only if we're showing the customizer
  try {
    console.log('Checking for active quiz...');
    const { data: activeQuiz } = await checkForActiveQuiz();
    console.log('Active quiz check result:', activeQuiz);
    
    if (activeQuiz) {
      console.log('Active quiz found, updating state');
      setActiveQuizState({
        activeQuiz,
        activeTestletType: getTestletType(activeQuiz),
        showActiveQuizModal: true
      });
    }
  } catch (error) {
    console.error('Error checking for active quiz:', error);
    setError?.('Failed to check for active quizzes');
  }
}, [simulationData.groupedCategories, checkForActiveQuiz, setActiveQuizState, setError]);

// Update handleSelectAll
const handleSelectAll = useCallback(async () => {
  console.log('handleSelectAll called');
  
  // First handle the selection
  setSelectAll(prev => {
    const next = !prev;
    console.log('Toggling selectAll to:', next);
    const allCategoryIds = next 
      ? simulationData.groupedCategories.flatMap(category => category.categoryIds)
      : [];
    
    console.log('All category IDs:', allCategoryIds);
    setSelectedCategories(allCategoryIds);
    setShowCustomizer(allCategoryIds.length > 0);
    return next;
  });

  // Then check for active quiz only if we're showing the customizer
  try {
    console.log('Checking for active quiz...');
    const { data: activeQuiz } = await checkForActiveQuiz();
    console.log('Active quiz check result:', activeQuiz);
    
    if (activeQuiz) {
      console.log('Active quiz found, updating state');
      setActiveQuizState({
        activeQuiz,
        activeTestletType: getTestletType(activeQuiz),
        showActiveQuizModal: true
      });
    }
  } catch (error) {
    console.error('Error checking for active quiz:', error);
    setError?.('Failed to check for active quizzes');
  }
}, [simulationData.groupedCategories, checkForActiveQuiz, setActiveQuizState, setError]);

   // Update handleResetSimulations to use the hook's reset function
   const handleResetSimulations = () => {
    if (selectedCategories.length > 0 && !isResettingCategories) {
      setShowResetPopup(true);
    }
  };

  const handleConfirmReset = async () => {
    if (!user) return;
    setIsResettingCategories(true);
    try {
      await resetSimulationsFromHook(selectedCategories);
      
      // Clear local simulation state
      setSelectedCategories([]);
      setShowCustomizer(false);
      
      // Refetch simulation categories
      await fetchSimulationCategories();
    } catch (error) {
      console.error('Error resetting simulations:', error);
      setError('Failed to reset simulations. Please try again.');
    } finally {
      setIsResettingCategories(false);
      setShowResetPopup(false);
    }
  };
  // In Simulations.tsx, update the session validation effect
  useEffect(() => {
    const validateSession = async () => {
      const isActive = sessionStorage.getItem('simulation_quiz_active') === 'true';
      const currentQuizId = sessionStorage.getItem('current_quiz_id');

      if (isActive && currentQuizId) {
        try {
          const quizHistory = await api.getSimulationQuizHistoryById(currentQuizId);
          if (!quizHistory || quizHistory.completed || quizHistory.end_time) {
            // Clear invalid session
            sessionStorage.removeItem('simulation_quiz_active');
            sessionStorage.removeItem('current_quiz_id');
            
            // Check for any other active quizzes
            const activeQuiz = await api.getActiveQuiz(userId, courseId);
            if (activeQuiz && !activeQuiz.completed && !activeQuiz.end_time) {
              // Found another active quiz, update session
              sessionStorage.setItem('current_quiz_id', activeQuiz.id);
              sessionStorage.setItem('simulation_quiz_active', 'true');
            }
          }
        } catch (error) {
          console.error('Error validating session:', error);
          sessionStorage.removeItem('simulation_quiz_active');
          sessionStorage.removeItem('current_quiz_id');
        }
      } else if (!isActive) {
        // If no active session, check for active quiz
        try {
          const activeQuiz = await api.getActiveQuiz(userId, courseId);
          if (activeQuiz && !activeQuiz.completed && !activeQuiz.end_time) {
            // Found active quiz, set session
            sessionStorage.setItem('current_quiz_id', activeQuiz.id);
            sessionStorage.setItem('simulation_quiz_active', 'true');
          }
        } catch (error) {
          console.error('Error checking for active quiz:', error);
        }
      }
    };

    validateSession();
  }, [userId, courseId]);

  // Update handleStartSimulation
  const handleStartSimulation = useCallback(async (simulations: Simulation[], qhId: string) => {
    if (simulations.length === 0) {
      console.error('Cannot start simulation with 0 items');
      return;
    }

    try {
      // Set transitioning state immediately
      setIsTransitioning(true);

      // Show loading screen first
      setLoading(true);

      // Start new quiz immediately without checking for active quiz
      const quizHistory = await api.getSimulationQuizHistoryById(qhId);
      const firstSimId = (quizHistory.questions_data[0] as SimulationQuestionData).simulationId;
      const firstSimulation = await api.getSimulationsById([firstSimId], userId, courseId);

      setSelectedSimulation(firstSimulation[0]);
      setCurrentSimulationIndex(0);
      setTotalSimulations(simulations.length);
      setCurrentQuizHistoryId(qhId);
      setQuizStarted(true);
      setSimulationResults([]);
      
      // Set new session data
      sessionStorage.setItem('current_quiz_id', qhId);
      sessionStorage.setItem('simulation_quiz_active', 'true');

    } catch (error) {
      console.error('Error starting simulation:', error);
      setError('Failed to start simulation. Please try again.');
    } finally {
      setIsTransitioning(false);
      setLoading(false);
    }
  }, [courseId, userId, setError]);
  
  const handleResumeQuizFromHistory = useCallback(async (quizToResume: QuizHistory) => {
    try {
      // Find the first unanswered simulation
      const unansweredSimulation = quizToResume.questions_data.find(
        (q): q is SimulationQuestionData => 
          q.type === 'simulation' && !q.answered
      );
  
      if (!unansweredSimulation) {
        console.error('No unanswered simulations found');
        return;
      }
  
      // Load the simulation data
      const [simulation] = await api.getSimulationsById(
        [unansweredSimulation.simulationId], 
        userId, 
        courseId
      );
  
      if (!simulation) {
        console.error('Failed to fetch simulation data');
        return;
      }
  
      // Navigate to simulations view with the proper state
      navigate(`/course/${courseId}/simulations`, { 
        state: { 
          resumeQuizId: quizToResume.id,
          quizData: quizToResume,
          currentSimulationIndex: quizToResume.questions_data.findIndex(
            q => q.type === 'simulation' && q.simulationId === unansweredSimulation.simulationId
          ),
          simulation: simulation
        } 
      });
  
    } catch (error) {
      console.error('Error resuming simulation from history:', error);
      setError?.('Failed to resume simulation. Please try again.');
    }
  }, [courseId, navigate, userId]);

  const handleSimulationComplete = async (result: SimulationResult) => {
    try {
      if (!currentQuizHistoryId) {
        console.error('No quiz history ID found');
        return;
      }
  
      // Using the timeSpent from the result which now comes from QuestionTimeManager
      const timeSpent = result.timeSpent;
  
      const questionData = isMockExam ? {
        type: 'mock_exam_sim' as const,
        questionId: result.simulation.id,
        simulationId: result.simulation.id,
        answered: true,
        isCorrect: result.score >= 70,
        timeSpent,
        confidenceLevel: result.confidenceLevel,
        userAnswer: JSON.stringify(result.userAnswers),
        score: result.score,
        feedback: result.feedback,
        free_trial_content: result.simulation.free_trial_content,
        question_results: [],
        mockExamId: mockExamId!,
        mockExamTestletId: effectiveTestletId!,
        testletNumber: currentSimulationIndex + 1,
        mockExamNumber: 1
      } : {
        type: 'simulation' as const,
        questionId: result.simulation.id,
        simulationId: result.simulation.id,
        answered: true,
        isCorrect: result.score >= 70,
        timeSpent,
        confidenceLevel: result.confidenceLevel,
        userAnswer: JSON.stringify(result.userAnswers),
        score: result.score,
        feedback: result.feedback,
        free_trial_content: result.simulation.free_trial_content,
        question_results: []
      };
  
      await api.updateSimulationQuizHistory(currentQuizHistoryId, questionData);
      setShowExplanation(true);
      await fetchSimulationCategories();
  
      if (isMockExam && effectiveTestletId && result.score != null) {
        await api.completeTestlet(
          effectiveTestletId,
          result.score,
          timeSpent,
          currentQuizHistoryId // Add this parameter
        );
      }
    } catch (error) {
      console.error('Error handling simulation completion:', error);
      setError('Failed to process simulation completion');
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  // Add these new handler functions
const handleExpandAll = (expand: boolean) => {
  if (expand) {
    const allParentCategories = simulationCategories.map(cat => cat.id);
    setExpandedCategories(allParentCategories);
  } else {
    setExpandedCategories([]);
  }
  setIsAllExpanded(expand);
};

const isCategoryExpanded = (categoryId: string) => expandedCategories.includes(categoryId);

const handleCategoryClick = (categoryId: string) => {
  setExpandedCategories(prev => {
    if (prev.includes(categoryId)) {
      return prev.filter(id => id !== categoryId);
    } else {
      return [...prev, categoryId];
    }
  });
  setIsAllExpanded(false);
};

  // Inside your Simulations component, update the renderCategories function:
  const renderCategories = () => {
    if (isLoading || !simulationData) {
      return (
        <div className="flex justify-center items-center h-48">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-blue"></div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex justify-center items-center h-48 text-red-500">
          <div className="text-center">
            <div className="mb-2">⚠️</div>
            <div>{error}</div>
          </div>
        </div>
      );
    }

    const { groupedCategories, overallStats } = simulationData;

    if (!groupedCategories || groupedCategories.length === 0) {
      return (
        <div className="flex justify-center items-center h-48 text-gray-500">
          No categories available
        </div>
      );
    }

    // Calculate accuracy percentage
    const getAccuracyPercentage = (stats: ExtendedSimulationStats) => {
      const correct = stats.correct ?? 0;
      const incorrect = stats.incorrect ?? 0;
      return (correct + incorrect) > 0 
        ? ((correct / (correct + incorrect)) * 100).toFixed(1)
        : '0.0';
    };

    // Helper function to render stats with icons
    const renderStatWithIcon = (icon: React.ReactNode, value: number | string, label?: string) => (
      <div className="flex items-center gap-1.5 text-sm text-gray-600 dark:text-gray-300">
        {icon}
        <span>{value}</span>
        {label && <span className="text-gray-400 dark:text-gray-500">{label}</span>}
      </div>
    );

    // Confidence level rendering with type safety
    const renderConfidenceLevel = (count: number | undefined, type: ConfidenceLevel, isMobile: boolean = false) => {
      const safeCount = count ?? 0;
      const iconClasses = isMobile ? "w-3 h-3" : "w-4 h-4";
      
      const getIcon = () => {
        switch(type) {
          case 'confident': return <Sparkle className={iconClasses} />;
          case 'maybe': return <Scale className={iconClasses} />;
          case 'guessing': return <Shuffle className={iconClasses} />;
        }
      };
      
      const getColors = () => {
        switch(type) {
          case 'confident': return 'bg-green-100 dark:bg-green-600/50 text-green-800 dark:text-green-100';
          case 'maybe': return 'bg-yellow-100 dark:bg-yellow-600/50 text-yellow-800 dark:text-yellow-100';
          case 'guessing': return 'bg-red-100/90 text-red-900 dark:bg-red-600/50 dark:text-red-100';
        }
      };

      const baseClasses = `${getColors()} rounded font-medium flex items-center`;
      const responsiveClasses = isMobile 
        ? 'px-1.5 py-0.5 text-xs gap-1'
        : 'px-2.5 py-1.5 text-sm gap-1.5';

      return (
        <span className={`${baseClasses} ${responsiveClasses}`}>
          {getIcon()}
          <span>{safeCount}</span>
        </span>
      );
    };

    return (
      <div className="space-y-3">
        {/* Summary Card */}
        <div className="bg-gradient-to-r from-primary-blue to-blue-600 rounded-lg shadow-sm p-4">
          <div className="flex flex-col space-y-4">
            <h3 className="text-white text-lg font-semibold">Simulation Categories Overview</h3>
            
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
              <div className="bg-white/20 rounded-lg p-3">
                <div className="text-white/90 text-sm">Total Simulations</div>
                <div className="text-white text-xl sm:text-2xl font-bold flex items-center gap-2">
                  <ListCheck className="w-5 h-5 sm:w-6 sm:h-6" />
                  {overallStats.total}
                </div>
              </div>
              <div className="bg-white/20 rounded-lg p-3">
                <div className="text-white/90 text-sm">Unanswered</div>
                <div className="text-white text-xl sm:text-2xl font-bold flex items-center gap-2">
                  <FileQuestion className="w-5 h-5 sm:w-6 sm:h-6" />
                  {overallStats.unanswered}
                </div>
              </div>
              <div className="bg-white/20 rounded-lg p-3">
                <div className="text-white/90 text-sm">Accuracy %</div>
                <div className="text-white text-xl sm:text-2xl font-bold flex items-center gap-2">
                  <CheckCircle className="w-5 h-5 sm:w-6 sm:h-6" />
                  {getAccuracyPercentage(overallStats)}%
                </div>
              </div>
              <div className="bg-white/20 rounded-lg p-3">
                <div className="text-white/90 text-sm">Confidence Count</div>
                <div className="hidden sm:flex items-center gap-2 mt-1">
                  {renderConfidenceLevel(overallStats.confident, 'confident')}
                  {renderConfidenceLevel(overallStats.maybe, 'maybe')}
                  {renderConfidenceLevel(overallStats.guessing, 'guessing')}
                </div>
                <div className="flex gap-1 mt-1 sm:hidden">
                  {renderConfidenceLevel(overallStats.confident, 'confident', true)}
                  {renderConfidenceLevel(overallStats.maybe, 'maybe', true)}
                  {renderConfidenceLevel(overallStats.guessing, 'guessing', true)}
                </div>
              </div>
            </div>

            {/* Progress Bar Section */}
            <div className="bg-white/20 rounded-lg p-2.5">
              <div className="flex items-center gap-2 mb-2">
                <Target className="w-4 h-4 text-white/90" />
                <span className="text-white/90 text-sm">Simulation Progress</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="flex-grow">
                  <div className="w-full bg-gray-200/30 rounded-full h-2.5">
                    <div 
                      className="bg-sky-200 h-2.5 rounded-full transition-all duration-500"
                      style={{ 
                        width: `${((overallStats.total - overallStats.unanswered) / overallStats.total * 100)}%` 
                      }}
                    ></div>
                  </div>
                </div>
                <span className="text-white text-sm font-medium">
                  {((overallStats.total - overallStats.unanswered) / overallStats.total * 100).toFixed(1)}%
                </span>
              </div>
            </div>

          </div>
        </div>

        {/* Select All Checkbox */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border-gray-200 p-4">
          <div className="flex items-center gap-3">
            <div className="shrink-0 w-5">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
                className="w-5 h-5 rounded border-gray-300 text-primary-blue focus:ring-primary-blue"
              />
            </div>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-200">
              Select All
            </span>
          </div>
        </div>

        {/* Categories List */}
        <div className="space-y-2">
          {groupedCategories.map((category: GroupedCategory) => {
            const isSelected = category.categoryIds.every(id => selectedCategories.includes(id));
            
            return (
              <div 
                key={category.id} 
                className={`
                  overflow-hidden bg-white dark:bg-gray-800 rounded-lg shadow-sm border
                  ${isSelected 
                    ? 'border-primary-blue dark:border-primary-blue/50' 
                    : 'border-gray-200 dark:border-gray-700'
                  }
                `}
              >
                <div 
                  className={`
                    p-4 transition-all duration-200
                    ${isSelected 
                      ? 'bg-blue-50 hover:bg-blue-100 dark:bg-blue-950/50 dark:hover:bg-blue-900/50' 
                      : 'hover:bg-gray-50 dark:hover:bg-gray-700/50'
                    }
                  `}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4 flex-grow">
                      <div className="shrink-0 w-5">
                        <input
                          type="checkbox"
                          checked={isSelected}
                          onChange={() => handleCategorySelection(category.id)}
                          className="w-5 h-5 rounded border-gray-300 text-primary-blue focus:ring-primary-blue"
                        />
                      </div>
                      <div className="flex-grow min-w-0">
                        <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white">
                          {category.parent_question_category_name}
                        </h3>
                        <div className="flex flex-wrap items-center gap-3 mt-2">
                          {renderStatWithIcon(
                            <ListCheck className="w-4 h-4" />, 
                            category.stats.total
                          )}
                          {renderStatWithIcon(
                            <FileQuestion className="w-4 h-4" />, 
                            category.stats.unanswered
                          )}
                          {renderStatWithIcon(
                            <CheckCircle className="w-4 h-4" />, 
                            `${getAccuracyPercentage(category.stats)}%`
                          )}
                        </div>
                        {/* Mobile confidence counts */}
                        <div className="flex sm:hidden items-center gap-1.5 mt-2">
                          {renderConfidenceLevel(category.stats.confident, 'confident', true)}
                          {renderConfidenceLevel(category.stats.maybe, 'maybe', true)}
                          {renderConfidenceLevel(category.stats.guessing, 'guessing', true)}
                        </div>
                      </div>
                    </div>
                    
                    <div className="hidden sm:flex items-center gap-1.5">
                      {renderConfidenceLevel(category.stats.confident, 'confident')}
                      {renderConfidenceLevel(category.stats.maybe, 'maybe')}
                      {renderConfidenceLevel(category.stats.guessing, 'guessing')}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <LoadingScreen message="Loading your Simulations..." subMessage="This may take a few moments" />;
  }
  if (error) return <div className="text-red-500">{error}</div>;

  if (selectedSimulation && currentQuizHistoryId) {
    return (
      <SimulationQuiz
        simulation={selectedSimulation}
        onSubmit={handleSimulationComplete}
        onNext={async () => {
          if (!currentQuizHistoryId) return;
          try {
            const quizHistory = await api.getSimulationQuizHistoryById(currentQuizHistoryId);
            const simulationsData = quizHistory.questions_data as SimulationQuestionData[];
            const currentSimIndex = simulationsData.findIndex(s => s.simulationId === selectedSimulation.id);
            const nextSimData = currentSimIndex !== -1 && currentSimIndex < simulationsData.length - 1
              ? simulationsData[currentSimIndex + 1]
              : null;
  
            if (nextSimData) {
              const nextSimulation = await api.getSimulationsById([nextSimData.simulationId], userId, courseId);
              if (nextSimulation[0]) {
                setSelectedSimulation(nextSimulation[0]);
                setShowExplanation(false);
                setCurrentSimulationIndex(currentSimIndex + 1);
              }
            } else {
              if (effectiveTestletId && mockExamId) {
                navigate(`/course/${courseId}/mock-exam/${mockExamId}`, {
                  replace: true
                });
              } else {
                await api.endQuizSession(currentQuizHistoryId, 0, false);
                navigate(`/course/${courseId}/simulation-review`, {
                  state: {
                    quizHistoryId: currentQuizHistoryId,
                    courseId
                  },
                  replace: true
                });
              }
              setSelectedSimulation(null);
              setShowExplanation(false);
              setCurrentSimulationIndex(0);
            }
          } catch (error) {
            console.error('Error navigating to next simulation:', error);
          }
        }}
        currentSimulationIndex={currentSimulationIndex}
        setCurrentSimulationIndex={setCurrentSimulationIndex}
        totalSimulations={totalSimulations}
        simulationResults={simulationResults}
        userId={userId}
        courseId={courseId}
        isMockExam={isMockExam}
        mockExamId={mockExamId}        // Now correctly typed as string | undefined
        mockExamTestletId={effectiveTestletId}  // Now correctly typed as string | undefined
        quizHistoryId={currentQuizHistoryId}
        cachedResponses={userResponses}
        showUpgradeModal={showUpgradeModal}
        setShowUpgradeModal={setShowUpgradeModal}
        setSelectedSimulation={setSelectedSimulation}
        currentQuizHistoryId={currentQuizHistoryId}
        invalidateSimulationData={invalidateSimulationData} // Pass the function directly
      />
    );
  }
  
  return (
    <>
      {isTransitioning && (
        <div className="fixed inset-0 bg-white dark:bg-gray-900 z-50">
          <LoadingScreen message="Starting Simulation..." subMessage="Preparing your questions" />
        </div>
      )}
    <div className={`p-4 sm:p-4 md:p-4  max-w-[1200px] mx-auto rounded-lg shadow-md border border-gray-400 dark:border-gray-700 ${isDarkMode ? 'bg-gray-800 text-gray-100' : 'bg-white text-gray-900'}`}>
      <div className="max-w-7xl mx-auto space-y-6">
        {!isMockExam && (
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
            <h2 className="text-2xl font-bold">Task-Based Simulations</h2>
            <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
              {showUpgradeButton && (
                <Button
                  onClick={handleUpgradeClick}
                  className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center justify-center px-4 py-2 text-sm font-medium w-full sm:w-auto"
                >
                  <Crown size={16} className="mr-2" />
                  Upgrade
                </Button>
              )}
              <button
                onClick={handleResetSimulations}
                disabled={selectedCategories.length === 0 || isResettingCategories}
                className={`${
                  selectedCategories.length === 0 || isResettingCategories
                    ? 'text-red-300 dark:text-red-800 cursor-not-allowed'
                    : 'text-red-500 dark:text-red-400 hover:text-red-600 dark:hover:text-red-300'
                } transition-colors duration-200`}
              >
                Reset Simulations
              </button>
            </div>
          </div>
        )}

        {/* Render Categories */}
        {!isMockExam && renderCategories()}

        {/* Modals and Popups */}
        {showResetPopup && (
          <SimulationResetCategoryPopup 
            isOpen={showResetPopup}
            onClose={() => setShowResetPopup(false)}
            onConfirm={handleConfirmReset}
            categoryCount={selectedCategories.length}
          />
        )}

        {isResettingCategories && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-blue mx-auto"></div>
              <p className="mt-4 text-center text-gray-600 dark:text-gray-300">
                Resetting simulations...
              </p>
            </div>
          </div>
        )}

        {/* Upgrade Modal */}
        <UpgradeModal
          isOpen={showUpgradeModal}
          onClose={() => setShowUpgradeModal(false)}
        />

        {/* Simulation Customizer */}
        {!isMockExam && (
          <SimulationsCustomizer
            courseId={courseId}
            userId={userId}
            show={showCustomizer}
            availableSimulationCounts={displayStats}
            customSimulationCount={customSimulationCount}
            setCustomSimulationCount={setCustomSimulationCount}
            keslerQFilter={keslerQFilter}
            setKeslerQFilter={setKeslerQFilter}
            onStartSimulation={handleStartSimulation}
            onResumeSimulation={handleResumeQuizFromHistory}
            selectedCategories={selectedCategories}
            setTotalSimulations={setTotalSimulations}
            setCurrentSimulationIndex={setCurrentSimulationIndex}
            setSelectedSimulation={setSelectedSimulation}
            setCurrentQuizHistoryId={setCurrentQuizHistoryId}
            setQuizStarted={setQuizStarted}
            error={error}
            setError={setError}
            showUpgradeModal={showUpgradeModal}
            setShowUpgradeModal={setShowUpgradeModal}
            activeQuizState={activeQuizState}
            setActiveQuizState={setActiveQuizState}
            resetActiveQuizCheck={resetActiveQuizCheck}
          />
        )}
      </div>
      <PopupModal
        isOpen={activeQuizState.showActiveQuizModal}
        onClose={() => {
          console.log('Closing modal');
          setActiveQuizState({
            ...activeQuizState,
            showActiveQuizModal: false
          });
        }}
        onResume={() => {
          console.log('Resuming quiz');
          if (activeQuizState.activeQuiz) {
            handleResumeQuizFromHistory(activeQuizState.activeQuiz);
          }
          setActiveQuizState({
            ...activeQuizState,
            showActiveQuizModal: false
          });
        }}
        onEnd={async () => {
          try {
            if (!activeQuizState.activeQuiz) return;
            await api.endTBSSession(activeQuizState.activeQuiz.id, 0);
            setActiveQuizState({
              activeQuiz: null,
              activeTestletType: null,
              showActiveQuizModal: false
            });
            resetActiveQuizCheck();
            // Refetch categories after ending the quiz
            await fetchSimulationCategories();
          } catch (err) {
            console.error('Failed to end quiz session:', err);
            setError('Failed to end quiz session. Please try again.');
          }
        }}
        activeQuiz={activeQuizState.activeQuiz}
        courseId={courseId}
      />
    </div>
  </>
);
};

export default Simulations;