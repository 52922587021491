import { useState, useCallback } from 'react';
import { Loader2 } from 'lucide-react';
import { Button } from '../ui/Button';

export interface NavigationButtonProps {
  onNavigate: () => Promise<void>;
  currentIndex: number;
  totalCount: number;
  disabled?: boolean;
  className?: string;
  position?: 'fixed' | 'static';
  size?: 'default' | 'auto';
}

const NavigationButton = ({
  onNavigate,
  currentIndex,
  totalCount,
  disabled = false,
  className = '',
  position = 'static',
  size = 'default'
}: NavigationButtonProps) => {
  const [isNavigating, setIsNavigating] = useState(false);

  const handleClick = useCallback(async () => {
    if (isNavigating || disabled) return;
    
    setIsNavigating(true);
    try {
      window.scrollTo({ top: 0, behavior: 'auto' });
      await onNavigate();
    } finally {
      setTimeout(() => setIsNavigating(false), 500);
    }
  }, [onNavigate, isNavigating, disabled]);

  const buttonText = currentIndex === totalCount - 1 
    ? "End Quiz & View Results" 
    : "Next Question";

  const baseStyles = `
    font-bold relative
    ${size === 'default' ? 'w-full' : 'w-auto'}
    ${currentIndex === totalCount - 1
      ? 'bg-blue-600 hover:bg-blue-700'
      : 'bg-orange-500 hover:bg-orange-600'
    } 
    text-white
    ${isNavigating || disabled ? 'cursor-not-allowed opacity-75' : ''}
    ${className}
  `;

  const positionStyles = position === 'fixed' 
    ? 'fixed bottom-4 left-1/2 transform -translate-x-1/2 max-w-md z-50 shadow-lg'
    : '';

  return (
    <Button
      onClick={handleClick}
      disabled={isNavigating || disabled}
      className={`${baseStyles} ${positionStyles}`}
    >
      {isNavigating && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader2 className="w-6 h-6 animate-spin" />
        </div>
      )}
      <span className={isNavigating ? 'invisible' : ''}>
        {buttonText}
      </span>
    </Button>
  );
};

export default NavigationButton;