import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, Lock, CheckCircle, LucideMessageCircleWarning, Crown } from 'lucide-react';
import { api } from '../../services/api';
import { Button } from '../../components/ui/Button';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import UpgradeModal from './common/UpgradeModal';

interface StudyPlanMentorProps {
    courseId: string;
    userId: string;
    isExpanded: boolean;
    onExpandChange: (isExpanded: boolean) => void;
}

interface MentorModule {
    id: string;
    title: string;
    sequence_number: number;
    free_trial_content: boolean;
    is_completed?: boolean;
    description?: string;
}

// Module descriptions mapping
const MODULE_DESCRIPTIONS: Record<string, string> = {
    "Module 1: CPA Exam Mental Prep": "Develop the right mindset and mental approach to tackle the CPA exam with confidence",
    "VIP Bonuses, Checklists & More": "Access exclusive study resources and organizational tools to streamline your preparation",
    "Module 2: CPA Exam Planning": "Create a personalized study schedule that fits your lifestyle and maximizes efficiency",
    "Study Group Mastermind": "Connect with fellow candidates and learn proven study strategies from successful CPAs",
    "Module 3: CPA Exam Productivity Optimization": "Master time management techniques to study smarter, not harder",
    "Module 4: Create Your Support System": "Build a network of accountability partners and mentors to keep you on track",
    "Module 5: Defeat The Curve Of Forgetting!": "Learn scientifically-proven retention techniques to remember what you study",
    "TUTORIALS: Implement Study Loops & Study Funnels With Your Specific Course": "Learn how to apply proven study methods to your specific review course materials",
    "Module 6: Become Super Test Taker": "Master exam-taking strategies and techniques to maximize your score on test day",
    "Module 7: Post Exam Recovery": "Learn how to effectively recover and reset between exam sections",
    "TUTORIALS: Boost Your Retake Scores With EZ To Expert Study Funnels": "Specialized strategies for improving your scores if you need to retake an exam section"
};

export const StudyPlanMentor: React.FC<StudyPlanMentorProps> = ({ 
    courseId, 
    userId, 
    isExpanded,
    onExpandChange 
}) => {
    const [modules, setModules] = useState<MentorModule[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
    const navigate = useNavigate();
    const { userCourseAccesses } = useUserCourseAccess();

    useEffect(() => {
        const fetchModules = async () => {
            if (!userId) {
                console.error('User ID is required to fetch mentor modules');
                return;
            }

            try {
                const metadata = await api.getMentorModulesMetadata();
                const modulesWithStatus = metadata.map(module => ({
                    ...module,
                    is_completed: module.is_completed || false
                }));

                setModules(modulesWithStatus);

                // Check if all modules are completed and update expanded state
                const allCompleted = modulesWithStatus.every(module => module.is_completed);
                if (allCompleted) {
                    onExpandChange(false);
                }
            } catch (error) {
                console.error('Error fetching mentor modules:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchModules();
    }, [userId, onExpandChange]);

    const isContentLocked = (module: MentorModule) => {
        if (!userCourseAccesses?.length) return true;
        
        const hasMentorAccess = userCourseAccesses.some(access => 
            access.course_id === 'a6a5eaf3-63bc-4e9a-92ac-64153a1f8395' && 
            (access.tier_id === '25f83b83-0a8a-4599-b387-c8870d79b214' || 
             access.tier_id === 'c99793af-5529-446b-be12-cc56709509e6')
        );
        
        if (hasMentorAccess) return false;
        
        const hasStudyMaterialsAccess = userCourseAccesses.some(access =>
            access.tier_id === '0b992474-3a65-41b5-ad90-cc88662cc594'
        );

        if (hasStudyMaterialsAccess && module.free_trial_content) {
            return false;
        }

        const currentAccess = userCourseAccesses.find(access => access.course_id === courseId);
        if (!currentAccess) return true;

        if (currentAccess.tier_id === '337eeb06-1040-43d5-ad6d-5316e058aec9' && module.free_trial_content) {
            return false;
        }

        return !(currentAccess.tier_id === '25f83b83-0a8a-4599-b387-c8870d79b214' || 
                 currentAccess.tier_id === 'c99793af-5529-446b-be12-cc56709509e6');
    };

    const handleModuleClick = (moduleId: string) => {
        navigate(`/course/${courseId}/mentor/${moduleId}`);
    };

    const handleUpgradeClick = () => {
        setIsUpgradeModalOpen(true);
    };

    const completedModules = modules.filter(m => m.is_completed).length;
    const totalModules = modules.length;
    const progressPercentage = (completedModules / totalModules) * 100;

    if (isLoading) {
        return (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden animate-pulse">
                <div className="h-20 bg-gray-100 dark:bg-gray-700"></div>
            </div>
        );
    }

    const renderButton = (module: MentorModule, classes: string = "") => {
        if (isContentLocked(module)) {
            return (
                <Button
                    onClick={handleUpgradeClick}
                    className={`bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center justify-center ${classes}`}
                >
                    <Crown size={16} className="mr-2" />
                    Upgrade
                </Button>
            );
        }

        return (
            <Button
                onClick={() => handleModuleClick(module.id)}
                className={`text-sm font-medium rounded-md transition-colors whitespace-nowrap ${
                    module.is_completed
                        ? 'bg-primary-orange hover:bg-primary-orange-hover text-white'
                        : 'bg-primary-blue hover:bg-primary-blue-hover text-white'
                } ${classes}`}
            >
                {module.is_completed ? 'Review' : 'Start'}
            </Button>
        );
    };

    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden mb-6 border border-gray-400 dark:border-gray-700">
            <div
                onClick={() => onExpandChange(!isExpanded)}
                className="bg-gray-50 dark:bg-gray-700 border-b border-gray-100 dark:border-gray-700 
                         cursor-pointer p-4"
            >
                <div className="flex items-center justify-between">
                    <div className="flex-1">
                        <div className="flex items-center">
                            <LucideMessageCircleWarning className="h-5 w-5 text-primary-blue flex-shrink-0" />
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white ml-2">
                                Week 0: Mentorship Modules
                            </h3>
                        </div>
                        
                        <div className="mt-2">
                            <h4 className="text-base font-medium text-gray-700 dark:text-gray-300">
                                Before You Start Studying...
                            </h4>
                            <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                Complete the Kesler CPA Mentorship Program to learn a step-by-step process for crafting the perfect study plan that helps you start, study and pass on your first try.
                            </p>
                        </div>

                        <div className="flex items-center gap-2 mt-3">
                            <div className="w-32 h-2 bg-gray-200 dark:bg-gray-600 rounded-full">
                                <div 
                                    className="h-full bg-green-500 rounded-full transition-all duration-300"
                                    style={{ width: `${progressPercentage}%` }}
                                />
                            </div>
                            <span className="text-sm text-gray-600 dark:text-gray-300">
                                {progressPercentage.toFixed(0)}%
                            </span>
                        </div>
                    </div>
                    <ChevronRight 
                        className={`w-5 h-5 text-gray-400 dark:text-gray-500 transition-transform duration-200 ml-4 ${
                            isExpanded ? 'rotate-90' : ''
                        }`}
                    />
                </div>
            </div>

            {isExpanded && (
                <div className="divide-y divide-gray-100 dark:divide-gray-700">
                    {modules.map((module) => (
                        <div 
                            key={module.id}
                            className="p-4 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200"
                        >
                            <div className="flex flex-col sm:flex-row sm:items-center gap-3">
                                <div className="flex-1">
                                    <div className="flex items-start gap-3">
                                        <div className="mt-1 flex-shrink-0">
                                            {module.is_completed ? (
                                                <CheckCircle className="w-5 h-5 text-green-500" />
                                            ) : isContentLocked(module) ? (
                                                <Lock className="w-5 h-5 text-gray-400" />
                                            ) : (
                                                <div className="w-5 h-5 border-2 border-gray-300 rounded-full" />
                                            )}
                                        </div>
                                        <div className="flex-1">
                                            <h4 className="text-gray-900 dark:text-white font-medium">
                                                {module.title}
                                            </h4>
                                            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                {MODULE_DESCRIPTIONS[module.title]}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Desktop button - hidden on mobile */}
                                <div className="hidden sm:block flex-shrink-0">
                                    {renderButton(module, "w-auto min-w-[100px]")}
                                </div>

                                {/* Mobile button - hidden on desktop */}
                                <div className="sm:hidden pl-8">
                                    {renderButton(module, "w-full")}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <UpgradeModal 
                isOpen={isUpgradeModalOpen}
                onClose={() => setIsUpgradeModalOpen(false)}
            />
        </div>
    );
};

export default StudyPlanMentor;