// src/components/AuthProvider.tsx
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useMemo
} from 'react';
import { Session, AuthChangeEvent } from '@supabase/supabase-js';
import { supabase } from '../services/supabase';
import { api, User, SupportUser } from '../services/api';
import { userDataService } from '../services/userDataService';
import { queryClient } from '../services/queryClient';

// ============== TYPES & INTERFACES ==============
interface AuthContextType {
  user: User | null;
  supportUser: SupportUser | null;
  session: Session | null;
  loading: boolean;
  error: Error | null;
  refreshUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// ============== CONSTANTS ==============
const AUTH_CHECK_INTERVAL = 30 * 60 * 1000;     // 30 minutes - regular check interval
const MIN_CHECK_INTERVAL = 15 * 60 * 1000;      // 15 minutes - absolute minimum between checks
const SESSION_EXPIRY_THRESHOLD = 60 * 60 * 1000; // 60 minutes - when to start warning about expiry
const ACTIVE_SESSION_ROUTES = ['/course', '/simulations', '/quiz', '/mock-exam'];
const DEBOUNCE_DELAY = 10000; // 10 seconds - for visibility/focus events

// ============== DEBOUNCE HELPER ==============
function debounce<T extends (...args: any[]) => Promise<any>>(
  func: T,
  wait: number
): {
  debouncedFn: (...args: Parameters<T>) => ReturnType<T>;
  cancel: () => void;
} {
  let timeout: NodeJS.Timeout | null = null;
  let lastCall = 0;

  const cancel = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  const debouncedFn = (...args: Parameters<T>): ReturnType<T> => {
    const now = Date.now();
    const timeSinceLastCall = now - lastCall;

    return new Promise((resolve, reject) => {
      cancel();

      if (timeSinceLastCall >= wait) {
        lastCall = now;
        func(...args).then(resolve).catch(reject);
      } else {
        timeout = setTimeout(() => {
          lastCall = Date.now();
          func(...args).then(resolve).catch(reject);
        }, wait);
      }
    }) as ReturnType<T>;
  };

  return { debouncedFn, cancel };
}

// ============== AUTH PROVIDER COMPONENT ==============
export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [authState, setAuthState] = useState<{
    user: User | null;
    supportUser: SupportUser | null;
    session: Session | null;
    loading: boolean;
    error: Error | null;
  }>({
    user: null,
    supportUser: null,
    session: null,
    loading: true,
    error: null,
  });

  // Refs
  const initializingRef = useRef(true);
  const lastAuthCheckRef = useRef<Date | null>(null);
  const sessionRef = useRef<Session | null>(null);
  const isProcessingRef = useRef(false);
  const activeSessionRef = useRef(false);

  // We'll attach a debounced handler for focus/visibility events
  const visibilityHandlerRef = useRef<{
    debouncedFn: () => Promise<void>;
    cancel: () => void;
  } | null>(null);

  // Debug logger
  const logAuthEvent = (event: string, details?: any) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(`[Auth Event] ${event}`, {
        timestamp: new Date().toISOString(),
        userId: authState.user?.id,
        isInitializing: initializingRef.current,
        isActiveSession: activeSessionRef.current,
        ...details,
      });
    }
  };

  // Helper to see if user is on an "active session" route
  const isInActiveSession = useCallback(() => {
    const currentPath = window.location.pathname;
    const isActive = ACTIVE_SESSION_ROUTES.some((route) =>
      currentPath.includes(route)
    );
    activeSessionRef.current = isActive;
    return isActive;
  }, []);

  // ============== PROCESS SESSION ==============
  const processSession = useCallback(
    async (session: Session | null) => {
      // Add strict rate limiting check
      const now = Date.now();
      const lastCheck = lastAuthCheckRef.current?.getTime() || 0;
      if (now - lastCheck < MIN_CHECK_INTERVAL) {
        logAuthEvent('Skipping session processing', { 
          reason: 'too soon since last check',
          timeSinceLastCheck: now - lastCheck,
          minInterval: MIN_CHECK_INTERVAL
        });
        return;
      }

      // Rest of the original processSession logic...
      if (isProcessingRef.current) {
        logAuthEvent('Skipping session processing', { reason: 'already processing' });
        return;
      }

      isProcessingRef.current = true;

      if (!session?.user && activeSessionRef.current && authState.user) {
        logAuthEvent('Skipping session processing', { reason: 'active session (no user session found)' });
        isProcessingRef.current = false;
        return;
      }

      try {
        sessionRef.current = session;

        if (!session?.user) {
          if (!activeSessionRef.current) {
            setAuthState((prev) => ({
              ...prev,
              user: null,
              supportUser: null,
              session: null,
              loading: false,
              error: null,
            }));
            queryClient.clear();
          }
          return;
        }

        // Skip if we've checked recently and user hasn't changed
        if (
          authState.user &&
          authState.session?.user.id === session.user.id &&
          now - lastCheck < AUTH_CHECK_INTERVAL
        ) {
          return;
        }

        const userData = await userDataService.getAllUserData(session.user.id);
        if (!userData.user) {
          throw new Error('No user data returned');
        }

        let supportUser = authState.supportUser;
        if (!supportUser) {
          supportUser = await api.getOrCreateSupportUser(
            userData.user.email || '',
            userData.user.full_name || ''
          );
        }

        setAuthState((prev) => ({
          ...prev,
          user: userData.user,
          supportUser,
          session,
          loading: false,
          error: null,
        }));

        lastAuthCheckRef.current = new Date();
      } catch (error) {
        logAuthEvent('Error processing session', { error });
        if (!activeSessionRef.current) {
          setAuthState((prev) => ({
            ...prev,
            user: null,
            supportUser: null,
            session: null,
            loading: false,
            error: error as Error,
          }));
        }
      } finally {
        isProcessingRef.current = false;
      }
    },
    [authState.user, authState.session, authState.supportUser]
  );

  // ============== VISIBILITY/FOCUS CHECKS ==============
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible' && !activeSessionRef.current) {
        const now = new Date();
        const lastCheck = lastAuthCheckRef.current;
        const timeSinceLastCheck = lastCheck ? now.getTime() - lastCheck.getTime() : Infinity;

        // Only check if it's been longer than MIN_CHECK_INTERVAL
        if (timeSinceLastCheck < MIN_CHECK_INTERVAL) {
          logAuthEvent('Skipping visibility check', { 
            reason: 'too soon since last check',
            timeSinceLastCheck
          });
          return;
        }

        const expiresAt = sessionRef.current?.expires_at
          ? sessionRef.current.expires_at * 1000
          : null;
        const sessionExpired = expiresAt && now.getTime() > (expiresAt + SESSION_EXPIRY_THRESHOLD);

        if (timeSinceLastCheck > AUTH_CHECK_INTERVAL || sessionExpired) {
          const { data: { session } } = await supabase.auth.getSession();
          await processSession(session || null);
        }
      }
    };

    visibilityHandlerRef.current = debounce(handleVisibilityChange, DEBOUNCE_DELAY);
    
    const handler = () => {
      visibilityHandlerRef.current?.debouncedFn();
    };

    document.addEventListener('visibilitychange', handler);
    window.addEventListener('focus', handler);

    return () => {
      document.removeEventListener('visibilitychange', handler);
      window.removeEventListener('focus', handler);
      visibilityHandlerRef.current?.cancel();
    };
  }, [processSession]);

  // ============== INITIAL LOAD ==============
  useEffect(() => {
    const initialize = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session) {
          await processSession(session);
        } else {
          setAuthState((prev) => ({
            ...prev,
            loading: false,
          }));
        }
      } catch (error) {
        console.error('Error initializing auth:', error);
        setAuthState((prev) => ({
          ...prev,
          loading: false,
          error: error as Error,
        }));
      } finally {
        initializingRef.current = false;
      }
    };

    initialize();
  }, [processSession]);

  // ============== SUPABASE EVENT LISTENER ==============
  useEffect(() => {
    const handleAuthChange = async (event: AuthChangeEvent, session: Session | null) => {
      // If not initializing or processing, and not in active session route, check
      if (!initializingRef.current && !isProcessingRef.current && !activeSessionRef.current) {
        await processSession(session);
      }
    };

    const { data: { subscription } } = supabase.auth.onAuthStateChange(handleAuthChange);
    return () => {
      subscription.unsubscribe();
    };
  }, [processSession]);

  // ============== REFRESH USER HELPER ==============
  const refreshUser = useCallback(async () => {
    if (!isProcessingRef.current && !activeSessionRef.current) {
      const { data: { session } } = await supabase.auth.getSession();
      await processSession(session || null);
    }
  }, [processSession]);

  // ============== FINAL CONTEXT VALUE ==============
  // We don't want to setAuthState in an effect for refreshUser
  // We just provide it here in a stable memo.
  const contextValue = useMemo<AuthContextType>(() => {
    return {
      user: authState.user,
      supportUser: authState.supportUser,
      session: authState.session,
      loading: authState.loading,
      error: authState.error,
      refreshUser,
    };
  }, [
    authState.user,
    authState.supportUser,
    authState.session,
    authState.loading,
    authState.error,
    refreshUser,
  ]);

  // ============== RENDER ==============
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

// ============== USE AUTH HOOK ==============
export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
