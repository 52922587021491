import React, { useState, useEffect, useMemo } from 'react';
import {
  QuizHistory,
  QuizQuestionData,
  isSimulationQuestion, 
  isMockExamSimulation,
  isStudyTaskTestlet,
  isMockExamMCQ
} from '../../services/api';
import QuizHistoryTableEndQuizPopup from './AnalyticsEndQuizPopup';
import { useNavigate } from 'react-router-dom';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import { Button } from '../ui/Button';
import { ToggleGroup, ToggleGroupItem } from '../ui/ToggleGroup';
import Pagination from './common/Pagination';
import {
  Clock,
  CheckCircle,
  Calendar,
  Award,
  Activity,
  Sparkle,
  Scale,
  Shuffle,
  BrainCircuit,
  Target,
  FileSpreadsheet,
  BookOpen,
  Filter
} from 'lucide-react';
import DOMPurify from 'dompurify';

interface QuizHistoryTableProps {
  quizzes: QuizHistory[];
  onViewResults: (id: string) => void;
  onResumeQuiz: (quiz: QuizHistory) => void;
  onEndQuiz: (id: string, totalTime: number) => void;
  courseId: string;
  selectedType: string;
  onFilterChange: (type: string) => void;
  currentPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
}

interface BaseQuizQuestion {
  answered: boolean;
  type: string;
}

interface MCQQuestion extends BaseQuizQuestion {
  type: 'mcq';
  isCorrect: boolean;
}

interface SimulationQuestion extends BaseQuizQuestion {
  type: 'simulation';
  score: number;
}

interface MockExamMCQQuestion extends BaseQuizQuestion {
  type: 'mock_exam_mcq';
  isCorrect: boolean;
}

interface MockExamSimulationQuestion extends BaseQuizQuestion {
  type: 'mock_exam_simulation';
  score: number;
}

interface StudyTaskQuestion extends BaseQuizQuestion {
  type: 'study_task';
  isCorrect: boolean;
}

type QuestionType = MCQQuestion | SimulationQuestion | MockExamMCQQuestion | MockExamSimulationQuestion | StudyTaskQuestion;

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const formatDateTime = (date: Date): string => {
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

const parseQuizName = (quizName: string): string => {
  if (!quizName) return '';
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = DOMPurify.sanitize(quizName);
  const textContent = tempDiv.textContent || '';
  return textContent.trim();
};

interface ConfidenceCounts {
  confident: number;
  maybe: number;
  guessing: number;
  total: number;
}

const getConfidenceCounts = (quiz: QuizHistory): ConfidenceCounts => {
  if (!quiz.questions_data) return { confident: 0, maybe: 0, guessing: 0, total: 0 };
  
  return quiz.questions_data.reduce((counts, question) => {
    if (!question.answered) return counts;
    
    if (question.confidenceLevel === 'confident') {
      counts.confident += 1;
    } else if (question.confidenceLevel === 'maybe') {
      counts.maybe += 1;
    } else if (question.confidenceLevel === 'guessing') {
      counts.guessing += 1;
    }
    counts.total += 1;
    return counts;
  }, { confident: 0, maybe: 0, guessing: 0, total: 0 });
};

const ConfidenceIndicator: React.FC<{ count: number, type: 'confident' | 'maybe' | 'guessing' }> = ({ count, type }) => {
  if (count === 0) return null;

  const getIcon = () => {
    switch(type) {
      case 'confident': return <Sparkle className="w-3 h-3" />;
      case 'maybe': return <Scale className="w-3 h-3" />;
      case 'guessing': return <Shuffle className="w-3 h-3" />;
    }
  };
  
  const getColors = () => {
    switch(type) {
      case 'confident': return 'bg-green-100 dark:bg-green-600/50 text-green-800 dark:text-green-100';
      case 'maybe': return 'bg-yellow-100 dark:bg-yellow-600/50 text-yellow-800 dark:text-yellow-100';
      case 'guessing': return 'bg-red-100/90 text-red-900 dark:bg-red-600/50 dark:text-red-100';
    }
  };

  return (
    <span className={`${getColors()} rounded px-1.5 py-0.5 text-xs font-medium flex items-center gap-1`}>
      {getIcon()}
      <span>{count}</span>
    </span>
  );
};

const getQuizTypeIcon = (quizType: string) => {
  switch (quizType) {
    case 'Simulation':
      return <FileSpreadsheet className="w-3.5 h-3.5" />;
    case 'Mock Exam':
      return <Award className="w-3.5 h-3.5" />;
    case 'Study Task':
      return <BookOpen className="w-3.5 h-3.5" />;
    default:
      return <Target className="w-3.5 h-3.5" />;
  }
};

const QuizHistoryTable: React.FC<QuizHistoryTableProps> = ({ 
  quizzes, 
  onViewResults, 
  onResumeQuiz, 
  onEndQuiz,
  courseId,
  selectedType,
  onFilterChange,
  currentPage,
  totalItems,
  onPageChange
}) => {
  const navigate = useNavigate();
  const [showEndQuizPopup, setShowEndQuizPopup] = useState(false);
  const [quizToEnd, setQuizToEnd] = useState<string | null>(null);
  const { userCourseAccesses, isLoading } = useUserCourseAccess();
  const quizzesPerPage = 21;
 
  const handleEndQuiz = (id: string) => {
    setQuizToEnd(id);
    setShowEndQuizPopup(true);
  };

  const handleConfirmEndQuiz = () => {
    if (quizToEnd) {
      const quiz = quizzes.find(q => q.id === quizToEnd);
      if (quiz) {
        onEndQuiz(quizToEnd, quiz.total_time);
      }
      setQuizToEnd(null);
      setShowEndQuizPopup(false);
    }
  };

  const getTotalQuestions = (quiz: QuizHistory): number => {
    return quiz.questions_data?.length || 0;
  };

  const getCorrectAnswers = (quiz: QuizHistory): number => {
    if (!quiz.questions_data?.length) return 0;
    
    return quiz.questions_data.reduce<number>((count, question) => {
      // Ensure question was answered
      if (!question.answered) return count;
  
      // Handle simulation-based questions (both regular and mock exam)
      if (isSimulationQuestion(question) || isMockExamSimulation(question)) {
        return count + (question.score >= 70 ? 1 : 0);
      }
      
      // Handle all MCQ-style questions (regular MCQ, mock exam MCQ, study task)
      if (isMockExamMCQ(question) || isStudyTaskTestlet(question)) {
        return count + (question.isCorrect ? 1 : 0);
      }
  
      // Check if it's a regular MCQ question
      if ('type' in question && question.type === 'mcq' && 'isCorrect' in question) {
        return count + (question.isCorrect ? 1 : 0);
      }
  
      // Log warning for unknown question types
      console.warn('Unknown question type encountered:', question);
      return count;
    }, 0);
  };

  const getQuizType = (quiz: QuizHistory): string => {
    if (!quiz.questions_data?.[0]) return 'MCQ';
    
    const firstQuestion = quiz.questions_data[0];
    
    if (isSimulationQuestion(firstQuestion)) return 'Simulation';
    if (isMockExamMCQ(firstQuestion) || isMockExamSimulation(firstQuestion)) return 'Mock Exam';
    if (isStudyTaskTestlet(firstQuestion)) return 'Study Task';
    return 'MCQ';
  };

  const getQuizTypeColor = (quizType: string): string => {
    switch (quizType) {
      case 'Simulation':
        return 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-200';
      case 'Mock Exam':
        return 'bg-purple-100 text-purple-700 dark:bg-purple-900 dark:text-purple-200';
      case 'Study Task':
        return 'bg-orange-100 text-orange-700 dark:bg-orange-900 dark:text-orange-200';
      default:
        return 'bg-pink-100 text-pink-700 dark:bg-pink-900 dark:text-pink-200';
    }
  };

   // Filter quizzes based on selected type
   const filteredQuizzes = useMemo(() => {
    return quizzes.filter(quiz => {
      const firstQuestion = quiz.questions_data?.[0];
      if (!firstQuestion) return false;

      switch (selectedType) {
        case 'simulation':
          return isSimulationQuestion(firstQuestion) || isMockExamSimulation(firstQuestion);
        case 'mockExam':
          return isMockExamMCQ(firstQuestion) || isMockExamSimulation(firstQuestion);
        case 'studyTask':
          return isStudyTaskTestlet(firstQuestion);
        case 'mcq':
          return !isSimulationQuestion(firstQuestion) && 
                 !isMockExamMCQ(firstQuestion) &&
                 !isMockExamSimulation(firstQuestion) && 
                 !isStudyTaskTestlet(firstQuestion);
        case 'all':
        default:
          return true;
      }
    });
  }, [quizzes, selectedType]);

  // Calculate current page's quizzes
  const currentQuizzes = useMemo(() => {
    const indexOfLastQuiz = currentPage * quizzesPerPage;
    const indexOfFirstQuiz = indexOfLastQuiz - quizzesPerPage;
    return filteredQuizzes.slice(indexOfFirstQuiz, indexOfLastQuiz);
  }, [filteredQuizzes, currentPage]);

  const handleTypeChange = (newType: string) => {
    onFilterChange(newType);
    onPageChange(1); // Reset to first page when filter changes
  };

  if (isLoading) {
    return <div>Loading user access data...</div>;
  }

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col sm:flex-row sm:items-center gap-4">
          <div className="sm:w-1/3">
            <h3 className="text-xl font-bold text-gray-900 dark:text-white text-center sm:text-left">Quiz History</h3>
          </div>
          <div className="sm:w-2/3 flex items-center gap-3">
            <Filter className="h-5 w-5 min-w-[20px] min-h-[20px] text-gray-500" />
            <ToggleGroup
              type="single"
              value={selectedType}
              onValueChange={handleTypeChange}
              className="w-full flex justify-between overflow-x-auto"
            >
              <ToggleGroupItem value="all" aria-label="Show all quizzes" className="flex-1 whitespace-nowrap justify-center">
                <BrainCircuit className="h-5 w-5 min-w-[20px] min-h-[20px]" />
                <span className="hidden [@media(min-width:1200px)]:inline">All</span>
              </ToggleGroupItem>
              <ToggleGroupItem value="mcq" aria-label="Show MCQ quizzes" className="flex-1 whitespace-nowrap justify-center">
                <Target className="h-5 w-5 min-w-[20px] min-h-[20px]" />
                <span className="hidden [@media(min-width:1200px)]:inline">MCQ</span>
              </ToggleGroupItem>
              <ToggleGroupItem value="simulation" aria-label="Show simulations" className="flex-1 whitespace-nowrap justify-center">
                <FileSpreadsheet className="h-5 w-5 min-w-[20px] min-h-[20px]" />
                <span className="hidden [@media(min-width:1200px)]:inline">Sims</span>
              </ToggleGroupItem>
              <ToggleGroupItem value="mockExam" aria-label="Show mock exams" className="flex-1 whitespace-nowrap justify-center">
                <Award className="h-5 w-5 min-w-[20px] min-h-[20px]" />
                <span className="hidden [@media(min-width:1200px)]:inline">Mock</span>
              </ToggleGroupItem>
              <ToggleGroupItem value="studyTask" aria-label="Show study tasks" className="flex-1 whitespace-nowrap justify-center">
                <BookOpen className="h-5 w-5 min-w-[20px] min-h-[20px]" />
                <span className="hidden [@media(min-width:1200px)]:inline">Tasks</span>
              </ToggleGroupItem>
            </ToggleGroup>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pb-5">
          {quizzes.map((quiz) => {
            const totalQuestions = getTotalQuestions(quiz);
            const correctAnswers = getCorrectAnswers(quiz as QuizHistory & { questions_data: QuizQuestionData[] });
            const incorrectAnswers = totalQuestions - correctAnswers;
            const quizType = getQuizType(quiz);
            const typeColor = getQuizTypeColor(quizType);
            const confidenceCounts = getConfidenceCounts(quiz);
            
            return (
              <div 
                key={quiz.id} 
                className={`
                  relative rounded-xl overflow-hidden
                  transition-all duration-300 shadow-md
                  ${!quiz.completed ? `
                    bg-gradient-to-b from-white to-blue-50 
                    dark:from-gray-800 dark:to-blue-900/10
                    ring-2 ring-blue-400/40 dark:ring-blue-300/40 
                    shadow-lg shadow-blue-500/20 dark:shadow-blue-400/20
                    hover:ring-blue-400/50 dark:hover:ring-blue-300/50
                    hover:shadow-xl hover:shadow-blue-500/30 dark:hover:shadow-blue-400/30
                  ` : `
                    bg-white dark:bg-gray-800
                    ring-1 ring-gray-200 dark:ring-gray-700
                    hover:shadow-lg dark:hover:shadow-gray-700/30
                  `}
                `}
              >
                <div className="p-4 border-b border-gray-200 dark:border-gray-700">
                  <div className="flex justify-between items-start mb-2">
                    <span className={`px-3 py-1 rounded-full text-xs font-medium ${typeColor} flex items-center gap-1.5`}>
                      {getQuizTypeIcon(quizType)}
                      <span>{quizType}</span>
                    </span>
                    {quiz.completed && confidenceCounts.total > 0 && (
                      <div className="flex gap-1">
                        <ConfidenceIndicator count={confidenceCounts.confident} type="confident" />
                        <ConfidenceIndicator count={confidenceCounts.maybe} type="maybe" />
                        <ConfidenceIndicator count={confidenceCounts.guessing} type="guessing" />
                      </div>
                    )}
                  </div>
                  <h3 className="font-medium text-gray-900 dark:text-white text-sm line-clamp-2 mb-1">
                    {parseQuizName(quiz.quiz_name)}
                  </h3>
                  <span className="text-xs text-gray-500 dark:text-gray-400 flex items-center">
                    <Calendar className="w-3.5 h-3.5 mr-1" />
                    {formatDateTime(new Date(quiz.end_time || quiz.start_time))}
                  </span>
                </div>

              {/* Rest of the card content remains the same */}
              <div className="p-4">
                {/* ... (keep existing card body content) */}
                <div className="space-y-4">
                  {/* Correct count and percentage row */}
                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <CheckCircle className="w-4 h-4 mr-2 text-green-500" />
                      <div>
                        <div className="text-sm font-medium text-gray-900 dark:text-white">
                          {correctAnswers}/{totalQuestions}
                        </div>
                        <div className="text-xs text-gray-500 dark:text-gray-400">
                          Questions Correct
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <Activity className="w-4 h-4 mr-2 text-gray-400" />
                      <div>
                        <div className="text-sm font-medium text-gray-900 dark:text-white">
                          {quiz.percentage_correct?.toFixed(2)}%
                        </div>
                        <div className="text-xs text-gray-500 dark:text-gray-400">
                          Score
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Time and Average Time row */}
                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <Clock className="w-4 h-4 mr-2 text-gray-400" />
                      <div>
                        <div className="text-sm font-medium text-gray-900 dark:text-white">
                          {formatTime(quiz.total_time)}
                        </div>
                        <div className="text-xs text-gray-500 dark:text-gray-400">
                          Total Time
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <Clock className="w-4 h-4 mr-2 text-gray-400" />
                      <div>
                        <div className="text-sm font-medium text-gray-900 dark:text-white">
                          {formatTime(Math.round(quiz.total_time / totalQuestions))}
                        </div>
                        <div className="text-xs text-gray-500 dark:text-gray-400">
                          Average Time/Q
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Card Actions */}
                <div className="mt-4">
                  {quiz.completed ? (
                    <Button
                      onClick={() => onViewResults(quiz.id)}
                      className="w-full bg-primary-blue hover:bg-blue-600 text-white"
                    >
                      View Results
                    </Button>
                  ) : (
                    <div className="grid grid-cols-2 gap-2">
                      <Button
                        onClick={() => onResumeQuiz(quiz)}
                        className="w-full bg-primary-blue hover:bg-blue-600 text-white"
                      >
                        Resume
                      </Button>
                      <Button
                        onClick={() => handleEndQuiz(quiz.id)}
                        className="w-full bg-red-500 hover:bg-red-600 text-white"
                      >
                        End Quiz
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {totalItems > 0 && (
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={quizzesPerPage}
            onPageChange={onPageChange}
          />
        )}
      </div>

      <QuizHistoryTableEndQuizPopup
        isOpen={showEndQuizPopup}
        onClose={() => setShowEndQuizPopup(false)}
        onConfirmEnd={handleConfirmEndQuiz}
        quizId={quizToEnd || ''}
        courseId={courseId}
      />
    </>
  );
};

export default React.memo(QuizHistoryTable);