import React, { createContext, useContext, useCallback, useRef } from 'react';
import { useQuery, UseQueryResult, QueryClient, QueryClientProvider } from 'react-query';
import { api } from '../services/api';
import type { Product as ApiProduct } from '../services/api';
import { useAuth } from '../components/AuthProvider';

// Use the Product type from the API
type Product = ApiProduct;

interface ProductContextType {
  products: { [id: string]: Product };
  loading: boolean;
  error: unknown;
  refreshProducts: () => void;
  getProduct: (id: string) => Promise<Product | null>;
}

const ProductContext = createContext<ProductContextType | undefined>(undefined);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000, // 1 hour
      cacheTime: 24 * 60 * 60 * 1000, // 24 hours
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

export function ProductProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  const courseCache = useRef<{ [id: string]: Product | null }>({});

  const fetchProducts = useCallback(async () => {
    const products = await api.getProducts();
    products.forEach(product => {
      courseCache.current[product.id] = product;
    });
    return products.reduce((acc, product) => {
      acc[product.id] = product;
      return acc;
    }, {} as { [id: string]: Product });
  }, []);

  const cacheKey = `products_${user?.id || 'anonymous'}`;

  const {
    data: products = {},
    isLoading,
    error,
    refetch,
  }: UseQueryResult<{ [id: string]: Product }, unknown> = useQuery(cacheKey, fetchProducts, {
    initialData: () => {
      const cachedProducts = localStorage.getItem(cacheKey);
      return cachedProducts ? JSON.parse(cachedProducts) : {};
    },
    onSuccess: (data) => {
      localStorage.setItem(cacheKey, JSON.stringify(data));
    },
  });

  const getProduct = useCallback(async (id: string): Promise<Product | null> => {
    if (courseCache.current[id]) {
      return courseCache.current[id];
    }

    try {
      const product = await queryClient.fetchQuery(['course', id], () => api.getCourse(id), {
        staleTime: 60 * 60 * 1000, // 1 hour
      });
      if (product) {
        courseCache.current[id] = product;
        queryClient.setQueryData<{ [id: string]: Product } | undefined>('products', (oldData) => {
          if (oldData) {
            return { ...oldData, [product.id]: product };
          }
          return { [product.id]: product };
        });
      }
      return product;
    } catch (err) {
      console.error('Failed to fetch the product:', err);
      return null;
    }
  }, []);

  const value: ProductContextType = {
    products,
    loading: isLoading,
    error,
    refreshProducts: refetch,
    getProduct,
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
    </QueryClientProvider>
  );
}

export function useProducts(): ProductContextType {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error('useProducts must be used within a ProductProvider');
  }
  return context;
}