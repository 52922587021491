import React, { useState, useEffect } from 'react';
import { api, Note } from '../../services/api';
import DOMPurify from 'dompurify';
import { Edit, Save, X, Trash2 } from 'lucide-react';

interface SimulationNotesProps {
  simulationId: string;
  courseId: string;
  userId: string;
}

const MAX_NOTE_LENGTH = 2000;

const SimulationNotes: React.FC<SimulationNotesProps> = ({ simulationId, courseId, userId }) => {
  const [note, setNote] = useState<Note | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchNote = async () => {
    setIsLoading(true);
    try {
      const response = await api.getNotes(userId, courseId, 'Simulation');
      const simulationNote = response.notes.find(n => n.simulation_id === simulationId);
      
      if (simulationNote) {
        setNote(simulationNote);
        setContent(simulationNote.content);
        setIsEditing(false);
      } else {
        setNote(null);
        setContent('');
        setIsEditing(true);
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching note:', err);
      setError('Failed to load note');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setNote(null);
    setContent('');
    setIsEditing(false);
    setError(null);
    
    if (simulationId && userId && courseId) {
      fetchNote();
    }
  }, [simulationId, userId, courseId]);

  const handleSave = async () => {
    try {
      const sanitizedContent = DOMPurify.sanitize(content);
      if (note) {
        const updatedNote = await api.updateNote(
          note.id,
          { content: sanitizedContent },
          userId,
          courseId,
          null, // questionId
          null, // lessonId
          null,  // moduleId
          simulationId // Pass simulationId as separate parameter
        );
        setNote(updatedNote);
      } else {
        const newNoteData = {
          user_id: userId,
          course_id: courseId,
          simulation_id: simulationId,
          content: sanitizedContent,
          question_id: null,
          lesson_id: null,
          module_id: null
        };
        const newNote = await api.createNote(newNoteData);
        setNote(newNote);
      }
      setIsEditing(false);
      setError(null);
    } catch (err) {
      console.error('Error saving note:', err);
      setError('Failed to save note');
    }
  };

  const handleClear = async () => {
    if (!note?.id) return;
    try {
      await api.deleteNote(note.id);
      setNote(null);
      setContent('');
      setIsEditing(true);
      setError(null);
    } catch (err) {
      console.error('Error deleting note:', err);
      setError('Failed to delete note');
    }
  };

  if (isLoading) {
    return (
      <div className="mt-8 bg-gray-50 dark:bg-gray-700 p-6 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100">Simulation Notes</h3>
        </div>
        <p className="text-gray-600 dark:text-gray-400">Loading notes...</p>
      </div>
    );
  }

  return (
    <div className="mt-8 bg-gray-50 dark:bg-gray-700 p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100">Simulation Notes</h3>
        <div className="flex space-x-2">
          {note && !isEditing && (
            <>
              <button
                onClick={() => setIsEditing(true)}
                className="p-2 text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
              >
                <Edit size={20} />
              </button>
              <button
                onClick={handleClear}
                className="p-2 text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
              >
                <Trash2 size={20} />
              </button>
            </>
          )}
        </div>
      </div>

      {error && (
        <div className="mb-4 text-red-600 dark:text-red-400">
          {error}
        </div>
      )}

      {isEditing || !note ? (
        <div className="space-y-4">
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value.slice(0, MAX_NOTE_LENGTH))}
            className="w-full p-3 border rounded-md dark:bg-gray-800 dark:border-gray-600 dark:text-white"
            rows={4}
            placeholder="Add your notes here..."
          />
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {content.length}/{MAX_NOTE_LENGTH} characters
          </p>
          <div className="flex justify-end space-x-2">
            <button
              onClick={() => {
                setIsEditing(false);
                setContent(note?.content || '');
              }}
              className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300 dark:bg-gray-600 dark:text-gray-200 dark:hover:bg-gray-500"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              disabled={!content.trim()}
              className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        <div className="prose dark:prose-invert max-w-none">
          {note.content}
        </div>
      )}
    </div>
  );
};

export default SimulationNotes;