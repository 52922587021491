import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  api, 
  Simulation,
  UserTBSResponse,
  FeedbackItem,
  SimulationQuestionData,
  ConfidenceLevelValue,
  BatchUserTBSResponse,
  TBSq,
  HTMLContent,
  TIER_IDS
} from '../../services/api';
import { useTheme } from '../../contexts/ThemeContext';
import { useAuth } from '../AuthProvider';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import SimulationsRender from './SimulationsRender';
import { ChevronDown, ChevronUp, Calculator as CalculatorIcon, Table, HelpCircle, X, Wrench, Crown } from 'lucide-react';
import { Button } from '../../components/ui/Button';
import NavigationButton from '../../components/ui/NavigationButton';
import useKeyPress from '../../hooks/useKeyPress';
import SimulationNotes from './SimulationNotes';
import LoadingScreen from './common/LoadingScreen';

// Tools
import EndTBSTestlet from './quiz-tools/EndTBSTestlet';
import Calculator from './quiz-tools/Calculator';
import Spreadsheet from './quiz-tools/Spreadsheet';
import FeedbackPopup from './quiz-tools/FeedbackPopup';
import TestModeToggle from './quiz-tools/TestModeToggle';
import Timer from '../../components/ui/Timer';
import MockTimer from "../../components/ui/MockTimer";

// Time Management
import { useSimulationTimer, useMockExamTimer } from '../../contexts/TimerContext';
import QuestionTimeManager from './quiz-tools/QuestionTimeManager';

interface SimulationResult {
  simulation: Simulation;
  userAnswers: Record<string, any>;
  score: number;
  feedback: FeedbackItem[];
  confidenceLevel: ConfidenceLevelValue;
  timeSpent: number;
}

interface SimulationWithConfidence extends Simulation {
  confidenceHistory?: Array<{
    level: string | null;
    timestamp: string;
  }>;
}

interface SimulationQuizProps {
  simulation: SimulationWithConfidence;
  onSubmit: (result: SimulationResult) => void;
  onNext: () => void;
  onTimeUpdate?: (elapsed: number) => void; // Add this line
  currentSimulationIndex: number;
  setCurrentSimulationIndex: (index: number) => void;
  totalSimulations: number;
  simulationResults: SimulationResult[];
  userId: string;
  courseId: string;
  isMockExam?: boolean;
  mockExamId?: string;
  mockExamTestletId?: string;
  quizHistoryId?: string;
  cachedResponses?: BatchUserTBSResponse;
  showUpgradeModal: boolean;
  setShowUpgradeModal: (show: boolean) => void;
  setSelectedSimulation: (simulation: Simulation | null) => void;
  currentQuizHistoryId: string;
  invalidateSimulationData: () => Promise<void>;
}

const SimulationQuiz: React.FC<SimulationQuizProps> = ({
  simulation,
  onSubmit,
  onNext,
  currentSimulationIndex,
  setCurrentSimulationIndex,  // Make sure this is destructured from props
  totalSimulations,
  userId,
  courseId,
  isMockExam = false,
  mockExamId,
  mockExamTestletId,
  quizHistoryId,
  cachedResponses = {},
  showUpgradeModal,
  setShowUpgradeModal,
  setSelectedSimulation,
  currentQuizHistoryId,
  invalidateSimulationData  // Add this prop destructuring
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [showEndQuizUpgrade, setShowEndQuizUpgrade] = useState(false);
  const { userCourseAccesses } = useUserCourseAccess();
  const [showSolution, setShowSolution] = useState(false);
  const [gradingResults, setGradingResults] = useState<{score: number | null; feedback: FeedbackItem[];} | null>(null);
  const [userResponse, setUserResponse] = useState<UserTBSResponse | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showResponseComparison, setShowResponseComparison] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [confidenceLevel, setConfidenceLevel] = useState<ConfidenceLevelValue | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCalculator, setShowCalculator] = useState(false);
  const [showSpreadsheet, setShowSpreadsheet] = useState(false);
  const [showEndTestletModal, setShowEndTestletModal] = useState(false);
  const [showMobileTools, setShowMobileTools] = useState(false);
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [submittedTime, setSubmittedTime] = useState<number | undefined>(undefined);
  const [isNavigating, setIsNavigating] = useState(false);
  // Add ref for scrolling
  const topRef = useRef<HTMLDivElement>(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  // Timers
  const mockExamTimer = useMockExamTimer();
  const simulationTimer = useSimulationTimer();
  const [currentSimulationTime, setCurrentSimulationTime] = useState<number>(0);
  const [finalTime, setFinalTime] = useState<number>(0);
  // Add ref for tracking current time
  const currentTimeRef = useRef<number>(0);
  // Add a ref to track total accumulated time
  const totalAccumulatedTimeRef = useRef<number>(0);

  // Add scroll helper function
  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: 'auto' });
  };


  // Update the time handler
  const handleTimeUpdate = useCallback((elapsed: number) => {
    currentTimeRef.current = elapsed;
    setDisplayTime(elapsed);
  }, []);

  // Add function to update total accumulated time
  const updateTotalAccumulatedTime = useCallback(() => {
    if (currentTimeRef.current > 0) {
      totalAccumulatedTimeRef.current += currentTimeRef.current;
    }
  }, []);

  // Reset accumulated time when starting a new quiz session
  useEffect(() => {
    if (!simulation?.id) return;
    
    // Only reset total time if this is the first simulation
    if (currentSimulationIndex === 0) {
      totalAccumulatedTimeRef.current = 0;
    }
    
    // Reset timer states for current simulation
    setDisplayTime(0);
    setCurrentSimulationTime(0);
    setFinalTime(0);
    
    return () => {
      simulationTimer.setIsRunning(false);
    };
  }, [simulation?.id, currentSimulationIndex, simulationTimer]);

  // Test Mode State
  const [isTestMode, setIsTestMode] = useState(() => {
    if (isMockExam) return true;
    const saved = localStorage.getItem('simulationTestMode');
    return saved ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    if (!isMockExam) {
      localStorage.setItem('simulationTestMode', JSON.stringify(isTestMode));
    }
  }, [isTestMode, isMockExam]);

  const handleTestModeToggle = useCallback((checked: boolean) => {
    if (!isMockExam) {
      setIsTestMode(checked);
    }
  }, [isMockExam]);


  // Update renderTimers function
  const renderTimers = () => {
    return (
      <div className="flex justify-between items-center mb-6">
        {/* Regular Timer for Simulation */}
        <div className="flex items-center">
          <span className="mr-2 text-gray-600 dark:text-gray-400">
            Simulation {currentSimulationIndex + 1} Time:
          </span>
          <div className={`transition-opacity duration-200 ${isSubmitted ? 'opacity-50' : 'opacity-100'}`}>
            <Timer
              onTimeUpdate={handleTimeUpdate}
              className="text-gray-700 dark:text-gray-300"
              fixedTime={isSubmitted ? finalTime : undefined}
              isRunning={!isSubmitted && !showSolution}
              startTime={Date.now()}
              elapsedTimeBeforePause={0}
            />
          </div>
        </div>
        
        {/* MockTimer for Mock Exam */}
        {isMockExam && (
          <div className="flex items-center">
            <span className="mr-2 text-gray-600 dark:text-gray-400">
              Mock Exam Time:
            </span>
            <MockTimer
              className="text-gray-700 dark:text-gray-300"
              variant="default"
              showIcon={false}
            />
          </div>
        )}
      </div>
    );
  };

  // Add effect to reset final submission time when simulation changes
  useEffect(() => {
    if (!simulation?.id) return;
    
    // Reset timer states
    setDisplayTime(0);
    setCurrentSimulationTime(0);
    setFinalTime(0);
    
    return () => {
      simulationTimer.setIsRunning(false);
    };
  }, [simulation?.id, simulationTimer]);

  // Combined effect for test mode handling and persistence
  useEffect(() => {
    if (isMockExam) {
      setIsTestMode(true);
    } else {
      localStorage.setItem('simulationTestMode', JSON.stringify(isTestMode));
    }
  }, [isTestMode, isMockExam]);


  // Enhanced reset effect that handles both regular and mock exam simulations
  // Simulation state reset effect - handles clearing state when simulation changes
  useEffect(() => {
    const handleSimulationStateReset = () => {
      // Don't reset if we're not actually changing simulations
      if (!simulation?.id) return;

      // Reset core simulation states
      setIsSubmitted(false);
      setShowSolution(false);
      setGradingResults(null);
      setConfidenceLevel(null);
      setUserAnswers({});
      setUserResponse(null);
      setShowExplanation(false);
      
      // Reset UI states
      setShowCalculator(false);
      setShowSpreadsheet(false);
      setShowFeedback(false);
      setShowResponseComparison(false);
      setShowMobileTools(false);
      setSubmissionError(null);

      // Ensure test mode is ON for mock exams
      if (isMockExam) {
        setIsTestMode(true);
      }
    };

    handleSimulationStateReset();
  }, [simulation?.id, isMockExam]);
  
  // Add state restoration from sessionStorage
  const [userAnswers, setUserAnswers] = useState<Record<string, any>>(() => {
    if (!quizHistoryId) return {};
    const saved = sessionStorage.getItem(`simulation_answers_${quizHistoryId}_${simulation.id}`);
    return saved ? JSON.parse(saved) : {};
  });

  // Add timer state restoration
  const [displayTime, setDisplayTime] = useState<number>(() => {
    if (!quizHistoryId) return 0;
    const saved = sessionStorage.getItem(`simulation_time_${quizHistoryId}_${simulation.id}`);
    return saved ? parseInt(saved, 10) : 0;
  });

  // Store answers in sessionStorage when they change
  useEffect(() => {
    if (quizHistoryId && simulation.id) {
      sessionStorage.setItem(
        `simulation_answers_${quizHistoryId}_${simulation.id}`,
        JSON.stringify(userAnswers)
      );
    }
  }, [userAnswers, quizHistoryId, simulation.id]);

  // Store time in sessionStorage when it changes
  useEffect(() => {
    if (quizHistoryId && simulation.id) {
      sessionStorage.setItem(
        `simulation_time_${quizHistoryId}_${simulation.id}`,
        displayTime.toString()
      );
    }
  }, [displayTime, quizHistoryId, simulation.id]);

  // Clear storage when simulation is submitted
  const clearStoredState = useCallback(() => {
    if (quizHistoryId && simulation.id) {
      sessionStorage.removeItem(`simulation_answers_${quizHistoryId}_${simulation.id}`);
      sessionStorage.removeItem(`simulation_time_${quizHistoryId}_${simulation.id}`);
    }
  }, [quizHistoryId, simulation.id]);

  // Only showing the modified useEffect section for clarity
  useEffect(() => {
    const loadUserAnswers = async () => {
      if (!user || !quizHistoryId || !simulation.id) return;
  
      try {
        // First check if we have a cached response for this simulation
        const cachedResponse = cachedResponses[simulation.id];
  
        // Load quizHistory and find current simulation data
        const quizHistory = await api.getSimulationQuizHistoryById(quizHistoryId);
        const simData = (quizHistory.questions_data as SimulationQuestionData[]).find(q => q.simulationId === simulation.id);
  
        const initializeAnswersFromQuestions = (questions: (TBSq | HTMLContent)[]) => {
          const initialAnswers: Record<string, any> = {};
          
          questions.forEach((item) => {
            // Skip HTML content
            if ('content' in item && typeof item.content === 'string') {
              return;
            }
  
            const tbsItem = item as TBSq;
            
            if (tbsItem.type === 'mixed_table' && tbsItem.content?.table) {
              const table = tbsItem.content.table;
              table.rows.forEach((row) => {
                row.cells.forEach((cell) => {
                  if (cell.type !== 'text' && cell.ref) {
                    if (!initialAnswers[tbsItem.id]) initialAnswers[tbsItem.id] = {};
                    initialAnswers[tbsItem.id][cell.ref] = {
                      value: cell.type === 'input' ? '0' : '',
                      touched: cell.type === 'input' // Auto-mark input fields as touched
                    };
                  }
                });
              });
            } else if (tbsItem.cells) {
              tbsItem.cells.forEach((cell) => {
                if (cell.type !== 'text' && cell.ref) {
                  if (!initialAnswers[tbsItem.id]) initialAnswers[tbsItem.id] = {};
                  initialAnswers[tbsItem.id][cell.ref] = {
                    value: cell.type === 'input' ? '0' : '',
                    touched: cell.type === 'input' // Auto-mark input fields as touched
                  };
                }
              });
            }
          });
  
          return initialAnswers;
        };
  
        if (!simData) {
          // No quiz history data for this simulation yet, initialize empty answers
          if (simulation.question_content && typeof simulation.question_content === 'object') {
            // If we have a cached response, use its answers
            if (cachedResponse?.answers) {
              setUserAnswers(cachedResponse.answers);
              setUserResponse(cachedResponse);
            } else {
              const initialAnswers = initializeAnswersFromQuestions(simulation.question_content.questions);
              setUserAnswers(initialAnswers);
              setUserResponse(null);
            }
          }
        } else {
          // We have quiz history data for this simulation
          if (simData.answered && simData.userAnswer) {
            const parsedUserAnswers = JSON.parse(simData.userAnswer);
            setUserAnswers(parsedUserAnswers);
  
            if (simData.feedback && simData.feedback.length > 0) {
              setGradingResults({
                score: simData.score,
                feedback: simData.feedback
              });
              setShowSolution(true);
              setIsSubmitted(true);
              setConfidenceLevel(simData.confidenceLevel || null);
            } else {
              // Not graded yet, user can still submit
              // Check if we have cached response data
              if (cachedResponse?.answers && !simData.answered) {
                setUserAnswers(cachedResponse.answers);
                setUserResponse(cachedResponse);
              }
              setIsSubmitted(false);
              setShowSolution(false);
            }
          } else {
            // Not answered in quiz history, check cache
            if (cachedResponse?.answers) {
              setUserAnswers(cachedResponse.answers);
              setUserResponse(cachedResponse);
            } else {
              // Initialize answers with touched state
              if (simulation.question_content && typeof simulation.question_content === 'object') {
                const initialAnswers = initializeAnswersFromQuestions(simulation.question_content.questions);
                setUserAnswers(initialAnswers);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error loading user answers:', error);
      }
    };
  
    loadUserAnswers();
  }, [user, simulation, courseId, quizHistoryId, cachedResponses]);

  const handleAnswerChange = (questionId: string, cellRef: string, value: string, touched: boolean = false) => {
    setUserAnswers(prevAnswers => {
      const newAnswers = {
        ...prevAnswers,
        [questionId]: {
          ...prevAnswers[questionId],
          [cellRef]: {
            value,
            touched: touched
          }
        }
      };
      return newAnswers;
    });
  };

  const isAllAnswered = useMemo(() => {
    if (!simulation.question_content || !simulation.question_content.questions) {
      return false;
    }
  
    const questionsToCheck = simulation.question_content.questions.filter(item => {
      return item.type !== 'html' && 
             !item.id.startsWith('totals') && 
             item.id !== 'initial' && 
             item.id !== 'adjusted_totals';
    });
  
    return questionsToCheck.every((item: any) => {
      const answer = userAnswers[item.id];
      let requiredFields = 0;
      let answeredFields = 0;
  
      if (item.type === 'mixed_table' && item.content?.table) {
        item.content.table.rows.forEach((row: any) => {
          row.cells.forEach((cell: any) => {
            if (cell.type !== 'text') {
              requiredFields++;
              const val = answer?.[cell.ref];
              // Check if the field has been touched by the user
              if (val?.touched) answeredFields++;
            }
          });
        });
      } else if (item.cells) {
        item.cells.forEach((cell: any) => {
          if (cell.type !== 'text') {
            requiredFields++;
            const val = answer?.[cell.ref];
            // Check if the field has been touched by the user
            if (val?.touched) answeredFields++;
          }
        });
      }
  
      return answeredFields === requiredFields;
    });
  }, [userAnswers, simulation.question_content]);

  const handleConfidenceSelection = (level: ConfidenceLevelValue) => {
    setConfidenceLevel(level);
  };

  // Add showUpgradeButton logic
  const showUpgradeButton = useMemo(() => {
    if (!userCourseAccesses || userCourseAccesses.length === 0) {
      return true;
    }
    
    const courseAccesses = userCourseAccesses.filter(access => access.course_id === courseId);
    if (courseAccesses.length === 0) {
      return true;
    }

    const hasFullAccess = courseAccesses.some(access => 
      access.tier_id === TIER_IDS.FULL_ACCESS || 
      access.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY
    );

    if (hasFullAccess) {
      return false;
    }

    const onlyHasRestrictedAccess = courseAccesses.every(access =>
      access.tier_id === TIER_IDS.FREE_TRIAL || 
      access.tier_id === TIER_IDS.MENTOR_ONLY
    );

    return onlyHasRestrictedAccess;
  }, [userCourseAccesses, courseId]);

  // Update the handleNavigation function to use the prop
  const handleNavigation = useCallback(async () => {
    if (isNavigating) return;
  
    setIsNavigating(true);
    setIsTransitioning(true);
  
    try {
      // Scroll to top before navigation
      scrollToTop();
  
      if (!currentQuizHistoryId) return;
  
      // Get current quiz history to check progression
      const quizHistory = await api.getSimulationQuizHistoryById(currentQuizHistoryId);
      const nextSimulationId = await api.getNextSimulationFromQuizHistory(
        currentQuizHistoryId, 
        simulation.id
      );
  
      // If there's a next simulation in practice mode, load it
      if (nextSimulationId && !isMockExam) {
        const nextSimulation = await api.getSimulationsById([nextSimulationId], userId, courseId);
        if (nextSimulation[0]) {
          setSelectedSimulation(nextSimulation[0]);
          setShowExplanation(false);
          setShowSolution(false);
          setIsSubmitted(false);
          setCurrentSimulationIndex(currentSimulationIndex + 1);
          setIsTransitioning(false);
          setIsNavigating(false);
          return;
        }
      }
  
      // If no next simulation or mock exam, proceed with end-of-quiz logic
      await invalidateSimulationData();
  
      if (isMockExam && mockExamId) {
        navigate(`/course/${courseId}/mock-exam/${mockExamId}`, {
          replace: true
        });
      } else {
        await api.endTBSSession(currentQuizHistoryId, totalAccumulatedTimeRef.current || 0);
        
        if (showUpgradeButton && currentSimulationIndex === totalSimulations - 1) {
          setShowEndQuizUpgrade(true);
        } else {
          navigate(`/course/${courseId}/simulation-review`, {
            state: {
              quizHistoryId: currentQuizHistoryId,
              courseId
            },
            replace: true
          });
        }
      }
      
      setSelectedSimulation(null);
      setShowExplanation(false);
      setCurrentSimulationIndex(0);
  
    } catch (error) {
      console.error('Navigation error:', error);
      setIsTransitioning(false);
    } finally {
      setIsNavigating(false);
    }
  }, [
    currentQuizHistoryId,
    isMockExam,
    mockExamId,
    courseId,
    showUpgradeButton,
    currentSimulationIndex,
    totalSimulations,
    navigate,
    setCurrentSimulationIndex,
    setShowExplanation,
    setSelectedSimulation,
    totalAccumulatedTimeRef,
    invalidateSimulationData,
    simulation.id,
    userId,
    isNavigating,
    scrollToTop
  ]);
  
  const handleEndTestlet = useCallback(async () => {
  if (!user || !quizHistoryId) return;
  setIsSubmitting(true);
  try {
    // No need to update time or call endQuizSession since everything is already updated
    // Just navigate to the appropriate page
    if (isMockExam && mockExamId) {
      navigate(`/course/${courseId}/mock-exam/${mockExamId}`, {
        replace: true
      });
    } else {
      navigate(`/course/${courseId}/simulation-review`, {
        state: { quizHistoryId, courseId },
        replace: true
      });
    }
  } catch (error) {
    console.error('Error ending testlet:', error);
  } finally {
    setIsSubmitting(false);
    setShowEndTestletModal(false);
  }
}, [user, quizHistoryId, courseId, isMockExam, mockExamId, navigate]);

  const handleTestlet3Completion = useCallback(async () => {
    // Add check to ensure this ONLY runs for testlet 3
    if (currentSimulationIndex !== 2) {
      console.log('Skipping testlet 3 completion logic - not testlet 3');
      return;
    }
  
    console.log('Starting testlet 3 completion sequence', {
      currentIndex: currentSimulationIndex,
      timerState: {
        isRunning: mockExamTimer.isRunning,
        startTime: mockExamTimer.startTime,
        elapsedBeforePause: mockExamTimer.elapsedTimeBeforePause
      }
    });
  
    // Calculate elapsed time
    const currentTime = Date.now();
    const elapsedSeconds = Math.floor((currentTime - mockExamTimer.startTime) / 1000) + mockExamTimer.elapsedTimeBeforePause;
  
    try {
      // First update localStorage
      localStorage.setItem('mockExamElapsedTime', elapsedSeconds.toString());
      localStorage.setItem('mockExamTimerPaused', 'true');
  
      // Then update timer context states one by one to ensure proper sequencing
      mockExamTimer.setElapsedTimeBeforePause(elapsedSeconds);
      await new Promise(resolve => setTimeout(resolve, 50));
      
      mockExamTimer.setIsRunning(false);
      await new Promise(resolve => setTimeout(resolve, 50));
      
      mockExamTimer.setSpecialBreakPaused(true);
      await new Promise(resolve => setTimeout(resolve, 50));
  
      // Verify timer state was updated correctly
      const verificationAttempts = 3;
      let attempt = 0;
      
      while (attempt < verificationAttempts) {
        const storedElapsedTime = localStorage.getItem('mockExamElapsedTime');
        const timerPaused = !mockExamTimer.isRunning && mockExamTimer.specialBreakPaused;
        
        if (storedElapsedTime === elapsedSeconds.toString() && timerPaused) {
          console.log('Timer state successfully updated for testlet 3', {
            elapsedSeconds,
            isRunning: mockExamTimer.isRunning,
            specialBreakPaused: mockExamTimer.specialBreakPaused
          });
          break;
        }
  
        console.warn(`Timer state verification attempt ${attempt + 1} failed, retrying...`);
        
        // Retry state updates
        localStorage.setItem('mockExamElapsedTime', elapsedSeconds.toString());
        localStorage.setItem('mockExamTimerPaused', 'true');
        mockExamTimer.setElapsedTimeBeforePause(elapsedSeconds);
        mockExamTimer.setIsRunning(false);
        mockExamTimer.setSpecialBreakPaused(true);
        
        await new Promise(resolve => setTimeout(resolve, 100));
        attempt++;
      }
  
      if (attempt === verificationAttempts) {
        throw new Error('Failed to verify timer state after multiple attempts');
      }
    } catch (error) {
      console.error('Error in handleTestlet3Completion:', error);
      throw error;
    }
  }, [mockExamTimer, currentSimulationIndex]);

  const debouncedHandleSubmit = useCallback(async () => {
    if (!user || !confidenceLevel || !isAllAnswered || !quizHistoryId || isSubmitting) return;

    setSubmissionError(null);
    setIsSubmitting(true);
    // Set transitioning state immediately for mock exams to prevent race conditions
    if (isMockExam) {
      setIsTransitioning(true);
    }
    const timeSpent = currentTimeRef.current;
    
    try {
      updateTotalAccumulatedTime();

      const results = await api.submitUserTBSResponse(
        user.id,
        simulation.id,
        userAnswers,
        confidenceLevel,
        courseId,
        timeSpent
      );

      if (!results || !results.feedback) {
        throw new Error('Feedback missing from results');
      }

      const result: SimulationResult = {
        simulation,
        userAnswers: { ...userAnswers },
        score: results.score,
        feedback: results.feedback,
        confidenceLevel,
        timeSpent
      };

      const questionData = isMockExam ? {
        type: 'mock_exam_sim' as const,
        questionId: simulation.id,
        simulationId: simulation.id,
        answered: true,
        isCorrect: results.score >= 70,
        confidenceLevel,
        userAnswer: JSON.stringify(userAnswers),
        score: results.score,
        feedback: results.feedback,
        free_trial_content: results.free_trial_content,
        question_results: results.question_results || [],
        mockExamId: mockExamId!,
        mockExamTestletId: mockExamTestletId!,
        testletNumber: currentSimulationIndex + 1,
        mockExamNumber: 1,
        timeSpent,
      } : {
        type: 'simulation' as const,
        questionId: simulation.id,
        simulationId: simulation.id,
        answered: true,
        isCorrect: results.score >= 70,
        timeSpent,
        confidenceLevel,
        userAnswer: JSON.stringify(userAnswers),
        score: results.score,
        feedback: results.feedback,
        free_trial_content: simulation.free_trial_content,
        question_results: results.question_results || []
      };

      // For mock exams, complete all operations before any state updates
      if (isMockExam) {
        try {
          // Update quiz history
          await api.updateSimulationQuizHistory(quizHistoryId, questionData);
          
          // Get next simulation info
          const [quizHistory, nextSimulationId] = await Promise.all([
            api.getSimulationQuizHistoryById(quizHistoryId),
            api.getNextSimulationFromQuizHistory(quizHistoryId, simulation.id)
          ]);

          console.log('Quiz progression:', {
            currentId: simulation.id,
            nextId: nextSimulationId,
            mode: 'mock',
            totalQuestions: quizHistory.total_questions
          });

          // Complete testlet if this is the last simulation
          if (!nextSimulationId && mockExamTestletId) {
            await api.completeTestlet(
              mockExamTestletId, 
              results.score, 
              timeSpent,
              quizHistoryId
            );

            const testletNumber = totalSimulations === 2 ? 3 : 
                                totalSimulations === 3 ? 4 : 5;

            if (testletNumber === 3) {
              await handleTestlet3Completion();
            }

            // Clear state and navigate
            clearStoredState();
            onSubmit(result);
            navigate(`/course/${courseId}/mock-exam/${mockExamId}`, {
              replace: true
            });
            return;
          }

          // Move to next simulation
          clearStoredState();
          onSubmit(result);
          setShowExplanation(false);
          setShowSolution(false);
          setConfidenceLevel(null);
          onNext();
          return;
        } catch (error) {
          console.error('Error in mock exam progression:', error);
          throw error;
        }
      }

      // Non-mock exam logic remains unchanged
      await api.updateSimulationQuizHistory(quizHistoryId, questionData);
      const quizHistory = await api.getSimulationQuizHistoryById(quizHistoryId);
      const nextSimulationId = await api.getNextSimulationFromQuizHistory(quizHistoryId, simulation.id);

      console.log('Quiz progression:', {
        currentId: simulation.id,
        nextId: nextSimulationId,
        mode: isTestMode ? 'test' : 'practice',
        totalQuestions: quizHistory.total_questions
      });

      if (isTestMode) {
        onSubmit(result);
        
        if (nextSimulationId) {
          setShowExplanation(false);
          setShowSolution(false);
          setConfidenceLevel(null);
          clearStoredState();
          onNext();
        } else {
          setIsTransitioning(true);
          setIsSubmitted(true);
          
          await api.endTBSSession(quizHistoryId, totalAccumulatedTimeRef.current || 0);
          clearStoredState();
          
          navigate(`/course/${courseId}/simulation-review`, {
            state: {
              quizHistoryId: quizHistoryId,
              courseId
            },
            replace: true
          });
        }
        return;
      }

      // Practice mode logic remains unchanged
      scrollToTop();
      onSubmit(result);
      setGradingResults({
        score: results.score,
        feedback: results.feedback,
      });
      setShowSolution(true);
      setUserResponse(results);
      setIsSubmitted(true);
      setShowExplanation(true);

      if (nextSimulationId) {
        console.log('Next simulation available:', nextSimulationId);
      } else {
        console.log('Final simulation in set completed');
      }

    } catch (error: any) {
      console.error('Submission error:', error);
      setSubmissionError('An error occurred while grading. Please try again.');
    } finally {
      if (!isTransitioning) {
        setIsSubmitting(false);
      }
    }
  }, [
    user, confidenceLevel, isAllAnswered, quizHistoryId, simulation, 
    userAnswers, courseId, onSubmit, isTestMode, onNext,
    isSubmitting, isMockExam, mockExamId, mockExamTestletId, 
    currentSimulationIndex, navigate, handleTestlet3Completion,
    totalSimulations, updateTotalAccumulatedTime, clearStoredState,
    scrollToTop, totalAccumulatedTimeRef, isTransitioning
  ]);

  // Add end quiz upgrade modal component
  const renderEndQuizUpgrade = () => {
    if (!showEndQuizUpgrade) return null;

    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-lg w-full mx-auto">
          <div className="flex justify-end p-2">
            <button
              onClick={() => {
                setShowEndQuizUpgrade(false);
                navigate(`/course/${courseId}/simulation-review`, {
                  state: {
                    quizHistoryId: currentQuizHistoryId,
                    courseId
                  },
                  replace: true
                });
              }}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-full p-1 
                       hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
            >
              <X size={24} />
            </button>
          </div>
          
          <div className="px-6 pb-6">
            <div className="text-center mb-6">
              <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
                Ready to Unlock Full Access?
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Great job completing the simulations! Upgrade now to access our complete
                simulation bank and boost your CPA exam preparation.
              </p>
              
              <div className="space-y-3">
                <Button
                  onClick={() => {
                    setShowEndQuizUpgrade(false);
                    setShowUpgradeModal(true);
                  }}
                  className="w-full bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center 
                           justify-center py-3 rounded-lg transition-colors"
                >
                  <Crown size={20} className="mr-2" />
                  Upgrade Now
                </Button>
                
                <Button
                  onClick={() => {
                    setShowEndQuizUpgrade(false);
                    navigate(`/course/${courseId}/simulation-review`, {
                      state: {
                        quizHistoryId: currentQuizHistoryId,
                        courseId
                      },
                      replace: true
                    });
                  }}
                  className="w-full bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 
                           text-gray-700 dark:text-gray-200 py-3 rounded-lg transition-colors"
                >
                  Maybe Later
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isTransitioning && (
        <div className="fixed inset-0 bg-white dark:bg-gray-900 z-50">
          <LoadingScreen 
            message="Processing..." 
            subMessage={isSubmitting ? "Grading your answers" : "Loading next question"} 
          />
        </div>
      )}
      <div ref={topRef} className="p-2 sm:p-4 md:p-8 bg-white dark:bg-gray-800 rounded-lg shadow-md max-w-[1000px] mx-auto border border-gray-400 dark:border-gray-700">
      {renderTimers()}
      <QuestionTimeManager
        questionId={simulation.id}
        questionType="simulation"
        isSubmitted={isSubmitted}
        onTimeUpdate={handleTimeUpdate}
      />

      {/* Mobile Tools Section */}
      <div className="mb-6">
        <div className="flex justify-between items-center mb-4 sm:hidden">
          <TestModeToggle 
            isTestMode={isTestMode} 
            onToggle={setIsTestMode}
            disabled={showSolution || isMockExam}
          />
          <Button
            onClick={() => setShowMobileTools(!showMobileTools)}
            className="btn btn-primary flex items-center justify-center"
          >
            <Wrench className="w-5 h-5 mr-2" />
            <span>Tools</span>
          </Button>
          {isSubmitted || showSolution ? (
            <NavigationButton
              onNavigate={handleNavigation}
              currentIndex={currentSimulationIndex}
              totalCount={totalSimulations}
              disabled={isNavigating}
              size="auto"
            />
          ) : (
            <Button 
              onClick={() => setShowEndTestletModal(true)} 
              className="bg-red-500 hover:bg-red-600 text-white"
            >
              End Simulation
            </Button>
          )}
        </div>

        {/* Desktop Tools Section */}
        <div className="hidden sm:flex sm:flex-row justify-between items-center mb-4 pb-4 border-b border-gray-300 dark:border-gray-700">
          <div>
            <TestModeToggle 
              isTestMode={isTestMode} 
              onToggle={handleTestModeToggle}
              disabled={showSolution || isMockExam}
            />
          </div>
          <div className="flex space-x-2">
            <Button onClick={() => setShowCalculator(!showCalculator)} className="btn btn-primary flex items-center justify-center">
              <CalculatorIcon className="w-5 h-5 mr-2" />
              <span>Calculator</span>
            </Button>
            <Button onClick={() => setShowSpreadsheet(!showSpreadsheet)} className="btn btn-primary flex items-center justify-center">
              <Table className="w-5 h-5 mr-2" />
              <span>Sheet</span>
            </Button>
            <Button onClick={() => setShowFeedback(!showFeedback)} className="btn btn-primary flex items-center justify-center">
              <HelpCircle className="w-5 h-5 mr-2" />
              <span>Feedback</span>
            </Button>
            {isSubmitted || showSolution ? (
              <NavigationButton
                onNavigate={handleNavigation}
                currentIndex={currentSimulationIndex}
                totalCount={totalSimulations}
                disabled={isNavigating}
                size="auto"
              />
            ) : (
              <Button 
                onClick={() => setShowEndTestletModal(true)} 
                className="bg-red-500 hover:bg-red-600 text-white font-bold"
              >
                End Simulation
              </Button>
            )}
          </div>
        </div>

        {/* Mobile Tools Dropdown */}
        {showMobileTools && (
          <div className="sm:hidden flex flex-col space-y-2 mb-4">
            <Button onClick={() => setShowCalculator(!showCalculator)} className="btn btn-primary flex items-center justify-center">
              <CalculatorIcon className="w-5 h-5 mr-2" />
              <span>Calculator</span>
            </Button>
            <Button onClick={() => setShowSpreadsheet(!showSpreadsheet)} className="btn btn-primary flex items-center justify-center">
              <Table className="w-5 h-5 mr-2" />
              <span>Sheet</span>
            </Button>
            <Button onClick={() => setShowFeedback(!showFeedback)} className="btn btn-primary flex items-center justify-center">
              <HelpCircle className="w-5 h-5 mr-2" />
              <span>Feedback</span>
            </Button>
          </div>
        )}
      </div>

      {/* Main Simulation Content */}
      <SimulationsRender
        simulation={simulation}
        onAnswerChange={handleAnswerChange}
        userAnswers={userAnswers}
        isSubmitted={isSubmitted}
        userResponse={userResponse}
        gradingResults={gradingResults}
        currentSimulationIndex={currentSimulationIndex}
        totalSimulations={totalSimulations}
        userId={userId}
        courseId={courseId}
        onTimeUpdate={handleTimeUpdate}
        isReviewing={false}
      />

      {/* Score Section - After Notes */}
      {gradingResults && (
        <div className="max-w-4xl mx-auto mt-8">
          <div 
            className={`score-box p-6 rounded-lg text-center text-2xl font-bold shadow-lg transform transition-all duration-200 ${
              (gradingResults.score ?? 0) >= 70 
                ? 'bg-green-500 text-white hover:bg-green-600'
                : 'bg-red-500 text-white hover:bg-red-600'
            }`}
          >
            Your Score: {gradingResults.score !== null ? Math.round(gradingResults.score) : 'N/A'}%
          </div>

          {/* Navigation after score */}
          <div className="mt-6 flex justify-center">
            <NavigationButton
              onNavigate={handleNavigation}
              currentIndex={currentSimulationIndex}
              totalCount={totalSimulations}
              disabled={isNavigating}
            />
          </div>
        </div>
      )}

      {/* Confidence Selection and Submit Section */}
      <div className="max-w-4xl mx-auto space-y-6">
        {!isSubmitted && (
          <div className="mt-4 sm:mt-8 bg-gray-50 dark:bg-gray-800 rounded-lg shadow-md p-4 sm:p-6">
            <div className="space-y-4 sm:space-y-6">
              <p className="text-base sm:text-lg font-semibold text-gray-900 dark:text-gray-200">
                {isAllAnswered 
                  ? "How confident are you in your answers?" 
                  : "Please answer all questions before selecting confidence level"}
              </p>
              <div className="flex flex-col sm:grid sm:grid-cols-3 gap-2 sm:gap-4">
                {(['guessing', 'maybe', 'confident'] as const).map((level) => {
                  const lastConfidenceLevel = simulation.confidenceHistory && simulation.confidenceHistory.length > 0
                    ? simulation.confidenceHistory
                        .reduceRight<ConfidenceLevelValue>((prev, current) => {
                          if (prev !== null) return prev;
                          if (current.level === 'confident' || current.level === 'maybe' || current.level === 'guessing') {
                            return current.level;
                          }
                          return null;
                        }, null)
                    : null;

                  const isLastConfidenceLevel = lastConfidenceLevel !== null && level === lastConfidenceLevel;
                  const isSelected = confidenceLevel === level;
                  
                  const getColorClass = (level: Exclude<ConfidenceLevelValue, null>) => {
                    switch(level) {
                      case 'guessing': return 'bg-red-500 hover:bg-red-600 dark:bg-red-700 dark:hover:bg-red-600';
                      case 'maybe': return 'bg-yellow-500 hover:bg-yellow-600 dark:bg-yellow-700 dark:hover:bg-yellow-600';
                      case 'confident': return 'bg-green-500 hover:bg-green-600 dark:bg-green-700 dark:hover:bg-green-600';
                    }
                  };

                  return (
                    <button
                      key={level}
                      onClick={() => handleConfidenceSelection(level)}
                      disabled={!isAllAnswered || isSubmitting}
                      className={`
                        py-3 px-4 rounded-lg font-medium transition-all duration-200 ease-in-out relative
                        ${isSelected 
                          ? `${getColorClass(level)} text-white border border-transparent`
                          : 'bg-white text-gray-800 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600 border border-gray-300 dark:border-gray-600'}
                        ${!isAllAnswered && 'opacity-50 cursor-not-allowed'}
                        ${isSelected && 'ring-2 ring-offset-2 ring-offset-gray-100 dark:ring-offset-gray-800 ring-blue-500'}
                      `}
                    >
                      {level.charAt(0).toUpperCase() + level.slice(1)}
                      {isLastConfidenceLevel && !isSelected && (
                        <span className="ml-1 text-xs">(Previous)</span>
                      )}
                    </button>
                  );
                })}
              </div>
              <button
                onClick={debouncedHandleSubmit}
                disabled={!confidenceLevel || !isAllAnswered || isSubmitting}
                className={`
                  w-full py-3 px-6 rounded-lg text-white font-semibold transition-all duration-200 ease-in-out relative
                  ${confidenceLevel && isAllAnswered && !isSubmitting
                    ? 'bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700'
                    : 'bg-gray-300 dark:bg-gray-600 text-gray-500 dark:text-gray-400 cursor-not-allowed'}
                `}
              >
                {isSubmitting && (
                  <span className="absolute inset-0 flex items-center justify-center">
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </span>
                )}
                <span className={isSubmitting ? 'invisible' : ''}>Submit Answers</span>
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Notes Section - Moved up */}
      <div className="max-w-4xl mx-auto mt-8">
        <SimulationNotes 
          simulationId={simulation.id}
          courseId={courseId}
          userId={userId}
        />
      </div>
      
      {/* Response Comparison - After Score */}
      {gradingResults && (
        <div className="max-w-4xl mx-auto mt-8">
          <button
            onClick={() => setShowResponseComparison(!showResponseComparison)}
            className={`
              w-full p-4 rounded-lg shadow-md 
              transition-all duration-300 ease-in-out
              flex items-center justify-between
              ${isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600 text-white' 
                : 'bg-gray-50 hover:bg-gray-100 text-gray-900'}
            `}
          >
            <div className="flex items-center space-x-3">
              <span className="text-lg font-semibold">Response Comparison</span>
              <span className="text-sm text-opacity-70">
                {showResponseComparison ? 'Click to collapse' : 'Click to expand'}
              </span>
            </div>
            {showResponseComparison ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </button>

          {showResponseComparison && (
            <div className="mt-4 space-y-4 transition-all duration-300 ease-in-out">
              {gradingResults.feedback
                .filter(f => f.questionId !== 'totals' && f.questionId !== 'header')
                .map((item) => {
                  const questionNum = item.questionId.replace(/[^0-9]/g, '');
                  return (
                    <div 
                      key={item.questionId} 
                      className={`
                        p-6 rounded-lg shadow-md transition-all duration-200
                        ${isDarkMode ? 'bg-gray-800 hover:bg-gray-750' : 'bg-white hover:bg-gray-50'}
                      `}
                    >
                      <h4 className="text-lg font-semibold mb-4">Question {questionNum}</h4>
                      <div className="space-y-4">
                        {Object.entries(item.answerFeedback).map(([key, val]) => {
                          const isCorrect = val.isCorrect;
                          const userAnswerValue = typeof val.userAnswer === 'object' && val.userAnswer !== null
                            ? val.userAnswer.value
                            : val.userAnswer;
                          const correctAnswerValue = typeof val.correctAnswer === 'object' && val.correctAnswer !== null
                            ? val.correctAnswer.value
                            : val.correctAnswer;

                          return (
                            <div 
                              key={key} 
                              className={`
                                p-5 rounded-lg transition-colors duration-200
                                ${isCorrect 
                                  ? 'bg-green-100 dark:bg-green-900/40' 
                                  : 'bg-red-100 dark:bg-red-900/40'}
                              `}
                            >
                              <div className="grid grid-cols-[auto,1fr] gap-x-6 gap-y-3">
                                <p className={`text-right min-w-[120px] ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                  Your answer:
                                </p>
                                <p className={`text-left font-mono whitespace-normal break-words ${
                                  isCorrect 
                                    ? 'text-green-600 dark:text-green-400' 
                                    : 'text-red-600 dark:text-red-400'
                                }`}>
                                  {userAnswerValue}
                                </p>
                                <p className={`text-right min-w-[120px] ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                  Correct answer:
                                </p>
                                <p className="text-left font-mono font-semibold whitespace-normal break-words">
                                  {correctAnswerValue}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}

      {/* Solution section - Moved to bottom */}
      {showSolution && (
        <div className="max-w-4xl mx-auto mt-8 mb-8">
          <div className="solution">
            <h3 className="text-xl font-bold mb-4">Solution:</h3>
            <div 
              dangerouslySetInnerHTML={{ 
                __html: `<div class="${isDarkMode ? 'text-gray-300' : 'text-gray-700'}
                  [&>div]:space-y-6 
                  [&>div>div]:bg-gray-800/5 
                  dark:[&>div>div]:bg-gray-100/5 
                  [&>div>div]:p-4 
                  [&>div>div]:rounded-lg 
                  [&_p]:mb-4 
                  last:[&_p]:mb-0 
                  [&_h5]:font-bold 
                  [&_h5]:text-lg 
                  [&_h5]:mb-2
                  [&_ul]:list-disc
                  [&_ul]:pl-8
                  [&_ul]:mb-4
                  [&_ul>li]:list-item
                  [&_ul>li]:text-left
                  [&_ul>li]:ml-4
                  [&_ul.list-inside>li]:ml-0
                  [&_ul.list-inside>li]:pl-8
                  [&_li]:mb-2
                  [&_li]:pl-2
                  [&_li]:relative
                  [&_ol]:list-decimal
                  [&_ol]:pl-8
                  [&_ol]:mb-4
                  [&_ol>li]:list-item
                  [&_ol>li]:text-left
                  [&_ol>li]:ml-4
                  [&_ol.list-inside>li]:ml-0
                  [&_ol.list-inside>li]:pl-8
                  [&_strong]:font-semibold
                ">${simulation.answer_content}</div>` 
              }}
            />
          </div>

          {/* Final navigation button */}
          <div className="mt-8 flex justify-center">
            <NavigationButton
              onNavigate={handleNavigation}
              currentIndex={currentSimulationIndex}
              totalCount={totalSimulations}
              disabled={isNavigating}
            />
          </div>
        </div>
      )}

      {/* Modals and Popups */}
      <EndTBSTestlet
        isOpen={showEndTestletModal}
        onClose={() => setShowEndTestletModal(false)}
        onEndTestlet={handleEndTestlet}
        isLastQuestion={currentSimulationIndex === totalSimulations - 1}
        quizHistoryId={quizHistoryId || ''}
        isViewingExplanation={showSolution}
        courseId={courseId}
      />
      <Calculator 
        isOpen={showCalculator} 
        onClose={() => setShowCalculator(false)}
      />
      <Spreadsheet 
        isOpen={showSpreadsheet} 
        onClose={() => setShowSpreadsheet(false)}
        onFocusChange={() => {}}
      />
      <FeedbackPopup 
        isOpen={showFeedback} 
        onClose={() => setShowFeedback(false)}
        contentId={simulation.id}
        contentType="simulations"
        contentTitle={`${simulation.question_category_name || ''} - ${simulation.question_content.introduction?.slice(0, 50)}`}
      />
    {/* Add upgrade modal render */}
      {renderEndQuizUpgrade()}
      </div>
  </>
);
};

export default React.memo(SimulationQuiz);