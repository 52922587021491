import React, { createContext, useContext, useState } from 'react';

interface TextSelectionContextType {
  isSelectionPopupVisible: boolean;
  setSelectionPopupVisible: (visible: boolean) => void;
}

const TextSelectionContext = createContext<TextSelectionContextType>({
  isSelectionPopupVisible: false,
  setSelectionPopupVisible: () => {},
});

export const TextSelectionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSelectionPopupVisible, setSelectionPopupVisible] = useState(false);

  return (
    <TextSelectionContext.Provider 
      value={{ 
        isSelectionPopupVisible, 
        setSelectionPopupVisible 
      }}
    >
      {children}
    </TextSelectionContext.Provider>
  );
};

export const useTextSelection = () => useContext(TextSelectionContext);