import React from 'react';
import { 
  Clock, 
  CheckCircle, 
  Calendar,
  X,
  Repeat,
  Filter,
  ScrollText
} from 'lucide-react';
import DOMPurify from 'dompurify';

interface StudyPlanItemProps {
  item: {
    item_id: string;
    primary_topic_title: string | null;
    secondary_topic_title: string | null;
    blueprint_topic_title?: string | null;
    sequence_number: number;
    topic_type: string | null;
    is_completed: boolean;
    week_number: number | null;
    due_date?: Date;
  };
  primaryDisplayName: string;
  secondaryDisplayName: string;
  isPrimaryBlueprint: boolean;
  isSecondaryBlueprint?: boolean;
  onItemClick: (itemId: string, topicType: string | null) => void;
  startDate: Date; // Add startDate prop
}

const processHtmlContent = (content: string) => {
  return DOMPurify.sanitize(content, { ALLOWED_TAGS: ['span'], ALLOWED_ATTR: ['class'] });
};

type TaskStatus = 'completed' | 'late' | 'due' | 'future';

const StatusIcon: React.FC<{ status: TaskStatus; topicType: string | null }> = ({ status, topicType }) => {
  const iconProps = {
    className: "w-5 h-5",
    strokeWidth: 2
  };

  // If task is completed, always show checkmark
  if (status === 'completed') {
    return <CheckCircle {...iconProps} className="w-5 h-5 text-green-500" />;
  }

  // For incomplete tasks, check topic type first
  switch (topicType?.toLowerCase()) {
    case 'loop':
      return <Repeat {...iconProps} className="w-5 h-5 text-blue-500" />;
    case 'funnel':
    case 'retake_funnel':
      return <Filter {...iconProps} className="w-5 h-5 text-purple-500" />;
    case 'mock_exam':
      return <ScrollText {...iconProps} className="w-5 h-5 text-orange-500" />;
    default:
      // For other types, use status-based icons
      switch (status) {
        case 'late':
          return <X {...iconProps} className="w-5 h-5 text-red-500" />;
        case 'due':
          return <Clock {...iconProps} className="w-5 h-5 text-yellow-500" />;
        case 'future':
          return <Calendar {...iconProps} className="w-5 h-5 text-blue-500" />;
      }
  }
};

const TopicRow: React.FC<{ displayName: string; content: string }> = ({ displayName, content }) => (
  <div className="flex items-baseline gap-2 text-sm">
    <span className="font-medium text-gray-600 dark:text-gray-400 whitespace-nowrap">
      {displayName}
    </span>
    <span className="text-gray-400 dark:text-gray-500 flex-shrink-0">•</span>
    <div 
      className="font-medium text-gray-900 dark:text-white"
      dangerouslySetInnerHTML={{ __html: processHtmlContent(content) }}
    />
  </div>
);

interface WeekRange {
  start: Date;
  end: Date;
  weekNumber: number;
}

const getWeekRange = (weekNumber: number, startDate: Date): WeekRange => {
  // Clone the start date to avoid modifying the original
  const weekStart = new Date(startDate);
  
  // Calculate the start of the specified week
  weekStart.setDate(startDate.getDate() + ((weekNumber - 1) * 7));
  
  // Calculate the end of the week (6 days after start)
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 6);

  return {
    start: weekStart,
    end: weekEnd,
    weekNumber
  };
};

const getTaskStatus = (item: StudyPlanItemProps['item'], startDate: Date): TaskStatus => {
  if (item.is_completed) return 'completed';
  
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // If we have a week number, use the week range
  if (item.week_number !== null) {
    const weekRange = getWeekRange(item.week_number, startDate);
    
    // If today is within the week range, task is current/due
    if (today >= weekRange.start && today <= weekRange.end) {
      return 'due';
    }
    
    // If today is after the week's end date, task is late
    if (today > weekRange.end) {
      return 'late';
    }
    
    // If today is before the week's start date, task is future
    return 'future';
  }

  // If we have a specific due date, use that
  if (item.due_date) {
    const dueDate = new Date(item.due_date);
    dueDate.setHours(0, 0, 0, 0);

    if (dueDate < today) {
      return 'late';
    }
    if (dueDate.getTime() === today.getTime()) {
      return 'due';
    }
  }

  return 'future';
};

export const StudyPlanItem = React.memo(({
  item,
  primaryDisplayName,
  secondaryDisplayName,
  isPrimaryBlueprint,
  isSecondaryBlueprint = false,
  onItemClick,
  startDate
}: StudyPlanItemProps) => {
  const status = getTaskStatus(item, startDate);
  
  const getStatusClasses = (status: TaskStatus) => {
    switch (status) {
      case 'completed':
        return 'bg-green-50 dark:bg-green-900/20 border-green-200 dark:border-green-800';
      case 'late':
        return 'bg-red-50 dark:bg-red-900/20 border-red-200 dark:border-red-800';
      case 'due':
        return 'bg-yellow-50 dark:bg-yellow-900/20 border-yellow-200 dark:border-yellow-800';
      case 'future':
        return 'bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700';
      default:
        return 'bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700';
    }
  };
  
  // Check if either provider is blueprint-based
  const shouldShowBlueprint = !isPrimaryBlueprint && !isSecondaryBlueprint;
  
  return (
    <div className={`px-6 py-4 border-l-4 ${getStatusClasses(status)}`}>
      <div className="space-y-3">
        <div className="flex flex-col sm:flex-row sm:items-center gap-3">
          <div className="flex items-center gap-3 sm:w-48">
            <StatusIcon status={status} topicType={item.topic_type} />
            <span className="text-base font-medium text-gray-700 dark:text-gray-300">
              Task #{item.sequence_number}
            </span>
          </div>

          <div className="flex flex-col sm:flex-row sm:items-start justify-between gap-4 flex-grow">
            <div className="space-y-2 min-w-0 flex-grow">
              {item.primary_topic_title && (
                <TopicRow 
                  displayName={primaryDisplayName}
                  content={item.primary_topic_title}
                />
              )}
              
              {item.secondary_topic_title && (
                <TopicRow 
                  displayName={secondaryDisplayName}
                  content={item.secondary_topic_title}
                />
              )}

              {/* Only show Blueprint row if neither provider is blueprint-based */}
              {shouldShowBlueprint && item.blueprint_topic_title && (
                <TopicRow 
                  displayName="Blueprint"
                  content={item.blueprint_topic_title}
                />
              )}
            </div>

            <div className="w-full sm:w-auto sm:ml-4 flex justify-center sm:justify-end">
              <button
                onClick={() => onItemClick(item.item_id, item.topic_type)}
                className={`w-full sm:w-auto px-4 py-2 text-sm font-medium rounded-md transition-colors whitespace-nowrap ${
                  item.is_completed
                    ? 'bg-primary-orange hover:bg-primary-orange-hover text-white'
                    : status === 'late'
                    ? 'bg-red-500 hover:bg-red-600 text-white'
                    : 'bg-primary-blue hover:bg-primary-blue-hover text-white'
                }`}
              >
                {item.is_completed ? 'Review' : status === 'late' ? 'Start (Late)' : 'Start'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

StudyPlanItem.displayName = 'StudyPlanItem';

export default StudyPlanItem;