import React, { useState, useEffect, useRef } from 'react';
import { Clock } from 'lucide-react';

interface TimerProps {
  onTimeUpdate?: (elapsedTime: number) => void;
  className?: string;
  fixedTime?: number;
  isRunning?: boolean;
  startTime?: number;
  elapsedTimeBeforePause?: number;
  specialBreakPaused?: boolean;
}

const Timer: React.FC<TimerProps> = ({
  onTimeUpdate,
  className = '',
  fixedTime,
  isRunning = true,
  startTime: propStartTime,
  elapsedTimeBeforePause = 0,
  specialBreakPaused = false
}) => {
  const [displayTime, setDisplayTime] = useState<number>(elapsedTimeBeforePause);
  const intervalRef = useRef<NodeJS.Timeout>();
  const startTimeRef = useRef<number>(propStartTime || Date.now());
  const elapsedBeforePauseRef = useRef<number>(elapsedTimeBeforePause);

  useEffect(() => {
    // Clear any existing interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }

    // If we have a fixed time, just display that
    if (fixedTime !== undefined) {
      setDisplayTime(fixedTime);
      if (onTimeUpdate) {
        onTimeUpdate(fixedTime);
      }
      return;
    }

    // Don't start timer if not running or special break
    if (!isRunning || specialBreakPaused) {
      return;
    }

    // Initialize or reset start time
    startTimeRef.current = Date.now();
    elapsedBeforePauseRef.current = elapsedTimeBeforePause;

    // Start the timer
    intervalRef.current = setInterval(() => {
      const now = Date.now();
      const elapsedSeconds = Math.floor((now - startTimeRef.current) / 1000);
      const totalElapsed = elapsedSeconds + elapsedBeforePauseRef.current;
      
      setDisplayTime(totalElapsed);
      if (onTimeUpdate) {
        onTimeUpdate(totalElapsed);
      }
    }, 1000);

    // Cleanup function
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    };
  }, [isRunning, specialBreakPaused, fixedTime, elapsedTimeBeforePause, onTimeUpdate]);

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <Clock className="w-5 h-5" />
      <span className="font-mono text-lg">{formatTime(Math.max(0, displayTime))}</span>
    </div>
  );
};

export default Timer;