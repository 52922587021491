import { useState, useEffect } from 'react';
import ProductList from '../components/products/ProductList';
import { useProducts } from '../components/ProductProvider';
import { userDataService } from '../services/userDataService';
import { courseService } from '../services/courseService';
import { Product, UserAccessState, TIER_IDS } from '../services/api';
import { getUserProductAccess } from '../utils/accessUtils';
import UpgradeModal from '../components/course/common/UpgradeModal';
import UpgradeSMOnlyModal from '../components/course/common/switch-course/UpgradeSMOnlyModal';
import SwitchConfirmationModal from '../components/course/common/switch-course/SwitchConfirmationModal';
import { useAuth } from '../components/AuthProvider';
import { useUserCourseAccess } from '../components/UserCourseAccessProvider';
import { auth } from '../services/auth'; // Add this import
import { useNotification } from '../contexts/NotificationContext';
import LoadingScreen from '../components/course/common/LoadingScreen';

function Products() {
  console.log('Products component rendering');

  const [products, setProducts] = useState<Product[]>([]);
  const [accessStates, setAccessStates] = useState<Record<string, UserAccessState>>({});
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showSMOnlyModal, setShowSMOnlyModal] = useState(false);
  const [showSwitchModal, setShowSwitchModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<{id: string, title: string} | null>(null);
  const [currentCourse, setCurrentCourse] = useState<{id: string, title: string} | null>(null);
  
  const { user, loading: userLoading, error } = useAuth();
  const { getProduct, loading: productsLoading, error: productsError, refreshProducts } = useProducts();
  const { refetch: refetchUserCourseAccess } = useUserCourseAccess();
  const { showNotification } = useNotification();
  const [isSwitching, setIsSwitching] = useState(false);

  console.log('Hook states:', {
    userLoading,
    userError: error?.message,
    productsLoading,
    productsError: productsError,
    hasUser: !!user,
    userId: user?.id
  });

  useEffect(() => {
    async function fetchUserData() {
      if (!user) {
        console.log('fetchUserData: No user available, skipping fetch');
        return;
      }

      console.log('fetchUserData: Starting data fetch for user', user.id);

      try {
        console.time('getAllUserData');
        const userData = await userDataService.getAllUserData(user.id);
        console.timeEnd('getAllUserData');
        console.log('User data fetched:', {
          courseAccessesCount: userData.courseAccesses.length,
          coursePurchasesCount: userData.coursePurchases.length,
          hasMentorshipAccess: !!userData.mentorshipAccess
        });

        const courseDetails = await Promise.all(
          userData.courseAccesses.map(access => {
            console.log('Fetching product details for course:', access.course_id);
            return getProduct(access.course_id);
          })
        );

        const validProducts = courseDetails.filter((product): product is Product => {
          const isValid = product !== null;
          if (!isValid) {
            console.warn('Found null product in courseDetails');
          }
          return isValid;
        });
        console.log('Valid products count:', validProducts.length);

        setProducts(validProducts);

        console.time('calculateAccessStates');
        const states = validProducts.reduce((acc, product) => {
          acc[product.id] = getUserProductAccess(
            product,
            userData.courseAccesses,
            userData.coursePurchases,
            userData.mentorshipAccess || undefined
          );
          return acc;
        }, {} as Record<string, UserAccessState>);
        console.timeEnd('calculateAccessStates');

        console.log('Access states calculated:', {
          productCount: Object.keys(states).length,
          statesSummary: Object.entries(states).map(([id, state]) => ({
            id,
            hasFullAccess: state.hasFullAccess,
            isActivatable: state.isActivatable,
            hasMentorAccess: state.hasMentorAccess
          }))
        });

        setAccessStates(states);

        // Find active course
        const activeCourse = validProducts.find(p => 
          states[p.id]?.hasFullAccess || states[p.id]?.currentTier === TIER_IDS.FULL_ACCESS || 
          states[p.id]?.currentTier === TIER_IDS.STUDY_MATERIALS_ONLY
        );

        // If no active course found but user has switches available,
        // find another course that's not the mentorship course
        // and preferably not the BAR course (which is often selected first)
        const nonMentorshipCourses = validProducts.filter(p => 
          p.type !== 'mentorship' && 
          p.id !== '7b008bd2-4b5d-4876-82d5-c9f1e05895e0' // BAR course ID
        );

        const firstAvailableCourse = !activeCourse && user.switches_available > 0 && nonMentorshipCourses.length > 0
          ? nonMentorshipCourses[0]
          : null;

        const courseToUse = activeCourse || firstAvailableCourse;

        if (courseToUse) {
          console.log('Setting active/first available course:', {
            id: courseToUse.id,
            title: courseToUse.title,
            isActive: !!activeCourse
          });
          setCurrentCourse({
            id: courseToUse.id,
            title: courseToUse.title
          });
        } else {
          console.log('No active or available course found');
        }
      } catch (err) {
        console.error('Failed to fetch user data:', err);
        const errorDetails = err instanceof Error ? {
          message: err.message,
          stack: err.stack
        } : err;
        console.error('Error details:', errorDetails);
        alert('Failed to load course data. Please try again.');
      }
    }

    fetchUserData();
  }, [user, getProduct]);

  const handleActivate = async (productId: string) => {
    console.log('handleActivate called for product:', productId);

    if (currentCourse?.id === productId) {
      console.log('Cannot switch to the same course');
      showNotification(
        'Cannot switch to the same course you are switching from. Please select a different course.',
        'error'
      );
      return;
    }

    const product = products.find(p => p.id === productId);
    if (product) {
      console.log('Setting selected product:', {
        id: product.id,
        title: product.title
      });
      setSelectedProduct({
        id: product.id,
        title: product.title
      });
      setShowSwitchModal(true);
    }
  };

  const refreshAllData = async (userId: string) => {
    console.log('Starting complete data refresh');
    try {
      // Clear cache first
      courseService.clearUserCache(userId);

      // Get fresh user data from auth service
      const freshUserData = await auth.getUserProfile(userId);
      console.log('Fresh user data fetched:', {
        switchesAvailable: freshUserData.switches_available
      });

      // Refresh all necessary data concurrently
      const [userData, ] = await Promise.all([
        userDataService.refreshUserData(userId),
        refreshProducts(),
        refetchUserCourseAccess()
      ]);

      // Get updated course details
      const courseDetails = await Promise.all(
        userData.courseAccesses.map(access => getProduct(access.course_id))
      );

      const validProducts = courseDetails.filter((product): product is Product => product !== null);

      // Calculate new access states with fresh user data
      const newStates = validProducts.reduce((acc, product) => {
        acc[product.id] = getUserProductAccess(
          product,
          userData.courseAccesses,
          userData.coursePurchases,
          userData.mentorshipAccess || undefined
        );
        return acc;
      }, {} as Record<string, UserAccessState>);

      // Update component state
      setProducts(validProducts);
      setAccessStates(newStates);

      // Return the fresh user data for verification
      return freshUserData;
    } catch (error) {
      console.error('Error during data refresh:', error);
      throw error;
    }
  };

  if (userLoading || productsLoading) {
    console.log('Rendering loading state:', {
      userLoading,
      productsLoading
    });
    return <div>Loading...</div>;
  }

  if (productsError) {
    console.error('Rendering error state:', productsError);
    return <div className="text-red-500">Failed to load courses</div>;
  }

  console.log('Rendering full Products component:', {
    productsCount: products.length,
    accessStatesCount: Object.keys(accessStates).length,
    switchesAvailable: user?.switches_available
  });

  return (
    <div className="max-w-6xl mx-auto">
      {isSwitching && (
        <LoadingScreen 
          message="Switching Course Access"
          subMessage="Please wait while we update your course access..."
        />
      )}
      
      <h1 className="text-3xl font-bold mb-6 px-4 md:px-0">Available Courses</h1>
      <ProductList 
        products={products}
        accessStates={accessStates}
        switchesAvailable={user?.switches_available ?? 0}
        onUpgrade={() => {
          console.log('Upgrade modal triggered');
          setShowUpgradeModal(true);
        }}
        onUpgradeSMOnly={() => {
          console.log('Upgrade SM Only modal triggered');
          setShowSMOnlyModal(true);
        }}
        onActivate={handleActivate}
        onRequestSwitch={() => {
          console.log('Switch request triggered');
          showNotification('Please contact support to request additional course switches.', 'info');
        }}
      />
  
      <UpgradeModal 
        isOpen={showUpgradeModal} 
        onClose={() => setShowUpgradeModal(false)} 
      />
      
      <UpgradeSMOnlyModal 
        isOpen={showSMOnlyModal} 
        onClose={() => setShowSMOnlyModal(false)}
      />
      
      <SwitchConfirmationModal 
        isOpen={showSwitchModal}
        onClose={() => setShowSwitchModal(false)}
        onConfirm={async () => {
          if (!user || !selectedProduct || !currentCourse) {
            console.log('Switch confirmation cancelled - missing required data');
            return;
          }
  
          if (selectedProduct.id === currentCourse.id) {
            console.log('Prevented switch to same course');
            showNotification(
              'Cannot switch to the same course you are switching from. Please select a different course.',
              'error'
            );
            setShowSwitchModal(false);
            return;
          }
        
          try {
            setIsSwitching(true);
            setShowSwitchModal(false); // Close the confirmation modal first
            
            console.log('Attempting course switch');
  
            const result = await courseService.handleCourseSwitch(
              user.id,
              selectedProduct.id,
              currentCourse.id,
              true
            );
        
            if (result.success) {
              await new Promise(resolve => setTimeout(resolve, 100));
              const freshUserData = await refreshAllData(user.id);
              
              showNotification('Your course access has been updated successfully.', 'success');
  
              if (freshUserData.switches_available === 0) {
                // Keep the loading screen visible for a moment so user can see the notification
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              } else {
                setIsSwitching(false);
              }
            } else {
              console.error('Switch failed:', result.message);
              showNotification(
                result.message || 'Failed to switch courses. Please try again.',
                'error'
              );
              setIsSwitching(false);
            }
          } catch (error) {
            console.error('Error during course switch:', error);
            showNotification(
              'An error occurred while switching courses. Please try again.',
              'error'
            );
            setIsSwitching(false);
          }
        }}
        switchesAvailable={user?.switches_available ?? 0}
        fromCourse={currentCourse?.title ?? 'Current Course'}
        toCourse={selectedProduct?.title ?? 'Selected Course'}
      />
    </div>
  );
}

export default Products;