import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useParams } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider, useAuth } from './components/AuthProvider';
import { ProductProvider } from './components/ProductProvider';
import { UserCourseAccessProvider } from './components/UserCourseAccessProvider';
import { ThemeProvider } from './contexts/ThemeContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { ThriveCartProvider } from './contexts/ThriveCartContext';
import { TextSelectionProvider } from './contexts/TextSelectionContext';
import { 
  MockExamTimerProvider,
  SimulationTimerProvider,
  QuizTimerProvider 
} from './contexts/TimerContext';
import { api } from './services/api';
import Layout from './components/layout/Layout';
import Home from './pages/Home';
import Products from './pages/Products';
import Course from './pages/Course';
import Profile from './pages/Profile';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Logout from './components/auth/Logout';
import ForgotPassword from './components/auth/ForgotPassword';
import VerifyEmail from './components/auth/VerifyEmail';
import ResetPassword from './components/auth/ResetPassword';
import TextSelectionPopup from './components/course/PiracyProtection/TextSelectionPopup';
import SupportDashboard from './components/admin/support/SupportDashboard';
import TicketComposer from './components/admin/user/TicketComposer';
import UserTicketDashboard from './components/admin/user/UserTicketDashboard';

export const ModalContext = React.createContext<{
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isModalOpen: false,
  setIsModalOpen: () => {},
});

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRole?: 'admin' | 'moderator' | 'user';
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000, // 1 hour
      cacheTime: 24 * 60 * 60 * 1000, // 24 hours
      refetchOnWindowFocus: false,
      refetchOnMount: true, // Keep this true to ensure initial data load
      refetchOnReconnect: false,
      retry: false,
      refetchInterval: false // Prevent periodic refetching
    },
    mutations: {
      retry: false,
    }
  },
});

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredRole }) => {
  const { user, loading } = useAuth();
  
  if (loading) return <div>Loading...</div>;
  
  if (!user) return <Navigate to="/login" replace />;
  
  if (requiredRole && user.role !== requiredRole) {
    // If role doesn't match, redirect to home
    return <Navigate to="/" replace />;
  }
  
  return <>{children}</>;
};

const CourseRoute: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const { isModalOpen } = React.useContext(ModalContext);

  return (
    <>
      <Course />
      {!isModalOpen && courseId && <TextSelectionPopup courseId={courseId} />}
    </>
  );
};

const PublicOnlyRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user, loading } = useAuth();
  if (loading) return <div>Loading...</div>;
  if (user) return <Navigate to="/" replace />;
  return <>{children}</>;
};

function AuthListener() {
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      api.clearAllCache();
      queryClient.clear(); // Clear React Query cache when user logs out
    }
  }, [user]);

  return null;
}

// Simplified FlashcardRoutes since we no longer need special IDB handling
function FlashcardRoutes() {
  return <Course />;
}

function AppRoutes() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log('AppRoutes: Current path:', location.pathname);
  }, [location.pathname]);

  return (
    <ModalContext.Provider value={{ isModalOpen, setIsModalOpen }}>
      <Layout>
        <AuthListener />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={
            <ProtectedRoute>
              <Products />
            </ProtectedRoute>
          } />
          <Route path="/user/support" element={
            <ProtectedRoute>
              <UserTicketDashboard />
            </ProtectedRoute>
          } />
          <Route path="/user/support/new" element={
            <ProtectedRoute>
              <TicketComposer onBack={() => window.history.back()} />
            </ProtectedRoute>
          } />
          <Route path="/admin/support" element={
            <ProtectedRoute requiredRole="admin">
              <SupportDashboard />
            </ProtectedRoute>
          } />
          
          {/* Updated Course routes to use FlashcardRoutes */}
          <Route path="course/:courseId/*" element={
            <ProtectedRoute>
              <FlashcardRoutes />
            </ProtectedRoute>
          } />
          
          <Route path="/profile" element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          } />
          
          {/* Auth routes remain the same */}
          <Route path="/login" element={
            <PublicOnlyRoute>
              <Login />
            </PublicOnlyRoute>
          } />
          
          <Route path="/register" element={
            <PublicOnlyRoute>
              <Register />
            </PublicOnlyRoute>
          } />
          <Route path="/forgot-password" element={
            <PublicOnlyRoute>
              <ForgotPassword />
            </PublicOnlyRoute>
          } />
          <Route path="/reset-password" element={
            <PublicOnlyRoute>
              <ResetPassword />
            </PublicOnlyRoute>
          } />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </Layout>
    </ModalContext.Provider>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <UserCourseAccessProvider>
            <ProductProvider>
              <ThemeProvider>
                <NotificationProvider>
                  <ThriveCartProvider>
                    <TextSelectionProvider>
                      <MockExamTimerProvider>
                        <SimulationTimerProvider>
                          <QuizTimerProvider>
                            <AppRoutes />
                          </QuizTimerProvider>
                        </SimulationTimerProvider>
                      </MockExamTimerProvider>
                    </TextSelectionProvider>
                  </ThriveCartProvider>
                </NotificationProvider>
              </ThemeProvider>
            </ProductProvider>
          </UserCourseAccessProvider>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
}

export default App;