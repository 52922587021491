import React, { useState } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../components/ui/Tabs';
import { Card } from '../../components/ui/Card';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Mermaid from '../ui/Mermaid';
import { X, Loader2 } from 'lucide-react';

interface Exhibit {
  id: string;
  type: 'pdf' | 'markdown';
  content?: string;
  url?: string;
}

interface ExhibitsProps {
  exhibits: Exhibit[];
}

const Exhibits: React.FC<ExhibitsProps> = ({ exhibits }) => {
  const [activeTab, setActiveTab] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
    
  if (!exhibits.length) return null;

  const handleTabChange = (value: string) => {
    setIsLoading(true);
    setActiveTab(value);
    // Simulate minimum loading time to prevent flashing
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  const handleClose = () => {
    setActiveTab('');
  };

  // Helper function to add PDF viewer parameters
  const getPdfUrl = (url: string) => {
    // Ensure we're not trying to modify an already modified URL
    if (url.includes('#')) return url;
    
    const params = new URLSearchParams({
      zoom: '100',
      view: 'FitH',
      toolbar: '1',
      navpanes: '0',
      scrollbar: '1',
      statusbar: '0',
      pagemode: 'none',
      embedded: 'true',
      download: '0',
      print: '0',
      tools: 'zoom',
    });
    
    // Add an additional check to prevent double-encoding
    const baseUrl = url.includes('?') ? url : url;
    return `${baseUrl}#${params.toString()}`;
  };

  const LoadingIndicator = () => (
    <div className="flex items-center justify-center min-h-[200px]">
      <div className="flex flex-col items-center space-y-4">
        <Loader2 className="w-8 h-8 animate-spin text-primary-600 dark:text-primary-400" />
        <span className="text-sm text-gray-600 dark:text-gray-400">Loading exhibit...</span>
      </div>
    </div>
  );

  const MarkdownComponents: Components = {
    h1: ({ node, children, ...props }) => (
      <h1 className="text-2xl font-bold mt-0 mb-4 text-gray-900 dark:text-gray-100" {...props}>
        {children}
      </h1>
    ),
    h2: ({ node, children, ...props }) => (
      <h2 className="text-xl font-semibold mt-6 mb-3 text-gray-800 dark:text-gray-200" {...props}>
        {children}
      </h2>
    ),
    h3: ({ node, children, ...props }) => (
      <h3 className="text-lg font-medium mt-4 mb-2 text-gray-800 dark:text-gray-200" {...props}>
        {children}
      </h3>
    ),
    p: ({ node, children, ...props }) => (
      <p className="mt-2 mb-4 text-gray-700 dark:text-gray-300" {...props}>
        {children}
      </p>
    ),
    code: ({ className, children, ...props }) => {
      const match = /language-(\w+)/.exec(className || '');
      const language = match ? match[1] : '';
      
      if (language === 'mermaid') {
        const mermaidContent = String(children)
          .replace(/^\s+|\s+$/g, '')
          .replace(/\\n/g, '\n')
          .replace(/\n\s+/g, '\n')
          .trim();
        
        return (
          <div className="my-8 border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden">
            <div className="bg-gray-50 dark:bg-gray-800 px-4 py-2 border-b border-gray-200 dark:border-gray-700">
              <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
                Flowchart
              </span>
            </div>
            <Mermaid chart={mermaidContent} />
          </div>
        );
      }
      
      return (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
    table: ({ node, children, ...props }) => (
      <div className="overflow-x-auto my-6 max-w-full">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden rounded-lg">
            <table className="min-w-full border border-gray-200 dark:border-gray-700" {...props}>
              {children}
            </table>
          </div>
        </div>
      </div>
    ),
    th: ({ node, children, ...props }) => (
      <th 
        className="px-4 py-3 bg-gray-50 dark:bg-gray-800 text-left text-sm font-medium text-gray-600 dark:text-gray-300 uppercase tracking-wider max-w-xs break-words border border-gray-200 dark:border-gray-700" 
        {...props}
      >
        {children}
      </th>
    ),
    td: ({ node, children, ...props }) => (
      <td 
        className="px-4 py-3 text-sm text-gray-700 dark:text-gray-300 max-w-xs break-words whitespace-normal border border-gray-200 dark:border-gray-700"
        {...props}
      >
        {children}
      </td>
    ),
    ul: ({ node, children, ...props }) => (
      <ul className="list-disc pl-6 my-4 space-y-2" {...props}>
        {children}
      </ul>
    ),
    li: ({ node, children, ...props }) => (
      <li className="text-gray-700 dark:text-gray-300" {...props}>
        {children}
      </li>
    ),
    strong: ({ node, children, ...props }) => (
      <strong className="font-semibold text-gray-900 dark:text-gray-100" {...props}>
        {children}
      </strong>
    )
  };

  return (
    <Card className="w-full p-6 mt-4 mb-8">
      <Tabs value={activeTab} onValueChange={handleTabChange}>
        <TabsList className="relative flex flex-wrap gap-1 p-1 bg-gray-100/80 dark:bg-gray-800/80 rounded-lg backdrop-blur-sm">
          {exhibits.map((exhibit, index) => (
            <TabsTrigger 
              key={exhibit.id} 
              value={exhibit.id}
              className="relative flex items-center justify-between gap-2 px-4 py-2 text-sm font-medium transition-all duration-200
                border border-gray-300 dark:border-gray-600
                data-[state=active]:bg-white data-[state=active]:text-primary-600 
                data-[state=active]:shadow-sm dark:data-[state=active]:bg-gray-700 
                data-[state=active]:border-primary-500 dark:data-[state=active]:border-primary-400
                data-[state=active]:text-primary-400 rounded-md
                hover:bg-gray-50 dark:hover:bg-gray-900/50
                hover:border-gray-400 dark:hover:border-gray-500
                focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              <span>Exhibit {index + 1}</span>
              {activeTab === exhibit.id && (
                <span 
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClose();
                  }}
                  className="cursor-pointer text-gray-900 dark:text-gray-100 ml-2"
                >
                  <X className="w-3 h-3" />
                </span>
              )}
            </TabsTrigger>
          ))}
        </TabsList>

        {activeTab && exhibits.map((exhibit) => (
          <TabsContent 
            key={exhibit.id} 
            value={exhibit.id}
            className="mt-6 overflow-hidden"
          >
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <>
                {exhibit.type === 'pdf' ? (
                  <div className="h-[800px] rounded-lg overflow-hidden shadow-lg">
                    <iframe
                      src={getPdfUrl(exhibit.url || exhibit.content || '')}
                      className="w-full h-full border-0"
                      title={`Exhibit ${exhibit.id}`}
                      allowFullScreen
                    />
                  </div>
                ) : (
                  <div className="prose dark:prose-invert prose-slate max-w-none overflow-x-auto">
                    <ReactMarkdown 
                      remarkPlugins={[remarkGfm]}
                      components={MarkdownComponents}
                    >
                      {exhibit.content || ''}
                    </ReactMarkdown>
                  </div>
                )}
              </>
            )}
          </TabsContent>
        ))}
      </Tabs>
    </Card>
  );
};

export default Exhibits;