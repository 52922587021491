import React, { useCallback, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useStudyTaskDetails } from '../../hooks/useStudyTaskDetails';
import { api } from '../../services/api';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import TaskTypeRenderer from './study-plan-tasks/TaskTypeRenderer';
import DOMPurify from 'dompurify';

interface StudyTaskDetailProps {
  userId: string;
  courseId: string;
}

const StudyTaskDetail: React.FC<StudyTaskDetailProps> = ({ userId, courseId }) => {
  const { itemId = '' } = useParams<{ itemId?: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { taskDetails, isLoading, error } = useStudyTaskDetails(userId, courseId, itemId);
  const [nextTaskId, setNextTaskId] = useState<string | null>(null);
  const [prevTaskId, setPrevTaskId] = useState<string | null>(null);

  // Add effect to scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchAdjacentTasks = useCallback(async () => {
    if (!taskDetails?.plan_id) return;

    try {
      const planId = taskDetails.plan_id;
      const items = await api.getStudyPlanItems(planId);
      const currentIndex = items.findIndex(item => item.item_id === itemId);

      if (currentIndex > 0) {
        setPrevTaskId(items[currentIndex - 1].item_id);
      } else {
        setPrevTaskId(null);
      }

      const nextIncompleteTask = items.slice(currentIndex + 1).find(item => !item.is_completed);
      setNextTaskId(nextIncompleteTask ? nextIncompleteTask.item_id : null);
    } catch (error) {
      console.error('Error fetching adjacent tasks:', error);
      setNextTaskId(null);
      setPrevTaskId(null);
    }
  }, [taskDetails, itemId]);

  useEffect(() => {
    if (taskDetails) {
      fetchAdjacentTasks();
    }
  }, [taskDetails, fetchAdjacentTasks]);

  const handleTaskNavigation = (taskId: string | null) => {
    if (taskId) {
      navigate(`/course/${courseId}/study-task/${taskId}`);
    }
  };

  const handleTaskComplete = async () => {
    await fetchAdjacentTasks();
    queryClient.invalidateQueries(['studyTaskDetails', userId, courseId, itemId]);
  };

  const handleBackToPlanner = () => {
    // Invalidate the study plan query before navigating
    queryClient.invalidateQueries(['studyPlan', userId, courseId]);
    
    // Navigate back with a state flag indicating the need to refresh
    navigate(`/course/${courseId}/study-plan`, {
      state: { refreshStudyPlan: true, timestamp: Date.now() }
    });
  };

  if (isLoading) return <div className="text-center py-8">Loading task details...</div>;
  if (error) return <div className="text-center py-8 text-red-500">Error: {error.message}</div>;
  if (!taskDetails) return <div className="text-center py-8">Task not found</div>;

  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <button
            onClick={handleBackToPlanner}
            className="flex items-center text-primary-blue hover:text-primary-blue-hover dark:text-primary-blue dark:hover:text-primary-blue-hover mr-4 whitespace-nowrap"
          >
            <ArrowLeft size={18} className="mr-2" />
            <span>Back To Planner</span>
          </button>
        </div>
        <div className="flex space-x-4">
          {prevTaskId && (
            <button
              onClick={() => handleTaskNavigation(prevTaskId)}
              className="text-primary-blue hover:text-primary-blue-hover dark:text-primary-blue dark:hover:text-primary-blue-hover"
              title="Previous Task"
            >
              <ArrowLeft size={24} />
            </button>
          )}
          {nextTaskId && (
            <button
              onClick={() => handleTaskNavigation(nextTaskId)}
              className="text-primary-blue hover:text-primary-blue-hover dark:text-primary-blue dark:hover:text-primary-blue-hover"
              title="Next Task"
            >
              <ArrowRight size={24} />
            </button>
          )}
        </div>
      </div>

      <TaskTypeRenderer
        taskDetails={taskDetails}
        userId={userId}
        courseId={courseId}
        itemId={itemId}
        onTaskComplete={handleTaskComplete}
        nextTaskId={nextTaskId}
        prevTaskId={prevTaskId}
        onNavigateNext={() => handleTaskNavigation(nextTaskId)}
        onNavigatePrev={() => handleTaskNavigation(prevTaskId)}
      />

      <div className="mt-8 flex justify-between">
        {prevTaskId && (
          <button
            onClick={() => handleTaskNavigation(prevTaskId)}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-3 px-6 rounded transition duration-300"
          >
            Previous Task
          </button>
        )}
        {nextTaskId && (
          <button
            onClick={() => handleTaskNavigation(nextTaskId)}
            className="bg-primary-orange hover:bg-primary-orange-hover text-white py-3 px-6 rounded transition duration-300"
          >
            Next Task
          </button>
        )}
      </div>
    </div>
  );
};

export default StudyTaskDetail;