import React, { useState, useCallback } from 'react';

interface FormattedNumberInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  maxLength?: number;
}

const FormattedNumberInput: React.FC<FormattedNumberInputProps> = ({
  value,
  onChange,
  placeholder,
  className,
  disabled,
  onFocus,
  maxLength = 14 // Default to 14 characters
}) => {
  const [displayValue, setDisplayValue] = useState(formatNumber(value));

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/[^\d.-]/g, '');
    
    // Check if the raw value exceeds maxLength
    if (rawValue.length <= maxLength) {
      setDisplayValue(formatNumber(rawValue));
      onChange(rawValue); // Pass the raw value to the parent component
    }
  }, [onChange, maxLength]);

  const handleBlur = useCallback(() => {
    setDisplayValue(formatNumber(value));
  }, [value]);

  const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select(); // Auto-select content on focus
    if (onFocus) {
      onFocus(e);
    }
  }, [onFocus]);

  return (
    <input
      type="text"
      value={displayValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      placeholder={placeholder}
      className={className}
      disabled={disabled}
      maxLength={maxLength} // Add HTML maxLength attribute
    />
  );
};

function formatNumber(value: string): string {
  if (!value) return '';
  
  const parts = value.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
  // Only add decimal part if it exists in the original value
  if (parts.length > 1) {
    return parts.join('.');
  } else {
    return parts[0];
  }
}

export default FormattedNumberInput;