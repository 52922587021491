import { useQuery, useQueryClient } from 'react-query';
import { api, QuizHistory, QuizHistoryFilter, QuizQuestionData } from '../services/api';

export const useQuizHistory = (
  userId: string | undefined, 
  courseId: string, 
  filter: QuizHistoryFilter = 'all', 
  isFilteringLocally: boolean = false,
  refetchOnMount: boolean = false,
  isRestrictedAccess: boolean = false
) => {
  const queryClient = useQueryClient();

  const calculateQuizStats = (questions: QuizQuestionData[]) => {
    const answeredQuestions = questions.filter(q => q.answered);
    const totalAnswered = answeredQuestions.length;
    const correctAnswers = answeredQuestions.filter(q => q.isCorrect).length;
    const incorrectAnswers = totalAnswered - correctAnswers;
    const totalTime = answeredQuestions.reduce((sum, q) => sum + (q.timeSpent || 0), 0);
    
    return {
      total_questions: questions.length,
      correct_answers: correctAnswers,
      incorrect_answers: incorrectAnswers,
      percentage_correct: totalAnswered > 0 ? (correctAnswers / totalAnswered) * 100 : 0,
      total_time: totalTime,
      avg_time_per_question: totalAnswered > 0 ? totalTime / totalAnswered : 0
    };
  };

  const { data, isLoading, error } = useQuery(
    ['quizHistory', userId, courseId, filter],
    async () => {
      // Return empty array for restricted access users
      if (isRestrictedAccess || !userId) {
        return [];
      }
      
      const quizHistory = await api.getFilteredQuizHistory(userId, courseId, filter);
      
      // Ensure quizHistory is an array before mapping
      if (!Array.isArray(quizHistory)) {
        return [];
      }
      
      return quizHistory.map((quiz: QuizHistory) => {
        const questions = quiz.questions_data || [];
        const stats = calculateQuizStats(questions);

        return {
          ...quiz,
          questions_data: questions,
          ...stats,
          completed: quiz.completed || false,
          quiz_name: quiz.quiz_name || 'Untitled Quiz'
        };
      });
    },
    {
      enabled: !isRestrictedAccess && !!userId && !isFilteringLocally,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount,
      retry: 3,
      onError: (error) => {
        console.error('Error fetching quiz history:', error);
      }
    }
  );

  const refetchQuizHistory = () => {
    if (isRestrictedAccess) {
      return Promise.resolve();
    }
    return queryClient.invalidateQueries(['quizHistory', userId, courseId]);
  };

  return { 
    data: data ?? [], 
    isLoading: isLoading && !isRestrictedAccess, 
    error, 
    refetchQuizHistory 
  };
};