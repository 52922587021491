import { QueryClient, QueryCache, Query, QueryObserver, QueryKey, DefaultOptions } from 'react-query';

// Define the QueryCacheNotifyEvent type to handle different cache events
type QueryCacheNotifyEvent =
  | { type: 'queryAdded'; query: Query }
  | { type: 'queryRemoved'; query: Query }
  | { type: 'queryUpdated'; query: Query; action: unknown }
  | { type: 'observerAdded'; query: Query; observer: QueryObserver }
  | { type: 'observerRemoved'; query: Query; observer: QueryObserver }
  | { type: 'observerResultsUpdated'; query: QueryObserver };

// Active session routes that require special handling
const ACTIVE_SESSION_ROUTES = ['/quiz', '/mock-exam', '/simulations'];

// Helper to check if we're in an active session
export const isInActiveSession = (): boolean => {
  const currentPath = window.location.pathname;
  return ACTIVE_SESSION_ROUTES.some(route => currentPath.includes(route));
};

// Create a new QueryCache instance with proper error handling
const queryCache = new QueryCache({
  onError: (error: unknown) => {
    console.error('React Query cache error:', error);
  },
});

// Define type for query options
type QueryConfigOptions = DefaultOptions['queries'] & {
  staleTime: number;
  cacheTime: number;
};

// Get dynamic query config based on session state
const getQueryConfig = (): QueryConfigOptions => ({
  staleTime: isInActiveSession() ? Infinity : 5 * 60 * 1000,
  cacheTime: isInActiveSession() ? Infinity : 10 * 60 * 1000,
  refetchOnWindowFocus: false,
  refetchOnMount: isInActiveSession() ? false : true,
  refetchOnReconnect: false,
  retry: false,
  refetchInterval: false,
});

// Create the client with correct configuration
export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: getQueryConfig(),
    mutations: {
      retry: false,
    },
  },
});

// Add method to refresh query config when session state changes
export const updateQueryConfig = () => {
  queryClient.setDefaultOptions({
    queries: getQueryConfig(),
    mutations: {
      retry: false,
    },
  });
};

// Add methods to handle session state
export const markActiveSession = () => {
  updateQueryConfig();
  // Pause all active queries during sessions
  queryClient.getQueryCache().getAll().forEach(query => {
    queryClient.cancelQueries(query.queryKey);
  });
};

export const clearActiveSession = () => {
  updateQueryConfig();
  // Get all queries that need refreshing
  const queries = queryClient.getQueryCache().getAll();
  
  // Create a batch of promises for queries that need refreshing
  const refreshPromises = queries.map(query => {
    const queryData = query.state.data;
    const lastUpdated = query.state.dataUpdatedAt;
    const now = Date.now();
    
    // Refresh if data is older than staleTime
    if (queryData && (now - lastUpdated) > getQueryConfig().staleTime) {
      return queryClient.invalidateQueries(query.queryKey, { refetchActive: true });
    }
    return Promise.resolve();
  });

  // Execute all refreshes
  return Promise.all(refreshPromises);
};

// Helper method to clear specific query data without triggering refreshes
export const clearQueryData = (queryKey: string | readonly unknown[]) => {
  queryClient.removeQueries(queryKey, { exact: true });
};

// Add method to handle errors during active sessions
export const handleQueryError = (error: unknown, queryKey: unknown) => {
  console.error(`Query error for key ${JSON.stringify(queryKey)}:`, error);
  if (isInActiveSession()) {
    // Don't invalidate cache during active sessions
    return;
  }
  // Only remove the specific failed query
  clearQueryData(queryKey as string | readonly unknown[]);
};

// Add method to safely reset query cache
export const resetQueryCache = () => {
  if (!isInActiveSession()) {
    queryClient.clear();
  }
};