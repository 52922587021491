import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../../services/auth';
import { api } from '../../services/api';
import { getBrowserInstanceId } from '../../utils/browserInstance';
import { Button } from '../ui/Button';
import { Alert, AlertDescription } from '../ui/Alert';
import { Mail, Lock, AlertCircle, Loader, CheckCircle } from 'lucide-react';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check for logout message
    const authMessage = sessionStorage.getItem('authMessage');
    if (authMessage) {
      setMessage(authMessage);
      sessionStorage.removeItem('authMessage');
    }
  }, []);

  const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    setIsLoading(true);

    try {
      const { data } = await auth.login(email, password);
      api.clearAllCache();
      
      const browserInstanceId = await getBrowserInstanceId();
      await api.updateUserInstance(browserInstanceId);
      
      navigate('/');
    } catch (err) {
      setError((err as Error).message || 'Failed to login. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [email, password, navigate]);

  return (
    <div className="min-h-screen flex items-top justify-center py-6 px-4 sm:px-6 lg:px-8 bg-gray-50 dark:bg-gray-900">
      <div className="w-full max-w-md space-y-8">
        {/* Header Section */}
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
            Welcome Back
          </h2>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            Sign in to continue your CPA exam preparation
          </p>
        </div>

        {/* Alert Messages */}
        {(message || error) && (
          <Alert className={`${message ? 'bg-green-50 dark:bg-green-900/30 border-green-200 dark:border-green-800' : 
                                     'bg-red-50 dark:bg-red-900/30 border-red-200 dark:border-red-800'}`}>
            <div className="flex items-center gap-3">
              {message ? (
                <CheckCircle className="h-4 w-4 text-green-600 dark:text-green-400 flex-shrink-0" />
              ) : (
                <AlertCircle className="h-4 w-4 text-red-600 dark:text-red-400 flex-shrink-0" />
              )}
              <AlertDescription className={`${message ? 'text-green-700 dark:text-green-300' : 
                                                     'text-red-700 dark:text-red-300'}`}>
                {message || error}
              </AlertDescription>
            </div>
          </Alert>
        )}

        {/* Form Section */}
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="space-y-4">
            {/* Email Field */}
            <div className="space-y-2">
              <label 
                htmlFor="email" 
                className="block text-sm font-medium text-gray-700 dark:text-gray-300"
              >
                Email address
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 
                            rounded-lg bg-white dark:bg-gray-800 
                            text-gray-900 dark:text-white
                            placeholder-gray-500 dark:placeholder-gray-400
                            focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 
                            focus:border-transparent
                            transition duration-150 ease-in-out"
                  placeholder="Enter your email"
                />
              </div>
            </div>

            {/* Password Field */}
            <div className="space-y-2">
              <label 
                htmlFor="password" 
                className="block text-sm font-medium text-gray-700 dark:text-gray-300"
              >
                Password
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 
                            rounded-lg bg-white dark:bg-gray-800 
                            text-gray-900 dark:text-white
                            placeholder-gray-500 dark:placeholder-gray-400
                            focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 
                            focus:border-transparent
                            transition duration-150 ease-in-out"
                  placeholder="Enter your password"
                />
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <Button
            type="submit"
            disabled={isLoading}
            className="w-full flex justify-center py-3 px-4 border border-transparent 
                     rounded-lg shadow-sm text-sm font-medium text-white
                     bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600
                     focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                     disabled:opacity-50 disabled:cursor-not-allowed
                     transition duration-150 ease-in-out"
          >
            {isLoading ? (
              <div className="flex items-center">
                <Loader className="w-5 h-5 mr-2 animate-spin" />
                Signing in...
              </div>
            ) : (
              'Sign in'
            )}
          </Button>

          {/* Links Section */}
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 text-sm">
            <Link 
              to="/forgot-password"
              className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300"
            >
              Forgot your password?
            </Link>
            <Link 
              to="/register"
              className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300"
            >
              Don't have an account? Sign up
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;