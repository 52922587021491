import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../../../components/ui/Modal';
import { Button } from '../../../components/ui/Button';
import { api, SimulationQuestionData } from '../../../services/api';

interface EndTBSTestletProps {
  isOpen: boolean;
  onClose: () => void;
  onEndTestlet: () => Promise<void>;
  isLastQuestion: boolean;
  quizHistoryId: string;
  isViewingExplanation: boolean;
  courseId: string;
}

const EndTBSTestlet: React.FC<EndTBSTestletProps> = ({
  isOpen,
  onClose,
  onEndTestlet,
  isLastQuestion,
  quizHistoryId,
  isViewingExplanation,
  courseId
}) => {
  const navigate = useNavigate();
  const [isEnding, setIsEnding] = useState(false);
  const [actualQuestionCounts, setActualQuestionCounts] = useState({
    answered: 0,
    total: 0
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchQuizData = async () => {
      if (!quizHistoryId || !isOpen) return;

      try {
        setIsLoading(true);
        const quizHistory = await api.getSimulationQuizHistoryById(quizHistoryId);
        if (!quizHistory) return;

        const questionsData = quizHistory.questions_data as SimulationQuestionData[];

        const answeredCount = questionsData.filter(q =>
          q.answered &&
          q.score !== undefined &&
          q.score !== null &&
          q.feedback &&
          Array.isArray(q.feedback) &&
          q.feedback.length > 0
        ).length;

        setActualQuestionCounts({
          answered: answeredCount,
          total: questionsData.length
        });
      } catch (error) {
        console.error('Error fetching quiz data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuizData();
  }, [quizHistoryId, isOpen]);

  const handleEndTestlet = async () => {
    setIsEnding(true);
    try {
      // First, end the TBS session to clean up questions_data and mark as completed
      await api.endTBSSession(quizHistoryId, 0); // Pass 0 as total time since it's handled in questions_data
      
      if (actualQuestionCounts.answered === 0) {
        // If no questions were answered, navigate to simulations home
        navigate(`/course/${courseId}/simulations`);
      } else {
        // Otherwise, proceed with normal end testlet flow
        await onEndTestlet();
      }
    } catch (error) {
      console.error('Error ending testlet:', error);
    } finally {
      setIsEnding(false);
      onClose();
    }
  };

  if (isLoading) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full mx-auto p-6">
            <div className="flex items-center justify-center">
              <svg className="animate-spin h-5 w-5 text-gray-700 dark:text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span className="ml-2 text-gray-700 dark:text-gray-300">Loading quiz data...</span>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full mx-auto">
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
              {isLastQuestion ? 'End of Testlet' : 'Warning'}
            </h2>
            <p className="mb-4 text-gray-700 dark:text-gray-300">
              You have completed {actualQuestionCounts.answered} out of {actualQuestionCounts.total} simulations.
            </p>
            <p className="mb-6 text-gray-700 dark:text-gray-300">
              {actualQuestionCounts.answered === 0
                ? 'You haven\'t completed any simulations yet. Are you sure you want to end the testlet?'
                : isLastQuestion
                  ? 'Would you like to finish the quiz or continue reviewing your answers?'
                  : `You have not completed all simulations in this testlet. The quiz will only grade your completed simulations.`}
            </p>
            <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
              <Button
                onClick={onClose}
                variant="outline"
                className="w-full sm:w-auto dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
              >
                {isLastQuestion ? 'Continue Review' : 'Cancel'}
              </Button>
              <Button
                onClick={handleEndTestlet}
                variant={isLastQuestion ? "primary" : "destructive"}
                className="w-full sm:w-auto relative"
                disabled={isEnding}
              >
                {isEnding && (
                  <span className="absolute inset-0 flex items-center justify-center">
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </span>
                )}
                <span className={isEnding ? 'invisible' : ''}>
                  {actualQuestionCounts.answered === 0
                    ? 'End Without Grading'
                    : isLastQuestion
                      ? 'Finish Quiz'
                      : 'End Testlet'}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EndTBSTestlet;