import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { api, Simulation, SimulationQuestionData, SimulationCounts, QuizHistory, ExtendedSimulationStats } from '../../services/api';
import { Sparkle, Scale, Shuffle, FileQuestion, CheckCircle, X, ChevronDown, Filter, ArrowRightLeft } from 'lucide-react';
import PopupModal from './common/PopupModal';
import Tooltip from '../ui/Tooltip';
import { Button } from '../../components/ui/Button';
import { ActiveQuizState, getTestletType } from '../../hooks/useActiveQuiz';

type ConfidenceLevel = 'guessing' | 'maybe' | 'confident';
type StatusFilter = 'unanswered' | 'correct' | 'incorrect';
type KeslerFilter = StatusFilter | ConfidenceLevel;
type TestletType = 'mock_exam' | 'study_task' | 'simulation' | 'MCQ' | 'loop' | 'learn' | 'funnel' | 'retake_funnel' | null;

// Update the props interface to use ExtendedSimulationStats
interface SimulationsCustomizerProps {
  courseId: string;
  userId: string;
  show: boolean;
  availableSimulationCounts: ExtendedSimulationStats;
  customSimulationCount: number;
  setCustomSimulationCount: (count: number) => void;
  keslerQFilter: string[];
  setKeslerQFilter: (filter: string[]) => void;
  onStartSimulation: (simulations: Simulation[], quizHistoryId: string) => void;
  onResumeSimulation: (simulationToResume: QuizHistory) => void;
  selectedCategories: string[];
  setTotalSimulations: (count: number) => void;
  setCurrentSimulationIndex: (index: number) => void;
  setSelectedSimulation: (simulation: Simulation | null) => void;
  setCurrentQuizHistoryId: (id: string | undefined) => void;
  setQuizStarted: (started: boolean) => void;
  error?: string | null;
  setError?: (error: string | null) => void;
  showUpgradeModal: boolean;
  setShowUpgradeModal: (show: boolean) => void;
  // Add new active quiz state props
  activeQuizState: ActiveQuizState;
  setActiveQuizState: (state: ActiveQuizState) => void;
  resetActiveQuizCheck: () => void;
}

const getTotalAvailable = (
  stats: ExtendedSimulationStats | SimulationCounts, 
  filters: string[]
): number => {
  if (!stats) return 0;

  if (filters.includes('unanswered')) {
    return stats.unanswered || 0;
  }

  const statusFilter = filters.find(f => 
    ['correct', 'incorrect'].includes(f)
  ) as 'correct' | 'incorrect' | undefined;
  
  const confidenceFilter = filters.find(f => 
    ['confident', 'maybe', 'guessing'].includes(f)
  ) as ConfidenceLevel | undefined;

  if (statusFilter && !confidenceFilter) {
    return stats[statusFilter] || 0;
  }

  if (confidenceFilter && !statusFilter) {
    return stats[confidenceFilter] || 0;
  }

  if (statusFilter && confidenceFilter) {
    const key = `${confidenceFilter}_${statusFilter}` as keyof ExtendedSimulationStats;
    return stats[key] || 0;
  }

  return stats.total || 0;
};

const SimulationsCustomizer: React.FC<SimulationsCustomizerProps> = ({
  courseId,
  userId,
  show,
  availableSimulationCounts,
  customSimulationCount,
  setCustomSimulationCount,
  keslerQFilter,
  setKeslerQFilter,
  onStartSimulation,
  onResumeSimulation,
  selectedCategories,
  setSelectedSimulation,
  setCurrentSimulationIndex,
  setTotalSimulations,
  setCurrentQuizHistoryId,
  setQuizStarted,
  error,
  setError,
  // Destructure new active quiz props
  activeQuizState,
  setActiveQuizState,
  resetActiveQuizCheck
}) => {
  const [selectedCountButton, setSelectedCountButton] = useState<number | null>(null);
  const [isValidInput, setIsValidInput] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);

  const availableButtons = [2, 5, 10];
  const MAX_ALLOWED_SIMULATIONS = 20;

  const totalAvailableSimulations = useMemo(() => {
    return getTotalAvailable(availableSimulationCounts, keslerQFilter);
  }, [keslerQFilter, availableSimulationCounts]);

  // Effect to initialize counts
  useEffect(() => {
    if (!hasUserInteracted) {
      const maxCount = Math.min(totalAvailableSimulations, MAX_ALLOWED_SIMULATIONS);
      setCustomSimulationCount(maxCount);
      setSelectedCountButton(availableButtons.includes(maxCount) ? maxCount : null);
      setInputValue(maxCount.toString());
    }
  }, [totalAvailableSimulations, setCustomSimulationCount, availableButtons, hasUserInteracted]);

// Helper function to get the button text based on quiz type
const getSessionButtonText = (testletType: TestletType | null) => {
  switch (testletType) {
    case 'simulation':
      return 'Resume/End Simulation';
    case 'mock_exam':
      return 'Resume/End Quiz';
    case 'study_task':
      return 'Resume/End Study Task';
    default:
      return 'Resume/End Quiz';
  }
};

  const handleKeslerQFilterChange = useCallback((clickedLabel: string) => {
    const clicked = clickedLabel.toLowerCase() as KeslerFilter;
    let newFilters: string[] = [...keslerQFilter];

    if (clicked === 'unanswered') {
      newFilters = ['unanswered'];
    } else {
      newFilters = newFilters.filter(f => f !== 'unanswered');
      
      if (['correct', 'incorrect'].includes(clicked)) {
        newFilters = newFilters.filter(f => !['correct', 'incorrect'].includes(f));
        if (!keslerQFilter.includes(clicked)) {
          newFilters.push(clicked);
        }
      }
      
      if (['confident', 'maybe', 'guessing'].includes(clicked)) {
        newFilters = newFilters.filter(f => !['confident', 'maybe', 'guessing'].includes(f));
        if (!keslerQFilter.includes(clicked)) {
          newFilters.push(clicked);
        }
      }
      
      if (newFilters.length === 0) {
        newFilters = ['unanswered'];
      }
    }
    
    setHasUserInteracted(false);
    setKeslerQFilter(newFilters);
  }, [keslerQFilter, setKeslerQFilter]);

  const handleSimulationCountChange = useCallback((count: number) => {
    setHasUserInteracted(true);
    setSelectedCountButton(count);
    const limitedCount = Math.min(Math.max(0, count), Math.min(MAX_ALLOWED_SIMULATIONS, totalAvailableSimulations));
    setCustomSimulationCount(limitedCount);
    setInputValue(limitedCount.toString());
    setIsValidInput(true);
  }, [totalAvailableSimulations, setCustomSimulationCount]);

  const handleInputChange = useCallback((value: string) => {
    setHasUserInteracted(true);
    
    // Remove non-numeric characters and limit to 2 digits
    const numericValue = value.replace(/[^0-9]/g, '').slice(0, 2);
    
    // Always update the input value first to prevent long numbers
    setInputValue(numericValue);
    setSelectedCountButton(null);
    
    if (numericValue === '') {
      setIsValidInput(false);
      setCustomSimulationCount(0);
      return;
    }

    const parsed = parseInt(numericValue, 10);
    if (isNaN(parsed)) {
      setIsValidInput(false);
      setCustomSimulationCount(0);
      return;
    }

    // Ensure the parsed value doesn't exceed the maximum allowed simulations or available simulations
    const maxAllowed = Math.min(MAX_ALLOWED_SIMULATIONS, totalAvailableSimulations);
    const limitedCount = Math.min(parsed, maxAllowed);
    setCustomSimulationCount(limitedCount);
    setIsValidInput(true);
  }, [totalAvailableSimulations, setCustomSimulationCount]);

  const startNewSimulation = async () => {
    if (!userId || !courseId || selectedCategories.length === 0) return;
    setIsLoading(true);
    try {
      const limitedSimulationCount = Math.min(customSimulationCount, 20);
      const simulations = await api.getSimulationsForCustomPractice(
        courseId,
        selectedCategories,
        keslerQFilter,
        limitedSimulationCount,
        userId
      );

      if (!simulations || simulations.length === 0) {
        setError?.('No simulations available for the selected criteria');
        return;
      }

      const finalSimulations = simulations.slice(0, 20);
      const initialQuestionsData: SimulationQuestionData[] = finalSimulations.map(simulation => ({
        type: 'simulation',
        questionId: simulation.id,
        simulationId: simulation.id,
        answered: false,
        isCorrect: false,
        timeSpent: 0,
        confidenceLevel: null,
        userAnswer: '',
        score: 0,
        feedback: [],
        free_trial_content: simulation.free_trial_content,
        question_results: []
      }));

      const quizHistoryId = await api.createQuizHistory(userId, courseId, initialQuestionsData);
      onStartSimulation(finalSimulations, quizHistoryId);
    } catch (error) {
      console.error('Failed to start custom simulation:', error);
      setError?.('Failed to start simulation. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartSimulation = () => {
    if (activeQuizState.activeQuiz) {
      setActiveQuizState({
        ...activeQuizState,
        showActiveQuizModal: true
      });
    } else {
      startNewSimulation();
    }
  };

  const handleEndQuiz = useCallback(async () => {
    if (!activeQuizState.activeQuiz) return;
    try {
      setError?.(null);
      await api.endTBSSession(activeQuizState.activeQuiz.id, 0);
      setActiveQuizState({
        activeQuiz: null,
        activeTestletType: null,
        showActiveQuizModal: false
      });
      resetActiveQuizCheck();
    } catch (err) {
      console.error('Failed to end simulation:', err);
      setError?.('Failed to end simulation. Please try again.');
    }
  }, [activeQuizState.activeQuiz, setError, setActiveQuizState, resetActiveQuizCheck]);


  // Helper function to get filter tooltip
  const getFilterTooltip = (filter: string): string => {
    switch (filter) {
      case 'unanswered': return 'Questions you haven\'t attempted';
      case 'correct': return 'Previously answered correctly';
      case 'incorrect': return 'Previously answered incorrectly';
      case 'confident': return 'Marked as Confident';
      case 'maybe': return 'Marked as Maybe';
      case 'guessing': return 'Marked as Guessing';
      default: return '';
    }
  };

  const getFilterColor = (filter: string, isSelected: boolean) => {
    const baseColors = {
      unanswered: {
        normal: 'bg-blue-50 text-blue-700 border-blue-200 hover:bg-blue-100 dark:bg-blue-900/20 dark:text-blue-300 dark:border-blue-800',
        selected: 'bg-blue-500 text-white border-blue-600 hover:bg-blue-600 dark:bg-blue-600 dark:text-white dark:border-blue-500'
      },
      incorrect: {
        normal: 'bg-red-50 text-red-700 border-red-200 hover:bg-red-100 dark:bg-red-900/20 dark:text-red-300 dark:border-red-800',
        selected: 'bg-red-500 text-white border-red-600 hover:bg-red-600 dark:bg-red-600 dark:text-white dark:border-red-500'
      },
      confident: {
        normal: 'bg-emerald-50 text-emerald-700 border-emerald-200 hover:bg-emerald-100 dark:bg-emerald-900/20 dark:text-emerald-300 dark:border-emerald-800',
        selected: 'bg-emerald-500 text-white border-emerald-600 hover:bg-emerald-600 dark:bg-emerald-600 dark:text-white dark:border-emerald-500'
      },
      maybe: {
        normal: 'bg-yellow-50 text-yellow-700 border-yellow-200 hover:bg-yellow-100 dark:bg-yellow-900/20 dark:text-yellow-300 dark:border-yellow-800',
        selected: 'bg-yellow-500 text-white border-yellow-600 hover:bg-yellow-600 dark:bg-yellow-600 dark:text-white dark:border-yellow-500'
      },
      guessing: {
        normal: 'bg-orange-50 text-orange-700 border-orange-200 hover:bg-orange-100 dark:bg-orange-900/20 dark:text-orange-300 dark:border-orange-800',
        selected: 'bg-orange-500 text-white border-orange-600 hover:bg-orange-600 dark:bg-orange-600 dark:text-white dark:border-orange-500'
      }
    };

    const filterKey = filter.toLowerCase();
    if (filterKey in baseColors) {
      return isSelected 
        ? baseColors[filterKey as keyof typeof baseColors].selected 
        : baseColors[filterKey as keyof typeof baseColors].normal;
    }
    // Default fallback colors if filter doesn't match
    return isSelected 
      ? 'bg-gray-500 text-white' 
      : 'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-300';
  };

  const renderFilterButton = (filter: string, count: number) => {
    const isSelected = keslerQFilter.includes(filter);
    const getFilterIcon = () => {
      switch (filter) {
        case 'unanswered': return <FileQuestion className="w-4 h-4" />;
        case 'correct': return <CheckCircle className="w-4 h-4" />;
        case 'incorrect': return <X className="w-4 h-4" />;
        case 'confident': return <Sparkle className="w-4 h-4" />;
        case 'maybe': return <Scale className="w-4 h-4" />;
        case 'guessing': return <Shuffle className="w-4 h-4" />;
        default: return null;
      }
    };

    return (
      <div className="relative" key={filter}>
        <Tooltip 
          content={getFilterTooltip(filter)}
          side="top"
          delayMS={200}
          className="z-[9999]"
        >
          <button
            onClick={() => handleKeslerQFilterChange(filter)}
            className={`flex items-center shrink-0 gap-1 px-2.5 py-1.5 rounded-md text-sm transition-all whitespace-nowrap border ${
              getFilterColor(filter, isSelected)
            }`}
          >
            <div className="w-4 h-4 shrink-0 flex items-center justify-center">
              {getFilterIcon()}
            </div>
            <span className="text-xs">{count}</span>
          </button>
        </Tooltip>
      </div>
    );
  };

  // Render active session button
  const renderActiveSessionButton = useCallback(() => {
    return (
      <Button
        onClick={() => setActiveQuizState({
          ...activeQuizState,
          showActiveQuizModal: true
        })}
        className="flex items-center gap-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md text-sm font-medium transition-colors"
      >
        <ArrowRightLeft className="w-4 h-4" />
        <span>{getSessionButtonText(activeQuizState.activeTestletType)}</span>
      </Button>
    );
  }, [activeQuizState, setActiveQuizState]);

  // Update renderActionButton to handle active sessions
  const renderActionButton = () => {
    if (isLoading) {
      return (
        <Button
          disabled
          className="min-w-[120px] px-4 py-2 text-sm font-medium bg-gray-100 text-gray-400 cursor-not-allowed"
        >
          Loading...
        </Button>
      );
    }

    // If there's an active quiz, show the resume button
    if (activeQuizState.activeQuiz) {
      return renderActiveSessionButton();
    }

    // For starting a new quiz
    return (
      <Button
        onClick={handleStartSimulation}
        disabled={!isValidInput || parseInt(inputValue) === 0 || selectedCategories.length === 0}
        className={`min-w-[120px] px-4 py-2 text-sm font-medium ${
          !isValidInput || parseInt(inputValue) === 0 || selectedCategories.length === 0
            ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
            : 'bg-blue-600 text-white hover:bg-blue-700'
        }`}
      >
        Start Simulation ({inputValue})
      </Button>
    );
  };

  // Add handler for input selection
  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsInputFocused(true);
    setHasUserInteracted(true);
    event.target.select();
  };

  if (!show) return null;

  return (
    <div className="quiz-customizer-wrapper fixed bottom-0 left-0 right-0 z-40">
      {/* Tab Header */}
      <div className="absolute top-0 left-[max(1rem,calc(50%-35rem))] -translate-y-full">
        <div className="flex items-center gap-1.5 px-4 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-t-lg cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-750">
          <span className="text-sm font-medium text-gray-700 dark:text-gray-200">TBS Customizer</span>
          <ChevronDown className="w-4 h-4 text-gray-500 dark:text-gray-400 shrink-0" />
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow-lg transition-all duration-300 ease-in-out border-t border-gray-200 dark:border-gray-700">
        <div className="max-w-7xl mx-auto px-4 lg:px-8 py-3 pb-5 md:py-4 md:pb-6">
          <div className="flex flex-col md:flex-row md:items-center md:justify-center space-y-4 md:space-y-0 md:gap-6">
            {/* Filter Section */}
            <div className="w-full md:w-auto">
              <div className="flex items-center gap-3 md:border md:border-gray-200 dark:md:border-gray-700 md:rounded-lg md:p-2">
                <div className="hidden md:flex items-center gap-2 px-2 text-gray-600 dark:text-gray-300">
                  <Filter className="w-4 h-4" />
                  <span className="text-sm font-medium">Filters</span>
                </div>
                <div className="flex gap-2 overflow-x-auto [scrollbar-width:none] [-ms-overflow-style:none] [&::-webkit-scrollbar]:hidden pb-2 -mb-2 md:pb-0 md:mb-0">
                  {([
                    { filter: 'unanswered', count: availableSimulationCounts.unanswered },
                    { filter: 'incorrect', count: availableSimulationCounts.incorrect },
                    { filter: 'correct', count: availableSimulationCounts.correct },
                    { filter: 'confident', count: availableSimulationCounts.confident },
                    { filter: 'maybe', count: availableSimulationCounts.maybe },
                    { filter: 'guessing', count: availableSimulationCounts.guessing }
                  ] as const).map(({ filter, count }) => renderFilterButton(filter, count))}
                </div>
              </div>
            </div>

            {/* Question Count and Start Button */}
            <div className="flex items-center justify-between md:justify-end gap-4">
              <div className="flex items-center border border-gray-200 dark:border-gray-700 rounded-lg p-2 gap-2">
                {availableButtons.map((count) => (
                  <button
                    key={count}
                    onClick={() => handleSimulationCountChange(count)}
                    disabled={count > totalAvailableSimulations}
                    className={`px-3 py-1.5 text-sm rounded-md transition-colors ${
                      count > totalAvailableSimulations
                        ? 'bg-gray-100 dark:bg-gray-800 text-gray-400 dark:text-gray-600 cursor-not-allowed'
                        : selectedCountButton === count
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                    }`}
                  >
                    {count}
                  </button>
                ))}
                <input
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={inputValue}
                  onChange={(e) => handleInputChange(e.target.value)}
                  onFocus={handleInputFocus}
                  onBlur={() => {
                    setIsInputFocused(false);
                    const parsed = parseInt(inputValue, 10);
                    if (isNaN(parsed) || parsed < 0) {
                      handleSimulationCountChange(0);
                    } else {
                      handleSimulationCountChange(parsed);
                    }
                  }}
                  className={`w-16 px-3 py-1.5 text-sm text-center border rounded-md text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-700 ${
                    isValidInput 
                      ? 'border-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-400' 
                      : 'border-red-500'
                  }`}
                />
              </div>

              {renderActionButton()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SimulationsCustomizer);