import React from 'react';
import { Alert } from './Alert';
import { Quote, Lightbulb } from 'lucide-react';

interface MotivationalQuote {
  quote: string;
  author: string;
}

export const MOTIVATIONAL_QUOTES: MotivationalQuote[] = [
    {
      quote: "Success is not final, failure is not fatal: it is the courage to continue that counts.",
      author: "Winston Churchill"
    },
    {
      quote: "The CPA exam is a marathon, not a sprint. Take it one step at a time.",
      author: "Kesler CPA Review"
    },
    {
      quote: "The difference between try and triumph is just a little umph!",
      author: "Marvin Phillips"
    },
    {
      quote: "The expert in anything was once a beginner.",
      author: "Helen Hayes"
    },
    {
      quote: "Your future self is watching you right now through memories.",
      author: "Unknown"
    },
    {
      quote: "Don't wish it were easier, wish you were better.",
      author: "Jim Rohn"
    },
    {
      quote: "Small progress is still progress.",
      author: "Unknown"
    },
    {
      quote: "What seems impossible today will one day become your warm-up.",
      author: "Unknown"
    },
    {
      quote: "I can do all things through Christ who strengthens me.",
      author: "Philippians 4:13"
    },
    {
      quote: "Trust in the Lord with all your heart, and lean not on your own understanding.",
      author: "Proverbs 3:5"
    },
    {
      quote: "Commit to the Lord whatever you do, and he will establish your plans.",
      author: "Proverbs 16:3"
    },
    {
      quote: "Have I not commanded you? Be strong and courageous. Do not be afraid; do not be discouraged, for the Lord your God will be with you wherever you go.",
      author: "Joshua 1:9"
    },
    {
      quote: "For I know the plans I have for you, declares the Lord, plans to prosper you and not to harm you, plans to give you hope and a future.",
      author: "Jeremiah 29:11"
    },
    {
      quote: "The only way to achieve the impossible is to believe it is possible.",
      author: "Charles Kingsleigh"
    },
    {
      quote: "It does not matter how slowly you go as long as you do not stop.",
      author: "Confucius"
    },
    {
      quote: "Our greatest glory is not in never falling, but in rising every time we fall.",
      author: "Confucius"
    },
    {
      quote: "The best way out is always through.",
      author: "Robert Frost"
    },
    {
      quote: "What lies behind us and what lies before us are tiny matters compared to what lies within us.",
      author: "Ralph Waldo Emerson"
    },
    {
      quote: "The only limit to our realization of tomorrow is our doubts of today.",
      author: "Franklin D. Roosevelt"
    },
    {
      quote: "You may encounter many defeats, but you must not be defeated.",
      author: "Maya Angelou"
    },
    {
      quote: "Strength does not come from physical capacity. It comes from an indomitable will.",
      author: "Mahatma Gandhi"
    },
    {
      quote: "The only thing we have to fear is fear itself.",
      author: "Franklin D. Roosevelt"
    },
    {
      quote: "Believe you can and you're halfway there.",
      author: "Theodore Roosevelt"
    },
    {
      quote: "Do not wait to strike till the iron is hot; but make it hot by striking.",
      author: "William Butler Yeats"
    },
    {
      quote: "The harder the conflict, the greater the triumph.",
      author: "George Washington"
    },
    {
      quote: "Perseverance is not a long race; it is many short races one after the other.",
      author: "Walter Elliot"
    },
    {
      quote: "Success is the sum of small efforts, repeated day in and day out.",
      author: "Robert Collier"
    },
    {
      quote: "Don't watch the clock; do what it does. Keep going.",
      author: "Sam Levenson"
    },
    {
      quote: "Keep your eyes on the stars, and your feet on the ground.",
      author: "Theodore Roosevelt"
    },
    {
      quote: "The journey of a thousand miles begins with one step.",
      author: "Lao Tzu"
    },
    {
      quote: "What you get by achieving your goals is not as important as what you become by achieving your goals.",
      author: "Zig Ziglar"
    },
    {
      quote: "You are never too old to set another goal or to dream a new dream.",
      author: "C.S. Lewis"
    },
    {
      quote: "The only person you are destined to become is the person you decide to be.",
      author: "Ralph Waldo Emerson"
    },
    {
      quote: "Go confidently in the direction of your dreams. Live the life you have imagined.",
      author: "Henry David Thoreau"
    },
    {
      quote: "The secret of getting ahead is getting started.",
      author: "Mark Twain"
    },
    {
      quote: "The best time to plant a tree was 20 years ago. The second best time is now.",
      author: "Chinese Proverb"
    },
    {
      quote: "It's not whether you get knocked down, it's whether you get up.",
      author: "Vince Lombardi"
    },
    {
      quote: "You miss 100% of the shots you don't take.",
      author: "Wayne Gretzky"
    },
    {
      quote: "The only impossible journey is the one you never begin.",
      author: "Tony Robbins"
    },
    {
      quote: "Hardships often prepare ordinary people for an extraordinary destiny.",
      author: "C.S. Lewis"
    },
    {
      quote: "The impediment to action advances action. What stands in the way becomes the way.",
      author: "Marcus Aurelius"
    },
    {
      quote: "You have power over your mind—not outside events. Realize this, and you will find strength.",
      author: "Marcus Aurelius"
    },
    {
      quote: "Difficulties strengthen the mind, as labor does the body.",
      author: "Seneca"
    },
    {
      quote: "He who fears he will suffer, already suffers because he fears.",
      author: "Michel de Montaigne"
    },
    {
      quote: "We suffer more often in imagination than in reality.",
      author: "Seneca"
    },
    {
      quote: "It does not matter what you bear, but how you bear it.",
      author: "Seneca"
    },
    {
      quote: "The greater the difficulty, the more glory in surmounting it.",
      author: "Epicurus"
    },
    {
      quote: "Do not pray for an easy life, pray for the strength to endure a difficult one.",
      author: "Bruce Lee"
    },
    {
      quote: "What we fear doing most is usually what we most need to do.",
      author: "Tim Ferriss"
    },
    {
      quote: "The obstacle is the path.",
      author: "Zen Proverb"
    },
    {
      quote: "Fall seven times, stand up eight.",
      author: "Japanese Proverb"
    },
    {
      quote: "Consider it pure joy, my brothers and sisters, whenever you face trials of many kinds.",
      author: "James 1:2"
    },
    {
      quote: "The Lord is my strength and my shield; my heart trusts in him, and he helps me.",
      author: "Psalm 28:7"
    },
    {
      quote: "But those who hope in the Lord will renew their strength. They will soar on wings like eagles.",
      author: "Isaiah 40:31"
    },
    {
      quote: "Be strong and courageous. Do not be afraid; do not be discouraged, for the Lord your God will be with you wherever you go.",
      author: "Joshua 1:9"
    },
    {
      quote: "And let us not grow weary of doing good, for in due season we will reap, if we do not give up.",
      author: "Galatians 6:9"
    },
    {
      quote: "The pain you feel today is the strength you feel tomorrow.",
      author: "Unknown"
    },
    {
      quote: "Success is not how high you have climbed, but how you make a positive difference to the world.",
      author: "Roy T. Bennett"
    },
    {
      quote: "It always seems impossible until it's done.",
      author: "Nelson Mandela"
    },
    {
      quote: "Start where you are. Use what you have. Do what you can.",
      author: "Arthur Ashe"
    },
    {
      quote: "Success is walking from failure to failure with no loss of enthusiasm.",
      author: "Winston Churchill"
    },
    {
      quote: "Don't let yesterday take up too much of today.",
      author: "Will Rogers"
    },
    {
      quote: "You learn more from failure than from success. Don't let it stop you. Failure builds character.",
      author: "Unknown"
    },
    {
      quote: "People who are crazy enough to think they can change the world, are the ones who do.",
      author: "Rob Siltanen"
    },
    {
      quote: "Failure will never overtake me if my determination to succeed is strong enough.",
      author: "Og Mandino"
    }
  ];
  

export interface QuoteAlertProps {
  onClose?: () => void;
}

export const QuoteAlert: React.FC<QuoteAlertProps> = ({ onClose }) => {
  const [quote, setQuote] = React.useState<MotivationalQuote>(MOTIVATIONAL_QUOTES[0]);

  React.useEffect(() => {
    const randomQuote = MOTIVATIONAL_QUOTES[Math.floor(Math.random() * MOTIVATIONAL_QUOTES.length)];
    setQuote(randomQuote);

    const interval = setInterval(() => {
      const newQuote = MOTIVATIONAL_QUOTES[Math.floor(Math.random() * MOTIVATIONAL_QUOTES.length)];
      setQuote(newQuote);
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Alert className="mb-6 bg-primary-blue/5 dark:bg-primary-blue/10 border border-gray-400 dark:border-gray-700">
      <div className="relative">
        {onClose && (
          <button
            onClick={onClose}
            className="absolute -right-2 -top-2 p-2 text-primary-blue hover:text-primary-blue-hover dark:text-blue-300 dark:hover:text-blue-100"
            aria-label="Close quote"
          >
            <svg 
              className="w-4 h-4" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M6 18L18 6M6 6l12 12" 
              />
            </svg>
          </button>
        )}
        
        {/* Mobile Layout */}
        <div className="md:hidden">
          <div className="flex items-center space-x-3 mb-3">
            <Quote className="h-5 w-5 text-black dark:text-yellow-400" />
            <Lightbulb className="h-5 w-5 text-black dark:text-yellow-400" />
            <span className="text-base font-bold text-black dark:text-yellow-400">
              Daily Motivation:
            </span>
          </div>

          <div className="flex flex-col space-y-2">
            <span className="text-primary-blue dark:text-blue-100 font-medium">
              "{quote.quote}"
            </span>
            <span className="text-sm text-primary-blue/80 dark:text-blue-200">
              — {quote.author}
            </span>
          </div>
        </div>

        {/* Desktop Layout */}
        <div className="hidden md:block">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            <div className="flex-1">
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <div className="flex items-center space-x-3 mb-2 md:mb-0">
                  <Quote className="h-5 w-5 text-black dark:text-yellow-400" />
                  <Lightbulb className="h-5 w-5 text-black dark:text-yellow-400" />
                  <span className="text-base font-bold text-black dark:text-yellow-400">
                    Daily Motivation:
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="text-primary-blue dark:text-blue-100 font-medium">
                    "{quote.quote}"
                  </span>
                  <span className="text-sm text-primary-blue/80 dark:text-blue-200">
                    — {quote.author}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Alert>
  );
};