import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../AuthProvider';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import { api, MentorModule, ContentBlock, MentorModuleMetadata, MentorModuleContent } from '../../services/api';
import { CheckCircle, ChevronDown, ChevronUp, Download, Book, Lock, Crown, Link2, FileText } from 'lucide-react';
import DOMPurify from 'dompurify';
import LoadingScreen from './common/LoadingScreen';
import UpgradeModal from './common/UpgradeModal';
import { Button } from '../ui/Button';
import { Card } from '../ui/Card';
import { useNavigate, useLocation } from 'react-router-dom';

// Cache keys
const CACHE_KEYS = {
  MODULE_METADATA: 'mentor_module_metadata',
  MODULE_CONTENT: 'mentor_module_content_',
  CACHE_TIMESTAMP: 'mentor_cache_timestamp',
  CACHE_DURATION: 24 * 60 * 60 * 1000, // 24 hours in milliseconds
};

// Cache management functions
const cacheManager = {
  setItem: (key: string, value: any) => {
    try {
      localStorage.setItem(key, JSON.stringify({
        timestamp: Date.now(),
        data: value
      }));
    } catch (error) {
      console.error('Error setting cache:', error);
    }
  },

  getItem: (key: string): any | null => {
    try {
      const item = localStorage.getItem(key);
      if (!item) return null;

      const { timestamp, data } = JSON.parse(item);
      const isExpired = Date.now() - timestamp > CACHE_KEYS.CACHE_DURATION;

      if (isExpired) {
        localStorage.removeItem(key);
        return null;
      }

      return data;
    } catch (error) {
      console.error('Error getting cache:', error);
      return null;
    }
  },

  clearCache: () => {
    Object.values(CACHE_KEYS).forEach(key => {
      if (typeof key === 'string' && key.startsWith('mentor_')) {
        localStorage.removeItem(key);
      }
    });
  }
};

// Module content prefetcher
const prefetchModuleContent = async (moduleId: string) => {
  const cacheKey = `${CACHE_KEYS.MODULE_CONTENT}${moduleId}`;
  const cachedContent = cacheManager.getItem(cacheKey);

  if (cachedContent) {
    return cachedContent;
  }

  try {
    const content = await api.getModuleContent(moduleId);
    if (content) {
      cacheManager.setItem(cacheKey, content);
    }
    return content;
  } catch (error) {
    console.error('Error prefetching module content:', error);
    return null;
  }
};

// In Mentor.tsx
interface MentorProps {
  courseId: string;
  userId: string;
  initialModuleId?: string;
}

interface ResourceCardProps {
  title: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
}

const ResourceCard: React.FC<ResourceCardProps> = ({ title, children, icon }) => {
  return (
    <Card className="mb-6">
      <div className="p-6">
        <div className="flex items-center gap-2 mb-4">
          {icon}
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{title}</h3>
        </div>
        <div className="space-y-4">{children}</div>
      </div>
    </Card>
  );
};

const MAX_NOTE_LENGTH = 2000;

const Mentor: React.FC<MentorProps> = ({ courseId, userId, initialModuleId }) => {
  const { user } = useAuth();
  const { userCourseAccesses } = useUserCourseAccess();
  const navigate = useNavigate();
  const location = useLocation();
  const [modules, setModules] = useState<MentorModule[]>([]);
  const [expandedModules, setExpandedModules] = useState<Set<string>>(new Set());
  const [isInitialLoading, setInitialLoading] = useState(true);
  const [isModuleLoading, setModuleLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [editingNoteId, setEditingNoteId] = useState<string | null>(null);
  const [currentModule, setCurrentModule] = useState<string | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showRecommendation, setShowRecommendation] = useState(false);
  const mainContentRef = useRef<HTMLDivElement>(null);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isNotesLoading, setIsNotesLoading] = useState<Record<string, boolean>>({});
  const [moduleMetadata, setModuleMetadata] = useState<MentorModuleMetadata[]>([]);
  const [moduleContents, setModuleContents] = useState<Record<string, MentorModuleContent>>({});
  const [isLoadingContent, setIsLoadingContent] = useState<Record<string, boolean>>({});

  // Update the current module data memo
  const currentModuleData = useMemo(() => {
    if (!currentModule) return null;
    const metadata = moduleMetadata.find(m => m.id === currentModule);
    const content = moduleContents[currentModule];
    if (!metadata) return null;
    
    // Construct a full MentorModule object
    const fullModule: MentorModule = {
      ...metadata,
      course_id: courseId, // Add required course_id
      content: content?.content || { description: '', blocks: [] },
      notes: content?.notes || '',
      created_at: new Date().toISOString(), // Add placeholder timestamp
      updated_at: new Date().toISOString(), // Add placeholder timestamp
    };
    
    return fullModule;
  }, [currentModule, moduleMetadata, moduleContents, courseId]);
  
  // Enhanced module content loading with prefetching
  const prefetchNextModules = useCallback(async (currentModuleId: string) => {
    const currentIndex = moduleMetadata.findIndex(m => m.id === currentModuleId);
    if (currentIndex === -1) return;

    // Prefetch next two modules
    const nextModules = moduleMetadata.slice(currentIndex + 1, currentIndex + 3);
    nextModules.forEach(module => {
      if (!moduleContents[module.id]) {
        prefetchModuleContent(module.id);
      }
    });
  }, [moduleMetadata, moduleContents]);

  // Modified loadModuleContent with caching
  const loadModuleContent = useCallback(async (moduleId: string) => {
    if (moduleContents[moduleId] || isLoadingContent[moduleId]) return;

    const cacheKey = `${CACHE_KEYS.MODULE_CONTENT}${moduleId}`;
    const cachedContent = cacheManager.getItem(cacheKey);

    if (cachedContent) {
      setModuleContents(prev => ({ ...prev, [moduleId]: cachedContent }));
      return;
    }

    setIsLoadingContent(prev => ({ ...prev, [moduleId]: true }));
    try {
      const content = await api.getModuleContent(moduleId);
      if (content) {
        setModuleContents(prev => ({ ...prev, [moduleId]: content }));
        cacheManager.setItem(cacheKey, content);
      }
    } catch (err) {
      console.error('Error loading module content:', err);
      setError('Failed to load module content. Please try again.');
    } finally {
      setIsLoadingContent(prev => ({ ...prev, [moduleId]: false }));
    }
  }, [moduleContents, isLoadingContent]);

  // Split into separate effects for metadata and content loading
  const [initialMetadataLoaded, setInitialMetadataLoaded] = useState(false);

  // Effect for loading metadata
  useEffect(() => {
    let isMounted = true;

    const fetchMetadata = async () => {
      if (!user || !courseId) return;
      
      try {
        setInitialLoading(true);
        const cachedMetadata = cacheManager.getItem(CACHE_KEYS.MODULE_METADATA);
        
        if (cachedMetadata && isMounted) {
          setModuleMetadata(cachedMetadata);
          setInitialMetadataLoaded(true);
        } else {
          const metadata = await api.getMentorModulesMetadata();
          if (isMounted) {
            setModuleMetadata(metadata);
            cacheManager.setItem(CACHE_KEYS.MODULE_METADATA, metadata);
            setInitialMetadataLoaded(true);
          }
        }
      } catch (err) {
        if (isMounted) {
          console.error('Error fetching mentor data:', err);
          setError('Failed to load mentor data. Please try again.');
        }
      } finally {
        if (isMounted) {
          setInitialLoading(false);
        }
      }
    };

    fetchMetadata();

    return () => {
      isMounted = false;
    };
  }, [user, courseId]);

  // Effect for loading initial module content
  useEffect(() => {
    if (!initialMetadataLoaded) return;

    const moduleIdFromUrl = location.pathname.split('/').pop();
    const moduleToLoad = moduleIdFromUrl || initialModuleId;
    
    if (moduleToLoad && moduleToLoad !== 'mentor') {
      setExpandedModules(new Set([moduleToLoad]));
      setCurrentModule(moduleToLoad);
      loadModuleContent(moduleToLoad).then(() => {
        prefetchNextModules(moduleToLoad);
      });
    }
  }, [initialMetadataLoaded, location.pathname, initialModuleId, loadModuleContent, prefetchNextModules]);

  // Modified toggle module function with prefetching
  const toggleModule = useCallback((moduleId: string) => {
    setCurrentModule(moduleId);
    setExpandedModules(prev => {
      const newSet = new Set(prev);
      if (newSet.has(moduleId)) {
        newSet.delete(moduleId);
      } else {
        newSet.add(moduleId);
      }
      return newSet;
    });
    setIsMobileMenuOpen(false);
    setShowRecommendation(false);

    if (mainContentRef.current) {
      mainContentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

    // Load content if not already loaded
    loadModuleContent(moduleId);
    // Prefetch next modules
    prefetchNextModules(moduleId);
  }, [loadModuleContent, prefetchNextModules]);

  // Memoized access control check
  const isContentLocked = useCallback((module: MentorModule | null) => {
    if (!module || !userCourseAccesses?.length) return true;
  
    // First check if user has mentor-only or full access to the mentorship product
    const hasMentorAccess = userCourseAccesses.some(access => 
      access.course_id === 'a6a5eaf3-63bc-4e9a-92ac-64153a1f8395' && // MENTORSHIP
      (access.tier_id === '25f83b83-0a8a-4599-b387-c8870d79b214' || // MENTOR_ONLY
       access.tier_id === 'c99793af-5529-446b-be12-cc56709509e6')    // FULL_ACCESS
    );
  
    // If they have mentor access, never lock the content
    if (hasMentorAccess) return false;

    // Check if the user has study materials only access
    const hasStudyMaterialsAccess = userCourseAccesses.some(access =>
      access.tier_id === '0b992474-3a65-41b5-ad90-cc88662cc594' // STUDY_MATERIALS_ONLY
    );

    // If user has study materials access and content is marked for free trial, allow access
    if (hasStudyMaterialsAccess && module.free_trial_content) {
      return false;
    }
  
    // Otherwise, check course-specific access
    const currentAccess = userCourseAccesses.find(access => access.course_id === courseId);
    if (!currentAccess) return true;
  
    // Define tier IDs
    const freeTrialTierId = '337eeb06-1040-43d5-ad6d-5316e058aec9';
    const mentorOnlyTierId = '25f83b83-0a8a-4599-b387-c8870d79b214';
    const fullAccessTierId = 'c99793af-5529-446b-be12-cc56709509e6';
  
    // If the course is in free trial and content is marked for free trial, allow access
    if (currentAccess.tier_id === freeTrialTierId && module.free_trial_content) {
      return false;
    }
  
    // Otherwise, require mentor-only or full access tier
    return !(currentAccess.tier_id === mentorOnlyTierId || 
             currentAccess.tier_id === fullAccessTierId);
  }, [courseId, userCourseAccesses]);

  // URL update effect
  useEffect(() => {
    if (currentModule && !isInitialLoading) {
      const newPath = `/course/${courseId}/mentor/${currentModule}`;
      if (location.pathname !== newPath) {
        navigate(newPath, { replace: true });
      }
    }
  }, [currentModule, courseId, navigate, isInitialLoading]);

  const handleUpgradeClick = () => {
    setIsUpgradeModalOpen(true);
  };

  const handleModuleCompletion = async (moduleId: string, isCompleted: boolean) => {
    if (!user) return;
  
    try {
      await api.updateMentorModuleProgress(moduleId, isCompleted);
      setModuleMetadata(prev => 
        prev.map(m => m.id === moduleId ? { ...m, is_completed: isCompleted } : m)
      );
      if (isCompleted) {
        setShowRecommendation(true);
      } else {
        setShowRecommendation(false);
      }
    } catch (err) {
      console.error('Error updating module completion:', err);
      setError('Failed to update module completion. Please try again.');
    }
  };


  const sanitizeInput = (input: string) => {
    return DOMPurify.sanitize(input);
  };

  const handleSaveNote = async (moduleId: string, content: string) => {
    if (!user) return;

    try {
      const module = modules.find(m => m.id === moduleId);
      if (!module) {
        throw new Error('Module not found');
      }

      const sanitizedContent = sanitizeInput(content).slice(0, MAX_NOTE_LENGTH);
      const existingNote = await api.getNoteForMentor(user.id, module.course_id, moduleId);
      const updatedNote = await api.updateNote(
        existingNote?.id || null,
        { content: sanitizedContent },
        user.id,
        module.course_id,
        null,
        null,
        moduleId,
        null
      );

      setModules(prev =>
        prev.map(m => m.id === moduleId ? { ...m, notes: updatedNote.content } : m)
      );
      setEditingNoteId(null);
    } catch (err) {
      console.error('Failed to save note:', err);
      setError('Failed to save note. Please try again.');
    }
  };


  const handleClearNote = async (moduleId: string) => {
    if (!user) return;

    try {
      const module = modules.find(m => m.id === moduleId);
      if (!module) {
        throw new Error('Module not found');
      }

      const existingNote = await api.getNoteForMentor(user.id, module.course_id, moduleId);
      if (existingNote) {
        await api.deleteNote(existingNote.id);
      }

      setModules(prev => 
        prev.map(m => m.id === moduleId ? { ...m, notes: '' } : m)
      );
    } catch (err) {
      console.error('Error clearing notes:', err);
      setError('Failed to clear notes. Please try again.');
    }
  };

  const renderCompletionCheckbox = (module: MentorModule) => (
    <button
      onClick={() => handleModuleCompletion(module.id, !module.is_completed)}
      className={`flex items-center justify-center p-3 rounded-md transition-colors duration-200 w-full ${
        module.is_completed
          ? 'bg-primary-blue hover:bg-primary-blue-hover text-white'
          : 'bg-primary-orange hover:bg-primary-orange-hover text-white'
      }`}
      title={module.is_completed ? "Mark as incomplete" : "Mark as complete"}
    >
      <CheckCircle size={20} className="mr-2" />
      <span className="text-sm font-medium">
        {module.is_completed ? "Completed" : "Mark Complete"}
      </span>
    </button>
  );

  const renderNextModuleRecommendation = () => {
    const nextIncompleteModule = moduleMetadata.find(m => !m.is_completed);
    if (!nextIncompleteModule) return (
      <div className="mt-8 bg-blue-50 dark:bg-gray-600 p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
          Congratulations! You've completed all modules.
        </h3>
        <p className="text-gray-600 dark:text-gray-400">
          Keep reviewing to reinforce your knowledge.
        </p>
      </div>
    );
  
    // Construct full module for the recommendation
    const fullNextModule: MentorModule = {
      ...nextIncompleteModule,
      course_id: courseId,
      content: moduleContents[nextIncompleteModule.id]?.content || { description: '', blocks: [] },
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    };
  
    return (
      <div className="mt-8 bg-blue-50 dark:bg-gray-600 p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
          Recommended Next Module
        </h3>
        <p className="text-gray-600 dark:text-gray-400 mb-4">
          Continue your learning journey with the next uncompleted module:
        </p>
        <button
          onClick={() => toggleModule(fullNextModule.id)}
          className="bg-primary-blue text-white px-4 py-2 rounded hover:bg-primary-blue-hover transition-colors duration-200"
        >
          Start {fullNextModule.title}
        </button>
      </div>
    );
  };

  // Update isResourceAccessLocked with the same logic
  const isResourceAccessLocked = useCallback(() => {
    if (!userCourseAccesses?.length) return true;
  
    // First check if user has mentor-only or full access to the mentorship product
    const hasMentorAccess = userCourseAccesses.some(access => 
      access.course_id === 'a6a5eaf3-63bc-4e9a-92ac-64153a1f8395' && // MENTORSHIP
      (access.tier_id === '25f83b83-0a8a-4599-b387-c8870d79b214' || // MENTOR_ONLY
       access.tier_id === 'c99793af-5529-446b-be12-cc56709509e6')    // FULL_ACCESS
    );
  
    // If they have mentor access, never lock the resources
    if (hasMentorAccess) return false;
  
    // Get current course access
    const currentAccess = userCourseAccesses.find(access => access.course_id === courseId);
    if (!currentAccess) return true;
  
    const mentorOnlyTierId = '25f83b83-0a8a-4599-b387-c8870d79b214';
    const fullAccessTierId = 'c99793af-5529-446b-be12-cc56709509e6';
  
    // Require mentor-only or full access tier for this specific course
    return !(currentAccess.tier_id === mentorOnlyTierId || 
             currentAccess.tier_id === fullAccessTierId);
  }, [courseId, userCourseAccesses]);

  const renderResourcesForVideo = (blocks: ContentBlock[], startIndex: number, module: MentorModule): JSX.Element[] => {
    const resources: JSX.Element[] = [];
    let currentIndex = startIndex + 1;
    const downloadBlocks: ContentBlock[] = [];
    
    while (currentIndex < blocks.length && blocks[currentIndex].type !== 'video') {
      const block = blocks[currentIndex];
      
      if (block.type === 'download') {
        downloadBlocks.push(block);
      } else if (block.type === 'html') {
        resources.push(
          <div key={`html-${currentIndex}`} className="relative">
            <ResourceCard 
              title="External Resources"
              icon={<Link2 className="h-5 w-5 text-primary-blue" />}
            >
              <div 
                className={`prose dark:prose-invert max-w-none ${isResourceAccessLocked() ? 'blur-sm' : ''}`}
                dangerouslySetInnerHTML={{ 
                  __html: DOMPurify.sanitize(block.content, {
                    ADD_ATTR: ['target', 'rel'],
                    ADD_TAGS: ['iframe']
                  })
                }} 
              />
            </ResourceCard>
            
            {isResourceAccessLocked() && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 rounded-lg">
                <div className="text-center text-white px-6 py-4">
                  <Lock size={36} className="mx-auto mb-3" />
                  <h3 className="text-lg font-semibold mb-2">Premium External Resources</h3>
                  <p className="mb-4 text-sm">Upgrade your account to access external study resources and tools.</p>
                  <Button
                    onClick={handleUpgradeClick}
                    className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center mx-auto"
                  >
                    <Crown size={16} className="mr-2" />
                    Upgrade Now
                  </Button>
                </div>
              </div>
            )}
          </div>
        );
      }
      
      currentIndex++;
    }
    
    // Add all download buttons to a single card if any exist
    if (downloadBlocks.length > 0) {
      resources.push(
        <div key="downloads" className="relative">
          <ResourceCard 
            title="Course Materials"
            icon={<FileText className="h-5 w-5 text-primary-blue" />}
          >
            <div className={`flex flex-col space-y-3 ${isResourceAccessLocked() ? 'blur-sm' : ''}`}>
              {downloadBlocks.map((block, idx) => {
                if (!block.downloadUrl) return null;
                
                const downloadUrl = block.downloadUrl.replace('{{courseId}}', courseId);
                const buttonClasses = block.buttonClass || 
                  "inline-flex items-center px-4 py-2 bg-primary-blue text-white rounded-md hover:bg-primary-blue-hover transition-colors duration-200 w-full justify-center";
                
                return (
                  <a 
                    key={`download-${idx}`}
                    href={downloadUrl}
                    className={buttonClasses}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Download size={18} className="mr-2" />
                    <span>{block.buttonText || 'Download'}</span>
                  </a>
                );
              })}
            </div>
          </ResourceCard>

          {isResourceAccessLocked() && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 rounded-lg">
              <div className="text-center text-white px-6 py-4">
                <Lock size={36} className="mx-auto mb-3" />
                <h3 className="text-lg font-semibold mb-2">Premium Course Materials</h3>
                <p className="mb-4 text-sm">Upgrade your account to access course materials and downloads.</p>
                <Button
                  onClick={handleUpgradeClick}
                  className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center mx-auto"
                >
                  <Crown size={16} className="mr-2" />
                  Upgrade Now
                </Button>
              </div>
            </div>
          )}
        </div>
      );
    }
    
    return resources;
  };

  const renderContentBlock = (
    block: ContentBlock, 
    courseId: string, 
    videoIndex: number, 
    totalVideos: number, 
    module: MentorModule,
    blocks: ContentBlock[], 
    blockIndex: number
  ): JSX.Element | null => {
    // Only show sales pitch for the first block when content is locked
    if (isContentLocked(module)) {
      const salesPitch = module.sales_pitch || 
                        module.content.sales_pitch || 
                        "Upgrade now to access this premium content and accelerate your CPA exam success.";
  
      return (
        <div className="relative min-h-[400px] bg-gray-50 dark:bg-gray-800 rounded-lg border border-gray-400 dark:border-gray-600">
          <div className="blur-sm pointer-events-none">
            <div className="prose dark:prose-invert max-w-none mb-6">
              <h3>{module.title}</h3>
              <p>{module.content.description}</p>
            </div>
          </div>
          
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="text-center text-white p-8 rounded-lg max-w-2xl">
              <Lock size={48} className="mx-auto mb-4" />
              <h3 className="text-2xl font-bold mb-4">{module.title}</h3>
              <div 
                className="mb-6 prose prose-invert max-w-none"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(salesPitch) }}
              />
              <Button
                onClick={handleUpgradeClick}
                className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center justify-center mx-auto"
              >
                <Crown size={16} className="mr-2" />
                Upgrade Now
              </Button>
            </div>
          </div>
        </div>
      );
    }

    // Enhanced content block rendering logic
    switch (block.type) {
      case 'video':
        return (
          <div key={`video-${blockIndex}`} className="space-y-6">
            <div className="mb-8 w-full">
              <div className="flex items-center justify-between p-3 mb-3">
                <h4 className="text-lg font-semibold">
                  {totalVideos > 1 ? `[${videoIndex} of ${totalVideos}] ` : ''}{block.content}
                </h4>
              </div>
              <div className="relative w-full rounded-lg overflow-hidden" style={{ paddingBottom: '56.25%' }}>
                <iframe
                  src={`https://player.vimeo.com/video/${block.videoId}?h=00000000`}
                  className="absolute top-0 left-0 w-full h-full"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  title={block.content}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {renderResourcesForVideo(blocks, blockIndex, module)}
            </div>
          </div>
        );

      case 'text':
        return (
          <div 
            key={`text-${blockIndex}`}
            className="prose dark:prose-invert max-w-none mb-6"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(block.content) }} 
          />
        );

      default:
        return null;
    }
  };

  const renderNotesSection = (module: MentorModule) => (
    <div className="mt-8 bg-gray-50 dark:bg-gray-700 p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Module Notes</h3>
      {isNotesLoading[module.id] ? (
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-4 py-1">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-gray-200 rounded"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      ) : editingNoteId === module.id ? (
        <>
          <textarea
            className="w-full p-3 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            rows={4}
            value={module.notes || ''}
            onChange={(e) => {
              const sanitizedContent = sanitizeInput(e.target.value).slice(0, MAX_NOTE_LENGTH);
              setModules(prev =>
                prev.map(m => m.id === module.id ? { ...m, notes: sanitizedContent } : m)
              );
            }}
            placeholder="Add your notes here..."
            maxLength={MAX_NOTE_LENGTH}
          ></textarea>
          <p className="text-sm text-gray-500 mt-1 mb-2">
            {(module.notes || '').length}/{MAX_NOTE_LENGTH} characters
          </p>
          <div className="mt-4 space-x-2">
            <button
              onClick={() => handleSaveNote(module.id, module.notes || '')}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200"
            >
              Save
            </button>
            <button
              onClick={() => setEditingNoteId(null)}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition-colors duration-200"
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            {module.notes 
              ? <span dangerouslySetInnerHTML={{ __html: sanitizeInput(module.notes) }} />
              : 'No notes yet.'}
          </p>
          <div className="space-x-2">
            <button
              onClick={() => setEditingNoteId(module.id)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200"
            >
              {module.notes ? 'Edit' : 'Add'} Note
            </button>
            {module.notes && (
              <button
                onClick={() => handleClearNote(module.id)}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-200"
              >
                Clear Note
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );

  if (isInitialLoading) {
    return <LoadingScreen message="Loading your Mentorship Modules..." subMessage="This may take a few moments" />;
  }

  if (error) return <div className="text-red-500 text-center py-10">{error}</div>;

  const completedModules = moduleMetadata.filter(m => m.is_completed).length;
  const totalModules = moduleMetadata.length;
  const progressPercentage = (completedModules / totalModules) * 100;

  return (
    <div className="flex flex-col md:flex-row h-screen overflow-hidden bg-gray-50 dark:bg-gray-700 rounded-lg shadow-md pl-4 py-4 max-w-[1400px] mx-auto">
      {/* Mobile Menu Dropdown */}
      <div className="md:hidden w-full bg-white dark:bg-gray-800 shadow-md mb-8 border border-gray-400 dark:border-gray-600">
        <button
          className="w-full p-4 flex justify-between items-center text-lg font-semibold"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          Kesler CPA Mentorship
          {isMobileMenuOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
        </button>
        {isMobileMenuOpen && (
          <div className="p-4 space-y-2">
            {moduleMetadata.map((module) => (
              <button
                key={module.id}
                onClick={() => toggleModule(module.id)}
                className={`w-full text-left p-2 text-sm rounded-md transition-colors duration-200 flex items-center ${
                  currentModule === module.id
                    ? 'bg-primary-blue text-white'
                    : 'hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                <span className="flex-grow pr-2">{module.title}</span>
                {module.is_completed && (
                  <CheckCircle size={16} className="text-green-500 flex-shrink-0" />
                )}
                {isContentLocked(currentModuleData) && (
                  <Lock size={16} className="text-gray-400 flex-shrink-0" />
                )}
              </button>
            ))}
          </div>
        )}
      </div>
  
      {/* Desktop Sidebar */}
      <div className="hidden md:block md:w-80 bg-white dark:bg-gray-800 shadow-md overflow-hidden rounded-lg h-full border border-gray-400 dark:border-gray-600">
        <div className="h-full flex flex-col">
          <h2 className="text-xl font-bold p-4 text-gray-800 dark:text-gray-200">Kesler CPA Mentorship</h2>
          <div className="flex-1 overflow-y-auto">
          <div className="p-2 space-y-1">
              {moduleMetadata.map((moduleData) => {
                // Construct full module for content locking check
                const fullModule: MentorModule = {
                  ...moduleData,
                  course_id: courseId,
                  content: moduleContents[moduleData.id]?.content || { description: '', blocks: [] },
                  created_at: new Date().toISOString(),
                  updated_at: new Date().toISOString()
                };

                return (
                  <button
                    key={moduleData.id}
                    onClick={() => toggleModule(moduleData.id)}
                    className={`w-full text-left p-3 text-sm rounded-md transition-colors duration-200 flex items-center border-l-4 ${
                      currentModule === moduleData.id
                        ? 'bg-blue-50 dark:bg-blue-900 border-primary-blue text-primary-blue dark:text-gray-200'
                        : moduleData.is_completed
                        ? 'bg-green-50 dark:bg-green-900 border-green-500 text-green-700 dark:text-green-300'
                        : 'bg-gray-50 dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 hover:border-primary-blue hover:text-primary-blue dark:hover:text-primary-blue'
                    }`}
                  >
                    <span className="flex-grow pr-2">{moduleData.title}</span>
                    <div className="flex items-center space-x-2">
                      {moduleData.is_completed && (
                        <CheckCircle size={16} className="text-green-500 flex-shrink-0" />
                      )}
                      {isContentLocked(fullModule) && (
                        <Lock size={16} className="text-gray-400 flex-shrink-0" />
                      )}
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
  
      {/* Main content */}
      <div ref={mainContentRef} className="flex-1 overflow-y-auto">
        <div className="max-w-[95%] mx-auto md:px-6">
          {/* Course Overview */}
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md mb-8 border border-gray-400 dark:border-gray-600">
            <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">How To Pass The CPA Exam</h1>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Complete the modules to learn our PROVEN step-by-step plan to starting, studying and passing the CPA Exam and earn your KeslerBOOST.
            </p>
            <div className="flex items-center space-x-4 mb-4">
              <div className="flex items-center">
                <Book size={20} className="mr-2 text-primary-blue" />
                <span>{totalModules} Modules</span>
              </div>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-3 dark:bg-gray-700">
              <div 
                className="bg-primary-blue h-3 rounded-full transition-all duration-500 ease-in-out" 
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              {completedModules} of {totalModules} modules completed
            </p>
          </div>
  
          {/* Module Content */}
          {currentModule && currentModuleData && (
            <div className="bg-white dark:bg-gray-800 py-6 px-2 md:px-6 rounded-lg shadow-md relative border border-gray-400 dark:border-gray-600">
              {/* Add loading indicator */}
              {isModuleLoading && (
                <div className="absolute top-0 left-0 w-full h-1">
                  <div className="h-full bg-primary-blue animate-pulse"></div>
                </div>
              )}
              
              <h2 className="text-2xl font-bold p-3 mb-4 text-gray-900 dark:text-white">
                {currentModuleData.title}
              </h2>
  
              {isContentLocked(currentModuleData) ? (
                <div className="relative min-h-[600px] bg-gray-50 dark:bg-gray-800 rounded-lg border border-gray-400 dark:border-gray-600">
                  <div className="blur-sm pointer-events-none">
                    <div className="prose dark:prose-invert max-w-none mb-6">
                      <h3>{currentModuleData.title}</h3>
                      <p>{currentModuleData.content.description}</p>
                    </div>
                  </div>
                  
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="text-center p-8 rounded-lg max-w-2xl">
                      <Lock size={48} className="mx-auto mb-4 text-white" />
                      <h3 className="text-2xl font-bold mb-4 text-white">
                        {currentModuleData.title}
                      </h3>
                      <div 
                        className="mb-6 prose prose-invert max-w-none dark:prose-p:text-gray-200"
                        dangerouslySetInnerHTML={{ 
                          __html: DOMPurify.sanitize(
                            currentModuleData.sales_pitch ||
                            currentModuleData.content.sales_pitch || 
                            "Upgrade now to access this premium content and accelerate your CPA exam success."
                          )
                        }}
                      />
                      <Button
                        onClick={() => setIsUpgradeModalOpen(true)}
                        className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center justify-center mx-auto"
                      >
                        <Crown size={16} className="mr-2" />
                        Upgrade Now
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="space-y-6">
                    {currentModuleData.content.blocks.map((block, index) => {
                      const totalVideos = currentModuleData.content.blocks.filter(b => b.type === 'video').length;
                      const videoIndex = currentModuleData.content.blocks.filter((b, i) => b.type === 'video' && i <= index).length;
                      
                      return (
                        <div key={index}>
                          {renderContentBlock(
                            block,
                            courseId,
                            videoIndex,
                            totalVideos,
                            currentModuleData,
                            currentModuleData.content.blocks,
                            index
                          )}
                        </div>
                      );
                    })}
                  </div>
  
                  {/* Notes Section */}
                  {renderNotesSection(currentModuleData)}
  
                  {/* Module Completion */}
                  <div className="mt-8">
                    {renderCompletionCheckbox(currentModuleData)}
                  </div>
  
                  {/* Next Module Recommendation */}
                  {showRecommendation && renderNextModuleRecommendation()}
                </>
              )}
            </div>
          )}
  
          {/* Display course overview when no module is selected */}
          {!currentModule && (
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md mt-8 border border-gray-400 dark:border-gray-600">
              <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">Welcome to Your CPA Exam Preparation</h3>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                Select a module from the sidebar to begin your study session. 
                Each module contains valuable content to help you master the CPA exam material.
              </p>
              {renderNextModuleRecommendation()}
            </div>
          )}
        </div>
      </div>
  
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
      />
    </div>
  );
};

export default Mentor;