import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../../components/AuthProvider';
import { supabase } from '../../../services/supabase';
import { api, Message, Ticket, SupportUser } from '../../../services/api';
import { format } from 'date-fns';
import { 
  ArrowLeft,
  Clock,
  Loader2,
  Paperclip,
  Send,
  HelpCircle,
  AlertCircle,
  X
} from 'lucide-react';
import { Button } from '../../ui/Button';
import { Textarea } from '../../ui/Textarea';
import { Avatar } from '../../ui/Avatar';
import { Badge } from '../../ui/Badge';
import { ScrollArea } from '../../ui/ScrollArea';
import { Alert, AlertDescription } from '../../ui/Alert';

interface UserTicketDetailProps {
  ticketId: string;
  onBack: () => void;
  onToggleContent?: () => void;  // Renamed from onToggleQuestion
}

interface AttachmentError {
  fileName: string;
  error: string;
}

const UserTicketDetail: React.FC<UserTicketDetailProps> = ({ 
  ticketId, 
  onBack,
  onToggleContent 
}) => {
  const { user } = useAuth();
  const [ticket, setTicket] = useState<Ticket | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [messageText, setMessageText] = useState('');
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [attachmentErrors, setAttachmentErrors] = useState<AttachmentError[]>([]);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (ticketId && user?.id) {
      loadTicketData();
      
      // CHANGE: Update to use support_user_id
      const markMessagesAsRead = async () => {
        if (!user?.id) return;
        
        try {
          const supportUser = await api.getOrCreateSupportUser(user.email, user.id);
          
          await supabase
            .from('support_messages')
            .update({ is_read: true })
            .eq('ticket_id', ticketId)
            .neq('support_user_id', supportUser.id);  // Use support_user_id
        } catch (error) {
          console.error('Error marking messages as read:', error);
        }
      };
  
      markMessagesAsRead();
    }
  }, [ticketId, user?.id]);

    // Add this function to render HTML content safely
    const renderHtmlContent = (content: string) => {
      // Create a DOMParser to parse the HTML content
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');
      
      // Add base styles that will override the inline styles from the email
      const styleTag = `
        <style>
          /* Base styles */
          .ticket-content {
            font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
            line-height: 1.6;
          }
          
          /* Light mode styles */
          .ticket-content {
            --text-primary: #333333;
            --text-secondary: #666666;
            --border-color: #eeeeee;
            --link-color: #0066cc;
            color: var(--text-primary);
          }
          
          /* Dark mode styles */
          :root.dark .ticket-content,
          [data-theme="dark"] .ticket-content {
            --text-primary: #e5e7eb;
            --text-secondary: #9ca3af;
            --border-color: #374151;
            --link-color: #60a5fa;
            color: var(--text-primary);
          }
          
          /* Style overrides */
          .ticket-content [style*="color: #333333"] {
            color: var(--text-primary) !important;
          }
          
          .ticket-content [style*="color: #666666"] {
            color: var(--text-secondary) !important;
          }
          
          .ticket-content [style*="border-top: 1px solid #eeeeee"],
          .ticket-content [style*="border: 1px solid #eeeeee"] {
            border-color: var(--border-color) !important;
          }
          
          .ticket-content a {
            color: var(--link-color) !important;
            text-decoration: underline;
          }
          
          .ticket-content a:hover {
            text-decoration: none;
          }
          
          /* Preserve whitespace and line breaks */
          .ticket-content br {
            display: block;
            content: "";
            margin-top: 0.5em;
          }
          
          /* Additional spacing for paragraphs */
          .ticket-content p {
            margin: 0.5em 0;
          }
        </style>
      `;
  
      // Wrap the content in our styled container
      const wrappedContent = `
        <div class="ticket-content">
          ${doc.body.innerHTML}
        </div>
      `;
  
      return (
        <div 
          dangerouslySetInnerHTML={{ 
            __html: styleTag + wrappedContent
          }}
        />
      );
    };

    // CHANGE: Update to use support_users instead of users table
    //const loadUserData = async (supportUserIds: string[]) => {
      //if (!supportUserIds.length) return new Map();
      
      //const { data: users, error } = await supabase
       // .from('support_users')
        //.select('*')
        //.in('id', supportUserIds);

      //if (error) throw error;

      //return new Map(users?.map(user => [user.id, user]) || []);
    //};

    // ADD: Helper function for getting current support user
    const getCurrentSupportUser = async () => {
      if (!user?.id) return null;
      
      const { data: supportUser, error } = await supabase
        .from('support_users')
        .select('id')
        .eq('auth_user_id', user.id)
        .single();

      if (error) return null;
      return supportUser;
    };

    const loadTicketDetails = async () => {
      try {
        const { data: ticketData, error: ticketError } = await supabase
          .from('support_tickets')
          .select(`
            *,
            user:support_users!support_tickets_support_user_id_fkey(*)
          `)
          .eq('id', ticketId)
          .single();
    
        if (ticketError) throw ticketError;
        
        const currentSupportUser = await getCurrentSupportUser();
        if (!currentSupportUser) {
          throw new Error('Support user not found');
        }
        
        if (ticketData.support_user_id !== currentSupportUser.id) {
          throw new Error('Unauthorized access to ticket');
        }
    
        setTicket({
          ...ticketData,
          user: ticketData.user
        });
      } catch (error) {
        console.error('Error loading ticket details:', error);
        setError('Failed to load ticket details');
      }
    };
  
  const loadMessages = async () => {
    try {
      // CHANGE: Updated select to include support_user data directly
      const { data: ticketData } = await supabase
        .from('support_tickets')
        .select(`
          *,
          user:support_users!support_tickets_support_user_id_fkey(
            id,
            email,
            full_name
          )
        `)
        .eq('id', ticketId)
        .single();
  
      // CHANGE: Updated select to include support_user data directly
      const { data: messagesData, error: messagesError } = await supabase
        .from('support_messages')
        .select(`
          *,
          user:support_users!support_messages_support_user_id_fkey(
            id,
            email,
            full_name
          )
        `)
        .eq('ticket_id', ticketId)
        .eq('is_internal', false)
        .order('created_at', { ascending: true });
  
      if (messagesError) throw messagesError;
 
      // CHANGE: Update initial message to use support_user data
      const initialMessage = ticketData ? {
        id: ticketData.id,
        ticket_id: ticketData.id,
        support_user_id: ticketData.support_user_id, // Changed from user_id
        content: ticketData.body,
        is_internal: false,
        created_at: ticketData.created_at,
        attachments: ticketData.attachments || [],
        user: ticketData.user || {  // Using joined user data
          id: 'unknown',
          email: 'unknown',
          full_name: 'Unknown User'
        },
        message_type: 'inbound',
        email_sent: false,
        is_read: true,
        email_message_id: ticketData.original_email_id,
        from_email: ticketData.original_from_email,
        to_emails: null,
        email_subject: ticketData.original_subject,
        email_references: []
      } : null;
  
      // CHANGE: No need to transform messages since user data is already included
      const allMessages = [
        ...(initialMessage ? [initialMessage] : []),
        ...(messagesData || [])  // Messages already have user data from join
      ].sort((a, b) => {
        if (a.message_type === 'web' && !b.message_type) return -1;
        if (b.message_type === 'web' && !a.message_type) return 1;
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      });
  
      setMessages(allMessages);
    } catch (error) {
      console.error('Error loading messages:', error);
      setError('Failed to load messages');
    }
  };

  const loadTicketData = async () => {
    setLoading(true);
    try {
      await Promise.all([
        loadTicketDetails(),
        loadMessages()
      ]);
    } catch (error) {
      console.error('Error loading ticket data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    
    setUploading(true);
    const newErrors: AttachmentError[] = [];
    const validFiles: File[] = [];

    for (const file of Array.from(event.target.files)) {
      try {
        // Check file type
        const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/gif'];
        if (!allowedTypes.includes(file.type)) {
          newErrors.push({
            fileName: file.name,
            error: `Only PDF and images are permitted`
          });
          continue;
        }

        // Check file size (10MB limit)
        if (file.size > 10 * 1024 * 1024) {
          newErrors.push({
            fileName: file.name,
            error: `File exceeds 10MB limit`
          });
          continue;
        }

        validFiles.push(file);
      } catch (error) {
        newErrors.push({
          fileName: file.name,
          error: 'Failed to process file'
        });
      }
    }

    // Add valid files to attachments
    if (validFiles.length) {
      setAttachments(prev => [...prev, ...validFiles]);
    }

    // Set errors if any
    if (newErrors.length) {
      setAttachmentErrors(newErrors);
    }

    setUploading(false);
    if (event.target.value) {
      event.target.value = '';
    }
  };

  // Add function to remove specific error
  const removeError = (fileName: string) => {
    setAttachmentErrors(errors => errors.filter(e => e.fileName !== fileName));
  };

  // Update removeAttachment to also remove any associated errors
  const removeAttachment = (index: number) => {
    setAttachments(attachments.filter((_, i) => i !== index));
    setAttachmentErrors(errors => errors.filter(e => e.fileName !== attachments[index].name));
  };

  const handleSendMessage = async () => {
    if (!user || (!messageText.trim() && attachments.length === 0)) return;
    
    setSending(true);
    try {
      const supportUser = await api.getOrCreateSupportUser(user.email, user.id);
  
      // Handle attachments...
      const uploadedAttachments = [];
      for (const file of attachments) {
        const filePath = `${ticketId}/${Date.now()}-${file.name}`;
        const { error: uploadError } = await supabase.storage
          .from('support-attachments')
          .upload(filePath, file);

        if (uploadError) throw uploadError;

        const { data: { publicUrl } } = supabase.storage
          .from('support-attachments')
          .getPublicUrl(filePath);

        uploadedAttachments.push({
          name: file.name,
          url: publicUrl,
          type: file.type
        });
      }

      // CHANGE: Update message creation to use support_user_id and include user data in select
      const { data: messageData, error: messageError } = await supabase
        .from('support_messages')
        .insert({
          ticket_id: ticketId,
          support_user_id: supportUser.id,  // Use support_user_id
          content: messageText,
          is_internal: false,
          attachments: uploadedAttachments
        })
        .select()
        .single();

      if (messageError) throw messageError;

      // No changes needed for ticket status update
      const { error: ticketError } = await supabase
        .from('support_tickets')
        .update({ 
          status: 'pending',
          updated_at: new Date().toISOString(),
          last_activity_at: new Date().toISOString()
        })
        .eq('id', ticketId);

      if (ticketError) throw ticketError;

      // CHANGE: Use messageData directly since it includes user info
      setMessages(prev => [...prev, messageData]);
      setMessageText('');
      setAttachments([]);
      setAttachmentErrors([]);

    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message');
    } finally {
      setSending(false);
    }
  };

  const getTimeDisplay = (date: string) => {
    const messageDate = new Date(date);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (messageDate.toDateString() === today.toDateString()) {
      return `Today at ${format(messageDate, 'h:mm a')}`;
    } else if (messageDate.toDateString() === yesterday.toDateString()) {
      return `Yesterday at ${format(messageDate, 'h:mm a')}`;
    } else {
      return format(messageDate, 'MMM d, yyyy h:mm a');
    }
  };

  // Add new function to handle ticket reopening
  const handleReopen = async () => {
    if (!user?.id) return;
    
    try {
      const supportUser = await api.getOrCreateSupportUser(user.email, user.id);
      
      const { error } = await supabase
        .from('support_tickets')
        .update({ 
          status: 'open',
          updated_at: new Date().toISOString(),
          last_activity_at: new Date().toISOString()
        })
        .eq('id', ticketId)
        .eq('support_user_id', supportUser.id);  // Add support user check
  
      if (error) throw error;
      
      setTicket(prev => prev ? { ...prev, status: 'open' } : null);
    } catch (error) {
      console.error('Error reopening ticket:', error);
      setError('Failed to reopen ticket');
    }
  };

    // Add a helper function to check if a user is an admin
    const isAdminUser = (messageUser: SupportUser, ticketUser?: SupportUser) => {
      // Consider a user an admin if:
      // 1. They have an auth_user_id (internal user) AND
      // 2. Their ID is different from the ticket creator's ID
      return Boolean(
        messageUser?.auth_user_id && 
        ticketUser?.id && 
        messageUser.id !== ticketUser.id
      );
    };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader2 className="w-8 h-8 animate-spin text-gray-500" />
      </div>
    );
  }

  return (
    <div className="h-[calc(100vh-125px)] flex flex-col overflow-hidden">
      {/* Header */}
      <div className="flex-none px-3 md:px-6 py-2 md:py-4 bg-white dark:bg-gray-800 border-b dark:border-gray-700 shadow-sm">
        <div className="flex items-center gap-2 md:gap-4">
          <Button 
            variant="outline"
            onClick={onBack}
            className="h-8 w-8 md:h-10 md:w-10 p-0 flex items-center justify-center dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700 dark:text-gray-100"
          >
            <ArrowLeft className="h-4 w-4 md:h-5 md:w-5" />
          </Button>
          <div className="flex-1 min-w-0">
            <h2 className="text-sm md:text-lg font-semibold text-gray-900 dark:text-gray-100 break-words">
              {ticket?.subject}
            </h2>
            <div className="flex items-center gap-2 text-xs text-gray-500 dark:text-gray-400">
              <span className="flex items-center gap-1">
                <Clock className="w-3 h-3 md:w-4 md:h-4" />
                {ticket?.created_at && getTimeDisplay(ticket.created_at)}
              </span>
              <span className="text-gray-400 dark:text-gray-500">•</span>
              <Badge 
                variant={ticket?.status === 'closed' ? 'secondary' : 'default'}
                className="capitalize text-xs dark:text-gray-100"
              >
                {ticket?.status}
              </Badge>
            </div>
          </div>
        </div>
      </div>

      {/* Message Thread */}
      <div className="flex-1 overflow-y-auto">
        <div className="p-3 md:p-6 space-y-3 md:space-y-4">
          {error && (
            <Alert variant="destructive" className="mb-3 md:mb-4">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription className="text-xs md:text-sm">{error}</AlertDescription>
            </Alert>
          )}

          {messages.map((message, index) => {
            const isAdmin = isAdminUser(message.user as SupportUser, ticket?.user as SupportUser);
            const isFirstMessage = index === 0;
            
            return (
              <div key={message.id} className={`flex gap-2 md:gap-4 ${isAdmin ? 'flex-row-reverse' : ''}`}>
                <Avatar 
                  name={message.user?.full_name || 'Unknown User'}
                  className={`w-8 h-8 md:w-10 md:h-10 flex-shrink-0 ${
                    isAdmin 
                      ? 'bg-emerald-100 text-emerald-600 dark:bg-emerald-900 dark:text-emerald-200'
                      : 'bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-200'
                  }`}
                />
                <div className="flex-1 min-w-0">
                  <div className={`rounded-lg p-3 md:p-4 ${
                    isAdmin
                      ? 'bg-emerald-50 dark:bg-emerald-900/20'
                      : `bg-blue-50 dark:bg-blue-900/20 ${
                          isFirstMessage ? 'ring-1 ring-blue-200 dark:ring-blue-800' : ''
                        }`
                  }`}>
                    <div className="flex items-center justify-between gap-2 mb-2">
                      <div className="flex items-center gap-1.5 min-w-0">
                        <span className="text-sm md:text-base font-medium truncate">
                          {message.user?.full_name || 'Unknown User'}
                        </span>
                        {isAdmin && (
                          <Badge variant="secondary" className="text-xs whitespace-nowrap bg-emerald-100 text-emerald-800 dark:bg-emerald-900 dark:text-emerald-200">
                            Support
                          </Badge>
                        )}
                      </div>
                      <span className="text-xs md:text-sm text-gray-500 whitespace-nowrap">
                        {getTimeDisplay(message.created_at)}
                      </span>
                    </div>
                    <div className="prose dark:prose-invert max-w-none text-sm md:text-base">
                      {renderHtmlContent(message.content)}
                    </div>
                    {message.attachments && message.attachments.length > 0 && (
                      <div className="mt-3 space-y-1.5">
                        {message.attachments.map((attachment, idx) => (
                          <a
                            key={idx}
                            href={attachment.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center gap-1.5 md:gap-2 text-xs md:text-sm text-blue-500 hover:text-blue-600"
                          >
                            <Paperclip className="w-3 h-3 md:w-4 md:h-4 flex-shrink-0" />
                            <span className="flex-1 truncate">{attachment.name}</span>
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Reply Section */}
      <div className="flex-none bg-white dark:bg-gray-800 border-t dark:border-gray-700">
        {ticket?.status === 'closed' && (
          <div className="px-3 md:px-4 pt-2">
            <Alert className="mb-2 dark:bg-gray-800 dark:text-gray-100">
              <AlertDescription className="text-xs md:text-sm">
                This ticket has been closed. Reply to reopen it.
              </AlertDescription>
            </Alert>
          </div>
        )}

        {/* Attachment Errors */}
        {attachmentErrors.length > 0 && (
          <div className="px-3 md:px-4 pt-2">
            {attachmentErrors.map((error, index) => (
              <Alert 
                key={index}
                variant="destructive"
                className="mb-2 flex items-center justify-between"
              >
                <div className="flex items-center gap-2 min-w-0">
                  <AlertCircle className="h-4 w-4 flex-shrink-0" />
                  <span className="text-xs md:text-sm font-medium truncate">
                    {error.fileName}:
                  </span>
                  <span className="text-xs md:text-sm truncate">
                    {error.error}
                  </span>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => removeError(error.fileName)}
                  className="h-6 w-6 p-0 hover:bg-red-200 dark:hover:bg-red-900/50 ml-2 flex-shrink-0"
                >
                  <X className="h-3 w-3 md:h-4 md:w-4" />
                </Button>
              </Alert>
            ))}
          </div>
        )}

        {/* Attachments Display */}
        <div className="px-3 md:px-4 pt-2">
          {attachments.map((file, index) => (
            <div key={index} className="flex items-center gap-2 text-xs md:text-sm bg-gray-50 dark:bg-gray-700 p-2 rounded mb-2">
              <Paperclip className="w-3 h-3 md:w-4 md:h-4 flex-shrink-0" />
              <span className="flex-1 truncate">{file.name}</span>
              <Button
                variant="ghost"
                onClick={() => removeAttachment(index)}
                className="h-6 w-6 p-0 flex-shrink-0"
              >
                <X className="h-3 w-3 md:h-4 md:w-4" />
              </Button>
            </div>
          ))}
        </div>

        {/* Text Area and Actions */}
        <div className="p-3 md:p-4">
          <div className="flex gap-2">
            <Textarea
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              placeholder={
                ticket?.status === 'closed' 
                  ? "Type to reopen..."
                  : "Type your message..."
              }
              className="flex-1 min-h-[80px] md:min-h-[100px] bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 border-gray-200 dark:border-gray-700 resize-none"
            />
            <div className="flex flex-col gap-2">
              <Button
                variant="outline"
                onClick={() => fileInputRef.current?.click()}
                className="h-8 w-8 md:h-10 md:w-10 p-0 flex items-center justify-center"
                disabled={uploading}
              >
                {uploading ? (
                  <Loader2 className="w-4 h-4 animate-spin" />
                ) : (
                  <Paperclip className="w-4 h-4" />
                )}
              </Button>
              <Button
                onClick={async () => {
                  if (ticket?.status === 'closed') {
                    await handleReopen();
                  }
                  handleSendMessage();
                }}
                disabled={sending || (!messageText.trim() && attachments.length === 0)}
                className="h-8 w-8 md:h-10 md:w-10 p-0 flex items-center justify-center"
              >
                {sending ? (
                  <Loader2 className="w-4 h-4 animate-spin" />
                ) : (
                  <Send className="w-4 h-4" />
                )}
              </Button>
            </div>
          </div>
        </div>

        {/* Hidden file input */}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          className="hidden"
          multiple
          accept=".pdf,.jpg,.jpeg,.png,.gif"
        />
      </div>
    </div>
  );
};

export default UserTicketDetail;