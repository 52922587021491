// FlashcardUtils.ts

import { ConfidenceLevelValue } from '../services/api';

const CARDS_COMPLETED_KEY = 'keslerCardsCompleted';
const SPACED_REPETITION_UNLOCKED_KEY = 'spacedRepetitionUnlocked';
const CONFIDENCE_LEVELS_KEY = 'keslerCardsConfidence';
const FILTER_COUNTS_KEY = 'keslerCardsFilterCounts';
const REVIEW_DATES_KEY = 'keslerCardsReviewDates';

export interface FilterCounts {
  all: number;
  unanswered: number;
  guessing: number;
  maybe: number;
  confident: number;
  createcards: number;
}

// New interfaces for spaced repetition
export interface ReviewDateEntry {
  nextReviewDate: string;
  lastReviewed: string;
  confidenceLevel: ConfidenceLevelValue;
  interval: number;
}

export interface ReviewDatesMap {
  [cardId: string]: ReviewDateEntry;
}

export const REVIEW_INTERVALS = {
  guessing: 1,  // 1 day
  maybe: 3,     // 3 days
  confident: 7  // 7 days
} as const;

export const getCardsCompleted = (userId: string, courseId: string): number => {
  const key = `${CARDS_COMPLETED_KEY}_${userId}_${courseId}`;
  const stored = localStorage.getItem(key);
  return stored ? parseInt(stored, 10) : 0;
};

export const updateCardsCompletedInStorage = (userId: string, courseId: string, count: number): void => {
  const key = `${CARDS_COMPLETED_KEY}_${userId}_${courseId}`;
  localStorage.setItem(key, count.toString());
};

export const getSpacedRepetitionUnlocked = (userId: string, courseId: string): boolean => {
  const key = `${SPACED_REPETITION_UNLOCKED_KEY}_${userId}_${courseId}`;
  return localStorage.getItem(key) === 'true';
};

export const setSpacedRepetitionUnlocked = (userId: string, courseId: string, unlocked: boolean): void => {
  const key = `${SPACED_REPETITION_UNLOCKED_KEY}_${userId}_${courseId}`;
  localStorage.setItem(key, unlocked.toString());
};

export const getFilterCountsFromStorage = (userId: string, courseId: string): FilterCounts | null => {
  try {
    const key = `${FILTER_COUNTS_KEY}_${userId}_${courseId}`;
    const stored = localStorage.getItem(key);
    if (!stored) return null;
    return JSON.parse(stored) as FilterCounts;
  } catch (error) {
    console.error('Error reading filter counts from storage:', error);
    return null;
  }
};

export const setFilterCountsInStorage = (
  userId: string,
  courseId: string,
  counts: FilterCounts,
  expiryMinutes: number = 30
): void => {
  try {
    const key = `${FILTER_COUNTS_KEY}_${userId}_${courseId}`;
    const data = {
      counts,
      expires: Date.now() + expiryMinutes * 60 * 1000
    };
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.error('Error saving filter counts to storage:', error);
  }
};

export const getValidFilterCounts = (userId: string, courseId: string): FilterCounts | null => {
  try {
    const key = `${FILTER_COUNTS_KEY}_${userId}_${courseId}`;
    const stored = localStorage.getItem(key);
    if (!stored) return null;

    const data = JSON.parse(stored);
    if (!data.expires || Date.now() > data.expires) {
      localStorage.removeItem(key);
      return null;
    }

    return data.counts as FilterCounts;
  } catch (error) {
    console.error('Error reading valid filter counts from storage:', error);
    return null;
  }
};

// Existing confidence level storage functions
interface ConfidenceStorageEntry {
  confidenceLevel: 'guessing' | 'maybe' | 'confident' | null;
  lastUpdated: string;
}

interface ConfidenceStorageMap {
  [cardId: string]: ConfidenceStorageEntry;
}

export const getConfidenceLevelsFromStorage = (
  userId: string, 
  courseId: string, 
  cardId: string
): 'guessing' | 'maybe' | 'confident' | null => {
  try {
    const key = `${CONFIDENCE_LEVELS_KEY}_${userId}_${courseId}`;
    const stored = localStorage.getItem(key);
    if (!stored) return null;

    const confidenceLevels: ConfidenceStorageMap = JSON.parse(stored);
    return confidenceLevels[cardId]?.confidenceLevel ?? null;
  } catch (error) {
    console.error('Error reading confidence level from storage:', error);
    return null;
  }
};

export const setConfidenceLevelInStorage = (
  userId: string,
  courseId: string,
  cardId: string,
  confidenceLevel: 'guessing' | 'maybe' | 'confident' | null
): void => {
  try {
    const key = `${CONFIDENCE_LEVELS_KEY}_${userId}_${courseId}`;
    const stored = localStorage.getItem(key);
    let confidenceLevels: ConfidenceStorageMap = {};

    if (stored) {
      confidenceLevels = JSON.parse(stored);
    }

    confidenceLevels[cardId] = {
      confidenceLevel,
      lastUpdated: new Date().toISOString()
    };

    localStorage.setItem(key, JSON.stringify(confidenceLevels));
  } catch (error) {
    console.error('Error saving confidence level to storage:', error);
  }
};

// Update the review date for a card
export const updateReviewDate = (
  userId: string,
  courseId: string,
  cardId: string,
  confidenceLevel: ConfidenceLevelValue
): void => {
  try {
    const key = `${REVIEW_DATES_KEY}_${userId}_${courseId}`;
    const stored = localStorage.getItem(key);
    let reviewDates: ReviewDatesMap = {};

    if (stored) {
      reviewDates = JSON.parse(stored);
    }

    const now = new Date();
    const interval = confidenceLevel ? REVIEW_INTERVALS[confidenceLevel] : 1;
    const nextReviewDate = new Date(now);
    nextReviewDate.setDate(nextReviewDate.getDate() + interval);

    reviewDates[cardId] = {
      nextReviewDate: nextReviewDate.toISOString(),
      lastReviewed: now.toISOString(),
      confidenceLevel,
      interval
    };

    localStorage.setItem(key, JSON.stringify(reviewDates));
  } catch (error) {
    console.error('Error saving review date to storage:', error);
  }
};

// Get all due cards from storage
export const getDueCards = (
  userId: string,
  courseId: string
): string[] => {
  try {
    const key = `${REVIEW_DATES_KEY}_${userId}_${courseId}`;
    const stored = localStorage.getItem(key);
    if (!stored) return [];

    const reviewDates: ReviewDatesMap = JSON.parse(stored);
    const now = new Date();

    return Object.entries(reviewDates)
      .filter(([_, entry]) => new Date(entry.nextReviewDate) <= now)
      .map(([cardId]) => cardId);
  } catch (error) {
    console.error('Error reading due cards from storage:', error);
    return [];
  }
};

// Clean up old review dates
export const cleanupReviewDates = (
  userId: string,
  courseId: string,
  maxAgeDays: number = 30
): void => {
  try {
    const key = `${REVIEW_DATES_KEY}_${userId}_${courseId}`;
    const stored = localStorage.getItem(key);
    if (!stored) return;

    const reviewDates: ReviewDatesMap = JSON.parse(stored);
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - maxAgeDays);

    const cleanedDates = Object.entries(reviewDates).reduce((acc, [cardId, entry]) => {
      if (new Date(entry.lastReviewed) > cutoffDate) {
        acc[cardId] = entry;
      }
      return acc;
    }, {} as ReviewDatesMap);

    localStorage.setItem(key, JSON.stringify(cleanedDates));
  } catch (error) {
    console.error('Error cleaning up review dates:', error);
  }
};

// Get detailed study stats
export interface StudyStats {
  totalCards: number;
  dueCards: number;
  completedToday: number;
  averageInterval: number;
}

export const getStudyStats = (
  userId: string,
  courseId: string
): StudyStats => {
  try {
    const key = `${REVIEW_DATES_KEY}_${userId}_${courseId}`;
    const stored = localStorage.getItem(key);
    if (!stored) {
      return {
        totalCards: 0,
        dueCards: 0,
        completedToday: 0,
        averageInterval: 0
      };
    }

    const reviewDates: ReviewDatesMap = JSON.parse(stored);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const entries = Object.values(reviewDates);

    return {
      totalCards: entries.length,
      dueCards: entries.filter(entry => new Date(entry.nextReviewDate) <= now).length,
      completedToday: entries.filter(entry => new Date(entry.lastReviewed) >= today).length,
      averageInterval: entries.reduce((sum, entry) => sum + entry.interval, 0) / entries.length || 0
    };
  } catch (error) {
    console.error('Error calculating study stats:', error);
    return {
      totalCards: 0,
      dueCards: 0,
      completedToday: 0,
      averageInterval: 0
    };
  }
};