import { 
  User as SupabaseUser, 
  Session, 
  AuthChangeEvent, 
  AuthResponse, 
  UserResponse,
  AdminUserAttributes
} from '@supabase/supabase-js';
import { supabase, supabaseAdmin } from './supabase';
import { User, UserRole, SupportUser } from './api';
import { queryClient } from './queryClient';
import { cacheManager } from './api';

export const auth = {
  // Helper function to update role in auth metadata (underscore prefix indicates internal use)
  _updateUserRole: async (userId: string, role: UserRole): Promise<void> => {
    try {
      const { error } = await supabase.auth.admin.updateUserById(userId, {
        user_metadata: { role }
      });
      if (error) throw error;
    } catch (error) {
      console.error('Error updating user role in auth:', error);
      throw error;
    }
  },

  async login(email: string, password: string): Promise<AuthResponse> {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({ 
        email, 
        password 
      });
      
      if (error) throw error;
      
      if (data.user) {
        // Update last sign in
        await this.updateLastSignIn(data.user.id);

        // Fetch user profile to get role
        const userProfile = await this.getUserProfile(data.user.id);
        
        // Update session metadata with role from database
        if (userProfile) {
          await supabase.auth.updateUser({
            data: { role: userProfile.role }
          });
        }
      }
      
      return { data, error };
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  },

  async register(email: string, password: string, fullName: string): Promise<AuthResponse> {
    try {
      // Begin transaction
      const { data, error: authError } = await supabase.auth.signUp({ 
        email, 
        password,
        options: {
          data: {
            full_name: fullName,
            role: 'user' // Default role
          },
          emailRedirectTo: `${window.location.origin}/verify-email`
        }
      });

      if (authError) throw authError;

      if (data.user && !data.user.confirmed_at) {
        // First create the support user
        const { data: supportUser, error: supportError } = await supabase
          .from('support_users')
          .insert({
            email: email,
            full_name: fullName,
            auth_user_id: data.user.id,
            created_at: new Date().toISOString(),
            last_activity: new Date().toISOString()
          })
          .select()
          .single();

        if (supportError) throw supportError;

        // Then create the auth user profile
        const { error: profileError } = await supabase
          .from('users')
          .insert({
            id: data.user.id,
            email: email,
            full_name: fullName,
            role: 'user',
            switches_available: 0,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            last_switch_replenish_date: new Date().toISOString()
          });
          
        if (profileError) {
          // If profile creation fails, attempt to clean up
          await supabase.auth.admin.deleteUser(data.user.id);
          await supabase
            .from('support_users')
            .delete()
            .eq('auth_user_id', data.user.id);
            
          throw profileError;
        }
      }

      return { data, error: null };
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  },
  
  async logout(): Promise<void> {
    try {
      // Clear React Query cache
      queryClient.clear();
      queryClient.removeQueries();

      // Clear cache manager
      cacheManager.clear();

      // Clear Supabase-specific storage
      localStorage.removeItem('supabase.auth.token');
      localStorage.removeItem('sb-access-token');
      localStorage.removeItem('sb-refresh-token');
      
      // Clear auth-related items
      Object.keys(localStorage).forEach(key => {
        if (key.includes('supabase') || 
            key.includes('sb-') || 
            key.includes('auth') || 
            key.includes('userData') || 
            key.includes('userAccess')) {
          localStorage.removeItem(key);
        }
      });

      // Sign out with Supabase
      const { error } = await supabase.auth.signOut();
      if (error) throw error;

      // Clear session storage
      sessionStorage.clear();

      // Set logout success message
      sessionStorage.setItem('authMessage', 'Successfully logged out');

      // Redirect using href instead of replace
      window.location.href = '/login';
    } catch (error) {
      console.error('Error during logout:', error);
      
      // Force cleanup on error
      localStorage.clear();
      sessionStorage.clear();
      queryClient.clear();
      cacheManager.clear();
      
      // Set error message and redirect
      sessionStorage.setItem('authMessage', 'Logged out due to an error');
      window.location.href = '/login';
      throw error;
    }
  },

  async updateProfile(userId: string, updates: Partial<User>): Promise<User> {
    try {
      // Check if user has permission to update role
      const currentUser = await this.getCurrentUser();
      
      if (updates.role && currentUser?.role !== 'admin') {
        throw new Error('Only admins can update roles');
      }

      // Update profile according to RLS policies
      const { data, error } = await supabase
        .from('users')
        .update({
          ...updates,
          updated_at: new Date().toISOString()
        })
        .eq('id', userId)
        .single();
        
      if (error) throw error;

      // If role update succeeded, update session metadata
      if (updates.role && data) {
        await supabase.auth.updateUser({
          data: { role: updates.role }
        });
      }

      return data;
    } catch (error) {
      console.error('Error updating profile:', error);
      throw error;
    }
  },

  async getCurrentUser(): Promise<User | null> {
    try {
      const { data: { user }, error } = await supabase.auth.getUser();
      
      if (error) {
        if (error.name === 'AuthSessionMissingError') {
          return null;
        }
        throw error;
      }

      if (!user) return null;

      // Get user profile according to RLS policies
      const { data: profile, error: profileError } = await supabase
        .from('users')
        .select('*')
        .eq('id', user.id)
        .single();

      if (profileError) throw profileError;

      // Keep session metadata in sync
      if (profile && (!user.user_metadata?.role || user.user_metadata.role !== profile.role)) {
        await supabase.auth.updateUser({
          data: { role: profile.role }
        });
      }

      return profile;
    } catch (error) {
      console.error('Error getting current user:', error);
      return null;
    }
  },

  async getCurrentUserRole(): Promise<string | null> {
    try {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error || !user) return null;

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('role')
        .eq('id', user.id)
        .single();

      if (userError || !userData) return null;
      return userData.role;
    } catch (error) {
      console.error('Error getting user role:', error);
      return null;
    }
  },

  async sendAuthenticationEmail(email: string): Promise<{ data: any; error: Error | null }> {
    try {
      console.log('Starting authentication email process for:', email);

      // Check if the current user has admin/moderator privileges
      const currentUserRole = await this.getCurrentUserRole();
      if (!currentUserRole || (currentUserRole !== 'admin' && currentUserRole !== 'moderator')) {
        throw new Error('Unauthorized: Only admins and moderators can send authentication emails');
      }

      // Check if user already has an auth account
      const { data: existingAuthUser, error: listError } = await supabaseAdmin.auth.admin.listUsers();
      
      if (listError) {
        throw listError;
      }

      // Type guard to ensure email exists
      const hasExistingUser = existingAuthUser?.users.some(user => 
        user && typeof user.email === 'string' && user.email === email
      );

      if (hasExistingUser) {
        throw new Error('User already has an authentication account');
      }

      // Send the invite email
      const { data, error: mailError } = await supabaseAdmin.auth.admin.inviteUserByEmail(email, {
        redirectTo: `${window.location.origin}/signup`,
        data: {
          role: 'user',
          invited_at: new Date().toISOString(),
          invited_by: 'support'
        }
      });

      if (mailError) {
        console.error('Email invitation error:', mailError);
        throw mailError;
      }

      // Update support user record to track invitation
      const { error: updateError } = await supabaseAdmin
        .from('support_users')
        .update({
          invitation_sent_at: new Date().toISOString(),
          last_activity: new Date().toISOString()
        })
        .eq('email', email);

      if (updateError) {
        console.error('Support user update error:', updateError);
        // Don't throw as email was sent successfully
      }

      return {
        data: {
          message: 'Authentication email sent successfully',
        },
        error: null
      };
    } catch (error) {
      console.error('Error sending authentication email:', error);
      return {
        data: null,
        error: error instanceof Error ? error : new Error('Failed to send authentication email')
      };
    }
  },

  async resendAuthenticationEmail(email: string): Promise<{ data: any; error: Error | null }> {
    try {
      // Verify admin/moderator privileges
      const currentUserRole = await this.getCurrentUserRole();
      if (!currentUserRole || (currentUserRole !== 'admin' && currentUserRole !== 'moderator')) {
        throw new Error('Unauthorized: Only admins and moderators can resend authentication emails');
      }

      // Resend the invite email
      const { data, error: resendError } = await supabaseAdmin.auth.admin.inviteUserByEmail(email, {
        redirectTo: `${window.location.origin}/signup`,
        data: {
          role: 'user',
          invited_at: new Date().toISOString(),
          invited_by: 'support'
        }
      });

      if (resendError) throw resendError;

      // Update support user record
      await supabaseAdmin
        .from('support_users')
        .update({
          invitation_sent_at: new Date().toISOString(),
          last_activity: new Date().toISOString()
        })
        .eq('email', email);

      return {
        data: {
          message: 'Authentication email resent successfully'
        },
        error: null
      };
    } catch (error) {
      console.error('Error resending authentication email:', error);
      return {
        data: null,
        error: error instanceof Error ? error : new Error('Failed to resend authentication email')
      };
    }
  },

  async inviteUnregisteredUser(email: string): Promise<{ data: any; error: Error | null }> {
    try {
      console.log('Starting invitation process for email:', email);

      // Check if the current user has admin/moderator privileges
      const currentUserRole = await this.getCurrentUserRole();
      if (!currentUserRole || (currentUserRole !== 'admin' && currentUserRole !== 'moderator')) {
        throw new Error('Unauthorized: Only admins and moderators can send invitations');
      }

      // Check if user already exists
      const { data: existingUser } = await supabase
        .from('users')
        .select('id, email')
        .eq('email', email)
        .single();

      if (existingUser) {
        throw new Error('User already exists in the system');
      }

      // Create the user with signUp instead of invite
      const { data, error: signUpError } = await supabase.auth.signUp({
        email,
        password: Math.random().toString(36).slice(-12), // Temporary password
        options: {
          emailRedirectTo: `${window.location.origin}/signup`,
          data: {
            role: 'user'
          }
        }
      });

      if (signUpError) {
        console.error('Signup error:', signUpError);
        throw signUpError;
      }

      if (!data.user) {
        throw new Error('Failed to create user');
      }

      // Create user record in public.users table
      const { error: userError } = await supabase
        .from('users')
        .insert({
          id: data.user.id,
          email: email,
          role: 'user',
          full_name: email.split('@')[0],
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          switches_available: 0,
          last_switch_replenish_date: new Date().toISOString()
        });

      if (userError) {
        console.error('Error creating user record:', userError);
        // Try to clean up auth user if user record creation fails
        await supabase.auth.admin.deleteUser(data.user.id);
        throw userError;
      }

      // Update support user record if needed
      const { error: supportUserError } = await supabase
        .from('support_users')
        .update({
          auth_user_id: data.user.id,
          invitation_sent_at: new Date().toISOString()
        })
        .eq('email', email);

      if (supportUserError) {
        console.error('Support user update error:', supportUserError);
        // Don't throw as core user creation succeeded
      }

      console.log('User created successfully');
      return { 
        data: { 
          message: 'User created successfully. Verification email sent.',
          user: data.user,
          requiresVerification: true
        }, 
        error: null 
      };
    } catch (error) {
      console.error('Error in inviteUnregisteredUser:', error);
      return { 
        data: null, 
        error: error instanceof Error ? error : new Error('Failed to create user') 
      };
    }
  },

  // Helper to update user role - respects RLS policies
  async updateUserRole(userId: string, newRole: UserRole): Promise<void> {
    try {
      // Update public.users role using service role client
      const { error: userError } = await supabase
        .from('users')
        .update({ 
          role: newRole,
          updated_at: new Date().toISOString()
        })
        .eq('id', userId);

      if (userError) throw userError;

      // Update auth metadata to match
      const { error: authError } = await supabase.auth.admin.updateUserById(
        userId,
        { user_metadata: { role: newRole } }
      );

      if (authError) throw authError;
    } catch (error) {
      console.error('Error updating user role:', error);
      throw error;
    }
  },

  // Update ensureUserProfile to handle invited users
  async ensureUserProfile(userId: string, email: string): Promise<void> {
    try {
      // Check if profile exists
      const { data: existingProfile } = await supabase
        .from('users')
        .select('id')
        .eq('id', userId)
        .single();

      if (existingProfile) {
        console.log('User profile already exists');
        return;
      }

      console.log('Creating new user profile');
      
      // Create user profile if it doesn't exist
      const { error: profileError } = await supabase
        .from('users')
        .insert({
          id: userId,
          email: email,
          full_name: email.split('@')[0], // Default name from email
          role: 'user',
          switches_available: 0,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          last_switch_replenish_date: new Date().toISOString()
        });

      if (profileError) throw profileError;

      console.log('User profile created successfully');
    } catch (error) {
      console.error('Error ensuring user profile:', error);
      throw error;
    }
  },

  // Add a method to handle password setup
  async completeUserSetup(userId: string, password: string): Promise<{ error: Error | null }> {
    try {
      // Update the user's password
      const { error: updateError } = await supabase.auth.updateUser({
        password: password
      });

      if (updateError) throw updateError;

      // Ensure the session is persisted
      const { error: sessionError } = await supabase.auth.refreshSession();
      if (sessionError) throw sessionError;

      return { error: null };
    } catch (error) {
      console.error('Error completing user setup:', error);
      return { 
        error: error instanceof Error ? error : new Error('Failed to complete user setup') 
      };
    }
  },

  // Update the session refresh method
  async refreshSession(): Promise<{ session: Session | null; error: Error | null }> {
    try {
      const { data, error } = await supabase.auth.refreshSession();
      if (error) throw error;

      return { session: data.session, error: null };
    } catch (error) {
      console.error('Error refreshing session:', error);
      return { 
        session: null, 
        error: error instanceof Error ? error : new Error('Failed to refresh session') 
      };
    }
  },

  async verifyEmail(token: string): Promise<{ data: { user: SupabaseUser | null; session: Session | null } | null; error: Error | null }> {
    try {
      const { data, error } = await supabase.auth.verifyOtp({ 
        token_hash: token, 
        type: 'signup' 
      });
      
      if (error) throw error;

      // After verification, ensure role is set in auth metadata
      if (data?.user) {
        const profile = await this.getUserProfile(data.user.id);
        if (profile && (!data.user.user_metadata?.role || data.user.user_metadata.role !== profile.role)) {
          await this._updateUserRole(data.user.id, profile.role);
        }
      }

      console.log('Email verified successfully');
      return { data, error: null };
    } catch (error) {
      console.error('Verification error:', error);
      return { data: null, error: error as Error };
    }
  },

  // Keeping existing methods unchanged
  async getSession(): Promise<Session | null> {
    try {
      const { data, error } = await supabase.auth.getSession();
      if (error) throw error;
      return data.session;
    } catch (error) {
      console.error('Error getting session:', error);
      return null;
    }
  },

  async resetPassword(email: string): Promise<void> {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`,
    });
    if (error) throw error;
  },

  async updatePassword(newPassword: string): Promise<UserResponse> {
    const { data, error } = await supabase.auth.updateUser({ 
      password: newPassword 
    });
    if (error) throw error;
    return { data, error };
  },

  async updateLastSignIn(userId: string): Promise<void> {
    const { error } = await supabase
      .from('users')
      .update({ 
        last_sign_in_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      })
      .eq('id', userId);
      
    if (error) console.error('Error updating last sign in:', error);
  },

  async sendVerificationEmail(email: string): Promise<void> {
    const { error } = await supabase.auth.resend({
      type: 'signup',
      email: email,
      options: {
        emailRedirectTo: `${window.location.origin}/verify-email`
      }
    });
    if (error) throw error;
  },

  async getUserProfile(userId: string): Promise<User> {
    const { data, error } = await supabase
      .from('users')
      .select('*')
      .eq('id', userId)
      .single();
      
    if (error) throw error;
    return data;
  },

  // New method to get all admin users
  async getAdminUsers(): Promise<User[]> {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('role', 'admin')
        .order('full_name');

      if (error) throw error;
      return data || [];
    } catch (error) {
      console.error('Error fetching admin users:', error);
      throw error;
    }
  },

  onAuthStateChange(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return supabase.auth.onAuthStateChange(callback);
  }
};