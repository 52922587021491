import { useQuery } from 'react-query';
import { api, QuizHistory } from '../services/api';

// Types
export type TestletType = 
  | 'mock_exam' 
  | 'study_task' 
  | 'simulation' 
  | 'MCQ' 
  | 'loop'
  | 'learn'
  | 'funnel'
  | 'retake_funnel'
  | null;

export interface ActiveQuizState {
  activeQuiz: QuizHistory | null;
  activeTestletType: TestletType;
  showActiveQuizModal: boolean;
}

interface UseActiveQuizOptions {
  enabled?: boolean;
  onSuccess?: (quiz: QuizHistory | null) => void;
}

// Utility functions
export const getTestletType = (quiz: QuizHistory): TestletType => {
  if (!quiz.questions_data || quiz.questions_data.length === 0) {
    return null;
  }

  const firstQuestion = quiz.questions_data[0];
  
  if (firstQuestion.type === 'sp_studytasktestlet') {
    const testletType = quiz.study_task_testlet_type;
    if (testletType) {
      switch (testletType) {
        case 'loop': return 'loop';
        case 'learn': return 'learn';
        case 'funnel': return 'funnel';
        case 'retake_funnel': return 'retake_funnel';
        default: return 'study_task';
      }
    }
    return 'study_task';
  }

  if (firstQuestion.type === 'simulation' || firstQuestion.type === 'mock_exam_sim') {
    return 'simulation';
  }
  if (firstQuestion.type === 'mock_exam_mcq') {
    return 'mock_exam';
  }
  if (firstQuestion.type === 'mcq') {
    return 'MCQ';
  }
  return null;
};

export const isValidActiveQuiz = (quiz: QuizHistory): boolean => {
  if (!quiz.questions_data || quiz.questions_data.length === 0) {
    return false;
  }

  if (quiz.completed || quiz.end_time) {
    return false;
  }

  const firstQuestion = quiz.questions_data[0];
  const validTypes = [
    'simulation', 
    'mock_exam_sim', 
    'mock_exam_mcq', 
    'mcq', 
    'sp_studytasktestlet'
  ];
  return validTypes.includes(firstQuestion.type);
};

export const useActiveQuiz = (
  userId: string | undefined, 
  courseId: string,
  options: UseActiveQuizOptions = {}
) => {
  const queryKey = ['activeQuiz', userId, courseId];
  
  const query = useQuery(
    queryKey,
    async () => {
      if (!userId) return null;
      return api.getActiveQuiz(userId, courseId);
    },
    {
      enabled: false, // Disable automatic fetching
      staleTime: Infinity, // Prevent automatic refetching
      cacheTime: 0, // Don't cache results
      retry: false,
      onSuccess: options.onSuccess,
    }
  );

  // Add manual refetch method
  const checkForActiveQuiz = async () => {
    return query.refetch();
  };

  return {
    ...query,
    checkForActiveQuiz,
  };
};