import React, { createContext, useContext, useMemo, useCallback, useRef } from 'react';
import { useQuery, UseQueryResult, QueryClient, QueryClientProvider } from 'react-query';
import { useAuth } from '../components/AuthProvider';
import { UserCourseAccess, UserCoursePurchase, User, TIER_IDS } from '../services/api';
import { userDataService } from '../services/userDataService';

interface UserCourseAccessContextType {
  userCourseAccesses: UserCourseAccess[];
  userCoursePurchases: UserCoursePurchase[];
  userData: User | null;
  hasActivePurchase: boolean;
  canSwitchToPaidCourse: boolean;
  isLoading: boolean;
  error: unknown;
  refetch: () => Promise<void>;
}

const UserCourseAccessContext = createContext<UserCourseAccessContextType | undefined>(undefined);

// Constants
const STALE_TIME = 5 * 60 * 1000; // 5 minutes
const CACHE_TIME = 10 * 60 * 1000; // 10 minutes
const REFETCH_INTERVAL = 5 * 60 * 1000; // 5 minutes
const ACTIVE_ROUTES = ['/quiz', '/mock-exam', '/simulations'] as const;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

// Helper to check if user is in an active quiz/exam session
const isInActiveSession = (): boolean => {
  const currentPath = window.location.pathname;
  return ACTIVE_ROUTES.some(route => currentPath.includes(route));
};

interface UserCourseData {
  user: User | null;
  userCoursePurchases: UserCoursePurchase[];
  userCourseAccesses: UserCourseAccess[];
}

export const UserCourseAccessProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const lastFetchTimeRef = useRef<number>(0);
  const activeSessionRef = useRef(isInActiveSession());

  const fetchUserCourseData = useCallback(async (): Promise<UserCourseData> => {
    if (!user) {
      throw new Error('No authenticated user');
    }

    // Check if we should skip fetching due to active session
    if (activeSessionRef.current && Date.now() - lastFetchTimeRef.current < CACHE_TIME) {
      const cachedData = queryClient.getQueryData<UserCourseData>(['userCourseData', user.id]);
      if (cachedData) {
        return cachedData;
      }
    }

    const userData = await userDataService.getAllUserData(user.id);
    lastFetchTimeRef.current = Date.now();
    
    return {
      user: userData.user,
      userCoursePurchases: userData.coursePurchases,
      userCourseAccesses: userData.courseAccesses
    };
  }, [user]);

  const { data, isLoading, error, refetch }: UseQueryResult<UserCourseData, unknown> = useQuery(
    ['userCourseData', user?.id],
    fetchUserCourseData,
    {
      enabled: !!user,
      refetchInterval: activeSessionRef.current ? false : REFETCH_INTERVAL,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        console.error('Error fetching user course data:', error);
        // Keep stale data on error during active session
        if (activeSessionRef.current) {
          return queryClient.getQueryData(['userCourseData', user?.id]);
        }
      }
    }
  );

  const safeRefetch = useCallback(async () => {
    if (user && !activeSessionRef.current) {
      try {
        await refetch();
        lastFetchTimeRef.current = Date.now();
      } catch (error) {
        console.error('Error refetching user course data:', error);
      }
    }
  }, [user, refetch]);

  const contextValue = useMemo(() => {
    const hasActivePurchase = data?.userCoursePurchases.some(purchase => 
      (purchase.tier_id === TIER_IDS.FULL_ACCESS || 
       purchase.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY) &&
      purchase.is_active && 
      (purchase.subscription_status === 'active' || purchase.subscription_status === null)
    ) ?? false;

    const canSwitchToPaidCourse = !!data?.user && 
      typeof data.user.switches_available === 'number' && 
      data.user.switches_available > 0;

    return {
      userCourseAccesses: data?.userCourseAccesses ?? [],
      userCoursePurchases: data?.userCoursePurchases ?? [],
      userData: data?.user ?? null,
      hasActivePurchase,
      canSwitchToPaidCourse,
      isLoading,
      error,
      refetch: safeRefetch,
    };
  }, [data, isLoading, error, safeRefetch]);

  return (
    <QueryClientProvider client={queryClient}>
      <UserCourseAccessContext.Provider value={contextValue}>
        {children}
      </UserCourseAccessContext.Provider>
    </QueryClientProvider>
  );
};

export const useUserCourseAccess = (): UserCourseAccessContextType => {
  const context = useContext(UserCourseAccessContext);
  if (context === undefined) {
    throw new Error('useUserCourseAccess must be used within a UserCourseAccessProvider');
  }
  return context;
};