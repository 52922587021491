import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../../../components/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../../services/supabase';
import { 
  AlertCircle,
  Inbox,
  List,
  Plus,
  User,
} from 'lucide-react';
import { Button } from '../../ui/Button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/Tabs';
import { Card } from '../../ui/Card';
import TicketList from './TicketList';
import TicketDetail from './TicketDetail';
import CustomerSidebar from './CustomerSidebar';
import EmailComposer from './EmailComposer';
import { 
  ContentType,
  Ticket,
} from '../../../services/api';

interface SupportStats {
  open: number;
  pending: number;
  closed: number;
  total: number;
  urgentCount: number;
  unassignedCount: number;
  avgResponseTime: number;
}

const SupportDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [showCustomerSidebar, setShowCustomerSidebar] = useState(true);
  const [stats, setStats] = useState<SupportStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('all');
  const [isCreatingTicket, setIsCreatingTicket] = useState(false);
  const [newTicketEmail, setNewTicketEmail] = useState('');
  const [listRefreshKey, setListRefreshKey] = useState(0);

  const ticketSubscriptionRef = useRef<ReturnType<typeof supabase.channel> | null>(null);
  const messageSubscriptionRef = useRef<ReturnType<typeof supabase.channel> | null>(null);

  const handleNavigateToContent = useCallback((contentType: ContentType, contentId: string) => {
    // First, fetch the content item to get its course_id
    const fetchContentDetails = async () => {
      const tableName = {
        questions: 'questions',
        lessons: 'lessons',
        simulations: 'simulations',
        flashcards: 'flashcards'
      }[contentType];

      try {
        const { data, error } = await supabase
          .from(tableName)
          .select('course_id')
          .eq('id', contentId)
          .single();

        if (error) throw error;
        if (!data) throw new Error('Content not found');

        // Construct the appropriate route based on content type
        const routes = {
          questions: `/course/${data.course_id}/questions/${contentId}/preview`,
          lessons: `/course/${data.course_id}/lessons/${contentId}/preview`,
          simulations: `/course/${data.course_id}/simulations/${contentId}/preview`,
          flashcards: `/course/${data.course_id}/kesler-cards/${contentId}/preview`
        };

        const route = routes[contentType];
        if (route) {
          navigate(route);
        }
      } catch (error) {
        console.error('Error navigating to content:', error);
      }
    };

    fetchContentDetails();
  }, [navigate]);

  // Memoize loadDashboardStats to prevent unnecessary recreations
  const loadDashboardStats = useCallback(async () => {
    try {
      setLoading(true);
  
      const { data: statsData, error: statsError } = await supabase
        .from('support_tickets')
        .select(`
          id,
          status,
          priority,
          admin_id,
          created_at,
          support_messages (
            created_at
          )
        `)
        .order('created_at', { ascending: false });
  
      if (statsError) throw statsError;
  
      if (statsData) {
        const openCount = statsData.filter(t => t.status === 'open').length;
        const pendingCount = statsData.filter(t => t.status === 'pending').length;
        const closedCount = statsData.filter(t => t.status === 'closed').length;
        const urgentCount = statsData.filter(t => t.priority === 'urgent').length;
        const unassignedCount = statsData.filter(t => !t.admin_id).length;
  
        let totalResponseTime = 0;
        let ticketsWithResponse = 0;
  
        statsData.forEach(ticket => {
          if (ticket.support_messages && ticket.support_messages.length > 1) {
            const firstResponse = new Date(ticket.support_messages[1].created_at);
            const created = new Date(ticket.created_at);
            totalResponseTime += firstResponse.getTime() - created.getTime();
            ticketsWithResponse++;
          }
        });
  
        setStats({
          open: openCount,
          pending: pendingCount,
          closed: closedCount,
          total: statsData.length,
          urgentCount,
          unassignedCount,
          avgResponseTime: ticketsWithResponse ? totalResponseTime / ticketsWithResponse : 0
        });
      }
    } catch (error) {
      console.error('Error loading dashboard stats:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Setup subscriptions once on mount
  useEffect(() => {
    if (!user?.id) return;

    loadDashboardStats();

    const ticketChannel = supabase.channel('dashboard-tickets');
    const messageChannel = supabase.channel('dashboard-messages');

    ticketSubscriptionRef.current = ticketChannel
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'support_tickets'
      }, (payload) => {
        if (!selectedTicket && !isCreatingTicket) {
          loadDashboardStats();
          setListRefreshKey(prev => prev + 1);
        }
      })
      .subscribe();

    messageSubscriptionRef.current = messageChannel
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'support_messages'
      }, (payload) => {
        if (!selectedTicket && !isCreatingTicket) {
          loadDashboardStats();
          setListRefreshKey(prev => prev + 1);
        }
      })
      .subscribe();

    return () => {
      if (ticketSubscriptionRef.current) {
        ticketSubscriptionRef.current.unsubscribe();
      }
      if (messageSubscriptionRef.current) {
        messageSubscriptionRef.current.unsubscribe();
      }
    };
  }, [user?.id]);

  const handleTicketCreated = useCallback(() => {
    setIsCreatingTicket(false);
    setNewTicketEmail('');
    loadDashboardStats();
    setListRefreshKey(prev => prev + 1);
  }, [loadDashboardStats]);

  // Type-safe handlers
  const handleTicketSelect = useCallback((ticket: Ticket) => {
    setSelectedTicket(ticket);
    setIsCreatingTicket(false);
    setShowCustomerSidebar(true);
  }, []);

  const handleTicketUpdate = useCallback(async (status: string) => {
    try {
      setSelectedTicket(null);
      await loadDashboardStats();
      setListRefreshKey(prev => prev + 1);
      setShowCustomerSidebar(true);
      setIsCreatingTicket(false);
    } catch (error) {
      console.error('Error updating ticket status:', error);
    }
  }, [loadDashboardStats]);

  const handleCreateNewTicket = useCallback(() => {
    setSelectedTicket(null);
    setIsCreatingTicket(true);
    setNewTicketEmail('');
  }, []);

  const handleRefreshData = useCallback((status?: string) => {
    loadDashboardStats();
    setListRefreshKey(prev => prev + 1);
  }, [loadDashboardStats]);

  return (
    <div className="h-full flex flex-col bg-gray-50 dark:bg-gray-900">
      {/* Header with stats */}
      {!selectedTicket && !isCreatingTicket && (
        <div className="flex-none bg-white dark:bg-gray-900">
          <div className="flex justify-between items-center h-14 md:h-16 px-3 md:px-6 border-b dark:border-gray-700 bg-white dark:bg-gray-800">
            <h1 className="text-lg md:text-2xl font-bold">Support Dashboard</h1>
            <Button
              onClick={handleCreateNewTicket}
              className="h-8 md:h-10 text-sm md:text-base flex items-center"
            >
              <Plus className="w-4 h-4 mr-1 md:mr-2" />
              <span className="hidden md:inline">New Ticket</span>
              <span className="md:hidden">New</span>
            </Button>
          </div>
  
          <div className="px-3 md:px-6 py-3 md:py-4 bg-white dark:bg-gray-800 border-b dark:border-gray-700">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4">
              <Card className="bg-blue-50 dark:bg-blue-900/20 p-3 md:p-4">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-sm md:text-lg font-semibold text-blue-600 dark:text-blue-400">Open</h3>
                    <p className="text-xl md:text-2xl font-bold">{stats?.open || 0}</p>
                  </div>
                  <Inbox className="w-5 h-5 md:w-6 md:h-6 text-blue-500" />
                </div>
              </Card>
  
              <Card className="bg-yellow-50 dark:bg-yellow-900/20 p-3 md:p-4">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-sm md:text-lg font-semibold text-yellow-600 dark:text-yellow-400">Pending</h3>
                    <p className="text-xl md:text-2xl font-bold">{stats?.pending || 0}</p>
                  </div>
                  <List className="w-5 h-5 md:w-6 md:h-6 text-yellow-500" />
                </div>
              </Card>
  
              <Card className="bg-red-50 dark:bg-red-900/20 p-3 md:p-4">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-sm md:text-lg font-semibold text-red-600 dark:text-red-400">Urgent</h3>
                    <p className="text-xl md:text-2xl font-bold">{stats?.urgentCount || 0}</p>
                  </div>
                  <AlertCircle className="w-5 h-5 md:w-6 md:h-6 text-red-500" />
                </div>
              </Card>
  
              <Card className="bg-purple-50 dark:bg-purple-900/20 p-3 md:p-4">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-sm md:text-lg font-semibold text-purple-600 dark:text-purple-400">Unassigned</h3>
                    <p className="text-xl md:text-2xl font-bold">{stats?.unassignedCount || 0}</p>
                  </div>
                  <User className="w-5 h-5 md:w-6 md:h-6 text-purple-500" />
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
  
      {/* Main content area */}
      <div className="flex-1 min-h-0 p-3 md:p-6">
        <div className="h-full flex gap-3 md:gap-6">
          {/* Ticket List Section */}
          <div className={`transition-all duration-300 ease-in-out h-full ${
            selectedTicket || isCreatingTicket
              ? 'hidden md:block md:w-1/4 md:min-w-[300px]'
              : 'w-full'
          }`}>
            <Tabs value={currentTab} onValueChange={setCurrentTab} className="h-full flex flex-col">
              <TabsList className="flex-none w-full h-9 md:h-10 bg-white dark:bg-gray-800 rounded-none border-b dark:border-gray-700">
                <TabsTrigger value="all" className="text-xs md:text-sm">All</TabsTrigger>
                <TabsTrigger value="mine" className="text-xs md:text-sm">My Tickets</TabsTrigger>
                <TabsTrigger value="urgent" className="text-xs md:text-sm">Urgent</TabsTrigger>
              </TabsList>
  
              <div className="flex-1 min-h-0 bg-white dark:bg-gray-800 rounded-b-lg">
                <TabsContent value="all" className="h-full m-0">
                  <TicketList
                    key={`all-${listRefreshKey}`}
                    filter="all"
                    onTicketSelect={handleTicketSelect}
                    selectedTicketId={selectedTicket?.id}
                  />
                </TabsContent>
                <TabsContent value="mine" className="h-full m-0">
                  <TicketList
                    key={`mine-${listRefreshKey}`}
                    filter="mine"
                    onTicketSelect={handleTicketSelect}
                    selectedTicketId={selectedTicket?.id}
                  />
                </TabsContent>
                <TabsContent value="urgent" className="h-full m-0">
                  <TicketList
                    key={`urgent-${listRefreshKey}`}
                    filter="urgent"
                    onTicketSelect={handleTicketSelect}
                    selectedTicketId={selectedTicket?.id}
                  />
                </TabsContent>
              </div>
            </Tabs>
          </div>
  
          {/* Ticket Detail and Sidebar Section */}
          {(selectedTicket || isCreatingTicket) && (
            <div className="flex-1 min-h-0">
              <div className="flex flex-col md:flex-row gap-3 md:gap-6 h-full">
                {/* Ticket Detail Section */}
                <div className="flex-1 min-h-0 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
                  {isCreatingTicket ? (
                    <EmailComposer
                      ticketId="new"
                      recipientEmail={newTicketEmail}
                      onTicketCreated={handleTicketCreated}
                      onRecipientChange={setNewTicketEmail}
                      requireUserConfirmation={true}
                    />
                  ) : (
                    <TicketDetail
                      ticketId={selectedTicket!.id}
                      onBack={() => {
                        setSelectedTicket(null);
                        loadDashboardStats();
                      }}
                      onStatusChange={handleTicketUpdate}
                    />
                  )}
                </div>

                {/* Customer Sidebar */}
                {showCustomerSidebar && selectedTicket && (
                  <div className="w-full md:w-1/3 md:flex-none md:min-w-[300px] bg-white dark:bg-gray-800 rounded-lg shadow-sm">
                    <CustomerSidebar
                      userId={selectedTicket.support_user_id}
                      contentType={selectedTicket.content_type || undefined}
                      contentId={selectedTicket.content_id}
                      onRefreshData={handleRefreshData}
                      onNavigateToContent={handleNavigateToContent}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SupportDashboard;