import { useState, useEffect, useCallback, useRef } from 'react';
import { 
  setFilterCountsInStorage, 
  FilterCounts
} from '../utils/FlashcardUtils';
import { 
  getCardsForSpacedRepetition,
  updateCardReviewSchedule,
  DueCardInfo
} from '../utils/spaced-repetition-utils';
import { Flashcard, CustomFlashcard, UserFlashcardProgress } from '../services/api';
import type {
  ConfidenceLevelValue,
  FilterType,
  FilterableCard
} from '../services/api';

// Define a more specific type for cards with confidence
interface FlashcardWithConfidence extends Flashcard {
  confidence_level: ConfidenceLevelValue;
}

/**
 * Props for the useFlashcardCounts hook.
 */
interface UseFlashcardCountsProps {
  userId: string;
  courseId: string;
  regularCards: Flashcard[];
  customCards: CustomFlashcard[];
  userProgress: UserFlashcardProgress[];
  selectedCategory: string | null;
  confidenceFilter: FilterType;
  isCreateMode: boolean;
  isRandomMode: boolean;
  isSpacedRepetitionMode: boolean;
  selectedTags: string[];
}

/**
 * The return type of the useFlashcardCounts hook.
 */
interface UseFlashcardCountsResult {
  counts: FilterCounts;
  filteredCards: FilterableCard[];
  dueCards: DueCardInfo[];
  isCalculating: boolean;
  getFlashcardCount: (filter: string) => number;
  updateFlashcardCount: (
    cardId: string, 
    oldLevel: ConfidenceLevelValue, 
    newLevel: ConfidenceLevelValue,
    isCustomCard: boolean
  ) => { wasRemoved: boolean; currentIndex: number };
}

const initialFilterCounts: FilterCounts = {
  all: 0,
  unanswered: 0,
  guessing: 0,
  maybe: 0,
  confident: 0,
  createcards: 0
};

/**
 * This hook filters the entire deck of flashcards (regular + custom)
 * and returns a sorted or shuffled list, depending on user settings (random/spaced).
 * It also returns numeric counts for each filter category.
 */
export const useFlashcardCounts = ({
  userId,
  courseId,
  regularCards,
  customCards,
  userProgress,
  selectedCategory,
  confidenceFilter,
  isCreateMode,
  isRandomMode,
  isSpacedRepetitionMode,
  selectedTags
}: UseFlashcardCountsProps): UseFlashcardCountsResult => {
  const [counts, setCounts] = useState<FilterCounts>(initialFilterCounts);
  const [filteredCards, setFilteredCards] = useState<FilterableCard[]>([]);
  const [dueCards, setDueCards] = useState<DueCardInfo[]>([]);
  const [isCalculating, setIsCalculating] = useState(false);
  const randomOrderRef = useRef<string[]>([]);

  const getStoredConfidenceLevel = useCallback(
    (card: FilterableCard): ConfidenceLevelValue => {
      if ('side_1_content' in card) {
        const progress = userProgress.find(p => p.flashcard_id === card.id);
        return progress?.confidence_level ?? null;
      } else {
        return card.confidence_level;
      }
    },
    [userProgress]
  );

  const buildInitialRandomOrder = useCallback((cards: FilterableCard[]) => {
    const newIds = cards.map(c => c.id);
    for (let i = newIds.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newIds[i], newIds[j]] = [newIds[j], newIds[i]];
    }
    return newIds;
  }, []);

  const filterBase = useCallback((): FilterableCard[] => {
    let cardsToFilter: FilterableCard[] = [];

    if (isSpacedRepetitionMode) {
      const dueCardsInfo = getCardsForSpacedRepetition(
        regularCards,
        customCards,
        userProgress
      );
      setDueCards(dueCardsInfo);
      cardsToFilter = dueCardsInfo.map(dc => ({
        ...dc.card,
        confidence_level: dc.confidenceLevel // Already set correctly
      }));
    } else {
      const regularWithConfidence: FlashcardWithConfidence[] = regularCards.map(card => ({
        ...card,
        confidence_level: getStoredConfidenceLevel(card)
      }));
      cardsToFilter = [...regularWithConfidence, ...customCards];
    }

    if (selectedCategory) {
      cardsToFilter = cardsToFilter.filter(card => 
        'flash_card_category_id' in card && 
        card.flash_card_category_id === selectedCategory
      );
    }

    if (isCreateMode) {
      cardsToFilter = customCards;
      if (selectedTags.length > 0) {
        cardsToFilter = cardsToFilter.filter(card => 
          'tags' in card && selectedTags.some(t => card.tags.includes(t))
        );
      }
    }

    if (!isSpacedRepetitionMode && confidenceFilter !== 'All' && confidenceFilter !== 'CreateCards') {
      cardsToFilter = cardsToFilter.filter(card => {
        const stored = getStoredConfidenceLevel(card);
        if (confidenceFilter === 'Unanswered') {
          return stored === null;
        }
        return stored?.toLowerCase() === confidenceFilter.toLowerCase();
      });
    }

    return cardsToFilter;
  }, [
    isSpacedRepetitionMode,
    regularCards,
    customCards,
    userProgress,
    selectedCategory,
    isCreateMode,
    selectedTags,
    confidenceFilter,
    getStoredConfidenceLevel
  ]);

  const applyRandomOrder = useCallback(
    (baseCards: FilterableCard[]): FilterableCard[] => {
      if (!isRandomMode || isSpacedRepetitionMode) {
        return baseCards;
      }

      if (
        randomOrderRef.current.length !== baseCards.length ||
        !randomOrderRef.current.every(id => baseCards.some(card => card.id === id))
      ) {
        randomOrderRef.current = buildInitialRandomOrder(baseCards);
      }

      const orderMap = new Map<string, FilterableCard>();
      baseCards.forEach(card => {
        orderMap.set(card.id, card);
      });

      return randomOrderRef.current
        .map(id => orderMap.get(id))
        .filter((card): card is FilterableCard => !!card);
    },
    [isRandomMode, isSpacedRepetitionMode, buildInitialRandomOrder]
  );

  const calculateCounts = useCallback(
    (cards: FilterableCard[]): FilterCounts => {
      const newCounts: FilterCounts = {
        all: cards.length,
        unanswered: 0,
        guessing: 0,
        maybe: 0,
        confident: 0,
        createcards: customCards.length
      };

      cards.forEach(card => {
        const cLevel = getStoredConfidenceLevel(card);
        if (!cLevel) {
          newCounts.unanswered++;
        } else {
          newCounts[cLevel]++;
        }
      });

      return newCounts;
    },
    [customCards.length, getStoredConfidenceLevel]
  );

  useEffect(() => {
    setIsCalculating(true);

    const base = filterBase();
    const final = applyRandomOrder(base);

    const newCounts = calculateCounts(final);
    setFilteredCards(final);
    setCounts(newCounts);

    setFilterCountsInStorage(userId, courseId, newCounts);

    setIsCalculating(false);
  }, [
    filterBase,
    applyRandomOrder,
    calculateCounts,
    userId,
    courseId
  ]);

  const getFlashcardCount = useCallback(
    (filter: string): number => {
      return counts[filter.toLowerCase() as keyof FilterCounts] || 0;
    },
    [counts]
  );

  const updateFlashcardCount = useCallback(
    (
      cardId: string, 
      oldLevel: ConfidenceLevelValue, 
      newLevel: ConfidenceLevelValue,
      isCustomCard: boolean
    ): { wasRemoved: boolean; currentIndex: number } => {
      let cardRemoved = false;
      let currentIndex = -1;

      // First update dueCards if in spaced repetition mode
      if (isSpacedRepetitionMode) {
        setDueCards(prev => prev.filter(dc => dc.card.id !== cardId));
      }

      // Update the counts
      setCounts(prev => {
        const newCounts = { ...prev };
        
        // In spaced repetition mode, always decrement total and specific level
        if (isSpacedRepetitionMode) {
          newCounts.all = Math.max(0, newCounts.all - 1);
          if (oldLevel) {
            const oldKey = oldLevel.toLowerCase() as keyof FilterCounts;
            newCounts[oldKey] = Math.max(0, newCounts[oldKey] - 1);
          }
        } else {
          // Regular mode - update counts as before
          if (oldLevel === null) {
            newCounts.unanswered = Math.max(0, newCounts.unanswered - 1);
          } else if (oldLevel) {
            const oldKey = oldLevel.toLowerCase() as keyof FilterCounts;
            newCounts[oldKey] = Math.max(0, newCounts[oldKey] - 1);
          }

          if (newLevel === null) {
            newCounts.unanswered++;
          } else {
            const newKey = newLevel.toLowerCase() as keyof FilterCounts;
            newCounts[newKey]++;
          }
        }

        setFilterCountsInStorage(userId, courseId, newCounts);
        return newCounts;
      });

      // Handle filtered cards update
      setFilteredCards(prev => {
        const cardIndex = prev.findIndex(card => card.id === cardId);
        if (cardIndex === -1) return prev;

        let updatedCards = [...prev];
        
        if (isSpacedRepetitionMode) {
          // Always remove the card in spaced repetition mode
          updatedCards = prev.filter(card => card.id !== cardId);
          cardRemoved = true;
          currentIndex = Math.min(cardIndex, updatedCards.length - 1);
        } else {
          // Regular mode logic remains the same
          updatedCards = prev.map(card => {
            if (card.id === cardId) {
              if (isCustomCard) {
                return {
                  ...card,
                  confidence_level: newLevel,
                  last_reviewed: new Date().toISOString()
                } as CustomFlashcard;
              } else {
                return {
                  ...card,
                  confidence_level: newLevel
                } as FlashcardWithConfidence;
              }
            }
            return card;
          });

          const shouldRemove = confidenceFilter !== 'All' && 
                             confidenceFilter !== 'CreateCards' &&
                             confidenceFilter.toLowerCase() !== newLevel?.toLowerCase();

          if (shouldRemove) {
            updatedCards = updatedCards.filter(card => card.id !== cardId);
            cardRemoved = true;
            currentIndex = Math.min(cardIndex, updatedCards.length - 1);
          } else {
            currentIndex = cardIndex;
          }
        }

        return updatedCards;
      });

      return { 
        wasRemoved: cardRemoved, 
        currentIndex: currentIndex >= 0 ? currentIndex : 0 
      };
    },
    [userId, courseId, confidenceFilter, isSpacedRepetitionMode]
  );

  return {
    counts,
    filteredCards,
    dueCards,
    isCalculating,
    getFlashcardCount,
    updateFlashcardCount
  };
};