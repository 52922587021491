import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { 
  ChevronRight, 
  ListCheck, 
  CheckCircle,
} from 'lucide-react';
import StudyPlanItem from './StudyPlanItem';

interface WeekData {
  weekNumber: number;
  start: Date;
  end: Date;
  availableMinutes: number;
  isFinalWeek?: boolean;
}

interface TaskDueDate {
  start: Date;
  end: Date;
  weekNumber: number;
  sequenceNumber: number;
}

interface SPStudyPlanItem {
  item_id: string;
  week_number: number | null;
  is_completed: boolean;
  topic_type: string | null;
  primary_topic_title: string | null;
  secondary_topic_title: string | null;
  blueprint_topic_title?: string | null;
  sequence_number: number;
  blueprint_topic_sequence?: number;
  primary_sequence?: number;
  secondary_topic_sequence?: number | null;
}

interface WeekSectionProps {
  week: WeekData;
  items: SPStudyPlanItem[];
  isExpanded: boolean;
  onToggle: () => void;
  primaryDisplayName: string;
  secondaryDisplayName: string;
  handleTaskClick: (itemId: string, topicType: string | null) => void;
  isPrimaryBlueprint: boolean;
  isSecondaryBlueprint: boolean;
  activeProvider: 'primary' | 'secondary' | 'blueprint';
  startDate: Date;
}

interface StudyPlanViewProps {
  weeks: WeekData[];
  items: SPStudyPlanItem[];
  primaryDisplayName: string;
  secondaryDisplayName: string;
  taskDueDates: Map<string, TaskDueDate>;
  isPrimaryBlueprint: boolean;
  isSecondaryBlueprint: boolean;
  handleTaskClick: (itemId: string, topicType: string | null, sequence?: number) => void;
  isAllExpanded: boolean;
  activeProvider: 'primary' | 'secondary' | 'blueprint';
  startDate: Date;
}

const WeekStatusTag = ({ status }: { status: 'Current' | 'Late' | 'Future' }) => {
  const statusStyles = {
    Current: 'bg-emerald-100 dark:bg-emerald-900/30 text-emerald-700 dark:text-emerald-300 ring-1 ring-emerald-400/30 dark:ring-emerald-400/20',
    Late: 'bg-red-100 dark:bg-red-900/30 text-red-700 dark:text-red-300 ring-1 ring-red-400/30 dark:ring-red-400/20',
    Future: 'bg-gray-100 dark:bg-gray-900/30 text-gray-700 dark:text-gray-300 ring-1 ring-gray-400/30 dark:ring-gray-400/20'
  };

  return (
    <div className={`
      absolute top-3 right-3 
      px-2 py-1 
      rounded-full 
      text-xs 
      font-medium
      ${statusStyles[status]}
    `}>
      {status}
    </div>
  );
};

const WeekSection: React.FC<WeekSectionProps> = ({
  week,
  items,
  isExpanded,
  onToggle,
  primaryDisplayName,
  secondaryDisplayName,
  handleTaskClick,
  isPrimaryBlueprint,
  isSecondaryBlueprint,
  activeProvider,
  startDate
}) => {
  const completedCount = items.filter(item => item.is_completed).length;
  const progressPercentage = (completedCount / items.length) * 100;

  const getWeekStatus = () => {
    const today = new Date();
    const weekStart = new Date(week.start);
    const weekEnd = new Date(week.end);

    const hasIncomplete = items.some(item => !item.is_completed);

    if (today >= weekStart && today <= weekEnd) {
      return 'Current';
    } else if (today > weekEnd && hasIncomplete) {
      return 'Late';
    } else if (today < weekStart) {
      return 'Future';
    }
    return null;
  };

  const weekStatus = getWeekStatus();

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden relative border border-gray-400 dark:border-gray-700">
      {weekStatus && (
        <WeekStatusTag status={weekStatus} />
      )}
      
      <div
        onClick={onToggle}
        className="bg-gray-50 dark:bg-gray-700 border-b border-gray-100 dark:border-gray-700 
                 cursor-pointer p-4"
      >
        <div className="flex items-center justify-between">
          <div className="flex-1">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Week {week.weekNumber}
              {week.isFinalWeek && " + Final Review"}
            </h3>
            <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4 mt-1">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {week.start.toLocaleDateString()} - {week.end.toLocaleDateString()}
              </span>
              <div className="flex items-center gap-2">
                <div className="w-32 h-2 bg-gray-200 dark:bg-gray-600 rounded-full">
                  <div 
                    className="h-full bg-green-500 rounded-full"
                    style={{ width: `${progressPercentage}%` }}
                  />
                </div>
                <span className="text-sm text-gray-600 dark:text-gray-300">
                  {progressPercentage.toFixed(0)}%
                </span>
              </div>
            </div>
            <div className="flex items-center gap-3 mt-2">
              <div className="flex items-center gap-1.5 text-sm text-gray-600 dark:text-gray-400">
                <ListCheck className="w-4 h-4" />
                <span>{items.length} Tasks</span>
              </div>
              <div className="flex items-center gap-1.5 text-sm text-gray-600 dark:text-gray-400">
                <CheckCircle className="w-4 h-4" />
                <span>{completedCount} Completed</span>
              </div>
            </div>
          </div>
          <ChevronRight 
            className={`w-5 h-5 text-gray-400 dark:text-gray-500 transition-transform duration-200 ${
              isExpanded ? 'rotate-90' : ''
            }`}
          />
        </div>
      </div>

      {isExpanded && (
        <div className="divide-y divide-gray-100 dark:divide-gray-700">
          {items.map(item => (
            <StudyPlanItem
              key={item.item_id}
              item={item}
              primaryDisplayName={primaryDisplayName}
              secondaryDisplayName={secondaryDisplayName}
              isPrimaryBlueprint={isPrimaryBlueprint}
              isSecondaryBlueprint={isSecondaryBlueprint}
              onItemClick={handleTaskClick}
              startDate={startDate}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const StudyPlanView: React.FC<StudyPlanViewProps> = ({
  weeks,
  items,
  primaryDisplayName,
  secondaryDisplayName,
  taskDueDates,
  isPrimaryBlueprint,
  isSecondaryBlueprint,
  handleTaskClick,
  isAllExpanded,
  activeProvider,
  startDate
}) => {
  const getCurrentWeekNumber = useCallback(() => {
    const today = new Date();
    
    for (const week of weeks) {
      const startDate = new Date(week.start);
      const endDate = new Date(week.end);
      if (today >= startDate && today <= endDate) {
        return week.weekNumber;
      }
    }
    return null;
  }, [weeks]);

  const [expandedWeeks, setExpandedWeeks] = useState<Set<number>>(() => {
    const currentWeek = getCurrentWeekNumber();
    return new Set(currentWeek ? [currentWeek] : []);
  });

  useEffect(() => {
    if (isAllExpanded) {
      setExpandedWeeks(new Set(weeks.map(w => w.weekNumber)));
    } else {
      const currentWeek = getCurrentWeekNumber();
      setExpandedWeeks(new Set(currentWeek ? [currentWeek] : []));
    }
  }, [isAllExpanded, weeks, getCurrentWeekNumber]);

  const toggleWeekExpansion = useCallback((weekNumber: number) => {
    setExpandedWeeks(prev => {
      const newSet = new Set(prev);
      if (newSet.has(weekNumber)) {
        newSet.delete(weekNumber);
      } else {
        newSet.add(weekNumber);
      }
      return newSet;
    });
  }, []);

  const sortedWeekGroups = useMemo(() => {
    const groupedItems = items.reduce((acc, item) => {
      const weekNumber = item.week_number || 0;
      if (!acc[weekNumber]) {
        acc[weekNumber] = [];
      }
      acc[weekNumber].push(item);
      return acc;
    }, {} as Record<number, SPStudyPlanItem[]>);

    return weeks
      .filter(week => groupedItems[week.weekNumber]?.length > 0)
      .map(week => {
        const weekItems = [...(groupedItems[week.weekNumber] || [])];
        weekItems.sort((a, b) => {
          let aSeq = 0;
          let bSeq = 0;

          switch (activeProvider) {
            case 'blueprint':
              aSeq = a.blueprint_topic_sequence || 0;
              bSeq = b.blueprint_topic_sequence || 0;
              break;
            case 'primary':
              aSeq = a.primary_sequence || 0;
              bSeq = b.primary_sequence || 0;
              break;
            case 'secondary':
              aSeq = a.secondary_topic_sequence || 0;
              bSeq = b.secondary_topic_sequence || 0;
              break;
          }

          return aSeq - bSeq;
        });

        return {
          week,
          items: weekItems,
        };
      });
  }, [weeks, items, activeProvider]);

  return (
    <div className="space-y-4">
      {sortedWeekGroups.map(({ week, items }) => (
        <WeekSection
          key={week.weekNumber}
          week={week}
          items={items}
          isExpanded={expandedWeeks.has(week.weekNumber)}
          onToggle={() => toggleWeekExpansion(week.weekNumber)}
          primaryDisplayName={primaryDisplayName}
          secondaryDisplayName={secondaryDisplayName}
          handleTaskClick={handleTaskClick}
          isPrimaryBlueprint={isPrimaryBlueprint}
          isSecondaryBlueprint={isSecondaryBlueprint}
          activeProvider={activeProvider}
          startDate={startDate}
        />
      ))}

      {sortedWeekGroups.length === 0 && (
        <div className="text-center py-8 text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
          No study tasks available for the selected filters
        </div>
      )}
    </div>
  );
};

export default StudyPlanView;