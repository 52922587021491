import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

interface TooltipProps {
  children: React.ReactElement;
  content: React.ReactNode;
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
  delayMS?: number;
  className?: string;
  variant?: 'default' | 'preview';
}

interface Position {
  top: number;
  left: number;
}

export const TooltipContent: React.FC<{
  children: React.ReactNode;
  className?: string;
  side?: 'top' | 'right' | 'bottom' | 'left';
  variant?: 'default' | 'preview';
}> = ({ children, className = '', variant = 'default' }) => (
  <div className={`
    bg-gray-900 dark:bg-gray-100 
    text-gray-50 dark:text-gray-900 rounded-md shadow-md
    ${variant === 'preview' ? 
      'p-3 max-w-lg text-xs leading-relaxed whitespace-pre-wrap' : 
      'px-3 py-2 text-sm'
    }
    ${className}
  `}>
    {children}
  </div>
);

export const TooltipTrigger: React.FC<{ 
  asChild?: boolean;
  children: React.ReactElement;
}> = ({ asChild, children }) => {
  if (asChild) {
    return children;
  }
  return <div className="inline-block">{children}</div>;
};

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  side = 'top',
  align = 'center',
  delayMS = 200,
  className = '',
  variant = 'default'
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [position, setPosition] = useState<Position>({ top: 0, left: 0 });
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const fadeTimeoutRef = useRef<NodeJS.Timeout>();
  const touchStartedRef = useRef(false);

  useEffect(() => {
    // Check if device supports touch events
    setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
    
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (fadeTimeoutRef.current) clearTimeout(fadeTimeoutRef.current);
    };
  }, []);

  const updatePosition = () => {
    if (!triggerRef.current || !tooltipRef.current) return;

    const triggerRect = triggerRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const scrollY = window.scrollY;
    const scrollX = window.scrollX;

    let top = 0;
    let left = 0;

    switch (side) {
      case 'top':
        top = triggerRect.top + scrollY - tooltipRect.height - 8;
        break;
      case 'bottom':
        top = triggerRect.bottom + scrollY + 8;
        break;
      case 'left':
        top = triggerRect.top + scrollY + (triggerRect.height - tooltipRect.height) / 2;
        left = triggerRect.left + scrollX - tooltipRect.width - 8;
        break;
      case 'right':
        top = triggerRect.top + scrollY + (triggerRect.height - tooltipRect.height) / 2;
        left = triggerRect.right + scrollX + 8;
        break;
    }

    if (side === 'top' || side === 'bottom') {
      switch (align) {
        case 'start':
          left = triggerRect.left + scrollX;
          break;
        case 'center':
          left = triggerRect.left + scrollX + (triggerRect.width - tooltipRect.width) / 2;
          break;
        case 'end':
          left = triggerRect.right + scrollX - tooltipRect.width;
          break;
      }
    }

    setPosition({ top, left });
  };

  const showTooltip = () => {
    if (fadeTimeoutRef.current) clearTimeout(fadeTimeoutRef.current);
    setIsFading(false);
    setIsVisible(true);
    setTimeout(updatePosition, 0);
  };

  const hideTooltip = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setIsVisible(false);
    setIsFading(false);
  };

  // Touch event handlers
  useEffect(() => {
    if (!triggerRef.current) return;

    const trigger = triggerRef.current;

    const handleTouch = () => {
      if (touchStartedRef.current) return;
      touchStartedRef.current = true;
      
      showTooltip();
      
      // Start fading out after 500ms
      fadeTimeoutRef.current = setTimeout(() => {
        setIsFading(true);
        // Actually hide after fade animation
        timeoutRef.current = setTimeout(() => {
          setIsVisible(false);
          setIsFading(false);
          touchStartedRef.current = false;
        }, 200); // Match the CSS transition duration
      }, 900);
    };

    trigger.addEventListener('touchstart', handleTouch, { passive: true });
    
    return () => {
      trigger.removeEventListener('touchstart', handleTouch);
    };
  }, []);

  const handleMouseEnter = () => {
    if (!isTouchDevice) {
      timeoutRef.current = setTimeout(showTooltip, delayMS);
    }
  };

  const handleMouseLeave = () => {
    if (!isTouchDevice) {
      hideTooltip();
    }
  };

  useEffect(() => {
    if (isVisible) {
      const handleScroll = () => {
        updatePosition();
      };

      window.addEventListener('scroll', handleScroll, true);
      window.addEventListener('resize', updatePosition);

      return () => {
        window.removeEventListener('scroll', handleScroll, true);
        window.removeEventListener('resize', updatePosition);
      };
    }
  }, [isVisible]);

  return (
    <div 
      ref={triggerRef}
      className="relative inline-block"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isVisible && createPortal(
        <div 
          ref={tooltipRef}
          style={{
            position: 'absolute',
            top: position.top,
            left: position.left,
            zIndex: 9999,
          }}
          className={`pointer-events-none transition-opacity duration-200 ${
            isFading ? 'opacity-0' : 'opacity-100'
          } ${className}`}
        >
          <TooltipContent side={side} variant={variant}>
            {content}
          </TooltipContent>
        </div>,
        document.body
      )}
    </div>
  );
};

export default Tooltip;